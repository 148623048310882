import { WSAlert } from "@wingspanhq/fe-component-library";
import { TaxFormType } from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { usePayeeTaxForms } from "../../../../query/taxForm/queries/usePayeeTaxForms";
import { TAX_DOCUMENTS_PATH } from "../../../TaxDocuments/paths";
import { CURRENT_YEAR } from "../../../TaxFiling/constants/currentYear";

export const BannerT4ATaxForm: React.FC = () => {
  const history = useHistory();

  const queryPayerT4ASlipTaxForms = usePayeeTaxForms({
    year: CURRENT_YEAR,
    type: TaxFormType.T4ASlip
  });

  if (!queryPayerT4ASlipTaxForms.data?.length) {
    return null;
  }

  return (
    <WSAlert
      title="Tax documents"
      theme="info"
      icon="info-circle"
      action={{
        text: "View tax documents",
        onClick: () => {
          history.push(TAX_DOCUMENTS_PATH);
        }
      }}
    >
      Your tax documents dashboard has been updated. Review your T4A form to
      ensure all reported information is accurate.
    </WSAlert>
  );
};
