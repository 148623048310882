import {
  toWSMoney,
  WSAvatar,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

import { useHistory } from "react-router-dom";
import {
  IPayoutResponse,
  IPayoutTransfer,
  PayoutTypeEnum
} from "../../../../services/api/payments/payouts/types";
import {
  avatarBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";

export const PayoutAccountDetailsItem: React.FC<{
  payoutAccountItem: IPayoutTransfer;
  payout: IPayoutResponse;
  isWallet?: boolean;
}> = ({ payoutAccountItem, payout, isWallet }) => {
  const history = useHistory();

  return (
    <WSPanel key={payoutAccountItem.fundsMovementId} mb={"L"} noBorder>
      <WSFlexBox mb="XL" justify="space-between">
        {isWallet ? (
          <WSFlexBox alignItems="center">
            <WSAvatar {...avatarWallet} mr="M" />
            <WSButton.Link
              size="M"
              name="viewRoutingNumber"
              onClick={() => history.push("/member/wallet")}
            >
              Wingspan Wallet
            </WSButton.Link>
          </WSFlexBox>
        ) : (
          <WSFlexBox alignItems="center">
            <WSAvatar {...avatarBankAccount} mr="M" />
            <WSText.ParagraphSm color={"gray500"} mr="2XL">
              {`${payoutAccountItem?.destination?.institutionName || ""} `}
            </WSText.ParagraphSm>
          </WSFlexBox>
        )}
      </WSFlexBox>

      <WSGrid gutter="2XL" mb={"L"}>
        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Account Number/Routing Number
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              {`(...${payoutAccountItem?.destination?.mask || ""})`}
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>

        {/* <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
                <WSFlexBox direction="column">
                  <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                    Routing number
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="book" color="gray700">
                    {payoutAccountItem.routingNumber}
                  </WSText.ParagraphSm>
                </WSFlexBox>
              </WSGrid.Item> */}

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Payout amount
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              {
                toWSMoney(payoutAccountItem?.amount, "default", payout.currency)
                  .formattedValue
              }
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>

        {/* <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
                <WSFlexBox direction="column">
                  <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                    Payout completion date
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="book" color="gray700">
                    {toWSDateString(
                      payoutAccountItem.payoutCompletionDate,
                      "monthDayYear"
                    )}
                  </WSText.ParagraphSm>
                </WSFlexBox>
              </WSGrid.Item> */}

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Statement description
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              Wingspan Payout
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>

        {payoutAccountItem.traceId && (
          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                Trace ID / reference #
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="book" color="gray700">
                {payoutAccountItem.traceId}
              </WSText.ParagraphSm>
            </WSFlexBox>
          </WSGrid.Item>
        )}

        {payout.payoutType && (
          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                Delivery method
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="book" color="gray700">
                {PayoutTypeEnum[payout.payoutType]}
              </WSText.ParagraphSm>
            </WSFlexBox>
          </WSGrid.Item>
        )}
      </WSGrid>
      <WSDivider mb={"L"} />
      <WSText.ParagraphSm mb={"L"} weight="book" color="gray500">
        {`Payout • ${
          toWSMoney(payoutAccountItem?.amount, "default", payout.currency)
            .formattedValue
        }(USD)`}
        {/* • ${toWSDateString(
                payoutAccountItem.payoutCompletionDate,
                "monthDayYear"
              )} */}
      </WSText.ParagraphSm>
      {payoutAccountItem.status && (
        <WSInfoBox mb={"L"}>
          {/* {payoutAccountItem.timeLine.map((timeLine, i) => (
                <WSFlexBox
                  alignItems="center"
                  mb={
                    i === payoutAccountItem.timeLine.length - 1 ? "NONE" : "L"
                  }
                >
                  <WSText.ParagraphSm weight="book" color="gray700">
                    {timeLine.label}
                  </WSText.ParagraphSm>

                  <WSPill
                    ml="L"
                    text={toWSDateString(
                      payoutAccountItem.payoutCompletionDate,
                      "monthDayYear"
                    )}
                    theme="neutral"
                  />
                </WSFlexBox>
              ))} */}
          <WSFlexBox alignItems="center">
            <WSText.ParagraphSm weight="book" color="gray700">
              {payoutAccountItem.status}
            </WSText.ParagraphSm>

            {/* <WSPill
                    ml="L"
                    text={toWSDateString(
                      payoutAccountItem.payoutCompletionDate,
                      "monthDayYear"
                    )}
                    theme="neutral"
                  /> */}
          </WSFlexBox>
        </WSInfoBox>
      )}
    </WSPanel>
  );
};
