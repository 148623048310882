import { WSQueryConfig } from "@ws-react-query";
import { getCustomerEntity } from "../../../services/api/onboarding/customer";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_CUSTOMER_ENTITY = "QUERY_CUSTOMER_ENTITY";

export const useQueryCustomerEntity = (
  config?: WSQueryConfig<
    Awaited<ReturnType<typeof getCustomerEntity>>,
    WSServiceError
  >
) => useWSQuery(QUERY_CUSTOMER_ENTITY, getCustomerEntity, config);
