import { useHistory, useLocation } from "react-router-dom";
import queryString from "qs";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";

export const BULK_IMPORTS_ROOT_PATH = "/member/imports" as const;

// payables

export const BULK_IMPORT_PAYABLES_BATCHES =
  `${BULK_IMPORTS_ROOT_PATH}/payables` as const;
export const BULK_IMPORT_PAYABLES_START =
  `${BULK_IMPORT_PAYABLES_BATCHES}/start` as const;
export const BULK_IMPORT_PAYABLES_BATCH_DETAILS =
  `${BULK_IMPORT_PAYABLES_BATCHES}/:bulkBatchId` as const;
export const BULK_IMPORT_PAYABLES_BATCH_EVENT_HISTORY =
  `${BULK_IMPORT_PAYABLES_BATCH_DETAILS}/history` as const;

const useGoToDetailsPath = (path: string) => {
  const history = useHistory();

  return (bulkBatchId: string, state?: any) => {
    const url = path.replace(":bulkBatchId", bulkBatchId);
    history.push({
      pathname: url,
      state
    });
  };
};

export const useGoToPayableBatches = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  // if year is not present in query params, add current year
  let search = location.search;
  if (!queryParams.year) {
    search = queryString.stringify({
      ...queryParams,
      year: new Date().getFullYear()
    });
  }
  return () => {
    history.push({
      pathname: BULK_IMPORT_PAYABLES_BATCHES,
      search
    });
  };
};

export const useGoToPayablesImportStart = () => {
  const history = useHistory();
  const queryFeatureFlags = useFeatureFlags();
  return () => {
    if (queryFeatureFlags.data?.newBulkImporterUIForPayables) {
      history.push(BULK_IMPORT_PAYABLES_START);
    } else {
      history.push("/member/invoices/payables/bulk-upload/info");
    }
  };
};

export const useGoToPayableBatchDetails = () =>
  useGoToDetailsPath(BULK_IMPORT_PAYABLES_BATCH_DETAILS);

export const useGoToPayableBatchEventHistory = () =>
  useGoToDetailsPath(BULK_IMPORT_PAYABLES_BATCH_EVENT_HISTORY);

// contractors
export const BULK_IMPORT_CONTRACTORS_BATCHES =
  `${BULK_IMPORTS_ROOT_PATH}/collaborators` as const;
export const BULK_IMPORT_CONTRACTORS_START =
  `${BULK_IMPORT_CONTRACTORS_BATCHES}/start` as const;
export const BULK_IMPORT_CONTRACTORS_BATCH_DETAILS =
  `${BULK_IMPORT_CONTRACTORS_BATCHES}/:bulkBatchId` as const;
export const BULK_IMPORT_CONTRACTORS_BATCH_EVENT_HISTORY =
  `${BULK_IMPORT_CONTRACTORS_BATCH_DETAILS}/history` as const;

export const useGoToContractorBatches = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  // if year is not present in query params, add current year
  let search = location.search;
  if (!queryParams.year) {
    search = queryString.stringify({
      ...queryParams,
      year: new Date().getFullYear()
    });
  }
  return () => {
    history.push({
      pathname: BULK_IMPORT_CONTRACTORS_BATCHES,
      search
    });
  };
};

export const useGoToContractorImportStart = () => {
  const history = useHistory();
  const queryFeatureFlags = useFeatureFlags();
  return () => {
    if (queryFeatureFlags.data?.newBulkImporterUI) {
      history.push(BULK_IMPORT_CONTRACTORS_START);
    } else {
      history.push("/member/invoices/contacts/collaborators/bulk-create/info");
    }
  };
};

export const useGoToContractorBatchDetails = () =>
  useGoToDetailsPath(BULK_IMPORT_CONTRACTORS_BATCH_DETAILS);

export const useGoToContractorBatchEventHistory = () =>
  useGoToDetailsPath(BULK_IMPORT_CONTRACTORS_BATCH_EVENT_HISTORY);
