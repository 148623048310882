import React from "react";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import { useDeleteAccount } from "../../../query/users/mutations";
import {
  useModalOldContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { getAccountName } from "../../../query/bookkeeping/selectors";

export const DELETE_ACCOUNT_MODAL = "DELETE_ACCOUNT_MODAL";

export const DeleteAccount: React.FC<{ onSuccess: () => void }> = ({
  onSuccess
}) => {
  const [deleteAccount, deleteAccountMeta] = useDeleteAccount();
  const { closeModal } = useModalOldContext();
  const { openSnackbar } = useWSSnackbar();

  return (
    <WSModalOld size="S" name={DELETE_ACCOUNT_MODAL}>
      {(account: IAccount) => {
        const accountName = getAccountName(account);

        return (
          <>
            <WSText.Heading5 mb="M">Delete {accountName}</WSText.Heading5>
            <WSText mb="M">
              We've imported transactions from {accountName}.
            </WSText>
            <WSText mb="M">Would you like to delete those transactions?</WSText>
            <WSText mb="2XL">
              Once confirmed, this action cannot be undone.
            </WSText>
            <WSButtons forceFullWidth format="modal">
              <WSButton
                destructive
                loading={deleteAccountMeta.isLoading}
                onClick={async () => {
                  await deleteAccount({
                    accountId: account.accountId,
                    deleteTransactions: true
                  });
                  onSuccess();
                  openSnackbar({
                    message: `Account deleted`,
                    type: "success"
                  });
                  closeModal(DELETE_ACCOUNT_MODAL);
                }}
              >
                Delete Transactions
              </WSButton>
              <WSButton
                destructive
                loading={deleteAccountMeta.isLoading}
                onClick={async () => {
                  await deleteAccount({ accountId: account.accountId });
                  onSuccess();
                  openSnackbar({
                    message: `Account deleted`,
                    type: "success"
                  });
                  closeModal(DELETE_ACCOUNT_MODAL);
                }}
              >
                Keep Transactions
              </WSButton>
              <WSButton.Secondary
                onClick={() => {
                  closeModal(DELETE_ACCOUNT_MODAL);
                }}
              >
                Cancel
              </WSButton.Secondary>
            </WSButtons>
          </>
        );
      }}
    </WSModalOld>
  );
};
