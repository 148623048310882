import {
  IInvoice,
  IInvoicePayoutDestination,
  InvoicePayoutDestinationType
} from "@wingspanhq/payments/dist/interfaces";
import { getInvoicePayoutDestinationDescription } from "../../query/payments/selectors";

export function getInvoicePayoutSummary(invoice: IInvoice) {
  const destinations = (invoice.payoutDestinations || [])
    .map(getInvoicePayoutDestinationDescription)
    .filter(Boolean);

  const hasPayoutType = invoice.payoutDestinations?.some(
    d => d.destinationType === InvoicePayoutDestinationType.Payout
  );

  if (destinations.length === 0 || hasPayoutType) {
    return "Funds have been deposited";
  }

  return `Funds deposited to ${destinations.join(", ")}`;
}

export function stackPayoutAmounts(
  payoutDestinations: IInvoicePayoutDestination[]
) {
  let stackedDestinations: IInvoicePayoutDestination[] = [];

  for (let i = 0; i < payoutDestinations.length; i++) {
    // Stack destinations with same id into the one
    if (
      payoutDestinations[i].destinationType ===
      InvoicePayoutDestinationType.Payout
    ) {
      const matchIndex = stackedDestinations.findIndex(
        d => d.destinationId === payoutDestinations[i].destinationId
      );

      if (matchIndex >= 0) {
        stackedDestinations[matchIndex].amount += payoutDestinations[i].amount;
        continue;
      }
    }

    stackedDestinations.push({ ...payoutDestinations[i] });
  }

  // Filter out destination with 0 amount
  stackedDestinations = stackedDestinations.filter(d => Boolean(d.amount));

  return stackedDestinations;
}
