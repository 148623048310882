import classNames from "classnames";
import React, { useMemo } from "react";
import { WSAlert, WSAlertProps } from "../WSAlert/WSAlert";
import { WSDivider } from "../WSDivider/WSDivider";
import { WSElement } from "../WSElement/WSElement.component";
import { Label, WSDataItemLabelProps } from "./Label";
import styles from "./styles.module.scss";
import { Value, WSDataItemValueProps } from "./Value";
import { WSPill, WSPillProps } from "../common";

const DEFAULT_ALERT_SIZE = "S";

export type WSDataItemProps = {
  label?: WSDataItemLabelProps | string;
  className?: string;
  inline?: boolean;
  divider?: boolean;
  alert?: WSAlertProps;
  pill?: WSPillProps;
} & WSDataItemValueProps;

export const WSDataItem: React.FC<WSDataItemProps> = (props) => {
  const labelProps = useMemo(() => {
    if (typeof props.label === "string") {
      return {
        text: props.label
      };
    }

    return props.label;
  }, [props.label]);

  return (
    <WSElement className={props.className}>
      <WSElement
        className={classNames(
          styles.dataItem,
          props.inline && styles.inline,
          props.divider && styles.divider
        )}
      >
        <Label {...labelProps} />

        {props.divider && !props.inline && <WSDivider />}

        {props.hideValue !== true && (
          <Value
            formatter={props.formatter}
            value={props.value}
            type={props.type}
            formatterOptions={props.formatterOptions}
            pill={props.pill}
            avatar={props.avatar}
            icon={props.icon}
            copy={props.copy}
            fsExclude={props.fsExclude}
            action={props.action}
          />
        )}
      </WSElement>

      {props.pill && <WSPill mt="S" {...props.pill} />}

      {props.alert && (
        <WSAlert mt="S" size={DEFAULT_ALERT_SIZE} {...props.alert} />
      )}
    </WSElement>
  );
};
