import {
  useModalOldContext,
  WSButton,
  WSFormOld,
  WSModalOld,
  WSRadioInputGroup
} from "@wingspanhq/fe-component-library";
import React from "react";
import { PaymentMethodType } from "../../../components/WSAccountsListV2/types";
import { CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL } from "../../../Invoices/components/ChooseAccountForPaymentMethodsModal/ChooseAccountForPaymentMethodsModal";
import { usePaymentCardForm } from "../../../shared/hooks/usePaymentCardForm";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useUpdateClient } from "../../../query/users/mutations";
import { SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL } from "./SavedPaymentMethodCreditCardModal";

export interface ChooseAddPaymentMethodFlowModalOptions {
  shouldSetDefaultPaymentMethod?: boolean;
  shouldSetSubscriptionPaymentMethod?: boolean;
}

export const CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL =
  "CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL";

export const ChooseAddPaymentMethodFlowModal: React.FC = () => {
  const userId = useUserId();
  const { openModal, closeModal } = useModalOldContext();
  const queryFeatureFlags = useFeatureFlags();
  const paymentCardForm = usePaymentCardForm();
  const [updateClient] = useUpdateClient(userId);

  const onFormSubmit = (
    data: any,
    modalProps: ChooseAddPaymentMethodFlowModalOptions
  ) => {
    if (data.addPaymentMethodFlowType === PaymentMethodType.BANK_ACCOUNT) {
      openModal(CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL, modalProps);
    } else {
      if (queryFeatureFlags.data?.paymentCardAsPaymentMethod) {
        paymentCardForm.open({
          onSuccess: paymentCard => {
            if (modalProps.shouldSetDefaultPaymentMethod) {
              updateClient({
                clientId: userId,
                profile: {
                  defaultPaymentMethod: {
                    paymentMethodId: paymentCard.paymentCardId
                  }
                }
              });
            }
          }
        });
      } else {
        openModal(SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL, modalProps);
      }
    }

    closeModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL);
  };

  return (
    <WSModalOld
      name={CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL}
      size="S"
      title="Add payment method"
    >
      {(modalProps: ChooseAddPaymentMethodFlowModalOptions) => (
        <WSFormOld
          defaultValues={{
            addPaymentMethodFlowType: PaymentMethodType.BANK_ACCOUNT
          }}
          onSubmit={data => onFormSubmit(data, modalProps)}
        >
          <>
            <WSFormOld.Field
              name="addPaymentMethodFlowType"
              label=""
              mb="XL"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    label: "Bank account",
                    value: PaymentMethodType.BANK_ACCOUNT
                  },
                  {
                    label: "Credit card",
                    value: PaymentMethodType.CREDIT_CARD
                  }
                ]
              }}
            />
            <WSButton type="submit" name="continue">
              Continue
            </WSButton>
          </>
        </WSFormOld>
      )}
    </WSModalOld>
  );
};
