import {
  WSButton,
  WSButtons,
  WSFlexBox,
  WSModalOld,
  WSText,
  useModalOldContext
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";

import useSnackbar from "../../../../hooks/useSnackbar";
import { useHistory } from "react-router-dom";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { isTruthy } from "../../../../utils";

const DELETE_BULK_BATCH_MODAL = "DELETE_BULK_BATCH_MODAL";

interface DeleteBulkBatchModalChildrenProps {
  bulkBatch: IBulkBatch;
  isEmptyBatch?: boolean;
}

function DeleteBulkImportPayableModal({
  title,
  bulkBatchId,
  bulkResource
}: {
  title: string;
  bulkBatchId: string;
  bulkResource: BulkResource;
}) {
  const { closeModal } = useModalOldContext();
  const history = useHistory();
  const { success, warning } = useSnackbar();
  const { useDeleteBulkBatch } = getBulkQueriesByResource(bulkResource);
  const [deleteBulkBatch, deleteBulkBatchMeta] = useDeleteBulkBatch();

  let deleteModalProps = {
    modalDesc:
      "This import has line items but no payables or deductions are created yet.",
    emptyBatchModalDesc:
      "This import is empty. No payable or line items are imported yet.",
    redirectPath: "/member/imports/payables"
  };
  if (bulkResource === BulkResource.Collaborator) {
    deleteModalProps = {
      modalDesc:
        "This import has contractors data but no contractors are created yet.",
      emptyBatchModalDesc:
        "This import is empty. No contractors are imported yet.",
      redirectPath: "/member/imports/collaborators"
    };
  }
  const { modalDesc, emptyBatchModalDesc, redirectPath } = deleteModalProps;

  const deleteBulkImport = async () => {
    await deleteBulkBatch(
      { bulkBatchId },
      {
        onSuccess: (response: IBulkBatch) => {
          success(
            `${getUploadedFilename(
              response,
              bulkResource
            )} successfully deleted.`
          );
          closeModal(DELETE_BULK_BATCH_MODAL);
          history.push(redirectPath);
        },
        onError: err => {
          warning(
            `Failed to delete: ${
              err.response?.data?.error || "Something went wrong!"
            }`
          );
        }
      }
    );
  };
  return (
    <WSModalOld
      name={DELETE_BULK_BATCH_MODAL}
      title={title}
      size="XS"
      showCloseIcon={false}
    >
      {({ bulkBatch, isEmptyBatch }: DeleteBulkBatchModalChildrenProps) => {
        return (
          <>
            {bulkBatch && bulkBatch.status === BulkStatus.Open ? (
              <WSText color="gray500" mb="XL">
                {isTruthy(isEmptyBatch) ? emptyBatchModalDesc : modalDesc}
              </WSText>
            ) : null}
            <WSButtons format="modal" justify="center">
              <WSButton
                destructive
                name="deleteBulkImport"
                loading={deleteBulkBatchMeta.isLoading}
                onClick={deleteBulkImport}
              >
                Delete
              </WSButton>
              <WSButton.Tertiary
                name="cancelDeleteBulkImport"
                onClick={() => closeModal(DELETE_BULK_BATCH_MODAL)}
              >
                Cancel
              </WSButton.Tertiary>
            </WSButtons>
          </>
        );
      }}
    </WSModalOld>
  );
}

export interface BulkBatchDetailsFooterProps {
  bulkBatchId: string;
  isEmptyBatch?: boolean;
  bulkResource: BulkResource;
}

export function DeleteBulkBatch({
  bulkBatchId,
  isEmptyBatch,
  bulkResource
}: BulkBatchDetailsFooterProps) {
  const { openModal } = useModalOldContext();
  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);

  const confirmDeleteBulkImport = () => {
    openModal(DELETE_BULK_BATCH_MODAL, {
      isEmptyBatch,
      bulkBatch: qBulkBatch.data
    });
  };

  return (
    <WSQueries queries={{ qBulkBatch }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        const isImportInprogress = [
          BulkStatus.Pending,
          BulkStatus.Processing
        ].includes(bulkBatch.status);
        let deleteModalTitle = "Delete import?";
        return (
          <>
            <DeleteBulkImportPayableModal
              title={deleteModalTitle}
              bulkBatchId={bulkBatchId}
              bulkResource={bulkResource}
            />
            <WSButton
              destructive
              disabled={isImportInprogress}
              icon="trash"
              fullWidth
              onClick={confirmDeleteBulkImport}
              mb="M"
            >
              Delete import
            </WSButton>
          </>
        );
      }}
    </WSQueries>
  );
}
