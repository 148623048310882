import {
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import styles from "./other.module.scss";
import { useHistory } from "react-router";
import { CatchClickOutside } from "../../utils/useDidClickOutside";
import { Tooltip } from "../Tooltip";
import { useShowNewCollaboratorPaymentNav } from "../../userFeatures/useShowNewCollaboratorPaymentNav";
import { useShowNewOneTimeInvoiceNav } from "../../userFeatures/useShowNewOneTimeInvoiceNav";
import { useShowNewRecurringInvoicesNav } from "../../userFeatures/useShowNewRecurringInvoicesNav";

export interface NewProps {
  isSideNavOpened: boolean;
  setIsSideNavOpened: (isDrawerOpened: boolean) => void;
}

export const New: React.FC<NewProps> = ({
  isSideNavOpened,
  setIsSideNavOpened
}) => {
  const { openModal } = useModalOldContext();
  const history = useHistory();

  const showNewCollaboratorPaymentNav = useShowNewCollaboratorPaymentNav();
  const showNewOneTimeInvoiceNav = useShowNewOneTimeInvoiceNav();
  const showNewRecurringInvoicesNav = useShowNewRecurringInvoicesNav();

  return (
    <NewDropdown
      setIsSideNavOpened={setIsSideNavOpened}
      isSideNavOpened={isSideNavOpened}
    >
      {showNewOneTimeInvoiceNav && (
        <WSFlexBox.CenterY
          onClick={() => {
            history.push("/member/invoices/create");
            setIsSideNavOpened(false);
          }}
          className={styles.dropdownItem}
          px="M"
        >
          <WSText.ParagraphSm color="gray600">
            One-time invoice
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      )}

      {showNewRecurringInvoicesNav && (
        <WSFlexBox.CenterY
          onClick={() => {
            history.push("/member/invoices/create", { isRecurring: true });
            setIsSideNavOpened(false);
          }}
          className={styles.dropdownItem}
          px="M"
        >
          <WSText.ParagraphSm color="gray600">
            Recurring invoice
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      )}

      {showNewCollaboratorPaymentNav && (
        <WSFlexBox.CenterY
          onClick={() => {
            history.push("/member/invoices/payables/create");
            setIsSideNavOpened(false);
          }}
          className={styles.dropdownItem}
          px="M"
        >
          <WSText.ParagraphSm color="gray600">
            Contractor payment
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      )}
      <WSFlexBox.CenterY
        onClick={() => {
          history.push("/member/bookkeeping/transactions/add-transaction");
          setIsSideNavOpened(false);
        }}
        className={styles.dropdownItem}
        px="M"
      >
        <WSText.ParagraphSm color="gray600">Transaction</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
    </NewDropdown>
  );
};

interface NewDropdownProps extends WSElementProps {
  isSideNavOpened: boolean;
  setIsSideNavOpened: (isDrawerOpened: boolean) => void;
}

const NewDropdown: React.FC<NewDropdownProps> = ({
  children,

  isSideNavOpened,
  setIsSideNavOpened
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isSideNavOpened) {
      setIsExpanded(false);
    }
  }, [isSideNavOpened]);

  return (
    <>
      {isSideNavOpened || isMobile ? (
        <WSFlexBox
          className={styles.dropdownWrapper}
          px="XL"
          mb="XL"
          wrap="nowrap"
        >
          <WSFlexBox.Center className={styles.newTrigger}>
            {isMobile ? (
              <WSButton.Primary
                onClick={() => setIsExpanded(prev => !prev)}
                fullWidth
                icon="plus"
              >
                New
              </WSButton.Primary>
            ) : (
              <WSButton.Secondary
                onClick={() => setIsExpanded(prev => !prev)}
                fullWidth
                icon="plus"
              >
                New
              </WSButton.Secondary>
            )}
            {isExpanded ? (
              <CatchClickOutside onClickOutside={() => setIsExpanded(false)}>
                {ref => (
                  <div ref={ref} className={styles.dropdownContent}>
                    {children}
                  </div>
                )}
              </CatchClickOutside>
            ) : null}
          </WSFlexBox.Center>
        </WSFlexBox>
      ) : (
        <Tooltip
          className={styles.newTooltip}
          onTriggerClick={() => {
            setIsSideNavOpened(true);
            setIsExpanded(true);
          }}
          trigger={
            <WSFlexBox.Center>
              <WSButton.Secondary size="S" icon="plus" />
            </WSFlexBox.Center>
          }
          placement="right"
        >
          <WSText color="white">Send or pay invoices</WSText>
        </Tooltip>
      )}
    </>
  );
};
