import {
  WSAvatar,
  WSButton,
  WSCard,
  WSFlexBox,
  WSForm,
  WSImage,
  WSInfoBox,
  WSInputMask,
  WSList,
  WSText,
  toWSDateString,
  useWSModal,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import {
  AccountVerificationType,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";
import imageTransactions from "../../../assets/images/microDepositTransactions.png";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { updateAccountCache } from "../../../query/cache/updateAccountCache";
import { useWSMutation } from "../../../query/helpers";
import { QUERY_USERS_ACCOUNTS } from "../../../query/users/keys";
import { usersService } from "../../../services/users";
import { openIntercom } from "../../../shared/utils/intercom";
import { WSServiceError } from "../../../utils/serviceHelper";

function amountToNumber(amount: string | null) {
  if (!amount) {
    return 0;
  }

  return Number(amount.replace("$0.", "0."));
}

type Props = {
  account: IAccount;
  onClose: () => void;
};

type FormData = { amount1: string | null; amount2: string | null };

export const ModalMicrodepositsVerify: React.FC<Props> = ({
  account,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();

  const [verify, verifyMeta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const amount1 = amountToNumber(data.amount1);
      const amount2 = amountToNumber(data.amount2);

      const updatedAccount = await usersService.account.verify(
        account.accountId,
        {
          type: AccountVerificationType.MicroDeposit,
          verifications: {
            microDepositAmount1: amount1,
            microDepositAmount2: amount2
          }
        }
      );

      updateAccountCache(updatedAccount);
    },
    {
      onSuccess: () => {
        openSnackbar({
          type: "success",
          icon: {
            name: "check-circle"
          },
          message: "You’re all set! The payment account was verified"
        });
        onClose();
      },
      dependencies: [QUERY_USERS_ACCOUNTS]
    }
  );

  return (
    <WSCard header="Verify Account" divider>
      <WSForm<FormData>
        defaultValues={{ amount1: null, amount2: null }}
        validationSchema={Yup.object().shape({
          amount1: Yup.string()
            .required("Should be greater than 0")
            .not(["00"], "Should be greater than 0"),
          amount2: Yup.string()
            .required("Should be greater than 0")
            .not(["00"], "Should be greater than 0")
        })}
        onSubmit={data => {
          verify(data);
        }}
      >
        <WSList>
          <WSText.ParagraphSm>
            Wingspan made <b>two deposits</b> in USD into your account ending in
            •••
            {account.numbers.account.slice(-4)} on{" "}
            {toWSDateString(new Date(), "monthDate")}. Enter the amounts to
            verify your account.
          </WSText.ParagraphSm>

          <WSInfoBox>
            <WSFlexBox.CenterY mb="XL">
              <WSAvatar.Icon
                mr="M"
                icon="bank"
                color="white"
                colorBackground="gray500"
              />

              <WSText>
                •••{account.numbers.account.slice(-4)} Bank Statement
              </WSText>
            </WSFlexBox.CenterY>

            <WSImage mb="2XL" src={imageTransactions} placeholder="" />

            <p>Enter amounts:</p>

            <WSList>
              <WSForm.Field
                name="amount1"
                component={WSInputMask}
                componentProps={{
                  mask: "$0.99",
                  placeholder: "$0.00",
                  slotChar: "0"
                }}
              />

              <WSForm.Field
                name="amount2"
                component={WSInputMask}
                componentProps={{
                  mask: "$0.99",
                  placeholder: "$0.00",
                  slotChar: "0"
                }}
              />
            </WSList>
          </WSInfoBox>

          <WSErrorMessage
            contextKey="BankAccountVerification"
            error={verifyMeta.error}
          />

          <WSForm.Values names={["amount1", "amount2"]}>
            {values => {
              const isAmount1Valid =
                !!values.amount1 && values.amount1 !== "00";
              const isAmount2Valid =
                !!values.amount2 && values.amount2 !== "00";
              const isValid = isAmount1Valid && isAmount2Valid;

              return (
                <WSButton
                  fullWidth
                  disabled={!isValid}
                  loading={verifyMeta.isLoading}
                >
                  Verify bank account
                </WSButton>
              );
            }}
          </WSForm.Values>

          <WSText.ParagraphSm color="gray500">
            Payments to invoices made from this account will be in pending
            status until the verification process is complete. Once the account
            is verified, invoices paid with this account will be processed.
          </WSText.ParagraphSm>

          <WSText.ParagraphXs color="gray400">
            Didn’t receive the deposits?{" "}
            <WSButton.Link size="S" onClick={openIntercom} type="button">
              Contact support
            </WSButton.Link>
          </WSText.ParagraphXs>
        </WSList>
      </WSForm>
    </WSCard>
  );
};

export const useModalMicrodepositsVerify = () =>
  useWSModal(ModalMicrodepositsVerify, {
    style: {
      padding: 0,
      borderRadius: 8
    }
  });
