import { WSCheckboxToggle, WSFormOld } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import {
  FormDataAddress,
  FormPartialAddress,
  formDataToRequestAddress,
  validationSchemaAddress
} from "../../../shared/components/FormPartialAddress";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

export interface PersonalAddressFormValues {
  businessAddress: FormDataAddress;
  isHomeAddressSame: boolean;
  homeAddress: FormDataAddress;
}

interface FormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues: PersonalAddressFormValues;
  onCancel?: (data: any) => void;
}

export const PersonalAddressForm: React.FC<FormProps> = ({
  onSubmit,
  defaultValues = {},
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (formValues: PersonalAddressFormValues) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        homeAddress:
          formValues.isHomeAddressSame && defaultValues.businessAddress
            ? formDataToRequestAddress(defaultValues.businessAddress)
            : formDataToRequestAddress(formValues.homeAddress)
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(formValues);
      }
    });
  };

  return (
    <WSFormOld<PersonalAddressFormValues>
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        homeAddress: Yup.object().when("isHomeAddressSame", {
          is: true,
          then: Yup.object(),
          otherwise: validationSchemaAddress
        })
      })}
    >
      <WSFormOld.Field
        name="isHomeAddressSame"
        component={WSCheckboxToggle}
        componentProps={{
          label: (
            <>
              My personal address is the same as my{" "}
              <Link to="/member/settings/business-info#businessAddress">
                business address
              </Link>
            </>
          )
        }}
        mb="XL"
      />

      <WSFormOld.Value name="isHomeAddressSame">
        {isHomeAddressSame => (
          <>
            <FormPartialAddress
              mb="2XL"
              name="homeAddress"
              hidden={isHomeAddressSame}
            />
          </>
        )}
      </WSFormOld.Value>

      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
