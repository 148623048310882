export const QUERY_PAYMENTS_STATUS = "PAYMENTS_STATUS";
export const QUERY_INVOICES = "INVOICES";
export const QUERY_INVOICE = "QUERY_INVOICE";
export const QUERY_INVOICE_TEMPLATES = "INVOICE_TEMPLATES";
export const QUERY_INVOICE_TEMPLATE = "QUERY_INVOICE_TEMPLATE";

export const QUERY_COLLABORATOR_GROUPS = "QUERY_COLLABORATOR_GROUPS";
export const QUERY_COLLABORATOR_GROUP = "QUERY_COLLABORATOR_GROUP";
export const QUERY_ALL_COLLABORATOR_GROUPS = "QUERY_ALL_COLLABORATOR_GROUPS";
export const QUERY_ELIGIBILITY_REQUIREMENTS = "QUERY_ELIGIBILITY_REQUIREMENTS";
export const QUERY_ELIGIBILITY_REQUIREMENT = "QUERY_ELIGIBILITY_REQUIREMENT";
export const QUERY_CLIENT_INVOICE = "QUERY_CLIENT_INVOICE";
export const QUERY_CLIENT_INVOICE_FEES = "QUERY_CLIENT_INVOICE_FEES";
export const QUERY_CLIENT_INVOICE_TEMPLATE = "QUERY_CLIENT_INVOICE_TEMPLATE";
export const QUERY_BANK_STATEMENTS_LIST = "QUERY_BANK_STATEMENTS_LIST";
export const QUERY_BANK_STATEMENT = "QUERY_BANK_STATEMENT";
export const QUERY_PAYABLES = "QUERY_PAYABLES";
export const QUERY_PAYABLE = "QUERY_PAYABLE";
export const QUERY_PAYROLL_SETTINGS = "QUERY_PAYROLL_SETTINGS";
export const QUERY_CARD_LIST = "QUERY_CARD_LIST";
export const QUERY_CARD = "QUERY_CARD";
export const QUERY_PAYOUT_SETTINGS = "QUERY_PAYOUT_SETTINGS";
export const QUERY_PAYOUT_SETTINGS_NEW = "QUERY_PAYOUT_SETTINGS_NEW";
export const QUERY_PAYOUT_SETTINGS_DEBIT_CARDS =
  "QUERY_PAYOUT_SETTINGS_DEBIT_CARDS";
export const QUERY_BANK_INSTITUTION = "QUERY_BANK_INSTITUTION";
export const QUERY_COLLABORATOR_DEDUCTIONS = "QUERY_COLLABORATOR_DEDUCTIONS";
export const QUERY_COLLABORATOR_DEDUCTION = "QUERY_COLLABORATOR_DEDUCTION";

export const QUERY_COLLABORATOR_OPEN_PAYABLES =
  "QUERY_COLLABORATOR_OPEN_PAYABLES";

export const QUERY_REPORTS_COLLABORATORS = "QUERY_REPORTS_COLLABORATORS";

export const QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY =
  "QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY";

export const QUERY_REPORTS_PAYROLL = "QUERY_REPORTS_PAYROLL";

export const QUERY_PAYROLL_IMMEDIATE_PAYABLES =
  "QUERY_PAYROLL_IMMEDIATE_PAYABLES";

export const QUERY_REPORTS_OPEN_PAYABLES_AGING =
  "QUERY_REPORTS_OPEN_PAYABLES_AGING";
export const QUERY_REPORTS_OPEN_RECEIVABLE_AGING =
  "QUERY_REPORTS_OPEN_RECEIVABLE_AGING";
export const QUERY_REPORTS_OPEN_LINE_ITEM_AGING =
  "QUERY_REPORTS_OPEN_LINE_ITEM_AGING";

export const CURRENCY_RATE = "CURRENCY_RATE";
