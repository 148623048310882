import { useMemo } from "react";
import { hybridEncrypt } from "@wingspanhq/utils/dist/crypto";
import {
  IBulkBatch,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";

import { useNonContractorXWingspanExpansion } from "../../../shared/hooks/useXWingspanExpansion";
import { pullSessionToken } from "../../../services/sessionStorage";
import { BulkResource } from "../types";
import {
  BULK_IMPORT_CONTRACTORS_NAMESPACE,
  BULK_IMPORT_PAYABLES_NAMESPACE
} from "../constants";
import { selectorIsImpersonatedWingspanAdmin } from "../../../FocusedFeatures/Dashbaord/selectors/selectorIsImpersonatedWingspanAdmin";
import { themeConfig } from "../../../shared/flatfile/themeConfig";
import { getBatchCreatorName } from "../utils/getBatchCreator";
import { useRouteMatch } from "react-router-dom";
import { selectorIsEngagementsActive } from "../../../shared/selectors/selectorIsEngagementsActive";
import { useUserId } from "../../../query/hooks/helpers";
import { useUserProfile } from "../../../query/users/queries";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { IS_DEV_ENV } from "../../../shared/constants/environment";
import { useXWingspanPayerId } from "../../Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export function useFlatfileSpaceConfig(
  bulkBatch: IBulkBatch,
  bulkResource: BulkResource
) {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const queryFeatureFlags = useFeatureFlags();

  const xWingspanExpansion = useNonContractorXWingspanExpansion();
  const xWingspanPayerId = useXWingspanPayerId();

  const spaceProps = useMemo(() => {
    const isImpersonatedWingspanAdmin = selectorIsImpersonatedWingspanAdmin();
    // encrypt the API token for storage in the metadata
    const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY as string;
    const originalPublicKey = publicKey.replace(/\\n/g, "\n");

    const apiTokenEncrypted = hybridEncrypt(
      originalPublicKey,
      pullSessionToken() as string
    );

    let spaceConfig = {
      name: "Payables Upload",
      namespace: BULK_IMPORT_PAYABLES_NAMESPACE
    };
    if (bulkResource === BulkResource.Collaborator) {
      spaceConfig = {
        name: "Contractors Upload",
        namespace: BULK_IMPORT_CONTRACTORS_NAMESPACE
      };
    }

    return {
      ...(bulkBatch?.labels.flatfileBatchId && {
        id: bulkBatch.labels.flatfileBatchId as string
      }),
      config: {
        ...spaceConfig,

        // set batch Id
        // set api token to use it on listener
        metadata: {
          // custom data
          apiToken: apiTokenEncrypted,
          customHeaders: {
            ...(xWingspanExpansion && {
              "x-wingspan-expansion": xWingspanExpansion
            }),
            ...(xWingspanPayerId && { "x-wingspan-user": xWingspanPayerId })
          },
          bulkBatchId,

          isEngagementsEnabled:
            queryUser.data &&
            selectorIsEngagementsActive(queryUser.data) &&
            queryFeatureFlags.data?.flatfileEngagements,
          // user info
          userInfo: {
            // FIXME: https://linear.app/wingspan/issue/WIN-11396
            userId: (bulkBatch as IBulkPayableBatch)?.clientId,
            name: getBatchCreatorName(bulkBatch as IBulkPayableBatch)
          },

          // part of configuration
          sidebarConfig: {
            // show sidebar for impersonated wingspan admin
            showSidebar: isImpersonatedWingspanAdmin || IS_DEV_ENV
          },
          ...themeConfig
        }
      }
    };
  }, [
    bulkBatch,
    bulkBatchId,
    bulkResource,
    queryUser.data,
    queryFeatureFlags.data,
    xWingspanExpansion,
    xWingspanPayerId
  ]);

  return spaceProps;
}
