import {
  useWSModal,
  WSActions,
  WSForm,
  WSInfoBox,
  WSInputText,
  WSInputTextarea,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import isEmpty from "lodash/isEmpty";

import { useUpdateBulkCollaboratorBatch } from "../../query/bulkCollaborator/mutations";
import {
  defaultCollaboratorGroupOption,
  SelectEngagementField
} from "../SelectEngagementField";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

type FormData = {
  name: string;
  notes: string;
};

const ModalNewContractorBatchCreated: React.FC<{
  onClose: () => void;
  onDelete: () => void;
  bulkBatchId: string;
  onAck: () => void;
}> = ({ onClose, onDelete, bulkBatchId, onAck }) => {
  const [selectedCollaboratorGroup, setSelectedCollaboratorGroup] = useState(
    defaultCollaboratorGroupOption
  );
  const [selectedEngagement, setSelectedEngagement] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [updateBulkCollaboratorBatch, updateBulkCollaboratorBatchMeta] =
    useUpdateBulkCollaboratorBatch();

  const onSelectEngagement = (
    option: { label: string; value: string } | null
  ) => {
    setSelectedEngagement(option);
  };

  const onSelectCollaboratorGroup = (option: {
    label: string;
    value: string;
  }) => {
    setSelectedCollaboratorGroup(option);
  };

  const handleSubmit = async (data: FormData) => {
    const labels = {
      ...(selectedEngagement?.value && {
        engagementId: selectedEngagement?.value
      }),
      ...(selectedCollaboratorGroup.value !==
        defaultCollaboratorGroupOption.value && {
        collaboratorGroupId: selectedCollaboratorGroup.value
      }),

      [BATCH_LABELS_WS_KEYS.bulkBatchName]: data.name,
      [BATCH_LABELS_WS_KEYS.bulkBatchNotes]: data.notes
    };
    if (!isEmpty(labels)) {
      await updateBulkCollaboratorBatch(
        {
          id: bulkBatchId,
          data: {
            labels
          }
        },
        {
          onSuccess: () => {
            onClose();
            onAck();
          }
        }
      );
    } else {
      onClose();
      onAck();
    }
  };

  const onCancel = () => {
    onClose();
    onDelete();
  };

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm weight="book" color="gray600">
        A new batch was created and ready for your formatted spreadsheet to be
        uploaded. Optionally, select an engagement for the batch, if applicable:
      </WSText.ParagraphSm>
      <WSForm<FormData>
        defaultValues={{
          name: "",
          notes: ""
        }}
        onSubmit={handleSubmit}
      >
        <WSList gap="XL">
          <WSForm.Field
            name="name"
            label="Batch name (optional)"
            component={WSInputText}
            componentProps={{
              placeholder: "Enter name for the import batch"
            }}
          />
          <WSForm.Field
            name="notes"
            label="Notes (optional)"
            component={WSInputTextarea}
            componentProps={{
              placeholder: "Provide notes for your reference",
              helperText: "Provide details about the batch for your reference"
            }}
          />

          <WSInfoBox>
            <WSText.ParagraphSm weight="book" color="gray600">
              Tip: adding a name and notes can be helpful when filtering by
              batches in the contractor list and help identify the purpose of an
              imported batch for your own reference.
            </WSText.ParagraphSm>
          </WSInfoBox>

          <SelectEngagementField
            selectedEngagementId={selectedEngagement?.value || ""}
            onSelectEngagement={onSelectEngagement}
            supportCollaboratorGroups={true}
            selectedCollaboratorGroupId={selectedCollaboratorGroup.value}
            onSelectCollaboratorGroup={onSelectCollaboratorGroup}
          />

          <WSInfoBox>
            <p>Reminders:</p>
            <ul>
              <li>An example template is available for download</li>
              <li>
                If you created the batch by accident or are not ready to upload
                the spreadsheet yet, you can delete the batch.
              </li>
            </ul>
          </WSInfoBox>

          <WSErrorMessage
            contextKey={ErrorContextKey.BulkBatchUpdate}
            error={updateBulkCollaboratorBatchMeta.error}
          />

          <WSActions
            alignment="fill"
            sortButtons={false}
            buttons={[
              {
                destructive: true,
                kind: "Secondary",
                label: "Cancel & delete batch",
                onClick: onCancel
              },
              {
                label: "Continue",
                type: "submit",
                loading: updateBulkCollaboratorBatchMeta.isLoading
              }
            ]}
          />
        </WSList>
      </WSForm>
    </WSList>
  );
};

export const useModalNewContractorBatchCreated = () =>
  useWSModal(ModalNewContractorBatchCreated, {
    title: "New batch created",
    size: "S",
    showCloseIcon: false
  });
