import {
  IBalanceBaseResponse,
  ICashbackPromotionStatusResponse,
  IRedeemCashbackBalanceRequest,
  IRedeemCashbackBalanceResponse
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  IPendingTransactionCreate,
  IPendingTransactionResponse,
  IPendingTransactionUpdate
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { IActivity } from "@wingspanhq/users";
import { WSMutationsConfig, WSQueryCache } from "@ws-react-query";
import bookkeepingService, {
  CreateTransactionData,
  UpdateTransactionData
} from "../../services/bookkeeping";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSMutation } from "../helpers";
import {
  QUERY_BOOKKEEPING_BANKING_BALANCE,
  QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
  QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
  QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
  QUERY_BOOKKEEPING_STATUS,
  QUERY_BOOKKEEPING_TRANSACTION,
  QUERY_BOOKKEEPING_TRANSACTIONS
} from "./keys";
import { QUERY_CASHBACK_PROMOTION } from "../cards/queries/useCashbackPromotionQuery";

export const useSetBookkeepingEnable = (
  config?: WSMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation((userId: string) => bookkeepingService.enable(userId), {
    dependencies: [QUERY_BOOKKEEPING_STATUS],
    ...config
  });

export const useCreateTransaction = (
  config?: WSMutationsConfig<ITransaction, WSServiceError>
) =>
  useWSMutation(
    async (request: CreateTransactionData) =>
      await bookkeepingService.createTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ],
      ...config
    }
  );

export const useUpdateTransaction = (
  config?: WSMutationsConfig<ITransaction, WSServiceError>
) =>
  useWSMutation(
    async (request: UpdateTransactionData) =>
      await bookkeepingService.updateTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_TRANSACTION,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ],
      ...config
    }
  );

export const useDeleteTransaction = (
  config?: WSMutationsConfig<any, WSServiceError>
) =>
  useWSMutation(
    async (transactionId: string) =>
      await bookkeepingService.deleteTransaction(transactionId),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ],
      onSuccess(data, transactionId) {
        WSQueryCache.removeQueries([
          QUERY_BOOKKEEPING_TRANSACTION,
          transactionId
        ]);
      },
      ...config
    }
  );

export const useCreateBankingPendingTransaction = (
  config?: WSMutationsConfig<
    IPendingTransactionResponse,
    WSServiceError,
    IPendingTransactionCreate
  >
) =>
  useWSMutation(
    async (request: IPendingTransactionCreate) =>
      await bookkeepingService.createPendingBankingTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
        QUERY_BOOKKEEPING_BANKING_BALANCE,
        QUERY_BOOKKEEPING_TRANSACTIONS
      ],
      ...config
    }
  );

export const useUpdateBankingPendingTransaction = (
  config?: WSMutationsConfig<
    IPendingTransactionResponse,
    WSServiceError,
    IPendingTransactionUpdate & { id: string }
  >
) =>
  useWSMutation(
    async (request: IPendingTransactionUpdate & { id: string }) =>
      await bookkeepingService.updatePendingBankingTransaction(
        request.id,
        request
      ),
    {
      dependencies: [
        QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
        QUERY_BOOKKEEPING_BANKING_BALANCE
      ],
      ...config
    }
  );

export const useRedeemCashbackBalance = (
  config?: WSMutationsConfig<
    IRedeemCashbackBalanceResponse,
    WSServiceError,
    IRedeemCashbackBalanceRequest
  >
) =>
  useWSMutation(
    async (request: IRedeemCashbackBalanceRequest) => {
      const postedCashbackBalance =
        await bookkeepingService.getPostedCashbackBalance();
      const redeemAmount =
        request.amountToRedeem || postedCashbackBalance.postedCashbackBalance;

      const redeemResponse = await bookkeepingService.redeemCashbackBalance(
        request
      );

      // Do an optimistic update of banking balance
      const preRedeemBalance = WSQueryCache.getQueryData<IBalanceBaseResponse>(
        QUERY_BOOKKEEPING_BANKING_BALANCE
      );
      if (preRedeemBalance) {
        WSQueryCache.setQueryData(QUERY_BOOKKEEPING_BANKING_BALANCE, {
          ...preRedeemBalance,
          available: (preRedeemBalance.available || 0) + redeemAmount,
          balance: (preRedeemBalance.balance || 0) + redeemAmount
        });
      }

      return redeemResponse;
    },
    {
      dependencies: [
        QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
        QUERY_BOOKKEEPING_TRANSACTIONS
      ],
      ...config
    }
  );

export const useRedeemCashbackPromotion = (
  config?: WSMutationsConfig<ICashbackPromotionStatusResponse, WSServiceError>
) =>
  useWSMutation(bookkeepingService.redeemCashbackPromotion, {
    dependencies: [QUERY_CASHBACK_PROMOTION],
    ...config
  });
