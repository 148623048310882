import {
  useIsDesktop,
  WSButton,
  WSElement,
  WSEmptyState,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";

import { useHistory, useRouteMatch } from "react-router-dom";
import { openIntercom } from "../../../../shared/utils/intercom";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { getCorrectionType } from "../../../TaxCorrections/utils/getCorrectionType";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import {
  CORRECTION_ADDRESS,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { createRequestCorrectionPayload } from "../../../TaxCorrections/utils/createRequestCorrectionPayload";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useTaxFormRequestCorrection } from "../../../../query/taxForm/mutations/useTaxFormRequestCorrection";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { selectorPayeeTaxFormSupportContacts } from "../../selectors/selectorPayeeTaxFormSupportContacts";
import { useGoToTaxDocumentsDashboard } from "../../paths";
import { SetupRequestCorrectionFlowProps } from "./RequestCorrectionFlow";
import { TaxDocumentsDetailsFAQ } from "../../components/TaxDocumentsDetailsFAQ";
import { useGotoAccountInfo } from "../../../Settings/paths";

export const RouteRequestCorrectionStatus: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const gotoTaxDocumentsPage = useGoToTaxDocumentsDashboard();
  const history = useHistory<{
    isCorrectionRequestFailed: boolean;
    canTryAgain: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;

  const isCorrectionRequestFailed =
    history.location.state?.isCorrectionRequestFailed;
  const canTryAgain = history.location.state?.canTryAgain;
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;

  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();

  const isDesktop = useIsDesktop();

  const goToAccountSettings = useGotoAccountInfo();

  const tryAgainCorrectionRequest = async () => {
    await requestCorrection({
      taxFormId,
      requestData: createRequestCorrectionPayload(
        correctionsFormData,
        queryTaxForm?.data as IPayeeTaxFormResponse
      )
    });
  };
  return (
    <EditLayout
      noBack
      title="Request correction"
      contentRight={<TaxDocumentsDetailsFAQ mb="2XL" />}
    >
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const correctionTypeData = getCorrectionType(
            taxForm,
            correctionsFormData
          );
          const isAddressStateNotChanged =
            correctionTypeData.corrections.length === 1 &&
            correctionTypeData.corrections.includes(CORRECTION_ADDRESS) &&
            correctionTypeData.addressStateNotChanged;
          const { supportEmail, supportPhone } =
            selectorPayeeTaxFormSupportContacts(taxForm);

          return (
            <WSElement mr={isDesktop ? "3XL" : "NONE"}>
              <WSPanel mb="2XL">
                {isCorrectionRequestFailed || requestCorrectionMeta.isError ? (
                  <>
                    <WSEmptyState
                      mb="2XL"
                      title="There was an error submitting the correction request"
                      description={
                        canTryAgain
                          ? "Try again and if the issue persists, please contact support and note down the corrections you were attempting to make."
                          : "Please contact support and note down the corrections you were attempting to make."
                      }
                      orientation="horizontal"
                      hasBorder={false}
                      type={canTryAgain ? "noInternetConnection" : "error"}
                      buttons={[
                        {
                          label: canTryAgain ? "Try again" : "Contact support",
                          onClick: canTryAgain
                            ? tryAgainCorrectionRequest
                            : openIntercom,
                          kind: "Secondary",
                          loading:
                            canTryAgain && requestCorrectionMeta.isLoading
                        }
                      ]}
                    />
                  </>
                ) : (
                  // FIX: WSEmptyState is not rendering properly because of Layout grid
                  // need to fix the layout when rendering Flow component
                  <WSEmptyState
                    mb="2XL"
                    title="Correction request successfully submitted"
                    description={
                      "Your correction request has been submitted to payer. They will review the request and it is their discretion as to whether to file a correction. You will be notified when they accept or reject your correction request."
                    }
                    orientation="horizontal"
                    hasBorder={false}
                    type="done"
                  />
                )}

                <WSElement colorBackground="gray50" px="XL" py="L">
                  <WSText.ParagraphSm color="gray500" weight="medium">
                    Payer support contact:
                  </WSText.ParagraphSm>

                  <WSText.ParagraphSm color="gray500" weight="book">
                    Email: {supportEmail}
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm color="gray500" weight="book">
                    Phone: {supportPhone}
                  </WSText.ParagraphSm>
                </WSElement>

                <WSErrorMessage
                  mb="XL"
                  contextKey={ErrorContextKey.NEC1099RequestCorrection}
                  error={requestCorrectionMeta.error}
                />
              </WSPanel>

              {correctionTypeData.corrections.includes(
                CORRECTION_TIN_OR_NAME
              ) ||
              correctionTypeData.corrections.includes(CORRECTION_ADDRESS) ? (
                <WSPanel>
                  <WSElement colorBackground="gray50" px="XL" py="2XL">
                    <WSText.ParagraphSm color="gray600" weight="medium" mb="XL">
                      Would you like to update your Wingspan profile?
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm
                      color="gray600"
                      weight="book"
                      inline
                      mb="XL"
                    >
                      Updates on your profile{" "}
                      <WSText.ParagraphSm
                        color="gray600"
                        weight="medium"
                        inline
                      >
                        occur separately from tax filings
                      </WSText.ParagraphSm>{" "}
                      to ensure the most up-to-date information is reflected in
                      Wingspan. These changes do not need to be accepted by the
                      payer to be updated on your profile. To update your
                      information, go to your account settings page.
                    </WSText.ParagraphSm>

                    <WSButton.Secondary
                      my="M"
                      fullWidth
                      onClick={goToAccountSettings}
                    >
                      Go to account settings
                    </WSButton.Secondary>
                  </WSElement>
                </WSPanel>
              ) : null}

              <WSButton.Primary
                mt="2XL"
                fullWidth
                onClick={gotoTaxDocumentsPage}
              >
                Go to Tax Documents
              </WSButton.Primary>
            </WSElement>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
