import {
  useIsMobile,
  WSElement,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import queryString from "qs";

import { Tabs } from "../../../../components/Tabs";
import styles from "./styles.module.scss";
import { BulkImportPayables } from "../BulkImportPayables";
import { BulkImportCollaborators } from "../BulkImportCollaborators";

import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";
import {
  BULK_IMPORT_CONTRACTORS_BATCHES,
  BULK_IMPORT_PAYABLES_BATCHES,
  useGoToContractorImportStart,
  useGoToPayablesImportStart
} from "../../paths";
import { FilterSelectYear } from "../../components/FilterSelectYear";
import { useUrlQueryFilters } from "../../../../utils/router";
import { BulkBatchesFilters } from "../../services/types";

export function BulkImports() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const queries: { year?: string } = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const { filters } = useUrlQueryFilters<BulkBatchesFilters>({
    year: queries.year
  });
  const { hasPaymentsScope, hasCollaboratorsScope } =
    useAuthorizedScopeGroups();

  const goToContractorImportStart = useGoToContractorImportStart();
  const goToPayablesImportStart = useGoToPayablesImportStart();

  // TODO

  return (
    <WSPage>
      <WSPageToolbar
        title="Bulk imports"
        titleAfter={<FilterSelectYear defaultYear={Number(filters.year)} />}
        actions={[
          {
            hidden: !hasPaymentsScope,
            name: "importPayables",
            buttonKind: "Primary",
            label: "Import payables & deductions",
            onClick: () => {
              goToPayablesImportStart();
            }
          },
          {
            hidden: !hasCollaboratorsScope,
            name: "importContractors",
            label: "Import contractors",
            buttonKind: "Secondary",
            onClick: () => {
              goToContractorImportStart();
            }
          }
        ]}
      />

      <Tabs
        tabs={[
          ...(hasPaymentsScope
            ? [
                {
                  path: BULK_IMPORT_PAYABLES_BATCHES,
                  label: "Payables & deductions",
                  params: { year: filters.year }
                }
              ]
            : []),
          ...(hasCollaboratorsScope
            ? [
                {
                  path: BULK_IMPORT_CONTRACTORS_BATCHES,
                  label: "Contractors",
                  params: { year: filters.year }
                }
              ]
            : [])
        ]}
      />
      <WSElement mt={isMobile ? "XL" : "L"} className={styles.wrapper}>
        <Switch>
          {hasPaymentsScope ? (
            <Route
              path={BULK_IMPORT_PAYABLES_BATCHES}
              component={BulkImportPayables}
            />
          ) : null}
          {hasCollaboratorsScope ? (
            <Route
              path={BULK_IMPORT_CONTRACTORS_BATCHES}
              component={BulkImportCollaborators}
            />
          ) : null}
          <Redirect
            from="*"
            to={`${BULK_IMPORT_PAYABLES_BATCHES}?year=${new Date().getFullYear()}`}
          />
        </Switch>
      </WSElement>
    </WSPage>
  );
}
