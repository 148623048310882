import { useMemo } from "react";
import {
  WSAvatarProps,
  WSButton,
  WSCard,
  WSInfoBox,
  WSText,
  WSTimeline,
  WSTimelineItemProps
} from "@wingspanhq/fe-component-library";
import { formatNumber } from "accounting";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { INewUser, ISharedUser } from "@wingspanhq/users";
import { IBulkBatchEvents } from "@wingspanhq/payments/dist/interfaces";
import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";

import {
  BulkBatchEventDriver,
  BulkBatchEventType,
  BulkImportResource,
  BulkResource,
  IBulkBatchEvent
} from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import {
  useGoToContractorBatchEventHistory,
  useGoToPayableBatchEventHistory
} from "../../paths";
import { useRouteMatch } from "react-router-dom";
import {
  isBatchFullyImported,
  isBatchPartiallyImported
} from "../../utils/bulkBatchUtils";

type Props = {
  bulkBatch: IBulkBatch;
  bulkResource: BulkImportResource;
  showDetailedContent?: boolean;
};
const RECENT_HISTORY_ITEMS_COUNT = 4;
const labels = {
  [BulkResource.Payable]: "Payables",
  [BulkResource.Collaborator]: "Contractors"
};
export const BulkBatchEventHistoryWidget: React.FC<Props> = ({
  bulkBatch,
  bulkResource,
  showDetailedContent = false
}) => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const gotoPayableBatchHistory = useGoToPayableBatchEventHistory();
  const gotoContractorBatchHistory = useGoToContractorBatchEventHistory();

  const onClickViewAll = () => {
    if (bulkResource === BulkResource.Payable) {
      gotoPayableBatchHistory(bulkBatchId);
    } else {
      gotoContractorBatchHistory(bulkBatchId);
    }
  };

  const timelineItems = useMemo(() => {
    if (!bulkBatch) {
      return [];
    }

    const timelineItems = getTimelineItems(bulkBatch, bulkResource, {
      showDetailedContent
    });
    const recentItems = timelineItems.slice(0, RECENT_HISTORY_ITEMS_COUNT);

    if (timelineItems.length > RECENT_HISTORY_ITEMS_COUNT) {
      recentItems.push({
        content: (
          <WSButton.Link onClick={onClickViewAll}>
            View all history and details
          </WSButton.Link>
        )
      });
    }

    return recentItems;
  }, [bulkBatchId, bulkBatch, bulkResource, showDetailedContent]);
  return (
    <WSCard
      header={{
        label: {
          text: "Recent history"
        },
        value: {
          ...(timelineItems.length > 0 && {
            action: {
              label: "View all",
              onClick: onClickViewAll
            }
          })
        }
      }}
      divider
    >
      {timelineItems.length === 0 ? (
        <WSText.ParagraphSm color="gray400" align="center">
          No history available
        </WSText.ParagraphSm>
      ) : (
        <WSTimeline items={timelineItems} />
      )}
    </WSCard>
  );
};

const getBadges = (event: IBulkBatchEvent, bulkBatch: IBulkBatch) => {
  switch (event.triggeredBy) {
    case BulkBatchEventDriver.System:
      return ["Automated"];
    case BulkBatchEventDriver.Payer:
      return [getEventActorName(event)];
    default:
      return ["Unknown"];
  }
};

const getAvatar = (
  event: IBulkBatchEvent,
  bulkBatch: IBulkBatch
): WSAvatarProps => {
  switch (event.triggeredBy) {
    case BulkBatchEventDriver.System:
      return {
        type: "icon",
        icon: "info-circle"
      };
    case BulkBatchEventDriver.Payer:
      return {
        type: "text",
        text: getEventActorName(event)
      };
    default:
      return {
        type: "icon",
        icon: "info-circle"
      };
  }
};

const getEventActorName = (event: IBulkBatchEvent) => {
  if (event.eventActor) {
    const names = wsName({
      user: event.eventActor as INewUser
    });
    return names.getResolvedName() || "Payer";
  }
  return "Payer";
};

const SeeMoreButton: React.FC<{
  bulkResource: BulkImportResource;
  label?: string;
}> = ({ bulkResource, label = "see more" }) => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const gotoPayableBatchHistory = useGoToPayableBatchEventHistory();
  const gotoContractorBatchHistory = useGoToContractorBatchEventHistory();
  const onClick = () => {
    if (bulkResource === BulkResource.Payable) {
      gotoPayableBatchHistory(bulkBatchId);
    } else {
      gotoContractorBatchHistory(bulkBatchId);
    }
  };
  return <WSButton.Link onClick={onClick}>{label}</WSButton.Link>;
};

function getBatchProcessedTimelineContent(
  bulkBatch: IBulkBatch,
  bulkResource: BulkImportResource,
  showDetailedContent: boolean
) {
  let content = null;
  let briefContent = null;
  const itemsProcessed = bulkBatch.statistics?.itemsProcessed || 0;
  const itemsFailed = bulkBatch.statistics?.itemsFailed || 0;
  const itemsTotal = bulkBatch.statistics?.itemsTotal || 0;

  const formattedItemsProcessed = formatNumber(itemsProcessed);
  const formattedItemsFailed = formatNumber(itemsFailed);
  const formattedItemsTotal = itemsTotal
    ? formatNumber(itemsTotal)
    : BulkStatus.Complete
    ? formattedItemsProcessed
    : formattedItemsFailed;
  if (isBatchFullyImported(bulkBatch)) {
    content = (
      <>
        <WSText.ParagraphSm>{labels[bulkResource]} imported</WSText.ParagraphSm>
        <WSInfoBox>
          <ul>
            <li>
              {formattedItemsProcessed} of {formattedItemsTotal} imported
            </li>
          </ul>
        </WSInfoBox>
      </>
    );
    briefContent = (
      <>
        <WSText.ParagraphSm inline>
          {labels[bulkResource]} imported
        </WSText.ParagraphSm>
        <SeeMoreButton bulkResource={bulkResource} />
      </>
    );
  } else if (isBatchPartiallyImported(bulkBatch)) {
    content = (
      <>
        <WSText.ParagraphSm>
          {labels[bulkResource]} imported partially
        </WSText.ParagraphSm>
        <WSInfoBox>
          <ul>
            <li>
              {formattedItemsProcessed} of {formattedItemsTotal} imported
            </li>
            <li>
              {formattedItemsFailed} of {formattedItemsTotal} failed
            </li>
          </ul>
        </WSInfoBox>
      </>
    );
    briefContent = (
      <>
        <WSText.ParagraphSm inline>
          {labels[bulkResource]} imported partially
        </WSText.ParagraphSm>
        <SeeMoreButton bulkResource={bulkResource} />
      </>
    );
  } else if (bulkBatch.status === BulkStatus.Failed) {
    content = (
      <>
        <WSText.ParagraphSm>
          {labels[bulkResource]} import failed
        </WSText.ParagraphSm>
        <WSInfoBox>
          <ul>
            <li>
              {formattedItemsFailed} of {formattedItemsTotal} failed
            </li>
          </ul>
        </WSInfoBox>
      </>
    );
    briefContent = (
      <>
        <WSText.ParagraphSm inline>
          {labels[bulkResource]} imported failed
        </WSText.ParagraphSm>
        <SeeMoreButton bulkResource={bulkResource} />
      </>
    );
  }
  return showDetailedContent ? content : briefContent;
}

const getContent = (
  event: IBulkBatchEvent,
  bulkBatch: IBulkBatch,
  bulkResource: BulkImportResource,
  options: { showDetailedContent?: boolean } = {}
): WSTimelineItemProps["content"] => {
  switch (event.eventType) {
    case BulkBatchEventType.BatchCreated:
      return options.showDetailedContent ? (
        <>
          <WSText.ParagraphSm mb="S">Batch created</WSText.ParagraphSm>
          <WSInfoBox>
            <ul>
              <li>
                Created by <b>{getEventActorName(event)}</b>
              </li>
            </ul>
          </WSInfoBox>
        </>
      ) : (
        <>
          <WSText.ParagraphSm inline>Batch created</WSText.ParagraphSm>
          <SeeMoreButton bulkResource={bulkResource} />
        </>
      );
    case BulkBatchEventType.UploadStarted:
      return options.showDetailedContent ? (
        <>
          <WSText.ParagraphSm mb="S">
            Spreadsheet upload started
          </WSText.ParagraphSm>
          <WSInfoBox>
            <ul>
              <li>
                {getUploadedFilename(bulkBatch, bulkResource)} is uploaded.
              </li>
              <li>
                Upload started by <b>{getEventActorName(event)}</b>
              </li>
            </ul>
          </WSInfoBox>
        </>
      ) : (
        <>
          <WSText.ParagraphSm inline>Upload started</WSText.ParagraphSm>
          <SeeMoreButton bulkResource={bulkResource} />
        </>
      );
    case BulkBatchEventType.UploadCompleted:
      return options.showDetailedContent ? (
        <>
          <WSText.ParagraphSm mb="S">
            Spreadsheet upload completed
          </WSText.ParagraphSm>
          <WSInfoBox>
            <ul>
              <li>
                {getUploadedFilename(bulkBatch, bulkResource)} is uploaded.
              </li>
              <li>Upload completed</li>
            </ul>
          </WSInfoBox>
        </>
      ) : (
        <>
          <WSText.ParagraphSm inline>Upload completed</WSText.ParagraphSm>
          <SeeMoreButton bulkResource={bulkResource} />
        </>
      );
    case BulkBatchEventType.ImportStarted:
      return options.showDetailedContent ? (
        <>
          <WSText.ParagraphSm mb="S">
            {labels[bulkResource]} import started
          </WSText.ParagraphSm>
          <WSInfoBox>
            <ul>
              <li>
                {getUploadedFilename(bulkBatch, bulkResource)} is uploaded
              </li>
              <li>
                {getUploadedFilename(bulkBatch, bulkResource)} import initiated
                by <b>{getEventActorName(event)}</b>
              </li>
            </ul>
          </WSInfoBox>
        </>
      ) : (
        <>
          <WSText.ParagraphSm inline>Import started</WSText.ParagraphSm>
          <SeeMoreButton bulkResource={bulkResource} />
        </>
      );
    case BulkBatchEventType.ImportCompleted:
      return getBatchProcessedTimelineContent(
        bulkBatch,
        bulkResource,
        !!options.showDetailedContent
      );

    case BulkBatchEventType.BatchInfoUpdated:
      return options.showDetailedContent ? (
        <>
          <WSText.ParagraphSm mb="S">Batch updated</WSText.ParagraphSm>
          <WSInfoBox>
            <ul>
              <li>
                Name and notes updated by <b>{getEventActorName(event)}</b>
              </li>
            </ul>
          </WSInfoBox>
        </>
      ) : (
        <>
          <WSText.ParagraphSm inline>Name and notes updated</WSText.ParagraphSm>
          <SeeMoreButton bulkResource={bulkResource} />
        </>
      );
    default:
      return null;
  }
};

export function getTimelineItems(
  bulkBatch: IBulkBatch,
  bulkResource: BulkImportResource,
  options: { showDetailedContent?: boolean } = {}
) {
  const eventHistory = prepareEventHistory(bulkBatch);

  return eventHistory
    .map(eventHistory =>
      getTimelineItem(eventHistory, bulkBatch, bulkResource, options)
    )
    .filter(Boolean);
}

export function getTimelineItem(
  bulkBatchEvent: IBulkBatchEvent,
  bulkBatch: IBulkBatch,
  bulkResource: BulkImportResource,
  options: { showDetailedContent?: boolean } = {}
): WSTimelineItemProps {
  return {
    date: bulkBatchEvent.timestamp,
    content: getContent(bulkBatchEvent, bulkBatch, bulkResource, options),
    badges: getBadges(bulkBatchEvent, bulkBatch),
    avatar: getAvatar(bulkBatchEvent, bulkBatch)
  };
}

const getEventName = (eventAtKey: string) => {
  const name = eventAtKey.replace(/At$/, "");
  return (name.charAt(0).toUpperCase() + name.slice(1)) as BulkBatchEventType;
};

const getEventActorKey = (eventName: string) => eventName.replace(/At$/, "By");

// This function takes the bulkBatch and prepares the event history
// from events and eventActors
function prepareEventHistory(bulkBatch: IBulkBatch) {
  if (!bulkBatch) {
    return [];
  }
  const eventsList = Object.keys(bulkBatch.events || {}).map(key => {
    let eventActor = bulkBatch.eventActors[getEventActorKey(key)] || null;

    // special case for batchCreatedAt event
    if (key === "batchCreatedAt" && !eventActor) {
      eventActor = bulkBatch.eventActors.createdBy;
    }
    return {
      eventType: getEventName(key),
      timestamp: bulkBatch.events?.[key as keyof IBulkBatchEvents] as Date,
      eventActor: eventActor as unknown as ISharedUser,
      triggeredBy: eventActor
        ? BulkBatchEventDriver.Payer
        : BulkBatchEventDriver.System
    };
  });

  // sort the events recent first
  eventsList.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  return eventsList;
}
