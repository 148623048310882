import { Route, Switch } from "react-router-dom";
import { TeamIndex } from "../../TeamV2/routes";

export const TeamsIndex = () => {
  return (
    <Switch>
      <Route path="/member/settings/team" component={TeamIndex} />
    </Switch>
  );
};
