import { get, useFormContext } from "react-hook-form";
import { SelectPayeeEngagement } from ".";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { useState } from "react";
import { selectorPayeeEngagementsTmp } from "../../selectors/selectorPayeeEngagementsTmp";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { selectorPayerPayeeEngagements } from "../../selectors/selectorPayerPayeeEngagements";

type Props = {
  name: string;
  disabled?: boolean;
  preselectedEmail?: string;
};

export const FormFieldSelectPayeeEngagement: React.FC<Props> = ({
  name,
  disabled,
  preselectedEmail
}) => {
  const { setValue, errors, clearErrors, watch, register } = useFormContext();

  const namePayeeId = `${name}.payeeId`;
  const namePayerPayeeEngagementId = `${name}.payerPayeeEngagementId`;
  const isEngagementsActive = useIsEngagementsActive();

  const payeeId = watch(namePayeeId);
  const payerPayeeEngagementId = watch(namePayerPayeeEngagementId);

  const [autoFocus, setAutoFocus] = useState(false);

  usePayeeRowsQuery(
    {
      filter: {
        searchString: preselectedEmail
      }
    },
    {
      // TODO: why it's not working?
      enabled: !!preselectedEmail,
      onSuccess: result => {
        if (!preselectedEmail) {
          return;
        }

        const payeeRows = result.pages.flat();

        const payee = payeeRows.find(
          payee => payee.user.email === preselectedEmail
        );
        const engagements = payee?.engagements || [];

        const filteredEngagements = isEngagementsActive
          ? selectorPayerPayeeEngagements(engagements, true)
          : selectorPayeeEngagementsTmp(engagements, false, true, false);

        if (payee && filteredEngagements.length === 1) {
          setAutoFocus(false);
          setValue(namePayeeId, payee.payeeId);
          setValue(
            namePayerPayeeEngagementId,
            filteredEngagements[0].payerPayeeEngagementId
          );
        } else {
          setAutoFocus(true);
        }
      }
    },
    true
  );

  return (
    <>
      <input type="hidden" name={namePayeeId} ref={register()} />
      <input type="hidden" name={namePayerPayeeEngagementId} ref={register()} />

      <SelectPayeeEngagement
        autoFocus={autoFocus}
        defaultSearchText={preselectedEmail}
        disabled={disabled}
        value={
          payeeId && payerPayeeEngagementId
            ? { payeeId, payerPayeeEngagementId }
            : null
        }
        onChange={newValue => {
          if (newValue) {
            clearErrors(name);
            setValue(namePayeeId, newValue.payeeId);
            setValue(
              namePayerPayeeEngagementId,
              newValue.payerPayeeEngagementId
            );
          } else {
            setValue(namePayeeId, null);
            setValue(namePayerPayeeEngagementId, null);
          }
        }}
        status={get(errors, namePayerPayeeEngagementId) ? "error" : undefined}
        message={get(errors, namePayerPayeeEngagementId)?.message}
      />
    </>
  );
};
