import { v4 as uuidv4 } from "uuid";

const ANONYMOUS_STAMP_KEY = "ws-anon-stamp";

export const getAnonymousStamp = () => {
  let stamp = window.localStorage.getItem(ANONYMOUS_STAMP_KEY);
  if (!stamp) {
    stamp = uuidv4();
    window.localStorage.setItem(ANONYMOUS_STAMP_KEY, stamp);
  }
  return stamp;
};
