import React from "react";
import { WSModalOldProvider } from "../components/common/WSModalOld/WSModalOldContext";
import { WSSnackbarProvider } from "../components/WSSnackbar/WSSnackbar.component";
import { UseWSModalProvider } from "../components/WSModal/WSModal.context";

export const WSProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => (
  <WSSnackbarProvider>
    <WSModalOldProvider>
      <UseWSModalProvider>{children}</UseWSModalProvider>
    </WSModalOldProvider>
  </WSSnackbarProvider>
);
