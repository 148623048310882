import {
  useWSModal,
  WSButton,
  WSGrid,
  WSInfoBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { ContractorColumnsStackedView } from "../ContractorColumnsStackedView";

const ModalContractorColumnsGuide: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <>
      <WSGrid gutter="XL">
        <WSGrid.Item span={{ xs: "12", m: "6" }}>
          <WSInfoBox my="L">
            <b>Adding new contractors:</b>
            <ul>
              <li>
                Newly created contractors automatically receive an invitation
                email to sign-up for Wingspan.
              </li>
              <li>
                Optional fields capture more information when adding
                contractors.
              </li>
              <li>
                Don't worry if your spreadsheet has other details, but please
                make sure it contains the required columns.
              </li>
            </ul>

            <ContractorColumnsStackedView my="L" mx="XL" />

            <b>Updating existing contractors:</b>
            <ul>
              <li>
                Identify the contractor: provide at least one of Wingspan user
                ID, External ID, or Email.
              </li>
              <li>
                Field overwrites: columns you include overwrite existing values.
              </li>
              <li>
                Archived contractors: if an identifier matches an archived
                contractor, that contractor is reactivated with no new
                invitation sent.
              </li>
            </ul>

            <b>Engagements (optional):</b>
            <ul>
              <li>
                Specify an active engagement to add contractors (new or
                existing) to upon upload.
              </li>
              <li>
                If a contractor is already in that engagement, no duplicate
                record is created.
              </li>
            </ul>
          </WSInfoBox>
        </WSGrid.Item>
        <WSGrid.Item span={{ xs: "12", m: "6" }}>
          <WSInfoBox my="L">
            <b>Conflicting Identifiers</b>
            <WSText.ParagraphSm weight="book" color="gray600" mb="L">
              If multiple identifiers (e.g., Email, External ID, Wingspan user
              ID) appear in the same row, they must match the same contractor or
              the row will be rejected.
            </WSText.ParagraphSm>

            <b>All acceptable columns</b>
            <WSText.ParagraphSm weight="book" color="gray600" mb="L">
              Below is a summary of the columns you can include:
            </WSText.ParagraphSm>

            <ol>
              <li>
                <b>Identifiers (at least one required)</b>
                <ul>
                  <li>Email</li>
                  <li>
                    External ID (your organization’s unique contractor
                    reference)
                  </li>
                  <li>Wingspan user ID (internal system ID)</li>
                </ul>
              </li>
              <li>
                <b>Basic information</b>
                <ul>
                  <li>First Name (required for new contractors)</li>
                  <li>Last Name (required for new contractors)</li>
                  <li>Business Name (optional)</li>
                  <li>Phone number (optional)</li>
                </ul>
              </li>
              <li>
                <b>Custom fields (optional)</b>
                <ul>
                  <li>(Your list of custom fields)</li>
                </ul>
              </li>
              <li>
                <b>U.S. tax information (W-9 fields)</b>
                <ul>
                  <li>SSN or EIN (only one)</li>
                  <li>Legal Name (Line 1), Business Name (Line 2)</li>
                  <li>
                    Address (Line 1, Line 2, City, State, Postal Code, Country)
                  </li>
                  <li>Company Structure (if EIN is provided)</li>
                </ul>
              </li>
              <li>
                <b>Engagement</b>
                <ul>
                  <li>
                    Specify the name of one active engagement to assign
                    contractors to.
                  </li>
                </ul>
              </li>
              <li>
                <b>Organization Account ID</b>
                <ul>
                  <li>
                    If you manage multiple Wingspan organizations, specify the
                    organization to place the contractor in.
                  </li>
                </ul>
              </li>
            </ol>
          </WSInfoBox>
        </WSGrid.Item>
      </WSGrid>

      <WSButton fullWidth onClick={onClose}>
        Done
      </WSButton>
    </>
  );
};

export const useModalContractorColumnsGuide = () =>
  useWSModal(ModalContractorColumnsGuide, {
    title: "Contractors spreadsheet guide",
    size: "L"
  });
