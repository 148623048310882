import {
  IPendingTransactionResponse,
  PendingTransactionSubType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import {
  toWSDateString,
  toWSMoneyString,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import sortBy from "lodash/sortBy";
import React from "react";
import { IAccount } from "@wingspanhq/users";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";

type MappedPendingTransaction = {
  source: IPendingTransactionResponse | ITransaction;
  account?: IAccount;
  description: string;
  amount: number;
  name: string;
  status: string;
  date: Date;
};

export const TablePendingTransactions: React.FC<{
  pendingTransactions: (ITransaction | IPendingTransactionResponse)[];
  accounts: IAccount[];
}> = ({ pendingTransactions, accounts }) => {
  const queryFeatureFlags = useFeatureFlags();
  const featureFlags = queryFeatureFlags.data;

  const columns: Array<WSTableColumn<MappedPendingTransaction>> = [
    {
      config: {
        header: { text: "Merchant" },
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowData => (
        <WSTableCell
          avatar={{
            type: "icon",
            icon: "transfer",
            color: "gray500"
          }}
          text={rowData.data.name}
          // pill={rowData.data.business ? undefined : { text: "Ignored" }}
          // secondaryTextColor={
          //   rowData.data.business &&
          //   rowData.data.type !== TransactionType.Transfer
          //     ? "gray600"
          //     : "gray400"
          // }
          secondaryText={toWSDateString(rowData.data.date, "monthDayYear")}
        />
      )
    },
    {
      config: {
        hideOnScreens: ["XS", "S", "M"],
        header: { text: "Description" },
        gridTemplateSizeMax: "0.5fr"
        // justify: "end"
      },
      renderFunction: rowData => <WSTableCell text={rowData.data.description} />
    },
    /*
    {
      config: {
        hideOnScreens: ["XS"],
        header: { text: "Account" },
        gridTemplateSizeMax: "0.2fr"
        // justify: "end"
      },
      renderFunction: rowData => (
        <WSTableCell
          text={
            rowData.data.account?.mask ? `•••${rowData.data.account.mask}` : "--"
          }
        />
      )
    },
    */
    {
      config: {
        hideOnScreens: featureFlags?.cashback2025
          ? ["XS"]
          : ["XS", "S", "M", "L", "XL"],
        header: { text: "Cashback Amount" },
        gridTemplateSizeMax: "0.4fr"
      },
      renderFunction: rowData => {
        return <WSTableCell text={undefined} />;
      }
    },
    {
      config: {
        justify: "end",
        header: { text: "Amount" },
        gridTemplateSizeMax: "auto"
      },
      renderFunction: rowData => (
        <WSTableCell
          text={toWSMoneyString(rowData.data.source.amount * -1, "bookkeeping")}
        />
      )
    },
    {
      config: {
        header: { text: "" },
        gridTemplateSizeMax: "72px"
      },
      renderFunction: () => ""
    }
  ];

  const tableData = sortBy(
    pendingTransactions.map((transaction, index) => {
      const account = accounts.find(
        account => account.accountId === transaction.accountId
      );

      if ("pendingTransactionId" in transaction) {
        return {
          data: {
            source: transaction,
            account,
            name: "Pending transaction",
            description: "Transfer",
            amount:
              Math.abs(transaction.amount) *
              (transaction.subType === PendingTransactionSubType.Deposit
                ? 1
                : -1),
            status: transaction.status,
            date: transaction.createdAt
          },
          id: transaction.pendingTransactionId
        };
      } else {
        return {
          data: {
            source: transaction,
            account,
            name: transaction.name || "Pending transaction",
            description: "Transfer",
            amount: transaction.amount * -1,
            status: transaction.status,
            date: transaction.date || transaction.createdAt
          },
          id: `${transaction.transactionId} ${transaction.date} ${index}`
        };
      }
    }),
    "data.date"
  ).reverse();

  if (!tableData.length) {
    return null;
  }

  return (
    <WSTable<MappedPendingTransaction>
      mt="XL"
      tableData={tableData}
      columns={columns}
      forceShowGroups
      showHeader={false}
      getGroupName={item => "Pending"}
      // rowActions={item => {
      //   const {
      //     id,
      //     data: { source }
      //   } = item;
      //
      //   const actions: WSTableAction[] = [];
      //
      //   if (source.status === PendingTransactionStatus.Pending) {
      //     actions.push({
      //       text: "Cancel",
      //       onAsyncClick: async () => {
      //         await updateTransaction({
      //           id: source.pendingTransactionId,
      //           status: PendingTransactionStatus.Cancelled
      //         });
      //       }
      //     });
      //   }
      //
      //   return actions;
      // }}
    />
  );
};
