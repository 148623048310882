import {
  FundingSourceType,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount, IClient } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAccountFullName } from "./selectorAccountFullName";
import { selectorCreditCardName } from "./selectorCreditCardName";
import { selectorFundingSource } from "./selectorFundingSource";
import { IInternalAccount } from "../../services/api/banking/types";

export const selectorFundingSourceName = (
  client: IClient,
  payrollSettings: IPayrollSettings,
  accounts: IAccount[],
  internalAccount?: IInternalAccount
) => {
  const fundingSource = selectorFundingSource(payrollSettings, client);

  if (!fundingSource) {
    return;
  }

  switch (fundingSource.type) {
    case FundingSourceType.Account:
      const account = accounts.find(
        account => account.accountId === fundingSource.id
      );

      if (account) {
        return selectorAccountFullName(account);
      }
      break;

    case FundingSourceType.PaymentCard:
      const card = (client.profile.savedPaymentMethods || []).find(
        paymentMethod => paymentMethod.paymentMethodId === fundingSource.id
      );

      if (card) {
        return selectorCreditCardName(card);
      }
      break;

    case FundingSourceType.InternalAccount:
      if (
        payrollSettings.fundingSource?.fundingSourceType ===
        FundingSourceType.InternalAccount
      ) {
        return `Wingspan Wallet${
          internalAccount
            ? ` (${internalAccount.numbers?.account?.slice(-4)})`
            : ""
        }`;
      }
  }
};
