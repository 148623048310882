import {
  useWSModal,
  WSActions,
  WSControl,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUpdateActivity } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useMutationUpdateAcknowledgement } from "../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { acknowledgementVersions } from "../../../shared/constants/acknowledgements";

type Props = {
  isMonitoredEntity: boolean;
  mailDelivery: boolean;
  onClose: () => void;
};

const DeliveryPreferences: React.FC<Props> = ({
  mailDelivery,
  isMonitoredEntity,
  onClose
}) => {
  const [electronicDelivery, setElectronicDelivery] = React.useState(
    !mailDelivery
  );
  const userId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [updateAcknowledge, updateAcknowledgeMeta] =
    useMutationUpdateAcknowledgement();

  return (
    <WSList gap="L">
      <WSInfoBox>
        Opt-in to electronic delivery (paperless). Turning this option off will
        result in physical forms being mailed. The actual delivery may be
        adjusted based on when you share your tax information.
      </WSInfoBox>
      <WSControl
        onChange={setElectronicDelivery}
        value={electronicDelivery}
        chip
        label="Opt-in to electronic delivery (paperless)"
        type="switch"
        tooltip={
          <WSInfoBox transparent title="Electronic delivery">
            <ol>
              <li>
                Electronic delivery is only available if you share your tax
                information with the payer before form submission. Otherwise,
                forms will be mailed.
              </li>
              <li>
                Your saved preference will be set for the forthcoming tax year.
                You can update anytime prior to the following year’s tax form
                submissions.
              </li>
            </ol>
          </WSInfoBox>
        }
      />
      <WSInfoBox title="Reminder">
        <ul>
          <li>
            Your delivery preference won’t take effect for the current filing
            after forms have been submitted by your payer(s). If you haven't
            shared tax information with the payer, forms will be mailed
            regardless of preference.
          </li>
          <li>
            In-app forms are also available in your tax documents section by
            default if you’ve shared your tax info with your payer(s).
          </li>
          <li>Contact your payer(s) for additional help with deliveries.</li>
        </ul>
      </WSInfoBox>
      <WSErrorMessage
        error={updateActivityMeta.error || updateAcknowledgeMeta.error}
        contextKey="NEC1099TaxDocuments"
      />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Save",
            kind: "Primary",
            onAsyncClick: async () => {
              await updateActivity({
                events: { electronic1099Consent: electronicDelivery }
              });

              if (isMonitoredEntity) {
                await updateAcknowledge(
                  {
                    acknowledgementName: "ElectronicTaxFormConsent",
                    requestData: {
                      acknowledgementName: "ElectronicTaxFormConsent",
                      acknowledgementStatus: electronicDelivery
                        ? "Given"
                        : "NotGiven",
                      version:
                        acknowledgementVersions["ElectronicTaxFormConsent"]
                    }
                  },
                  {
                    onSuccess: () => {
                      onClose();
                    }
                  }
                );
              } else {
                onClose();
              }
            }
          },
          {
            label: "Cancel",
            kind: "Tertiary",
            onClick: onClose
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export const useUpdateDeliveryPreferencesModal = () =>
  useWSModal(DeliveryPreferences, {
    title: "Delivery preference",
    size: "S"
  });
