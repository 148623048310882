import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { getCollaboratorGroupsListSize } from "../../../services/collaboratorGroups";
import { QUERY_COLLABORATOR_GROUPS_LIST_SIZE } from "../keys";

export const useCollaboratorGroupListSizeQuery = (
  params?: Parameters<typeof getCollaboratorGroupsListSize>[0],
  config?: WSQueryConfig<number, WSServiceError>
) => {
  return useWSQuery<number, WSServiceError>(
    [QUERY_COLLABORATOR_GROUPS_LIST_SIZE],
    () => getCollaboratorGroupsListSize(params),
    config
  );
};
