import { useHistory } from "react-router-dom";
import { PATH_SETTINGS_PAYROLL_METHOD } from "../../../paths";
import { Layout } from "../../../../../shared/components/Layout";
import { ManageFundingMethod } from "../../../components/funding/ManageFundingMethod";

export const RouteManageFundingMethod: React.FC = () => {
  const history = useHistory();

  return (
    <Layout
      title="Manage payroll funding method"
      onClose={() => {
        history.push(PATH_SETTINGS_PAYROLL_METHOD);
      }}
    >
      <ManageFundingMethod
        onNext={() => {
          history.push(PATH_SETTINGS_PAYROLL_METHOD);
        }}
      />
    </Layout>
  );
};
