import {
  ICatchUpExpenseTransactions,
  ICatchUpIncomeTransactions,
  IProfitAndLossResponse,
  ITransaction,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IPendingTransactionResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import {
  TransactionStatus,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { WSDateRangeSelectOptions } from "@wingspanhq/fe-component-library";
import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import bookkeepingService, {
  BookkeepingEnablement
} from "../../services/bookkeeping";
import { Await } from "../../utils";
import { UnwrapService, WSServiceError } from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_BOOKKEEPING_BANKING_ACCOUNT,
  QUERY_BOOKKEEPING_BANKING_BALANCE,
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME,
  QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTION,
  QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
  QUERY_BOOKKEEPING_PENDING_CASHBACK_BALANCE,
  QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
  QUERY_BOOKKEEPING_POSTED_CASHBACK_ELIGIBILITY,
  QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
  QUERY_BOOKKEEPING_STATUS,
  QUERY_BOOKKEEPING_SUBCATEGORIES,
  QUERY_BOOKKEEPING_TRANSACTION,
  QUERY_BOOKKEEPING_TRANSACTIONS,
  QUERY_BOOKKEEPING_TRANSACTIONS_SUMMARY
} from "./keys";

export const useBookkeepingStatus = (
  queryConfig?: WSQueryConfig<BookkeepingEnablement, unknown>
) => {
  return useWSQuery(
    QUERY_BOOKKEEPING_STATUS,
    async () => {
      try {
        return await bookkeepingService.getStatus();
      } catch {
        return { enabled: false };
      }
    },
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export const useCatchUpTransactionsExpense = (
  memberId: string,
  year?: number,
  queryConfig?: WSQueryConfig<ICatchUpExpenseTransactions, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE, year],
    async () => {
      try {
        return await bookkeepingService.getCatchUpExpenseTransactions(
          memberId,
          year ? `${year}` : undefined
        );
      } catch {
        const defaultData: ICatchUpExpenseTransactions = {
          accountExpenses: [],
          merchantExpenses: [],
          memberId
        };

        return defaultData;
      }
    },
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export const useCatchUpTransactionsIncome = (
  memberId: string,
  year?: number,
  queryConfig?: WSQueryConfig<ICatchUpIncomeTransactions, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME, year],
    async () => {
      try {
        return await bookkeepingService.getCatchUpIncomeTransactions(
          memberId,
          year ? `${year}` : undefined
        );
      } catch {
        const defaultData: ICatchUpIncomeTransactions = {
          accountIncome: [],
          sourceIncome: [],
          memberId
        };

        return defaultData;
      }
    },
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export type TransactionParams = {
  status?: TransactionStatus | TransactionStatus[] | null;
  type?: TransactionType | "" | TransactionType[];
  size?: string;
  nonBusiness?: boolean;
  accountId?: string[] | "";
  search?: string;
  wsCategory?: string;
  subcategory?: string;
  dateRange: "ALL" | "CUSTOM" | "PAST_WEEK" | "PAST_MONTH" | "PAST_YEAR";
  category: "ALL" | "CUSTOM";
  customDateRange: Date[];
  pageSize: number;
  sort?: { [key in keyof ITransaction]?: "desc" | "asc" };
  businessBanking?: boolean;
};

const mapTransactionParamsToFilter = (params: TransactionParams) => {
  let dateRange: any;

  switch (params.dateRange) {
    case "CUSTOM": {
      dateRange = params.customDateRange;
      break;
    }
    case "PAST_WEEK": {
      dateRange = WSDateRangeSelectOptions.PastWeek.range;
      break;
    }
    case "PAST_MONTH": {
      dateRange = WSDateRangeSelectOptions.PastMonth.range;
      break;
    }
    case "PAST_YEAR": {
      dateRange = WSDateRangeSelectOptions.Past12Months.range;
      break;
    }
    default: {
      dateRange = false;
    }
  }

  const filter = {
    ...(params.wsCategory && params.wsCategory !== WSCategory.Income
      ? {
          wsCategory: params.wsCategory
        }
      : null),
    ...(params.subcategory
      ? {
          "labels.subcategory": params.subcategory
        }
      : null),
    ...(params.nonBusiness
      ? {}
      : {
          business: true
        }),
    ...(params.accountId?.length === 1 && params.accountId[0] === "ALL"
      ? {}
      : {
          accountId: {
            in: params.accountId
          }
        }),
    ...(params.businessBanking
      ? {
          businessBanking: true
        }
      : {}),
    ...(params.search
      ? {
          name: { contains: params.search }
        }
      : {}),
    ...(params.type
      ? {
          type: Array.isArray(params.type) ? { in: params.type } : params.type
        }
      : {
          type: {
            in: [
              TransactionType.Expense,
              TransactionType.Income,
              TransactionType.Transfer
            ]
          }
        }),
    ...(params.type && params.size === "large"
      ? {
          amount: Array.isArray(params.type)
            ? params.type.includes(TransactionType.Expense) ||
              params.type.includes(TransactionType.Transfer)
            : [TransactionType.Expense, TransactionType.Transfer].includes(
                params.type
              )
            ? { ">=": 1000 }
            : { "<=": -1000 }
        }
      : {}),
    date: {
      ...(dateRange && dateRange[0] ? { ">=": dateRange[0] } : {}),
      ...(dateRange && dateRange[1] ? { "<=": dateRange[1] } : {})
    },
    ...(params.status
      ? {
          status: Array.isArray(params.status)
            ? {
                in: params.status
              }
            : params.status
        }
      : {
          status: {
            in: [TransactionStatus.Active]
          }
        })
  };

  return filter;
};

export const useTransactionsSummary = (
  params: TransactionParams,
  queryConfig?: WSQueryConfig<{ listSize: number }, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_TRANSACTIONS_SUMMARY, params],
    async () => {
      const filter = mapTransactionParamsToFilter(params);

      const { summary } = await bookkeepingService.fetchTransactions({
        sort: params.sort ? params.sort : { createdAt: "desc" },
        page: {
          size: 1,
          number: 1
        },
        filter
      });

      return { listSize: summary.listSize };
    },
    queryConfig
  );
};

export const useTransactions = (
  params: TransactionParams,
  queryConfig?: WSInfiniteQueryConfig<ITransaction[], unknown>
) => {
  return useWSInfiniteQuery(
    [QUERY_BOOKKEEPING_TRANSACTIONS, params],
    async ({ pageParam = 1 }) => {
      const filter = mapTransactionParamsToFilter(params);

      const { data } = await bookkeepingService.fetchTransactions({
        sort: params.sort ? params.sort : { createdAt: "desc" },
        page: {
          size: 10,
          number: pageParam
        },
        filter
      });

      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );
};
export const useTransaction = (
  transactionId: string,
  queryConfig?: WSQueryConfig<ITransaction, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_TRANSACTION, transactionId],
    async () => {
      return await bookkeepingService.getTransaction(transactionId);
    },
    queryConfig
  );
};

export const useProfitAndLoss = (
  { startDate, endDate }: { startDate: Date; endDate: Date },
  queryConfig?: WSQueryConfig<IProfitAndLossResponse, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_PROFIT_AND_LOSS, { startDate, endDate }],
    async () => await bookkeepingService.getProfitAndLoss(startDate, endDate),
    queryConfig
  );
};

export type CustomSubcategoriesResponse = UnwrapService<
  typeof bookkeepingService.getSubcategories
>;

export const useSubcategories = (
  wsCategory?: WSCategory,
  queryConfig?: WSQueryConfig<CustomSubcategoriesResponse, unknown>
) => {
  return useWSQuery(
    [QUERY_BOOKKEEPING_SUBCATEGORIES, { wsCategory }],
    async () => await bookkeepingService.getSubcategories(wsCategory),
    queryConfig
  );
};

export const useBankingBalance = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getBankingBalance>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_BANKING_BALANCE,
    bookkeepingService.getBankingBalance,
    queryConfig
  );

export const useBankingAccount = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getBankingAccount>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_BANKING_ACCOUNT,
    bookkeepingService.getBankingAccount,
    queryConfig
  );

export const usePendingBankingTransactions = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getPendingBankingTransactions>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
    bookkeepingService.getPendingBankingTransactions,
    queryConfig
  );

export const usePendingBankingTransaction = (
  transactionId: string,
  queryConfig?: WSQueryConfig<IPendingTransactionResponse, WSServiceError>
) =>
  useWSQuery(
    [QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTION, transactionId],
    () => bookkeepingService.getPendingBankingTransaction(transactionId),
    queryConfig
  );

export const usePostedCashbackBalance = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getPostedCashbackBalance>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
    async () => await bookkeepingService.getPostedCashbackBalance(),
    queryConfig
  );

export const usePendingCashbackBalance = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getPendingCashbackBalance>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_PENDING_CASHBACK_BALANCE,
    async () => await bookkeepingService.getPendingCashbackBalance(),
    queryConfig
  );

export const useCashbackEligibility = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getCashbackEligibility>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_BOOKKEEPING_POSTED_CASHBACK_ELIGIBILITY,
    async () => await bookkeepingService.getCashbackEligibility(),
    {
      retry: 1,
      ...queryConfig
    }
  );
