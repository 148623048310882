import { WSInfiniteQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../helpers";
import { QUERY_ENGAGEMENT_LIST } from "../keys";
import {
  EngagementApiFilter,
  EngagementFilter,
  EngagementListQueryResponse,
  getEngagements
} from "../../../services/engagements";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export function mapEngagementsFilters(filters?: EngagementFilter) {
  let filter: EngagementApiFilter = {};

  if (filters?.type) {
    filter.type = filters.type;
  }

  if (filters && "isDefault" in filters) {
    filter.isDefault = filters.isDefault;
  }

  return filter;
}

export type EngagementListQueryParams = {
  sort?: { updatedAt?: "desc" | "asc"; createdAt?: "desc" | "asc" };
  filter?: EngagementFilter;
  payeeId?: string;
};

export const useEngagementListQuery = (
  params?: EngagementListQueryParams,
  config?: WSInfiniteQueryConfig<EngagementListQueryResponse, WSServiceError>
) => {
  const { sort, filter, payeeId } = params || {};

  const xWingspanPayerId = useXWingspanPayerId();

  return useWSInfiniteQuery<EngagementListQueryResponse, WSServiceError>(
    [QUERY_ENGAGEMENT_LIST, params, xWingspanPayerId],
    async ({ pageParam = 1 }) => {
      const { data, summary } = await getEngagements(
        {
          filter: mapEngagementsFilters(filter),
          page: { size: 10, number: pageParam },
          sort
        },
        xWingspanPayerId
      );

      return { data, summary };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};
