import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  InvoiceStatus,
  IPayeeTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { RouteInvoiceDetails } from "../../../../Invoices/screens/RouteInvoiceDetails";
import { WSQueries } from "../../../../query/WSQuery";
import { useInvoicesFilteredQuery } from "../../../../query/payments/queries";
import { ButtonDownloadCsv } from "../../../../shared/components/ButtonDownloadCsv";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";

type Props = {
  taxForm: IPayeeTaxFormResponse;
};

export const Payments: React.FC<Props> = ({ taxForm }) => {
  const queryInvoices = useInvoicesFilteredQuery({
    clientId: taxForm.clientId,
    status: InvoiceStatus.Paid,
    "events.paidAt": {
      ">=": new Date(Date.UTC(taxForm.year, 0, 1)),
      "<": new Date(Date.UTC(taxForm.year + 1, 0, 1))
    }
  });
  const payerName = selectorPayeeTaxFormPayerName(taxForm);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={match.path + "/payments/:invoiceId"}
        component={RouteInvoiceDetails}
      />

      <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
        <WSText weight="medium">Invoices</WSText>

        {queryInvoices.data && queryInvoices.data.length > 0 && (
          <ButtonDownloadCsv
            getData={async () => {
              const invoices = queryInvoices.data || [];
              return invoices.map(invoice => ({
                "Invoice Number": invoice.invoiceNumber,
                "Non-employee compensation": invoice.amount,
                "Paid Date": invoice.events.paidAt
              }));
            }}
            fileName={`${payerName} Tax Form Invoices`}
            customTrigger={<WSButton.Link>Download CSV</WSButton.Link>}
          />
        )}
      </WSFlexBox.CenterY>
      <WSDivider mt="M" mb="2XL" />
      <WSQueries queries={{ queryInvoices }}>
        {({ queryInvoicesData: invoices }) => {
          return (
            <>
              <WSTable<IInvoice>
                tableData={invoices.map(invoice => ({
                  id: invoice.invoiceId,
                  data: invoice
                }))}
                onRowClick={({ data: invoice }) => {
                  history.push(
                    location.pathname + "/payments/" + invoice.invoiceId,
                    {
                      backPath: location.pathname
                    }
                  );
                }}
                columns={[
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      header: {
                        text: `Invoices paid in ${taxForm.year}`
                      }
                    },
                    renderFunction: ({ data: payable }) => (
                      <WSTableCell
                        avatar={{
                          type: "icon",
                          icon: "check-double",
                          color: "green500",
                          colorBackground: "green50"
                        }}
                        text={payable.invoiceNumber}
                      />
                    )
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      justify: "start",
                      header: {
                        text: "Non-employee compensation"
                      }
                    },
                    renderFunction: ({ data: payable }) => (
                      <WSTableCell
                        text={toWSMoneyString(payable.amount)}
                        secondaryText={`Paid on ${toWSDateString(
                          payable.events.paidAt,
                          "monthDate"
                        )}`}
                      />
                    )
                  }
                ]}
              />
              {invoices.length === 0 && (
                <WSText.ParagraphSm align="center" color="gray400" mt="M">
                  No invoices found
                </WSText.ParagraphSm>
              )}
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
