import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSInputMaskOld,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useWSMutation } from "../../query/helpers";
import { ErrorContextKey } from "../../services/platform";
import { usersService } from "../../services/users";
import { OTPDisclosure } from "../../shared/components/OPTDisclosure";
import { validatorOTP } from "../../shared/validators/validatorOTP";
import { WSServiceError } from "../../utils/serviceHelper";
import { WSErrorMessage } from "../WSErrorMessage/WSErrorMessage";

type FormData = { code: string };

type Props = {
  onSuccess: () => void;
} & WSElementProps;

export const SmsConfirmationForm: React.FC<Props> = ({
  onSuccess,
  ...elementProps
}) => {
  const [submit, submitMeta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      await usersService.session.createOtpSession({
        code: data.code
      });
    },
    {
      onSuccess
    }
  );

  return (
    <WSElement {...elementProps}>
      <WSFormOld<FormData>
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          code: validatorOTP
        })}
      >
        <WSList gap="XL">
          <WSText color="gray600">
            A 6 digit verification code has been sent via SMS to your registered
            mobile number.
          </WSText>

          <WSFormOld.Field
            label="Verification code"
            name="code"
            component={WSInputMaskOld}
            componentProps={{
              autoComplete: "one-time-code",
              mask: "999999",
              slotChar: ""
            }}
          />
          <OTPDisclosure />
          <WSErrorMessage
            error={submitMeta.error}
            contextKey={ErrorContextKey.CreateOtpSession}
          />
          <WSButton
            name="confirmTwoFactorAuth"
            fullWidth
            loading={submitMeta.isLoading}
          >
            Continue
          </WSButton>
        </WSList>
      </WSFormOld>
    </WSElement>
  );
};
