import { WSFormOld, WSInputNumberOld } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import { WSQueryCache } from "@ws-react-query";
import { QUERY_BOOKKEEPING_PROFIT_AND_LOSS } from "../../../query/bookkeeping/keys";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import styles from "./SettingsUpdateForms.module.scss";

interface TaxNumberOfDependentsFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const TaxNumberOfDependentsForm: React.FC<
  TaxNumberOfDependentsFormProps
> = ({ field, onSubmit, defaultValues, onCancel }) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          [field]: data[field]
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
        WSQueryCache.invalidateQueries(QUERY_BOOKKEEPING_PROFIT_AND_LOSS);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.number()
          .typeError("This field is required")
          .required("This field is required")
          .min(0)
          .max(10, "Sorry, the maximum dependents allowed is 10")
      })}
    >
      <WSFormOld.Field name={field} component={WSInputNumberOld} />
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
