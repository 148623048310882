import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { WSQueryConfig } from "@ws-react-query";
import {
  CustomFieldListFilters,
  customFieldsService,
  mapCustomFieldListFilters
} from "../../../services/customFields";
import { getAllEntries, WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_CUSTOM_FIELDS_ALL } from "../keys";

export const useCustomFieldsAll = (
  filters?: CustomFieldListFilters,
  config?: WSQueryConfig<Array<ICustomField>, WSServiceError>
) => {
  return useWSQuery<Array<ICustomField>, WSServiceError>(
    [QUERY_CUSTOM_FIELDS_ALL, { filters }],
    () =>
      getAllEntries(
        customFieldsService.customField.list,
        mapCustomFieldListFilters(filters)
      ),
    {
      ...config
    }
  );
};
