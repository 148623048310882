import React from "react";
import { WSFormOld, WSRadioInputGroup } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import { TaxFilingCode } from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_BOOKKEEPING_PROFIT_AND_LOSS } from "../../../query/bookkeeping/keys";
import { useUserId } from "../../../query/hooks/helpers";

export const TAX_FILING_STATUS_OPTIONS = [
  {
    value: TaxFilingCode.Single,
    label: "Single"
  },
  {
    value: TaxFilingCode.Married,
    label: "Married"
  },
  {
    value: TaxFilingCode.HeadOfHousehold,
    label: "Head of Household"
  },
  {
    value: TaxFilingCode.MarriedFilingSeparately,
    label: "Married Filing Separately"
  },
  {
    value: TaxFilingCode.QualifiedWidower,
    label: "Qualified Widower"
  }
];

interface TaxFilingStatusFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const TaxFilingStatusForm: React.FC<TaxFilingStatusFormProps> = ({
  field,
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          filingCode: data.filingCode,
          incomeEstimateForYear: 0,
          expensesEstimateForYear: 0
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
        WSQueryCache.invalidateQueries(QUERY_BOOKKEEPING_PROFIT_AND_LOSS);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.string().required("Tax Filing Status is required")
      })}
    >
      <WSFormOld.Field name={field}>
        <WSFormOld.Input
          render={props => (
            <WSRadioInputGroup {...props} options={TAX_FILING_STATUS_OPTIONS} />
          )}
        />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
