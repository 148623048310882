import {
  useModalOldContext,
  WSButton,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";

import React from "react";

import { useHistory } from "react-router-dom";

import {
  useMemberPrivateDelete,
  useMemberPublicDelete
} from "../../query/files/mutations";

import {
  IMemberFileResponse,
  IMemberPublicFileResponse
} from "@wingspanhq/files/dist/lib/interfaces";

type DeleteFileModalProps = {
  source: IMemberFileResponse | IMemberPublicFileResponse;
};

export const DOCUMENTS_MODALS_DELETE_FILE = "DOCUMENTS_MODALS_DELETE_FILE";

export const DeleteFileModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  const [deletePublicFile, deletePublicFileMeta] = useMemberPublicDelete();
  const [deletePrivateFile, deletePrivateFileMeta] = useMemberPrivateDelete();

  const deleteFile = async (
    row: IMemberFileResponse | IMemberPublicFileResponse
  ) => {
    if (row.fileAccessScope === "Private") {
      deletePrivateFile(row.fileId);
    } else {
      deletePublicFile(row.fileId);
    }
  };

  return (
    <WSModalOld name={DOCUMENTS_MODALS_DELETE_FILE} size="S">
      {(props: DeleteFileModalProps) => (
        <>
          <WSText.Heading4 mb="M">
            Are you sure you want to delete this file?
          </WSText.Heading4>
          <WSText mb="M">Once confirmed, this action can’t be undone.</WSText>

          <WSButton
            destructive
            fullWidth
            loading={
              deletePublicFileMeta.isLoading || deletePrivateFileMeta.isLoading
            }
            onClick={async () => {
              await deleteFile(props.source);
              closeModal(DOCUMENTS_MODALS_DELETE_FILE);
            }}
            name="deleteFile"
          >
            Delete
          </WSButton>
          <WSButton.Tertiary
            mt="M"
            fullWidth
            onClick={() => closeModal(DOCUMENTS_MODALS_DELETE_FILE)}
          >
            Cancel
          </WSButton.Tertiary>
        </>
      )}
    </WSModalOld>
  );
};
