import {
  IPayerPayeePayerOwnedData,
  RequirementEmbeddedStatus,
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import {
  IPayerPayeeW9Data,
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces/payerPayee";
import {
  IMember,
  INewUser,
  ISharedMember,
  ISharedUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { PayerPayeeSearchableTinStatus } from "../../../modules/Payees/routes/RoutePayees/filtering";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import { ISearchablePayerPayeeEngagement } from "../payer";
import { serviceSearch } from "../service";
import { PayerPayeeEngagementSearchableStatus } from "../types";

export interface IPayeeRow {
  createdAt: Date;
  updatedAt: Date;
  payeeId: string;
  searchableStatus: PayerPayeeEngagementSearchableStatus;
  payerId: string;
  payer?: {
    member: IMember;
    user: INewUser;
  };

  payerOwnedData: IPayerPayeePayerOwnedData;
  payeeOwnedData: {
    status: PayerPayeeStatus; //IPayerPayeePayeeOwnedData["status"],
    shareTaxDocument: PayeeTaxDocumentSharePermission; //IPayerPayeePayeeOwnedData["shareTaxDocument"],
    autoPayRequirement: PaymentRequirementStrategy; // IPayerPayeePayeeOwnedData["autoPayRequirement"]
    payeeW9Data?: IPayerPayeeW9Data;
  };

  engagements: ISearchablePayerPayeeEngagement[];
  user: ISharedUser;
  member: ISharedMember;
  searchableTinStatus: PayerPayeeSearchableTinStatus;
}

export type PayeeRowsQueryFilter = {
  searchString?: string;
  searchableStatus?:
    | PayerPayeeEngagementSearchableStatus
    | {
        "!="?: PayerPayeeEngagementSearchableStatus;
        in?: PayerPayeeEngagementSearchableStatus[];
      };
  "engagements.engagementId"?:
    | string
    | {
        in?: string[];
      };

  "engagements.payerPayeeEngagementId"?: string;

  "engagements.requirements.requirementType"?:
    | RequirementType
    | {
        "!="?: RequirementType;
        in?: RequirementType[];
      };

  "engagements.requirements.status"?:
    | RequirementEmbeddedStatus
    | {
        "!="?: RequirementEmbeddedStatus;
        in?: RequirementEmbeddedStatus[];
      };

  "payerOwnedData.status"?:
    | PayerPayeeStatus
    | {
        "!="?: PayerPayeeStatus;
        in?: PayerPayeeStatus[];
      };
  "payerOwnedData.autoPayStrategy"?:
    | PaymentRequirementStrategy
    | {
        "!="?: PaymentRequirementStrategy;
        in?: PaymentRequirementStrategy[];
      };
  "payeeOwnedData.shareTaxDocument"?:
    | PayeeTaxDocumentSharePermission
    | {
        "!="?: PayeeTaxDocumentSharePermission;
        in?: PayeeTaxDocumentSharePermission[];
      };
  "labels.bulkBatchId"?: {
    in?: string[];
  };
  payerId?:
    | string
    | {
        "!="?: string;
        in?: string[];
      };
  [key: string]:
    | undefined
    | string
    | {
        in?: string[];
      }
    | {
        "!="?: string;
      }
    | {
        contains?: string;
      };
};

export type PayeeRowsQuerySort = {
  updatedAt?: "asc" | "desc";
};

export type PayeeRowsQuery = ListRequestQuery<
  PayeeRowsQueryFilter,
  PayeeRowsQuerySort
>;

export const getPayeeRows = async (
  params?: PayeeRowsQuery,
  xWingspanExpansion?: string
): Promise<IPayeeRow[]> => {
  const response = await serviceSearch.get("/payee-row", {
    params,
    ...(xWingspanExpansion
      ? {
          headers: {
            "x-wingspan-expansion": xWingspanExpansion
          }
        }
      : {})
  });

  return response.data;
};

export type IPayeeSearchRowsSummary = {
  listSize: number;
};

export const getPayeeRowsSummary = async (
  params?: PayeeRowsQuery,
  xWingspanExpansion?: string
): Promise<IPayeeSearchRowsSummary> => {
  const { headers } = await serviceSearch.get(`/payee-row`, {
    params,
    headers: {
      "X-WS-Get-List-Summary": "true",
      ...(xWingspanExpansion
        ? {
            "x-wingspan-expansion": xWingspanExpansion
          }
        : {})
    }
  });

  return {
    listSize: Number(headers["x-ws-list-size"])
  };
};
