import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { SetupRequestCorrectionFlowProps } from "./index";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../constants/corrections";
import { AmountCorrectionDiffPreview } from "../components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../components/AddressCorrectionDiffPreview";
// import { FormRequestCorrectionData } from "./types";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "./getDefaultValuesForCorrectionsForm";
import { useCancelCorrectionModal } from "../components/CorrectionFormActions";
import { useTaxFormRequestCorrection } from "../../../../query/taxForm/mutations/useTaxFormRequestCorrection";
import { ErrorContextKey } from "../../../../services/platform";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { createRequestCorrectionPayload } from "./createRequestCorrectionPayload";
import { useCurrentTaxDocumentsYear } from "../../../TaxDocuments/utils/useCurrentTaxDocumentsYear";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { OLD_TAX_DOCUMENTS_PATH } from "../../paths";
import { FormRequestCorrectionData } from "../components/types";
import { getCorrectionType } from "./getCorrectionType";

export const RouteReviewCorrection: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const [consent, setConsent] = useState(false);
  const currentYear = useCurrentTaxDocumentsYear();

  const history = useHistory<{
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();

  const corrections = history.location.state?.corrections ?? [];
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;

  const cancelCorrectionModal = useCancelCorrectionModal();
  const onEdit = () => {
    onNext({
      isEdit: true,
      corrections,
      correctionsFormData
    });
  };

  const onCancel = () => {
    history.push(`${OLD_TAX_DOCUMENTS_PATH}/${taxFormId}`);
  };

  const onRequestCorrection = async () => {
    await requestCorrection(
      {
        taxFormId,
        requestData: createRequestCorrectionPayload(
          correctionsFormData,
          queryTaxForm?.data as IPayeeTaxFormResponse
        )
      },
      {
        onSuccess: res => {
          onNext({
            isCorrectionRequestFailed: false,
            corrections,
            correctionsFormData
          });
        },
        onError: error => {
          onNext({
            isCorrectionRequestFailed: true,
            canTryAgain: [
              500, // Internal Server Error
              502, // Bad Gateway
              503, // Service Unavailable
              504 // Gateway Timeout
            ].includes(error?.response?.status as number),
            corrections,
            correctionsFormData
          });
        }
      }
    );
  };

  const onCancelRequest = () => {
    cancelCorrectionModal.open({ onCancel });
  };

  return (
    <EditLayout title="Request correction">
      <PreventLeave isEnabled={true} />
      <WSText.Heading4 mb="M">
        Review your request for correction
      </WSText.Heading4>
      <WSText.ParagraphSm color="gray500">
        Please review your requested corrections thoroughly. You may go back to
        edit at any time prior to submitting your request.
      </WSText.ParagraphSm>

      <WSDivider my="3XL" />

      <WSQueries
        queries={{
          queryTaxForm
        }}
      >
        {({ queryTaxFormData: taxForm }) => {
          const payerName = selectorPayeeTaxFormPayerName(taxForm);
          const correctionTypeData = getCorrectionType(
            taxForm,
            correctionsFormData
          );
          const correctionTypeToPreviewComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                amount={taxForm?.data?.totalAmount ?? 0}
                correctedAmount={correctionsFormData.necTotalAmount}
                comment={correctionsFormData.amountCorrectionHelpText}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                originalTinData={getPayeeTINorName(taxForm)}
                correctedTinData={{
                  ssn: correctionsFormData.ssn,
                  firstName: correctionsFormData.firstName,
                  lastName: correctionsFormData.lastName,

                  ein: correctionsFormData.ein,
                  businessName: correctionsFormData.businessName,
                  disregardedEntityName:
                    correctionsFormData.disregardedEntityName,
                  taxClassification:
                    correctionsFormData.taxClassification as CompanyStructure
                }}
                reasonForChange={correctionsFormData.reasonForChange}
                otherReason={correctionsFormData.otherReason}
                identificationNumberType={
                  correctionsFormData.identificationNumberType
                }
              />
            ),
            [CORRECTION_ADDRESS]: (
              <AddressCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                originalAddress={getPayeeFormW9Address(taxForm)}
                correctedAddress={correctionsFormData.formW9Address as any}
              />
            )
          };

          const hasOnlyAmountCorrection =
            correctionTypeData.corrections.length === 1 &&
            correctionTypeData.corrections.some(
              type => type === CORRECTION_AMOUNT
            );
          return (
            <>
              <WSText.Heading4 mb="2XL">
                {payerName} {currentYear} Form 1099-NEC
              </WSText.Heading4>

              {correctionTypeData.corrections.map(correctionType => {
                const DiffPreviewComponent =
                  correctionTypeToPreviewComponentMap[correctionType];
                return (
                  <WSElement mb="2XL" key={correctionType}>
                    {DiffPreviewComponent}
                    <WSDivider my="3XL" />
                  </WSElement>
                );
              })}

              <WSMessageBox.Info mb="2XL">
                <WSText weight="medium" mb="M" color="gray500">
                  Important, please read:
                </WSText>
                <TextBulleted color="gray500">
                  <li>
                    <b>
                      Only one (1) correction request can be made through
                      Wingspan to your payer for a tax year.
                    </b>{" "}
                    Please ensure all the information in your correction request
                    is accurate before submitting to your payer.
                  </li>
                  <li>
                    Once your request for correction has been submitted, it
                    cannot be cancelled.
                  </li>
                  <li>
                    The payer company has sole discretion to accept or reject
                    the requested change(s). They may reach out to you regarding
                    the requested change(s).
                  </li>
                  {hasOnlyAmountCorrection ? null : (
                    <li>
                      Changes to taxpayer identification number (EIN or SSN),
                      name, classification, or address - once you submit the
                      correction request, your <b>Wingspan profile</b> will also
                      be updated (whether the correction request is accepted or
                      not by the payer for tax purposes) to ensure the most
                      up-to-date information is reflected in Wingspan.
                    </li>
                  )}
                </TextBulleted>
              </WSMessageBox.Info>

              <WSCheckboxToggle
                mb="2XL"
                name="consent"
                value={consent}
                onChange={setConsent}
                label="By checking this box, I understand that once I submit the request for correction, it cannot be cancelled and the payer company has sole discretion to accept or reject the requested change(s)."
              />

              <WSErrorMessage
                mb="XL"
                contextKey={ErrorContextKey.NEC1099RequestCorrection}
                error={requestCorrectionMeta.error}
              />

              <WSButtons forceFullWidth mb="2XL">
                <WSButton.Primary
                  disabled={!consent}
                  onClick={onRequestCorrection}
                  loading={requestCorrectionMeta.isLoading}
                >
                  Request correction
                </WSButton.Primary>
                <WSButton.Secondary destructive onClick={onCancelRequest}>
                  Cancel request
                </WSButton.Secondary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
