import {
  useModalOldContext,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSModalOld,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import * as React from "react";
import { Tooltip } from "../Tooltip";
import styles from "./CertifiedSecureDigitalPaymentsTooltip.module.scss";

type Props = WSElementProps;

const CERTIFIED_SECURE_DIGITAL_PAYMENTS_INFO_MODAL =
  "CERTIFIED_SECURE_DIGITAL_PAYMENTS_INFO_MODAL";

export const CertifiedSecureDigitalPaymentsTooltip: React.FC<Props> = ({
  className,
  ...elementProps
}) => {
  const { openModal } = useModalOldContext();
  const infoContent = (
    <>
      <WSFlexBox mb="M">
        <WSIcon block name="lock" color="gray500" mr="M" />
        <WSText.ParagraphSm weight="medium" color="gray600">
          Wingspan's security guarantee.
        </WSText.ParagraphSm>
      </WSFlexBox>
      <WSText.ParagraphSm color="gray600">
        Wingspan has been audited by a PCI-certified auditor and is a certified
        Level 1 PCI Service Provider. This is the most stringent level of
        certification available in the payments industry.
      </WSText.ParagraphSm>
    </>
  );
  return (
    <WSElement {...elementProps} className={cn(styles.container, className)}>
      <WSScreen.Mobile>
        <WSElement
          pr="M"
          pl="XS"
          onClick={() =>
            openModal(CERTIFIED_SECURE_DIGITAL_PAYMENTS_INFO_MODAL)
          }
        >
          <WSIcon
            block
            name="info-circle"
            color="gray500"
            className={styles.icon}
          />
        </WSElement>
        <WSModalOld
          size="S"
          key="modal"
          name="CERTIFIED_SECURE_DIGITAL_PAYMENTS_INFO_MODAL"
        >
          {infoContent}
        </WSModalOld>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <Tooltip
          trigger={
            <WSIcon
              block
              name="info-circle"
              ml="XS"
              color="gray500"
              className={styles.icon}
            />
          }
          toolTipClassName={styles.toolTipContent}
          arrowClassName={styles.arrow}
          placement="right"
        >
          {infoContent}
        </Tooltip>
      </WSScreen.TabletAndDesktop>
    </WSElement>
  );
};
