import {
  WSAlert,
  WSDivider,
  WSElement,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import {
  IPayeeResponse,
  PayerPayeeEngagementStatus,
  PayerPayeeStatus,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { GeneralRequirementsTable } from "./GeneralRequirementsTable";
import styles from "./index.module.scss";
import { RequirementsTable } from "../../../Requirements/components/RequirementsTable";
import { RequirementContextType } from "../../../Requirements/types";
import { selectorUniqRequirementsFromEngagementsList } from "../../../Requirements/selectors/selectorUniqRequirementsFromEngagementsList";
import { PaymentsEligibility } from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFocusedFeatures } from "../../../../FocusedFeatures";
import { GeneralRequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { IMember } from "@wingspanhq/users";

export type EligibilityPanelProps = {
  engagements: IPayeeEngagementListResponse;
  payee: IPayeeResponse;
  member: IMember;
  noActions?: boolean;
};

const TOOLTIP_TEXT = `• General requirements - include account setup and onboarding tasks that must be completed to be eligible for payment. \n
• Engagement requirements - if engagements are utilized, any engagement-specific requirements must also be completed to be eligible for payment on those engagements.`;

export const EligibilityRequirements: React.FC<EligibilityPanelProps> = ({
  engagements,
  payee,
  noActions,
  member
}) => {
  const userId = useUserId();
  const focusedFeatures = useFocusedFeatures();

  const engagementRequirements = useMemo(() => {
    return selectorUniqRequirementsFromEngagementsList(engagements);
  }, [engagements]);

  const generalRequirements = useMemo(() => {
    return (
      payee.requirements?.filter(requirement =>
        focusedFeatures.showContractorsEligibility
          ? true
          : requirement.requirementType !== GeneralRequirementType.PayoutMethod
      ) || []
    );
  }, [payee.requirements]);

  const allRequirements = useMemo(() => {
    return [...generalRequirements, ...engagementRequirements];
  }, [generalRequirements, engagementRequirements]);

  const completedLength = useMemo(() => {
    return allRequirements.filter(e => e.status === RequirementStatus.Complete)
      .length;
  }, [allRequirements]);

  const isRequirementsComplete = useMemo(() => {
    return allRequirements.every(e => e.status === RequirementStatus.Complete);
  }, [allRequirements]);

  const hasEligibleEngagements = useMemo(() => {
    return engagements
      .filter(
        engagement => engagement.status === PayerPayeeEngagementStatus.Active
      )
      .some(
        engagement =>
          engagement.paymentsEligibility === PaymentsEligibility.Eligible
      );
  }, [engagements]);

  const isInactive = payee.payerOwnedData.status === PayerPayeeStatus.Inactive;

  return (
    <WSPanel p="NONE" colorBackground="gray50" className={styles.panel}>
      <WSElement py="M" px="XL" colorBackground="white">
        <WSSectionToolbar
          title="Eligibility"
          tooltip={{
            title: "Eligibility requirements",
            text: TOOLTIP_TEXT
          }}
          pill={
            payee.payerOwnedData.status === PayerPayeeStatus.Inactive
              ? {
                  icon: "archive",
                  text: "Archived",
                  theme: "neutral"
                }
              : {
                  icon: true,
                  text: `${completedLength} of ${allRequirements.length} completed`,
                  theme:
                    isRequirementsComplete && hasEligibleEngagements
                      ? "success"
                      : "warning"
                }
          }
        />
      </WSElement>
      <WSDivider />

      <WSElement colorBackground="gray50" p="XL">
        {isInactive ? (
          <WSAlert icon="archive" size="M" mb="L" theme="info" title="Archived">
            <WSText.ParagraphSm weight="book" color="gray700">
              This contractor is currently inactive and cannot be added to new
              engagements or invoices until they are restored. <br />
              Their eligibility will be recalculated if they are restored. Past
              requirement files for this contractor can be viewed here.
            </WSText.ParagraphSm>
          </WSAlert>
        ) : isRequirementsComplete && hasEligibleEngagements ? (
          <WSAlert
            mb="L"
            theme="success"
            icon="check-circle"
            title="Contractor is all set for now"
          >
            All eligibility requirements completed. Any new requirements will
            appear here.
          </WSAlert>
        ) : isRequirementsComplete ? (
          <WSAlert
            mb="L"
            theme="warning"
            icon="alert"
            title="Payments eligibility pending"
          >
            The contractor should have at least one active engagement that is
            eligible for payments.
          </WSAlert>
        ) : focusedFeatures.showContractorDetailsTaxOnlyPayerEligibilityWarning ? (
          <WSAlert
            mb="L"
            theme="warning"
            icon="alert"
            title="Requirements incomplete"
          >
            The contractor must complete all requirements to receive tax forms.
          </WSAlert>
        ) : (
          <WSAlert
            mb="L"
            theme="warning"
            icon="alert"
            title="Eligibility requirements pending"
          >
            The contractor must complete all eligibility requirements to start
            receiving payments.
          </WSAlert>
        )}
        <WSSectionToolbar title="General requirements" mb="S" />
        <GeneralRequirementsTable
          requirements={generalRequirements}
          payee={payee}
          payerCountry={member.profile?.address?.country}
        />
        {engagementRequirements.length ? (
          <>
            <WSSectionToolbar title="Engagement requirements" mb="S" mt="L" />
            <RequirementsTable
              payeeId={payee.payeeId}
              payerId={userId}
              requirements={engagementRequirements}
              contextType={RequirementContextType.Payee}
              showOverrideActions
              hideActions={noActions}
            />
          </>
        ) : null}
      </WSElement>
    </WSPanel>
  );
};
