import { WSForm, WSInfoBox, WSSelect } from "@wingspanhq/fe-component-library";
import { selectorNo1099CompanyStructure } from "../../selectors/selectorNo1099CompanyStructure";
import {
  businessTaxClassificationSelectOptions,
  taxClassificationLabelMap
} from "../../../modules/Onboarding/utils/taxClassification";
import { FederalTaxClassification } from "../../../modules/Onboarding/types";
import React from "react";

export type FormPartialFederalTaxClassificationProps = {
  name: string;
  show1099Message?: boolean;
  hidden?: boolean;
  helperText?: string;
};

export const FormPartialFederalTaxClassification: React.FC<
  FormPartialFederalTaxClassificationProps
> = ({ name, show1099Message, hidden, helperText }) => {
  return (
    <>
      <WSForm.Field
        name={name}
        hidden={hidden}
        component={WSSelect}
        label="Federal tax classification"
        componentProps={{
          required: true,
          helperText: helperText,
          options: businessTaxClassificationSelectOptions
        }}
      />

      <WSForm.Value name={name}>
        {taxClassification =>
          !hidden &&
          show1099Message &&
          selectorNo1099CompanyStructure(taxClassification) ? (
            <WSInfoBox title="1099-NEC form may not be issued">
              As a{" "}
              {
                taxClassificationLabelMap[
                  taxClassification as FederalTaxClassification
                ]
              }
              , you may not be eligible for a 1099 per IRS guidelines. Your
              payer has discretion in issuing 1099s based on your tax
              classification. Wingspan will provide an annual payment summary
              for tax purposes. If this doesn't align with your business
              structure, consider updating your federal tax classification.
            </WSInfoBox>
          ) : null
        }
      </WSForm.Value>
    </>
  );
};
