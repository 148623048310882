import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import {
  useIsMobile,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useCMSWebflowEnterprise } from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { getCurrentEntepriseId } from "../../query/platform/selectors";
import { useActivities, useUserProfile } from "../../query/users/queries";
import { openIntercom } from "../../shared/utils/intercom";
import { useWSStore } from "../../store";
import { WS_LINKS } from "../../types/wsLinks";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../utils/analytics";
import { HeadwayNotification } from "../HeadwayNotification";
import styles from "./other.module.scss";

interface HelpCenterProps extends WSElementProps {
  children(onClick: () => void): React.ReactNode;

  place: "header" | "footer" | "sidebar";
}

export const HelpCenter: React.FC<HelpCenterProps> = ({
  children,
  place,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const history = useHistory();
  const isMobile = useIsMobile();

  const userId = useUserId();
  const anonymousId = getSegmentAnonymousIdFromLocalStorage();
  const user = useUserProfile(userId);
  const qActivity = useActivities(userId);
  const store = useWSStore();
  const enterpriseId = getCurrentEntepriseId(store, qActivity.data);
  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });

  const isEarlyAccess =
    qActivity.data?.flows.settingsFoundingMemberInterest?.complete;

  if (
    enterpriseQuery.isInitialLoading ||
    enterpriseQuery.data?.isHideHelpButton
  ) {
    return place === "header" ? <WSElement ml="2XL" /> : null;
  }

  return (
    <>
      <WSElement className={styles.helpCenterWrapper} {...props}>
        {children(() => setIsOpened(true))}
        {isOpened ? (
          <>
            <WSElement
              className={styles.overlay}
              onClick={() => setIsOpened(false)}
            />
            <WSElement
              className={styles.helpCenter}
              style={
                place === "header"
                  ? { top: 54, right: -6 }
                  : place === "footer"
                  ? { bottom: 54, right: 0 }
                  : { bottom: 85, left: 65, position: "fixed" }
              }
              py="M"
            >
              <WSFlexBox.CenterY
                className={styles.helpCenterItem}
                onClick={() => {
                  window.open(WS_LINKS.helpCenter, "_blank");
                  setIsOpened(false);
                }}
              >
                <WSIcon block name="help-circle" mr="M" />
                <WSText.ParagraphSm color="gray600">
                  Help & support guide
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>

              <WSFlexBox.CenterY
                className={styles.helpCenterItem}
                onClick={() => {
                  openIntercom();
                  setIsOpened(false);
                }}
              >
                <WSIcon block name="chat" mr="M" />
                <WSText.ParagraphSm color="gray600">
                  Send us a message
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY
                className={styles.helpCenterItem}
                onClick={() => {
                  track("Send Feedback Started");

                  const typeFormReference = createPopup(
                    `https://form.typeform.com/to/nrUIPhqI?typeform-medium=embed-snippet#name=${user.data?.profile.firstName} ${user.data?.profile.lastName}&email=${user.data?.email}&userid=${user.data?.userId}&anonymousid=${anonymousId}`,
                    {
                      onSubmit: responseId => {
                        track("Send Feedback Submitted", {
                          responseId: responseId
                        });
                      }
                    }
                  );
                  typeFormReference.open();
                  setIsOpened(false);
                }}
              >
                <WSIcon block name="heart" mr="M" />
                <WSText.ParagraphSm color="gray600">
                  Give feedback
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>

              <WSDivider />

              <HeadwayNotification
                className={styles.headaway}
                isMobile={isMobile}
                isSideNavOpened={true}
                kind="help"
              />

              <WSFlexBox.CenterY
                className={styles.helpCenterItem}
                onClick={
                  isEarlyAccess
                    ? undefined
                    : () => {
                        history.push("/member/settings/account/early-access");
                        setIsOpened(false);
                      }
                }
              >
                <WSIcon block name="star" mr="M" color="amber400" />
                {isEarlyAccess ? (
                  <WSText.ParagraphSm color="gray600">
                    Early access member
                  </WSText.ParagraphSm>
                ) : (
                  <WSText.ParagraphSm color="blue500">
                    Request early access
                  </WSText.ParagraphSm>
                )}
              </WSFlexBox.CenterY>
            </WSElement>
          </>
        ) : null}
      </WSElement>
    </>
  );
};
