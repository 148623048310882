import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";

export const selectorTaxFormPayeeCountry = (
  data: IPayerTaxFormResponse | IPayeeTaxFormResponse
) => {
  return data.payee?.profile?.address?.country || "US";
};
