import React from "react";
import { WSQueryCache } from "@ws-react-query";
import {
  useWSSnackbar,
  WSMarginProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { useGoToPayeeDetails } from "../../utils";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { getCountryName } from "../../../../query/users/selectors";
import { usePayeesDownloadCSV } from "../../../../query/payee/mutations/usePayeesDownloadCSV";
import {
  IPayeeRow,
  PayeeRowsQuery,
  PayerPayeeEngagementSearchableStatus
} from "../../../../services/search";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { QUERY_PAYEE_ROWS } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import {
  WSTableColumn,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { useResendPayeeInvite } from "../../../../query/payee/mutations/useResendPayeeInvite";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useFocusedFeatures } from "../../../../FocusedFeatures";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";
import { useUserId } from "../../../../query/hooks/helpers";
import { PayerPayeeSearchableTinStatus } from "../../routes/RoutePayees/filtering";

export interface PayeeRowsTableProps extends WSMarginProps {
  isArchivedOnly?: boolean;
  filters?: PayeeRowsQuery;
  payees: IPayeeRow[];
  loading?: boolean;
}

export const PayeeRowsTable: React.FC<PayeeRowsTableProps> = ({
  filters,
  payees,
  isArchivedOnly,
  loading,
  ...elementProps
}) => {
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();
  const history = useHistory();
  const [downloadCSV, downloadCSVMeta] = usePayeesDownloadCSV();
  const [updatePayee] = useUpdatePayee();
  const [resendInvite, resendInviteMeta] = useResendPayeeInvite();
  const focusedFeatures = useFocusedFeatures();
  const isOrganizationContext = useXWingspanExpansion();
  const goToPayeeDetails = useGoToPayeeDetails();

  const tableData = payees.map(payee => {
    return {
      data: payee,
      id: payee.payeeId
    };
  });

  const handleRestoreContractor = async (payee: IPayeeRow) => {
    await updatePayee(
      {
        payeeId: payee.payeeId,
        payerOwnedData: {
          status: PayerPayeeStatus.Active
        }
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            WSQueryCache.invalidateQueries(QUERY_PAYEE_ROWS);
          }, 1000);

          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Contractor restored successfully"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to restore contractor`
          });
        }
      }
    );
  };

  return (
    <WSTable<IPayeeRow>
      loading={loading}
      tableData={tableData}
      onRowClick={({ data }) => {
        const isOrganizationPayee =
          !!isOrganizationContext &&
          !!data.payer &&
          !!data.payerId &&
          data.payerId !== userId;

        goToPayeeDetails(
          data.payeeId,
          isOrganizationPayee ? data.payerId : undefined
        );
      }}
      headerAction={{
        icon: "download",
        onClick: async () => {
          await downloadCSV({
            fileName: isArchivedOnly ? "Archived contractors" : "Contractors",
            ...filters
          });
        },
        loading: downloadCSVMeta.isLoading
      }}
      columns={[
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1.5fr",
            header: {
              text: "Name"
            }
          },
          renderFunction: ({ data }) => {
            const names = wsName({
              user: data.user!,
              member: data.member,
              payerOwnedData: data.payerOwnedData
            });
            return (
              <WSTableCell
                avatar={{
                  type: "text",
                  text: names.getResolvedName(true) || "--"
                }}
                text={names.getResolvedName(true)}
                secondaryText={names.getResolvedSecondaryName()}
              />
            );
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: isOrganizationContext ? "Organization" : "Contact"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => {
            const isOrganizationPayee =
              !!isOrganizationContext &&
              !!data.payer &&
              !!data.payerId &&
              data.payerId !== userId;

            if (isOrganizationContext) {
              let name = "-";
              if (data.payer?.user && isOrganizationPayee) {
                const names = wsName({
                  user: data.payer.user,
                  member: data.payer.member
                });

                name = names.getResolvedName();
              }

              return <WSTableCell text={name} />;
            } else {
              return <WSTableCell text={data.user?.email} />;
            }
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Tax ID"
            }
          },
          renderFunction: ({ data }) => {
            return <TaxIdCell data={data} />;
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Location"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => (
            <WSTableCell
              text={data.member?.profile?.address?.city}
              secondaryText={
                data.member?.profile?.address?.country
                  ? getCountryName(data.member?.profile?.address?.country)
                  : undefined
              }
            />
          )
        },
        // {
        //   config: {
        //     gridTemplateSizeMin: "0",
        //     gridTemplateSizeMax: "1fr",
        //     header: {
        //       text: "Engagements"
        //     }
        //   },
        //   renderFunction: ({ data }) => (
        //     <WSTableCell
        //       text={data.engagements?.[0]?.name}
        //       secondaryText={
        //         data.engagements?.length! > 1
        //           ? `${data.engagements?.length! - 1} more`
        //           : undefined
        //       }
        //     />
        //   )
        // },
        // always show archived column on archived page for clarity
        ...(focusedFeatures.showContractorsEligibility || isArchivedOnly
          ? ([
              {
                config: {
                  gridTemplateSizeMin: "0",
                  gridTemplateSizeMax: "1fr",
                  header: {
                    text: "Eligibility status"
                  }
                },
                renderFunction: ({ data }) => {
                  return <EligibilityStatusCell data={data} />;
                }
              }
            ] as Array<WSTableColumn<IPayeeRow>>)
          : [])
      ]}
      rowActions={({ data }) =>
        data.user.status === UserStatus.Pending &&
        data.payerOwnedData.status !== PayerPayeeStatus.Inactive
          ? [
              {
                text: "Resend invite",
                onAsyncClick: async () => {
                  await resendInvite(data.payeeId);
                }
              }
            ]
          : []
      }
      rowMenuActions={({ data }) =>
        isArchivedOnly
          ? ([
              {
                label: "Restore contractor",
                icon: "repeat-circle",
                onAsyncClick: async () => {
                  await handleRestoreContractor(data);
                }
              }
            ] as WSTableRowMenuAction<IPayeeRow>[])
          : data.user.status === UserStatus.Pending &&
            data.payerOwnedData.status !== PayerPayeeStatus.Inactive
          ? ([
              {
                label: "Resend invite",
                icon: "send",

                onAsyncClick: async () => {
                  await resendInvite(data.payeeId);
                }
              }
            ] as WSTableRowMenuAction<IPayeeRow>[])
          : []
      }
      {...elementProps}
    />
  );
};

export const TaxIdCell: React.FC<{ data: IPayeeRow }> = ({ data }) => {
  const isShared =
    data.payeeOwnedData.shareTaxDocument ===
    PayeeTaxDocumentSharePermission.Allow;
  const secondaryText = isShared ? "Contractor shared" : "Provided by you";
  const tin = isShared
    ? data?.payeeOwnedData?.payeeW9Data?.tinType === TinType.Business
      ? data?.payeeOwnedData?.payeeW9Data?.einLastFour
      : data?.payeeOwnedData?.payeeW9Data?.ssnLastFour
    : data?.payerOwnedData?.payeeW9Data?.tinType === TinType.Business
    ? data?.payerOwnedData?.payeeW9Data?.einLastFour
    : data?.payerOwnedData?.payeeW9Data?.ssnLastFour;

  if (data.searchableTinStatus === PayerPayeeSearchableTinStatus.Pending) {
    return (
      <WSTableCell
        pill={{
          icon: "time",
          theme: "neutral",
          text: `${tin ? `•••${tin} ` : ""}Pending`
        }}
        secondaryText={secondaryText}
      />
    );
  } else if (
    data.searchableTinStatus === PayerPayeeSearchableTinStatus.Verified
  ) {
    return (
      <WSTableCell
        pill={{
          icon: true,
          theme: "success",
          text: `${tin ? `•••${tin} ` : ""}Matched`
        }}
        secondaryText={secondaryText}
      />
    );
  } else if (
    data.searchableTinStatus === PayerPayeeSearchableTinStatus.Failed
  ) {
    return (
      <WSTableCell
        pill={{
          icon: true,
          theme: "warning",
          text: `${tin ? `•••${tin} ` : ""}Mismatch`
        }}
        secondaryText={secondaryText}
      />
    );
  } else {
    return <WSTableCell text={undefined} />;
  }
};

export const EligibilityStatusCell: React.FC<{ data: IPayeeRow }> = ({
  data
}) => {
  if (data.searchableStatus === PayerPayeeEngagementSearchableStatus.Inactive) {
    return (
      <WSTableCell
        pill={{
          icon: "archive",
          theme: "neutral",
          text: "Archived"
        }}
      />
    );
  } else if (
    data.searchableStatus === PayerPayeeEngagementSearchableStatus.Eligible
  ) {
    return (
      <WSTableCell
        pill={{
          icon: true,
          theme: "success",
          text: "Complete"
        }}
        secondaryText="Eligible for payment"
      />
    );
  } else if (
    data.searchableStatus === PayerPayeeEngagementSearchableStatus.NotEligible
  ) {
    return (
      <WSTableCell
        pill={{
          icon: true,
          theme: "warning",
          text: "Incomplete"
        }}
        secondaryText="Not yet eligible for payment"
        secondaryTextColor="amber400"
      />
    );
  } else if (
    data.searchableStatus === PayerPayeeEngagementSearchableStatus.NotSignedUp
  ) {
    return (
      <WSTableCell
        pill={{
          icon: true,
          theme: "warning",
          text: "Incomplete"
        }}
        secondaryText="Not yet signed up"
        secondaryTextColor="amber400"
      />
    );
  } else {
    return <WSTableCell text="--" />;
  }
};
