import startCase from "lodash/startCase";
import { IStatementInternalAccountType } from "../../../services/api/banking/statements/types";
import { InternalAccountType } from "../../../services/api/banking/types";

export const accountTypeToHumanReadable = (
  accountType: IStatementInternalAccountType
) => {
  switch (accountType) {
    case InternalAccountType.Banking:
      return "Wingspan Wallet";
    case InternalAccountType.Clearing:
      return "Clearing";
    case InternalAccountType.TaxWithholding:
      return "Tax Withholding";
    case InternalAccountType.CardClearing:
      return "Card Clearing";
    case InternalAccountType.InternationalClearing:
      return "International Clearing";
    default:
      return startCase(accountType);
  }
};
