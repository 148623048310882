import {
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableRowConfig
} from "../../types";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { selectorIsMemberClientRequirementResponse } from "../../selectors/selectorIsMemberClientRequirementResponse";
import { selectorIsRequirement } from "../../selectors/selectorIsRequirement";
import { usePayeeSignatureMemberClientRequirementResponseConfig } from "./cells/payee/usePayeeSignatureMemberClientRequirementResponseConfig";
import { usePayerSignatureMemberClientRequirementResponseConfig } from "./cells/payer/usePayerSignatureMemberClientRequirementResponseConfig";
import { usePayerSignatureRequirementConfig } from "./cells/payer/usePayerSignatureRequirementConfig";
import { usePayeeSignatureRequirementConfig } from "./cells/payee/usePayeeSignatureRequirementConfig";
import { useMemo } from "react";
import { concurrentActions } from "../../../../utils/serviceHelper";
import { getSignedDocument } from "../../../../services/signedDocuments";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_SIGNED_DOCUMENT } from "../../../../query/signedDocuments/keys";
import {
  ISignedDocument,
  ISignedDocumentResponse
} from "@wingspanhq/signed-documents/dist/lib/interfaces";

export const useSignatureRequirementTableConfig = (
  callback: RequirementTableCallback
): RequirementTableRowConfig[RequirementType.Signature] => {
  const payeeSignatureMemberClientRequirementResponseConfig =
    usePayeeSignatureMemberClientRequirementResponseConfig(callback);
  const payerSignatureMemberClientRequirementResponseConfig =
    usePayerSignatureMemberClientRequirementResponseConfig(callback);
  const payerSignatureRequirementConfig =
    usePayerSignatureRequirementConfig(callback);
  const payeeSignatureRequirementConfig =
    usePayeeSignatureRequirementConfig(callback);

  const cells = useMemo(() => {
    const allCells: Array<
      keyof Omit<
        RequirementTableRowConfig[RequirementType.Signature],
        "fetchContext"
      >
    > = ["nameCell", "typeCell", "statusCell", "rowActions", "rowMenuActions"];

    return allCells.reduce((acc, key) => {
      acc[key] = {
        [RequirementContextType.Payer]: (requirement, context) => {
          if (selectorIsMemberClientRequirementResponse(requirement)) {
            return payerSignatureMemberClientRequirementResponseConfig[key](
              requirement,
              context
            );
          } else if (selectorIsRequirement(requirement)) {
            return payerSignatureRequirementConfig[key](
              requirement,
              context
            ) as any;
          }
        },
        [RequirementContextType.Payee]: (requirement, context) => {
          if (selectorIsMemberClientRequirementResponse(requirement)) {
            return payeeSignatureMemberClientRequirementResponseConfig[key](
              requirement,
              context
            );
          } else if (selectorIsRequirement(requirement)) {
            return payeeSignatureRequirementConfig[key](
              requirement,
              context
            ) as any;
          }
        }
      };
      return acc;
    }, {} as RequirementTableRowConfig[RequirementType.Signature]);
  }, []);

  return {
    ...cells,
    async fetchContext(requirements) {
      const actions = requirements
        .filter(selectorIsRequirement)
        .filter(
          requirement =>
            requirement.requirementType === RequirementType.Signature
        )
        .map(requirement => async () => {
          try {
            let document = WSQueryCache.getQueryData<ISignedDocument>([
              QUERY_SIGNED_DOCUMENT,
              requirement.dataSourceId
            ]);

            if (!document) {
              document = await getSignedDocument(requirement.dataSourceId);
              WSQueryCache.setQueryData(
                [QUERY_SIGNED_DOCUMENT, requirement.dataSourceId],
                document
              );
            }

            return document;
          } catch (e) {}
        });

      const signedDocuments = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return signedDocuments.filter(
        document => document
      ) as ISignedDocumentResponse[];
    }
  };
};
