import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMutationUpdateAcknowledgement } from "../../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { QUERY_USERS_ACTIVITIES } from "../../../../query/users/keys";
import { usersService } from "../../../../services/users";
import { acknowledgementVersions } from "../../../../shared/constants/acknowledgements";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (config?: WSMutationConfig<void>) => {
  const userId = useUserId();
  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  return useWSMutation<any, WSServiceError>(
    async () => {
      await usersService.activity.update(userId, {
        events: {
          w8BenFormAcknowledged: new Date()
        }
      });

      await updateAcknowledgement({
        acknowledgementName: "W8BenCertification",
        requestData: {
          acknowledgementName: "W8BenCertification",
          acknowledgementStatus: "Given",
          version: acknowledgementVersions["W8BenCertification"]
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );
};
