import {
  WSIcon,
  WSIconName,
  WSTooltip,
  WSTooltipProps
} from "@wingspanhq/fe-component-library";
import { WSIconProps } from "@wingspanhq/fe-component-library/dist/lib/components/core/WSIcon/WSIcon.component";
import React, { useRef } from "react";

export type InfoTooltipProps = {
  iconProps?: Omit<WSIconProps, "name"> & { name?: WSIconName };
  tooltipProps?: WSTooltipProps;
  text: React.ReactNode;
};

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  iconProps,
  tooltipProps,
  text
}) => {
  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  return (
    <>
      <WSTooltip target={tooltipIconRef} children={text} {...tooltipProps} />
      <WSIcon
        ref={tooltipIconRef}
        name="info-circle-fill"
        color="gray500"
        size="S"
        {...iconProps}
      />
    </>
  );
};
