import {
  detachLayoutProps,
  WSMarginProps,
  WSSelect
} from "@wingspanhq/fe-component-library";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";
import { useUserProfile } from "../../../../query/users/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { selectorIsEngagementsActive } from "../../../../shared/selectors/selectorIsEngagementsActive";

export const defaultCollaboratorGroupOption = {
  label: "Do not add contractors to group",
  value: "NONE"
};

type Props = WSMarginProps & {
  supportCollaboratorGroups?: boolean;
  selectedEngagementId: string;
  onSelectEngagement: (option: { label: string; value: string } | null) => void;

  selectedCollaboratorGroupId?: string;
  onSelectCollaboratorGroup?: (option: {
    label: string;
    value: string;
  }) => void;
};
export const SelectEngagementField: React.FC<Props> = ({
  selectedEngagementId,
  onSelectEngagement,

  selectedCollaboratorGroupId,
  onSelectCollaboratorGroup,
  supportCollaboratorGroups = true,

  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryCollaboratorGroups = useCollaboratorGroupsQuery({
    enabled: supportCollaboratorGroups
  });
  const queryEngagements = useEngagementsListAllQuery({
    filter: {
      isDefault: false
    }
  });

  const engagements = queryEngagements.data || [];
  const collaboratorGroups = queryCollaboratorGroups.data || [];
  const user = queryUser.data!;

  const engagementOptions = engagements
    .map(engagement => ({
      label: engagement.name,
      value: engagement.engagementId
    }))
    .filter(option => option.label && option.value); // filter out empty values

  const collaboratorGroupOptions = [
    defaultCollaboratorGroupOption,
    ...collaboratorGroups.map(collaboratorGroup => ({
      label: collaboratorGroup.name,
      value: (collaboratorGroup as any).collaboratorGroupId
    }))
  ];

  const handleSelectCollaboratorGroup = (value: any) => {
    const collaboratorGroup = collaboratorGroupOptions.find(
      cg => cg.value === value
    );
    onSelectCollaboratorGroup?.(
      collaboratorGroup ?? defaultCollaboratorGroupOption
    );
  };

  const handleSelectEngagement = (value: any) => {
    const engagement = engagementOptions.find(e => e.value === value);
    onSelectEngagement(engagement ?? null);
  };

  const showEngagementField =
    selectorIsEngagementsActive(user) && engagementOptions.length > 0;

  const showCollaboratorGroupField =
    supportCollaboratorGroups && collaboratorGroupOptions.length > 0;

  if (showEngagementField) {
    return (
      <WSSelect
        {...layoutProps}
        label="Engagement (optional)"
        value={selectedEngagementId}
        onChange={handleSelectEngagement}
        tooltip={{
          dark: true,
          title: "Engagement (optional)",
          children: `An engagement is a type of work arrangement that allows payers to organize their diverse work relationships in a flexible way. One engagement can be assigned when adding a new contractor. Other engagements can be added later.
            
    If you have not set up engagements or do not plan to use engagements, you can skip this field. Engagements can be updated in the Contractors section of the app at any time.`
        }}
        options={engagementOptions}
        internalSearch
        placeholder="Select an option"
      />
    );
  } else if (showCollaboratorGroupField) {
    return (
      <WSSelect
        {...layoutProps}
        label="Contractor group"
        value={selectedCollaboratorGroupId}
        onChange={handleSelectCollaboratorGroup}
        options={collaboratorGroupOptions}
        internalSearch
        placeholder="Select a collaborator group"
      />
    );
  }
  return null;
};
