import { IMemberProfileWrite } from "@wingspanhq/users/dist/lib/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../../../../query/helpers";
import { useUserId } from "../../../../../../query/hooks/helpers";
import {
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../../../../query/users/keys";
import { usersService } from "../../../../../../services/users";
import { formDataToRequestAddress } from "../../../../../../shared/components/FormPartialAddress";
import { getChangedData } from "../../../../../../utils/getChangedData";
import { WSServiceError } from "../../../../../../utils/serviceHelper";
// import { IdentificationNumberType } from "../../types";
import { FormData } from "./types";
import { TinType } from "@wingspanhq/payments/dist/interfaces";

export const useMutationSubmit = (
  config?: WSMutationConfig<FormData, WSServiceError>
) => {
  const userId = useUserId();

  return useWSMutation<FormData, WSServiceError, FormData>(
    async data => {
      if (data.identificationNumberType === TinType.Individual) {
        let user = await usersService.user.get(userId);
        user = await usersService.user.update(userId, {
          profile: getChangedData(user.profile, {
            firstName: data.firstName,
            lastName: data.lastName
          })
        });
      }

      let member = await usersService.member.get(userId);
      let memberProfileRequest: IMemberProfileWrite;
      if (data.identificationNumberType === TinType.Individual) {
        memberProfileRequest = {
          company: {
            structure: data.taxClassification
          },
          address: formDataToRequestAddress(data.address)
        };

        if (data.ssn) {
          memberProfileRequest.ssn = data.ssn;
        }
      } else {
        memberProfileRequest = {
          company: {
            taxId: data.ein,
            structure: data.taxClassification,
            legalBusinessName: data.businessName,
            name: data.businessName
          },
          address: formDataToRequestAddress(data.address)
        };
      }

      memberProfileRequest.formW9Options = {
        exemptFatcaCode: data.exemptFatcaReportingCode,
        exemptPayeeCode: data.exemptPayeeCode,
        tinPreference: data.identificationNumberType
      };

      member = await usersService.member.update(userId, {
        memberId: userId,
        profile: getChangedData(member, memberProfileRequest)
      });

      return data;
    },
    {
      awaitDependencies: [QUERY_USERS_USER_PROFILE, QUERY_USERS_MEMBER_PROFILE],
      ...config
    }
  );
};
