import {
  WSActions,
  WSControl,
  WSDivider,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";

import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SetupNewCorrectionProps } from "./index";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../query/WSQuery";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { selectorTaxFormRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormRecipientName";
import { AmountCorrectionDiffPreview } from "../../../TaxCorrections/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxCorrections/components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../../../TaxCorrections/components/AddressCorrectionDiffPreview";
import { selectorPayeeTaxFormPayerCompanyName } from "../../selectors/selectorPayeeTaxFormPayerCompanyName";
import { getCorrectionType } from "../../../TaxCorrections/utils/getCorrectionType";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { TaxFormViewer } from "../../../TaxDocuments/types";
// import { UnchangedRecipientDetails } from "../../components/UnchangedRecipientDetails";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import uniq from "lodash/uniq";
import { createRequestCorrectionPayload } from "../../../TaxCorrections/utils/createRequestCorrectionPayload";
import { useTaxFormRequestCorrection } from "../../../../query/taxForm/mutations/useTaxFormRequestCorrection";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";
import { useOnCancelNewCorrection } from "./useOnCancelNewCorrection";
import { AreYouReadyForCorrection } from "../../../TaxCorrections/components/AreYouReadyForCorrection";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_PAYMENTS_TAXFORM_PAYER } from "../../../../query/taxForm/keys";

export const RouteReviewAndSubmitCorrection: React.FC<
  SetupNewCorrectionProps
> = ({ onNext, onBack }) => {
  const currentYear = useCurrentTaxFilingYear();
  const [consent, setConsent] = useState(false);
  const onCancel = useOnCancelNewCorrection();

  const history = useHistory<{
    corrections: string[];
    correctionsFormData: any;
    isForce: boolean;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();

  const corrections = uniq(history.location.state?.corrections ?? []);
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;
  const isForce = !!history.location.state?.isForce;

  const onEdit = () => {
    onNext({
      isEdit: true,
      corrections,
      correctionsFormData
    });
  };

  const onEditUnchangedDetails = () => {
    const correctionTypeData = getCorrectionType(
      queryTaxForm.data as IPayerTaxFormResponse,
      correctionsFormData
    );

    const newCorrectionTypes = [
      // applied corrections list after comparing corrections data with original data
      ...correctionTypeData.corrections,
      // unchanged recipient details corrections list
      ...(!correctionTypeData.corrections.includes(CORRECTION_TIN_OR_NAME)
        ? [CORRECTION_TIN_OR_NAME]
        : []),
      ...(!correctionTypeData.corrections.includes(CORRECTION_ADDRESS)
        ? [CORRECTION_ADDRESS]
        : [])
    ];

    onNext({
      isEdit: true,
      corrections: newCorrectionTypes,
      correctionsFormData: {
        ...getDefaultValuesForCorrectionsForm(
          newCorrectionTypes,
          queryTaxForm.data as IPayerTaxFormResponse
        ),
        ...correctionsFormData
      }
    });
  };

  const onSubmitCorrection = async () => {
    await requestCorrection(
      {
        taxFormId,
        requestData: createRequestCorrectionPayload(
          correctionsFormData,
          queryTaxForm?.data as IPayerTaxFormResponse,
          TaxFormViewer.Payer,
          isForce
        )
      },
      {
        onSuccess: res => {
          WSQueryCache.removeQueries(QUERY_PAYMENTS_TAXFORM_PAYER);
          onNext({ isSuccess: true, submissionId: res.submissionId });
        }
      }
    );
  };

  const onUpdateRecipientInfo = () => {
    // BE will update the recipient info without creating any creation
    onSubmitCorrection();
  };

  return (
    <EditLayout title="Submit correction to the IRS" noBack onClose={onCancel}>
      <PreventLeave isEnabled={true} />

      <WSQueries
        queries={{
          queryTaxForm
        }}
      >
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          const { corrections, addressStateNotChanged, isVoidType } =
            getCorrectionType(taxForm, correctionsFormData);

          const hasUnchangedDetails =
            !corrections.includes(CORRECTION_TIN_OR_NAME) ||
            !corrections.includes(CORRECTION_ADDRESS);

          const isType1Correction =
            corrections.length === 1 && corrections.includes(CORRECTION_AMOUNT);

          const isOnlyAddressCorrectionAndStateNotChanged =
            corrections.length === 1 &&
            corrections.includes(CORRECTION_ADDRESS) &&
            addressStateNotChanged;

          const correctionTypeToPreviewComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                taxFormViewer={TaxFormViewer.Payer}
                amount={taxForm?.data?.totalAmount ?? 0}
                correctedAmount={correctionsFormData.necTotalAmount}
                comment={correctionsFormData.amountCorrectionHelpText}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionDiffPreview
                isVoidType={isVoidType}
                isForce={isForce}
                showRejectOptionText={true}
                taxForm={taxForm}
                identificationNumberType={
                  correctionsFormData.identificationNumberType
                }
                onEdit={onEdit}
                taxFormViewer={TaxFormViewer.Payer}
                originalTinData={getPayeeTINorName(taxForm)}
                correctedTinData={{
                  ssn: correctionsFormData.ssn,
                  ein: correctionsFormData.ein,
                  businessName: correctionsFormData.businessName,
                  firstName: correctionsFormData.firstName,
                  lastName: correctionsFormData.lastName,
                  disregardedEntityName:
                    correctionsFormData.disregardedEntityName,
                  taxClassification:
                    correctionsFormData.taxClassification as CompanyStructure
                }}
                reasonForChange={correctionsFormData.reasonForChange}
                otherReason={correctionsFormData.otherReason}
              />
            ),
            [CORRECTION_ADDRESS]: (
              <>
                <AddressCorrectionDiffPreview
                  taxForm={taxForm}
                  onEdit={onEdit}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  correctedAddress={correctionsFormData.formW9Address}
                />
                {isOnlyAddressCorrectionAndStateNotChanged ? (
                  <WSText.ParagraphSm my="S" color="gray500">
                    The address change does not require a correction to be filed
                    with the IRS because the filing State did not change.
                    Confirm and update the recipient information or edit to make
                    other corrections.
                  </WSText.ParagraphSm>
                ) : null}
              </>
            )
          };

          return (
            <>
              <CorrectionFlowHeader year={currentYear} name={recipientName} />

              <WSText.Paragraph weight="medium">
                Review and submit correction
              </WSText.Paragraph>
              <WSText.ParagraphSm color="gray500" mt="S" mb="XL">
                Please review the corrections thoroughly before submitting a
                correction to the IRS.
              </WSText.ParagraphSm>

              {corrections.map(correctionType => {
                const DiffPreviewComponent =
                  correctionTypeToPreviewComponentMap[correctionType];
                return (
                  <React.Fragment key={correctionType}>
                    <WSElement mb="L">{DiffPreviewComponent}</WSElement>
                    <WSDivider my="XL" />
                  </React.Fragment>
                );
              })}
              {/*
              {hasUnchangedDetails ? (
                <UnchangedRecipientDetails
                  onEdit={onEditUnchangedDetails}
                  taxForm={taxForm}
                  corrections={corrections}
                />
              ) : null}
              */}
              {!isOnlyAddressCorrectionAndStateNotChanged ? (
                <AreYouReadyForCorrection
                  isType1Correction={isType1Correction}
                  amountChanges={corrections.includes(CORRECTION_AMOUNT)}
                  tinAddressChanges={
                    corrections.includes(CORRECTION_TIN_OR_NAME) ||
                    corrections.includes(CORRECTION_ADDRESS)
                  }
                />
              ) : null}

              {!isOnlyAddressCorrectionAndStateNotChanged ? (
                <WSControl
                  mt="L"
                  size="S"
                  type="checkbox"
                  name="consent"
                  value={consent}
                  onChange={setConsent}
                  label={`Under penalties of perjury, I declare that to the best of my knowledge. ${selectorPayeeTaxFormPayerCompanyName(
                    taxForm
                  )} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.`}
                />
              ) : null}

              <WSErrorMessage
                mt="M"
                contextKey={ErrorContextKey.NEC1099PayerCorrection}
                error={requestCorrectionMeta.error}
              />

              <WSActions
                mt="XL"
                alignment="fill"
                buttons={[
                  {
                    label: "Update recipient information",
                    onClick: onUpdateRecipientInfo,
                    visible: isOnlyAddressCorrectionAndStateNotChanged
                  },
                  {
                    label: "Submit correction to the IRS",
                    onClick: onSubmitCorrection,
                    loading: requestCorrectionMeta.isLoading,
                    disabled: !consent,
                    hidden: isOnlyAddressCorrectionAndStateNotChanged
                  },
                  {
                    label: "Cancel",
                    onClick: onCancel,
                    destructive: true
                  }
                ]}
              />
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
