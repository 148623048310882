import * as Yup from "yup";
import { getValidationSchemaAddress } from "../../../modules/Onboarding/components/FormPartialAddress";
import { getValidationSchemaBankCard } from "../FormPartialBankCard";

export const validationSchema = Yup.object().shape({
  card: getValidationSchemaBankCard(),
  country: Yup.string().nullable().required("Required"),
  address: Yup.lazy((_, context) => {
    const country = context?.parent?.country;
    return getValidationSchemaAddress(country, {
      allowPOBox: true
    });
  })
});
