import {
  useWSSnackbar,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useMutationSubmit } from "./useMutationSubmit";

export type FormW9ConsentProps = {
  onClose: () => void;
  clients: {
    clientId: string;
    name: string;
  }[];
} & WSElementProps;

export const W9Consent: React.FC<FormW9ConsentProps> = ({
  onClose,
  clients,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const [shareW9, setShareW9] = useState(false);
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess() {
      openSnackbar({
        type: "success",
        duration: 3000,
        message: `"Consent to share W-9 information" complete`
      });
      onClose();
    }
  });

  const names = clients.map(client => client.name).join(", ");

  return (
    <WSElement {...elementProps}>
      <WSText>
        {`To receive payments from ${names}, please consent to sharing your Form W-9 information with them.`}
      </WSText>

      <WSCheckboxToggle
        my="3XL"
        name="shareW9"
        label={`I consent to share my Form W-9 with ${names}`}
        value={shareW9}
        onChange={value => {
          setShareW9(value);
        }}
      />

      <WSButton
        disabled={!shareW9}
        mb="M"
        onClick={() => submit(clients.map(client => client.clientId))}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Submit
      </WSButton>
    </WSElement>
  );
};
