import {
  WSAvatarProps,
  WSTable,
  WSTableProps
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { components } from "../../../../services/api/banking/types";
import { disputeTransaction } from "../../utils/disputeTransaction";
import { columns } from "./columns";
import { getAvatar, getDescription, getGroupName, getName } from "./helpers";

export type ItemData = {
  transaction: components["schemas"]["Transaction"];
  avatar: WSAvatarProps;
  name: string;
  description: string;
};

type Props = {
  transactions: components["schemas"]["Transaction"][];
} & Omit<WSTableProps, "tableData">;

export const TableTransactions: React.FC<Props> = ({
  transactions,
  ...tableProps
}) => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const tableData = useMemo(
    () =>
      transactions.map(transaction => ({
        id: transaction.transactionId,
        data: {
          transaction,
          avatar: getAvatar(transaction),
          name: getName(transaction),
          description: getDescription(transaction)
        }
      })),
    [transactions]
  );

  return (
    <WSTable<ItemData>
      tableData={tableData}
      columns={columns}
      getGroupName={row => getGroupName(row.data.transaction)}
      rowMenuActions={item => {
        return [
          {
            label: "Dispute transaction",
            icon: "dispute",
            onClick: () => {
              disputeTransaction(item.data.transaction, queryMember.data!);
            }
          }
        ];
      }}
      {...tableProps}
    />
  );
};
