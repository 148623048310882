import { useFlatfile } from "@flatfile/react";
import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import {
  useCurrentScreenSize,
  WSActionsButton,
  WSActionsProps,
  WSMenuIconItem,
  WSMenuItem,
  WSPageToolbarAction
} from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "../query/bulkCollaborator/keys";
import { WSQueryCache } from "@ws-react-query";
import { useConfirmImportModal } from "../components/ConfirmImportModal";
import { BulkImportResource, BulkResource } from "../types";
import {
  isAPIUpload,
  isBatchFullyImported,
  isBatchPartiallyImported,
  isFileUploaded,
  isUploadFinished,
  isUploadInProgress
} from "../utils/bulkBatchUtils";
import { useModalConfirmBulkBatchDelete } from "../components/ModalConfirmBulkBatchDelete";
import { getBulkQueriesByResource } from "../query/bulkBatchResourceQueryMap";
import {
  BULK_PAYABLE_BATCH_LIST,
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_SUMMARY
} from "../query/bulkPayable/keys";

type Props = {
  bulkBatchId: string;
  bulkResource: BulkImportResource;
  isEmptyBatch: boolean;
  downloadExampleCSV: () => void;
  downloadErrorsCSV: () => void;
  hideButtonOn?: WSPageToolbarAction["hideButtonOn"];
};

export function useBulkBatchActions({
  bulkBatchId,
  bulkResource,
  isEmptyBatch,
  downloadExampleCSV,
  downloadErrorsCSV,
  hideButtonOn
}: Props): WSPageToolbarAction[] {
  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const bulkBatch = queryBulkBatch.data;

  const onClosePayableFlatfileModal = () => {
    WSQueryCache.invalidateQueries(BULK_PAYABLE_BATCH_LIST);
    WSQueryCache.invalidateQueries([QUERY_BULK_PAYABLE_BATCH, bulkBatchId]);
    WSQueryCache.invalidateQueries(QUERY_BULK_PAYABLE_BATCH_SUMMARY);
  };
  const onCloseContractorFlatfileModal = () => {
    WSQueryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_LIST);
    WSQueryCache.invalidateQueries([
      QUERY_BULK_COLLABORATOR_BATCH,
      bulkBatchId
    ]);
    WSQueryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_SUMMARY);
  };
  let onClose = onClosePayableFlatfileModal;
  if (bulkResource === BulkResource.Collaborator) {
    onClose = onCloseContractorFlatfileModal;
  }
  const { openPortal } = useFlatfile({
    onClose
  });

  const confirmBulkBatchDeleteModal = useModalConfirmBulkBatchDelete();

  const confirmImportModal = useConfirmImportModal(bulkResource);

  const actions: WSPageToolbarAction[] = useMemo(() => {
    if (!bulkBatch) {
      return [];
    }

    const labels = {
      [BulkResource.Payable]: "payables",
      [BulkResource.Collaborator]: "contractors"
    };

    const commonActions: WSPageToolbarAction[] = [
      {
        separator: true
      },
      {
        label: "Delete batch",
        labelProps: {
          color: "red400"
        },
        onClick: () => {
          confirmBulkBatchDeleteModal.open({
            bulkBatchId,
            isEmptyBatch,
            bulkResource
          });
        },
        icon: "trash",
        iconProps: {
          color: "red400"
        },
        hideButtonOn
      }
    ];

    // complete state actions
    if (isBatchFullyImported(bulkBatch)) {
      return [
        {
          label: "View upload",
          onClick: () => openPortal(),
          buttonKind: "Secondary",
          icon: "view",
          hideButtonOn
        }
      ];
    }

    // failed OR partially imported state actions
    if (
      isBatchPartiallyImported(bulkBatch) ||
      bulkBatch.status === BulkStatus.Failed
    ) {
      return [
        // this will be hidden for status widget and shown in page toolbar
        {
          label: "View upload",
          onClick: () => openPortal(),
          buttonKind: "Secondary",
          icon: "view",
          name: "viewUploadInToolbar",
          hideButtonOn
        },
        // this will be shown in status widget and hidden in page toolbar
        {
          label: "Download spreadsheet",
          onClick: downloadErrorsCSV,
          buttonKind: "Secondary",
          buttonOrder: 1,
          icon: "download",
          name: "downloadErrorsCSV",
          hideButtonOn
        }
      ];
    }
    // importing state actions
    if (
      [BulkStatus.Pending, BulkStatus.Processing].includes(bulkBatch.status)
    ) {
      return [
        {
          label: "View upload",
          onClick: () => openPortal(),
          buttonKind: "Secondary",
          icon: "view",
          hideButtonOn
        }
      ];
    }

    // upload finished state actions
    if (isUploadFinished(bulkBatch, isEmptyBatch)) {
      return [
        {
          label: `Import ${labels[bulkResource]}`,
          onClick: () =>
            confirmImportModal.open({
              bulkBatchId,
              bulkResource
            }),
          buttonKind: "Primary",
          icon: "user",
          hideButtonOn
        },
        ...(isAPIUpload(bulkBatch)
          ? []
          : [
              {
                label: "View upload",
                onClick: () => openPortal(),
                buttonKind: "Secondary",
                buttonOrder: 1,
                icon: "view",
                hideButtonOn
              }
            ]),
        ...commonActions
      ] as WSPageToolbarAction[];
    }

    // upload in progress state actions
    if (isUploadInProgress(bulkBatch)) {
      return [
        {
          label: "View upload progress",
          onClick: () => openPortal(),
          buttonKind: "Secondary",
          icon: "view",
          hideButtonOn
        },
        ...commonActions
      ];
    }

    // empty state but file uploaded state actions
    if (isFileUploaded(bulkBatch)) {
      return [
        {
          label: "View upload",
          onClick: () => openPortal(),
          buttonKind: "Secondary",
          icon: "view",
          hideButtonOn
        },
        ...commonActions
      ];
    }

    // empty state actions
    return [
      {
        label: `Upload ${labels[bulkResource]}`,
        onClick: () => openPortal(),
        buttonKind: "Primary",
        icon: "upload",
        hideButtonOn
      },
      {
        label: "Download example",
        onClick: downloadExampleCSV,
        buttonKind: "Secondary",
        buttonOrder: 1,
        icon: "download",
        hideButtonOn
      },
      ...commonActions
    ];
  }, [
    bulkBatchId,
    bulkResource,
    bulkBatch,
    isEmptyBatch,
    downloadExampleCSV,
    downloadErrorsCSV,
    hideButtonOn,
    openPortal,
    confirmBulkBatchDeleteModal,
    confirmImportModal
  ]);

  return actions;
}

export function useBulkBatchStatusActions(props: Props): WSActionsProps {
  const actions = useBulkBatchActions(props);
  const currentScreenSize = useCurrentScreenSize();
  const menuItems: WSMenuItem[] = useMemo(() => {
    return actions.map(
      ({ buttonKind, hideButtonOn, buttonOrder, ...menuItem }) => menuItem
    );
  }, [actions]);

  const buttons: WSActionsButton[] = useMemo(() => {
    const actionButtons: WSActionsButton[] = [];

    const filteredActions = actions.filter(action => action.buttonKind);

    sortBy(filteredActions, ({ buttonOrder = 1 }) => buttonOrder).forEach(
      ({ buttonKind, buttonOrder, hideButtonOn = ["XS"], ...action }) => {
        const buttonProps = action as WSMenuIconItem;

        const sharedProps = {
          onClick: buttonProps.onClick,
          onAsyncClick: action.onAsyncClick,
          loading: action.loading,
          label: buttonProps.label,
          icon: buttonProps.icon,
          kind: buttonKind,
          ...action
        } as WSActionsButton;

        const isVisible = Array.isArray(hideButtonOn)
          ? !hideButtonOn.includes(currentScreenSize)
          : true;

        if (buttonKind && isVisible) {
          actionButtons.push({
            ...sharedProps,
            label: buttonProps.label as string
          });
        }
      }
    );

    return actionButtons;
  }, [actions, currentScreenSize]);

  return {
    menuItems,
    buttons: buttons.filter(btn => btn.name !== "viewUploadInToolbar")
  };
}
