import {
  useIsMobile,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { WingspanProducts } from "@wingspanhq/users/dist/lib/interfaces";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../Auth/components/Header/Header";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCMSWebflowPartner } from "../../query/cms/queries";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useSetWSStore, useWSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";
import { OnboardingQuestion } from "../components/OnboardingQuestion/OnboardingQuestion";
import styles from "./styles.module.scss";

interface OnboardingStep2Props {}

export const OnboardingStep2: React.FC<OnboardingStep2Props> = () => {
  const [answer, setAnswer] = useLocalStorage("pre-sign-up:q2", "");
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();

  const partnerId =
    (store.growthAttributionDetails?.growthSource ===
      GrowthSourceNames.Partner &&
      store.growthAttributionDetails?.growthName) ||
    "";
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: !!partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  const partner = qPartner.data;

  const onBack = () => {
    history.push(`/member/pre-sign-up/questionnaire/1${location.search}`);
  };

  const onSubmit = async (data: any) => {
    setAnswer(data.productImportance);

    if (data.productImportance === WingspanProducts.Payments) {
      history.push(
        `/member/pre-sign-up/questionnaire/3/payments${location.search}`
      );
    } else if (data.productImportance === WingspanProducts.Bookkeeping) {
      history.push(
        `/member/pre-sign-up/questionnaire/3/bookkeeping${location.search}`
      );
    } else if (data.productImportance === WingspanProducts.Benefits) {
      history.push(
        `/member/pre-sign-up/questionnaire/3/benefits${location.search}`
      );
    }
  };
  return (
    <WSLayout
      onBack={isMobile ? undefined : onBack}
      line
      headerCenter={<Header hideBorder partnerLogo={partner?.logo?.src} />}
    >
      <BrowserPageTitle title="Onboarding: Step 2" />
      <WSMessageBox kind="Warning" noBorder centered className={styles.banner}>
        {" "}
      </WSMessageBox>
      <WSContainer verticalPadding>
        {isMobile ? (
          <WSFlexBox.CenterY mb="M" onClick={onBack}>
            <WSIcon
              block
              name="chevron-left"
              size="XS"
              mr="XS"
              color="gray700"
            />
            <WSText.ParagraphSm color="gray500">
              <b>Back</b>
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : null}
        <OnboardingQuestion
          name="productImportance"
          question={{
            question: (
              <WSText.Heading4 inline>
                You're our people!
                <WSText.Heading4>Which is most top of mind?</WSText.Heading4>
              </WSText.Heading4>
            ),
            options: [
              {
                label: "Making money",
                value: WingspanProducts.Payments,
                virtualAssistantMsg:
                  "Our platform enables you to send professional invoices, manage numerous clients, and automate complex pay schedules.",
                ctaBtnText: "Make more money"
              },
              {
                label: "Managing money",
                value: WingspanProducts.Bookkeeping,
                virtualAssistantMsg:
                  "Wingspan automatically handles bookkeeping, expenses, and even finds tax deductions for you.",
                ctaBtnText: "Save more money"
              },
              {
                label: "Big company benefits & perks",
                value: WingspanProducts.Benefits,
                virtualAssistantMsg:
                  "Wingspan can help you get the coverage you need and the perks you want with custom insurance and benefits packages for you and your family.",
                ctaBtnText: "Get covered"
              }
            ]
          }}
          onSubmit={onSubmit}
          defaultValues={{
            productImportance: answer
          }}
        />
      </WSContainer>
    </WSLayout>
  );
};
