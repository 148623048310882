import {
  IPayeeResponse,
  IPayeeUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";

import { servicePayments } from "../servicePayments";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const updatePayee = async (
  id: string,
  request: IPayeeUpdateRequest,
  orgPayerId?: string | undefined
) => {
  const { data } = await servicePayments.patch(
    `/payee/${id}`,
    request,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data as IPayeeResponse;
};
