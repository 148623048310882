import { IWebflowPartner } from "@wingspanhq/cms/dist/lib/interfaces/webflow/partners";
import {
  IGrantedSubscriberResponse,
  INextgenSubscriptionCreateRequest,
  ISubscription,
  ISubscriptionGrantCreateRequest,
  ISubscriptionGrantUpdateRequest,
  ISubscriptionUpdateRequest
} from "@wingspanhq/users";
import { WSMutationsConfig } from "@ws-react-query";
import { usersService } from "../../services/users";
import { IS_DEV_ENV } from "../../shared/constants/environment";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { track } from "../../utils/analytics";
import { WSServiceError } from "../../utils/serviceHelper";
import { useCMSWebflowPartner } from "../cms/queries";
import { useWSMutation } from "../helpers";
import {
  QUERY_NEXT_GEN_SUBSCRIPTION_LIST,
  QUERY_SUBSCRIPTION_GRANT,
  QUERY_SUBSCRIPTION_GRANT_LIST,
  QUERY_SUBSCRIPTION_PAYMENT_METHOD,
  QUERY_SUBSCRIPTION_V3
} from "./keys";
import { useSubscriptionV3Query } from "./queries";

export const useCreateOrUpdateSubscriptionV3 = (
  memberId: string,
  config?: WSMutationsConfig<ISubscription, WSServiceError>
) => {
  const subscriptionQuery = useSubscriptionV3Query(memberId);
  const [signUpPartner] = useLocalStorage("sign-up-with-partner", "");
  const qPartner = useCMSWebflowPartner(signUpPartner, {
    enabled: !!signUpPartner
  });

  return useWSMutation(
    async (data: ISubscriptionUpdateRequest) => {
      let subscription: ISubscription | undefined;

      if (subscriptionQuery.data) {
        // If it's already loaded – it fine
        subscription = subscriptionQuery.data;
      } else if (subscriptionQuery.isLoading) {
        // If there is no subscription in initial query AND query is still loading – we need to wait until it's loaded. Only one way to do it asynchronously – is run refetch
        subscription = await subscriptionQuery.refetchData();
      }

      let response;
      if (subscription) {
        response = await usersService.subscriptionV3.update(memberId, data);
      } else {
        let grantedSubscriberList: IGrantedSubscriberResponse[] = [];
        try {
          grantedSubscriberList = await usersService.grantedSubscriber.list();
        } catch (e) {}
        const partner = qPartner.data as IWebflowPartner;
        let request = data;
        let discountCode;
        if (partner?.stripeDiscountCode) {
          discountCode = partner?.stripeDiscountCode;
        }
        if (partner?.stripeDiscountCode && IS_DEV_ENV) {
          discountCode = "PARTNERCODE";
        }
        request = {
          ...data,
          ...(discountCode ? { discountCode } : {})
        };
        /**
         * Add subscriptionGrantId when logged-in user has subscription-grants.
         */
        const userSubscriptionGrants =
          grantedSubscriberList?.filter(sg => sg.memberId === memberId) || [];
        if (userSubscriptionGrants?.length > 0) {
          request.subscriptionGrantId =
            userSubscriptionGrants[0].subscriptionGrantId;
        }
        response = await usersService.subscriptionV3.create(memberId, request);
        track("Trial Started");
      }
      return response;
    },
    {
      dependencies: [QUERY_SUBSCRIPTION_V3, QUERY_SUBSCRIPTION_PAYMENT_METHOD],
      ...config
    }
  );
};

export const useCancelSubscriptionV3 = (memberId: string) =>
  useWSMutation(
    async () => {
      await usersService.subscriptionV3.delete(memberId);
    },
    {
      dependencies: [QUERY_SUBSCRIPTION_V3]
    }
  );

export const useCreateSubscriptionGrant = () =>
  useWSMutation(
    (request: ISubscriptionGrantCreateRequest) =>
      usersService.subscriptionGrant.create(request),
    {
      dependencies: [QUERY_SUBSCRIPTION_GRANT, QUERY_SUBSCRIPTION_GRANT_LIST]
    }
  );

export const useUpdateSubscriptionGrant = () =>
  useWSMutation(
    (request: ISubscriptionGrantUpdateRequest & { id: string }) => {
      const { id, ...data } = request;
      return usersService.subscriptionGrant.update(id, data);
    },
    {
      dependencies: [QUERY_SUBSCRIPTION_GRANT, QUERY_SUBSCRIPTION_GRANT_LIST]
    }
  );

export const useDeleteSubscriptionGrant = () =>
  useWSMutation((id: string) => usersService.subscriptionGrant.delete(id), {
    dependencies: [QUERY_SUBSCRIPTION_GRANT, QUERY_SUBSCRIPTION_GRANT_LIST]
  });

// Next gen subscriptions

export const useCreateNextGenSubscription = (memberId: string) =>
  useWSMutation(
    async (request: INextgenSubscriptionCreateRequest) => {
      await usersService.nextGenSubscription.create(memberId, request);
    },
    {
      dependencies: [QUERY_NEXT_GEN_SUBSCRIPTION_LIST]
    }
  );

export const useUpdateNextGenSubscription = (memberId: string) =>
  useWSMutation(
    async (request: any) => {
      const { subscriptionId, ...payload } = request;
      await usersService.nextGenSubscription.update(
        memberId,
        subscriptionId,
        payload
      );
    },
    {
      dependencies: [QUERY_NEXT_GEN_SUBSCRIPTION_LIST]
    }
  );

export const useDeleteNextGenSubscription = (memberId: string) =>
  useWSMutation(
    async (subscriptionId: string) => {
      await usersService.nextGenSubscription.delete(memberId, subscriptionId);
    },
    {
      dependencies: [QUERY_NEXT_GEN_SUBSCRIPTION_LIST]
    }
  );
