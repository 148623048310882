import { Calendar, CalendarProps } from "primereact/calendar";
import { classNames } from "primereact/utils";
import * as React from "react";
import { toCamelCase } from "../../../../utils";
import {
  WSElement,
  WSElementCustomProps,
  WSElementLayoutProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import styles from "./WSTextInput.module.scss";

export interface WSInputDatePropsOld
  extends Omit<CalendarProps, "pt">,
    WSElementLayoutProps,
    WSElementCustomProps {}

export const WSInputDateOld: React.FC<WSInputDatePropsOld> = ({
  onChange,
  className,
  placeholder,
  ...otherProps
}) => {
  const { layoutProps, systemProps, ...calendarProps } = detachLayoutProps(
    otherProps
  );

  const defaultPlaceholder =
    calendarProps.selectionMode === "range"
      ? "Select date range"
      : calendarProps.selectionMode === "single" || !calendarProps.selectionMode
      ? "MM/DD/YYYY"
      : undefined;

  const testId = otherProps.name
    ? toCamelCase(otherProps.name, "field")
    : undefined;

  return (
    <WSElement
      {...layoutProps}
      {...systemProps}
      className={classNames(styles.inputContainer, className)}
    >
      <Calendar
        baseZIndex={1200}
        className={styles.pmInputContainer}
        placeholder={placeholder || defaultPlaceholder}
        onChange={(e) => onChange?.(e.value || (undefined as any))}
        pt={{
          input: { root: { className: styles.input } }
        }}
        data-testid={testId}
        {...(calendarProps as any)}
      />
    </WSElement>
  );
};
