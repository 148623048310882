import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSGrid,
  WSInfoBox,
  WSList,
  WSSectionToolbar,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import React from "react";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import { getEngagementLabelByType } from "../../../Payers/utils/engagement";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";

type EditEmailUpdateContractorModalProps = {
  onClose: () => void;
  onSuccess?: () => void;
  response: IPayeeEmailChangeResponse;
};

const EngagementNameCell: React.FC<{
  engagementId: string;
}> = ({ engagementId }) => {
  const queryEngagement = useEngagementQuery(engagementId, {
    retry: false
  });

  const engagementName = queryEngagement.data?.name;
  const engagementDescription = queryEngagement.data?.description;

  if (queryEngagement.isInitialLoading) {
    return <WSTableCell text="..." secondaryText="....." />;
  }

  if (queryEngagement.isError) {
    return (
      <WSTableCell
        text="--"
        secondaryText="error"
        secondaryTextColor="amber400"
      />
    );
  }

  return (
    <WSTableCell text={engagementName} secondaryText={engagementDescription} />
  );
};

const EngagementTypeCell: React.FC<{
  engagementId: string;
}> = ({ engagementId }) => {
  const queryEngagement = useEngagementQuery(engagementId, {
    retry: false
  });

  const type = queryEngagement.data?.type
    ? getEngagementLabelByType(queryEngagement.data.type)
    : "";

  if (queryEngagement.isInitialLoading) {
    return <WSTableCell text="..." />;
  }

  if (queryEngagement.isError) {
    return (
      <WSTableCell
        text="--"
        secondaryText="error"
        secondaryTextColor="amber400"
      />
    );
  }

  return <WSTableCell text={type} />;
};

const EditEmailUpdateContractorModal: React.FC<
  EditEmailUpdateContractorModalProps
> = ({ onClose, onSuccess, response }) => {
  return (
    <WSList gap="L">
      <FieldView label="Contractor email" value={response.targetEmail} />

      <WSAlert theme="success">Contractor profile exists</WSAlert>

      <WSInfoBox>
        A contractor with that email already exists. The previous email
        (profile) will be archived and the contractor data below will be added
        to the existing active profile. Any eligible payments from the previous
        email will be paid out to the active profile after this process. Would
        you like to proceed?
      </WSInfoBox>

      <WSSectionToolbar title="Add contractor data to existing contractor profile:" />

      <WSGrid gutter="S">
        <WSGrid.Item span={{ xs: "12", s: "6" }}>
          <FieldView
            label="Invoices"
            value={response.invoiceIds?.length || 0}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ xs: "12", s: "6" }}>
          <FieldView
            label="Deductions"
            value={response.deductionIds?.length || 0}
          />
        </WSGrid.Item>
      </WSGrid>

      {response.newEngagementIds?.length ? (
        <WSTable
          mt="XL"
          tableData={response.newEngagementIds.map(id => ({
            id,
            data: { engagementId: id, payeeId: response.targetPayeeId! }
          }))}
          columns={[
            {
              config: {
                header: {
                  text: "Engagement"
                },
                gridTemplateSizeMax: "1fr"
              },
              renderFunction: ({ data: { engagementId } }) => (
                <EngagementNameCell engagementId={engagementId} />
              )
            },
            {
              config: {
                header: {
                  text: "Type"
                },
                gridTemplateSizeMax: "1fr"
              },
              renderFunction: ({ data: { engagementId } }) => (
                <EngagementTypeCell engagementId={engagementId} />
              )
            }
          ]}
        />
      ) : null}

      <WSDivider />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Add to existing profile",
            onClick: () => {
              onSuccess?.();
              onClose();
            },
            kind: "Primary"
          },
          {
            label: "Back",
            onClick: onClose,
            kind: "Tertiary"
          }
        ]}
      />
    </WSList>
  );
};

export function useEditEmailUpdateContractorModal() {
  return useWSModal(EditEmailUpdateContractorModal, {
    title: "Update contractor profile",
    size: "S"
  });
}
