import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EnabledPaymentMethod } from "../SignUp/screens/AutopaySetup/SetupPaymentMethod";
import { useSetWSStore, useWSStore } from "../store";
import { getGrowthSourceFromPathname } from "./growthAttribution";

export const HistoryListener: React.FC = ({ children }) => {
  const location = useLocation<any>();
  const store = useWSStore();
  const setStore = useSetWSStore();

  useEffect(() => {
    const { search, pathname } = location;
    const params = new URLSearchParams(search);

    if (pathname.includes("sign-up") || pathname.includes("autopay/")) {
      let growthSource;

      if (!store.growthAttributionDetails) {
        let growthAttributionDetails;
        try {
          // Check the pathname for a growth source
          growthSource = getGrowthSourceFromPathname(location);

          if (growthSource) {
            // Gather other growth data
            const growthName = params.get("growth_name") || undefined;
            const growthUserId = params.get("growth_user_id") || undefined;

            // Save everything in the store for use later
            growthAttributionDetails = {
              growthSource: growthSource.source,
              growthName,
              growthUserId
            };
          }
        } catch (e) {
          console.error(e);
        }

        growthAttributionDetails && setStore({ growthAttributionDetails });
      }
    }

    if (pathname.includes("autopay/")) {
      const autopayMatch = location.pathname.match(/\/(autopay)\/(.+)/);
      const isAutopay = autopayMatch?.[1];
      const tag = autopayMatch?.[2];

      if (!store.signUpAutopayDetails && isAutopay && tag) {
        const companyName = params.get("companyName") || undefined;

        const payeeId = params.get("p") || undefined;
        const email = params.get("email") || undefined;

        const redirectTo = params.get("redirect_to") || undefined;

        const enabledPaymentMethods: EnabledPaymentMethod[] = (
          params.get("enabled_payment_methods") || ""
        )
          .split(",")
          .map(s => s.trim() as EnabledPaymentMethod)
          .filter(s => {
            const allAvailable: EnabledPaymentMethod[] = [
              "credit",
              "ach",
              "manual"
            ];

            return allAvailable.includes(s) ? s : false;
          });

        if (payeeId && companyName && isAutopay && tag) {
          const signUpAutopayDetails = {
            companyName,
            enabledPaymentMethods,
            tag,
            redirectTo,

            payeeId,
            email
          };

          setStore({ signUpAutopayDetails });

          window.sessionStorage.setItem(
            "signUpAutopayDetails",
            JSON.stringify(signUpAutopayDetails)
          );
        }
      }
    }
  }, [location]);

  return <>{children}</>;
};
