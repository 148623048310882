import {
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSGrid,
  WSImage,
  WSLoader,
  WSModalOld,
  WSPage,
  WSTable,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IMemberFileResponse,
  IMemberPublicFileResponse
} from "@wingspanhq/files/dist/lib/interfaces";
import React, { ChangeEvent, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import imageNoFilesDesktop from "../../assets/images/documents/no_files.png";
import imageNoFilesMobile from "../../assets/images/documents/no_files_mobile.png";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSPersistentUpgradeButton } from "../../components/Membership/WSPersistentUpgradeButton";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useMemberPrivateCreate } from "../../query/files/mutations";
import {
  useMemberPrivateFiles,
  useMemberPublicFiles
} from "../../query/files/queries";
import { filesService } from "../../services/files";
import { WSFrontendFeature } from "../../Settings/utils/subscriptionUtils";
import { format } from "../../utils/dates";
import { RecommendedTemplates } from "../components/RecommendedTemplates/RecommendedTemplates";
import { RequestTemplateModal } from "../components/RequestTemplateModal/RequestTemplateModal";
import {
  DeleteFileModal,
  DOCUMENTS_MODALS_DELETE_FILE
} from "../modals/DeleteFileModal";
import { columns } from "./tableData";

type DocumentsProps = RouteComponentProps;

export const Documents: React.FC<DocumentsProps> = () => {
  const isMobile = useIsMobile();

  const { openModal } = useModalOldContext();

  const {
    status: statusFilesPublic,
    error: errorFilesPublic,
    data: dataFilesPublic = []
  } = useMemberPublicFiles();

  const {
    status: statusFilesPrivate,
    error: errorFilesPrivate,
    data: dataFilesPrivate = []
  } = useMemberPrivateFiles();

  const files: (IMemberPublicFileResponse | IMemberFileResponse)[] = [
    ...dataFilesPrivate,
    ...dataFilesPublic
  ].map((file: IMemberPublicFileResponse | IMemberFileResponse) => ({
    ...file,
    createdAtDisplayValue: format(file.createdAt)
  }));

  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [requestTemplateModalVisible, setRequestTemplateModalVisible] =
    useState(false);

  const downloadFileFromTable = async (
    row: IMemberFileResponse | IMemberPublicFileResponse
  ) => {
    try {
      let data;
      if (row["fileAccessScope"] === "Private") {
        data = await filesService.member.private.download(row.fileId);
      } else {
        data = await filesService.member.public.download(row.fileId);
      }
      const tempLink = document.createElement("a");
      tempLink.href = window.URL.createObjectURL(
        new Blob([data], { type: row.mimetype ? row.mimetype : "" })
      );
      tempLink.setAttribute("download", row.filename);
      const tempElement = document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempElement);
    } catch {
      //download failed, do nothing
    }
  };

  const tableData = files.map(file => {
    return {
      data: {
        source: file,
        name: file.filename,
        createdAt: file.createdAt
      },
      id: file.fileId
    };
  });

  const filesSection = (
    <WSGrid>
      {files.length === 0 ? (
        <>
          <WSGrid.Item span={{ s: "4" }}>
            <WSText.Heading5 mb="M">All files</WSText.Heading5>
            <WSText weight="medium" mb="XS">
              No files yet
            </WSText>
            <WSText>
              Everything you generate in Wingspan will automatically be stored
              here for your reference.
            </WSText>
            {!isMobile && (
              <WSPersistentUpgradeButton
                feature={WSFrontendFeature.FileVault}
                mt="XL"
                onClick={() => setUploadFileModalVisible(true)}
                name="uploadFiles"
              >
                Upload files
              </WSPersistentUpgradeButton>
            )}
          </WSGrid.Item>
          <WSGrid.Item span={{ s: "8" }}>
            <WSImage
              placeholder=""
              src={isMobile ? imageNoFilesMobile : imageNoFilesDesktop}
            />
          </WSGrid.Item>
        </>
      ) : (
        <WSGrid.Item span={{ s: "12" }}>
          <WSText.Heading5>All files</WSText.Heading5>
          <WSTable
            mt="XL"
            loading={
              statusFilesPrivate === "loading" ||
              statusFilesPublic === "loading"
            }
            showHeader={true}
            rowMenuActions={(rowItem: any) => [
              {
                label: "Download",
                icon: "download",
                onClick: () => downloadFileFromTable(rowItem.data.source)
              },
              {
                label: "Delete",
                icon: "trash",
                onClick: () =>
                  openModal(DOCUMENTS_MODALS_DELETE_FILE, {
                    source: rowItem.data.source as
                      | IMemberFileResponse
                      | IMemberPublicFileResponse
                  })
              }
            ]}
            columns={columns}
            tableData={tableData}
          />
        </WSGrid.Item>
      )}
    </WSGrid>
  );

  const inputFile = useRef<HTMLInputElement>(null);

  const [fileToUpload, setFileToUpload] = useState<File>();
  const [isFileAllowed, setIsFileAllowed] = useState<boolean>(true);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFileAllowed(true);
    event.stopPropagation();
    event.preventDefault();
    if (event.target && event.target.files && event.target.files) {
      setFileToUpload(event.target.files[0]);
      const blackListedMimeTypes = [
        "application/x-msdownload",
        "application/x-ms-installer",
        "application/x-elf"
      ];
      if (
        blackListedMimeTypes.includes(event.target.files[0]?.type as string)
      ) {
        setIsFileAllowed(false);
      }
    }
  };

  const onChooseFile = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const [uploadFile, { error: uploadFileError, isLoading: isUploadingFile }] =
    useMemberPrivateCreate();

  const onFileUpload = async () => {
    if (fileToUpload) {
      const data = new FormData();
      data.append("file", fileToUpload);
      await uploadFile(data, {
        onSuccess: () => {
          setUploadFileModalVisible(false);
          setFileToUpload(undefined);
        }
      });
    }
  };
  const modals = (
    <>
      <DeleteFileModal />
      {uploadFileModalVisible && (
        <WSModalOld
          onClose={() => {
            setUploadFileModalVisible(false);
          }}
        >
          <WSText weight="medium">Upload a file</WSText>
          <WSText my="XS">
            Files you upload will be saved to your Documents. 100 MB max file
            size.
          </WSText>
          {/* Need to put back WSFileInput once it works again */}
          <input
            type="file"
            name="file"
            data-testid="fileInput"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={onFileChange}
          />
          {fileToUpload ? (
            <WSButton.Link onClick={onChooseFile}>
              Choose another file
            </WSButton.Link>
          ) : null}
          <WSText>{fileToUpload?.name}</WSText>
          {isUploadingFile && <WSLoader.Spinner mt="M" />}

          {fileToUpload ? (
            <WSButton
              mt="M"
              onClick={onFileUpload}
              name="uploadSelectedFile"
              disabled={!isFileAllowed}
            >
              Upload
            </WSButton>
          ) : (
            <WSButton mt="M" onClick={onChooseFile}>
              Choose a file
            </WSButton>
          )}

          <WSErrorMessage
            contextKey="UploadFile"
            mt="M"
            error={
              isFileAllowed
                ? uploadFileError
                : "Sorry! Cannot upload executable files."
            }
          />
        </WSModalOld>
      )}
      {requestTemplateModalVisible && (
        <RequestTemplateModal
          onSubmit={() => {
            setRequestTemplateModalVisible(false);
          }}
          onClose={() => {
            setRequestTemplateModalVisible(false);
          }}
        />
      )}
    </>
  );

  return (
    <>
      {modals}
      <BrowserPageTitle title="My Documents" />
      <WSPage
        title="My Documents"
        secondaryButtonComponent={props => (
          <WSPersistentUpgradeButton
            feature={WSFrontendFeature.FileVault}
            kind="Secondary"
            onClick={() => setRequestTemplateModalVisible(true)}
            {...props}
          >
            Request a template
          </WSPersistentUpgradeButton>
        )}
        primaryButtonComponent={props => (
          <WSPersistentUpgradeButton
            feature={WSFrontendFeature.FileVault}
            onClick={() => setUploadFileModalVisible(true)}
            name="uploadFiles"
            {...props}
          >
            Upload
          </WSPersistentUpgradeButton>
        )}
      >
        <RecommendedTemplates mb="2XL" />
        {filesSection}
      </WSPage>
    </>
  );
};
