import {
  toWSDateString,
  WSText,
  WSFlexBox,
  WSIcon
} from "@wingspanhq/fe-component-library";
import { IBulkPayableBatch } from "@wingspanhq/payments/dist/interfaces";
import { formatNumber } from "accounting";
import { RowData, ListValueType } from "../../../../components/LabelValueList";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import {
  ORIGINAL_SPREADSHEET_MODAL,
  IMPORT_STRATEGY_MODAL,
  INITIAL_IMPORT_STATUS_MODAL
} from "./InfoModals";
import { BulkResource, ILabelInfoValueOptions } from "../../types";
import { getDisplayImportStatusValue } from "../../utils/getDisplayImportStatusValue";
import { isNil } from "../../../../utils";
import camelCase from "lodash/camelCase";
import { IPayableBatchImportSummary } from "../../services/types";

export function getPayableBatchDetailsItems(
  bulkBatch?: IBulkPayableBatch,
  bulkBatchSummary?: IPayableBatchImportSummary,
  options?: ILabelInfoValueOptions
): RowData[] {
  if (isNil(bulkBatch) || isNil(bulkBatchSummary)) {
    return [];
  }
  return [
    {
      name: "originalSpreadsheet",
      modalName: ORIGINAL_SPREADSHEET_MODAL,
      label: "Original spreadsheet",
      value: getUploadedFilename(bulkBatch, BulkResource.Payable, {
        length: 40
      })
    },
    {
      name: "importedToPayables",
      label: "Imported to payables",
      value: getDisplayImportStatusValue(bulkBatch.status)
    },
    {
      name: "createdAt",
      label: "Created",
      value: toWSDateString(bulkBatch.createdAt)
    },
    {
      name: "createdBy",
      label: "Creator",
      value: getBatchCreatorName(bulkBatch)
    },
    {
      name: "collaboratorsImpacted",
      label: "Collaborators impacted",
      value: formatNumber(bulkBatchSummary.collaboratorsImpactedCount)
    },
    {
      name: "importStrategy",
      modalName: IMPORT_STRATEGY_MODAL,
      label: "Import strategy",
      value: bulkBatch.processingStrategy
    },
    {
      name: "initialImportStatus",
      modalName: INITIAL_IMPORT_STATUS_MODAL,
      label: "Initial status of created payables",
      value: bulkBatch.labels.payableStatus
    }
  ].map(row => ({
    id: row.name,
    type: ListValueType.Text,
    name: row.name,
    value: (
      <WSText.ParagraphSm
        data-testid={camelCase(row.name)}
        mr="XS"
        color={options?.valueColor || "gray500"}
      >
        {row.value}
      </WSText.ParagraphSm>
    ),
    label: row.modalName ? (
      <WSFlexBox.CenterY>
        <WSText.ParagraphSm mr="XS" color={options?.labelColor || "gray500"}>
          {row.label}
        </WSText.ParagraphSm>
        <WSIcon
          block
          name="info-circle"
          size="XS"
          onClick={() => options?.onClickInfo?.(row.modalName)}
        />
      </WSFlexBox.CenterY>
    ) : (
      <WSText.ParagraphSm mr="XS" color={options?.labelColor || "gray500"}>
        {row.label}
      </WSText.ParagraphSm>
    )
  }));
}
