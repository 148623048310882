import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSModalOld,
  WSRadioInput,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AccountStatus,
  IAccount,
  IClientUpdateRequest
} from "@wingspanhq/users/dist/lib/interfaces";
import React, { useState } from "react";
import { ErrorMessage } from "../../../Auth/components/ErrorMessage/ErrorMessage";
import { InstitutionLogo } from "../../../components/InstitutionLogo/InstitutionLogo";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateAccount,
  useUpdateAccount,
  useUpdateClient
} from "../../../query/users/mutations";
import { useAccounts } from "../../../query/users/queries";
import { getPossiblePaymentsAccounts } from "../../../query/users/selectors";
import { CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL } from "../../../Settings/components/PaymentMethodsList/ChooseAddPaymentMethodFlowModal";
import { useMemberPlaidLink } from "../../../utils/usePlaidLink";
import styles from "./ChooseAccountForPaymentMethodsModal.module.scss";

export const CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL =
  "CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL";

type Props = {
  onSuccess?: () => void;
};

export const ChooseAccountForPaymentMethodsModal: React.FC<Props> = ({
  onSuccess = () => {}
}) => {
  const userId = useUserId();
  const [createAccount, createAccountMeta] = useCreateAccount();
  const [updateAccount, updateAccountMeta] = useUpdateAccount();
  const [updateClient, updateClientMeta] = useUpdateClient(userId);
  const accountsQuery = useAccounts();
  const { closeModal } = useModalOldContext();
  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const accounts = (await createAccount({
        publicToken,
        status: AccountStatus.Pending
      })) as IAccount[];

      if (accounts) {
        return {
          success: true,
          data: accounts
        };
      } else {
        return {
          success: false
        };
      }
    }
  });

  const filteredAccounts = getPossiblePaymentsAccounts(
    accountsQuery.data || []
  );

  const selectedAccount = filteredAccounts.find(
    account => account.usedFor?.payments
  );

  const [value, setValue] = useState(
    selectedAccount ? selectedAccount.accountId : ""
  );
  const [error, setError] = useState("");

  const accountsByInstitution: { [key: string]: IAccount[] } = {};

  filteredAccounts.forEach(account => {
    const institution = account.institution || "";
    if (!accountsByInstitution[institution])
      accountsByInstitution[institution] = [];

    accountsByInstitution[institution].push(account);
  });

  const onSelectPaymentMethod = async (modalProps?: any) => {
    setError("");
    if (value) {
      const account = accountsQuery.data?.find(
        account => account.accountId === value
      );
      if (!account) {
        setError("Something went wrong");
        return;
      }
      const updatedAccount = await updateAccount(
        {
          accountId: value,
          usedFor: account.usedFor
            ? {
                ...account.usedFor,
                payments: true
              }
            : {
                payouts: false,
                bookkeeping: false,
                payments: true
              }
        },
        {
          onSuccess: async () => {
            closeModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL);
            if (modalProps?.shouldSetDefaultPaymentMethod) {
              const request: IClientUpdateRequest = {
                clientId: userId,
                profile: {
                  defaultPaymentMethod: {
                    accountId: value
                  }
                }
              };
              await updateClient(request, {
                onSuccess: () => {
                  modalProps?.onFinish?.();
                }
              });
            }
            if (modalProps?.shouldSetSubscriptionPaymentMethod) {
              const request: IClientUpdateRequest = {
                clientId: userId,
                profile: {
                  subscriptionPaymentMethod: {
                    accountId: value
                  }
                }
              };
              await updateClient(request, {
                onSuccess: () => {
                  modalProps?.onFinish?.();
                }
              });
            }
          }
        }
      );

      if (updatedAccount) {
        closeModal(CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL);
        onSuccess();
      }
    } else {
      setError("Please, choose one account");
    }
  };

  return (
    <WSModalOld
      name={CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL}
      size="S"
      title="Select an account as payment method"
    >
      {modalProps => (
        <>
          {Object.keys(accountsByInstitution).map(institution => (
            <WSElement
              key={institution}
              mb="2XL"
              pb="2XL"
              className={styles.section}
            >
              <WSText.Heading5 mb="XL">{institution}</WSText.Heading5>
              <WSElement as="ul" data-testid="accountsList">
                {accountsByInstitution[institution].map(account => (
                  <WSFlexBox.CenterY
                    wrap="nowrap"
                    justify="space-between"
                    as="li"
                    mb="2XL"
                    key={account.accountId}
                    onClick={() => {
                      setValue(account.accountId);
                    }}
                    data-testid={`account-item-${account.accountId}`}
                  >
                    <WSFlexBox.CenterY
                      wrap="nowrap"
                      mr="M"
                      className={styles.name}
                    >
                      <InstitutionLogo institutionId={account.institutionId} />
                      <WSText.ParagraphSm weight="medium">
                        {account.name}
                      </WSText.ParagraphSm>
                    </WSFlexBox.CenterY>
                    <WSRadioInput
                      name="user-account"
                      value={account.accountId}
                      checked={value === account.accountId}
                    />
                  </WSFlexBox.CenterY>
                ))}
              </WSElement>
            </WSElement>
          ))}
          {(error || createAccountMeta.error || updateAccountMeta.error) && (
            <ErrorMessage>
              {error ||
                createAccountMeta.error?.message ||
                updateAccountMeta.error?.message}
            </ErrorMessage>
          )}

          {filteredAccounts.length === 0 && (
            <WSText my="XL" color="gray500" align="center">
              No accounts added.
            </WSText>
          )}

          <WSButtons forceFullWidth format="modal">
            {filteredAccounts.length > 0 && (
              <WSButton
                name="proceedSelectedAccount"
                onClick={() => onSelectPaymentMethod(modalProps)}
                fullWidth
                loading={updateAccountMeta.isLoading}
              >
                Proceed with selected account
              </WSButton>
            )}
            <WSButton.Secondary
              onClick={() => {
                plaidHandler.open();
              }}
              fullWidth
              name="linkAccount"
              data-testid="linkAccount"
            >
              {filteredAccounts.length > 0
                ? "Link another account"
                : "Link account"}
            </WSButton.Secondary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
