import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces/api/notifications";
import { patchViewNotification } from "../../../services/notifications/patchViewNotification";
import {
  QUERY_NOTIFICATIONS_NOTIFICATION,
  QUERY_NOTIFICATIONS_NOTIFICATIONS
} from "../keys";
import { WSQueryCache } from "@ws-react-query";

export const useViewNotification = (
  config?: WSMutationConfig<
    IChannelNotificationResponse,
    WSServiceError,
    string
  >
) =>
  useWSMutation((id: string) => patchViewNotification(id), {
    onSuccess: (response, id) => {
      WSQueryCache.setQueryData(
        [QUERY_NOTIFICATIONS_NOTIFICATION, id],
        response
      );
      WSQueryCache.setQueryData(QUERY_NOTIFICATIONS_NOTIFICATIONS, () => {
        return WSQueryCache.getQueryData<IChannelNotificationResponse[]>(
          QUERY_NOTIFICATIONS_NOTIFICATIONS
        )?.map((cachedData: IChannelNotificationResponse) => {
          if (cachedData.channelMessageId === id) {
            return response;
          }
          return cachedData;
        });
      });
    },
    ...config
  });
