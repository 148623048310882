import {
  WSActions,
  WSButtonProps,
  WSCard,
  WSLabelProps,
  WSList,
  WSMenuItem,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import { WSCardHeaderProps } from "@wingspanhq/fe-component-library/dist/lib/components/WSCard/WSCardHeader";
import {
  ICheckbookCard,
  IPaymentCard
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount, ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo, useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { components } from "../../../../services/api/banking/types";
import { useModalRemove } from "./ModalRemove";

import { usePlaidReconnect } from "../../../../shared/hooks/usePlaidReconnect";
import { useModalMicrodepositsVerify } from "../ModalMicrodepositsVerify";
import { getMethods } from "./utils";

export type MethodType =
  | "Account"
  | "InternalAccount"
  | "CreditCard"
  | "CheckbookCard"
  | "PaymentCard";

export type Method = {
  id: string;
  type: MethodType;
  label: WSLabelProps;
  action?: WSButtonProps<"Link">;
};

export type MethodValue = {
  id: string;
  type: MethodType;
};

export type ManageMethodsProps = {
  data: {
    // External bank accounts
    accounts?: IAccount[];
    // Internal bank accounts
    internalAccounts?: components["schemas"]["InternalAccount"][];
    // Stripe cards
    creditCards?: ICreditCard[];
    // Checkbook/debit cards
    checkbookCards?: ICheckbookCard[];
    // Footprint cards
    paymentCards?: IPaymentCard[];
  };

  initialValue: MethodValue | undefined;
  header?: WSCardHeaderProps;
  onAddNew?: () => void;
  addNewLabel?: string;
  onSubmit: (value: MethodValue) => void;
  isLoading?: boolean;
  error?: any;
};

export const ManageMethods: React.FC<ManageMethodsProps> = props => {
  const [value, setValue] = useState<MethodValue | undefined>(
    props.initialValue
  );
  const modalMicrodepositsVerify = useModalMicrodepositsVerify();
  const openPlaidReconnect = usePlaidReconnect();

  const methods = useMemo(
    () =>
      getMethods(props.data, {
        openMicrodepositsVerify: modalMicrodepositsVerify.open,
        openPlaidReconnect
      }),
    [modalMicrodepositsVerify.open, openPlaidReconnect, props.data]
  );

  return (
    <WSList gap="2XL">
      <WSCard header={props.header}>
        <WSList gap="L">
          {methods.map(method => (
            <MethodView
              key={method.id}
              {...method}
              initialAcitve={method.id === props.initialValue?.id}
              active={method.id === value?.id}
              onSelect={() => {
                setValue({
                  id: method.id,
                  type: method.type
                });
              }}
              action={method.action}
            />
          ))}

          {props.onAddNew && (
            <WSCard
              header={{
                label: {
                  link: {
                    icon: "plus-circle",
                    onClick: props.onAddNew,
                    label: props.addNewLabel
                  }
                }
              }}
            />
          )}
        </WSList>
      </WSCard>

      <WSErrorMessage contextKey="FundingMethod" error={props.error} />

      <WSActions
        alignment="fill"
        buttons={[
          {
            disabled: !value,
            label: "Save changes",
            onClick: () => {
              if (value) {
                props.onSubmit(value);
              }
            },
            loading: props.isLoading
          }
        ]}
      />
    </WSList>
  );
};

type MethodViewProps = Method & {
  onSelect: () => void;
  initialAcitve?: boolean;
  active?: boolean;
  action?: WSButtonProps<"Link">;
};

const MethodView: React.FC<MethodViewProps> = props => {
  const pill = useMemo(
    () =>
      props.active && props.initialAcitve
        ? pillDefault
        : props.initialAcitve
        ? pillInitialDefault
        : props.active
        ? pillNewDefault
        : undefined,
    [props.active, props.initialAcitve]
  );

  const modalRemove = useModalRemove();

  const menu = useMemo(() => {
    const items: WSMenuItem[] = [
      {
        label: "Set as default",
        icon: "checkbox",
        onClick: props.onSelect
      }
    ];

    if (props.type !== "InternalAccount") {
      items.push(
        {
          separator: true
        },
        {
          label: "Remove",
          icon: "trash",
          iconProps: {
            color: "red400"
          },
          labelProps: {
            color: "red400"
          },
          onClick: () => {
            modalRemove.open({
              id: props.id,
              type: props.type
            });
          }
        }
      );
    }

    return items;
  }, [props.id, props.onSelect, props.type]);

  return (
    <WSCard
      active={props.active}
      onClick={props.onSelect}
      header={{
        label: props.label,
        value: {
          pill,
          action: props.action,
          menu
        }
      }}
    />
  );
};

const pillDefault: WSPillProps = {
  text: "Current default",
  icon: "check",
  theme: "blue",
  type: "dark"
};

const pillInitialDefault: WSPillProps = {
  text: "Current default"
};

const pillNewDefault: WSPillProps = {
  text: "Set as default",
  icon: "check",
  theme: "blue",
  type: "dark"
};
