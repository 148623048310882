import { WSLabelProps } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useQueryInternalAccount } from "../../../../query/banking/queries/useQueryInternalAccount";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCard } from "../../../../query/paymentCards/queries/useQueryPaymentsCard";
import { usePayoutSettingsDebitCard } from "../../../../query/payments/queries";
import { useAccount, useClientQuery } from "../../../../query/users/queries";
import { avatarBankAccount } from "../../../../shared/constants/avatars";
import { MethodType } from "../ManageMethods";
import {
  getAccountLabelProps,
  getCheckbookCardLabelProps,
  getCreditCardLabelProps,
  getInternalAccountLabelProps,
  getPaymentCardLabelProps
} from "../ManageMethods/utils";

const emptyLabel = {
  text: "Account",
  avatar: avatarBankAccount
};

export function useMethodLabelProps(
  id?: string,
  type?: MethodType,
  enabled: boolean = true
) {
  const userId = useUserId();

  const queryAccount = useAccount(id as string, {
    enabled: !!id && type === "Account" && enabled
  });
  const queryInternalAccount = useQueryInternalAccount(id as string, {
    enabled: !!id && type === "InternalAccount" && enabled
  });
  const queryClient = useClientQuery(userId, {
    enabled: !!id && type === "CreditCard" && enabled
  });
  const queryCheckbookCard = usePayoutSettingsDebitCard(userId, id as string, {
    enabled: !!id && type === "CheckbookCard" && enabled
  });
  const queryPaymentCard = useQueryPaymentsCard(id as string, {
    enabled: !!id && type === "PaymentCard" && enabled
  });

  const isLoading = useMemo(() => {
    if (type === "Account") {
      return queryAccount.isLoading;
    }
    if (type === "InternalAccount") {
      return queryInternalAccount.isLoading;
    }
    if (type === "CreditCard") {
      return queryClient.isLoading;
    }
    if (type === "CheckbookCard") {
      return queryCheckbookCard.isLoading;
    }
    if (type === "PaymentCard") {
      return queryPaymentCard.isLoading;
    }

    return undefined;
  }, [
    type,
    queryAccount.isLoading,
    queryInternalAccount.isLoading,
    queryClient.isLoading,
    queryCheckbookCard.isLoading,
    queryPaymentCard.isLoading
  ]);

  const label = useMemo((): WSLabelProps | undefined => {
    if (type === "Account" && queryAccount.data) {
      return getAccountLabelProps(queryAccount.data);
    }

    if (type === "InternalAccount" && queryInternalAccount.data) {
      return getInternalAccountLabelProps(queryInternalAccount.data);
    }

    if (type === "CreditCard") {
      const creditCard = queryClient.data?.profile.savedPaymentMethods?.find(
        creditCard => creditCard.paymentMethodId === id
      );

      if (creditCard) {
        return getCreditCardLabelProps(creditCard);
      }
    }

    if (type === "CheckbookCard" && queryCheckbookCard.data) {
      return getCheckbookCardLabelProps(queryCheckbookCard.data);
    }

    if (type === "PaymentCard" && queryPaymentCard.data) {
      return getPaymentCardLabelProps(queryPaymentCard.data);
    }

    if (id) {
      return emptyLabel;
    }
  }, [
    id,
    queryAccount.data,
    queryCheckbookCard.data,
    queryClient.data?.profile.savedPaymentMethods,
    queryInternalAccount.data,
    queryPaymentCard.data,
    type
  ]);

  return { isLoading, label };
}
