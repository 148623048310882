import { IAdditionalData } from "@wingspanhq/payments/dist/interfaces";

export function preparePayablesCSVFile<CSVRow>(
  filename: string,
  data: Array<CSVRow>,
  customFields: IAdditionalData[],
  options?: { includeErrors?: boolean }
) {
  return {
    filename,
    headers: [
      { label: "Email", key: "email" },
      { label: "External Contractor ID", key: "contractorId" },
      { label: "Contractor ID", key: "collaboratorId" },
      { label: "Amount (USD)", key: "amount" },
      { label: "Line Item Title", key: "lineItemTitle" },
      { label: "Line Item Description", key: "lineItemDescription" },
      { label: "Due Date", key: "dueDate" },
      { label: "Reimbursable", key: "reimbursable" },
      { label: "Invoice Notes", key: "invoiceNotes" },
      { label: "Attachment ID", key: "attachmentId" },
      { label: "Payment Reference ID", key: "paymentRefId" },
      { label: "Paid Date", key: "paidDate" },
      { label: "Invoice Date", key: "invoiceDate" },
      ...customFields.map(field => ({ label: field.name, key: field.key })),
      ...(options?.includeErrors ? [{ label: "Error", key: "error" }] : [])
    ],
    data
  };
}
