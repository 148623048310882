import { WSQueryConfig } from "@ws-react-query";
import { useWSQuery } from "../../helpers";
import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces/api/notifications";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../keys";
import { getNotifications } from "../../../services/notifications";

export const useNotifications = (
  queryConfig?: WSQueryConfig<IChannelNotificationResponse[], unknown>
) =>
  useWSQuery(QUERY_NOTIFICATIONS_NOTIFICATIONS, getNotifications, {
    ...queryConfig
  });
