import { useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD,
  PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD,
  PATH_SETTINGS_PAYROLL_METHOD
} from "../../../paths";
import { Layout } from "../../../../../shared/components/Layout";
import { FlowAddFundingMethod } from "../../../components/funding/FlowAddFundingMethod";

export const RouteAddFundingMethod: React.FC = () => {
  const history = useHistory();
  return (
    <Layout title="Add payroll funding method">
      <FlowAddFundingMethod
        basePath={PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD}
        onBack={() => {
          history.push(PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD);
        }}
        onNext={() => {
          history.push(PATH_SETTINGS_PAYROLL_METHOD);
        }}
      />
    </Layout>
  );
};
