import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { IPayeeRow } from "../../../services/search/payee";

export const getPayeeNames = (payee: IPayeeRow | IPayeeResponse) => {
  if (!payee.user) {
    console.error(`Payee ${payee.payerId} has no user`);
  }

  return wsName({
    user: payee.user!,
    member: payee.member,
    payerOwnedData: payee.payerOwnedData
  });
};

export const getPayeeName = (payee: IPayeeRow | IPayeeResponse) =>
  getPayeeNames(payee).getResolvedName();

export const getPayeeSecondaryName = (payee: IPayeeRow | IPayeeResponse) =>
  getPayeeNames(payee).getResolvedSecondaryName();
