import { TransactionType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { TaxEstimateType } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import { WSCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import {
  useIsDesktop,
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSDivider,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInputDateOld,
  WSInputNumberOld,
  WSMessageBox,
  WSPanel,
  WSSidebar,
  WSSwitchInput,
  WSText,
  WSTextArea,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces/member";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { PrivateURL } from "../../../components/PrivateFileURL/PrivateFileURL";
import { calculateLineItemsTotal } from "../../../Invoices/utils";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import { useTransaction } from "../../../query/bookkeeping/queries";
import { getAccountName } from "../../../query/bookkeeping/selectors";
import { useMemberPrivateHiddenCreate } from "../../../query/files/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { useInvoiceQuery } from "../../../query/payments/queries";
import { useTaxEstimate } from "../../../query/taxes/queries";
import { useAccounts, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { getCurrentIRSYear } from "../../../shared/utils/taxes";
import { isPDFSupported } from "../../../utils/isPDFSupported";
import { Editable } from "../../components/Editable/Editable";
import { ViewPDF } from "../../components/ViewPDF/ViewPDF";
import {
  transactionToWSCategoriesData,
  WSCategories,
  WSCategoryIcon
} from "../../components/WSCategories/WSCategories";
import {
  getAmountValueAndFormat,
  getEditAmount,
  getTaxSavingsForTransaction,
  getTransactionName
} from "../../utils";
import {
  BOOKKEEPING_DELETE_TRANSACTION,
  DeleteTransaction
} from "../modals/DeleteTransaction";
import {
  EDIT_TRANSACTION_RECEIPT,
  EditTransactionReceipt
} from "../modals/EditTransactionReceipt";
import {
  BOOKKEEPING_HOW_TAX_SAVINGS_CALCULATED,
  HowTaxSavingsCalculated
} from "../modals/HowTaxCalculated";
import { WhatIsARefund } from "../modals/WhatIsARefund";
import { WhichTransactionsMatter } from "../modals/WhichTransactionsMatter";
import { useBookkeepingFilters } from "./BookkeepingIndex";
import styles from "./BookkeepingTransaction.module.scss";
import { useShouldPauseAccount } from "../../../shared/hooks/useShouldPauseAccount";

type Props = RouteComponentProps<{ transactionId: string }>;

export const BookkeepingTransactionDetailsV2: React.FC<Props> = ({
  history,
  match
}) => {
  const { redirectWithFilters } = useBookkeepingFilters();
  const { openModal } = useModalOldContext();
  const qAccounts = useAccounts();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const userId = useUserId();
  const qMember = useMemberProfile(userId);
  const [uploadReceipt, uploadReceiptMeta] = useMemberPrivateHiddenCreate();
  const [uploadReceiptLoading, setUploadReceiptLoading] = useState(false);
  const [updateBusinessLoading, setUpdateBusinessLoading] = useState(false);

  const qTransaction = useTransaction(match.params.transactionId, {
    onError(error: any) {
      if (error?.response?.status === 404) {
        redirectWithFilters("/member/bookkeeping/transactions");
      }
    },
    retry: false,
    refetchOnMount: true
  });

  const qInvoice = useInvoiceQuery(qTransaction.data?.invoiceId as string, {
    enabled: !!qTransaction.data?.invoiceId,
    retry: false
  });

  const qTaxEstimate = useTaxEstimate({
    type: TaxEstimateType.Real,
    year: getCurrentIRSYear()
  });
  const [updateTransaction, updateTransactionMeta] = useUpdateTransaction();
  const shouldPauseAccount = useShouldPauseAccount("taxes");

  return (
    <>
      <WSSidebar
        onClose={() => redirectWithFilters("/member/bookkeeping/transactions")}
        header={"Transaction details"}
      >
        <BrowserPageTitle title="Transaction" />
        <EditTransactionReceipt />
        <WSQueries
          queries={{
            qAccounts,
            qTransaction,
            qTaxEstimate,
            qMember
          }}
        >
          {({ qTransaction, qMember, qAccounts, qTaxEstimate }) => {
            const transaction = qTransaction.data;
            const displayName = getTransactionName(transaction);
            const account = qAccounts.data.find(
              a => a.accountId === transaction.accountId
            );

            const invoice = qInvoice.data;

            const totalBilled = invoice?.amountDetails
              ? invoice?.creditFeeHandling?.clientPays === 100
                ? Math.abs(invoice.amountDetails?.memberGross)
                : Math.abs(invoice.amountDetails?.clientPaid)
              : calculateLineItemsTotal(invoice?.lineItems || []);

            const withholdingsRate = invoice?.withholdings?.tax?.rate || 0;
            const withholdings =
              invoice?.amountDetails?.memberTaxWithheld ||
              invoice?.withholdings?.tax?.amountWithheld ||
              0;
            const depositedAmount =
              Math.abs(invoice?.amountDetails?.memberNet || 0) ||
              totalBilled * ((100 - withholdingsRate) / 100);
            const payoutAccountName = account
              ? getAccountName(account)
              : "Payout Account";

            return (
              <WSElement pb="2XL">
                <WSPanel noBorder mb="L">
                  <WhatIsARefund />
                  <Editable
                    testid="editName"
                    px={isDesktop ? "2XL" : "NONE"}
                    renderEdit={(onClose, setLoading, loading) => (
                      <WSFormOld
                        defaultValues={{
                          amount: getEditAmount(
                            transaction.amount,
                            transaction.type
                          ),
                          name: displayName.substring(0, 100),
                          date: transaction.date
                        }}
                        validationSchema={Yup.object().shape({
                          amount: Yup.number().required("Amount is required"),
                          name: Yup.string()
                            .max(100, "Must be at most 100 characters")
                            .required("Name is required"),
                          date: Yup.date().required("Date is required")
                        })}
                        onSubmit={async formData => {
                          setLoading(true);
                          let amount =
                            Math.abs(Number(formData.amount)) *
                            (transaction.amount >= 0 ? 1 : -1);
                          let shouldOverrideName =
                            formData.name !== displayName.substring(0, 100);

                          const updatedTransaction = {
                            transactionId: transaction.transactionId,
                            amount,
                            date: formData.date,
                            businessAmount: amount,
                            ...(shouldOverrideName
                              ? {
                                  labels: {
                                    ...transaction.labels,
                                    overrideName: formData.name
                                  }
                                }
                              : {})
                          };
                          await updateTransaction(updatedTransaction);
                          setLoading(false);
                          onClose();
                        }}
                      >
                        <WSText.Heading4 mb="XL">
                          Edit transaction
                        </WSText.Heading4>

                        <WSFormOld.Field
                          mb="XL"
                          label="Dollar amount"
                          name="amount"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD",
                            min:
                              transaction.type === TransactionType.Transfer
                                ? undefined
                                : 0
                          }}
                        />

                        <WSFormOld.Field
                          mb="XL"
                          label="Name"
                          name="name"
                          component={WSTextInput}
                        />
                        <WSFormOld.Field
                          label="Date"
                          name="date"
                          component={WSInputDateOld}
                        />
                        <WSFlexBox
                          wrap={isMobile ? "wrap" : "nowrap"}
                          direction={isMobile ? "column" : "row"}
                          mt="3XL"
                        >
                          <WSButton.Primary
                            type="submit"
                            fullWidth={isMobile}
                            loading={loading}
                            name="updateName"
                          >
                            Update
                          </WSButton.Primary>
                        </WSFlexBox>
                      </WSFormOld>
                    )}
                    renderView={() => (
                      <>
                        <WSText.Heading1
                          mb="M"
                          data-testid="transactionDetailsAmount"
                          {...getAmountValueAndFormat(transaction)}
                        />
                        <WSText
                          weight="medium"
                          mb="XS"
                          data-testid="transactionDetailsName"
                        >
                          {displayName}
                        </WSText>
                        {account ? (
                          <WSText.ParagraphXs mb="XS">
                            {getAccountName(account)}
                          </WSText.ParagraphXs>
                        ) : null}
                        <WSText.ParagraphXs>
                          {moment(transaction.date).format("MMM D")}
                        </WSText.ParagraphXs>
                      </>
                    )}
                  />
                  {transaction.business &&
                  transaction.type === TransactionType.Expense &&
                  transaction.name.toLocaleLowerCase().includes("wingspan") ? (
                    <WSFlexBox.Center
                      className={styles.wingspanMark}
                      mt="XL"
                      p="XS"
                    >
                      <WSIcon
                        block
                        name="logo"
                        size="S"
                        mr="M"
                        color="red500"
                      />
                      <WSText.ParagraphSm color="gray700">
                        Always 100% deductible
                      </WSText.ParagraphSm>
                    </WSFlexBox.Center>
                  ) : null}
                </WSPanel>
                <WSPanel
                  mb="L"
                  noBorder
                  data-testid="transactionData"
                  data-testdata={JSON.stringify(transaction)}
                >
                  {updateTransactionMeta.isError ? (
                    <WSMessageBox.Error>
                      Sorry, something went wrong
                    </WSMessageBox.Error>
                  ) : null}
                  <>
                    {transaction.receiptFileId ? (
                      <WSFlexBox.Center
                        my="XL"
                        mx={isMobile ? "NONE" : "2XL"}
                        className={styles.receipt}
                        style={{ height: isPDFSupported() ? 152 : 60 }}
                        onClick={() => {
                          openModal(EDIT_TRANSACTION_RECEIPT, transaction);
                        }}
                      >
                        <PrivateURL fileId={transaction.receiptFileId}>
                          {(status, url, type) =>
                            status === "success" ? (
                              type === "application/pdf" ? (
                                <ViewPDF
                                  height={isPDFSupported() ? 152 : 60}
                                  isSmall={true}
                                  url={url}
                                  text={"Receipt"}
                                />
                              ) : (
                                <WSElement
                                  className={styles.receiptImage}
                                  style={{
                                    backgroundImage: `url("${url}")`
                                  }}
                                />
                              )
                            ) : status === "fail" ? (
                              <WSText color="red500">
                                Something went wrong!
                              </WSText>
                            ) : (
                              <WSElement
                                className={styles.receiptLoader}
                                shimmer
                              />
                            )
                          }
                        </PrivateURL>
                      </WSFlexBox.Center>
                    ) : (
                      <WSElement my="XL" mx={isMobile ? "NONE" : "2XL"}>
                        <WSDropFileInput
                          buttonProps={{ icon: "download" }}
                          buttonText="Upload receipt"
                          accept="image/*, .pdf"
                          loading={uploadReceiptLoading}
                          onDrop={async (accepted, rejected, event) => {
                            if (!accepted.length) {
                              return;
                            }

                            setUploadReceiptLoading(true);

                            const data = new FormData();
                            data.append("file", accepted[0]);
                            await uploadReceipt(data, {
                              onSuccess: async response => {
                                if (response.length > 0) {
                                  await updateTransaction({
                                    transactionId: transaction.transactionId,
                                    receiptFileId: response[0].fileId
                                  });
                                }
                                setUploadReceiptLoading(false);
                              },
                              onError() {
                                setUploadReceiptLoading(false);
                              }
                            });
                          }}
                        />
                      </WSElement>
                    )}
                  </>

                  <WSFlexBox.CenterY
                    px={isDesktop ? "2XL" : "NONE"}
                    mx={isDesktop ? "2XL" : "NONE"}
                    justify="space-between"
                    className={styles.isBusiness}
                  >
                    <WSFlexBox.CenterY>
                      <WSIcon
                        block
                        name="calculator"
                        mr="M"
                        shimmer={updateBusinessLoading}
                      />
                      <WSText.ParagraphSm shimmer={updateBusinessLoading}>
                        Is Tax Deductible?
                      </WSText.ParagraphSm>
                    </WSFlexBox.CenterY>
                    <WSSwitchInput
                      shimmer={updateBusinessLoading}
                      name="isBusiness"
                      value={transaction.business}
                      onChange={async () => {
                        setUpdateBusinessLoading(true);
                        await updateTransaction({
                          transactionId: transaction.transactionId,
                          business: !transaction.business
                        });
                        setUpdateBusinessLoading(false);
                      }}
                    />
                  </WSFlexBox.CenterY>
                  <WSFlexBox.Center>
                    <WSText.ParagraphXs mt="M">
                      {transaction.business &&
                      transaction.scheduleCLineNumber ? (
                        getTaxSavingsForTransaction(
                          transaction,
                          qTaxEstimate.data
                        ).totalSavings > 0 ? (
                          <WSFlexBox.CenterY
                            onClick={() => {
                              openModal(BOOKKEEPING_HOW_TAX_SAVINGS_CALCULATED);
                            }}
                          >
                            <WSText
                              weight="medium"
                              inline
                              align="center"
                              formatMoney="bookkeeping"
                              mr="XS"
                            >
                              {
                                getTaxSavingsForTransaction(
                                  transaction,
                                  qTaxEstimate.data
                                ).totalSavings
                              }
                            </WSText>{" "}
                            in tax savings
                            <WSIcon
                              block
                              name="info-circle"
                              ml="XS"
                              size="XS"
                              color="gray500"
                            />
                          </WSFlexBox.CenterY>
                        ) : null
                      ) : (
                        <>Not Tax Deductible (Ignored)</>
                      )}
                    </WSText.ParagraphXs>
                    <HowTaxSavingsCalculated transaction={transaction} />
                    <WhichTransactionsMatter />
                  </WSFlexBox.Center>

                  <>
                    <WSElement mt="M" />
                    <WSCategories
                      key={`${transaction.transactionId}-${transaction.updatedAt}`}
                      header={transaction.merchantName || transaction.name}
                      data={transactionToWSCategoriesData(transaction as any)}
                      renderTrigger={({
                        value,
                        label,
                        pseudoCategory,
                        isOpened,
                        isLoading,
                        open,
                        close
                      }) => {
                        if (isLoading) {
                          return (
                            <WSFlexBox.CenterY
                              px={isDesktop ? "2XL" : "NONE"}
                              mx={isDesktop ? "2XL" : "NONE"}
                              wrap="nowrap"
                              justify="space-between"
                              className={styles.selectCategory}
                            >
                              <WSFlexBox.CenterY>
                                <WSCategoryIcon
                                  shimmer
                                  value={{ wsCategory: WSCategory.Income }}
                                  size="M"
                                  mr="M"
                                />
                                <WSText shimmer>
                                  Category placeholder name
                                </WSText>
                              </WSFlexBox.CenterY>
                            </WSFlexBox.CenterY>
                          );
                        }

                        if (value.wsCategory) {
                          return (
                            <WSFlexBox.CenterY
                              px={isDesktop ? "2XL" : "NONE"}
                              mx={isDesktop ? "2XL" : "NONE"}
                              wrap="nowrap"
                              justify="space-between"
                              className={styles.selectCategory}
                              onClick={open}
                              data-testid="editCategory"
                            >
                              <WSFlexBox.CenterY>
                                <WSCategoryIcon value={value} mr="M" size="M" />
                                <WSElement>
                                  <WSText
                                    color="gray600"
                                    data-testid="transactionDetailsCategoryHeader"
                                  >
                                    {label.pseudoCategory ?? label.wsCategory}
                                  </WSText>
                                  <WSText.ParagraphSm
                                    color="gray500"
                                    data-testid="transactionDetailsCategorySubheader"
                                  >
                                    {label.pseudoCategory
                                      ? label.wsCategory
                                      : label.subcategory}
                                  </WSText.ParagraphSm>
                                </WSElement>
                              </WSFlexBox.CenterY>
                              <WSIcon
                                block
                                size="XS"
                                name="chevron-down"
                                color="blue500"
                              />
                            </WSFlexBox.CenterY>
                          );
                        }

                        return (
                          <WSFlexBox.CenterY
                            px={isDesktop ? "2XL" : "NONE"}
                            wrap="nowrap"
                            mx={isDesktop ? "2XL" : "NONE"}
                            justify="space-between"
                            className={styles.selectCategory}
                            onClick={open}
                            data-testid="addCategory"
                          >
                            <WSFlexBox.CenterY>
                              <WSButton.Link>Add category</WSButton.Link>
                            </WSFlexBox.CenterY>
                            <WSIcon
                              block
                              size="XS"
                              name="chevron-down"
                              color="blue500"
                            />
                          </WSFlexBox.CenterY>
                        );
                      }}
                      onUpdate={async data => {
                        const amount =
                          Math.abs(transaction.amount) *
                          (data.isPositive ? 1 : -1);

                        await updateTransaction({
                          transactionId: transaction.transactionId,
                          wsCategory: data.wsCategory as WSCategory,
                          amount: amount,
                          labels: {
                            ...transaction.labels,
                            subcategory: data.subcategory ?? ""
                          },
                          business: data.business,
                          businessAmount: amount,
                          type: data.type
                        });
                      }}
                    />

                    <Editable
                      mt="XL"
                      openButtonLabel={null}
                      renderEdit={(onClose, setLoading, loading) => (
                        <WSFormOld
                          defaultValues={{
                            note: transaction.note
                          }}
                          validationSchema={Yup.object().shape({
                            note: Yup.string().max(200)
                          })}
                          onSubmit={async formData => {
                            setLoading(true);
                            await updateTransaction({
                              transactionId: transaction.transactionId,
                              note: formData.note
                            });
                            setLoading(false);

                            onClose();
                          }}
                        >
                          <WSText.Heading4 mb="XL">
                            {transaction.note ? "Edit" : "Add"} note
                          </WSText.Heading4>

                          <WSFormOld.Field
                            mb="2XL"
                            label="Note text"
                            name="note"
                            component={WSTextArea}
                          />
                          <WSButton.Primary
                            mt="3XL"
                            type="submit"
                            fullWidth={isMobile}
                            loading={loading}
                          >
                            {transaction.note ? "Update" : "Add"} note
                          </WSButton.Primary>
                        </WSFormOld>
                      )}
                      renderView={onOpen =>
                        transaction.note ? (
                          <WSFlexBox
                            mx={isDesktop ? "2XL" : "NONE"}
                            wrap="nowrap"
                            justify="space-between"
                            className={styles.note}
                            onClick={onOpen}
                          >
                            <WSFlexBox wrap="nowrap">
                              <WSIcon
                                block
                                name="file"
                                size="M"
                                mr="M"
                                color="gray600"
                              />
                              <WSText color="gray600">
                                {transaction.note}
                              </WSText>
                            </WSFlexBox>
                            <WSIcon
                              block
                              name="edit"
                              size="S"
                              ml="M"
                              color="gray600"
                            />
                          </WSFlexBox>
                        ) : (
                          <WSFlexBox.CenterY
                            mx={isDesktop ? "2XL" : "NONE"}
                            wrap="nowrap"
                            justify="space-between"
                            className={styles.note}
                            onClick={onOpen}
                          >
                            <WSFlexBox.CenterY>
                              <WSIcon
                                block
                                name="file"
                                size="M"
                                mr="M"
                                color="gray600"
                              />
                              <WSText color="blue500">Add Note</WSText>
                            </WSFlexBox.CenterY>
                            <WSIcon
                              block
                              name="chevron-right"
                              size="XS"
                              color="blue500"
                            />
                          </WSFlexBox.CenterY>
                        )
                      }
                    />

                    {invoice &&
                    transaction.wsCategory !== WSCategory.ContractorPayment ? (
                      <>
                        <WSDivider my="XL" />
                        <WSElement px={isDesktop ? "2XL" : "NONE"}>
                          <WSText.Heading5>Invoice details</WSText.Heading5>
                          <WSFlexBox.CenterY mt="M" justify="space-between">
                            <WSText>Total earnings</WSText>
                            <WSText formatMoney>{totalBilled}</WSText>
                          </WSFlexBox.CenterY>
                          <WSFlexBox.CenterY mt="M" justify="space-between">
                            <WSText color="gray500">
                              Tax withholdings{" "}
                              {withholdings !== 0 && `(${withholdingsRate}%)`}
                            </WSText>
                            {withholdings ? (
                              <WSText color="gray500" formatMoney>
                                {Math.abs(withholdings) * -1}
                              </WSText>
                            ) : (
                              <WSText color="gray500">n/a</WSText>
                            )}
                          </WSFlexBox.CenterY>
                          <WSDivider mt="M" />
                          <WSFlexBox.CenterY mt="M" justify="space-between">
                            <WSText weight="medium">{payoutAccountName}</WSText>
                            <WSText weight="medium" formatMoney>
                              {depositedAmount}
                            </WSText>
                          </WSFlexBox.CenterY>
                          <WSButton.Link
                            mt="M"
                            onClick={() =>
                              history.push(
                                `/member/invoices/${transaction.invoiceId}`
                              )
                            }
                          >
                            Go to invoice
                          </WSButton.Link>
                        </WSElement>
                      </>
                    ) : null}
                    {invoice &&
                    transaction.wsCategory === WSCategory.ContractorPayment ? (
                      <>
                        <WSDivider my="XL" />
                        <WSElement px={isDesktop ? "2XL" : "NONE"}>
                          <WSText.Heading5>Payable details</WSText.Heading5>
                          <WSButton.Link
                            mt="M"
                            onClick={() =>
                              history.push(
                                `/member/invoices/payables/${transaction.invoiceId}`
                              )
                            }
                          >
                            Go to payable
                          </WSButton.Link>
                        </WSElement>
                      </>
                    ) : null}
                    {!shouldPauseAccount &&
                    invoice &&
                    qMember.data.profile.withholdings?.tax?.status !==
                      MemberWithholdingStatus.Active ? (
                      <WSElement px={isDesktop ? "2XL" : "NONE"}>
                        <WSDivider my="XL" />
                        <WSFlexBox.CenterY mb="M">
                          <WSIcon
                            block
                            name="alert-circle"
                            color="amber400"
                            mr="M"
                          />
                          <WSText weight="medium">
                            Tax withholdings is not enabled
                          </WSText>
                        </WSFlexBox.CenterY>
                        <WSText.ParagraphXs color="gray600" mb="XL">
                          Enable tax withholdings today to ensure you’re setting
                          aside enough for {new Date().getFullYear()} taxes.
                        </WSText.ParagraphXs>
                        <WSButton.Link
                          mb="M"
                          onClick={() => {
                            history.push("/member/taxes");
                          }}
                        >
                          Learn more
                        </WSButton.Link>
                      </WSElement>
                    ) : null}
                  </>
                </WSPanel>

                {transaction.type === TransactionType.Expense &&
                transaction.scheduleCDescription ? (
                  <WSPanel noBorder mb="L">
                    <WSElement px={isDesktop ? "2XL" : "NONE"}>
                      <WSText.ParagraphSm mb="M" color="gray600">
                        Schedule C Category
                      </WSText.ParagraphSm>
                      <WSText weight="medium">
                        {transaction.scheduleCDescription}
                      </WSText>
                    </WSElement>
                  </WSPanel>
                ) : null}

                <WSPanel noBorder mb="L">
                  <DeleteTransaction />
                  <WSElement px={isDesktop ? "2XL" : "NONE"}>
                    <WSButton.Secondary
                      destructive
                      onClick={async () => {
                        openModal(BOOKKEEPING_DELETE_TRANSACTION, {
                          transactionId: transaction.transactionId
                        });
                      }}
                      name="deleteTransaction"
                    >
                      Delete transaction
                    </WSButton.Secondary>
                  </WSElement>
                </WSPanel>
              </WSElement>
            );
          }}
        </WSQueries>
      </WSSidebar>
    </>
  );
};
