import flatten from "lodash/flatten";
import { WSInfiniteQueryConfig } from "@ws-react-query";

import {
  getPayerRows,
  IPayerRow,
  PayerRowsQuery
} from "../../../../services/search/payer";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../../helpers";

const PAGE_SIZE = 20;

export const QUERY_PAYER_ROWS = "QUERY_PAYER_ROWS";

export const useQueryPayerRows = (
  requestQuery?: PayerRowsQuery,
  config?: WSInfiniteQueryConfig<IPayerRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IPayerRow[], WSServiceError>(
    [QUERY_PAYER_ROWS, requestQuery],
    ({ pageParam = 1 }) => {
      return getPayerRows({
        ...requestQuery,
        page: {
          size: PAGE_SIZE,
          number: pageParam
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};
