import {
  WSBanner,
  WSButton,
  WSCard,
  WSEmptyState,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useClientQuery } from "../../../../query/users/queries";

import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";
import { usePaymentsSettingsManagedByOrganization } from "../../../../userFeatures/usePaymentsSettingsManagedByOrganization";
import { useUserOrganizationName } from "../../../../userFeatures/useUserOrganizationName";
import { useCustomization } from "../../../customization";
import { LabelMethod } from "../LabelMethod";
import { MethodType } from "../ManageMethods";

type Props = {
  onManage: () => void;
  onAddNew: () => void;
  helperText?: string;
  hidable?: boolean;
};

export const DefaultPaymentMethod: React.FC<Props> = props => {
  const hasManagedPayments = !!usePaymentsSettingsManagedByOrganization();

  const userId = useUserId();
  const queryClient = useClientQuery(userId);
  const [isSendPaymentsVisible, setIsSendPaymentsVisible] = useLocalStorage(
    userId + ":settings:isSendPaymentsVisible",
    hasManagedPayments ? true : false
  );

  const organizationName = useUserOrganizationName();
  const { support } = useCustomization();
  const payoutInformationUrl =
    support(false)?.documentation?.payoutInformationUrl;

  const [type, id] = useMemo(() => {
    let type: MethodType | undefined, id;

    if (queryClient.data?.profile.defaultPaymentMethod?.accountId) {
      id = queryClient.data?.profile.defaultPaymentMethod.accountId;
      type = "Account";
    } else if (
      queryClient.data?.profile.defaultPaymentMethod?.paymentMethodId
    ) {
      id = queryClient.data?.profile.defaultPaymentMethod.paymentMethodId;
      type = "CreditCard";
    }

    return [type, id];
  }, [
    queryClient.data?.profile.defaultPaymentMethod?.accountId,
    queryClient.data?.profile.defaultPaymentMethod?.paymentMethodId
  ]);

  const isShowManagedBanner = payoutInformationUrl && hasManagedPayments;

  const helperText = useMemo(() => {
    if (typeof props.helperText === "undefined") {
      return (
        (hasManagedPayments ? "Method" : "Default method") +
        " to pay others outside of contractor payroll - e.g. transferring funds to a Wingspan account (Wallet or Tax Withholdings), or paying off-platform users through the Client Payment portal."
      );
    }

    return props.helperText;
  }, [hasManagedPayments, props.helperText]);

  if (props.hidable && !isSendPaymentsVisible) {
    return (
      <WSButton.Link
        mb={"L"}
        onClick={() => {
          setIsSendPaymentsVisible(true);
        }}
        name="showPaymentMethods"
      >
        Show payment method
      </WSButton.Link>
    );
  }

  return (
    <WSCard
      divider={!hasManagedPayments}
      header={{
        label: {
          text: "Payment method",
          helperText
        },
        value: {
          pill: {
            theme: "neutral",
            text: "Default",
            badge: true
          }
        }
      }}
      actions={{
        alignment: "left",
        buttons: hasManagedPayments
          ? []
          : [
              {
                kind: "Link",
                rightIcon: "hide",
                label: "Hide (I don’t use payment method)",
                visible: props.hidable ?? false,
                onClick: () => {
                  setIsSendPaymentsVisible(false);
                }
              },
              {
                kind: "Link",
                label: "Manage payment method",
                onClick: props.onManage
              }
            ]
      }}
    >
      {type && id ? (
        <>
          <WSFlexBox alignItems="center" justify="space-between" wrap="nowrap">
            <LabelMethod type={type} id={id} />
          </WSFlexBox>
          {isShowManagedBanner ? (
            <WSBanner mt="L" theme="info">
              Payment method is managed by <b>{organizationName}</b> - contact{" "}
              {organizationName} support.
            </WSBanner>
          ) : null}
        </>
      ) : (
        <>
          <WSEmptyState
            type="card"
            title="No payment method added"
            orientation="horizontal"
            description={
              isShowManagedBanner
                ? `This feature is not currently active through your ${organizationName}`
                : "Add a default payment method to pay others outside of contractor payroll."
            }
            size="S"
            fullWidth
            buttons={
              hasManagedPayments
                ? []
                : [
                    {
                      label: "Add payment method",
                      kind: "Secondary",
                      onClick: props.onAddNew
                    }
                  ]
            }
          />
          {isShowManagedBanner ? (
            <WSBanner mt="L" theme="info">
              Payment method is managed by <b>{organizationName}</b> - contact{" "}
              {organizationName} support.
            </WSBanner>
          ) : null}
        </>
      )}
    </WSCard>
  );
};
