import {
  toWSDateString,
  WSButton,
  WSChip,
  WSElement,
  WSFlexBox,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import { IBulkCollaboratorBatch } from "@wingspanhq/payments/dist/interfaces";
import format from "date-fns/format";
import { Route, useHistory, useLocation } from "react-router-dom";
import queryString from "qs";

import { WSQueries } from "../../../../query/WSQuery";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";
import { TotalCollaboratorsCount } from "../../components/TotalCollaboratorsCount";
import {
  useBulkCollaboratorBatchesQuery,
  useQueryCollaboratorBatchesSummary
} from "../../query/bulkCollaborator/queries";
import { BulkResource } from "../../types";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { BulkImportCollaboratorBatchDetails } from "../BulkImportCollaboratorBatchDetails";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useGoToContractorBatchDetails,
  useGoToContractorImportStart
} from "../../paths";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { pluralize } from "../../../../shared/utils/pluralize";

import styles from "../BulkImportPayables/styles.module.scss";
import { useUrlQueryFilters } from "../../../../utils/router";
import { BulkBatchesFilters } from "../../services/types";

export function BulkImportCollaborators() {
  const history = useHistory();
  const location = useLocation();
  const queries: { year?: string } = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const { filters } = useUrlQueryFilters<BulkBatchesFilters>({
    year: queries.year
  });
  const queryBulkCollaboratorBatches = useBulkCollaboratorBatchesQuery(filters);
  const queryCollaboratorBatchesSummary =
    useQueryCollaboratorBatchesSummary(filters);
  const goToContractorImportStart = useGoToContractorImportStart();
  const queryFeatureFlags = useFeatureFlags();
  useBrowserPageTitle("Bulk imports: Contractors");

  const doFirstImport = () => {
    goToContractorImportStart();
  };

  const columns: Array<WSTableColumn<IBulkCollaboratorBatch>> = [
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Created" }
      },
      renderFunction: row => (
        <WSTableCell
          text={toWSDateString(row.data.createdAt, "monthDayYear")}
          secondaryText={format(row.data.createdAt, "hh:mm aaa")}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.8fr",
        justify: "start",
        header: { text: "Name" }
      },
      renderFunction: row => (
        <WSTableCell
          text={
            (row.data.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName] as string) ||
            getUploadedFilename(row.data, BulkResource.Collaborator) ||
            "--"
          }
          secondaryText={
            row.data.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName]
              ? getUploadedFilename(row.data, BulkResource.Collaborator)
              : undefined
          }
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        justify: "start",
        header: { text: "Imported by" },
        hideOnScreens: ["XS"]
      },
      renderFunction: row => (
        <WSTableCell text={getBatchCreatorName(row.data)} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Contractors count" },
        hideOnScreens: ["XS"]
      },
      renderFunction: row => (
        <TotalCollaboratorsCount batchId={row.data.bulkCollaboratorBatchId} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import status" }
      },
      renderFunction: row => (
        <BulkBatchImportStatus
          showIcon
          batchId={row.data.bulkCollaboratorBatchId}
          bulkResource={BulkResource.Collaborator}
        />
      )
    }
  ];

  const goToContractorBatchDetails = useGoToContractorBatchDetails();

  return (
    <>
      {!queryFeatureFlags.data?.newBulkImporterUI ? (
        <Route
          path="/member/imports/collaborators/:bulkBatchId"
          component={BulkImportCollaboratorBatchDetails}
          exact
        />
      ) : null}
      <WSQueries
        queries={{
          queryBulkCollaboratorBatches,
          queryCollaboratorBatchesSummary
        }}
      >
        {({
          queryBulkCollaboratorBatchesData: bulkCollaboratorBatches,
          queryCollaboratorBatchesSummaryData: batchesSummary
        }) => {
          const tableData = bulkCollaboratorBatches.map(collaboratorBatch => {
            return {
              data: collaboratorBatch,
              id: collaboratorBatch.bulkCollaboratorBatchId
            };
          });

          return (
            <>
              <WSFlexBox.CenterY className={styles.statsContainer}>
                <WSElement className={styles.resultsStats}>
                  <WSText.ParagraphXs color="gray500">
                    Showing:
                  </WSText.ParagraphXs>
                  <WSChip
                    pillText={pluralize(batchesSummary?.listSize)}
                    theme="neutral"
                    pillType="noBackground"
                  />
                </WSElement>
              </WSFlexBox.CenterY>
              <WSInfiniteScroll
                onLoad={() => {
                  queryBulkCollaboratorBatches.fetchNextPage();
                }}
                loadMore={bulkCollaboratorBatches.length > 0}
                endOfList={!queryBulkCollaboratorBatches.hasNextPage}
                loading={!!queryBulkCollaboratorBatches.isFetchingNextPage}
              >
                <WSTable<IBulkCollaboratorBatch>
                  mt="L"
                  bottomMessage={
                    queryBulkCollaboratorBatches.isFetched &&
                    tableData.length === 0 ? (
                      <WSFlexBox.Center direction="column" mt="3XL">
                        <WSText>No imports found.</WSText>
                        <WSButton.Link onClick={doFirstImport}>
                          Import contractors
                        </WSButton.Link>
                      </WSFlexBox.Center>
                    ) : null
                  }
                  tableData={tableData}
                  columns={columns}
                  onRowClick={rowItem => {
                    if (queryFeatureFlags.data?.newBulkImporterUI) {
                      goToContractorBatchDetails(rowItem.id);
                    } else {
                      history.push(
                        `/member/imports/collaborators/${rowItem.id}`
                      );
                    }
                  }}
                />
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
}
