import { WSModalOld, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

export const TAXES_MODAL_SCHEDULE_C_INFO = "TAXES_MODAL_SCHEDULE_C_INFO";

export const TaxesModalScheduleCInfo: React.FC = () => (
  <WSModalOld
    name={TAXES_MODAL_SCHEDULE_C_INFO}
    size="S"
    title="Schedule C PDF"
  >
    <WSText weight="medium" mb="M">
      What is the Schedule-C?
    </WSText>
    <WSText mb="M">
      As a self-employed individual, you need to fill out an IRS Schedule C to
      report how much money you made in your business. This form must included
      with your income tax return if you had self-employment (1099) income.
    </WSText>
    <WSText weight="medium" mb="M">
      What’s in the Schedule-C?
    </WSText>
    <WSText mb="M">
      The Schedule C has five section. Part I includes a list all the income of
      your business generated and your gross profit. Part II contains your
      business expenses and your net profit or loss.
    </WSText>

    <WSText mb="M">
      If your business requires you to purchase inventory, you only need to
      complete Parts III through V.
    </WSText>
  </WSModalOld>
);
