import {
  toWSDateString,
  useModalOldContext,
  WSElement,
  WSElementProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import {
  IInvoiceTemplate,
  IPayerResponse,
  IScheduleDate,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPayerName,
  getPayerSecondaryName
} from "../../../modules/Payers/utils/getPayerNames";
import {
  useInvoicesQuery,
  useInvoiceTemplatesQuery
} from "../../../query/payments/queries";
import {
  getInvoice,
  getInvoiceTemplate
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { calculateLineItemsTotal } from "../../utils";
import { buildInvoiceIcon } from "../InvoicesTable/buildInvoiceIcon";
import { SERIES_INVOICE_ACTIONS_MODAL } from "../SeriesInvoiceActionsModal/SeriesInvoiceActionsModal";

export type SeriesTableItemData = {
  index: number;
  scheduleDate: IScheduleDate;
};

type Props = WSElementProps & {
  data: SeriesTableItemData[];
  parentInvoiceTemplate: IInvoiceTemplate;
  payer: IPayerResponse;
  noActions?: boolean;
};

export const InvoiceSeriesTable: React.FC<Props> = ({
  data,
  parentInvoiceTemplate,
  payer,
  noActions,
  ...elementProps
}) => {
  const location = useLocation();
  const history = useHistory();
  const invoiceTemplatesQuery = useInvoiceTemplatesQuery();
  const invoicesQuery = useInvoicesQuery();
  const { openModal } = useModalOldContext();

  const payerName = getPayerName(payer);
  const payerSecondaryName = getPayerSecondaryName(payer);

  return (
    <WSElement {...elementProps}>
      <WSQueries
        queries={{
          invoiceTemplatesQuery,
          invoicesQuery
        }}
      >
        {({
          invoiceTemplatesQuery: { data: invoiceTemplates },
          invoicesQuery: { data: invoices }
        }) => {
          const getScheduleDateStatusText = (
            scheduleDate: IScheduleDate
          ): string => {
            const invoiceTemplate = scheduleDate.invoiceTemplateId
              ? getInvoiceTemplate(
                  invoiceTemplates,
                  scheduleDate.invoiceTemplateId
                )
              : undefined;

            return scheduleDate.status === ScheduleStatus.Skipped
              ? scheduleDate.date > new Date()
                ? "Skipping"
                : "Skipped"
              : scheduleDate.status === ScheduleStatus.Completed
              ? "Sent"
              : scheduleDate.status === ScheduleStatus.Modified
              ? invoiceTemplate && invoiceTemplate.scheduleDates?.[0]
                ? getScheduleDateStatusText(invoiceTemplate.scheduleDates[0])
                : "–"
              : "Scheduled";
          };

          return (
            <WSTable
              tableData={data.map(({ index, scheduleDate }) => {
                const invoice = scheduleDate.invoiceId
                  ? getInvoice(invoices, scheduleDate.invoiceId)
                  : undefined;

                const invoiceTemplate = scheduleDate.invoiceTemplateId
                  ? getInvoiceTemplate(
                      invoiceTemplates,
                      scheduleDate.invoiceTemplateId
                    )
                  : undefined;
                return {
                  id: String(index),
                  data: {
                    scheduleDate,
                    invoice,
                    invoiceTemplate
                  }
                };
              })}
              onRowClick={
                noActions
                  ? undefined
                  : ({ id, data }) => {
                      if (data.invoice) {
                        history.push({
                          pathname: `${location.pathname}/invoice/${data.invoice.invoiceId}`,
                          state: {
                            backPath: location.pathname
                          },
                          search: location.search
                        });
                      } else {
                        openModal(SERIES_INVOICE_ACTIONS_MODAL, {
                          parentInvoiceTemplateId:
                            parentInvoiceTemplate.invoiceTemplateId,
                          scheduleDateIndex: Number(id)
                        });
                      }
                    }
              }
              columns={[
                {
                  config: {
                    gridTemplateSizeMax: "0.7fr"
                  },
                  renderFunction: ({ data: { scheduleDate, invoice } }) => (
                    <WSTableCell
                      avatar={{
                        type: "icon",
                        ...(scheduleDate.status === ScheduleStatus.Skipped
                          ? {
                              icon: "skip-circle",
                              colorBackground: "gray50",
                              color: "gray500"
                            }
                          : invoice
                          ? buildInvoiceIcon(invoice)
                          : {
                              icon: "calendar",
                              colorBackground: "gray50",
                              color: "gray500"
                            })
                      }}
                      text={payerName}
                      secondaryText={payerSecondaryName}
                    />
                  )
                },
                {
                  config: {
                    gridTemplateSizeMax: "0.6fr",
                    justify: "end"
                  },
                  renderFunction: ({ data }) => {
                    return (
                      <WSTableCell
                        text={String(
                          calculateLineItemsTotal(
                            data.invoice
                              ? data.invoice.lineItems
                              : data.invoiceTemplate
                              ? data.invoiceTemplate.invoiceData.lineItems
                              : parentInvoiceTemplate?.invoiceData.lineItems ||
                                []
                          )
                        )}
                        secondaryText={`${getScheduleDateStatusText(
                          data.scheduleDate
                        )} ${toWSDateString(
                          data.scheduleDate.date,
                          "monthDate"
                        )}`}
                      />
                    );
                  }
                }
              ]}
            />
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
