import { WSButton, WSElement, WSPanel } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYMENT_METHODS,
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD
} from "..";
import { WSQueries } from "../../../../../query/WSQuery";
import { useBankingAccount } from "../../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryPaymentsCardsDebit } from "../../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../../query/payments/queries";
import {
  useAccounts,
  useMemberProfile
} from "../../../../../query/users/queries";
import { LayoutFullscreen } from "../../../../../shared/components/LayoutFullscreen";
import { selectorUseInternationalPayouts } from "../../../../../shared/selectors/selectorUseInternationalPayouts";
import { InstantMethods } from "../../../../PaymentMethods/components/InstantMethods";
import { InternationalMethods } from "../../../../PaymentMethods/components/InternationalMethods";
import { StandardMethods } from "../../../../PaymentMethods/components/StandardMethods";

export const RouteManagePayoutMethod: React.FC<RouteComponentProps> = () => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const queryPayoutSettingsDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();
  const queryMember = useMemberProfile(userId);
  const queryCustomerEntity = useQueryCustomerEntity();
  const history = useHistory();

  return (
    <LayoutFullscreen
      headTitle="Edit payout methods"
      title="Edit payout methods"
      description="Add a new payout method or set an existing payout method as default"
      onBack={() => {
        history.push(PATH_SETTINGS_PAYMENT_METHODS);
      }}
    >
      <WSQueries
        queries={{
          queryPayoutSettings,
          queryAccounts,
          queryPayoutSettingsDebitCards,
          queryMember,
          queryPaymentCards
        }}
      >
        {({
          queryPayoutSettings: { data: payoutSettings },
          queryAccounts: { data: accounts },
          queryPayoutSettingsDebitCards: { data: debitCards },
          queryMember: { data: member },
          queryPaymentCards: { data: paymentCards }
        }) => {
          const isInternational = selectorUseInternationalPayouts(
            member,
            queryCustomerEntity.data
          );

          return (
            <WSElement>
              {isInternational ? (
                <>
                  <StandardMethods
                    showInternationalTitle
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                    mb="XL"
                  />
                  <InternationalMethods
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                  />
                </>
              ) : (
                <>
                  <StandardMethods
                    payoutSettings={payoutSettings}
                    internalAccount={queryBankingAccount.data}
                    accounts={accounts}
                    mb="XL"
                  />
                  <InstantMethods
                    internalAccount={queryBankingAccount.data}
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                    debitCards={debitCards}
                    paymentCards={paymentCards}
                  />
                </>
              )}

              <WSPanel
                mt="2XL"
                mb="XL"
                onClick={() => {
                  history.push(PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD);
                }}
              >
                <WSButton.Link icon="plus-circle">
                  Add new payout method
                </WSButton.Link>
              </WSPanel>

              <WSButton
                fullWidth
                onClick={() => {
                  history.push(PATH_SETTINGS_PAYMENT_METHODS);
                }}
              >
                Save changes
              </WSButton>
            </WSElement>
          );
        }}
      </WSQueries>
    </LayoutFullscreen>
  );
};
