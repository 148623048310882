import {
  WSElement,
  WSLoader,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";
import { usePaymentsStatusQuery } from "../../../query/payments/queries";
import { RouteDashboard } from "../routes/RouteDashboard";
import { RouteTransactions } from "../routes/RouteTransactions";
import { selectorDefaultInternalAccount } from "../selectors/selectorDefaultInternalAccount";
import { RouteNewDashboard } from "./RouteNewDashboard";
import { RouteNewTransactions } from "./RouteNewTransactions";
import { WalletGettingStarted } from "./WalletGettingStarted";

export const RouteRootDashboard = () => {
  const history = useHistory();
  const queryInternalAccounts = useQueryInternalAccounts();
  const queryPaymentSettings = usePaymentsStatusQuery();

  const defaultInternalAccount = useMemo(
    () =>
      selectorDefaultInternalAccount(
        queryInternalAccounts.data || [],
        queryPaymentSettings.data
      ),
    [queryInternalAccounts.data, queryPaymentSettings.data]
  );

  if (queryInternalAccounts.isInitialLoading) {
    return (
      <WSPage>
        <WSPageToolbar title="Wingspan Wallet" mb="2XL" />
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  if (!defaultInternalAccount) {
    return <WalletGettingStarted />;
  }

  if (defaultInternalAccount.type === "ApPrefunding") {
    return (
      <WSPage>
        <WSPageToolbar title="Wingspan Wallet" />

        <Tabs
          mb="2XL"
          tabs={[
            {
              label: "Overview",
              path: "/member/wallet"
            },
            {
              label: "Transactions",
              path: "/member/wallet/transactions"
            }
          ]}
        />

        <Switch>
          <Route
            path="/member/wallet/transactions"
            render={() => (
              <RouteNewTransactions
                internalAccountId={defaultInternalAccount.internalAccountId}
              />
            )}
          />

          <Route
            path="/member/wallet"
            render={() => (
              <RouteNewDashboard
                internalAccountId={defaultInternalAccount.internalAccountId}
              />
            )}
          />
        </Switch>
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar
        title="Wingspan Wallet"
        actions={[
          {
            buttonKind: "Primary",
            label: "Transfer",
            onClick: () => {
              history.push("/member/transfer?balance=wallet");
            }
          }
        ]}
      />

      <WSElement mb="2XL">
        <Tabs
          tabs={[
            {
              label: "Overview",
              path: "/member/wallet"
            },
            {
              label: "Transactions",
              path: "/member/wallet/transactions"
            }
          ]}
        />
      </WSElement>

      <Switch>
        <Route
          path="/member/wallet/transactions"
          component={RouteTransactions}
        />

        <Route path="/member/wallet" component={RouteDashboard} />
      </Switch>
    </WSPage>
  );
};
