import {
  IPayeeResponse,
  PayeeTaxDocumentSharePermission
} from "@wingspanhq/payments/dist/interfaces";

export const isPayeeProvidedInfo = (payee: IPayeeResponse) => {
  return (
    payee.payeeOwnedData.shareTaxDocument ===
    PayeeTaxDocumentSharePermission.Allow
  );
};

export const getTaxFormRequirementName = (
  payee: IPayeeResponse,
  payerCountry: string = "US"
) => {
  /*
  https://linear.app/wingspan/issue/WIN-11306

United States (Payer) → United States (Payee)
- Tax Form Name: Form W-9
United States (Payer) → Canada (Payee)
- Tax Form Name: Form W-8BEN
United States (Payer) → Other (Payee)
-Tax Form Name: Form W-8BEN
Canada (Payer) → United States (Payee)
- Tax Form Name: Form W-9
Canada (Payer) → Canada (Payee)
- Tax Form Name: Canadian Taxpayer Information Certification
Canada (Payer) → Other (Payee)
-Tax Form Name: Local Tax Identification Certification
Other (Payer) → United States (Payee)
-Tax Form Name: Form W-9
Other (Payer) → Canada (Payee)
-Tax Form Name: Canadian Taxpayer Information Certification
Other (Payer) → Other (Payee)
-Tax Form Name: Local Tax Identification Certification
  * */

  const isUSPayer = payerCountry === "US";
  const isCAPayer = payerCountry === "CA";
  const isOtherPayer = !isUSPayer && !isCAPayer;

  const payeeCountry = payee.member?.profile?.address?.country || "US";

  const isUSPayee = payeeCountry === "US";
  const isCAPayee = payeeCountry === "CA";
  const isOtherPayee = !isUSPayee && !isCAPayee;

  if (isUSPayer) {
    if (isUSPayee) {
      return "Form W-9";
    } else if (isCAPayee || isOtherPayee) {
      return "Form W-8BEN";
    }
  } else if (isCAPayer) {
    if (isUSPayee) {
      return "Form W-9";
    } else if (isCAPayee) {
      return "Canadian Taxpayer Information Certification";
    } else if (isOtherPayee) {
      return "Local Tax Identification Certification";
    }
  } else if (isOtherPayer) {
    if (isUSPayee) {
      return "Form W-9";
    } else if (isCAPayee) {
      return "Canadian Taxpayer Information Certification";
    } else if (isOtherPayee) {
      return "Local Tax Identification Certification";
    }
  }
};
