import {
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSTableProps,
  toWSMoneyString,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import React, { useCallback } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { RouteInvoiceTemplateDetails } from "../../../../Invoices/screens/RouteInvoiceTemplateDetails";
import { calculateLineItemsTotal } from "../../../../Invoices/utils";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { IInvoiceTemplateRow } from "../../service";
import { buildIcon } from "./buildIcon";
import { useGetActions } from "./useGetActions";

type TableItem = WSTableItem<IInvoiceTemplateRow>;

type Props = {
  invoiceTemplates: IInvoiceTemplateRow[];
} & Omit<WSTableProps<IInvoiceTemplateRow>, "tableData">;

export const TableInvoiceTemplates: React.FC<Props> = ({
  invoiceTemplates,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const getActions = useGetActions();
  const match = useRouteMatch();

  const tableData: WSTableItem<IInvoiceTemplateRow>[] = invoiceTemplates.map(
    invoiceTemplate => ({
      id: invoiceTemplate.invoiceTemplateId,
      data: invoiceTemplate,
      icon: buildIcon(invoiceTemplate)
    })
  );

  const onView = useCallback(
    (item: TableItem) => {
      history.push({
        pathname: match.path + "/" + item.id,
        search: location.search,
        state: {
          backPath: location.pathname
        }
      });
    },
    [history, location.pathname, location.search, match.path]
  );

  const onViewInNewTab = useCallback(
    (item: TableItem) => {
      openInNewTab(match.path + `/${item.id}${location.search}`);
    },
    [location.search, match.path]
  );

  const rowMenuActions = useCallback(
    (item: TableItem) => {
      const { data: invoiceTemplate } = item;
      const menuItems: WSMenuItem[] = [];

      menuItems.push({
        icon: "search",
        label: "View",
        onClick: () => {
          onView(item);
        }
      });

      menuItems.push(...getActions(invoiceTemplate));

      return menuItems;
    },
    [getActions, onView]
  );

  return (
    <>
      <Route
        path={match.path + "/:invoiceTemplateId"}
        component={RouteInvoiceTemplateDetails}
      />
      <WSTable<IInvoiceTemplateRow>
        tableData={tableData}
        columns={[
          {
            config: {
              gridTemplateSizeMax: "0.7fr"
            },
            renderFunction: ({ data: invoiceTemplate }) => {
              return (
                <WSTableCell
                  avatar={{
                    type: "icon",
                    ...buildIcon(invoiceTemplate)
                  }}
                  text={
                    invoiceTemplate.primaryName ||
                    invoiceTemplate.invoiceData.clientCompany ||
                    invoiceTemplate.invoiceData.clientName
                  }
                  secondaryText={
                    invoiceTemplate.secondaryName ||
                    invoiceTemplate.invoiceData.clientEmail
                  }
                />
              );
            }
          },
          {
            config: {
              gridTemplateSizeMax: "0.6fr",
              justify: "end"
            },
            renderFunction: ({ data: invoiceTemplate }) => (
              <WSTableCell
                text={toWSMoneyString(
                  calculateLineItemsTotal(
                    invoiceTemplate.invoiceData.lineItems
                  ),
                  "default",
                  invoiceTemplate.invoiceData.currency
                )}
                secondaryText={invoiceTemplate.invoiceData.clientEmail}
              />
            )
          }
        ]}
        onRowClick={onView}
        onRowControlClick={onViewInNewTab}
        onRowMousewheelClick={onViewInNewTab}
        rowMenuActions={rowMenuActions}
        {...props}
      />
    </>
  );
};
