import cn from "classnames";
import React from "react";
import {
  WSMarginProps,
  WSPaddingProps
} from "../WSElement/WSElement.component";
import { ComponentWithKinds } from "../WSElement/WSElement.helpers";
import {
  WSFlexBox,
  WSFlexBoxProps
} from "../layout/WSFlexBox/WSFlexBox.component";
import styles from "./WSList.module.scss";

export const WSListKinds = ["Vertical", "Horizontal"] as const;
type WSListKindsType = typeof WSListKinds[number];

export interface WSListProps extends WSMarginProps, WSPaddingProps {
  kind?: WSListKindsType;
  className?: string;
  gap?: WSFlexBoxProps["gap"];
  wrap?: WSFlexBoxProps["wrap"];
  equalItems?: boolean;
}

export const WSList: ComponentWithKinds<WSListProps, WSListKindsType> = ({
  kind = "Vertical",
  gap = "M",
  className,
  children,
  equalItems,
  ...componentProps
}) => {
  const classNames = cn(
    className,
    styles.base,
    styles[kind],
    equalItems && styles.equalItems
  );
  const isVertical = kind === "Vertical";

  return (
    <WSFlexBox
      direction={isVertical ? "column" : "row"}
      alignItems={isVertical ? "stretch" : "flex-start"}
      wrap={isVertical ? "nowrap" : "wrap"}
      gap={gap}
      className={classNames}
      {...componentProps}
    >
      {children}
    </WSFlexBox>
  );
};

WSList.Horizontal = (props) => <WSList kind="Horizontal" {...props} />;
WSList.Vertical = (props) => <WSList kind="Vertical" {...props} />;
