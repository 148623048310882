import {
  WSButton,
  WSButtons,
  WSCentered,
  WSFlexBox,
  WSFormOld,
  WSInputMaskOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users";
import cn from "classnames";
import queryString from "qs";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import {
  useCMSWebflowEnterprise,
  useCMSWebflowPartner
} from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { getCurrentEntepriseId } from "../../query/platform/selectors";
import { useSession } from "../../query/session";
import { useUserLocation } from "../../query/user";
import { useBeginPhoneVerification } from "../../query/users/mutations";
import {
  useActivities,
  useUserProfile,
  useUserTag
} from "../../query/users/queries";
import { ErrorContextKey } from "../../services/platform";
import { useSetWSStore, useWSStore } from "../../store";
import { getCountryCode } from "../utils/phoneUtils";
import styles from "./styles.module.scss";

export interface VerifyPhoneNumberProps {}

export const VerifyPhoneNumber: React.FC<VerifyPhoneNumberProps> = () => {
  const history = useHistory();
  const location = useLocation<{ redirectPath?: string }>();
  const store = useWSStore();
  const setStore = useSetWSStore();
  const userId = useUserId();
  const locationQuery = useUserLocation();
  const [beginPhoneVerification, beginPhoneVerificationMeta] =
    useBeginPhoneVerification(userId);
  const activityQuery = useActivities(userId);
  const userProfileQuery = useUserProfile(userId);
  const user = userProfileQuery.data as INewUser;

  const queries = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const redirectPath = location.state?.redirectPath;

  useEffect(() => {
    const nextUrl = queries.next as string;
    if (nextUrl) {
      window.sessionStorage.setItem("OTP_FLOW_NEXT_URL", nextUrl);
    }

    if (redirectPath) {
      window.sessionStorage.setItem("OTP_FLOW_NEXT_URL", redirectPath);
    }
  }, [queries.next, redirectPath]);
  const partnerId = queries.partner as string;
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: !!partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  const partner = qPartner.data;

  const enterpriseId = getCurrentEntepriseId(store, activityQuery.data);
  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });

  const sessionQuery = useSession({
    enabled: !!enterpriseId
  });
  useEffect(() => {
    if (sessionQuery.data && !!enterpriseId) {
      if (userTagQuery.isDisabled) {
        userTagQuery.refetch();
      }
    }
  }, [sessionQuery, enterpriseId]);

  const userTagQuery = useUserTag(enterpriseId || "", {
    enabled: !!enterpriseId && !!sessionQuery.data,
    retry: false
  });

  const enterpriseForUserTag = userTagQuery.data
    ? {
        logo: {
          src: userTagQuery.data.member?.profile?.logoUrl
        }
      }
    : undefined;

  const enterprise = enterpriseQuery.data || enterpriseForUserTag || undefined;

  const onFormSubmit = async (data: any) => {
    const request = {
      phone: `+${Number.parseInt(data.countryCode)}${data.phoneNumber}`
    };
    await beginPhoneVerification(request, {
      onSuccess: () => {
        history.push({
          pathname: "/member/verify/phone-number/otp",
          state: {
            phoneNumber: data.phoneNumber
          }
        });
      },
      onError: err => {
        if (err.response?.status === 409) {
          history.push({
            pathname: "/member/verify/phone-number/existing-account",
            state: {
              message: err.response?.data?.error || ""
            }
          });
        }
      }
    });
  };
  const isEdit = queries.edit ? Number(queries.edit) : 0;

  return (
    <OnboardingLayout
      partnerLogo={enterprise?.logo.src || partner?.logo?.src}
      onBack={
        redirectPath
          ? () => {
              history.push(redirectPath);
            }
          : undefined
      }
    >
      <BrowserPageTitle title="Verify Phone number | Wingspan" />

      <WSCentered span={{ m: "6", xl: "4" }}>
        <WSText.Heading5 mb="M">
          {isEdit
            ? "Edit your phone number"
            : (location?.state as any)?.resend
            ? "Resend code"
            : "Verify your account"}
        </WSText.Heading5>
        <WSText color="gray600" mb="XL">
          A 6 digit verification code will be sent via SMS to your{" "}
          {isEdit ? "new " : ""}phone number. Message and Data rates may apply.
        </WSText>
        <WSFormOld
          onSubmit={onFormSubmit}
          defaultValues={{
            phoneNumber: (location.state as any)?.phoneNumber,
            countryCode: getCountryCode(
              locationQuery.data?.isoCode as string
            ).replace(/\D/g, "")
          }}
          validationSchema={Yup.object().shape({
            countryCode: Yup.string().required("Required").min(1, "Required"),
            phoneNumber: Yup.string()
              .when("countryCode", {
                is: "1",
                then: Yup.string().length(
                  10,
                  "Phone number should be 10 characters"
                ),
                otherwise: Yup.string()
                  .min(5, "Phone number should at least 5 characters")
                  .max(15, "Limit is 15 characters")
              })
              .required("Phone number is required")
          })}
        >
          <WSFlexBox wrap="nowrap">
            <WSFormOld.Field
              name="countryCode"
              component={WSInputMaskOld}
              componentProps={{
                className: cn(styles.input, styles.contryCodeInput),
                prefix: "+",
                mask: "9?999",
                slotChar: ""
              }}
              label="Country Code"
            />
            <WSFormOld.Value name="countryCode">
              {countryCode => (
                <WSFormOld.Field
                  className={styles.phoneNumberField}
                  key={!countryCode || countryCode === "1" ? "US" : "INT"}
                  name="phoneNumber"
                  component={WSInputMaskOld}
                  componentProps={{
                    type: "tel",
                    className: styles.input,
                    mask:
                      !countryCode || countryCode === "1"
                        ? "(999) 999-9999"
                        : "(999) 999-9999? x99999",
                    unmask: true,
                    autoClear: false
                  }}
                  label="Phone"
                />
              )}
            </WSFormOld.Value>
          </WSFlexBox>

          <WSErrorMessage
            mt="M"
            error={beginPhoneVerificationMeta.error}
            contextKey={ErrorContextKey.VerifyPhone}
          />

          <WSButtons mt="2XL" forceFullWidth>
            <WSFormOld.SubmitButton
              name="sendCode"
              fullWidth={!(location?.state as any)?.canSkip}
              className={styles.button}
              loading={beginPhoneVerificationMeta.isLoading}
            >
              Send code
            </WSFormOld.SubmitButton>
            {(location?.state as any)?.canSkip && !!user.phone?.number ? (
              <WSButton.Secondary
                ml="M"
                className={styles.button}
                name="skipPhoneVerification"
                fullWidth={!(location?.state as any)?.canSkip}
                onClick={() => {
                  window.sessionStorage.setItem(
                    "SKIP_PHONE_VERIFICATION",
                    "true"
                  );
                  history.push("/member/dashboard");
                }}
              >
                Skip
              </WSButton.Secondary>
            ) : null}
          </WSButtons>
        </WSFormOld>
      </WSCentered>
    </OnboardingLayout>
  );
};
