import { WSButton, WSModalOld, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

export const INTEGRATIONS_QUICKBOOKS_EXTERNAL_NOTICE_MODAL =
  "INTEGRATIONS_QUICKBOOKS_EXTERNAL_NOTICE_MODAL";

export const IntegrationsQuickbooksExternalNoticeModal: React.FC = () => {
  return (
    <WSModalOld
      name={INTEGRATIONS_QUICKBOOKS_EXTERNAL_NOTICE_MODAL}
      title="You are being sent to Intuit's website!"
    >
      {({ url }) => (
        <>
          <WSText mb="3XL">
            In order to authenticate with Quickbooks, we must send you to their
            authentication page. You will be redirected back to Wingspan when
            complete. Thank you!
          </WSText>
          <WSButton.Primary
            mt="XL"
            fullWidth
            data-testid="doOAuth"
            onClick={() => {
              window.location.replace(url);
            }}
          >
            Continue
          </WSButton.Primary>
        </>
      )}
    </WSModalOld>
  );
};
