import {
  useModalOldContext,
  WSDivider,
  WSGrid,
  WSList
} from "@wingspanhq/fe-component-library";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { InternalAccountType } from "../../../services/api/banking/types";
import { CashbackProgramWidget } from "../../PaymentMethods/components/DebitCashbackProgram";
import { PayoutSplits } from "../../PaymentMethods/components/PayoutSplits";
import { WingspanAccounts } from "../../PaymentMethods/components/WingspanAccounts";
import { AvailableBalance } from "../components/AvailableBalance";
import { BankCardsList } from "../components/BankCardsList/BankCardsList";
import { CardholderBenefits } from "../components/CardholderBenefits";
import { CashbackBalance } from "../components/CashbackBalance";
import {
  UPDATE_DEPOSIT_RATE_MODAL,
  UpdateDepositRateModal
} from "../modals/UpdateDepositRate";

export const RouteDashboard: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const { closeModal, openModal } = useModalOldContext();

  return (
    <WSGrid gutter={{ s: "3XL" }}>
      <WSGrid.Item span={{ m: "8" }}>
        <WSGrid mb="4XL">
          <WSGrid.Item
            span={{ m: "12", l: featureFlags.data?.cashback2025 ? "6" : "12" }}
          >
            <AvailableBalance />
          </WSGrid.Item>
          {featureFlags.data?.cashback2025 ? (
            <WSGrid.Item span={{ m: "12", l: "6" }}>
              <CashbackBalance />
            </WSGrid.Item>
          ) : null}
        </WSGrid>
        <BankCardsList />
      </WSGrid.Item>
      <WSGrid.Item span={{ m: "4" }}>
        <WSList gap="2XL">
          {featureFlags.data?.cashback2025 ? <CardholderBenefits /> : null}
          <UpdateDepositRateModal />
          <PayoutSplits
            onEditWallet={() => openModal(UPDATE_DEPOSIT_RATE_MODAL)}
          />
          <WSDivider />
          <WingspanAccounts accountType={InternalAccountType.Banking} />
          {/*<WSButton.Link rightIcon="chevron-right">Search for ATMs</WSButton.Link>*/}
          {featureFlags.data?.cashback2025 ? <CashbackProgramWidget /> : null}
        </WSList>
      </WSGrid.Item>
    </WSGrid>
  );
};
