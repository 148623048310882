import {
  WSAvatar,
  WSBadge,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { ChannelMessageStatus } from "@wingspanhq/notifications/dist/lib/interfaces/channelMessage";
import React from "react";
import { useOnVisible } from "../../../../shared/hooks/useOnVisible";

import classNames from "classnames";
import camelCase from "lodash/camelCase";
import { useGetMemberPublicFile } from "../../../../query/files/queries";
import styles from "./styles.module.scss";

type TodoItemProps = {
  onClick?(): void;
  viewed?: boolean;
  loading?: boolean;
  title: string;
  status: ChannelMessageStatus;
  body: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  buttonText?: string;
  isPending?: boolean;
  onDismiss?(): Promise<any>;
  onRead?(): void;
  buttonAction?(): void;
  buttonActionAsync?(): Promise<void>;
  numberOfTasks?: number;
  company?: {
    name: string;
    logoFileId: string;
  };
};

export const TodoItem: React.FC<TodoItemProps> = ({
  viewed,
  loading,
  body,
  footer,
  header,
  buttonText,
  buttonAction,
  buttonActionAsync,
  status,
  numberOfTasks,
  company,
  onDismiss,
  onRead,
  title,
  onClick,
  isPending
}) => {
  const [isViewed, setIsViewed] = React.useState(viewed || !onRead);
  const [ref] = useOnVisible(() => {
    setIsViewed(true);
    onRead?.();
  }, isViewed);
  const [processing, setProcessing] = React.useState(false);
  const [logoURL, setLogoURL] = React.useState<string>();

  useGetMemberPublicFile(company?.logoFileId || "", {
    enabled: !!company?.logoFileId,
    onSuccess: response => {
      setLogoURL(response.url);
    }
  });

  return (
    <WSPanel
      mb="XL"
      shimmer={loading || processing}
      className={classNames({
        [styles.clickable]: !isPending && (footer || buttonAction),
        [styles.dismissable]: onDismiss
      })}
      onClick={isPending ? undefined : onClick}
      as="label"
      data-testid={`todoItem-${camelCase(title)}`}
    >
      <WSElement ref={ref}>
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
          {header ? (
            header
          ) : (
            <WSFlexBox.CenterY wrap="nowrap">
              <WSText.Heading5 mr="M">{title}</WSText.Heading5>
              {isPending ? (
                <WSPill px="M" text="Pending" />
              ) : !onDismiss &&
                (buttonAction || buttonActionAsync || footer) ? (
                <WSPill px="M" text="Action required" theme="warning" />
              ) : null}
            </WSFlexBox.CenterY>
          )}
          <WSFlexBox.CenterY wrap="nowrap" ml="M">
            {numberOfTasks ? (
              <WSFlexBox.CenterY wrap="nowrap">
                <WSPill
                  text={
                    numberOfTasks >= 50
                      ? `${numberOfTasks}+`
                      : String(numberOfTasks)
                  }
                />
              </WSFlexBox.CenterY>
            ) : company ? (
              <WSFlexBox.CenterY wrap="nowrap">
                {logoURL ? <WSAvatar.Image size="S" image={logoURL} /> : null}
                {company.name ? (
                  <WSText.ParagraphSm ml="XS" pl="XS" color="gray500">
                    {company.name}
                  </WSText.ParagraphSm>
                ) : null}
              </WSFlexBox.CenterY>
            ) : null}
            {onDismiss ? (
              <WSIcon
                block
                onClick={async event => {
                  event.stopPropagation();
                  event.preventDefault();

                  setProcessing(true);
                  await onDismiss();
                  setProcessing(false);
                }}
                size="XS"
                ml="M"
                name="exit"
                color="gray500"
              />
            ) : null}
          </WSFlexBox.CenterY>
        </WSFlexBox.CenterY>
        <WSText.ParagraphSm color="gray500" className={styles.body}>
          {body}
        </WSText.ParagraphSm>
        {isPending ? null : footer ? (
          <WSElement mt="M">{footer}</WSElement>
        ) : buttonText ? (
          <WSElement mt="M">
            <WSButton.Link
              size="S"
              onClick={buttonAction}
              onAsyncClick={buttonActionAsync}
              rightIcon="chevron-right"
            >
              {buttonText}
            </WSButton.Link>
          </WSElement>
        ) : null}
      </WSElement>
    </WSPanel>
  );
};
