import { WSQueryConfig } from "@ws-react-query";
import { getInternalAccountBalance } from "../../../services/api/banking/internalAccounts";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_INTERNAL_ACCOUNT_BALANCE = "QUERY_INTERNAL_ACCOUNT_BALANCE";

export const useQueryInternalAccountBalance = (
  internalAccountId: Parameters<typeof getInternalAccountBalance>[0],
  config?: WSQueryConfig<
    Awaited<ReturnType<typeof getInternalAccountBalance>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_INTERNAL_ACCOUNT_BALANCE, internalAccountId],
    () => getInternalAccountBalance(internalAccountId),
    config
  );
