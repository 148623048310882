import { IWebflowPartnerSignUpTypes } from "@wingspanhq/cms/dist/lib/interfaces/webflow/partners";
import { FiltersOld } from "@wingspanhq/fe-component-library";
import { EnabledPaymentMethod } from "../SignUp/screens/AutopaySetup/SetupPaymentMethod";
import { createWSStore } from "../utils/WSStore";
import { GrowthAttributionDetails } from "../utils/growthAttribution";

export type WSStore = {
  appContext: {
    isEmbedded: boolean;
  };
  bankCardTokens: {
    token?: string;
    ids: { [wsCardId: string]: string };
  };
  captchaScriptIsLoaded: boolean;
  createdWithPPL: boolean;
  discountCode: string;
  growthAttributionDetails?: GrowthAttributionDetails;
  isEmployerSignUp?: boolean;
  is1099SignUp?: boolean;
  isPartnerSignUp: boolean;
  isPaymentsServiceReEnabled?: boolean;
  productActivationAfterOnboarding?: boolean;
  signUpHash?: string;
  signUpMethod?: "Google" | "Email";
  signUpType: IWebflowPartnerSignUpTypes;
  savedFilters: {
    [path: string]: FiltersOld;
  };
  invoiceFormBackPath?: string;
  signUpAutopayDetails?: {
    tag: string;
    companyName: string;
    enabledPaymentMethods?: EnabledPaymentMethod[];
    redirectTo?: string;

    email?: string;
    payeeId: string;
  };
  signUp1099?: {
    companyName: string;
    inviteeEmail: string;
    status: "active" | "pending";
  };
  xWingspanExpansion?: string;
};

export const wsStore = createWSStore<WSStore>(() => ({
  appContext: {
    isEmbedded: process.env.REACT_APP_BUILD_TARGET !== "app"
  },
  createdWithPPL: false,
  signUpType: IWebflowPartnerSignUpTypes.AllInOne,
  discountCode: "",
  captchaScriptIsLoaded: !!window.wingspanReCaptchaOnLoad,
  isPartnerSignUp: false,
  isEmployerSignUp: false,
  is1099SignUp: false,
  bankCardTokens: {
    ids: {}
  },
  savedFilters: {},
  growthAttributionDetails: undefined,
  enabledPayoutMethods: ["instant", "wallet", "ach"],
  signUp1099: (() => {
    try {
      const data = JSON.parse(window.localStorage.getItem("signUp1099") || "");

      if (data.companyName && data.inviteeEmail) {
        return data as WSStore["signUp1099"];
      }
    } catch {}
  })(),
  signUpAutopayDetails: (() => {
    try {
      const data = JSON.parse(
        window.sessionStorage.getItem("signUpAutopayDetails") || ""
      );

      if (data.payeeId && data.companyName) {
        return data as WSStore["signUpAutopayDetails"];
      }
    } catch {}
  })()
}));

export const useWSStore = wsStore.useWSStore;
export const useSetWSStore = wsStore.useSetWSStore;
export const useResetWSStore = wsStore.useResetWSStore;
