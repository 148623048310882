import {
  toWSDateString,
  toWSMoneyString,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { ItemData } from ".";

export const columns: Array<WSTableColumn<ItemData>> = [
  {
    config: {
      header: { text: "Merchant" },
      gridTemplateSizeMax: "0.5fr"
    },
    renderFunction: row => (
      <WSTableCell
        avatar={row.data.avatar}
        text={row.data.name}
        pill={row.data.transaction.business ? undefined : { text: "Ignored" }}
        secondaryTextColor={
          row.data.transaction.business &&
          row.data.transaction.type !== "Transfer"
            ? "gray600"
            : "gray400"
        }
        secondaryText={toWSDateString(
          row.data.transaction.date,
          "monthDayYear"
        )}
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS", "S", "M"],
      header: { text: "Description" },
      gridTemplateSizeMax: "0.5fr"
    },
    renderFunction: row => <WSTableCell text={row.data.description} />
  },
  {
    config: {
      justify: "end",
      header: { text: "Amount" },
      gridTemplateSizeMax: "auto"
    },
    renderFunction: row => (
      <WSTableCell
        text={toWSMoneyString(row.data.transaction.amount * -1, "bookkeeping")}
      />
    )
  }
];
