import { useWSModal, WSLoader } from "@wingspanhq/fe-component-library";
import {
  EmailVerificationStatus,
  PhoneVerificationStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { DataView } from "../../../../../shared/components/DataView";
import { FormAddPassword } from "../components/FormAddPassword";
import { FormChangePassword } from "../components/FormChangePassword";

type Props = {};

export const RouteAccount: React.FC<Props> = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);

  const modalChangePassword = useWSModal(FormChangePassword, {
    title: "Change password",
    size: "S"
  });
  const modalAddPassword = useWSModal(FormAddPassword, {
    title: "Add password",
    size: "S"
  });

  if (queryUser.isInitialLoading || queryMember.isInitialLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <DataView
      title="Account information"
      data={[
        {
          label: {
            text: "Sign-in email",
            action: {
              label: "Edit",
              onClick: () => {
                history.push("/member/verify/email?edit=1");
              }
            },
            actionPosition: "left"
          },
          icon:
            queryUser.data?.emailVerificationState?.status ===
            EmailVerificationStatus.Verified
              ? {
                  name: "check-circle",
                  color: "green400"
                }
              : undefined,
          value: queryUser.data?.email,
          action:
            queryUser.data?.emailVerificationState?.status !==
            EmailVerificationStatus.Verified
              ? {
                  label: "Verify",
                  onClick: () => {
                    history.push({
                      pathname: "/member/verify/email",
                      state: {
                        email: queryUser.data?.email
                      }
                    });
                  }
                }
              : undefined
        },
        {
          label: {
            text: "Phone number",
            action: {
              label: "Edit",
              onClick: () => {
                history.push("/member/verify/phone-number?edit=1");
              }
            },
            actionPosition: "left"
          },
          icon:
            queryUser.data?.phone?.status === PhoneVerificationStatus.Verified
              ? {
                  name: "check-circle",
                  color: "green400"
                }
              : undefined,
          value: queryUser.data?.phone.number,
          action:
            queryUser.data?.phone?.status !== PhoneVerificationStatus.Verified
              ? {
                  label: "Verify",
                  onClick: () => {
                    history.push({
                      pathname: "/member/verify/phone-number",
                      state: {
                        phoneNumber: queryUser.data?.phone.number
                      }
                    });
                  }
                }
              : undefined
        },
        {
          label: {
            text: "Password",
            action: queryUser.data?.isPasswordSet
              ? {
                  label: "Edit",
                  onClick: () => {
                    modalChangePassword.open();
                  }
                }
              : {
                  label: "Add",
                  onClick: () => {
                    modalAddPassword.open();
                  }
                },
            actionPosition: "left"
          },
          value: queryUser.data?.isPasswordSet
            ? "********"
            : "Password is not added yet"
        }
      ]}
    />
  );
};
