import React from "react";

export enum ErrorContextKey {
  BulkBatchImportError = "BulkBatchImportError",
  BulkBatchStartImport = "BulkBatchStartImport",
  InvoiceForm = "InvoiceForm",
  PayableForm = "PayableForm",
  UpdateSeriesInvoice = "UpdateSeriesInvoice",
  CreateClient = "CreateClient",
  UpdateClient = "UpdateClient",
  ArchiveClient = "ArchiveClient",
  RemoveClient = "RemoveClient",
  CreateCollaborator = "CreateCollaborator",
  CreateCustomField = "CreateCustomField",
  UpdateCollaborator = "UpdateCollaborator",
  EditContact = "EditContact",
  PaymentsSetup = "PaymentsSetup",
  BenefitsAddZipCode = "BenefitsAddZipCode",
  ActivateBenefit = "ActivateBenefit",
  BenefitsSetUp = "BenefitsSetUp",
  Auth = "Auth",
  PasswordReset = "PasswordReset",
  CreateInvoiceWithPPL = "CreateInvoiceWithPPL",
  PayInvoice = "PayInvoice",
  PaymentMethodRequired = "PaymentMethodRequired",
  UploadFile = "UploadFile",
  UploadLogo = "UploadLogo",
  CreatePPL = "CreatePPL",
  VerifyPhone = "VerifyPhone",
  VerifyEmail = "VerifyEmail",
  Unauthorized = "Unauthorized",
  CreateOtpSession = "CreateOtpSession",
  CreateAccount = "CreateAccount",
  AddPaymentMethod = "AddPaymentMethod",
  DisputeInvoice = "DisputeInvoice",
  IntegrationsQuickbooksMapping = "IntegrationsQuickbooksMapping",
  PendingWithholding = "PendingWithholding",
  EditCollaboratorIntegrations = "EditCollaboratorIntegrations",
  EditClientIntegrations = "EditClientIntegrations",
  RevealCardDataOPT = "RevealCardDataOPT",
  CardActions = "CardActions",
  CreateCollaboratorsGroup = "CreateCollaboratorsGroup",
  UpdateCollaboratorsGroup = "UpdateCollaboratorsGroup",
  DeleteCollaboratorsGroup = "DeleteCollaboratorsGroup",
  Other = "Other",
  PayoutSettings = "PayoutSettings",
  PayrollSettings = "PayrollSettings",
  EditTemplate = "EditTemplate",
  RemoveEligibilityRequirement = "RemoveEligibilityRequirement",
  BrowsingFiles = "BrowsingFiles",
  DownloadFile = "DownloadFile",
  SettingMemberLogo = "SettingMemberLogo",
  PaymentsVerificationUpgrade = "PaymentsVerificationUpgrade",
  NEC1099Onboarding = "NEC1099Onboarding",
  BulkPayableBatchCreate = "BulkPayableBatchCreate",
  BulkBatchUpdate = "BulkBatchUpdate",
  BankCard = "BankCard",
  NEC1099DetailsUpdate = "NEC1099DetailsUpdate",
  NEC1099BulkCalculate1099 = "NEC1099BulkCalculate1099",
  NEC1099Correction = "NEC1099Correction",
  NEC1099TaxDocuments = "NEC1099TaxDocuments",
  OffCyclePayroll = "OffCyclePayroll",
  Deduction = "Deduction",
  BankAccountVerification = "BankAccountVerification",
  NEC1099AddRecipient = "NEC1099AddRecipient",
  InvoiceRefund = "InvoiceRefund",
  NEC1099TaxDocumentsDownload = "NEC1099TaxDocumentsDownload",
  NEC1099RequestCorrection = "NEC1099RequestCorrection",
  NEC1099PayerCorrection = "NEC1099PayerCorrection",
  NEC1099PayerUpdateCorrection = "NEC1099PayerUpdateCorrection",
  NEC1099PayerRejectCorrection = "NEC1099PayerRejectCorrection",
  NEC1099PayerApproveCorrection = "NEC1099PayerApproveCorrection",
  UploadDocument = "UploadDocument",
  DeleteDocument = "DeleteDocument",
  Wallet = "Wallet",
  UpdateInvoicingConfig = "UpdateInvoicingConfig",
  Engagement = "Engagement",
  MonitoredEntity = "MonitoredEntity",
  CreatePayee = "CreatePayee",
  CreateEmailSnippet = "CreateEmailSnippet",
  OnboardingBusinessProfile = "OnboardingBusinessProfile",
  PayeeOnboardingConsentForms = "PayeeOnboardingConsentForms",
  ElectronicTaxFormConsent = "ElectronicTaxFormConsent",
  FundingMethod = "FundingMethod",
  RenameRequirement = "RenameRequirement",
  AddPayeeToEngagement = "AddPayeeToEngagement",
  InternalAccount = "InternalAccount",
  EditPayerPayeeInternalNotes = "EditPayerPayeeInternalNotes",
  Settings = "Settings",
  TaxFilling = "TaxFilling"
}

export type PlatformErrorRegexp = {
  pattern: string | RegExp;
  message: React.ReactNode;
};

export type PlatformErrorsMapping = {
  [key in ErrorContextKey]: {
    wsErrors: {
      [key: string]:
        | React.ReactNode
        | {
            [key: string]: React.ReactNode;
          };
    };
    errorRegexs?: PlatformErrorRegexp[];
  };
};
