import {
  WSSelectOption,
  WSSelectSearch,
  WSSelectSearchProps
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { Nullable } from "primereact/ts-helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";

type Value = string | null;

type Props = {
  value: Value;
  onChange: (value: Value) => void;
} & Omit<WSSelectSearchProps, "options" | "value" | "onChange">;

export const SelectPayee: React.FC<Props> = ({
  value,
  onChange,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectValue, setSelectValue] =
    useState<Nullable<WSSelectOption>>(null);

  const queryPayeeRows = usePayeeRowsQuery({
    filter: {
      searchString: searchText || undefined
    }
  });

  const queryPayee = usePayeeQuery(value as string, {
    enabled: !!value
  });

  const payee = queryPayee.data;

  useEffect(() => {
    if (!payee || !payee.user) {
      if (value) {
        return;
      }

      setSelectValue(null);
      return;
    }

    const payeeNames = wsName({
      user: payee.user,
      member: payee.member,
      payerOwnedData: payee.payerOwnedData
    });

    const name = payeeNames.getResolvedName();
    const secondaryName = payeeNames.getResolvedSecondaryName();

    setSelectValue({
      value: payee.payeeId,
      label: name,
      labels: [secondaryName].filter(Boolean) as string[],
      avatar: {
        type: "text",
        text: name
      }
    });
  }, [payee, value]);

  const options = useMemo(() => {
    const options: WSSelectOption[] = [];

    (queryPayeeRows.data || []).forEach(payee => {
      if (!payee.user) {
        return;
      }

      const payeeNames = wsName({
        user: payee.user,
        member: payee.member,
        payerOwnedData: payee.payerOwnedData
      });

      const name = payeeNames.getResolvedName();
      const secondaryName = payeeNames.getResolvedSecondaryName();

      options.push({
        value: payee.payeeId,
        label: name,
        labels: [secondaryName].filter(Boolean) as string[],
        avatar: {
          type: "text",
          text: name
        }
      });
    });

    return options;
  }, [queryPayeeRows.data]);

  const onReachMenuEnd = useCallback(() => {
    if (queryPayeeRows.hasNextPage) {
      queryPayeeRows.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayeeRows.hasNextPage]);

  return (
    <WSSelectSearch
      {...otherProps}
      value={selectValue}
      onChange={newOption => {
        if (newOption === null) {
          onChange(null);
          setSelectValue(null);
          return;
        } else if (newOption?.value) {
          onChange(newOption?.value as string);
          setSelectValue(newOption);
        }
      }}
      placeholder="All contractors"
      options={options}
      status={queryPayeeRows.isInitialLoading ? "loading" : otherProps.status}
      externalSearchText={searchText}
      onExternalSearchChange={setSearchText}
      isListLoading={
        (queryPayeeRows.isFetching && !queryPayeeRows.isFetchingNextPage) ||
        queryPayee.isInitialLoading
      }
      onReachMenuEnd={onReachMenuEnd}
    />
  );
};
