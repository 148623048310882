import {
  ITransaction,
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { ITransactionGroup } from "@wingspanhq/bookkeeping/dist/lib/interfaces/insights";
import {
  WSButton,
  WSButtons,
  WSLoader
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import flatten from "lodash/flatten";
import React, { useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import {
  useCatchUpTransactionsExpense,
  useCatchUpTransactionsIncome
} from "../../../query/bookkeeping/queries";
import {
  getTotalCatchUpExpenseTransactions,
  getTotalCatchUpIncomeTransactions
} from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateActivityCatchUpEvents } from "../../../query/users/mutations";
import { useAccounts, useActivities } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { track } from "../../../utils/analytics";
import {
  getCatchUpPagesFromGroups,
  getCatchUpTransactionsFromGroups,
  totalTransactionValues,
  updateTransactions
} from "../../utils";
import { CatchUpLayout } from "./CatchUpLayout";
import { CatchUpTransactionForm } from "./CatchUpTransactionForm";
import styles from "./Index.module.scss";
import { CatchUpTransactionDetails } from "./CatchUpTransaction";

export const CatchUpIncomeEdit: React.FC<{
  next: string;
}> = ({ next }) => {
  const history = useHistory();

  const memberId = useUserId();
  const [updateActivityCatchUpEvents] = useUpdateActivityCatchUpEvents();
  const [updateTransaction] = useUpdateTransaction();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<ITransactionGroup[][]>([]);
  const [oldTransactions, setOldTransactions] = useState<ITransaction[]>([]);
  const [loading, setLoading] = useState(false);

  const qAccounts = useAccounts();
  const qCatchUpTransactionsIncome = useCatchUpTransactionsIncome(
    memberId,
    undefined,
    {
      refetchOnMount: true
    }
  );
  const qCatchUpTransactionsExpense = useCatchUpTransactionsExpense(
    memberId,
    undefined,
    {
      refetchOnMount: true
    }
  );
  const qActivity = useActivities(memberId);

  return (
    <WSQueries
      queries={{
        qAccounts,
        qActivity,
        qCatchUpTransactionsIncome,
        qCatchUpTransactionsExpense
      }}
      onSuccess={({
        qCatchUpTransactionsIncome,
        qCatchUpTransactionsExpense,
        qActivity
      }) => {
        setPages(
          getCatchUpPagesFromGroups(
            qCatchUpTransactionsIncome.data?.sourceIncome,
            qCatchUpTransactionsIncome.data?.accountIncome,
            qActivity.data?.events.incomeReviewedAt
          )
        );

        const transactions = getCatchUpTransactionsFromGroups(
          qCatchUpTransactionsIncome.data?.sourceIncome,
          qCatchUpTransactionsIncome.data?.accountIncome
        );

        setOldTransactions(transactions);

        track("Catch Up Started", {
          type: "incremental",
          unreviewedExpensesCount: getTotalCatchUpExpenseTransactions(
            qCatchUpTransactionsExpense.data,
            qActivity.data
          ),
          unreviewedIncomeCount: getTotalCatchUpIncomeTransactions(
            qCatchUpTransactionsIncome.data,
            qActivity.data
          )
        });
      }}
      renderLoader={() => (
        <ProjectOnboardingLayout progress={0} noBack>
          <WSLoader.Spinner style={{ marginTop: 100 }} />
        </ProjectOnboardingLayout>
      )}
    >
      {({ qAccounts, qCatchUpTransactionsIncome, qActivity }) => {
        const totalAmount = totalTransactionValues(null, ...flatten(pages));

        const groupsToReview = flatten(pages.slice(page + 1, Infinity)).length;
        const nextGroups = pages[page + 1]?.length;

        const totalIncomeTransactions = getTotalCatchUpIncomeTransactions(
          qCatchUpTransactionsIncome.data,
          qActivity.data
        );

        if (totalIncomeTransactions === 0) {
          return <Redirect to={next} />;
        }

        return (
          <CatchUpLayout
            progress={0}
            steps={[
              {
                title: "Add up income",
                name: "1",
                current: true
              },
              {
                title: "Find business expenses",
                name: "2"
              }
            ]}
            onBack={
              page > 0
                ? () => {
                    setPage(page - 1);
                  }
                : undefined
            }
          >
            <Route
              path={"/member/bookkeeping/catch-up/income/:transactionId"}
              component={CatchUpTransactionDetails}
              exact
            />
            <BrowserPageTitle title="Select business income" />
            <p className={styles.subtitle}>
              Total 1099 income: {formatMoney(Math.abs(totalAmount))}
            </p>
            <h1 className={styles.title}>Select business income</h1>

            <CatchUpTransactionForm
              type={TransactionType.Income}
              transactionGroups={pages[page]}
              onChange={transactionGroups => {
                setPages(prev =>
                  prev.map((t, i) => {
                    if (i === page) {
                      return transactionGroups;
                    } else {
                      return t;
                    }
                  })
                );
              }}
              accounts={qAccounts.data}
            />

            <p className={styles.subtitle}>
              {groupsToReview
                ? `${groupsToReview} groups to review`
                : "All groups reviewed"}{" "}
            </p>

            <WSButtons mt="XL" mb="2XL">
              {nextGroups ? (
                <WSButton
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await updateTransactions(
                      pages[page],
                      oldTransactions,
                      TransactionType.Income,
                      updateTransaction
                    );
                    setLoading(false);
                    setPage(prev => prev + 1);
                  }}
                  name="catchUpIncomeNext"
                >
                  Continue to next {pages[page + 1]?.length}
                </WSButton>
              ) : (
                <WSButton
                  loading={loading}
                  name="catchUpIncomeFinish"
                  onClick={async () => {
                    setLoading(true);
                    await updateTransactions(
                      pages[page],
                      oldTransactions,
                      TransactionType.Income,
                      updateTransaction,
                      WSCategory.Income
                    );

                    await updateActivityCatchUpEvents({
                      userId: memberId,
                      events: {
                        incomeReviewedAt: new Date()
                      }
                    });

                    setLoading(false);

                    history.push(next);
                  }}
                >
                  Finish income review
                </WSButton>
              )}
            </WSButtons>
          </CatchUpLayout>
        );
      }}
    </WSQueries>
  );
};
