import {
  toWSDateString,
  WSAvatarProps
} from "@wingspanhq/fe-component-library";
import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { CATEGORY_ICONS } from "../../../../Bookkeeping/utils";
import { components } from "../../../../services/api/banking/types";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";

export function getAvatar(
  transaction: components["schemas"]["Transaction"]
): WSAvatarProps {
  if (!transaction.business) {
    return {
      type: "icon",
      icon: "block"
    };
  }

  if (transaction.name?.toLowerCase().includes("wingspan")) {
    return {
      type: "wingspan"
    };
  }

  if (transaction.type === "Transfer") {
    return {
      type: "icon",
      icon: "transfer"
    };
  }

  if (transaction.type === "Income") {
    return {
      type: "icon",
      icon: "dollar-circle"
    };
  }

  const wsCategory = transaction.wsCategory;
  const icon = wsCategory ? CATEGORY_ICONS[wsCategory] : "info-circle";

  return {
    type: "icon",
    icon: icon || "info-circle"
  };
}

export function getName(transaction: components["schemas"]["Transaction"]) {
  return (
    transaction.labels.overrideName ||
    transaction.merchantName ||
    transaction.name ||
    "Unknown"
  );
}

export function getDescription(
  transaction: components["schemas"]["Transaction"]
) {
  return transaction.wsCategory
    ? `${transaction.wsCategory}${
        transaction.labels.subcategory
          ? ` - ${transaction.labels.subcategory}`
          : ""
      }`
    : transaction.type;
}

export function getGroupName(
  transaction: components["schemas"]["Transaction"]
) {
  const year = new Date(transaction.date).getFullYear();
  const month = toWSDateString(transaction.date, "month");

  return `${month} ${year}`;
}
