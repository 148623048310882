import {
  WSModalOld,
  useModalOldContext
} from "@wingspanhq/fe-component-library";
import React from "react";
import { FormContainer } from "./FormContainer";

export const MODAL_PAYOUT_ACCOUNTS = "MODAL_PAYOUT_ACCOUNTS";

export type ModalPayoutAccountsProps = {
  onSuccess?: () => void;
};

export const ModalPayoutAccounts: React.FC = () => {
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld
      name={MODAL_PAYOUT_ACCOUNTS}
      size="S"
      title="Where should we pay you?"
    >
      {(props?: ModalPayoutAccountsProps) => (
        <FormContainer
          onSuccess={() => {
            closeModal(MODAL_PAYOUT_ACCOUNTS);
            props?.onSuccess?.();
          }}
        />
      )}
    </WSModalOld>
  );
};
