import {
  useModalOldContext,
  WSButton,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../query/hooks/helpers";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import { getAccountIsPaused } from "../../query/subscriptions/selectors";

export const AccountIsPausedModal: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const subscriptionQuery = useSubscriptionV3Query(userId);
  const accountIsPaused = getAccountIsPaused(subscriptionQuery.data);
  const { openModal, closeModal } = useModalOldContext();

  useEffect(() => {
    const handler = (event: any) => {
      if (
        accountIsPaused &&
        (event?.target?.dataset.blocked ||
          event?.target?.closest('[data-blocked="true"]'))
      ) {
        openModal("AccountIsPausedModal");
      }
    };

    if (accountIsPaused) {
      window.document.addEventListener("click", handler, true);
    }

    return () => {
      window.document.removeEventListener("click", handler, true);
    };
  }, [accountIsPaused]);

  return (
    <WSModalOld
      name="AccountIsPausedModal"
      size="S"
      title="Your free trial has expired"
    >
      <WSText mt="M">
        We're excited to help you grow your freelance business! Add your payment
        details today, and for as little as $20 a month we'll help you get paid
        faster, manage your taxes, and stay on top of your finances.
      </WSText>
      <WSText mt="M">All backed by our 3-month money-back guarantee.</WSText>
      <WSButton.Primary
        mt="XL"
        fullWidth
        onClick={() => {
          history.push("/member/settings/membership/your-plan?editPm=1");
          closeModal("AccountIsPausedModal");
        }}
      >
        Add payment method
      </WSButton.Primary>
    </WSModalOld>
  );
};
