import { WSModalOld, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

export const TAXES_MODAL_TAX_SUMMARY_SHEET = "TAXES_MODAL_TAX_SUMMARY_SHEET";

export const TaxesModalTaxSummarySheet: React.FC = () => (
  <WSModalOld
    name={TAXES_MODAL_TAX_SUMMARY_SHEET}
    size="S"
    title="Tax Summary Sheet"
  >
    <WSText weight="medium" mb="M">
      What’s included?
    </WSText>
    <WSText mb="M">
      Wingspan's Tax Summary sheet includes:
      <br />
      - A Schedule-C Tax Summary
      <br />
      - All of your Income & Expense transactions
      <br />- Profit & Loss statements
    </WSText>
    <WSText weight="medium" mb="M">
      How do I use it?
    </WSText>
    <WSText mb="M">
      You can share this sheet with your accountant to file your taxes, or file
      yourself with a tool like TurboTax.
    </WSText>
  </WSModalOld>
);
