import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openIntercom } from "../../../shared/utils/intercom";

export const BOOKKEEPING_NO_ACCOUNTS_CONNECTED =
  "BOOKKEEPING_NO_ACCOUNTS_CONNECTED";

export const NoAccountsConnected: React.FC = () => {
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld name={BOOKKEEPING_NO_ACCOUNTS_CONNECTED} size="XS">
      <WSText.Heading5 mb="M">
        <WSIcon block name="alert-circle" size="M" mr="M" color="red500" />
        No accounts connected
      </WSText.Heading5>
      <WSText mb="M">
        We're looking into what happened. If this persists, please{" "}
        <WSButton.Link onClick={openIntercom}>
          contact our support
        </WSButton.Link>{" "}
        and our team will be happy to help you
      </WSText>
      <WSButtons mt="2XL" format="modal">
        <WSButton.Primary
          data-testid="tryOtherImportMethod"
          onClick={() => {
            closeModal(BOOKKEEPING_NO_ACCOUNTS_CONNECTED);
          }}
        >
          Try other import method
        </WSButton.Primary>
      </WSButtons>
    </WSModalOld>
  );
};
