import {
  IPayeeCreateRequest,
  IPayeeResponse
} from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { createPayee } from "../../../services/payees";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";

export const useCreatePayee = (
  config?: WSMutationConfig<IPayeeResponse, WSServiceError>
) =>
  useWSMutation((request: IPayeeCreateRequest) => createPayee(request), {
    ...config,
    dependencies: [QUERY_PAYEE_ROWS_SUMMARY, QUERY_PAYEE_ROWS]
  });
