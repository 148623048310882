import {
  WSButton,
  WSCard,
  WSCopyButton,
  WSFlexBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { addNotification } from "../../components/Notification/Notification";
import { getPayerName } from "../../modules/Payers/utils/getPayerNames";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import { useDownloadInvoicePdf } from "../../query/payments/mutations";
import { useInvoiceQuery } from "../../query/payments/queries";
import { useMemberProfile } from "../../query/users/queries";
import { Layout } from "../../shared/components/Layout";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";
import { openIntercom } from "../../shared/utils/intercom";
import { AlertEnableTaxWithholdings } from "../components/AlertEnableTaxWithholdings";
import { FirstInvoiceBanner } from "../components/FirstInvoiceBanner/FirstInvoiceBanner";
import {
  InvoiceStatusTimeline,
  InvoiceTimelineStatus
} from "../components/InvoiceStatusTimeline/InvoiceStatusTimeline";
import { InvoiceTotals } from "../components/InvoiceTotals/InvoiceTotals";
import { useInvoiceFormGoBack } from "../utils/invoicesFormUtils";

export const RouteInvoiceConfirmation: React.FC<
  RouteComponentProps<{
    invoiceId: string;
  }>
> = ({
  match: {
    params: { invoiceId }
  }
}) => {
  const history = useHistory();
  const userId = useUserId();
  const queryInvoice = useInvoiceQuery(invoiceId);
  const queryPayer = usePayerQuery(queryInvoice.data?.clientId!, {
    enabled: !!queryInvoice.data?.clientId
  });
  const queryMember = useMemberProfile(userId);
  const shouldPauseAccount = useShouldPauseAccount("taxes");
  const [downloadInvoicePdf, downloadInvoicePdfMeta] = useDownloadInvoicePdf();
  const goBack = useInvoiceFormGoBack();

  return (
    <Layout
      onClose={goBack}
      title={
        queryInvoice.data
          ? `Invoice #${queryInvoice.data?.invoiceNumber}`
          : "Invoice"
      }
    >
      <WSQueries
        queries={{
          queryInvoice,
          queryPayer,
          queryMember
        }}
      >
        {({
          queryInvoice: { data: invoice },
          queryPayer: { data: payer },
          queryMember: { data: member }
        }) => {
          const taxWithholdingsEnabled =
            member.profile.withholdings?.tax?.status ===
            MemberWithholdingStatus.Active;

          const payerName = getPayerName(payer);

          return (
            <WSList gap="XL">
              <FirstInvoiceBanner />

              <WSCard>
                <WSFlexBox justify="space-between" wrap="nowrap">
                  <WSText.Heading4 mb="M">{payerName}</WSText.Heading4>
                  <WSButton.Link
                    onClick={() => {
                      downloadInvoicePdf({
                        invoiceId: invoice.invoiceId
                      });
                    }}
                    loading={downloadInvoicePdfMeta.isLoading}
                  >
                    Download PDF
                  </WSButton.Link>
                </WSFlexBox>

                <WSText.Heading5 mb="XS">
                  {invoice.status === InvoiceStatus.Pending
                    ? "Invoice is pending"
                    : invoice.notificationPreferences?.sendInvoice
                    ? "Your invoice has been sent"
                    : "Your invoice has been opened"}
                </WSText.Heading5>
                <WSText color="gray500" mb="XL">
                  <WSText>
                    {invoice.notificationPreferences?.sendInvoice
                      ? invoice.status === InvoiceStatus.Pending
                        ? `Will be sent to ${payer.user?.email}`
                        : `Sent to ${payer.user?.email}`
                      : "Use your invoice link to send your payment requests directly to clients through your existing communication channel."}
                  </WSText>
                  {invoice.status === InvoiceStatus.Pending && (
                    <WSButton.Link my="M" onClick={openIntercom}>
                      Ask about pending invoices
                    </WSButton.Link>
                  )}
                </WSText>
                <InvoiceStatusTimeline
                  currentStatus={
                    invoice.status === InvoiceStatus.Pending
                      ? undefined
                      : "Sent"
                  }
                  statuses={[
                    InvoiceTimelineStatus.Sent,
                    InvoiceTimelineStatus.Viewed,
                    InvoiceTimelineStatus.Paid,
                    InvoiceTimelineStatus.Deposited
                  ]}
                  mb="XL"
                />
                <WSCopyButton
                  fullWidth
                  mr="M"
                  name="copyInvoiceLink"
                  onCopy={() => {
                    addNotification({
                      text: "Invoice link copied to clipboard."
                    });
                  }}
                  data={invoice.attachments?.invoiceLink as string}
                  kind="Secondary"
                  isURL
                  copyLabel="Copy invoice link"
                  shareLabel="Share invoice link"
                  shareTitle="Invoice link"
                  {...{
                    "data-test-copy-text": invoice.attachments?.invoiceLink
                  }}
                />
              </WSCard>

              <WSCard>
                <WSText.Heading5 mb="M" data-testid="paymentTimingText">
                  Payment timing
                </WSText.Heading5>
                <WSText color="gray600">
                  Funds are automatically deposited within 7 business days of
                  payment.
                </WSText>
              </WSCard>

              <WSCard>
                <WSList gap="2XL">
                  <WSText.Heading5>Summary</WSText.Heading5>

                  <InvoiceTotals invoiceId={invoiceId} />

                  {!shouldPauseAccount && !taxWithholdingsEnabled && (
                    <AlertEnableTaxWithholdings />
                  )}

                  <WSButton
                    onClick={() => {
                      history.push("/member/invoices");
                    }}
                    fullWidth
                  >
                    Back to invoices
                  </WSButton>
                </WSList>
              </WSCard>
            </WSList>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
