import {
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import {
  getPayerName,
  getPayerSecondaryName
} from "../../modules/Payers/utils/getPayerNames";
import { WSQueries } from "../../query/WSQuery";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import { useInvoiceQuery } from "../../query/payments/queries";
import { calculateLineItemsTotal } from "../utils";
import { InvoiceRecentUpdate } from "./InvoicesTable/InvoiceRecentUpdate";
import { buildInvoiceIcon } from "./InvoicesTable/buildInvoiceIcon";

type Props = { parentInvoiceId: string };

export const PayableParentInvoice: React.FC<Props> = props => {
  const history = useHistory();

  const queryInvoice = useInvoiceQuery(props.parentInvoiceId);
  const queryPayer = usePayerQuery(queryInvoice.data?.clientId!, {
    enabled: !!queryInvoice.data?.clientId
  });

  return (
    <WSElement>
      <WSText.Heading5 mb="XL">Parent invoice</WSText.Heading5>
      <WSQueries
        queries={{
          queryInvoice,
          queryPayer
        }}
      >
        {({ queryInvoice: { data: invoice }, queryPayer: { data: payer } }) => {
          const payerName = getPayerName(payer);
          const payerSecondaryName = getPayerSecondaryName(payer);

          return (
            <WSFlexBox
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
              onClick={() => {
                history.push("/member/invoices/" + props.parentInvoiceId);
              }}
            >
              <WSFlexBox wrap="nowrap" alignItems="center">
                <WSAvatar.Icon {...buildInvoiceIcon(invoice)} mr="M" />
                <WSElement>
                  <WSText color="gray600">{payerName}</WSText>
                  <WSText.ParagraphXs color="gray500">
                    {payerSecondaryName}
                  </WSText.ParagraphXs>
                </WSElement>
              </WSFlexBox>

              <WSFlexBox direction="column" alignItems="flex-end">
                <WSText mb="XS" formatMoney color="gray600">
                  {calculateLineItemsTotal(invoice.lineItems)}
                </WSText>
                <InvoiceRecentUpdate invoice={invoice} />
              </WSFlexBox>
            </WSFlexBox>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
