import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ErrorTransform,
  requestFactory,
  WSServiceError
} from "../utils/serviceHelper";
import { maskSensitiveData } from "./maskSensetiveData";
import { isValidJSON } from "./isValidJSON";
import { IS_PRODUCTION_ENV } from "../shared/constants/environment";

/**
 *
 * WSService wraps up our axios instances and tacks on some nice lil extras!
 */

export class WSService {
  serviceRoot = "";
  errorTransforms: ErrorTransform[] = [];
  requestFactory: AxiosInstance;

  constructor(serviceRoot: string, errorTransforms?: ErrorTransform[]) {
    this.serviceRoot = serviceRoot;
    if (errorTransforms && errorTransforms.length > 0) {
      this.errorTransforms.push(...errorTransforms);
    }
    this.requestFactory = requestFactory(
      this.serviceRoot,
      this.errorTransforms
    );
  }

  private handleError(err: WSServiceError): Promise<AxiosResponse<any>> {
    try {
      const request: XMLHttpRequest = err.request;

      let requestData = undefined;
      if (isValidJSON(err?.config?.data)) {
        requestData = maskSensitiveData(err?.config?.data);
      }

      let errorMessage = [
        "WS ERROR INFO:",
        `Timestamp: ${new Date().toString()}`,
        `Request Method: ${err?.config?.method?.toUpperCase()}`,
        `Request URL: ${
          request?.responseURL || `${err.config?.baseURL}${err.config?.url}`
        }`,
        requestData ? `Request Data: ${requestData}` : null,
        `Error code: ${err.code}`,
        `Status Code: ${request?.status}`,
        `Response Text: ${request?.responseText}`
      ]
        .filter(Boolean)
        .join("\n");

      if (IS_PRODUCTION_ENV) {
        // Added extra logging about the error for FullStory
        // window.FS?.log?.({ level: "info", msg: errorMessage });
      } else {
        console.warn(errorMessage);
      }
    } catch (e) {}

    throw err;
  }

  async get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.requestFactory.get(url, config).catch(this.handleError);
  }

  async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    // Looks like our OpenAPI validator middleware is now squashing any POST requests that have empty bodies.
    // Unfortunately that pattern is common in our app.
    // Make sure if we're calling a POST with an empty body, we instead include an empty JSON body {}
    const requestData = data || {};

    return this.requestFactory
      .post(url, requestData, config)
      .catch(this.handleError);
  }

  async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.requestFactory.patch(url, data, config).catch(this.handleError);
  }

  async delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.requestFactory.delete(url, config).catch(this.handleError);
  }
}
