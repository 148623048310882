import {
  useWSModal,
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICheckbookCard,
  IPaymentCard,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib";
import React from "react";
import { WSQueryCache } from "@ws-react-query";
import { PaymentCard } from "../../../../ClientPayments/components/PaymentCard";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYOUT_SETTINGS_DEBIT_CARDS
} from "../../../../query/payments/keys";
import { QUERY_USERS_ACCOUNTS } from "../../../../query/users/keys";
import { paymentsService } from "../../../../services/payments";
import { usersService } from "../../../../services/users";
import { MethodWallet } from "../../../../shared/components/MethodWallet";
import { BadgePayoutPreference } from "../BadgePayoutPreference";
import { BankAccount } from "../BankAccount";
import { DebitCard } from "../DebitCard";
import { DefaultButton } from "./DefaultButton";
import { DeleteBanner } from "./DeleteBanner";
import { IInternalAccount } from "../../../../services/api/banking/types";

type Props = {
  account?: IAccount;
  debitCard?: ICheckbookCard;
  paymentCard?: IPaymentCard;
  internalAccount?: IInternalAccount;
  type: PayoutPreferences;
  isDefault?: boolean;
} & WSElementProps;

export const PayoutMethod: React.FC<Props> = ({
  account,
  debitCard,
  paymentCard,
  internalAccount,
  isDefault,
  type,
  ...elementProps
}) => {
  const userId = useUserId();
  const [setDefault, setDefaultMeta] = useWSMutation(
    async () => {
      const destination = account
        ? {
            destinationType: PayoutDestinationType.Account,
            destinationId: account.accountId
          }
        : debitCard
        ? {
            destinationType: PayoutDestinationType.Card,
            destinationId: debitCard?.cardId
          }
        : paymentCard
        ? {
            destinationType: PayoutDestinationType.Card,
            destinationId: paymentCard.paymentCardId
          }
        : null;

      if (type === PayoutPreferences.Standard) {
        return await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard,
          standard: destination
        });
      } else if (type === PayoutPreferences.Instant) {
        return await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Instant,
          instant: destination
        });
      }
    },
    {
      onSuccess: updatedPayoutSettings => {
        if (updatedPayoutSettings) {
          WSQueryCache.setQueryData(
            QUERY_PAYOUT_SETTINGS,
            updatedPayoutSettings
          );
          WSQueryCache.invalidateQueries([QUERY_PAYOUT_SETTINGS]);
        }
      }
    }
  );
  const removeModal = useWSModal(ModalRemovePayoutMethod);

  return (
    <WSElement {...elementProps}>
      <WSPanel p="S">
        <WSFlexBox wrap="nowrap" justify="space-between" alignItems="center">
          <WSElement>
            {account ? (
              <BankAccount account={account} />
            ) : debitCard ? (
              <DebitCard debitCard={debitCard} />
            ) : paymentCard ? (
              <PaymentCard paymentCard={paymentCard} />
            ) : internalAccount ? (
              <MethodWallet />
            ) : null}
          </WSElement>

          <WSFlexBox wrap="nowrap" alignItems="center">
            {account ? (
              <WSIcon
                block
                name="trash"
                color="gray400"
                mr="2XL"
                onClick={() => {
                  removeModal.open({ type, account });
                }}
              />
            ) : debitCard ? (
              <WSIcon
                block
                name="trash"
                color="gray400"
                mr="2XL"
                onClick={() => {
                  removeModal.open({ type, debitCard });
                }}
              />
            ) : paymentCard ? (
              <WSIcon
                block
                name="trash"
                color="gray400"
                mr="2XL"
                onClick={() => {
                  removeModal.open({ type, paymentCard });
                }}
              />
            ) : null}

            <DefaultButton
              isDefault={isDefault}
              onSetDefault={setDefault}
              isLoading={setDefaultMeta.isLoading}
            />
          </WSFlexBox>
        </WSFlexBox>
      </WSPanel>
    </WSElement>
  );
};

const ModalRemovePayoutMethod: React.FC<{
  type: PayoutPreferences;
  account?: IAccount;
  debitCard?: ICheckbookCard;
  paymentCard?: IPaymentCard;
  onClose: () => void;
}> = ({ type, account, debitCard, paymentCard, onClose }) => {
  const userId = useUserId();
  const [remove, removeMeta] = useWSMutation(
    async () => {
      if (account) {
        await usersService.account.delete(account.accountId);
      } else if (debitCard) {
        await paymentsService.payoutSettings.debitCard.delete(
          userId,
          debitCard.cardId
        );
      } else if (paymentCard) {
        await paymentsService.paymentCard.delete(paymentCard.paymentCardId);
      }
    },
    {
      dependencies: [
        QUERY_USERS_ACCOUNTS,
        QUERY_PAYOUT_SETTINGS_DEBIT_CARDS,
        QUERY_PAYOUT_SETTINGS
      ],
      onSuccess: onClose
    }
  );

  return (
    <>
      <WSText.Heading5 mb="M">Remove payout method</WSText.Heading5>
      <WSText color="gray400">
        Are you sure you want to remove this payout method?
      </WSText>

      <WSPanel my="2XL">
        <WSFlexBox wrap="nowrap" justify="space-between" alignItems="center">
          {account ? (
            <BankAccount account={account} />
          ) : debitCard ? (
            <DebitCard debitCard={debitCard} />
          ) : paymentCard ? (
            <PaymentCard paymentCard={paymentCard} />
          ) : null}
          <BadgePayoutPreference payoutPreference={type} />
        </WSFlexBox>
      </WSPanel>

      <DeleteBanner account={account} debitCard={debitCard} />

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton destructive onClick={remove} loading={removeMeta.isLoading}>
          Remove payout method
        </WSButton>
        <WSButton onClick={onClose}>Cancel</WSButton>
      </WSButtons>
    </>
  );
};
