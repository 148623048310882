import {
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableRowConfig
} from "../../types";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { selectorIsRequirement } from "../../selectors/selectorIsRequirement";
import { usePayerSharedFileRequirementConfig } from "./cells/payer/usePayerSharedFileRequirementConfig";
import { usePayeeSharedFileRequirementConfig } from "./cells/payee/usePayeeSharedFileRequirementConfig";
import { useMemo } from "react";
import { concurrentActions } from "../../../../utils/serviceHelper";
import { WSQueryCache } from "@ws-react-query";
import { ISharedFileRequestResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { QUERY_SHARED_FILE } from "../../../../query/sharedFile/keys";
import { getSharedFile } from "../../../../services/sharedFile/getSharedFile";

export const useSharedFileRequirementTableConfig = (
  callback: RequirementTableCallback
): RequirementTableRowConfig[RequirementType.SharedFile] => {
  const payerSharedFileRequirementConfig =
    usePayerSharedFileRequirementConfig(callback);
  const payeeSharedFileRequirementConfig =
    usePayeeSharedFileRequirementConfig(callback);

  const cells = useMemo(() => {
    const allCells: Array<
      keyof Omit<
        RequirementTableRowConfig[RequirementType.SharedFile],
        "fetchContext"
      >
    > = ["nameCell", "typeCell", "statusCell", "rowActions", "rowMenuActions"];

    return allCells.reduce((acc, key) => {
      acc[key] = {
        [RequirementContextType.Payer]: (requirement, context) => {
          return payerSharedFileRequirementConfig[key](
            requirement,
            context
          ) as any;
        },
        [RequirementContextType.Payee]: (requirement, context) => {
          return payeeSharedFileRequirementConfig[key](
            requirement,
            context
          ) as any;
        }
      };
      return acc;
    }, {} as RequirementTableRowConfig[RequirementType.SharedFile]);
  }, []);

  return {
    ...cells,
    async fetchContext(requirements) {
      const actions = requirements
        .filter(selectorIsRequirement)
        .filter(
          requirement =>
            requirement.requirementType === RequirementType.SharedFile &&
            requirement.dataSourceId
        )
        .map(requirement => async () => {
          try {
            let sharedFile =
              WSQueryCache.getQueryData<ISharedFileRequestResponse>([
                QUERY_SHARED_FILE,
                requirement.dataSourceId
              ]);

            if (!sharedFile) {
              sharedFile = await getSharedFile(requirement.dataSourceId);
              WSQueryCache.setQueryData(
                [QUERY_SHARED_FILE, requirement.dataSourceId],
                sharedFile
              );
            }

            return sharedFile;
          } catch (e) {}
        });

      let sharedFiles = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return sharedFiles.filter(
        sharedFile => sharedFile
      ) as ISharedFileRequestResponse[];
    }
  };
};
