import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateClient } from "../../../query/users/mutations";
import { useClientQuery } from "../../../query/users/queries";
import { removeElementFromArray } from "../../../utils/serviceHelper";
import { CreditCardPreview } from "../CreditCardPreview/CreditCardPreview";

export const REMOVE_CREDIT_CARD_MODAL_KEY = "removeCreditCard";

export const RemoveCreditCardModal: React.FC = () => {
  const userId = useUserId();
  const clientQuery = useClientQuery(userId);
  const [updateClient, updateClientMeta] = useUpdateClient(userId);
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld
      name={REMOVE_CREDIT_CARD_MODAL_KEY}
      size="S"
      title="Remove card"
    >
      {({ paymentMethod, index }) => (
        <>
          <WSText mb="XL">Are you sure you want to remove this card?</WSText>
          <CreditCardPreview paymentMethod={paymentMethod} mb="XL" />

          <WSButtons format="modal">
            <WSButton
              destructive
              onClick={() => {
                updateClient(
                  {
                    clientId: userId,
                    profile: {
                      savedPaymentMethods: removeElementFromArray(
                        clientQuery.data?.profile.savedPaymentMethods?.length ||
                          0,
                        index
                      )
                    }
                  },
                  {
                    onSuccess: () => {
                      closeModal(REMOVE_CREDIT_CARD_MODAL_KEY);
                    }
                  }
                );
              }}
              loading={updateClientMeta.isLoading}
            >
              Remove
            </WSButton>
            <WSButton.Tertiary
              onClick={() => {
                closeModal(REMOVE_CREDIT_CARD_MODAL_KEY);
              }}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
