import {
  toWSDateString,
  useWSSnackbar,
  WSButton,
  WSCard,
  WSEmptyState,
  WSInfoBox,
  WSList,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useCashbackDetailsModal } from "../modals/useCashbackDetailsModal";
import { useCashbackPromotionQuery } from "../../../query/cards/queries/useCashbackPromotionQuery";
import { ICashbackPromotionStatusResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { useRedeemCashbackPromotion } from "../../../query/bookkeeping/mutations";
import { formatNumber } from "accounting";
import { getDaysBetween } from "../../../utils/dates";

const getPercent = (promotion?: ICashbackPromotionStatusResponse) => {
  const currentSpend = promotion?.oneTimeCardSpend?.currentSpend || 0;
  const spendTarget = promotion?.oneTimeCardSpend?.spendTarget || 1;

  return Math.floor((currentSpend * 100) / spendTarget);
};

export const CardholderBenefits: React.FC = () => {
  const cashbackDetailsModal = useCashbackDetailsModal();
  const [redeemCashbackPromotion] = useRedeemCashbackPromotion();
  const { openSnackbar } = useWSSnackbar();

  const queryCashbackPromotion = useCashbackPromotionQuery();

  const percent = getPercent(queryCashbackPromotion.data);
  const endDate = queryCashbackPromotion.data?.endDate;
  const oneTimeCardSpend =
    queryCashbackPromotion.data?.oneTimeCardSpend?.spendTarget || 1000;

  const promotionStatus = queryCashbackPromotion.data?.status;
  const rewardStatus = queryCashbackPromotion.data?.rewardInformation?.status;

  const isRewardRedeemed =
    rewardStatus === "Issued" || rewardStatus === "Pending";

  const isPromotionActive = promotionStatus === "Active";
  const isPromotionComplete = promotionStatus === "Complete";
  const isPromotionExpired = promotionStatus === "Expired" && !isRewardRedeemed;

  const isRedeemable =
    isPromotionComplete && endDate && endDate < new Date() && !isRewardRedeemed;

  const waitForRedeem =
    isPromotionComplete && endDate && endDate > new Date() && !isRewardRedeemed;

  const daysLeft = getDaysBetween(new Date(), endDate || new Date());

  return (
    <WSList gap="L">
      <WSText.Paragraph weight="medium">Cardholder benefits</WSText.Paragraph>

      {isPromotionActive || isPromotionComplete ? (
        <WSCard
          header={{
            value: {
              pill: {
                text: "New",
                theme: "success",
                badge: true
              }
            },
            label: {
              text: "Bonus reward"
            }
          }}
          divider
          actions={{
            alignment: "left",
            sortButtons: false,
            buttons: [
              {
                label: "Details",
                kind: "Link",
                rightIcon: "chevron-right",
                onClick: () => {
                  cashbackDetailsModal.open();
                }
              }
            ]
          }}
        >
          <WSList gap="M">
            <WSText.Paragraph weight="medium" color="gray500">
              Earn a $50 gift card
            </WSText.Paragraph>
            {isPromotionComplete ? (
              <>
                <WSEmptyState
                  size="S"
                  orientation="horizontal"
                  type="done"
                  title="Congrats!"
                  description="You met the spend goal for this bonus reward!"
                />
                {isRedeemable ? (
                  <WSButton.Secondary
                    label="Redeem"
                    onAsyncClick={async () => {
                      await redeemCashbackPromotion(
                        {},
                        {
                          onSuccess() {
                            openSnackbar({
                              type: "success",
                              message:
                                "The gift card successfully sent! Check your email."
                            });
                          },
                          onError() {
                            openSnackbar({
                              type: "warning",
                              message: "Failed to send the gift card!"
                            });
                          }
                        }
                      );
                    }}
                  />
                ) : waitForRedeem ? (
                  <WSInfoBox>
                    Your gift card can be redeemed after the monthly cycle
                    closes (~{daysLeft} days). Check back soon.
                  </WSInfoBox>
                ) : null}
              </>
            ) : (
              <>
                <WSText.ParagraphSm color="gray500">
                  when you spend ${formatNumber(oneTimeCardSpend)} by{" "}
                  {endDate
                    ? toWSDateString(endDate, "monthDate")
                    : "the end of the cycle"}
                  .
                </WSText.ParagraphSm>
                <WSProgressBar
                  noAnimation={false}
                  percent={percent}
                  text={null}
                  mt="L"
                />
                <WSInfoBox>
                  {percent}% of the way to your bonus reward
                </WSInfoBox>
              </>
            )}
          </WSList>
        </WSCard>
      ) : isPromotionExpired ? (
        <WSCard
          header={{
            value: {
              pill: {
                text: "Expired",
                theme: "neutral",
                badge: true
              }
            },
            label: {
              text: "Bonus reward"
            }
          }}
          divider
        >
          <WSList gap="M">
            <WSInfoBox>
              The spend goal for this bonus reward was not met and the offer has
              expired. Keep an eye out for future offers to be announced on your
              Wallet page.
            </WSInfoBox>
          </WSList>
        </WSCard>
      ) : null}

      <WSCard
        header={{
          label: {
            text: "Cashback"
          }
        }}
        divider
        actions={{
          alignment: "fill",
          sortButtons: false,
          buttons: [
            {
              label: "Details",
              kind: "Link",
              rightIcon: "chevron-right",
              onClick: () => {
                cashbackDetailsModal.open();
              }
            }
          ]
        }}
      >
        <WSList gap="M">
          <WSText.Paragraph weight="medium" color="gray500">
            Earn 1% cashback
          </WSText.Paragraph>
          <WSText.ParagraphSm color="gray500">
            on eligible purchases every month—keep your average daily balance
            above $100.
          </WSText.ParagraphSm>
        </WSList>
      </WSCard>
    </WSList>
  );
};
