import {
  WSActionsButton,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IMemberFileResponse,
  WSFileAccessScope
} from "@wingspanhq/files/dist/lib/interfaces";
import { ITaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { useDownloadFile } from "../../../query/files/mutations";
import { selectorT4ADocument } from "../selectors/selectorT4ADocument";
import { selectorPayeeTaxFormPayerName } from "../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import { selectorPayeeTaxFormSupportContacts } from "../selectors/selectorPayeeTaxFormSupportContacts";
import { TaxFormCardView } from "./TaxFormCardView";

type Props = {
  taxForm: ITaxFormResponse;
  files: IMemberFileResponse[];
  year: number;
};

type FileTableItem = {
  fileId: string;
  filename: string;
  name: string;
  createdAt: Date;
};

export const TaxFormT4ACard: React.FC<Props> = props => {
  const payerName = selectorPayeeTaxFormPayerName(props.taxForm);
  const { supportPhone, supportEmail } = selectorPayeeTaxFormSupportContacts(
    props.taxForm
  );

  const files = useMemo(
    () =>
      props.files.filter(file =>
        selectorT4ADocument(file, props.taxForm.clientId, props.year)
      ),
    [props.files, props.taxForm.clientId, props.year]
  );

  const [downloadFile, meta] = useDownloadFile();

  const filesTableData = files
    .map(file => {
      let name = "Original form";

      if (file.filename.toLowerCase().includes("amended")) {
        name = "Correcting form";
      } else if (file.filename.toLowerCase().includes("cancelled")) {
        name = "Voided form";
      }

      return {
        id: file.fileId,
        data: {
          fileId: file.fileId,
          filename: file.filename,
          name: name,
          createdAt: file.createdAt
        }
      };
    })
    .sort((a, b) => {
      return a.data.createdAt > b.data.createdAt ? -1 : 1;
    });

  const filesContent = (
    <>
      <WSTable<FileTableItem>
        tableData={filesTableData}
        rowActions={({ data }) => [
          {
            label: " ",
            text: "Download",
            icon: "download",
            hideOn: [],
            onAsyncClick: async () => {
              await downloadFile({
                accessScope: WSFileAccessScope.Private,
                fileId: data.fileId
              });
            }
          }
        ]}
        columns={[
          {
            config: {
              gridTemplateSizeMax: "1fr",
              header: {
                text: "T4A slip"
              }
            },
            renderFunction: ({ data, id }) => {
              return <WSTableCell text={data.name} />;
            }
          },
          {
            config: {
              gridTemplateSizeMax: "1fr",
              header: {
                text: "Status"
              }
            },
            renderFunction: ({ data }) => {
              return (
                <WSTableCell
                  pill={{
                    theme: "blue",
                    icon: "send-mail",
                    text: "Submitted"
                  }}
                />
              );
            }
          }
        ]}
      />
    </>
  );

  const isMultiple = filesTableData.length > 1;

  return (
    <TaxFormCardView
      content={
        <>
          <WSText.ParagraphSm color="gray500">
            Your {props.year} Form T4A is now available.
          </WSText.ParagraphSm>
          {isMultiple ? filesContent : null}
        </>
      }
      pill={
        isMultiple
          ? {
              text: "Multiple forms",
              theme: "neutral",
              icon: "collection"
            }
          : undefined
      }
      supportEmail={supportEmail}
      supportPhone={supportPhone}
      buttons={[
        () =>
          ({
            label: "Download",
            rightIcon: "download",
            onAsyncClick: async () => {
              if (filesTableData[0]) {
                await downloadFile({
                  accessScope: WSFileAccessScope.Private,
                  fileId: filesTableData[0].data.fileId
                });
              }
            }
          } as WSActionsButton)
      ]}
      payerName={payerName}
    />
  );
};
