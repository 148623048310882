import {
  useModalOldContext,
  WSButton,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";

import {
  useCreateIntegrationsQuickbooksAccountAssets,
  useCreateIntegrationsQuickbooksAccountExpenses,
  useCreateIntegrationsQuickbooksAccountLiabilities,
  useCreateIntegrationsQuickbooksAccountRevenues,
  useCreateIntegrationsQuickbooksCustomers,
  useCreateIntegrationsQuickbooksItems,
  useCreateIntegrationsQuickbooksVendors,
  useUpdateIntegrationQuickbooks
} from "../../../../../query/integrations/mutations";

import {
  IntegrationsQuickbooksAuthStatus,
  IntegrationsQuickbooksSyncStatus
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";
import { useIntegrationsQuickbooks } from "../../../../../query/integrations/queries";
import { useLocation } from "react-router-dom";
import { openIntercom } from "../../../../../shared/utils/intercom";

export const INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL =
  "INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL";

export const IntegrationsQuickbooksResyncModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  const [updateIntegration] = useUpdateIntegrationQuickbooks();

  const [createIntegrationsQuickbooksAccountAssets] =
    useCreateIntegrationsQuickbooksAccountAssets();
  const [createIntegrationsQuickbooksAccountExpenses] =
    useCreateIntegrationsQuickbooksAccountExpenses();
  const [createIntegrationsQuickbooksAccountLiabilities] =
    useCreateIntegrationsQuickbooksAccountLiabilities();
  const [createIntegrationsQuickbooksAccountRevenues] =
    useCreateIntegrationsQuickbooksAccountRevenues();
  const [createIntegrationsQuickbooksCustomers] =
    useCreateIntegrationsQuickbooksCustomers();
  const [createIntegrationsQuickbooksItems] =
    useCreateIntegrationsQuickbooksItems();
  const [createIntegrationsQuickbooksVendors] =
    useCreateIntegrationsQuickbooksVendors();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isPerformingResync = params.get("resync") === "true";

  const [isSyncing, setIsSyncing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [currentStep, setCurrentStep] = useState("Starting resync");

  const doSync = async () => {
    window.history.pushState({}, document.title, window.location.pathname);
    setIsSyncing(true);
    setIsDone(false);
    setCurrentStep("Beginning sync");
    await updateIntegration({
      syncStatus: IntegrationsQuickbooksSyncStatus.PendingSync
    });

    setCurrentStep("Syncing asset accounts");
    await createIntegrationsQuickbooksAccountAssets();

    setCurrentStep("Syncing expense accounts");
    await createIntegrationsQuickbooksAccountExpenses();

    setCurrentStep("Syncing liability accounts");
    await createIntegrationsQuickbooksAccountLiabilities();

    setCurrentStep("Syncing revenue accounts");
    await createIntegrationsQuickbooksAccountRevenues();

    setCurrentStep("Syncing customers");
    await createIntegrationsQuickbooksCustomers();

    setCurrentStep("Syncing vendors");
    await createIntegrationsQuickbooksVendors();

    setCurrentStep("Syncing items");
    await createIntegrationsQuickbooksItems();

    setIsDone(true);
  };

  useEffect(() => {
    if (
      isPerformingResync &&
      !isSyncing &&
      qIntegrationsQuickbooks.data?.authStatus ===
        IntegrationsQuickbooksAuthStatus.Connected &&
      qIntegrationsQuickbooks.data?.syncStatus !==
        IntegrationsQuickbooksSyncStatus.Synced
    ) {
      console.log("Should start resync");
      doSync();
    }
  }, [isPerformingResync, qIntegrationsQuickbooks, isSyncing]);

  return (
    <WSModalOld
      blockClose={true}
      showCloseIcon={false}
      name={INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL}
    >
      {({ onSuccess, onError }) =>
        !hasError ? (
          isDone ? (
            <>
              <WSText.Heading5 mb="XL">
                Sync Repair Complete! 🎉
              </WSText.Heading5>
              <WSText mb="XL">
                We've successfully imported the necessary data into Wingspan
                from your Quickbooks Online account.
              </WSText>
              <WSText mb="3XL">
                The following steps will have you map your data in Wingspan to
                the data we imported from Quickbooks.
              </WSText>
              <WSButton.Primary
                mt="XL"
                fullWidth
                data-testid="finishResync"
                onClick={() => {
                  onSuccess && onSuccess();
                  closeModal(INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL);
                }}
              >
                Continue to data mapping
              </WSButton.Primary>
            </>
          ) : (
            <>
              <WSText.Heading5 mb="XL">
                Repairing your sync state... 🔧
              </WSText.Heading5>
              <WSText mb="3XL">
                We're syncing Wingspan with your Quickbooks. This process
                involves importing necessary data from your Quickbooks account
                into your Wingspan account. Please stand by...
              </WSText>

              <WSElement mb="XL">
                <WSLoader.Spinner mb="M" />
                <WSFlexBox justify="center">
                  <WSText.ParagraphXs>{currentStep}</WSText.ParagraphXs>
                </WSFlexBox>
              </WSElement>
            </>
          )
        ) : (
          <>
            <WSText.Heading5 mb="XL">
              Yikes! Something went wrong 🙀
            </WSText.Heading5>
            <WSText mb="3XL">
              Something went wrong while syncing with your Quickbooks. Please
              try again. If this error persists, please{" "}
              <WSButton.Link onClick={openIntercom}>
                contact support.
              </WSButton.Link>
            </WSText>
            <WSButton.Primary
              mt="XL"
              fullWidth
              data-testid="finishResync"
              onClick={() => {
                onError && onError();
                closeModal(INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL);
              }}
            >
              Go back
            </WSButton.Primary>
          </>
        )
      }
    </WSModalOld>
  );
};
