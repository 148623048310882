import { IServiceStatusResponse } from "@wingspanhq/payments/dist/interfaces";
import { components } from "../../../services/api/banking/types";

export const selectorDefaultInternalAccount = (
  internalAccounts: components["schemas"]["InternalAccount"][],
  paymentSettings?: IServiceStatusResponse
) => {
  const defaultCurrency = paymentSettings?.defaultAccountCurrency || "USD";

  const apPrefundingAccount = internalAccounts.find(
    internalAccount =>
      internalAccount.type === "ApPrefunding" &&
      internalAccount.currency === defaultCurrency &&
      internalAccount.status === "Active"
  );

  if (apPrefundingAccount) {
    return apPrefundingAccount;
  }

  const bankingAccount = internalAccounts.find(
    internalAccount =>
      internalAccount.type === "Banking" && internalAccount.status === "Active"
  );

  return bankingAccount;
};
