import {
  useWSModal,
  WSActions,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { BulkResource } from "../../types";
import { useGoToPayableBatches, useGoToContractorBatches } from "../../paths";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { getUploadedFilename } from "../../utils/getUploadedFilename";

const ModalConfirmBulkBatchDelete: React.FC<{
  onClose: () => void;
  bulkBatchId: string;
  isEmptyBatch: boolean;
  bulkResource: BulkResource;
}> = ({ onClose, bulkBatchId, isEmptyBatch, bulkResource }) => {
  const { success, warning } = useSnackbar();
  const { useDeleteBulkBatch } = getBulkQueriesByResource(bulkResource);
  const [deleteBulkBatch, deleteBulkBatchMeta] = useDeleteBulkBatch();
  const goToContractorBatches = useGoToContractorBatches();
  const goToPayableBatches = useGoToPayableBatches();

  let deleteModalProps = {
    modalDesc:
      "This batch has line items but no payables or deductions are created yet.",
    emptyBatchModalDesc:
      "This batch is empty. No payable or line items are imported yet.",
    redirect: goToPayableBatches
  };
  if (bulkResource === BulkResource.Collaborator) {
    deleteModalProps = {
      modalDesc:
        "This batch has contractors data but no contractors are created yet.",
      emptyBatchModalDesc:
        "This batch is empty. No contractors are imported yet.",
      redirect: goToContractorBatches
    };
  }
  const { modalDesc, emptyBatchModalDesc, redirect } = deleteModalProps;

  const deleteBulkImport = async () => {
    await deleteBulkBatch(
      { bulkBatchId },
      {
        onSuccess: (response: IBulkBatch) => {
          success(
            `${getUploadedFilename(
              response,
              bulkResource
            )} successfully deleted.`
          );
          onClose();
          redirect();
        },
        onError: err => {
          warning(
            `Failed to delete: ${
              err.response?.data?.error || "Something went wrong!"
            }`
          );
        }
      }
    );
  };
  return (
    <WSList gap="L">
      <WSText.ParagraphSm weight="book" color="gray600">
        Are you sure you want to delete this batch?
      </WSText.ParagraphSm>

      <WSInfoBox>{isEmptyBatch ? emptyBatchModalDesc : modalDesc}</WSInfoBox>

      <WSActions
        alignment="fill"
        sortButtons={false}
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Tertiary"
          },
          {
            label: "Delete",
            onClick: deleteBulkImport,
            destructive: true,
            loading: deleteBulkBatchMeta.isLoading
          }
        ]}
      />
    </WSList>
  );
};

export const useModalConfirmBulkBatchDelete = () =>
  useWSModal(ModalConfirmBulkBatchDelete, {
    title: "Delete batch?",
    size: "S",
    showCloseIcon: false
  });
