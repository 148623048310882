import {
  useModalOldContext,
  WSButton,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useDeleteIntegrationQuickbooks } from "../../../../../query/integrations/mutations";

export const INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL =
  "INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL";

export const IntegrationsQuickbooksDisconnectModal: React.FC = () => {
  const { closeModal } = useModalOldContext();
  const [deleteIntegrationsQuickbooks] = useDeleteIntegrationQuickbooks();

  return (
    <WSModalOld
      name={INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL}
      title="This will disconnect Wingspan from your Quickbooks account."
    >
      {() => (
        <>
          <WSText mb="3XL">
            We will not delete data from your Quickbooks, but we will no longer
            push new data.
          </WSText>
          <WSButton
            destructive
            mt="XL"
            fullWidth
            data-testid="disconnectOAuth"
            onClick={async () => {
              await deleteIntegrationsQuickbooks();
              closeModal(INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL);
            }}
          >
            Continue
          </WSButton>
        </>
      )}
    </WSModalOld>
  );
};
