import {
  WSFlexBox,
  WSGrid,
  WSIconList,
  WSMarginProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

import styles from "./styles.module.scss";

type Props = WSMarginProps;

export const PayableColumnsStackedView: React.FC<Props> = props => {
  const queryLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const lineItemCustomFields = queryLineItemCustomFields.data || [];

  const requiredFields = [
    "Email or external ID",
    "Amount (USD)",
    "Line item title",
    "Due date (or Pay date if paid)"
  ];
  const optionalFields = [
    "Line item description",
    "Reimbursable",
    "Invoice notes",
    "Invoice date",
    "Attachment ID",
    "Payment reference ID"
  ];

  lineItemCustomFields.forEach(field => {
    if (field.required) {
      requiredFields.push(field.name);
    } else {
      optionalFields.push(field.name);
    }
  });

  return (
    <WSGrid gutter="S" {...props}>
      <WSGrid.Item span={{ xs: "12", s: "6" }}>
        <WSFlexBox.CenterY mb="M">
          <WSText.ParagraphSm color="gray500" className={styles.title}>
            Required
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSIconList.Checks
          items={requiredFields}
          iconProps={{
            name: "check-circle",
            color: "green400"
          }}
          textProps={{
            kind: "ParagraphSm"
          }}
          mb="XL"
        />
      </WSGrid.Item>
      <WSGrid.Item span={{ xs: "12", s: "6" }}>
        <WSText.ParagraphSm color="gray500" mb="M">
          Optional
        </WSText.ParagraphSm>
        <WSIconList.Checks
          items={optionalFields}
          iconProps={{
            name: "info-circle",
            color: "gray500"
          }}
          textProps={{
            kind: "ParagraphSm"
          }}
          mb="XL"
        />
      </WSGrid.Item>
    </WSGrid>
  );
};
