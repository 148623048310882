import {
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import React, { useState } from "react";
import { WSQueries } from "../../../query/WSQuery";
import { usePayerListQuery } from "../../../query/payers/queries/usePayerListQuery";
import { useEnterpriseQuery } from "../../../query/platform/queries";
import { TextBulleted } from "../TextBulleted";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  onSuccess: () => void;
} & WSElementProps;

export const FormW9Concern: React.FC<Props> = ({
  onSuccess,
  ...elementProps
}) => {
  const [agreement, setAgreement] = useState(false);
  const [shareW9, setShareW9] = useState(false);
  const queryEnterprise = useEnterpriseQuery();
  const queryPayers = usePayerListQuery();
  const [submit, submitMeta] = useMutationSubmit({ onSuccess });

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ queryPayers }}>
        {({ queryPayers: { data: payers } }) => {
          const payerNames = payers
            .map(payer =>
              wsName({
                user: payer.user!,
                member: payer.member,
                payeeOwnedData: payer.payeeOwnedData
              }).getResolvedName()
            )
            .join(", ");

          return (
            <>
              <WSText mb="M">
                By using Wingspan’s services you agree that the information
                provided for Form W-9 is accurate and that you certify the
                provided information as follows:
              </WSText>
              <WSText mb="M">"I certify, under penalty of perjury that:</WSText>
              <TextBulleted mb="M">
                <li>
                  Taxpayer Identification Number or Social Security Number I
                  provided is correct;
                </li>
                <li>
                  I am not subject to backup withholding because: (a) I am
                  exempt from backup withholding, or (b) I have not been
                  notified by the Internal Revenue Service (IRS) that I am
                  subject to backup withholding as a result of a failure to
                  report all interest or dividends, or (c) the IRS has n otified
                  me that I am no longer subject to backup withholding; and
                </li>
                <li>I am a U.S. citizen or other U.S. person; and</li>
                <li>
                  The FATCA code(s) entered (if any) indicating that I am exempt
                  from FATCA reporting is correct.
                </li>
              </TextBulleted>
              <WSText>
                The Internal Revenue Service does not require your consent to
                any provision of this document other than the certifications
                required to avoid backup withholding."
              </WSText>

              <WSCheckboxToggle
                my="3XL"
                name="agreement"
                label="By providing the information on this form, I hereby certify,
                to the best of my knowledge, that the information provided
                is complete and correct."
                value={agreement}
                onChange={value => {
                  setAgreement(value);
                }}
              />

              {payers.length > 0 && (
                <WSCheckboxToggle
                  my="3XL"
                  name="shareW9"
                  label={`I consent to share my Form W-9 with ${payerNames}`}
                  value={shareW9}
                  onChange={value => {
                    setShareW9(value);
                  }}
                />
              )}

              <WSButton
                disabled={
                  payers.length > 0 ? !agreement || !shareW9 : !agreement
                }
                mb="M"
                onClick={submit}
                fullWidth
                name="next"
                loading={submitMeta.isLoading}
              >
                Accept payments
                {queryEnterprise.data
                  ? ` from ${queryEnterprise.data.name}`
                  : ""}
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
