import { WSTableCell } from "@wingspanhq/fe-component-library";
import { IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { BulkResource } from "../../types";
import { useBulkBatchStatus } from "../../hooks/useBulkBatchStatus";

export function BulkBatchImportStatus({
  batchId,
  bulkResource,
  showIcon
}: {
  batchId: string;
  bulkResource: BulkResource;
  showIcon?: boolean;
}) {
  const pillProps = useBulkBatchStatus(batchId, bulkResource, showIcon);
  return <WSTableCell pill={pillProps} />;
}
