import {
  WSActions,
  WSCard,
  WSEmptyState,
  WSList,
  WSLoader
} from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import React, { useEffect } from "react";
import { WSQueryCache } from "@ws-react-query";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  QUERY_SEARCH_TAX_FORM_ROWS,
  QUERY_SEARCH_TAX_FORM_ROWS_COUNT
} from "../../../../../query/search/taxForm/keys";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { openIntercom } from "../../../../../shared/utils/intercom";
import {
  useCreateBulk1099SubmitBatchItem,
  useCreateBulkCalculate1099Batch,
  useUpdateBulkCalculate1099Batch
} from "../../../../BulkImporter/query/bulkCalculation1099/mutations";
import { TAX_FILING_1099_FILING_DASHBOARD_PATH } from "../../../paths";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { BULK_CALCULATE_1099_BATCH_LIST } from "../../../../BulkImporter/query/bulkCalculation1099/keys";
import { QUERY_ACTIVE_BATCH_ITEMS } from "../../../hooks/useCurrentCalculationBatchStatus";

export const RouteNewSubmitting: React.FC<SetupRouteComponentProps> = ({
  onNext
}) => {
  const history = useHistory();
  const currentYear = useCurrentTaxFilingYear();
  const userId = useUserId();
  const [retryCount, setRetryCount] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(true);

  const [createBulk1099CalculateBatch, createBulk1099CalculateBatchMeta] =
    useCreateBulkCalculate1099Batch();

  const [createBulk1099SubmitBatchItem, createBulk1099SubmitBatchItemMeta] =
    useCreateBulk1099SubmitBatchItem();

  const [updateBulkCalculate1099Batch, updateBulkCalculate1099BatchMeta] =
    useUpdateBulkCalculate1099Batch();

  useEffect(() => {
    setIsProcessing(true);
    const onError = () => {
      setIsProcessing(false);
    };

    createBulk1099CalculateBatch(
      {
        labels: {
          filingYear: `${currentYear}`
        }
      },
      {
        throwOnError: true,
        onSuccess: async bulkBatch => {
          const bulkBatchId = bulkBatch.bulkCalculation1099BatchId;

          const batchItemsRequest: IBulkCalculation1099ItemCreate = {
            clientId: userId,
            year: currentYear,
            calculationType: BulkCalculation1099Type.Submissions
          };
          await createBulk1099SubmitBatchItem(
            {
              batchId: bulkBatchId,
              ...batchItemsRequest
            },
            {
              onSuccess: async () => {
                await updateBulkCalculate1099Batch(
                  {
                    id: bulkBatchId,
                    data: {
                      status: BulkStatus.Pending
                    }
                  },
                  {
                    onSuccess: () => {
                      // invalidate query for tax form search
                      WSQueryCache.removeQueries(QUERY_ACTIVE_BATCH_ITEMS);
                      WSQueryCache.invalidateQueries(
                        BULK_CALCULATE_1099_BATCH_LIST
                      );
                      WSQueryCache.invalidateQueries(
                        QUERY_SEARCH_TAX_FORM_ROWS
                      );
                      WSQueryCache.invalidateQueries(
                        QUERY_SEARCH_TAX_FORM_ROWS_COUNT
                      );
                      onNext({ bulkBatchId });
                    },
                    onError
                  }
                );
              },
              onError
            }
          );
        },
        onError
      }
    );
  }, [
    userId,
    retryCount,
    createBulk1099CalculateBatch,
    createBulk1099SubmitBatchItem,
    updateBulkCalculate1099Batch,
    onNext
  ]);

  const isLoading =
    isProcessing ||
    createBulk1099CalculateBatchMeta.isLoading ||
    createBulk1099SubmitBatchItemMeta.isLoading ||
    updateBulkCalculate1099BatchMeta.isLoading;

  return (
    <Layout title="1099-NEC Filing">
      {isLoading ? (
        <WSLoader.Spinner />
      ) : (
        <WSList gap="2XL">
          <WSCard>
            <WSEmptyState
              type="error"
              theme="garnet"
              orientation="horizontal"
              title="There was an error submitting the tax forms to the IRS"
              description={
                <>
                  Try again and if the issue persists, please{" "}
                  <a onClick={openIntercom}>contact support</a>.
                </>
              }
              buttons={[
                {
                  label: "Try again",
                  kind: "Secondary",
                  onClick: () => {
                    setRetryCount(retryCount + 1);
                  }
                }
              ]}
            />
          </WSCard>

          <WSActions
            alignment="fill"
            buttons={[
              {
                label: "Go to dashboard",
                onClick: () => {
                  history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
                }
              }
            ]}
          />
        </WSList>
      )}
    </Layout>
  );
};
