import {
  copyText,
  useIsDesktop,
  useModalOldContext,
  useWSSnackbar,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSInfiniteScroll,
  WSPage,
  WSSelectOld,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { IOrganizationAccount } from "@wingspanhq/users/dist/lib/interfaces/organizationAccount";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import React, { useCallback, useMemo, useState } from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useRequestOrganizationUser } from "../../query/users/mutations";
import {
  useOrganizationUser,
  useOrganizationUsers
} from "../../query/users/queries";
import { WSQueries, WSQuery } from "../../query/WSQuery";
import { useUrlQueryFilters } from "../../utils/router";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams,
  SortDirection
} from "../../utils/tableSortingHelpers";
import {
  ORGANIZATION_DESCRIPTION_INFO_MODAL,
  OrganizationDescriptionInfoModal
} from "../modals/OrganizationDescriptionInfoModal";
import styles from "./OrganizationAccounts.module.scss";

const Parent: React.FC<{ organizationAccount?: IOrganizationAccount }> = ({
  organizationAccount
}) => {
  const qUser = useOrganizationUser(
    organizationAccount?.parentUserId as string,
    {
      enabled: !!organizationAccount?.parentUserId
    }
  );

  if (!organizationAccount?.parentUserId) {
    return <WSTableCell />;
  }

  return (
    <WSQuery query={qUser} renderLoader={() => <WSTableCell text="Parent" />}>
      {user => (
        <WSTableCell
          text={
            user.data.profile.company?.name ||
            user.data.user.email ||
            user.data.memberId
          }
        />
      )}
    </WSQuery>
  );
};

export type DefaultOrganizationAccountsFilters = {
  searchBy?: string;
  search?: string;
  sort?: {
    "profile.preferredName"?: SortDirection;
    "profile.firstName"?: SortDirection;
    "profile.lastName"?: SortDirection;
    userId?: SortDirection;
    email?: SortDirection;
  };
};

export const defaultOrganizationAccountsFilters: DefaultOrganizationAccountsFilters =
  {
    sort: {},
    searchBy: "email",
    search: ""
  };

export const useOrganizationAccountsFilters = () =>
  useUrlQueryFilters<DefaultOrganizationAccountsFilters>(
    defaultOrganizationAccountsFilters
  );

export const OrganizationAccounts: React.FC = () => {
  const { openModal } = useModalOldContext();
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const isDesktop = useIsDesktop();

  const { filters, setFilters } = useOrganizationAccountsFilters();

  const [requestUser, requestUserMeta] = useRequestOrganizationUser();

  const onSwitchAccount = (userId: string) => {
    requestUser(userId, {
      onSuccess: () => {
        window.location.reload();
      },
      onError(error) {
        openSnackbar({
          message:
            error.response?.data?.error ||
            error.message ||
            "Something went wrong!",
          type: "warning"
        });
      }
    });
  };

  const { search, searchBy, sort } = filters;

  const qOrganizationUsers = useOrganizationUsers({
    sort,
    filter:
      searchBy && search
        ? {
            [searchBy]: {
              [["userId", "organizationAssociation.parentUserId"].includes(
                searchBy
              )
                ? "="
                : "contains"]: search
            }
          }
        : undefined
  });

  const columns: Array<WSTableColumn<INewUser>> = [
    {
      config: {
        header: {
          text: "Name"
        },
        gridTemplateSizeMax: "1fr",
        sortDirection: mapAndRevertParamsSortToTable(
          filters.sort?.["profile.preferredName"]
        ),
        onColumnSort(direction) {
          const newDirection = mapAndRevertTableSortToParams(
            filters.sort?.["profile.preferredName"]
          );

          return setFilters({
            sort: {
              "profile.preferredName": newDirection,
              "profile.lastName": newDirection
            }
          });
        }
      },
      renderFunction: row => <AccountName user={row.data} />
    },
    {
      config: {
        header: {
          text: "Parent(s)"
        },
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: row => (
        <Parent organizationAccount={row.data.organizationAssociation} />
      )
    },
    {
      config: {
        header: {
          text: "User ID"
        },
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: row => (
        <WSTableCell
          text={row.data.userId}
          onClick={async () => {
            await copyText(row.data.userId);
            openSnackbar({
              message: "Copied!",
              type: "success"
            });
          }}
          icon="copy-fill"
        />
      )
    },
    {
      config: {
        header: {
          text: "Email"
        },
        gridTemplateSizeMax: "1fr",
        sortDirection: mapAndRevertParamsSortToTable(filters.sort?.email),
        onColumnSort(direction) {
          return setFilters({
            sort: {
              email: mapAndRevertTableSortToParams(filters.sort?.email)
            }
          });
        }
      },
      renderFunction: row => <WSTableCell text={row.data.email} />
    }
  ];

  const searchByOptions = [
    {
      value: "email",
      label: "Email"
    },
    {
      value: "profile.preferredName",
      label: "Preferred Name"
    },
    {
      value: "profile.firstName",
      label: "First Name"
    },
    {
      value: "profile.lastName",
      label: "Last Name"
    },
    {
      value: "userId",
      label: "User ID(strict)"
    },
    {
      value: "organizationAssociation.parentUserId",
      label: "Parent Id(strict)"
    }
  ];

  const [realtimeSearch, setRealtimeSearch] = useState(search);

  const handleSearch = useCallback(
    debounce((query: string) => {
      setFilters({ ...filters, search: query });
    }, 1000),
    [filters.searchBy, filters.sort]
  );

  return (
    <WSPage title="Wingspan Organization">
      <OrganizationDescriptionInfoModal />
      <WSFlexBox wrap="nowrap">
        <WSText mb="2XL">
          Wingspan Organization Accounts allow you to programmatically create
          and manage child accounts.
          <WSIcon
            ml="XS"
            mr="M"
            name="info-circle"
            size="XS"
            color="blue500"
            onClick={() => openModal(ORGANIZATION_DESCRIPTION_INFO_MODAL)}
          />
          Your user ID is{" "}
          <WSElement as="b" color="gray500">
            {userId}
          </WSElement>
          <WSIcon
            name="copy-fill"
            size="S"
            ml="XS"
            color="blue400"
            onClick={async () => {
              await copyText(userId);
              openSnackbar({
                message: "Copied!",
                type: "success"
              });
            }}
          />
        </WSText>
      </WSFlexBox>

      <WSGrid>
        <WSGrid.Item span={{ xs: "6", s: "4" }}>
          <WSTextInput
            inputClassName={styles.searchInput}
            value={realtimeSearch}
            onChange={event => {
              setRealtimeSearch(event.target.value);
              handleSearch(event.target.value);
            }}
            name="search"
            icon="search"
            placeholder={`Search by`}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ xs: "6", s: "4" }}>
          <WSSelectOld
            label="Search by"
            error={false}
            value={searchBy || "email"}
            name="searchBy"
            onChange={newValue => {
              setFilters({
                ...filters,
                searchBy: newValue as any as string
              });
            }}
            mb="2XL"
            options={searchByOptions}
          />
        </WSGrid.Item>
      </WSGrid>

      <WSQueries queries={{ qOrganizationUsers }}>
        {({ qOrganizationUsersData }) => {
          const usersList = flatten(qOrganizationUsersData.pages);

          const tableData = usersList.map(user => {
            return {
              data: user,
              id: user.userId
            };
          });

          return (
            <WSInfiniteScroll
              onLoad={() => {
                qOrganizationUsers.fetchNextPage();
              }}
              loadMore={usersList.length > 0}
              endOfList={!qOrganizationUsers.hasNextPage}
              loading={!!qOrganizationUsers.isFetchingNextPage}
            >
              <WSTable
                mt="3XL"
                mb="XL"
                tableData={tableData}
                columns={columns}
                showHeader
                bottomMessage={!usersList.length && "No accounts yet"}
                rowActions={
                  isDesktop
                    ? row => [
                        {
                          text: "View Dashboard",
                          onClick: () => onSwitchAccount(row.data.userId)
                        }
                      ]
                    : undefined
                }
                rowMenuActions={row => [
                  {
                    icon: "view",
                    label: "View Dashboard",
                    onClick: () => onSwitchAccount(row.data.userId)
                  }
                ]}
              />
            </WSInfiniteScroll>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};

const AccountName = ({ user }: { user: INewUser }) => {
  const name = useMemo(
    () =>
      wsName({
        user: user,
        member: (user as any).member
      }).getResolvedName(),
    [user]
  );

  return (
    <WSTableCell
      text={name || "Unnamed user"}
      truncationOptions={{
        text: {
          allowTwoLines: true
        }
      }}
    />
  );
};
