import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../components/EditLayout";
import { useBulkCalculate1099Batch } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import {
  useCreateBulk1099SubmitBatchItem,
  useUpdateBulkCalculate1099Batch
} from "../../../../BulkImporter/query/bulkCalculation1099/mutations";
import {
  WSActions,
  WSCard,
  WSEmptyState,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import { useUserId } from "../../../../../query/hooks/helpers";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { WSQueries } from "../../../../../query/WSQuery";
import { WSQueryCache } from "@ws-react-query";
import {
  useGoToGenerateTaxFormProgress,
  useGoToTaxFiling
} from "../../../paths";
import React from "react";
import { GenerateTaxFormsInfo } from "./GenerateTaxFormsInfo";
import { QUERY_TAX_FORM_SUBMISSION_ROWS } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";

export const RouteGenerate1099NECAmounts: React.FC = () => {
  const history = useHistory<{
    canSkip?: boolean;
  }>();
  const userId = useUserId();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const canSkip = history.location.state?.canSkip;
  const goToDashboard = useGoToTaxFiling();
  const goToProgress = useGoToGenerateTaxFormProgress();
  const currentYear = useCurrentTaxFilingYear();

  const qCalculate1099BulkBatch = useBulkCalculate1099Batch(bulkBatchId);

  const [createBulk1099SubmitBatchItem, createBulk1099SubmitBatchItemMeta] =
    useCreateBulk1099SubmitBatchItem();

  const [updateBulkCalculate1099Batch, updateBulkCalculate1099BatchMeta] =
    useUpdateBulkCalculate1099Batch();

  const onContinue = async () => {
    const batchItemsRequest: IBulkCalculation1099ItemCreate = {
      clientId: userId,
      year: currentYear,
      calculationType: BulkCalculation1099Type.Balances
    };
    await createBulk1099SubmitBatchItem(
      {
        batchId: bulkBatchId,
        ...batchItemsRequest
      },
      {
        onSuccess: async () => {
          await updateBulkCalculate1099Batch(
            {
              id: bulkBatchId,
              data: {
                status: BulkStatus.Pending
              }
            },
            {
              onSuccess: () => {
                // invalidate query for tax form search
                WSQueryCache.invalidateQueries([
                  QUERY_TAX_FORM_SUBMISSION_ROWS
                ]);
                WSQueryCache.invalidateQueries([
                  QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY
                ]);

                goToProgress(bulkBatchId);
              }
            }
          );
        },
        onError: () => {}
      }
    );
  };

  return (
    <EditLayout title="Generate amounts and statuses" onBack={goToDashboard}>
      <WSQueries
        queries={{
          qCalculate1099BulkBatch
        }}
      >
        {({ qCalculate1099BulkBatchData: calculate1099BulkBatch }) => {
          return (
            <WSList gap="XL">
              <WSCard
                header="Generate Form 1099-NECs amounts and statuses"
                divider
              >
                <WSList gap="XL">
                  <WSEmptyState
                    fullWidth
                    orientation="horizontal"
                    type="documents"
                    title="What to expect "
                    description={`You are about to generate Form 1099-NECs for all contractors.\n\nClick “Generate Form 1099s” to proceed or use a shortcut below to address any issues.`}
                  />
                  <GenerateTaxFormsInfo />
                </WSList>
              </WSCard>
              <WSActions
                alignment="fill"
                buttons={[
                  {
                    onClick: onContinue,
                    label: "Generate Form 1099s",
                    kind: "Primary",
                    loading:
                      createBulk1099SubmitBatchItemMeta.isLoading ||
                      updateBulkCalculate1099BatchMeta.isLoading,
                    disabled: calculate1099BulkBatch.status !== BulkStatus.Open
                  },
                  {
                    kind: "Tertiary",
                    type: "button",
                    label: canSkip ? "Skip for now" : "Cancel",
                    onClick: goToDashboard
                  }
                ]}
              />

              {canSkip ? (
                <WSText.ParagraphSm color="gray400">
                  If you skip for now, you can run 1099-NECs amounts and
                  statuses calculations later from the 1099 dashboard. New
                  recipients will remain in a pending state until calculations
                  are run.
                </WSText.ParagraphSm>
              ) : null}
            </WSList>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
