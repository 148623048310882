import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { components } from "../../services/api/onboarding/types";
import { selectorIsDomesticMember } from "./selectorIsDomesticMember";

export const selectorUseInternationalPayouts = (
  member: IMember,
  monitoredEntity?: components["schemas"]["CustomerEntity"]
) => {
  if (monitoredEntity && monitoredEntity.type === "Business") {
    return monitoredEntity.country !== "US";
  }

  return !selectorIsDomesticMember(member);
};
