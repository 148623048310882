import React from "react";
import {
  CustomFieldResourceType,
  IPayeeResponse,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import {
  WSElement,
  WSFlexBox,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import orderBy from "lodash/orderBy";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorIsEnterpriseUser } from "../../../../shared/selectors/selectorIsEnterpriseUser";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import { useUpdateCustomFieldsModal } from "./UpdateCustomFieldsModal";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";

type CustomFieldsWidgetProps = {
  payee: IPayeeResponse;
  isInactive: boolean;
};

export const CustomFieldsWidget: React.FC<CustomFieldsWidgetProps> = ({
  payee,
  isInactive
}) => {
  const userId = useUserId();
  const [showEmptyFields, setShowEmptyFields] = useLocalStorage(
    userId + ":empty-custom-fields-expanded",
    true
  );
  const queryUser = useUserProfile(userId);
  const updateCustomFieldsModal = useUpdateCustomFieldsModal();
  const queryCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  const displayRule = (rule: string | boolean | null | undefined) => {
    return showEmptyFields ? true : Boolean(rule);
  };

  return (
    <WSQueries
      renderLoader={() => null}
      renderErrors={() => null}
      queries={{ queryCollaboratorCustomFields, queryUser }}
    >
      {({ queryCollaboratorCustomFieldsData, queryUserData }) => {
        const isEnterpriseUser = selectorIsEnterpriseUser(queryUserData);
        const additionalData = payee.payerOwnedData.customFields || {};

        return queryCollaboratorCustomFieldsData.length > 0 ||
          isEnterpriseUser ? (
          <WSElement>
            <WSSectionToolbar
              mb="S"
              title="Custom fields"
              actions={[
                {
                  label: `Edit`,
                  icon: "edit",
                  onClick: () =>
                    updateCustomFieldsModal.open({
                      payee,
                      allCustomFields: queryCollaboratorCustomFieldsData,
                      isEnterpriseUser
                    })
                },
                {
                  icon: showEmptyFields ? "hide" : "view",
                  label: `${
                    showEmptyFields ? "Hide" : "Show"
                  } empty custom fields`,
                  onClick: () => setShowEmptyFields(prev => !prev)
                }
              ]}
              button={{
                disabled: isInactive,
                label: "Edit",
                onClick: () => {
                  updateCustomFieldsModal.open({
                    payee,
                    allCustomFields: queryCollaboratorCustomFieldsData,
                    isEnterpriseUser
                  });
                }
              }}
            />
            <WSPanel>
              <WSFlexBox direction="column" gap="M">
                {displayRule(payee.payerOwnedData.payeeExternalId) && (
                  <FieldView
                    label="External ID"
                    value={payee.payerOwnedData.payeeExternalId || "-"}
                  />
                )}
                {orderBy(queryCollaboratorCustomFieldsData, "type", "desc").map(
                  (field, index) => {
                    return (
                      <>
                        {displayRule(additionalData[field.key]) ? (
                          <FieldView
                            key={field.name + index}
                            label={field.name}
                            value={
                              field.type === WSDataTypes.Boolean
                                ? additionalData[field.key] === "true"
                                  ? "Yes"
                                  : "No"
                                : additionalData[field.key] || "-"
                            }
                          />
                        ) : null}
                      </>
                    );
                  }
                )}
              </WSFlexBox>
            </WSPanel>
          </WSElement>
        ) : null;
      }}
    </WSQueries>
  );
};
