import React from "react";
import {
  WSElementProps,
  WSElementSpacingsType
} from "../WSElement/WSElement.component";
import { WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSActionsProps } from "../WSActions/WSActions";
import { WSIconSize, WSTextAlignType } from "../core";

type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? never : K;
}[keyof T];

type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K : never;
}[keyof T];

export type OptionalModalComponentProps<Props extends {}> = Omit<
  Pick<Props, OptionalKeys<Props>>,
  "onClose"
>;

export type RequiredModalComponentProps<Props extends {}> = Omit<
  Pick<Props, RequiredKeys<Props>>,
  "onClose"
>;

export type OpenModalComponentProps<Props extends {}> = Omit<Props, "onClose">;

export enum WSModalSizeMap {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}

export type WSModalSize = keyof typeof WSModalSizeMap;

export const modalPaddingMap: {
  [key in WSModalSizeMap]: WSElementSpacingsType;
} = {
  XS: "L",
  S: "L",
  M: "XL",
  L: "XL",
  XL: "XL"
};

export const modalIconSizeMap: { [key in WSModalSizeMap]: WSIconSize } = {
  XS: "M",
  S: "M",
  M: "L",
  L: "L",
  XL: "L"
};

export interface WSModalProps<Result extends any = any> extends WSElementProps {
  title?: string;
  titleAlign?: WSTextAlignType;
  onClose?: (result?: Result) => void;
  blockClose?: boolean;
  size?: WSModalSize;
  pullOver?: boolean;
  showCloseIcon?: boolean;
  centered?: boolean;
  fullScreen?: boolean /** For tablet & desktop */;
  fullScreenOnMobile?: boolean;
  allowClickOutsideToClose?: boolean;
  allowClickOutsideWithConfirmationToClose?: boolean;
  avatar?: WSAvatarProps;
  actions?: WSActionsProps;
  headerActions?: WSActionsProps;
  divider?: boolean;
  footer?: React.ReactNode;
}

export const buildModalKey = () => `modal-key-${Date.now()}-${Math.random()}`;
