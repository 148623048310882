import { useMemo } from "react";
import {
  BulkStatus,
  CustomFieldResourceType,
  IBulkPayableBatch,
  IBulkPayableItem
} from "@wingspanhq/payments/dist/interfaces";

import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { preparePayablesCSVFile } from "../../utils/preparePayablesCSVFile";
import { prepareLineItemsFromBulkBatchItem } from "../../utils/prepareLineItemsFromBulkBatchItem";

export function useDownloadErrorsCSV(
  bulkBatchId: string,
  bulkResource: BulkResource
) {
  const { useBulkBatch, useAllBulkBatchItems } =
    getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const queryLineItemsCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const queryAllFailedBulkBatchItems = useAllBulkBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled:
        (queryBulkBatch.data?.statistics?.itemsFailed || 0) > 0 ||
        queryBulkBatch.data?.status === BulkStatus.Failed
    }
  );

  const errorsCSVFileProps = useMemo(() => {
    const failedCSVLineItems = (queryAllFailedBulkBatchItems.data ?? []).map(
      failedBatchItem =>
        prepareLineItemsFromBulkBatchItem(
          failedBatchItem as IBulkPayableItem,
          queryLineItemsCustomFields.data || [],
          { includeErrors: true }
        )
    );
    const errorsCSVFileProps = preparePayablesCSVFile(
      `Errors_${getUploadedFilename(
        queryBulkBatch.data as IBulkPayableBatch,
        BulkResource.Collaborator
      )}`,
      failedCSVLineItems,
      queryLineItemsCustomFields.data || [],
      { includeErrors: true }
    );

    return errorsCSVFileProps;
  }, [
    queryAllFailedBulkBatchItems.data,
    queryLineItemsCustomFields.data,
    queryBulkBatch.data
  ]);

  return { errorsCSVFileProps };
}
