import React, { ComponentType, useMemo, useState } from "react";
import { WSPortal } from "../core/WSPortal/WSPortal.component";

type ModalElement = React.FC<{}>;

type ModalItem = { key: string; modal: ModalElement };

interface WSModalContext {
  modals: ModalItem[];
  createModal: (modal: ModalItem) => void;
  removeModal: (key: string) => void;
}

export const wsModalContext = React.createContext<WSModalContext>({
  modals: [],
  createModal: () => {},
  removeModal: () => {}
});

export const UseWSModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [modals, setModals] = useState<ModalItem[]>([]);

  const createModal = useMemo(
    () => (modal: ModalItem) => {
      setModals((prevModals) => [...prevModals, modal]);
    },
    []
  );

  const removeModal = useMemo(
    () => (key: string) => {
      setModals((prevModals) =>
        prevModals.filter((modal) => key !== modal.key)
      );
    },
    []
  );

  return (
    <wsModalContext.Provider
      value={{
        modals,
        createModal,
        removeModal
      }}
    >
      {children}
      <WSPortal>
        {modals.map(({ key, modal: Modal }) => (
          <Modal key={key} />
        ))}
      </WSPortal>
    </wsModalContext.Provider>
  );
};

export const withUseWSModalProvider = (Component: ComponentType) => () => (
  <UseWSModalProvider>
    <Component />
  </UseWSModalProvider>
);
