import {
  toWSDateString,
  toWSMoneyString,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  IPayableSchema,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useQueryAllPayeeRows } from "../../../query/search/payee/queries/useQueryAllPayeeRows";
import { calculateLineItemsTotal } from "../../utils";
import {
  buildPayableIcon,
  PayablesFilters,
  PayablesTableItemData
} from "./PayablesTable";
import { getStatusColumn } from "./getStatusColumn";

export const PayableNameCell: React.FC<{ payable?: IPayableSchema }> = ({
  payable
}) => {
  const queryAllPayeeRows = useQueryAllPayeeRows();

  const payeeRow = queryAllPayeeRows.data?.find(
    row => row.payeeId === payable?.memberId
  );

  if (queryAllPayeeRows.isLoading) {
    return <WSTableCell icon="loader" />;
  }

  if (!payable || !payeeRow || !payeeRow.user) {
    return null;
  }

  const name = wsName({
    user: payeeRow.user,
    member: payeeRow.member,
    payerOwnedData: payeeRow.payerOwnedData
  });

  return (
    <WSTableCell
      avatar={{
        type: "icon",
        ...buildPayableIcon(payable)
      }}
      text={name.getResolvedName()}
      secondaryText={name.getResolvedSecondaryName()}
      truncationOptions={{
        text: { allowTwoLines: true }
      }}
    />
  );
};

export const getColumns = (props: {
  showDateRequested?: boolean;
  showDueDate?: boolean;
  showDatePaid?: boolean;
  showDateExpectedPay?: boolean;
  showLatestUpdate?: boolean;
  payrollSettings?: IPayrollSettings;
  sendPaymentsPaidAtLabel?: string;
  filters: PayablesFilters;
  currencyCode?: string;
  setFilters: (newFilters: PayablesFilters) => void;
}): WSTableColumn<PayablesTableItemData>[] => [
  {
    config: {
      gridTemplateSizeMax: "2fr",
      header: {
        text: "Name"
      }
    },
    renderFunction: ({ data: { payable } }) => {
      return <PayableNameCell payable={payable} />;
    }
  },

  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: ["XS", "S"],
      header: {
        text: "Invoice #"
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell text={payable?.invoiceNumber} />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      header: {
        text: `Amount ${props.currencyCode ? `(${props.currencyCode})` : ""}`
      }
    },
    renderFunction: ({ data: { payable } }) => {
      if (!payable) {
        return null;
      }

      return (
        <WSTableCell
          text={toWSMoneyString(
            calculateLineItemsTotal(payable.lineItems) +
              (payable.chargedFees?.lateFee?.amount || 0),
            "default",
            payable.currency
          )}
          secondaryText={payable?.lineItems[0].description}
        />
      );
    }
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDateRequested
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Opened date"
      }
    },
    renderFunction: ({ data: { payable } }) =>
      payable?.status === InvoiceStatus.Pending ? (
        <WSTableCell text="Eligibility pending" />
      ) : (
        <WSTableCell
          text={toWSDateString(payable?.events.openedAt, "monthDayYear")}
        />
      )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDueDate
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Due date"
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell
        text={toWSDateString(
          payable?.client.payDate || payable?.dueDate,
          "monthDayYear"
        )}
        secondaryText={payable?.client.payDate ? "Updated" : ""}
      />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDatePaid
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: props.sendPaymentsPaidAtLabel
      },
      sortDirection:
        props.filters.sort?.["events.paidAt"] === "asc"
          ? "ascending"
          : "descending",
      onColumnSort(direction) {
        return props.setFilters({
          sort: {
            "events.paidAt": direction === "ascending" ? "asc" : "desc"
          }
        });
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell
        text={toWSDateString(payable?.events.paidAt, "monthDayYear")}
      />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDateExpectedPay
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Expected pay date"
      }
    },
    renderFunction: ({ data: { payrollRun } }) => (
      <WSTableCell text={toWSDateString(payrollRun?.date, "monthDayYear")} />
    )
  },
  {
    config: {
      header: {
        text: "Status"
      },
      gridTemplateSizeMax: "1.5fr"
    },
    renderFunction: ({ data: { payable } }) => {
      if (!payable || !props.payrollSettings) {
        return null;
      }

      return (
        <WSTableCell {...getStatusColumn(payable, props.payrollSettings)} />
      );
    }
  }
];
