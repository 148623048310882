import {
  toWSDateString,
  WSButton,
  WSCard,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getPayerName } from "../../modules/Payers/utils/getPayerNames";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import { useInvoiceTemplateQuery } from "../../query/payments/queries";
import { useMemberProfile } from "../../query/users/queries";
import { Layout } from "../../shared/components/Layout";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";
import { AlertEnableTaxWithholdings } from "../components/AlertEnableTaxWithholdings";
import { FirstInvoiceBanner } from "../components/FirstInvoiceBanner/FirstInvoiceBanner";
import { InvoiceTemplateTotals } from "../components/InvoiceTempalteTotals";
import { useInvoiceFormGoBack } from "../utils/invoicesFormUtils";

export const RouteInvoiceTemplateConfirmation: React.FC<
  RouteComponentProps<{
    invoiceTemplateId: string;
  }>
> = ({
  match: {
    params: { invoiceTemplateId }
  }
}) => {
  const history = useHistory();
  const userId = useUserId();
  const memberQuery = useMemberProfile(userId);
  const queryInvoiceTemplate = useInvoiceTemplateQuery(invoiceTemplateId);
  const queryPayer = usePayerQuery(
    queryInvoiceTemplate.data?.invoiceData.clientId!,
    {
      enabled: !!queryInvoiceTemplate.data?.invoiceData.clientId
    }
  );
  const shouldPauseAccount = useShouldPauseAccount("taxes");
  const goBack = useInvoiceFormGoBack();

  return (
    <Layout title="Confirmation" onClose={goBack}>
      <WSQueries
        queries={{
          memberQuery,
          queryInvoiceTemplate,
          queryPayer
        }}
      >
        {({
          memberQuery: { data: member },
          queryInvoiceTemplate: { data: invoiceTemplate },
          queryPayer: { data: payer }
        }) => {
          const taxWithholdingsEnabled =
            member.profile.withholdings?.tax?.status ===
            MemberWithholdingStatus.Active;

          const payerName = getPayerName(payer);

          return (
            <WSList gap="XL">
              <FirstInvoiceBanner />

              <WSCard>
                <WSText.Heading4 mb="M">{payerName}</WSText.Heading4>

                <WSText.Heading5 mb="XS">Invoice is scheduled</WSText.Heading5>

                <WSText color="gray500" mb="XL">
                  {invoiceTemplate.frequency
                    ? `First invoice will be sent ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`
                    : `Invoice will be sent ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`}
                </WSText>
                <WSButton.Secondary
                  fullWidth
                  onClick={() => {
                    history.push(
                      `/member/invoices/template/${invoiceTemplateId}/edit`
                    );
                  }}
                >
                  {invoiceTemplate.frequency ? "Edit series" : "Edit invoice"}
                </WSButton.Secondary>
              </WSCard>

              <WSCard>
                <WSText.Heading5 mb="M" data-testid="paymentTimingText">
                  Payment timing
                </WSText.Heading5>
                <WSText color="gray600">
                  Funds are automatically deposited within 7 business days of
                  payment.
                </WSText>
              </WSCard>

              <WSCard>
                <WSList gap="2XL">
                  <WSText.Heading5>Summary</WSText.Heading5>

                  <InvoiceTemplateTotals invoiceTemplate={invoiceTemplate} />

                  {!shouldPauseAccount && !taxWithholdingsEnabled && (
                    <AlertEnableTaxWithholdings />
                  )}

                  <WSButton
                    onClick={() => {
                      history.push("/member/invoices");
                    }}
                    fullWidth
                  >
                    Back to invoices
                  </WSButton>
                </WSList>
              </WSCard>
            </WSList>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
