import { WSGrid, WSList } from "@wingspanhq/fe-component-library";
import { useQueryInternalAccount } from "../../../query/banking/queries/useQueryInternalAccount";
import { WingspanAccounts } from "../../PaymentMethods/components/WingspanAccounts";
import { InternalAccountBalance } from "../components/InternalAccountBalance";

type Props = {
  internalAccountId: string;
};

export const RouteNewDashboard: React.FC<Props> = props => {
  const queryIntenalAccount = useQueryInternalAccount(props.internalAccountId);

  return (
    <WSGrid gutter={{ s: "3XL" }}>
      <WSGrid.Item span={{ m: "8" }}>
        <InternalAccountBalance internalAccountId={props.internalAccountId} />
      </WSGrid.Item>

      <WSGrid.Item span={{ m: "4" }}>
        <WSList gap="2XL">
          <WingspanAccounts accountType={queryIntenalAccount.data?.type} />
        </WSList>
      </WSGrid.Item>
    </WSGrid>
  );
};
