import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openIntercom } from "../../../shared/utils/intercom";

export const BOOKKEEPING_TROUBLE_WITH_SYNCING =
  "BOOKKEEPING_TROUBLE_WITH_SYNCING";

export const TroubleWithSyncing: React.FC = () => {
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld name={BOOKKEEPING_TROUBLE_WITH_SYNCING} size="XS">
      {({ institution }) => (
        <>
          <WSText.Heading5 mb="M">
            <WSIcon block name="alert-circle" size="M" mr="M" color="red500" />
            We’re having trouble syncing with {institution}
          </WSText.Heading5>
          <WSText mb="M">
            This is likely a temporary connection issue. If this persists,
            please{" "}
            <WSButton.Link onClick={openIntercom}>
              contact our support
            </WSButton.Link>{" "}
            and our team will be happy to help you
          </WSText>
          <WSButtons mt="2XL" format="modal">
            <WSButton.Primary
              data-testid="tryOtherImportMethod"
              onClick={() => {
                closeModal(BOOKKEEPING_TROUBLE_WITH_SYNCING);
              }}
            >
              Try other import method
            </WSButton.Primary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
