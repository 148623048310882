import {
  useModalOldContext,
  WSDivider,
  WSElement,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { InternalAccountType } from "../../../services/api/banking/types";
import { CashbackProgramWidget } from "../../PaymentMethods/components/DebitCashbackProgram";
import { PayoutSplits } from "../../PaymentMethods/components/PayoutSplits";
import { WingspanAccounts } from "../../PaymentMethods/components/WingspanAccounts";
import { PendingState } from "../components/PendingState";
import {
  UPDATE_DEPOSIT_RATE_MODAL,
  UpdateDepositRateModal
} from "../modals/UpdateDepositRate";
import styles from "./styles.module.scss";

export const WalletGettingStarted = () => {
  const history = useHistory();
  const { closeModal, openModal } = useModalOldContext();
  const queryFeatureFlags = useFeatureFlags();
  const featureFlags = queryFeatureFlags.data;
  const queryVerifications = useQueryVerifications();

  const isVerified = queryVerifications.data?.banking === "Verified";

  return (
    <WSPage>
      <WSPageToolbar title="Wingspan Wallet" mb="2XL" />

      <UpdateDepositRateModal />

      <WSGrid gutter="XL">
        <WSGrid.Item span={{ m: "7", l: "9" }}>
          <PendingState isVerified={isVerified} />
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "5", l: "3" }}>
          <WSList gap="2XL">
            {featureFlags?.cashback2025 && (
              <WSElement>
                <WSSectionToolbar
                  title="Virtual debit card"
                  button={
                    isVerified
                      ? {
                          label: "Get started",
                          rightIcon: "chevron-right",
                          onClick: () =>
                            history.push("/member/activate-banking")
                        }
                      : undefined
                  }
                />

                <WSElement className={styles.virtualCardPreview} />
              </WSElement>
            )}

            <PayoutSplits
              onEditWallet={() => openModal(UPDATE_DEPOSIT_RATE_MODAL)}
            />
            <WSDivider />

            {/*<WSButton.Link rightIcon="chevron-right">Search for ATMs</WSButton.Link>*/}
            {featureFlags?.cashback2025 ? <CashbackProgramWidget /> : null}
            <WingspanAccounts accountType={InternalAccountType.Banking} />
          </WSList>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
