import React from "react";
import { WSButton, WSButtonProps } from "@wingspanhq/fe-component-library";
import { WSFrontendFeature } from "../../Settings/utils/subscriptionUtils";

export const PERSISTENT_UPGRADE_MODAL = "PERSISTENT_UPGRADE_MODAL";

export interface WSPersistentUpgradeButtonProps extends WSButtonProps {
  feature?: WSFrontendFeature;
}

export const WSPersistentUpgradeButton: React.FC<
  WSPersistentUpgradeButtonProps
> = props => {
  const {
    onClick,
    feature = WSFrontendFeature.None,
    ...wsButtonProps
  }: WSPersistentUpgradeButtonProps = props;

  return <WSButton onClick={onClick} {...wsButtonProps} />;
};
