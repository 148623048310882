import {
  WSDivider,
  WSSidebar,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSPanel,
  WSPill,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { DeductionType } from "@wingspanhq/payments/dist/interfaces/deductions";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import { WSQueries } from "../../../query/WSQuery";
import { useCollaboratorDeduction } from "../../../query/payments/queries";
import { goBackOrToParent } from "../../../utils/goToParentRoute";
import {
  DeductionsHistoryList,
  PayableLink
} from "../../components/DeductionsHistoryList/DeductionsHistoryList";
import styles from "./styles.module.scss";

export const CompletedDeductionDetails: React.FC<
  RouteComponentProps<{
    deductionId: string;
  }>
> = ({ match, location }) => {
  const history = useHistory();
  const qDeduction = useCollaboratorDeduction(match.params.deductionId, {});
  const onClose = () => goBackOrToParent(history);

  return (
    <WSQueries
      queries={{ qDeduction }}
      renderErrors={() => null}
      renderLoader={() => (
        <>
          <WSScreen.Mobile>
            <OverlaySpinner />
          </WSScreen.Mobile>
          <WSScreen.TabletAndDesktop>
            <WSSidebar onClose={onClose} noCloseIcon>
              <WSElement className={styles.main}>
                <OverlaySpinner />
              </WSElement>
            </WSSidebar>
          </WSScreen.TabletAndDesktop>
        </>
      )}
    >
      {({ qDeduction: { data: deduction } }) => (
        <>
          <WSScreen.Mobile>
            <WSLayout
              title="Completed deduction details"
              onBack={onClose}
              line
              headerClassName={styles.header}
            >
              <WSElement className={styles.main}>
                <Inner data={deduction} />
              </WSElement>
            </WSLayout>
          </WSScreen.Mobile>
          <WSScreen.TabletAndDesktop>
            <WSSidebar onClose={onClose} noCloseIcon>
              <WSLayout
                title="Completed deduction details"
                onClose={onClose}
                line
                smallHeader
              >
                <WSElement className={styles.main}>
                  <Inner data={deduction} />
                </WSElement>
              </WSLayout>
            </WSSidebar>
          </WSScreen.TabletAndDesktop>
        </>
      )}
    </WSQueries>
  );
};

export const Inner: React.FC<{ data: IDeductionResponse }> = ({ data }) => {
  const history = useHistory();

  const deductedAmount = data.application
    .map(a => a.amountDeducted)
    .reduce((a, b) => a + b, 0);

  const payableId = data.application.find(a => a.payableId)?.payableId;

  return (
    <>
      <WSPanel noBorder mb="XL" data-testid="deductionsPanel">
        <WSFlexBox.CenterY
          mt="M"
          mb="XL"
          justify="space-between"
          alignItems="flex-end"
        >
          <WSText.Heading5 formatMoney>{data.amount}</WSText.Heading5>
          <WSFlexBox.CenterY>
            <WSText.ParagraphSm color="green500">Complete</WSText.ParagraphSm>
            <WSIcon block color="green500" size="XS" ml="XS" name="check" />
          </WSFlexBox.CenterY>
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText weight="medium">{data.name}</WSText>
          <WSPill
            icon={data.type === DeductionType.PostPayment ? "after" : "before"}
            text={
              data.type === DeductionType.PostPayment
                ? "Post-payment"
                : "Pre-payment"
            }
          />
        </WSFlexBox.CenterY>
        <WSDivider />
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText.ParagraphSm>Payable number</WSText.ParagraphSm>
          {payableId ? (
            <PayableLink payableId={payableId} />
          ) : (
            <WSText.ParagraphSm>-</WSText.ParagraphSm>
          )}
        </WSFlexBox.CenterY>
        <WSDivider />
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText weight="medium">Total deduction amount</WSText>
          <WSText weight="medium" formatMoney>
            {deductedAmount}
          </WSText>
        </WSFlexBox.CenterY>

        <DeductionsHistoryList deduction={data} />
      </WSPanel>
    </>
  );
};
