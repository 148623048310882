import { WSQueryConfig } from "@ws-react-query";

import { useWSQuery } from "../../helpers";
import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { QUERY_PAYER_ENGAGEMENT } from "../keys";
import { getPayerEngagement } from "../../../services/payerEngagements";

export const useGetPayerEngagementQuery = (
  payerId: string,
  engagementId: string,
  queryConfig?: WSQueryConfig<IPayerEngagementResponse, unknown>
) =>
  useWSQuery(
    [QUERY_PAYER_ENGAGEMENT, engagementId],
    () => getPayerEngagement(payerId, engagementId),
    {
      retry: false,
      ...queryConfig
    }
  );
