import {
  toWSDateString,
  useModalOldContext,
  WSButton,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IClientInvoice,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";
import { formatMoney } from "accounting";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import {
  useClientInvoiceFeesQuery,
  useClientInvoiceQuery
} from "../../query/payments/queries";
import {
  getClientInvoiceMemberName,
  getClientName
} from "../../query/payments/selectors";
import { useSession } from "../../query/session";
import { QUERY_USERS_CLIENT } from "../../query/users/keys";
import { getRedactedMemberName } from "../../query/users/selectors";
import { updatePayee } from "../../services/payees";
import { paymentsService } from "../../services/payments";
import { usersService } from "../../services/users";
import { VerticalDivider } from "../../shared/components/VerticalDivider";
import { usePaymentCardForm } from "../../shared/hooks/usePaymentCardForm";
import { selectorClientCreditCardFee } from "../../shared/selectors/selectorClientCreditCardFee";
import { useWSStore } from "../../store";
import { WSServiceError } from "../../utils/serviceHelper";
import { AutopayCard } from "../components/AutopayCard";
import { Card } from "../components/Card/Card";
import { ClientInvoiceDetailsDrawer } from "../components/ClientInvoiceDetailsDrawer/ClientInvoiceDetailsDrawer";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { CLIENT_SIGN_IN_MODAL_KEY } from "../components/ClientSignInModal/ClientSignInModal";
import { DonwloadPdfButton } from "../components/DownloadPdfButton/DonwloadPdfButton";
import { InfoRow } from "../components/InfoRow/InfoRow";
import { PrivacyPolicy } from "../components/PrivacyPolicy/PrivacyPolicy";
import { RemoveCreditCardModal } from "../components/RemoveCreditCardModal/RemoveCreditCardModal";
import { SelectPaymentCard } from "../components/SelectPaymentCard";
import { SelectPaymentCardGuest } from "../components/SelectPaymentCardGuest";
import {
  getIsAutopayRequired,
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const ClientPaymentsInvoicePaymentCreditCardFootprint: React.FC<
  Props
> = ({ match }) => {
  const invoiceId = match.params.invoiceId;
  const history = useHistory();
  const clientInvoiceFeesQuery = useClientInvoiceFeesQuery(invoiceId);
  const creditCardFee = clientInvoiceFeesQuery.data?.creditFeeTotal || 0;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const sessionQuery = useSession({ refetchOnWindowFocus: false });
  const userId = useUserId();
  const { openModal } = useModalOldContext();
  const [selectedPaymentCardId, setSelectedPaymentCardId] = useState("");
  const isGuest = sessionQuery.data?.sessionType !== SessionType.user;
  const [autopay, setAutopay] = useState<"enable" | "disable">("disable");
  const [canNotBePaid, setCanNotBePaid] = useState(false);
  const [tryingToPayOwnInvoice, setTryingToPayOwnInvoice] = useState(false);

  const [payInvoice, payInvoiceMeta] = useWSMutation<
    void,
    WSServiceError,
    {
      clientInvoice: IClientInvoice;
    }
  >(
    async ({ clientInvoice }) => {
      if (clientInvoice.memberClient.memberId === userId) {
        throw new Error("You cannot pay your own invoices");
      }

      if (!selectedPaymentCardId) {
        throw new Error("No payment card selected");
      }

      await paymentsService.client.invoice.pay(invoiceId, {
        paymentMethodId: selectedPaymentCardId
      });

      try {
        if (autopay === "enable" && clientInvoice.invoiceTemplateId) {
          await usersService.client.update(userId, {
            clientId: userId,
            profile: {
              defaultPaymentMethod: {
                paymentMethodId: selectedPaymentCardId
              }
            }
          });

          await updatePayee(clientInvoice.memberClient.memberId, {
            payerOwnedData: {
              autoPayStrategy: PaymentRequirementStrategy.All
            }
          });

          await paymentsService.client.invoiceTemplate.update(
            clientInvoice.invoiceTemplateId,
            {
              clientId: userId,
              paymentMethodId: selectedPaymentCardId
            }
          );
        }
      } catch (error) {
        console.error("Failed while setting autopay", error);
      }
    },
    {
      onSuccess: () => {
        history.push(`/payment/${invoiceId}/success`);
      },
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

  useEffect(() => {
    if (sessionQuery.data?.sessionType === SessionType.user) {
      setAutopay("enable");
    }
  }, [sessionQuery.data]);

  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId);

  useEffect(() => {
    if (
      clientInvoiceQuery.isFetched &&
      clientInvoiceQuery.data &&
      getIsAutopayRequired(clientInvoiceQuery.data)
    ) {
      setAutopay("enable");
    }
  }, [clientInvoiceQuery.isFetched]);

  const store = useWSStore();

  const paymentCardForm = usePaymentCardForm();

  return (
    <>
      <ClientInvoiceWrapper invoiceId={invoiceId}>
        {clientInvoice => {
          const allowAutopay = !!clientInvoice.invoiceTemplateId;

          const submitSection = (
            <>
              <WSButton
                fullWidth
                icon="lock"
                mt="2XL"
                onClick={async () => {
                  if (clientInvoice.memberClient.memberId === userId) {
                    setTryingToPayOwnInvoice(true);
                    return;
                  }

                  if (!clientInvoice.memberAcceptsPayments) {
                    setCanNotBePaid(true);
                    return;
                  }

                  payInvoice({
                    clientInvoice
                  });
                }}
                loading={payInvoiceMeta.isLoading}
                name="pay"
              >
                Confirm payment
              </WSButton>

              {userId && clientInvoice.memberClient.memberId === userId && (
                <WSText.ParagraphSm mt="XL" color="gray500">
                  You are the link owner. This is what your customers will see.
                </WSText.ParagraphSm>
              )}

              {canNotBePaid && (
                <WSText.ParagraphSm color="garnet" mt="XL">
                  {clientInvoice.memberCompany ||
                    getClientInvoiceMemberName(clientInvoice)}{" "}
                  does not currently accept digital payments. Please contact{" "}
                  <a href="mailto:support@wingspan.app">support@wingspan.app</a>{" "}
                  for more details.
                </WSText.ParagraphSm>
              )}

              {tryingToPayOwnInvoice && (
                <WSText.ParagraphSm color="garnet" mt="XL">
                  You cannot pay your own invoices
                </WSText.ParagraphSm>
              )}

              <WSErrorMessage
                mt="XL"
                contextKey="PayInvoice"
                error={payInvoiceMeta.error}
              />
            </>
          );

          return isClientInvoicePaid(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}/success`} />
          ) : isClientInvoiceCancelled(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}`} />
          ) : (
            <ClientPaymentsWrapper
              clientInvoice={clientInvoice}
              companyName={clientInvoice.memberCompany}
              clientEmail={clientInvoice.memberClient.emailTo}
              memberName={getClientInvoiceMemberName(clientInvoice)}
              companyLogoUrl={clientInvoice.memberLogoUrl}
              onBack={() => {
                history.goBack();
              }}
            >
              {drawerVisible && (
                <ClientInvoiceDetailsDrawer
                  invoiceId={invoiceId}
                  onClose={() => {
                    setDrawerVisible(false);
                  }}
                  processingFee={creditCardFee}
                />
              )}

              <WSText.ParagraphSm>
                {getPaymentStepText(clientInvoice, "payment")}
              </WSText.ParagraphSm>
              <WSText.Heading4 mb="2XL">Review & pay</WSText.Heading4>

              {isGuest ? (
                <SelectPaymentCardGuest
                  mb="M"
                  clientId={clientInvoice.memberClient.clientId}
                  requireSignIn={getIsAutopayRequired(clientInvoice)}
                  onSignIn={() => {
                    openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                      signUp: true,
                      clientEmailToPrefill: clientInvoice.memberClient.emailTo,
                      onSuccess: () => {
                        setSavePaymentMethod(true);
                      },
                      memberId: clientInvoice.memberClient.memberId,
                      member: clientInvoice.memberClient.member
                    });
                  }}
                  value={selectedPaymentCardId}
                  onChange={setSelectedPaymentCardId}
                />
              ) : (
                <SelectPaymentCard
                  mb="M"
                  value={selectedPaymentCardId}
                  onChange={setSelectedPaymentCardId}
                />
              )}

              <Card mb="M">
                <WSText.Heading5 mb="XS">Payment timing</WSText.Heading5>
                <WSText mb="XS">Within 2 business days</WSText>
                <WSText.ParagraphSm color="gray500">
                  Estimated arrival:{" "}
                  {toWSDateString(
                    wsMoment(new Date()).businessAdd(2).toDate(),
                    "monthDayYear"
                  )}
                </WSText.ParagraphSm>
              </Card>

              <Card mb={allowAutopay ? "M" : "3XL"}>
                <WSFlexBox justify="space-between" mb="XS">
                  <WSText.ParagraphSm>Total</WSText.ParagraphSm>
                  <WSFlexBox.CenterY>
                    <WSButton.Link
                      onClick={() => {
                        setDrawerVisible(true);
                      }}
                    >
                      View
                    </WSButton.Link>
                    {clientInvoice.attachments?.invoicePdf && (
                      <>
                        <VerticalDivider mx="M" my="XL" />

                        <DonwloadPdfButton clientInvoice={clientInvoice} />
                      </>
                    )}
                  </WSFlexBox.CenterY>
                </WSFlexBox>
                <WSText.Heading1 weight="book" mb="XS">
                  {formatMoney(clientInvoice.amount + creditCardFee)}
                </WSText.Heading1>
                <WSText.ParagraphSm color="gray500" mb="2XL">
                  {formatMoney(clientInvoice.amount)}
                  {creditCardFee > 0 &&
                    ` + ${selectorClientCreditCardFee(
                      clientInvoice.creditFeeHandling
                    )} processing fee`}
                </WSText.ParagraphSm>

                <InfoRow
                  label={store.createdWithPPL ? "From" : "To"}
                  value={getClientName(clientInvoice.memberClient)}
                  mb="M"
                />
                <InfoRow
                  label={store.createdWithPPL ? "To" : "From"}
                  value={getRedactedMemberName(
                    clientInvoice.memberClient.member
                  )}
                  mb="M"
                />
                {clientInvoice.invoiceNotes && (
                  <InfoRow
                    label="Note"
                    value={clientInvoice.invoiceNotes}
                    mb="M"
                  />
                )}

                {!allowAutopay && submitSection}
              </Card>

              {allowAutopay && (
                <AutopayCard
                  mb="XL"
                  clientInvoice={clientInvoice}
                  agreementValue={autopay === "enable"}
                  onAgreemenChange={newValue => {
                    if (newValue) {
                      if (isGuest) {
                        openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                          signUp: true,
                          clientEmailToPrefill:
                            clientInvoice.memberClient.emailTo,
                          onSuccess: () => {
                            setAutopay("enable");
                          },
                          memberId: clientInvoice.memberClient.memberId,
                          member: clientInvoice.memberClient.member
                        });
                      } else {
                        setAutopay("enable");
                      }
                    } else {
                      setAutopay("disable");
                    }
                  }}
                  submitSection={submitSection}
                />
              )}

              <CertifiedSecureDigitalPayments mb="M" />
              <PrivacyPolicy />
            </ClientPaymentsWrapper>
          );
        }}
      </ClientInvoiceWrapper>

      <RemoveCreditCardModal />
    </>
  );
};
