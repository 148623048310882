import { WSAlertProps } from "@wingspanhq/fe-component-library";
import { EntityType } from "../../modules/Onboarding/types";
import { components } from "../../services/api/onboarding/types";
import { INTERNATIONAL_TAX_ID_VALIDATIONS } from "../constants/taxId";
import { selectorCountryName } from "../selectors/selectorCountryName";

export const getTaxIdLabel = (type: EntityType, country: string) => {
  if (type === "Business") {
    if (country === "US") {
      return "Employer Identification Number (EIN)";
    }

    if (country === "CA") {
      return "Business Number (BN)";
    }
  }

  if (type === "Individual") {
    if (country === "US") {
      return "Social Security Number (SSN)";
    }

    if (country === "CA") {
      return "Social Insurance Number (SIN)";
    }
  }

  const invernationalValidation =
    INTERNATIONAL_TAX_ID_VALIDATIONS[country]?.[type];

  if (invernationalValidation) {
    return invernationalValidation.name;
  }

  return country ? selectorCountryName(country) + " Tax ID" : "Tax ID";
};

export const getTaxIdMask = (type: EntityType, country: string) => {
  if (type === "Business") {
    if (country === "US") {
      return "99-9999999"; // EIN format
    }

    if (country === "CA") {
      return "999999999"; // BN format
    }
  }

  if (type === "Individual") {
    if (country === "US") {
      return "999-99-9999"; // SSN format
    }

    if (country === "CA") {
      return "999-999-999"; // SIN format
    }
  }
};

export const getTaxIdPlaceholder = (type: EntityType, country: string) => {
  if (country === "US" || country === "CA") {
    return getTaxIdMask(type, country);
  }

  const invernationalValidation =
    INTERNATIONAL_TAX_ID_VALIDATIONS[country]?.[type];

  if (invernationalValidation) {
    return invernationalValidation.placeholder;
  }

  return "999-9999999";
};

export const getTaxIdStatusLabel = (
  country: string,
  status: components["schemas"]["VerificationStatus"]
) => {
  switch (status) {
    case "Verified": {
      if (country === "US") {
        return "IRS Verified";
      }

      return "Format matched";
    }

    case "Failed": {
      if (country === "US") {
        return "Mismatch";
      }

      return "Format mismatch";
    }

    default:
      return "Pending";
  }
};

export const getTaxIdVerificationAlert = (
  country: string,
  status?: components["schemas"]["VerificationStatus"]
): WSAlertProps | undefined => {
  switch (status) {
    case "Verified":
      return {
        theme: "success",
        icon: "check-circle",
        title: getTaxIdStatusLabel(country, status)
      };
    case "Pending":
      return {
        theme: "info",
        icon: "time",
        title: getTaxIdStatusLabel(country, status)
      };
    case "Failed":
      return {
        theme: "warning",
        icon: "alert",
        title: getTaxIdStatusLabel(country, status)
      };
    default:
      return undefined;
  }
};
