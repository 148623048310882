import {
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSInputMaskOld,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import * as Yup from "yup";

import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../../../query/payments/mutations";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { useUpdateActivity } from "../../../../query/users/mutations";
import {
  useActivities,
  useMemberProfile
} from "../../../../query/users/queries";
import { get1099SetupProgress } from "../../../../query/users/selectors";
import { WSQueries } from "../../../../query/WSQuery";
import { ErrorContextKey } from "../../../../services/platform";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import getCurrentFilingYear from "../../../../utils/getCurrentFilingYear";
import {
  useCreateBulk1099SubmitBatchItem,
  useCreateBulkCalculate1099Batch,
  useUpdateBulkCalculate1099Batch
} from "../../../BulkImporter/query/bulkCalculation1099/mutations";
import {
  IStateTaxId,
  stateTaxIdMapToArray,
  TAX_ID_REQUIRED_STATES
} from "../../utils/stateTaxIdMapToArray";
import styles from "./styles.module.scss";

const StateTaxIdField: React.FC = () => {
  const { control } = useFormContext();
  const {
    fields: stateTaxIds,
    append,
    remove
  } = useFieldArray<IStateTaxId>({
    name: "stateTaxIds",
    control
  });

  const onAddState = () => {
    append({
      state: "",
      taxId: ""
    });
  };

  const onRemoveState = (index: number) => {
    remove(index);
  };

  return (
    <>
      {stateTaxIds.map((stateTaxId, index) => {
        return (
          <WSGrid className={styles.stateTaxIdField} key={stateTaxId.id}>
            <WSFlexBox.Center justify="space-between">
              <WSGrid.Item span={{ xs: "5" }}>
                <WSFormOld.Field
                  defaultValue={stateTaxId.state}
                  name={`stateTaxIds[${index}].state`}
                  component={WSSelectOld}
                  componentProps={{
                    options: TAX_ID_REQUIRED_STATES,
                    placeholder: "Select",
                    searchable: true,
                    cleanable: true
                  }}
                  label="State"
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "5" }}>
                <WSFormOld.Field
                  defaultValue={stateTaxId.taxId}
                  label="Tax ID number"
                  name={`stateTaxIds[${index}].taxId`}
                  component={WSInputMaskOld}
                  componentProps={{
                    mask: "99-9999999",
                    unmask: true,
                    fsExclude: true
                  }}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "1" }}>
                <WSIcon
                  block
                  mt="XL"
                  name="minus-circle"
                  onClick={() => onRemoveState(index)}
                  color="blue400"
                />
              </WSGrid.Item>
            </WSFlexBox.Center>
          </WSGrid>
        );
      })}
      <WSButton.Link
        type="button"
        icon="plus-circle"
        onClick={onAddState}
        name="addState"
      >
        Add state
      </WSButton.Link>
    </>
  );
};

interface StateFilingFormValues {
  stateTaxIds: IStateTaxId[];
}

export const FormStateFiling: React.FC<
  SetupRouteComponentProps & {
    isEdit?: boolean;
  }
> = ({ onBack, onNext, isEdit }) => {
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();

  const qActivity = useActivities(userId);
  const qMemberProfile = useMemberProfile(userId);
  const qPayrollSettings = usePayrollSettings(userId);

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [updatePayrollSettings, updatePayrollSettingsMeta] =
    useUpdatePayrollSettings(userId);
  const [createBulkCalculate1099Batch, createBulkCalculate1099BatchMeta] =
    useCreateBulkCalculate1099Batch();
  const [createBulk1099SubmitBatchItem, createBulk1099SubmitBatchItemMeta] =
    useCreateBulk1099SubmitBatchItem();

  const [updateBulkCalculate1099Batch, updateBulkCalculate1099BatchMeta] =
    useUpdateBulkCalculate1099Batch();

  const initBulkCalculate1099Batch = async () => {
    const response = await createBulkCalculate1099Batch({});
    if (response?.bulkCalculation1099BatchId) {
      const batchItemsRequest: IBulkCalculation1099ItemCreate = {
        clientId: userId,
        year: getCurrentFilingYear(),
        calculationType: BulkCalculation1099Type.Balances
      };

      await createBulk1099SubmitBatchItem(
        {
          batchId: response?.bulkCalculation1099BatchId,
          ...batchItemsRequest
        },
        {
          onSuccess: async () => {
            await updateBulkCalculate1099Batch(
              {
                id: response?.bulkCalculation1099BatchId,
                data: {
                  status: BulkStatus.Pending
                }
              },
              {
                onSuccess: () => {
                  onNext({
                    bulkCalculation1099BatchId:
                      response?.bulkCalculation1099BatchId
                  });
                }
              }
            );
          }
        }
      );
    } else {
      openSnackbar({
        type: "warning",
        icon: {
          name: "alert-circle",
          color: "red500"
        },
        message: "Could not initiate generate 1099 forms"
      });
    }
  };

  const initBulkProcess = async () => {
    const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
    await updateActivity({
      flows: {
        nec1099Setup: {
          ...nec1099SetupProgress,
          complete: true
        }
      }
    });
    await initBulkCalculate1099Batch();
  };

  const onFormSubmit = async (data: { stateTaxIds: IStateTaxId[] }) => {
    const stateTaxIdMap = (data.stateTaxIds || []).reduce((acc: any, item) => {
      if (item.state && item.taxId) {
        acc[item.state] = item.taxId;
      }
      return acc;
    }, {});
    await updatePayrollSettings({
      calculationSettings1099: {
        stateTaxId: stateTaxIdMap
      }
    });
    if (isEdit) {
      onNext();
    } else {
      await initBulkProcess();
    }
  };

  return (
    <WSQueries queries={{ qMemberProfile, qPayrollSettings }}>
      {({
        qMemberProfile: { data: member },
        qPayrollSettings: { data: payrollSettings }
      }) => {
        const { address } = member.profile;
        const userStateTaxIds = stateTaxIdMapToArray(
          payrollSettings.calculationSettings1099?.stateTaxId ?? {}
        );
        let defaultStateTaxIds = [{ state: "", taxId: "" }];
        if (userStateTaxIds.length > 0) {
          defaultStateTaxIds = userStateTaxIds;
        } else if (
          TAX_ID_REQUIRED_STATES.map(state => state.value).includes(
            address?.state ?? ""
          )
        ) {
          defaultStateTaxIds = [{ state: address?.state ?? "", taxId: "" }];
        }
        const defaultValues = {
          stateTaxIds: defaultStateTaxIds
        };
        return (
          <>
            <WSText.Heading4 mb="XL">State filing settings</WSText.Heading4>
            <WSText color="gray600" mb="XL">
              Some states require a state Tax ID number to file 1099 at the
              state level. If you would like to submit any 1099 for state
              filing, add the applicable states and withholding or registration
              ID numbers below.
            </WSText>

            <WSFormOld<StateFilingFormValues>
              mb="XL"
              validationSchema={Yup.object().shape({
                stateTaxIds: Yup.array().of(
                  Yup.object().shape({
                    state: Yup.string(),
                    taxId: Yup.string().when("state", (state?: string) => {
                      if (state) {
                        return Yup.string().required("Required");
                      }
                      return Yup.string();
                    })
                  })
                )
              })}
              onSubmit={onFormSubmit}
              defaultValues={defaultValues}
            >
              <StateTaxIdField />

              <WSErrorMessage
                mt="XL"
                contextKey={ErrorContextKey.NEC1099BulkCalculate1099}
                error={
                  updatePayrollSettingsMeta.error ||
                  createBulkCalculate1099BatchMeta.error ||
                  createBulk1099SubmitBatchItemMeta.error ||
                  updateBulkCalculate1099BatchMeta.error
                }
              />
              <WSButtons mt="3XL" mb="XL">
                <WSButton.Secondary
                  className={styles.actionBtn}
                  onClick={onBack}
                >
                  {isEdit ? "Cancel" : "Back"}
                </WSButton.Secondary>
                <WSFormOld.SubmitButton
                  className={styles.actionBtn}
                  data-testid="confirmPayerInfo"
                  loading={
                    updatePayrollSettingsMeta.isLoading ||
                    updateActivityMeta.isLoading ||
                    createBulkCalculate1099BatchMeta.isLoading ||
                    createBulk1099SubmitBatchItemMeta.isLoading ||
                    updateBulkCalculate1099BatchMeta.isLoading
                  }
                >
                  {isEdit ? "Save" : "Continue"}
                </WSFormOld.SubmitButton>
              </WSButtons>

              {!isEdit && (
                <>
                  <WSText.ParagraphSm color="gray500" mb="XL">
                    This is the last step before we generate your 1099 forms. If
                    you skip this step, you will not be able to revisit it
                    without regenerating all 1099 forms. To start over and
                    generate new forms, contact support@wingspan.app.
                  </WSText.ParagraphSm>

                  <WSFlexBox.Center>
                    <WSButton.Link onClick={initBulkProcess} type="button">
                      Skip
                    </WSButton.Link>
                  </WSFlexBox.Center>
                </>
              )}
            </WSFormOld>
          </>
        );
      }}
    </WSQueries>
  );
};
