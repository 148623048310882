import { useEffect, useState } from "react";

export * from "./dates";
export * from "./formatters";
export * from "./toCamelCase";
export * from "./validators";

export const useResize = (myRef: React.RefObject<any>) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth || 0,
      height: myRef.current?.offsetHeight || 0
    });

    let animationId: number;

    const handleResize = () => {
      const newDimensions = getDimensions();

      if (
        newDimensions.height !== dimensions.height ||
        newDimensions.width !== dimensions.width
      ) {
        setDimensions(newDimensions);
      }

      animationId = window.requestAnimationFrame(handleResize);
    };

    animationId = window.requestAnimationFrame(handleResize);

    return () => {
      window.cancelAnimationFrame(animationId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  return dimensions;
};
