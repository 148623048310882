import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect, useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_USERS_ACCOUNTS } from "../../query/users/keys";
import { useAccounts } from "../../query/users/queries";
import { WSQuery } from "../../query/WSQuery";
import { usersService } from "../../services/users";
import { useWSPlaidLink } from "../../utils/usePlaidLink";

type PlaidReAuthChildren = {
  onOpen: () => void;
  status: boolean;
  loading?: boolean;
};

export type PlaidReAuthProps = {
  onReAuth?(): any;
  accountId: string;
  children(props: PlaidReAuthChildren): React.ReactNode;
};

export type PlaidLinkWrapperProps = {
  onReAuth(): any;
  token: string;
  children(props: PlaidReAuthChildren): React.ReactNode;
};

const PlaidLinkWrapper: React.FC<PlaidLinkWrapperProps> = ({
  token,
  children,
  onReAuth
}) => {
  const [loading, setLoading] = useState(false);
  const plaidLink = useWSPlaidLink({
    token: token,
    onSuccess: async () => {
      setLoading(true);
      await onReAuth();
      setLoading(false);
    }
  });

  useEffect(() => () => plaidLink.exit(), []);

  return (
    <>{children({ onOpen: () => plaidLink.open(), status: true, loading })}</>
  );
};

export const PlaidReAuth: React.FC<PlaidReAuthProps> = ({
  children,
  accountId,
  onReAuth = () => Promise.resolve()
}) => {
  const qAccounts = useAccounts({ retry: false });
  return (
    <WSQuery
      query={qAccounts}
      renderLoader={() => children({ onOpen: () => null, status: false })}
      renderError={() => children({ onOpen: () => null, status: false })}
    >
      {request => {
        const account = request.data.find(a => a.accountId === accountId);

        return account?.status === AccountStatus.Disconnected &&
          account?.publicToken ? (
          <>
            <PlaidLinkWrapper
              key={account.accountId}
              token={account.publicToken}
              onReAuth={async () => {
                for (const qAccount of request.data) {
                  await usersService.account.update(qAccount.accountId, {});
                }
                await WSQueryCache.refetchQueries(QUERY_USERS_ACCOUNTS);
                await onReAuth();
              }}
            >
              {children}
            </PlaidLinkWrapper>
          </>
        ) : (
          <>{children({ onOpen: () => null, status: false })}</>
        );
      }}
    </WSQuery>
  );
};

PlaidReAuth.displayName = "PlaidReAuth";
