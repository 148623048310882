import React from "react";
import { WSQueries } from "../../../../query/WSQuery";
import {
  PayeeRowsQuery,
  PayeeRowsQueryFilter,
  PayerPayeeEngagementSearchableStatus
} from "../../../../services/search";
import { useUrlQueryFilters } from "../../../../utils/router";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import {
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { toWSMoneyString, WSInfoBox } from "@wingspanhq/fe-component-library";
import { useBackgroundChecksSettingsQuery } from "../../../../query/backgroundChecks/queries/useBackgroundChecksSettingsQuery";

export type AddRequirementInformationProps = {
  requirementDefinition?: IRequirementDefinitionResponse;
  engagementId: string;
};

export const AddRequirementInformation: React.FC<
  AddRequirementInformationProps
> = ({ engagementId, requirementDefinition }) => {
  const filter: PayeeRowsQueryFilter = {
    searchableStatus: {
      "!=": PayerPayeeEngagementSearchableStatus.Inactive
    },
    "engagements.engagementId": engagementId
  };
  const { filters } = useUrlQueryFilters<PayeeRowsQuery>({
    filter: filter,
    sort: {
      updatedAt: "desc"
    }
  });

  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery(filters, {
    enabled:
      requirementDefinition?.requirementType === RequirementType.BackgroundCheck
  });

  const queryBackgroundChecksSettings = useBackgroundChecksSettingsQuery(
    requirementDefinition?.dataSourceId!,
    {
      enabled:
        !!requirementDefinition?.dataSourceId &&
        requirementDefinition?.requirementType ===
          RequirementType.BackgroundCheck
    }
  );

  const defaultView = (
    <WSInfoBox
      title="Requirement added will impact your existing contractors"
      p="L"
    >
      Your existing contractors will be notified of the requirement added to
      this engagement. The requirement will also apply to any future contractors
      added to this engagement.
    </WSInfoBox>
  );

  if (
    requirementDefinition?.requirementType !== RequirementType.BackgroundCheck
  ) {
    return defaultView;
  }

  return (
    <WSQueries
      renderErrors={() => defaultView}
      queries={{ queryPayeeRowsSummary, queryBackgroundChecksSettings }}
    >
      {({ queryPayeeRowsSummaryData, queryBackgroundChecksSettingsData }) => {
        return (
          <>
            <WSInfoBox
              title={`Requirement added will impact ${queryPayeeRowsSummaryData.listSize} existing contractors`}
              p="L"
            >
              {queryPayeeRowsSummaryData.listSize} existing contractors will be
              notified of the requirement added to this engagement. The
              requirement will also apply to any future contractors added to
              this engagement.
            </WSInfoBox>

            <WSInfoBox title="Background check package" p="L">
              The selected background check package is the Wingspan Standard
              package and will cost{" "}
              {toWSMoneyString(
                queryBackgroundChecksSettingsData.package?.costPerCheck
              )}{" "}
              per check.
            </WSInfoBox>
          </>
        );
      }}
    </WSQueries>
  );
};
