import {
  WSEmptyState,
  WSFilters,
  WSInfiniteScroll,
  WSPage
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import { PayeeRowsQuery } from "../../../../services/search";
import { useUrlQueryFilters } from "../../../../utils/router";
import { SearchPanel } from "../../../Invoicing/components/SearchPanel";
import { PayeeRowsTable } from "../../components/PayeeRowsTable";
import { PAYEES_ROOT_PATH } from "../../utils";

const DEFAULT_FILTERS: PayeeRowsQuery = {
  filter: {}
};

export const RoutePayeesSearch: React.FC = () => {
  const history = useHistory();

  const { filters, setFilters, changedFilters } =
    useUrlQueryFilters<PayeeRowsQuery>(DEFAULT_FILTERS);

  const queryPayeeRows = usePayeeRowsQuery(filters);
  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery(filters);

  // const { downloadCsv, isLoading } = useDownloadCsv();

  return (
    <WSPage>
      <SearchPanel
        mb="L"
        context="payees"
        onBack={() => {
          history.push(PAYEES_ROOT_PATH);
        }}
        value={filters.filter?.searchString || ""}
        onSearch={newValue => {
          setFilters({
            ...filters,
            filter: {
              searchString: newValue
            }
          });
        }}
        onClear={() => {
          setFilters({
            filter: {}
          });
        }}
        placeholder="Search contractors"
      />

      {!!filters.filter?.searchString && (
        <>
          {Object.keys(changedFilters).length &&
          queryPayeeRows.data?.length === 0 ? (
            <WSEmptyState
              py="3XL"
              fullWidth
              hasBorder
              bgColor="gray"
              type="search"
              title="No results found"
              description="Try adjusting your search or filter to find what you’re looking for."
            />
          ) : (
            <>
              <WSFilters
                my="L"
                values={{}}
                onFilter={() => {}}
                info={{
                  count: queryPayeeRowsSummary.data?.listSize ?? 0
                }}
              />
              <WSInfiniteScroll
                mt="M"
                onLoad={() => {
                  queryPayeeRows.fetchNextPage();
                }}
                loadMore={(queryPayeeRows.data ?? [])?.length > 0}
                endOfList={!queryPayeeRows.hasNextPage}
                loading={!!queryPayeeRows.isFetchingNextPage}
              >
                <PayeeRowsTable
                  payees={queryPayeeRows.data ?? []}
                  filters={filters}
                  loading={queryPayeeRows.isInitialLoading}
                />
              </WSInfiniteScroll>
            </>
          )}
        </>
      )}
    </WSPage>
  );
};
