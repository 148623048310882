import {
  IMemberFileResponse,
  IMemberFileSummaryResponse,
  IMemberPublicFileResponse,
  ITemplate
} from "@wingspanhq/files/dist/lib/interfaces";
import { WSQueryConfig } from "@ws-react-query";
import { filesService, PrivateFilesQuery } from "../../services/files";
import { getAllEntries, WSServiceError } from "../../utils/serviceHelper";
import { useWSQuery } from "../helpers";
import {
  QUERY_FILE_MEMBER_PUBLIC_FILE,
  QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY,
  QUERY_FILES_MEMBER_PRIVATE_FILES,
  QUERY_FILES_MEMBER_PUBLIC_FILES,
  QUERY_TEMPLATE,
  QUERY_TEMPLATES
} from "./keys";

export const useMemberPublicFiles = (
  queryConfig?: WSQueryConfig<IMemberPublicFileResponse[], WSServiceError>
) =>
  useWSQuery<IMemberPublicFileResponse[], WSServiceError>(
    QUERY_FILES_MEMBER_PUBLIC_FILES,
    filesService.member.public.list,
    {
      ...queryConfig
    }
  );

export const useGetMemberPublicFile = (
  id: string,
  queryConfig?: WSQueryConfig<IMemberPublicFileResponse, WSServiceError>
) =>
  useWSQuery<IMemberPublicFileResponse, WSServiceError>(
    [QUERY_FILE_MEMBER_PUBLIC_FILE, id],
    () => filesService.member.public.get(id),
    {
      ...queryConfig
    }
  );

export const useMemberPublicFileSummaryQuery = (
  id: string,
  queryConfig?: WSQueryConfig<IMemberFileSummaryResponse, WSServiceError>
) =>
  useWSQuery<IMemberFileSummaryResponse, WSServiceError>(
    [QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY, id],
    () => filesService.member.public.summary(id),
    {
      ...queryConfig
    }
  );

export const useMemberPrivateFiles = (
  requestQuery?: PrivateFilesQuery,
  queryConfig?: WSQueryConfig<IMemberFileResponse[], WSServiceError>
) =>
  useWSQuery<IMemberFileResponse[], WSServiceError>(
    [QUERY_FILES_MEMBER_PRIVATE_FILES, requestQuery],
    () => filesService.member.private.list(requestQuery),
    {
      ...queryConfig
    }
  );

export const useMemberPrivateFileQuery = (
  fileId: string,
  queryConfig?: WSQueryConfig<IMemberFileResponse, WSServiceError>
) =>
  useWSQuery<IMemberFileResponse, WSServiceError>(
    QUERY_FILES_MEMBER_PRIVATE_FILES,
    () => filesService.member.private.get(fileId),
    {
      ...queryConfig
    }
  );

export const useTemplatesQuery = (
  config?: WSQueryConfig<ITemplate[], WSServiceError>
) => {
  return useWSQuery<ITemplate[], WSServiceError>(
    QUERY_TEMPLATES,
    () => getAllEntries(filesService.template.list),
    config
  );
};

export const useTemplateQuery = (
  id: string,
  queryConfig?: WSQueryConfig<ITemplate, WSServiceError>
) =>
  useWSQuery<ITemplate, WSServiceError>(
    [QUERY_TEMPLATE, id],
    () => filesService.template.get(id),
    {
      ...queryConfig
    }
  );
