import { useHistory } from "react-router-dom";
import queryString from "qs";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

export const useXWingspanPayerId = () => {
  const history = useHistory();
  const xWingspanExpansion = useXWingspanExpansion();
  const payerId = queryString.parse(history.location.search, {
    ignoreQueryPrefix: true
  }).payerId as string;
  const isPayeeDetailsRoute =
    history.location.pathname.startsWith("/member/payees/");

  return xWingspanExpansion && isPayeeDetailsRoute && payerId
    ? payerId
    : undefined;
};
