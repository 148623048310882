import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_USERS_ACCOUNT, QUERY_USERS_ACCOUNTS } from "../users/keys";

export function updateAccountCache(updatedAccount: IAccount) {
  const list = WSQueryCache.getQueryData<IAccount[]>(QUERY_USERS_ACCOUNTS);

  if (list) {
    WSQueryCache.setQueryData(
      QUERY_USERS_ACCOUNTS,
      list.map(account =>
        account.accountId === updatedAccount.accountId
          ? updatedAccount
          : account
      )
    );
  }

  WSQueryCache.setQueryData(
    [QUERY_USERS_ACCOUNT, updatedAccount.accountId],
    updatedAccount
  );
}
