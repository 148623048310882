import {
  useIsMobile,
  useModalOldContext,
  WSPill,
  WSButton,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSImage,
  WSPage,
  WSPageProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ISubscription,
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces";
import { ServiceState } from "@wingspanhq/users/dist/lib/interfaces/enabledServices";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import {
  useBenefitsEnrollment,
  useBenefitsService,
  useUpdateBenefitsService
} from "../../query/benefits";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import {
  useUpdateActivity,
  useUpdateBenefitsSetupActivity
} from "../../query/users/mutations";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { benefitsContactFormIsComplete } from "../../query/users/selectors";
import { WSQueries } from "../../query/WSQuery";
import { isPremiumSubscription } from "../../Settings/utils/subscriptionUtils";
import { useSetWSStore } from "../../store";
import { goBackOrToParent } from "../../utils/goToParentRoute";
import { openInNewTab } from "../../utils/openInNewTab";
import { BenefitBlockStatus } from "../components/BenefitBlock/BenefitBlock.module";
import {
  BENEFITS_PENDING_MODAL,
  BenefitsPending
} from "../modals/BenefitsPending";
import {
  BenefitCardStatus,
  BenefitData,
  BenefitsBadgeProps
} from "./BenefitsV3";
import { useCreateOrUpdateSubscriptionV3 } from "../../query/subscriptions/mutations";
import { setPathAfterSuccess } from "../utils";

const ua = navigator.userAgent.toLowerCase();
const isAndroid = ua.indexOf("android") > -1;
const isIOS = /ipad|iphone|ipod/.test(ua);

const myBenefitsLink = isAndroid
  ? "https://play.google.com/store/apps/details?id=com.newbenefits.mybenefitswork"
  : isIOS
  ? "https://apps.apple.com/us/app/my-benefits-work/id1364040226"
  : "https://mybenefitswork.com/login";

export const BenefitDetails: React.FC<
  BenefitData & {
    isFree?: boolean;
  }
> = props => {
  const { openModal } = useModalOldContext();
  const history = useHistory();
  const isMobile = useIsMobile();
  const memberId = useUserId();
  const qUserProfile = useUserProfile(memberId);
  const qMemberProfile = useMemberProfile(memberId);
  const qSubscription = useSubscriptionV3Query(memberId);
  const qService = useBenefitsService();
  const qEnrollment = useBenefitsEnrollment(memberId);
  const qFeatureFlags = useFeatureFlags();
  const setStore = useSetWSStore();

  const [updateActivity, updateActivityMeta] = useUpdateActivity(memberId);
  const [createOrUpdateSubscription, createOrUpdateSubscriptionMeta] =
    useCreateOrUpdateSubscriptionV3(memberId);

  const [updateBenefitsService, updateBenefitsServiceMeta] =
    useUpdateBenefitsService({ throwOnError: true });

  const [updateBenefitsSetupActivity] = useUpdateBenefitsSetupActivity();

  useBrowserPageTitle(`Benefits - ${props.title}`);

  return (
    <WSQueries
      queries={{
        userData: qUserProfile,
        memberData: qMemberProfile,
        serviceData: qService,
        enrollmentData: qEnrollment,
        featureFlags: qFeatureFlags
      }}
      renderLoader={() => <WSPage />}
    >
      {({
        userData,
        memberData,
        serviceData,
        enrollmentData,
        featureFlags
      }) => {
        const subscription = qSubscription.data as ISubscription;
        const isPremium = isPremiumSubscription(subscription);
        const memberNumber =
          enrollmentData.data?.wingspanBenefits?.memberNumber;
        const groupNumber = enrollmentData.data?.wingspanBenefits?.groupNumber;

        const user = userData.data;
        const member = memberData.data;

        const features = featureFlags.data;

        const isUserDataFilled = benefitsContactFormIsComplete(user, member);

        const isEnabled =
          serviceData.data.serviceState === ServiceState.Enabled;
        const isPending =
          serviceData.data.serviceState === ServiceState.Pending;

        const isNotReady = (!isEnabled && !isPending) || !isUserDataFilled;

        let primaryButton: WSPageProps["primaryButton"];
        let secondaryButton: WSPageProps["secondaryButton"];

        const primaryButtonProps = props.primaryButton?.(
          user,
          member,
          features,
          openModal
        );
        const secondaryButtonProps = props.secondaryButton?.(
          user,
          member,
          features,
          openModal
        );

        if (props.isFree) {
          if (secondaryButtonProps) {
            secondaryButton = {
              label: secondaryButtonProps.label,
              link: secondaryButtonProps.link,
              linkTarget: "_blank",
              onClick: secondaryButtonProps.onClick
            };
          }

          if (primaryButtonProps) {
            primaryButton = {
              label: primaryButtonProps.label,
              link: primaryButtonProps?.link,
              linkTarget:
                primaryButtonProps?.link?.indexOf("http") === 0
                  ? "_blank"
                  : undefined,
              onClick: primaryButtonProps.onClick
            };
          } else {
            primaryButton = {
              label: "Launch in Premium Perks Work",
              link: myBenefitsLink,
              linkTarget: "_blank"
            };
          }
        } else if (isNotReady) {
          primaryButton = {
            label: "Start free trial",
            loading:
              createOrUpdateSubscriptionMeta.isLoading ||
              updateActivityMeta.isLoading,
            onClick: async () => {
              await createOrUpdateSubscription({
                package: SubscriptionPackage.Benefits,
                packageTier: SubscriptionPackageTier.Basic
              });
              await updateActivity({
                flows: {
                  benefitsSetup: {
                    version: 2,
                    totalSteps: 4,
                    currentStep: 1,
                    complete: false
                  }
                }
              });
              setPathAfterSuccess(history.location.pathname);
              history.push("/member/benefits/set-up/2");
            }
          };
        } else if (
          (primaryButtonProps?.permanent && isUserDataFilled) ||
          (primaryButtonProps && props.skipActivation)
        ) {
          primaryButton = {
            label: primaryButtonProps.label,
            link: primaryButtonProps.link,
            linkTarget: "_blank",
            onClick: primaryButtonProps.onClick
          };
        } else if (!isPremium && props.status === BenefitBlockStatus.PREMIUM) {
          primaryButton = {
            label: "Upgrade membership",
            onClick: async () => {
              history.push("/member/membership-plans");
            }
          };
        } else if (isPending) {
          primaryButton = {
            label: "Pending",
            disabled: true
          };
        } else if (isEnabled) {
          if (secondaryButtonProps) {
            secondaryButton = {
              label: secondaryButtonProps.label,
              link: secondaryButtonProps.link,
              linkTarget: "_blank",
              onClick: secondaryButtonProps.onClick
            };
          }

          if (primaryButtonProps) {
            primaryButton = {
              label: primaryButtonProps.label,
              link: primaryButtonProps?.link,
              linkTarget:
                primaryButtonProps?.link?.indexOf("http") === 0
                  ? "_blank"
                  : undefined,
              onClick: primaryButtonProps.onClick
            };
          } else {
            primaryButton = {
              label: "Launch in Premium Perks Work",
              link: myBenefitsLink,
              linkTarget: "_blank"
            };
          }
        } else {
          primaryButton = {
            label: "Get benefit",
            loading: updateBenefitsServiceMeta.isLoading,
            onClick: async () => {
              if (isUserDataFilled) {
                await updateBenefitsService({
                  userId: memberId,
                  enabled: true
                });
                openModal(BENEFITS_PENDING_MODAL);
                setStore({ productActivationAfterOnboarding: false });
                await updateBenefitsSetupActivity(memberId);
              } else {
                history.push("/member/benefits/contact-info");
              }
            }
          };
        }

        const pillProps =
          BenefitsBadgeProps[
            isPremium && props.status === BenefitBlockStatus.PREMIUM
              ? BenefitCardStatus.INCLUDED
              : props.status
          ];

        return (
          <WSPage
            title={
              <WSFlexBox justify={isMobile ? "space-between" : "flex-start"}>
                <WSElement as="span" mr="M">
                  {props.title}
                </WSElement>

                {pillProps && <WSPill {...pillProps} />}
              </WSFlexBox>
            }
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            breadcrumb={{
              label: "BENEFITS",
              icon: "arrow-left",
              onClick() {
                goBackOrToParent(history);
              }
            }}
          >
            <BenefitsPending />
            {props.subtitle ? (
              <WSText.Heading5>{props.subtitle}</WSText.Heading5>
            ) : null}
            <WSGrid data-testid="benefitContent">
              <WSGrid.Item span={{ m: "8" }}>
                <WSElement my="XL" pr="3XL">
                  {props.details?.(user, member, features, openModal)}
                </WSElement>
                {props.linkMore ? (
                  <WSButton.Link
                    mt="M"
                    onClick={() => openInNewTab(props.linkMore as string)}
                    type="button"
                  >
                    Learn more
                  </WSButton.Link>
                ) : null}
              </WSGrid.Item>
              <WSGrid.Item span={{ m: "4" }}>
                {props.ctaImage && <WSImage mt="XL" {...props.ctaImage} />}
                {!props.hideUserData && isEnabled ? (
                  <WSPanel>
                    <WSText.ParagraphSm color="gray500">
                      Your Member ID
                    </WSText.ParagraphSm>
                    <WSText mt="XS">{memberNumber}</WSText>
                    <WSText.ParagraphSm color="gray500" mt="XL">
                      Your Group ID
                    </WSText.ParagraphSm>
                    <WSText mt="XS">{groupNumber}</WSText>
                  </WSPanel>
                ) : !props.hideUserData && isPending ? (
                  <WSPanel>
                    <WSText.ParagraphSm color="gray500">
                      Your Member ID
                    </WSText.ParagraphSm>
                    <WSText mt="XS">——</WSText>
                    <WSText.ParagraphSm color="gray500" mt="XL">
                      Your Group ID
                    </WSText.ParagraphSm>
                    <WSText mt="XS">——</WSText>
                  </WSPanel>
                ) : null}
              </WSGrid.Item>
            </WSGrid>
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
