import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_LIST_PAYER } from "../keys";
import { paymentsService } from "../../../services/payments";
import { useXWingspanExpansion } from "../../../shared/hooks/useXWingspanExpansion";

interface TaxFormsFilter {
  year: number;
  filter?: {
    recipientSyncEnabled?: boolean;
  };
}

export const usePayerTaxFormsListSize = (
  params: TaxFormsFilter,
  config?: WSQueryConfig<number, WSServiceError>
) => {
  const defaultParams = {
    page: {
      size: 1
    }
  };
  const queryParams = {
    ...defaultParams,
    ...params
  };
  const { year, ...otherParams } = queryParams;

  const xWingspanExpansion = useXWingspanExpansion();

  return useWSQuery<number, WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYER, { queryParams, xWingspanExpansion }],
    async () => {
      const { summary } = await paymentsService.taxForm.payerList(
        year,
        xWingspanExpansion,
        {
          ...otherParams
        }
      );
      return summary.listSize;
    },
    config
  );
};

export const usePaginatedPayerTaxForms = (
  params: TaxFormsFilter,
  config?: WSInfiniteQueryConfig<IPayerTaxFormResponse[], WSServiceError>
) => {
  const { year, ...filter } = params;

  const xWingspanExpansion = useXWingspanExpansion();

  return useWSInfiniteQuery<IPayerTaxFormResponse[], WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYER, { params, xWingspanExpansion }],
    async ({ pageParam = 1 }) => {
      const { data } = await paymentsService.taxForm.payerList(
        year,
        xWingspanExpansion,
        {
          filter,
          page: { size: 10, number: pageParam }
        }
      );

      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};
