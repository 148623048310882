import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../query/WSQuery";

import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { AmountCorrectionForm } from "../../../TaxCorrections/components/AmountCorrectionForm";
import { TINOrNameCorrectionForm } from "../../../TaxCorrections/components/TINOrNameCorrectionForm";
import { AddressCorrectionForm } from "../../../TaxCorrections/components/AddressCorrectionForm";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSForm
} from "@wingspanhq/fe-component-library";
import { getFormValidationSchema } from "../../../TaxCorrections/utils/getFormValidationSchema";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { useTaxFormUpdateCorrection } from "../../../../query/taxForm/mutations/useTaxFormUpdateCorrection";
import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { createRequestCorrectionPayload } from "../../../TaxCorrections/utils/createRequestCorrectionPayload";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { selectorTaxFormCorrectionFormW9Address } from "./selectorTaxFormCorrectionFormW9Address";
import { getCorrectionTypeFromPayerCorrection } from "./getCorrectionTypeFromPayerCorrection";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { selectorTaxFormRecipientName } from "../../selectors/selectorTaxFormRecipientName";
import React from "react";
import { SetupRouteReviewCorrectionFlowProps } from "./RouteReviewCorrectionFlow";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_PAYMENTS_TAXFORM_PAYER } from "../../../../query/taxForm/keys";

export const RouteEditCorrectionRequest: React.FC<
  SetupRouteReviewCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const history = useHistory<{
    backPath: string;
    showRequestedValue?: boolean;
    corrections: string[];
    correctionsFormData: FormRequestCorrectionData;
  }>();

  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);
  const [updateTaxFormCorrection, updateTaxFormCorrectionMeta] =
    useTaxFormUpdateCorrection(taxFormId);

  const corrections = history.location.state?.corrections ?? [];
  const correctionsFormData = history.location.state?.correctionsFormData;
  const showRequestedValue = history.location.state?.showRequestedValue;

  const goToPrev = useGoBackOrToParent();

  const goBack = () => {
    if (history.location.state?.backPath) {
      history.replace(history.location.state?.backPath);
    } else {
      goToPrev();
    }
  };

  const onFormSubmit = async (data: FormRequestCorrectionData) => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const updateRequest = createRequestCorrectionPayload(
      data,
      taxForm,
      TaxFormViewer.Payer
    );
    await updateTaxFormCorrection(
      {
        correctionId: taxForm.pendingCorrection?.taxFormCorrectionId ?? "",
        requestData: {
          data: {
            ...updateRequest.data
          },
          payerOwnedData: updateRequest.payerOwnedData
        }
      },
      {
        onSuccess: () => {
          WSQueryCache.removeQueries(QUERY_PAYMENTS_TAXFORM_PAYER);
          goBack();
        }
      }
    );
  };

  return (
    <EditLayout title="Edit correction details" onClose={goBack}>
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          const correctedAddress =
            selectorTaxFormCorrectionFormW9Address(taxForm);
          const originalTinData = getPayeeTINorName(taxForm);

          const correctionTypeToFormComponentMap: Record<string, JSX.Element> =
            {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionForm
                  isOverride
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  amount={taxForm.data?.totalAmount || 0}
                  showRequestedValue={showRequestedValue}
                  correctedAmount={
                    taxForm?.pendingCorrection?.data?.totalAmount
                  }
                  recipientName={recipientName}
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionForm
                  isOverride
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalTinData={originalTinData}
                  identificationNumberType={
                    taxForm?.pendingCorrection?.data?.w9Info?.ein ||
                    taxForm?.pendingCorrection?.data?.w9Info?.einLastFour ||
                    taxForm?.data?.w9Info?.ein ||
                    taxForm?.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  showRequestedValue={showRequestedValue}
                  {...(showRequestedValue
                    ? {
                        correctedTinData: {
                          ssn:
                            taxForm?.pendingCorrection?.data?.w9Info?.ssn ?? "",
                          ein:
                            taxForm?.pendingCorrection?.data?.w9Info?.ein ?? "",
                          businessName:
                            taxForm?.pendingCorrection?.data?.w9Info
                              ?.legalBusinessName ?? "",
                          firstName:
                            taxForm?.pendingCorrection?.data?.w9Info?.firstName,
                          lastName:
                            taxForm?.pendingCorrection?.data?.w9Info?.lastName,
                          disregardedEntityName:
                            taxForm?.pendingCorrection?.data?.w9Info
                              ?.disregardedEntityName,
                          taxClassification: taxForm?.pendingCorrection?.data
                            ?.w9Info?.companyStructure as CompanyStructure
                        },
                        reasonForChange:
                          taxForm?.pendingCorrection?.payeeOwnedData?.reason ??
                          "",
                        otherReason:
                          taxForm?.pendingCorrection?.payeeOwnedData
                            ?.reasonComment
                      }
                    : {})}
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionForm
                  isOverride
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  showRequestedValue={showRequestedValue}
                  correctedAddress={correctedAddress}
                />
              )
            };

          return (
            <>
              <WSForm<FormRequestCorrectionData>
                defaultValues={correctionsFormData}
                validationSchema={getFormValidationSchema(
                  corrections,
                  taxForm.data?.w9Info?.country || "US",
                  false,
                  TaxFormViewer.Payer
                )}
                onSubmit={onFormSubmit}
              >
                {corrections.map((correctionType, index) => {
                  const FormComponent =
                    correctionTypeToFormComponentMap[correctionType];
                  const isLast = index === corrections.length - 1;
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {FormComponent}
                      {isLast ? null : <WSDivider my="XL" />}
                    </WSElement>
                  );
                })}

                <WSErrorMessage
                  mb="M"
                  contextKey={ErrorContextKey.NEC1099PayerUpdateCorrection}
                  error={updateTaxFormCorrectionMeta.error}
                />

                <WSForm.Values
                  names={[
                    "necTotalAmount",
                    "amountCorrectionHelpText",
                    "identificationNumberType",
                    "ssn",
                    "ein",
                    "firstName",
                    "lastName",
                    "businessName",
                    "disregardedEntityName",
                    "taxClassification",
                    "formW9Address"
                  ]}
                >
                  {formData => {
                    const correctionTypeData =
                      getCorrectionTypeFromPayerCorrection(taxForm, formData);
                    return (
                      <>
                        <PreventLeave
                          isEnabled={correctionTypeData.corrections.length > 0}
                        />
                        <WSButtons forceFullWidth>
                          <WSForm.SubmitButton
                            loading={updateTaxFormCorrectionMeta.isLoading}
                            disabled={
                              correctionTypeData.corrections.length === 0
                            }
                          >
                            Save changes
                          </WSForm.SubmitButton>
                          <WSButton.Tertiary type="button" onClick={goBack}>
                            Cancel
                          </WSButton.Tertiary>
                        </WSButtons>
                      </>
                    );
                  }}
                </WSForm.Values>
              </WSForm>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
