import {
  useIsDesktop,
  useModalOldContext,
  WSButton,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AccountStatus,
  AccountType,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces";
import React, { useState } from "react";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSAccountsListProps } from "../../../components/WSAccountsList/WSAccountsList.component";
import { useCreateAccount } from "../../../query/users/mutations";
import { useAccounts } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useMemberPlaidLink } from "../../../utils/usePlaidLink";
import { BOOKKEEPING_NO_ACCOUNTS_CONNECTED } from "../../screens/modals/NoAccountsConnected";
import { AccountsCheckLoading } from "../AccountsCheckList/AccountsCheckList";
import { AccountsFinishSetup } from "../AccountsFinishSetup/AccountsFinishSetup";
import { AccountsList } from "../AccountsList/AccountsList";

export const AccountsDashboard: React.FC<{
  onSave?(accounts: IAccount[]): void;
  saveButtonText?: string;
  onPlaidFinish(institutionId: string, accounts: IAccount[]): void;
  onCSV(): void;
  onContinueSetup(institutionId: string, accounts: IAccount[]): void;
  onManageInstitution?: WSAccountsListProps["onManageInstitution"];
}> = ({
  onSave,
  onContinueSetup,
  onPlaidFinish,
  onCSV,
  onManageInstitution,
  saveButtonText = "Save & Sync"
}) => {
  const { openModal } = useModalOldContext();
  const isDesktop = useIsDesktop();
  const [createAccountWithToken] = useCreateAccount();
  const qAccounts = useAccounts();

  const [saveLoading, setSaveLoading] = useState(false);
  const [syncingInstitution, setSyncingInstitution] = useState<{
    institution_id: string;
    name: string;
  } | null>(null);

  const plaidHandler = useMemberPlaidLink({
    async onSuccess(publicToken, meta) {
      setSyncingInstitution(meta?.institution);

      const createResponse = await createAccountWithToken({
        publicToken,
        canBeUsedFor: { bookkeeping: true }
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      accounts = accounts.filter(a =>
        [AccountType.Credit, AccountType.Depository].includes(a.type)
      );

      if (accounts.length > 0) {
        onPlaidFinish(accounts[0].institutionId as string, accounts);
      } else {
        openModal(BOOKKEEPING_NO_ACCOUNTS_CONNECTED);
        setSyncingInstitution(null);

        return {
          success: false,
          data: accounts
        };
      }

      setSyncingInstitution(null);

      return {
        success: true,
        data: accounts
      };
    },
    product: ["transactions"],
    skipError: true
  });

  useBrowserPageTitle("Bookkeeping - Accounts");

  return (
    <WSQueries queries={{ qAccounts }}>
      {({ qAccounts }) => {
        const activeBookkeepingAccounts = qAccounts.data.filter(
          a =>
            a.canBeUsedFor?.bookkeeping &&
            a.usedFor?.bookkeeping &&
            a.status !== AccountStatus.Inactive
        );

        const disconnectedAccounts: IAccount[] = [];
        const connectedAccounts: IAccount[] = [];
        const notFinishedAccounts: IAccount[] = [];

        qAccounts.data
          .filter(
            a =>
              a.canBeUsedFor?.bookkeeping && a.status !== AccountStatus.Inactive
          )
          .forEach(account => {
            if (account.usedFor?.bookkeeping) {
              if (account.status === AccountStatus.Disconnected) {
                disconnectedAccounts.push(account);
              } else if (!account.events?.initialSetupAt) {
                notFinishedAccounts.push(account);
              } else {
                connectedAccounts.push(account);
              }
            } else if (!account.events?.initialSetupAt) {
              notFinishedAccounts.push(account);
            }
          });

        return (
          <>
            {syncingInstitution?.institution_id ? (
              <WSPanel mt="2XL">
                <AccountsCheckLoading
                  institutionId={syncingInstitution?.institution_id}
                  institutionName={syncingInstitution?.name}
                />
              </WSPanel>
            ) : null}
            {notFinishedAccounts.length ? (
              <WSPanel mt="2XL">
                <AccountsFinishSetup
                  accounts={notFinishedAccounts}
                  onFinish={onContinueSetup}
                />
              </WSPanel>
            ) : null}
            {disconnectedAccounts.length ? (
              <WSPanel mt="2XL">
                <WSFlexBox.CenterY>
                  <WSIcon block name="alert-circle" color="red500" mr="M" />
                  <WSText.Heading5>Disconnected Accounts</WSText.Heading5>
                </WSFlexBox.CenterY>
                <WSText.ParagraphSm color="gray600" mt="M" mb="XL">
                  Connection lost, reconnect to restore sync.
                </WSText.ParagraphSm>

                <AccountsList accounts={disconnectedAccounts} />
              </WSPanel>
            ) : null}

            <WSPanel mt="2XL">
              <WSFlexBox.CenterY justify="space-between">
                <WSText.Heading5>Accounts</WSText.Heading5>

                <WSButton.Link icon="upload" onClick={onCSV}>
                  CSV bulk upload
                </WSButton.Link>
              </WSFlexBox.CenterY>
              {connectedAccounts.length ? (
                <>
                  <WSText mt="XL" mb="M">
                    Transaction sync is active for these accounts.
                  </WSText>
                  <AccountsList
                    accounts={connectedAccounts}
                    onManageInstitution={onManageInstitution}
                  />
                </>
              ) : null}
              <WSButton.Link
                icon="plus-circle"
                mt="2XL"
                onClick={() => plaidHandler.open()}
                data-testid="addAccount"
              >
                Add account
              </WSButton.Link>
              {onSave ? (
                <WSFlexBox.Center>
                  <WSButton.Primary
                    data-testId="saveAccounts"
                    fullWidth={!isDesktop}
                    loading={saveLoading}
                    mt="3XL"
                    onClick={async () => {
                      setSaveLoading(true);
                      await onSave(activeBookkeepingAccounts);
                      setSaveLoading(false);
                    }}
                  >
                    {saveButtonText}
                  </WSButton.Primary>
                </WSFlexBox.Center>
              ) : null}
            </WSPanel>
          </>
        );
      }}
    </WSQueries>
  );
};
