import {
  toWSDateString,
  useModalOldContext,
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSLayout,
  WSMessageBox,
  WSMessageBoxKindMap,
  WSMessageBoxProps,
  WSModalOld,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  IInvoice,
  IInvoiceTemplate,
  IInvoicingConfig,
  Intervals,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { isEqual } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useFormContext } from "react-hook-form";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import {
  PreventLeave,
  PROMPT_MESSAGE
} from "../../../components/PreventLeave/PreventLeave";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useQueryInvoicingConfigForPayee } from "../../../query/invoicingConfig/queries/useQueryInvoicingConfigForPayee";
import { useSaveInvoice } from "../../../query/payments/mutations";
import { getIsInvoiceRejected } from "../../../query/payments/selectors";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { IPayerRow } from "../../../services/search";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { isSameDate, isToday } from "../../../utils/dates";
import { getChangedData } from "../../../utils/getChangedData";
import { InvoicesSeriesDetails } from "../../screens/InvoicesSeriesDetails";
import { InvoicesSeriesInvoiceEdit } from "../../screens/InvoicesSeriesInvoiceEdit";
import { RouteInvoiceDetails } from "../../screens/RouteInvoiceDetails";
import {
  generateSendDate,
  useInvoiceFormGoBack
} from "../../utils/invoicesFormUtils";
import { InvoicePreview } from "../InvoicePreview/InvoicePreview";
import { AdvancedSection, AdvancedSectionValues } from "./AdvancedSection";
import { ClientSection, ClientSectionValues } from "./ClientSection";
import { DueSection, DueSectionValues } from "./DueSection";
import { FrequencySection, FrequencySectionValues } from "./FrequencySection";
import {
  InvoiceCollaboratorsSection,
  InvoiceCollaboratorsSectionValues
} from "./InvoiceCollaboratorsSection";
import { LateFeeSection, LateFeeSectionValues } from "./LateFeeSection";
import { LineItemsSection, LineItemsSectionValues } from "./LineItemsSection";
import { calculateTotalAmountWithoutDiscount } from "./LineItemsSection/utils";
import { getValidationSchemaLineItem } from "./LineItemsSection/validationSchema";
import { OtherSection, OtherSectionValues } from "./OtherSection";
import {
  PaymentMethodsSection,
  PaymentMethodsSectionValues
} from "./PaymentMethodsSection";
import { SendSection, SendSectionValues } from "./SendSection";

const SAVE_CHANGES_MODAL = "saveChanges";
const RESOLVE_DISPUTE_MODAL = "RESOLVE_DISPUTE_MODAL";

export type InvoicesFormStep1Values = ClientSectionValues &
  LineItemsSectionValues &
  InvoiceCollaboratorsSectionValues;

export type InvoicesFormStep2Values = OtherSectionValues &
  DueSectionValues &
  SendSectionValues &
  FrequencySectionValues &
  PaymentMethodsSectionValues &
  LateFeeSectionValues &
  AdvancedSectionValues & {
    purchaseOrderNumber?: string;
  };

export type InvoicesFormValues = InvoicesFormStep1Values &
  InvoicesFormStep2Values & { subject: string; email?: string };

export type InvoiceFormProps = {
  defaultValues: InvoicesFormValues;
  context?: {
    invoice?: IInvoice;
    invoiceTemplate?: IInvoiceTemplate;
    parentInvoiceTemplate?: IInvoiceTemplate;
  };
};

type IInvoicesFromContext = {
  invoicingConfigForPayee: IInvoicingConfig | null;
  defaultValues: InvoicesFormValues;
  values?: InvoicesFormValues;
  invoice?: IInvoice;
  invoiceTemplate?: IInvoiceTemplate;
  parentInvoiceTemplate?: IInvoiceTemplate;
  payerRow?: IPayerRow;
  isDraft?: boolean;
};

export const InvoicesFormContext = createContext<IInvoicesFromContext>(
  {} as IInvoicesFromContext
);
export const useInvoicesFormContext = () => useContext(InvoicesFormContext);

export const InvoicesForm: React.FC<InvoiceFormProps> = ({
  defaultValues,
  context
}) => {
  const [values, setValues] = useState(defaultValues);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [saveInvoice, saveInvoiceMeta] = useSaveInvoice();
  const [saveInvoice2, saveInvoice2Meta] = useSaveInvoice();
  const { openModal, closeModal } = useModalOldContext();
  const history = useHistory();
  const location = useLocation<{ backPath?: string }>();
  const goBack = useInvoiceFormGoBack();

  const queryFeatureFlags = useFeatureFlags();

  const queryPayerRows = useQueryPayerRows(
    {
      filter: {
        payerId: values.client?.payerId || undefined,
        "engagements.payerPayeeEngagementId":
          values.client?.payerPayeeEngagementId || undefined
      },
      page: {
        number: 1,
        size: 1
      }
    },
    {
      enabled:
        !!values.client?.payerId || !!values.client?.payerPayeeEngagementId
    }
  );

  const queryInvoicingConfigForPayee = useQueryInvoicingConfigForPayee(
    values.client?.payerId as string,
    {
      enabled: !!values.client?.payerId,
      // setting cacheTime to 0 to always show the latest form a/c to invoicing config
      // as WSForm.Field component is not re-rendering on change of client
      cacheTime: 0
    }
  );

  const invoice = context?.invoice;
  const invoiceTemplate = context?.invoiceTemplate;
  const parentInvoiceTemplate = context?.parentInvoiceTemplate;

  const isDraft = invoice
    ? invoice.status === InvoiceStatus.Draft
    : invoiceTemplate
    ? invoiceTemplate.status === FrequencyAndScheduleStatus.Draft
    : true;
  const isSent = invoice
    ? invoice.status === InvoiceStatus.Open ||
      invoice.status === InvoiceStatus.Overdue
    : false;
  const isScheduled =
    invoiceTemplate && invoiceTemplate.scheduleDates ? true : false;

  const isInvoiceRejected = invoice ? getIsInvoiceRejected(invoice) : false;

  const isRecurring =
    parentInvoiceTemplate && !!parentInvoiceTemplate.frequency;
  const isRecurringTemplate = invoiceTemplate && !!invoiceTemplate.frequency;

  const title = isRecurringTemplate
    ? "Edit recurring invoice series"
    : isRecurring
    ? "Edit recurring invoice"
    : isScheduled
    ? "Edit scheduled invoice"
    : isSent
    ? "Edit invoice"
    : "Create invoice";

  if (!values) {
    return null;
  }

  const commonFormProps = {
    title,
    banner: isRecurring
      ? {
          kind: WSMessageBoxKindMap.Warning,
          children: (
            <>
              You're editing an instance of a recurring invoice series,{" "}
              <WSButton.Link type="button">click</WSButton.Link> to edit the
              series
            </>
          ),
          onClick: () => {
            history.push(
              `/member/invoices/template/${invoice?.invoiceTemplateId}/edit`
            );
          }
        }
      : isRecurringTemplate
      ? {
          kind: WSMessageBoxKindMap.Warning,
          children: (
            <>
              All future invoices will be updated.{" "}
              {invoiceTemplate?.isSchedulingOnly && (
                <WSButton.Link type="button">
                  Click here to edit single invoice in series.
                </WSButton.Link>
              )}
            </>
          ),
          onClick: invoiceTemplate?.isSchedulingOnly
            ? () => {
                history.push({
                  pathname: `/member/invoices/template/${invoiceTemplate?.invoiceTemplateId}/edit/series`,
                  search: location.search
                });
              }
            : undefined
        }
      : undefined
  };

  return (
    <InvoicesFormContext.Provider
      value={{
        invoicingConfigForPayee: queryInvoicingConfigForPayee.data ?? null,
        defaultValues,
        values,
        invoice,
        invoiceTemplate,
        parentInvoiceTemplate,
        payerRow: queryPayerRows.data?.[0],
        isDraft
      }}
    >
      <BrowserPageTitle title={title} />
      {queryInvoicingConfigForPayee.isInitialLoading && <OverlaySpinner />}
      {previewVisible && (
        <InvoicePreview
          data={values}
          onClose={() => {
            setPreviewVisible(false);
          }}
          invoiceLink={invoice?.attachments?.invoiceLink}
          subject={values.subject}
          onSubjectChange={newSubject => {
            setValues(oldValues => ({
              ...oldValues,
              subject: newSubject
            }));
          }}
        />
      )}

      <WSModalOld
        name={SAVE_CHANGES_MODAL}
        size="XS"
        title="Do you want to save changes?"
      >
        {(values: InvoicesFormValues) => (
          <WSButtons forceFullWidth format="modal">
            <WSButton
              onClick={() => {
                saveInvoice(
                  {
                    invoiceId: invoice && invoice.invoiceId,
                    invoiceTemplateId:
                      invoiceTemplate && invoiceTemplate.invoiceTemplateId,
                    data: {
                      ...values,
                      ...(queryInvoicingConfigForPayee.data?.dueInDays?.enabled
                        ? {
                            dueInDays:
                              queryInvoicingConfigForPayee.data?.dueInDays
                                ?.value
                          }
                        : {})
                    }
                  },
                  {
                    onSuccess: () => {
                      closeModal(SAVE_CHANGES_MODAL);
                    }
                  }
                );
              }}
              loading={saveInvoiceMeta.isLoading}
            >
              Save changes
            </WSButton>
            <WSButton.Secondary
              onClick={() => {
                closeModal(SAVE_CHANGES_MODAL);
                goBack();
              }}
            >
              Discard
            </WSButton.Secondary>
          </WSButtons>
        )}
      </WSModalOld>

      <WSModalOld
        name={RESOLVE_DISPUTE_MODAL}
        size="S"
        title="Resolve the dispute"
      >
        {(values: InvoicesFormValues) => (
          <>
            <WSText mb="XL">
              This invoice has been disputed by the client, would you like to
              clear the dispute?
            </WSText>
            <WSButtons format="modal">
              <WSButton
                onClick={() => {
                  saveInvoice({
                    invoiceId: invoice && invoice.invoiceId,
                    data: {
                      ...values,
                      ...(queryInvoicingConfigForPayee.data?.dueInDays?.enabled
                        ? {
                            dueInDays:
                              queryInvoicingConfigForPayee.data?.dueInDays
                                ?.value
                          }
                        : {})
                    },
                    resubmit: true
                  });
                  closeModal(RESOLVE_DISPUTE_MODAL);
                }}
              >
                Yes
              </WSButton>
              <WSButton.Secondary
                onClick={() => {
                  saveInvoice({
                    invoiceId: invoice && invoice.invoiceId,
                    data: {
                      ...values,
                      ...(queryInvoicingConfigForPayee.data?.dueInDays?.enabled
                        ? {
                            dueInDays:
                              queryInvoicingConfigForPayee.data?.dueInDays
                                ?.value
                          }
                        : {})
                    }
                  });
                  closeModal(RESOLVE_DISPUTE_MODAL);
                }}
              >
                No
              </WSButton.Secondary>
            </WSButtons>
          </>
        )}
      </WSModalOld>

      <Switch>
        <Route
          children={({ location }) => {
            if (location.search?.includes("?step=2")) {
              return (
                <Step2Form
                  defaultValues={{
                    purchaseOrderNumber: values.purchaseOrderNumber,
                    other: values.other,
                    due: values.due,
                    customDueDate: values.customDueDate,
                    includesLateFee: values.includesLateFee,
                    lateFee: values.lateFee,
                    send: values.send,
                    recurring: values.recurring,
                    frequency: values.frequency,
                    paymentMethods: values.paymentMethods,
                    advanced: values.advanced
                  }}
                  onChange={newStep2Values => {
                    const newValues = { ...values, ...newStep2Values };
                    setValues(newValues);
                  }}
                  onBack={newStep2Values => {
                    const newValues = { ...values, ...newStep2Values };
                    setValues(newValues);
                    history.push({
                      search: "step=1",
                      state: location.state
                    });
                  }}
                  onPreview={newStep2Values => {
                    const newValues = { ...values, ...newStep2Values };
                    setValues(newValues);
                    setPreviewVisible(true);
                  }}
                  onSubmit={newStep2Values => {
                    const newValues = { ...values, ...newStep2Values };
                    setValues(newValues);

                    if (isInvoiceRejected) {
                      openModal(RESOLVE_DISPUTE_MODAL, values);
                    } else {
                      saveInvoice({
                        invoiceId: invoice && invoice.invoiceId,
                        invoiceTemplateId:
                          invoiceTemplate && invoiceTemplate.invoiceTemplateId,
                        data: {
                          ...newValues,
                          ...(queryInvoicingConfigForPayee.data?.dueInDays
                            ?.enabled
                            ? {
                                dueInDays:
                                  queryInvoicingConfigForPayee.data?.dueInDays
                                    ?.value
                              }
                            : {})
                        },
                        send: isDraft
                      });
                    }
                  }}
                  isSubmitting={saveInvoiceMeta.isLoading}
                  error={saveInvoiceMeta.error}
                  onSave={newStep2Values => {
                    const newValues = { ...values, ...newStep2Values };
                    setValues(newValues);
                    saveInvoice2({
                      invoiceId: invoice && invoice.invoiceId,
                      invoiceTemplateId:
                        invoiceTemplate && invoiceTemplate.invoiceTemplateId,
                      data: newValues
                    });
                  }}
                  isSaving={saveInvoice2Meta.isLoading}
                  {...commonFormProps}
                />
              );
            } else {
              return (
                <Step1Form
                  defaultValues={{
                    client: values.client,
                    lineItems: values.lineItems,
                    ...(queryFeatureFlags.data?.purchaseOrderNumber
                      ? { purchaseOrderNumber: values.purchaseOrderNumber }
                      : {}),
                    invoiceCollaborators: values.invoiceCollaborators
                  }}
                  onChange={newStep1Values => {
                    const newValues = { ...values, ...newStep1Values };
                    setValues(newValues);
                  }}
                  onBack={newStep1Values => {
                    const newValues = { ...values, ...newStep1Values };
                    setValues(newValues);

                    const changedData = getChangedData(
                      defaultValues,
                      newValues,
                      {
                        dismissTimeInDates: true
                      }
                    );

                    if (
                      Object.keys(changedData).length > 0 &&
                      newValues.client?.payerPayeeEngagementId
                    ) {
                      openModal(SAVE_CHANGES_MODAL, newValues);
                    } else {
                      goBack();
                    }
                  }}
                  onPreview={newStep1Values => {
                    const newValues = { ...values, ...newStep1Values };
                    setValues(newValues);
                    setPreviewVisible(true);
                  }}
                  onSubmit={newStep1Values => {
                    const newValues = { ...values, ...newStep1Values };
                    setValues(newValues);
                    history.push({
                      search: "step=2",
                      state: location.state
                    });
                  }}
                  isSubmitting={saveInvoiceMeta.isLoading}
                  error={saveInvoiceMeta.error || saveInvoice2Meta.error}
                  onSave={newStep1Values => {
                    const newValues = { ...values, ...newStep1Values };
                    setValues(newValues);

                    saveInvoice2({
                      invoiceId: invoice && invoice.invoiceId,
                      invoiceTemplateId:
                        invoiceTemplate && invoiceTemplate.invoiceTemplateId,
                      data: newValues
                    });
                  }}
                  isSaving={saveInvoice2Meta.isLoading}
                  {...commonFormProps}
                />
              );
            }
          }}
        />
      </Switch>

      <Route
        path="/member/invoices/template/:invoiceTemplateId/edit/series"
        component={InvoicesSeriesDetails}
        exact
      />

      <Route
        path="/member/invoices/template/:invoiceTemplateId/edit/series/:scheduleDateIndex"
        component={InvoicesSeriesInvoiceEdit}
        exact
      />

      <Route
        path="/member/invoices/template/:invoiceTemplateId/edit/series/invoice/:invoiceId"
        component={RouteInvoiceDetails}
        exact
      />
    </InvoicesFormContext.Provider>
  );
};

interface StepFormProps<Values> {
  title: string;
  defaultValues: Values;
  onChange: (values: Values) => void;
  onBack: (values: Values) => void;
  onPreview: (values: Values) => void;
  onSubmit: (values: Values) => void;
  isSubmitting?: boolean;
  error?: any;
  banner?: WSMessageBoxProps;
  onSave?: (values: Values) => void;
  isSaving?: boolean;
}

const Step1Form: React.FC<StepFormProps<InvoicesFormStep1Values>> = ({
  title,
  defaultValues,
  onBack,
  onPreview,
  onSubmit,
  banner,
  onSave,
  isSaving,
  onChange,
  error
}) => {
  const { isDraft, invoicingConfigForPayee } = useInvoicesFormContext();
  const [customError, setCustomError] = useState("");
  const queryFeatureFlags = useFeatureFlags();
  const isCurrencyConversionActive =
    queryFeatureFlags.data?.currencyConversionToUSD;

  return (
    <WSFormOld<InvoicesFormStep1Values>
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        client: Yup.object().shape({
          payerPayeeEngagementId: Yup.string().required("Required"),
          emailsCC: Yup.array(
            Yup.object().shape({
              email: validatorEmail
            })
          )
        }),
        lineItems: Yup.array(getValidationSchemaLineItem())
          .min(1, "At least one work item is required")
          .required("At least one work item is required")
      })}
      onSubmit={newValues => {
        setCustomError("");
        const totalAmount = calculateTotalAmountWithoutDiscount(
          newValues.lineItems
        );
        if (totalAmount > 0) {
          onSubmit(newValues);
        } else {
          setCustomError("Total line items amount can not be negative or zero");
        }
      }}
    >
      {step1Context => {
        return (
          <WSLayout
            headerLeft={
              <WSButton.Link
                icon="arrow-left"
                onClick={() => {
                  const newValues = step1Context.getValues();
                  onBack(newValues);
                }}
                type="button"
              >
                <WSScreen.TabletAndDesktop>
                  Save & Exit
                </WSScreen.TabletAndDesktop>
              </WSButton.Link>
            }
            headerCenter={
              <WSText.Heading5 data-testid="invoiceFormTitle">
                {title}
              </WSText.Heading5>
            }
            headerRight={
              <WSText.ParagraphSm color="gray500">1/2</WSText.ParagraphSm>
            }
            line
          >
            <SaveFormChanges onChange={onChange} />
            <WSFormOld.Value name={undefined as any as string}>
              {values => {
                const changedData = getChangedData(defaultValues, values);
                const isChanged = !!(
                  Object.keys(changedData).length > 0 &&
                  values.client?.payerPayeeEngagementId
                );

                return <PreventLeave isEnabled={isChanged} />;
              }}
            </WSFormOld.Value>
            <WSContainer verticalPadding>
              <WSCentered span={{ xl: "8" }}>
                {banner && <WSMessageBox mb="2XL" {...banner} />}
                <ClientSection mb="3XL" />
                <WSText.Heading5 mb={isCurrencyConversionActive ? "XS" : "M"}>
                  Work summary
                </WSText.Heading5>
                {isCurrencyConversionActive && (
                  <WSText.ParagraphSm mb="M" color="gray500">
                    Invoicing is only available in U.S. Dollars (USD). Please
                    enter the amount in USD or we’ll convert another currency to
                    USD based on latest exchange rates.
                  </WSText.ParagraphSm>
                )}
                <LineItemsSection
                  mb="3XL"
                  isIntegrationQuickbooksItems
                  isCurrencyConversionActive={isCurrencyConversionActive}
                />
                {invoicingConfigForPayee?.allowCollaboratorSplits?.enabled ? (
                  <InvoiceCollaboratorsSection mb="3XL" />
                ) : null}
                <WSErrorMessage
                  mb="XL"
                  contextKey="InvoiceForm"
                  error={customError || error}
                />
                <WSFlexBox.CenterY mb="XS">
                  {/* Preview is broken, hidding the button */}
                  {/* <WSButton.Secondary
                    mr="M"
                    mb="XL"
                    type="button"
                    onClick={() => {
                      onPreview(step1Context.getValues());
                    }}
                    name="preview"
                  >
                    Preview
                  </WSButton.Secondary> */}

                  <WSButton mb="XL" mr="XL" type="submit" name="next">
                    Next
                  </WSButton>

                  {onSave && isDraft && (
                    <WSButton.Link
                      mb="XL"
                      type="button"
                      loading={isSaving}
                      onClick={() => {
                        const newValues = step1Context.getValues();

                        if (!newValues.client?.payerPayeeEngagementId) {
                          step1Context.setError(
                            "client.payerPayeeEngagementId",
                            {
                              message: "Required",
                              shouldFocus: true
                            }
                          );
                          return;
                        }

                        onSave(newValues);
                      }}
                    >
                      Save as draft and exit
                    </WSButton.Link>
                  )}
                </WSFlexBox.CenterY>
              </WSCentered>
            </WSContainer>

            {/* <WSFormOld.Value name={undefined as any}>
              {values => <pre>{JSON.stringify(values, null, 2)}</pre>}
            </WSFormOld.Value>

            <WSFormOld.Context>
              {({ errors }) => <pre>{JSON.stringify(errors, null, 2)}</pre>}
            </WSFormOld.Context> */}
          </WSLayout>
        );
      }}
    </WSFormOld>
  );
};

const Step2Form: React.FC<StepFormProps<InvoicesFormStep2Values>> = ({
  title,
  defaultValues,
  isSubmitting,
  error,
  onBack,
  onPreview,
  onSubmit,
  banner,
  onSave,
  isSaving,
  onChange
}) => {
  const qFeatureFlags = useFeatureFlags();
  const formContext = useInvoicesFormContext();
  const [customError, setCustomError] = useState("");

  useEffect(() => {
    window.onbeforeunload = function () {
      return PROMPT_MESSAGE;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <WSFormOld<InvoicesFormStep2Values>
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        ...(!formContext.invoicingConfigForPayee?.dueInDays?.enabled
          ? {
              due: Yup.string()
                .oneOf(["on-receipt", "7-days", "30-days", "custom"])
                .required("Required"),
              customDueDate: Yup.date().when("due", {
                is: "custom",
                then: Yup.lazy((value: Date) => {
                  if (isSameDate(defaultValues.customDueDate, value)) {
                    return Yup.date();
                  } else {
                    return Yup.date().when("send.type", {
                      is: "immediately",
                      then: Yup.date().isTodayAndInFuture(),
                      otherwise: Yup.date().isAfter("send.date")
                    });
                  }
                })
              })
            }
          : {}),
        ...(qFeatureFlags.data?.purchaseOrderNumber
          ? {
              purchaseOrderNumber: formContext.invoicingConfigForPayee
                ?.requirePONumber?.enabled
                ? Yup.string().max(24).required("PO Number is required")
                : Yup.string().max(24)
            }
          : {}),

        other: Yup.object().shape({
          ...(formContext.invoicingConfigForPayee?.requireProjectName?.enabled
            ? {
                projectName: Yup.string().required("Project name is required")
              }
            : {}),

          ...(formContext.invoicingConfigForPayee?.requireAttachments?.enabled
            ? {
                attachments: Yup.array().test(
                  "attachments",
                  "At least one attachment is required",
                  attachments => {
                    const removedAttachments = attachments?.filter(
                      attachment => attachment?.remove === "1"
                    );
                    return !(
                      removedAttachments?.length === attachments?.length
                    );
                  }
                )
              }
            : {})
        }),

        lateFee: Yup.object().when("includesLateFee", {
          is: true,
          then: Yup.object().shape({
            amount: Yup.number().typeError("Required").required("Required"),
            type: Yup.string().oneOf(["percent", "fixed"]).required("Required"),
            every: Yup.number().typeError("Required").required("Required"),
            interval: Yup.string()
              .oneOf([Intervals.Week, Intervals.Month])
              .required("Required")
          })
        }),

        send: Yup.object().shape({
          type: Yup.string()
            .oneOf(["immediately", "date"])
            .required("Required"),
          date: Yup.date().when("type", {
            is: "date",
            then: Yup.lazy((value: Date) => {
              if (formContext.isDraft) {
                return Yup.date().isTodayAndInFuture();
              } else {
                const test = isSameDate(
                  value,
                  formContext.defaultValues.send.date
                );
                if (test) {
                  return Yup.date();
                } else {
                  return Yup.date().isTodayAndInFuture();
                }
              }
            })
          })
        }),

        frequency: Yup.object().when("recurring", {
          is: true,
          then: Yup.object().shape({
            every: Yup.number().typeError("Required").required("Required"),
            interval: Yup.string()
              .oneOf([Intervals.Week, Intervals.Month])
              .required("Required"),
            day: Yup.string(),
            end: Yup.string()
              .oneOf(["never", "date", "after"])
              .required("Required"),
            endDate: Yup.date().when("end", {
              is: "date",
              then: Yup.date().isTodayAndInFuture()
            }),
            endAfter: Yup.number()
              .nullable()
              .when("end", {
                is: "after",
                then: schema =>
                  schema.typeError("Required").required("Required")
              })
          })
        })
      })}
      onSubmit={values => {
        // Validate endDate of frequency
        setCustomError("");

        if (values.recurring) {
          const sendDate = generateSendDate({
            send: values.send
          });

          const every = Number(values.frequency.every);

          let startDate;

          if (values.frequency.start === "custom") {
            startDate = new Date(values.frequency.startDate);
          } else {
            startDate = new Date(sendDate);
            if (values.frequency.interval === Intervals.Month) {
              startDate.setMonth(startDate.getMonth() + every);
            } else {
              startDate.setDate(startDate.getDate() + 7 * every);
            }
          }

          if (
            values.frequency.end === "date" &&
            (values.frequency.endDate < startDate ||
              isSameDate(values.frequency.endDate, startDate))
          ) {
            setCustomError(
              `End date should be after ${toWSDateString(
                startDate,
                "monthDayYear"
              )}`
            );
            return;
          }
        }

        onSubmit(values);
      }}
    >
      {step2Context => (
        <WSLayout
          headerLeft={
            <WSButton.Link
              icon="arrow-left"
              onClick={() => {
                onBack(step2Context.getValues());
              }}
              type="button"
            >
              <WSScreen.TabletAndDesktop>Back</WSScreen.TabletAndDesktop>
            </WSButton.Link>
          }
          headerCenter={
            <WSText.Heading5 data-testid="invoiceFormTitle">
              {title}
            </WSText.Heading5>
          }
          headerRight={
            <WSText.ParagraphSm color="gray500">2/2</WSText.ParagraphSm>
          }
          line
        >
          <SaveFormChanges onChange={onChange} />
          <WSContainer verticalPadding>
            <WSCentered span={{ xl: "8" }}>
              {banner && <WSMessageBox mb="2XL" {...banner} />}

              <WSText.Heading5 mb="M">Other</WSText.Heading5>
              <OtherSection mb="3XL" />

              <DueSection mb="3XL" />

              <SendSection mb="3XL" />

              {formContext.invoicingConfigForPayee?.allowRecurringInvoices
                ?.enabled ? (
                <FrequencySection mb="3XL" />
              ) : null}

              {formContext.invoicingConfigForPayee?.allowLateFees?.enabled ? (
                <LateFeeSection mb="3XL" />
              ) : null}

              <PaymentMethodsSection mb="3XL" />

              <AdvancedSection mb="3XL" />

              <WSErrorMessage
                mb="XL"
                contextKey="InvoiceForm"
                error={error || customError}
              />

              <WSFlexBox.CenterY mb="XS">
                {/* Preview is broken, hidding the button */}
                {/* <WSButton.Secondary
                  mr="M"
                  mb="XL"
                  type="button"
                  onClick={() => {
                    onPreview(step2Context.getValues());
                  }}
                  name="preview"
                >
                  Preview
                </WSButton.Secondary> */}
                <WSButton
                  mr="XL"
                  mb="XL"
                  type="submit"
                  loading={isSubmitting}
                  name="submit"
                >
                  <SubmitButtonText />
                </WSButton>

                {onSave && formContext.isDraft && (
                  <WSButton.Link
                    type="button"
                    mb="XL"
                    loading={isSaving}
                    onClick={() => {
                      step2Context.handleSubmit(onSave)();
                    }}
                  >
                    Save as draft and exit
                  </WSButton.Link>
                )}
              </WSFlexBox.CenterY>

              {/* <WSFormOld.Context>
                  {context => <pre>{JSON.stringify(context, null, 2)}</pre>}
                </WSFormOld.Context> */}
              {/* <WSFormOld.Value name={undefined as any}>
                  {context => <pre>{JSON.stringify(context, null, 2)}</pre>}
                </WSFormOld.Value>
                <pre>{JSON.stringify(scheduledDates, null, 2)}</pre> */}
            </WSCentered>
          </WSContainer>
        </WSLayout>
      )}
    </WSFormOld>
  );
};

const SubmitButtonText = () => {
  const { isDraft, defaultValues } = useInvoicesFormContext();
  const { watch } = useFormContext<InvoicesFormValues>();
  const sendDate: Date = watch("send.date");

  const recurring = watch("recurring");
  const frequency = watch("frequency");
  const sendEmails = watch("advanced.sendEmails");
  const changedData = getChangedData(
    {
      recurring: defaultValues?.recurring,
      frequency: defaultValues?.frequency
    },
    {
      recurring,
      frequency
    },
    {
      dismissTimeInDates: true
    }
  );
  const frequencyWasChanged = Object.keys(changedData).length > 0;

  return (
    <>
      {isDraft
        ? isToday(sendDate)
          ? sendEmails
            ? "Send invoice"
            : "Create payment link"
          : "Schedule invoice"
        : isSameDate(sendDate, defaultValues?.send.date as Date) &&
          !frequencyWasChanged
        ? "Save invoice"
        : isToday(sendDate)
        ? sendEmails
          ? "Send invoice"
          : "Create payment link"
        : "Reschedule invoice"}
    </>
  );
};

const SaveFormChanges: React.FC<{ onChange: (values: any) => void }> = ({
  onChange
}) => {
  const { watch } = useFormContext();

  const valuesRef = useRef({});

  useEffect(() => {
    const interval = setInterval(() => {
      const values = watch();

      if (!isEqual(valuesRef.current, values)) {
        valuesRef.current = values;

        onChange(values);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onChange, watch]);

  return null;
};
