import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { ICollaboratorCSVRow } from "../types";

export function prepareCollaboratorCSVFile(
  filename: string,
  additionalData: ICustomField[],
  data: Array<ICollaboratorCSVRow>,
  options?: { includeErrors?: boolean }
) {
  const w9InfoColumns = [
    { label: "W-9 First Name", key: "w9FirstName" },
    { label: "W-9 Last Name", key: "w9LastName" },
    { label: "Date of Birth", key: "dob" },
    { label: "W-9 Legal Business Name", key: "legalBusinessName" },
    { label: "W-9 Company Structure", key: "companyStructure" },
    { label: "Employee Identification Number", key: "ein" },
    { label: "Social Security Number", key: "ssn" },
    { label: "Address Line1", key: "addressLine1" },
    { label: "Address Line2", key: "addressLine2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Postal Code", key: "postalCode" },
    { label: "Country", key: "country" }
  ];
  const hasW9InfoData = data.some(
    row => row.addressLine1 || row.ein || row.ssn
  );
  return {
    filename,
    headers: [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone number", key: "phoneNumber" },
      { label: "External Contractor ID", key: "contractorId" },
      { label: "Sub Organization Name", key: "organizationAccount" },
      ...(hasW9InfoData ? w9InfoColumns : []),
      ...additionalData.map(field => ({ label: field.name, key: field.key })),
      ...(options?.includeErrors ? [{ label: "Error", key: "error" }] : [])
    ],
    data
  };
}
