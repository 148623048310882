import {
  WSEmptyState,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPage,
  WSPageToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  TaxFormStatus,
  TaxFormType
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React, { useMemo } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { useMemberPrivateFiles } from "../../../query/files/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useQueryAcknowledgement } from "../../../query/onboarding/queries/useQueryAcknowledgement";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";
import { useAllPayerListQuery } from "../../../query/payers/queries/useAllPayerListQuery";
import { usePayeeTaxForms } from "../../../query/taxForm/queries/usePayeeTaxForms";
import { useActivities, useMemberProfile } from "../../../query/users/queries";
import { DataView } from "../../../shared/components/DataView";
import { TextBulleted } from "../../../shared/components/TextBulleted";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { openIntercom } from "../../../shared/utils/intercom";
import { OptOutPayerCard } from "../components/OptOutPayerCard";
import { SelectTaxYear } from "../components/SelectYear";
import { TaxDocumentsDetailsFAQ } from "../components/TaxDocumentsDetailsFAQ";
import { TaxFormCard } from "../components/TaxFormCard";
import { TaxFormT4ACard } from "../components/TaxFormT4ACard";
import { useUpdateDeliveryPreferencesModal } from "../components/useUpdateDeliveryPreferencesModal";
import { selectorT4ADocument } from "../selectors/selectorT4ADocument";
import { useCurrentTaxDocumentsYear } from "../utils/useCurrentTaxDocumentsYear";
import { PATH_SETTINGS_ACCOUNT_INFO } from "../../Settings/paths";

export const RouteIndex: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const currentYear = useCurrentTaxDocumentsYear() as 2024;

  useBrowserPageTitle(`${currentYear} Tax Documents`);

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryFeatureFlags = useFeatureFlags();
  const queryPayeeTaxForms = usePayeeTaxForms({
    year: currentYear
  });
  const queryActivity = useActivities(userId);

  const updateDeliveryPreferencesModal = useUpdateDeliveryPreferencesModal();
  const queryAllPayers = useAllPayerListQuery();

  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryElectronicTaxFormConsentAcknowledgement = useQueryAcknowledgement(
    "ElectronicTaxFormConsent",
    {
      enabled: !!queryMonitoredEntity.data
    }
  );

  const queryFiles = useMemberPrivateFiles({
    filter: {
      hidden: true,
      tags: {
        in: [`payee:${userId}`]
      }
    },
    page: {
      size: 100
    }
  });

  const isUSTaxResident = useMemo(
    () =>
      !queryMonitoredEntity.data?.country ||
      queryMonitoredEntity.data?.country === "US",
    [queryMonitoredEntity.data?.country]
  );

  return (
    <WSPage>
      <WSPageToolbar title="Tax Documents" titleAfter={<SelectTaxYear />} />

      <WSGrid gutter="2XL">
        <WSGrid.Item span={{ m: "8" }}>
          <WSList gap="2XL">
            <WSInfoBox>
              Ensure your personal or business information is up to date for tax
              reporting purposes in{" "}
              <a
                target="_self"
                onClick={() => history.push(PATH_SETTINGS_ACCOUNT_INFO)}
              >
                Account Settings
              </a>
              .
            </WSInfoBox>
            <WSQueries
              queries={{
                queryPayeeTaxForms,
                queryMember,
                queryFeatureFlags,
                queryActivity,
                queryAllPayers,
                queryFiles
              }}
            >
              {({
                queryPayeeTaxFormsData,
                queryMemberData,
                queryFeatureFlagsData,
                queryActivityData,
                queryAllPayersData,
                queryFilesData
              }) => {
                const filteredTaxForms = queryPayeeTaxFormsData.filter(
                  taxForm => {
                    if (taxForm.status === TaxFormStatus.Pending) {
                      return false;
                    }

                    if (taxForm.type === TaxFormType.T4ASlip) {
                      const matchingFile = queryFilesData.find(file =>
                        selectorT4ADocument(file, taxForm.clientId, currentYear)
                      );

                      return !!matchingFile;
                    }

                    return true;
                  }
                );

                const optOutPayers = queryAllPayersData.filter(payer => {
                  const isFilingDisabled =
                    payer.payerSettings?.taxFilingSettings[`${currentYear}`]
                      ?.filing1099Enabled === false;

                  const isT4AFormExist = filteredTaxForms.some(
                    taxForm =>
                      taxForm.clientId === payer.payerId &&
                      taxForm.type === TaxFormType.T4ASlip
                  );

                  return isFilingDisabled && !isT4AFormExist;
                });

                const isElectronicDelivery =
                  queryElectronicTaxFormConsentAcknowledgement.data
                    ?.acknowledgementStatus === "Given" ||
                  queryActivityData.events.electronic1099Consent;

                return (
                  <>
                    <DataView
                      data={[
                        {
                          label: {
                            text: "Delivery preference",
                            action: isUSTaxResident
                              ? {
                                  label: "Edit",
                                  onClick: () => {
                                    updateDeliveryPreferencesModal.open({
                                      mailDelivery: !isElectronicDelivery,
                                      isMonitoredEntity:
                                        !!queryMonitoredEntity.data
                                    });
                                  }
                                }
                              : undefined,
                            actionPosition: "left"
                          },
                          value: isElectronicDelivery
                            ? "Electronic delivery"
                            : "Mail"
                        },
                        ...(isElectronicDelivery
                          ? []
                          : [
                              {
                                label: {
                                  text: "Delivery address",
                                  tooltip: {
                                    title:
                                      "The delivery address is either your personal or business address provided in your account settings. If your address has changed, please update it in your Account Settings to ensure your information is accurate and up to date.",
                                    dark: true
                                  }
                                },
                                value: queryMemberData.profile?.address
                                  ? selectorAddressString(
                                      queryMemberData.profile?.address
                                    )
                                  : "No address provided"
                              }
                            ])
                      ]}
                    />

                    {queryFeatureFlagsData.taxDocumentsSeason2024List ? (
                      filteredTaxForms.length || optOutPayers.length ? (
                        <>
                          {filteredTaxForms.length > 0
                            ? filteredTaxForms.map(taxForm =>
                                taxForm.type === TaxFormType.T4ASlip ? (
                                  <TaxFormT4ACard
                                    key={taxForm.taxFormId}
                                    taxForm={taxForm}
                                    files={queryFilesData}
                                    year={currentYear}
                                  />
                                ) : (
                                  <TaxFormCard
                                    key={taxForm.taxFormId}
                                    taxForm={taxForm}
                                    member={queryMemberData}
                                    activity={queryActivityData}
                                  />
                                )
                              )
                            : null}

                          {optOutPayers.length > 0
                            ? optOutPayers.map(payer => (
                                <OptOutPayerCard
                                  payer={payer}
                                  year={currentYear}
                                />
                              ))
                            : null}
                        </>
                      ) : (
                        <WSEmptyState
                          py="3XL"
                          hasBorder
                          orientation="vertical"
                          fullWidth
                          type="documents"
                          title="No tax documents for this year"
                          description={
                            <WSText.ParagraphSm align="left">
                              <WSList gap="L">
                                <WSText.ParagraphSm>
                                  If you do not expect a Form 1099-NEC for{" "}
                                  {currentYear}, then you’re all set.
                                </WSText.ParagraphSm>
                                <WSText.ParagraphSm>
                                  If you were expecting a Form 1099-NEC for{" "}
                                  {currentYear}, here are a few things you can
                                  do:
                                </WSText.ParagraphSm>
                                <TextBulleted color="gray600">
                                  <li>
                                    <WSText.ParagraphSm weight="medium">
                                      Check your sign-up or sign-in email
                                    </WSText.ParagraphSm>
                                    A Form 1099-NEC is issued to the email that
                                    the payer has on file. Confirm the email and
                                    try signing up or signing in again.
                                  </li>
                                  <li>
                                    <WSText.ParagraphSm weight="medium">
                                      Need more help?
                                    </WSText.ParagraphSm>
                                    If you’ve confirmed your email and you’re
                                    still getting this message, please contact
                                    Wingspan support below and have the payer
                                    name(s) ready so we can assist you.
                                  </li>
                                </TextBulleted>
                              </WSList>
                            </WSText.ParagraphSm>
                          }
                          buttons={[
                            {
                              label: "Contact support",
                              kind: "Secondary",
                              onClick: openIntercom
                            }
                          ]}
                        />
                      )
                    ) : (
                      <WSEmptyState
                        py="3XL"
                        hasBorder
                        orientation="vertical"
                        fullWidth
                        type="documents"
                        title="No tax documents yet for this year"
                        description="Once a tax document is submitted by the payer and becomes available, it will appear here. Ensure your personal or business information is up to date in account settings."
                        buttons={[
                          {
                            label: "View account settings",
                            kind: "Secondary",
                            onClick: async () => {
                              history.push(PATH_SETTINGS_ACCOUNT_INFO);
                            }
                          }
                        ]}
                      />
                    )}
                  </>
                );
              }}
            </WSQueries>
          </WSList>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "4" }}>
          {isUSTaxResident && <TaxDocumentsDetailsFAQ mb="2XL" />}
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
