import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import { QUERY_STATEMENTS } from "../keys";
import { getStatements } from "../../../services/api/banking/statements";
import { useWSInfiniteQuery, useWSQuery } from "../../helpers";
import {
  IStatementListResponse,
  IStatementsRequestQuery
} from "../../../services/api/banking/statements/types";
import { WSServiceError } from "../../../utils/serviceHelper";
import { flatten } from "lodash";

export const useStatementsQuery = (
  queryConfig?: WSQueryConfig<IStatementListResponse, WSServiceError>
) => {
  return useWSQuery(
    QUERY_STATEMENTS,
    async () => await getStatements(),
    queryConfig
  );
};

const PAGE_SIZE = 20;

export const useInfinityStatementsQuery = (
  query?: IStatementsRequestQuery,
  queryConfig?: WSInfiniteQueryConfig<IStatementListResponse, WSServiceError>
) => {
  const queryResponse = useWSInfiniteQuery<
    IStatementListResponse,
    WSServiceError
  >(
    [QUERY_STATEMENTS, query],
    async ({ pageParam = 1 }) => {
      return await getStatements({
        ...query,
        page: {
          size: PAGE_SIZE,
          number: pageParam
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...queryConfig
    }
  );

  return {
    ...queryResponse,
    data: queryResponse.data ? flatten(queryResponse.data.pages) : undefined
  };
};
