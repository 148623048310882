import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  useWSModal,
  WSActions,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";

import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkImportResource, BulkResource } from "../../types";

interface ConfirmIMportModalProps {
  bulkBatchId: string;
  bulkResource: BulkImportResource;
  onClose: () => void;
}

const ConfirmIMportModal: React.FC<ConfirmIMportModalProps> = ({
  bulkBatchId,
  bulkResource,
  onClose
}) => {
  const { useUpdateBulkBatch } = getBulkQueriesByResource(bulkResource);

  const [updateBulkBatch, updateBulkBatchMeta] = useUpdateBulkBatch();

  const onConfirm = async () => {
    await updateBulkBatch({
      id: bulkBatchId,
      data: {
        status: BulkStatus.Pending
      }
    });
  };

  const labels = {
    [BulkResource.Payable]: "payables",
    [BulkResource.Collaborator]: "contractors"
  };

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm mb="L" weight="book">
        {`Are you sure you want to import ${labels[bulkResource]} from this uploaded spreadsheet?`}
      </WSText.ParagraphSm>

      <WSInfoBox>
        This action can't be rolled back, it will import all uploaded items from
        this batch until the process is complete and will create or update{" "}
        {labels[bulkResource]} records. If import errors occur, please review
        the spreadsheet details and try again. If additional updates need to be
        made after the import, start a new batch with the changes.
      </WSInfoBox>

      <WSErrorMessage
        error={updateBulkBatchMeta.error}
        mb="XL"
        contextKey="BulkBatchStartImport"
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Confirm & continue",
            onClick: onConfirm,
            loading: updateBulkBatchMeta.isLoading,
            kind: "Primary"
          },
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Tertiary"
          }
        ]}
      />
    </WSList>
  );
};

export const useConfirmImportModal = (bulkResource: BulkImportResource) => {
  const labels = {
    [BulkResource.Payable]: "Import payables",
    [BulkResource.Collaborator]: "Import contractors"
  };
  return useWSModal(ConfirmIMportModal, {
    title: labels[bulkResource],
    size: "S"
  });
};
