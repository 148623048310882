import {
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IUserCreateRequest } from "@wingspanhq/users";
import { UserAccountType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import React, { useEffect, useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { QUERY_USERS_SESSION } from "../../../query/session";
import {
  useCreateSession,
  useCreateUser,
  useIdentifyAuthentication,
  useSignInWithGoogle
} from "../../../query/users/mutations";
import { pushSessionToken } from "../../../services/sessionStorage";
import { ISignInWithGoogleRequest } from "../../../services/users";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../shared/validators/validatorPassword";
import { useSetWSStore, useWSStore } from "../../../store";
import { WS_LINKS } from "../../../types/wsLinks";
import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { NOT_YOU_MODAL, NotYouModal } from "../../screens/NotYouModal";
import { GoogleAuthButton } from "../GoogleAuthButton/GoogleAuthButton";
import styles from "./SignUpForm.module.scss";

interface SignUpFormData {
  email: string;
  password: string;
}

export interface SignUpFormProps {
  defaultValues?: Partial<SignUpFormData>;
  title?: string;
  description?: string;
  buttonText?: string;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({
  defaultValues = {},
  title = "Sign up",
  description,
  buttonText = "Try Wingspan free"
}) => {
  const setStore = useSetWSStore();
  const store = useWSStore();

  const { openModal, closeModal } = useModalOldContext();

  const isMobile = useIsMobile();

  const history = useHistory();
  const location = useLocation();

  const [showInviteCodeField, setShowInviteCodeField] = useState(false);
  const [showInviteCodeFieldForGoogle, setShowInviteCodeFieldForGoogle] =
    useState(false);
  const [showHelpMe, setShowHelpMe] = useState(false);
  useEffect(() => {
    if (location.hash === "#invite-code") {
      isDoingSignUpWithGoogle
        ? setShowInviteCodeFieldForGoogle(true)
        : setShowInviteCodeField(true);
    } else if (location.hash === "#help-me") {
      setShowHelpMe(true);
    }
  }, [location.hash]);
  const [isHelpMeSent, setIsHelpMeSent] = useState(false);

  const [googleInviteCode, setGoogleInviteCode] = useState("");
  const [signUpWithGoogleError, setSignUpWithGoogleError] = useState("");
  const [captchaLoadError, setCaptchaLoadError] = useState(false);
  const [ssoData, setSsoData] = useState({
    url: "",
    domain: ""
  });

  const [createUser, createUserMeta] = useCreateUser();
  const [createSession, createSessionMeta] = useCreateSession();

  const [isDoingSignUpWithGoogle, setIsDoingSignUpWithGoogle] = useState(false);
  const [signUpWithGoogle, signUpWithGoogleMeta] = useSignInWithGoogle();

  const [identifyAuth, identifyAuthMeta] = useIdentifyAuthentication();

  const analytics = useWSAnalytics();

  const [oldEmail, setOldEmail] = useState(defaultValues.email);

  useEffect(() => {
    if (createSessionMeta.error?.message === "Captcha loading error") {
      setCaptchaLoadError(true);
    }
  }, [createSessionMeta.error?.message]);

  const onGoogleLoginSuccess = (request: ISignInWithGoogleRequest) => {
    setIsDoingSignUpWithGoogle(true);
    signUpWithGoogle(
      {
        ...request,
        ...(googleInviteCode ? { inviteCode: googleInviteCode } : {}),
        ...(store.isEmployerSignUp
          ? { context: "EmployerSignUp" }
          : { context: "PublicSignUp" })
      },
      {
        onSuccess: async response => {
          setStore({ signUpMethod: "Google" });
          analytics.track.signUp();
          history.replace("/member/dashboard");
        }
      }
    );
  };
  const onGoogleLoginFailure = (errorRes: any) => {
    setSignUpWithGoogleError(errorRes.error);
  };

  const onFormSubmit = async (data: SignUpFormData) => {
    createUserMeta.reset();
    createSessionMeta.reset();
    setCaptchaLoadError(false);
    setIsDoingSignUpWithGoogle(false);

    const request: IUserCreateRequest & {
      context?: string;
      inviteCode?: string;
      isPublicSignUp?: boolean;
    } = {
      ...data,
      notificationSettings: {
        reviewNotifications: true,
        newsletters: true
      },
      context: "PublicSignUp",
      ...(store.isEmployerSignUp
        ? {
            context: "EmployerSignUp",
            settings: { userAccountType: UserAccountType.enterprise }
          }
        : {})
    };

    try {
      const identifyResult = await identifyAuth(
        { email: request.email },
        {
          throwOnError: true
        }
      );

      if (identifyResult?.authenticationStrategy === "SingleSignOn") {
        setSsoData({
          url: identifyResult?.authenticationUrl || "",
          domain: request.email.split("@").pop() || ""
        });
        return;
      } else {
        setSsoData({
          url: "",
          domain: ""
        });
      }
    } catch {
      setSsoData({
        url: "",
        domain: ""
      });
    }

    await createUser(request, {
      onSuccess: async user => {
        if (user.session?.token) {
          pushSessionToken(user.session.token);
          WSQueryCache.setQueryData(QUERY_USERS_SESSION, user.session);
        } else {
          await createSession(data);
        }

        setStore({ signUpMethod: "Email" });
        analytics.track.signUp();
      },
      onError: async error => {
        if (
          error.response?.status === 401 &&
          error.response?.data?.errorSubType === "EmailVerificationRequired"
        ) {
          history.push({
            pathname: "/member/verification-sent-to-email",
            search: history.location.search,
            state: {
              email: request.email,
              growthSource: store.growthAttributionDetails?.growthSource
            }
          });
        }
      }
    });
  };

  return (
    <WSFlexBox.CenterX
      ml={isMobile ? "NONE" : "3XL"}
      direction="column"
      px={isMobile ? "L" : "XL"}
      py="XL"
      className={styles.formContainer}
      alignItems="stretch"
    >
      <WSText.Heading4 mb="L">{title}</WSText.Heading4>
      {showHelpMe ? (
        <>
          <WSText.Paragraph mb="L">Need help signing up?</WSText.Paragraph>
          <WSText.ParagraphSm mb="XL">
            Submit your information so we can verify it with your payer company.
          </WSText.ParagraphSm>
          <WSFormOld
            defaultValues={{
              fullName: "",
              email: "",
              payerName: "",
              phoneNumber: ""
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string().required("Full name is required"),
              email: validatorEmail.required("Email is required"),
              payerName: Yup.string().required("Payer name is required"),
              phoneNumber: Yup.string().required("Phone number is required")
            })}
            onSubmit={async (data: any) => {
              analytics.track.signUpHelp(data);
              setIsHelpMeSent(true);
            }}
          >
            <WSFormOld.Field
              mb="L"
              name="fullName"
              label="Full name"
              component={WSTextInput}
              componentProps={{
                type: "text",
                className: styles.input
              }}
            />
            <WSFormOld.Field
              mb="L"
              name="email"
              label="Email address"
              component={WSTextInput}
              componentProps={{
                type: "email",
                className: styles.input
              }}
            />
            <WSFormOld.Field
              mb="L"
              name="payerName"
              label="Payer name"
              component={WSTextInput}
              componentProps={{
                type: "text",
                className: styles.input
              }}
            />
            <WSFormOld.Field
              mb="XL"
              name="phoneNumber"
              label="Phone number"
              component={WSTextInput}
              componentProps={{
                type: "tel",
                className: styles.input
              }}
            />
            {isHelpMeSent ? (
              <>
                <WSText mb="M">
                  <WSIcon block mr="S" size="M" name="check" color="green300" />
                  Thank you!
                </WSText>
                <WSText.ParagraphSm>
                  We will contact you via the email and phone number you
                  provided within one business day
                </WSText.ParagraphSm>
              </>
            ) : (
              <WSFormOld.SubmitButton name="helpMe" mt="2XL" fullWidth>
                Submit
              </WSFormOld.SubmitButton>
            )}
          </WSFormOld>
        </>
      ) : (
        <>
          {store.is1099SignUp && description ? (
            <WSText mb="2XL">{description}</WSText>
          ) : null}
          {!store.is1099SignUp && (
            <WSText color="gray700" mb="2XL">
              Already a member?{" "}
              <WSButton.Link
                onClick={() =>
                  history.push(
                    store.signUpAutopayDetails
                      ? `/member/sign-in#/member/autopay/${store.signUpAutopayDetails?.payeeId}`
                      : "/member/sign-in"
                  )
                }
              >
                Sign in.
              </WSButton.Link>
            </WSText>
          )}
          <GoogleAuthButton
            onLoginSuccess={onGoogleLoginSuccess}
            onLoginFailure={onGoogleLoginFailure}
          >
            Sign up with Google
          </GoogleAuthButton>
          {showInviteCodeFieldForGoogle ? (
            <>
              <WSElement mt="XL">
                <WSFormOld.Label>Invite Code</WSFormOld.Label>
                <WSTextInput
                  mb="M"
                  type="password"
                  className={styles.input}
                  onChange={event => setGoogleInviteCode(event.target.value)}
                />
                <WSText.Paragraph color="gray500">
                  If you don't have an invite code and need help, please{" "}
                  <WSButton.Link onClick={() => history.push("#help-me")}>
                    click here.
                  </WSButton.Link>
                </WSText.Paragraph>
              </WSElement>
            </>
          ) : null}
          <WSDivider my="XL" label="OR" />
          <WSFormOld
            defaultValues={{
              email: "",
              password: "",
              remember: true,
              ...defaultValues
            }}
            validationSchema={Yup.object().shape({
              email: validatorEmail.required("Email is required"),
              password: validatorPassword.required("Password is required"),
              remember: Yup.boolean()
            })}
            onSubmit={onFormSubmit}
          >
            <WSFormOld.Field
              mb="L"
              name="email"
              label="Email address"
              component={WSTextInput}
              hidden={!!oldEmail}
              componentProps={{
                type: "email",
                className: styles.input
              }}
            />

            {oldEmail ? (
              <WSFlexBox.CenterY
                py="M"
                mb="L"
                className={styles.emailPreview}
                wrap="nowrap"
              >
                <WSFormOld.Context>
                  {({ setValue }) => (
                    <NotYouModal
                      email={oldEmail}
                      companyName={"this company"}
                      onLeave={() => closeModal(NOT_YOU_MODAL)}
                      onUpdate={() => {
                        createUserMeta.reset();
                        createSessionMeta.reset();
                        setOldEmail("");
                        setValue("email", "");
                      }}
                    />
                  )}
                </WSFormOld.Context>
                <WSFlexBox.Center mr="M" className={styles.userIcon}>
                  <WSIcon block name="user" color="gray400" />
                </WSFlexBox.Center>

                <WSElement className={styles.userDetails}>
                  <WSText singleLine>{oldEmail}</WSText>
                  <WSButton.Link
                    type="button"
                    onClick={() => {
                      openModal(NOT_YOU_MODAL);
                    }}
                  >
                    Not you?
                  </WSButton.Link>
                </WSElement>
              </WSFlexBox.CenterY>
            ) : null}
            <WSFormOld.Field
              mb="L"
              name="password"
              label="Create a password"
              component={WSTextInput}
              componentProps={{
                type: "password",
                className: styles.input
              }}
            />

            {ssoData.url ? (
              <WSText.ParagraphSm color="red400" mb="XL">
                Your domain <b>{ssoData.domain}</b> has SSO enabled.{" "}
                <WSButton.Link
                  size="S"
                  onClick={() => {
                    window.location.href = ssoData.url;
                  }}
                  rightIcon="arrow-right"
                >
                  Sign in with SSO
                </WSButton.Link>
              </WSText.ParagraphSm>
            ) : null}

            <WSFormOld.Field
              mb={showInviteCodeField ? "XL" : "2XL"}
              name="remember"
              component={WSCheckboxToggle}
              componentProps={{ label: "Stay signed in" }}
            />

            {showInviteCodeField ? (
              <WSElement mb="2XL">
                <WSFormOld.Field
                  mb="M"
                  name="inviteCode"
                  label="Invite code"
                  component={WSTextInput}
                  componentProps={{
                    type: "password",
                    className: styles.input
                  }}
                />
                <WSText.Paragraph color="gray500">
                  If you don't have an invite code and need help, please{" "}
                  <WSButton.Link onClick={() => history.push("#help-me")}>
                    click here.
                  </WSButton.Link>
                </WSText.Paragraph>
              </WSElement>
            ) : null}

            <WSErrorMessage
              my="XL"
              contextKey="Auth"
              forceShowApiErrors
              error={
                createUserMeta.error ||
                createSessionMeta.error ||
                signUpWithGoogleMeta.error ||
                signUpWithGoogleError
              }
            />

            <WSButton
              name="signUp"
              size="L"
              fullWidth
              type="submit"
              className={styles.tryWSBtn}
              loading={createSessionMeta.isLoading || createUserMeta.isLoading}
            >
              {buttonText}
            </WSButton>

            {captchaLoadError && (
              <WSText color="garnet" mb="XL">
                Sorry, something went wrong.{" "}
                <WSButton.Link
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload and try again
                </WSButton.Link>
              </WSText>
            )}
          </WSFormOld>
          <WSText.ParagraphSm mt="3XL" color="gray600">
            By proceeding you agree to Wingspan's{" "}
            <a
              href={WS_LINKS.wingspanTosAcceptance}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>{" "}
            {"& "}
            <a
              href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            . This site is protected by reCAPTCHA and the Google{" "}
            <a
              href={WS_LINKS.googlePrivacyPolicy}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={WS_LINKS.googleTermOfService}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            apply.
          </WSText.ParagraphSm>
        </>
      )}
    </WSFlexBox.CenterX>
  );
};
