import {
  useIsDesktop,
  WSButton,
  WSButtons,
  WSCentered,
  WSCheckboxToggle,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSPanel,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import { IIntegrationCustomer } from "@wingspanhq/integrations/dist/lib/interfaces/customer";
import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { getSortedAndGroupedQuickbooksUserList } from "../../../../modules/Integrations/utils/getSortedAndGroupedQuickbooksUserList";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers
} from "../../../../query/integrations/queries";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import { useQueryPayerRowsAll } from "../../../../query/search/payer/queries/useQueryPayerRowsAll";
import { createPayer } from "../../../../services/payers";
import { openIntercom } from "../../../../shared/utils/intercom";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";
import { getChangedData } from "../../../../utils/getChangedData";
import {
  REGEX_NUMBER_SYMBOLS_TEXT_EXT,
  REGEX_TEXT_ONLY
} from "../../../../utils/validators";

interface FormData {
  [key: string]: string;
}

export const QuickbooksSetupStep5: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const queryPayers = useQueryPayerRowsAll();
  const isDesktop = useIsDesktop();

  const [
    successfullyCreatedPayerCustomerIDs,
    setSuccessfullyCreatedPayerCustomerIDs
  ] = useState<string[]>([]);

  const [error, setError] = useState("");

  const createPayerAction = bulkActionFactory({
    onStart() {
      setError("");
    },
    async action(customer: IIntegrationCustomer & { email: string }) {
      if (
        customer.email !== undefined &&
        !successfullyCreatedPayerCustomerIDs.includes(customer.customerId)
      ) {
        let cleanedName = customer.displayName;
        if (cleanedName && !REGEX_TEXT_ONLY.test(cleanedName)) {
          cleanedName = [...cleanedName]
            .filter(c => REGEX_TEXT_ONLY.test(c))
            .join("");
        }

        let cleanedCompanyName = customer.companyName;
        if (
          cleanedCompanyName &&
          !REGEX_NUMBER_SYMBOLS_TEXT_EXT.test(cleanedCompanyName)
        ) {
          cleanedCompanyName = [...cleanedCompanyName]
            .filter(c => REGEX_NUMBER_SYMBOLS_TEXT_EXT.test(c))
            .join("");
        }

        await createPayer({
          email: customer.email,
          payeeOwnedData: {
            payerCompanyName: cleanedCompanyName,
            payerName: cleanedName,
            integration: {
              quickbooks: {
                customerId: customer.customerId
              }
            }
          }
        });

        setSuccessfullyCreatedPayerCustomerIDs(
          successfullyCreatedPayerCustomerIDs => [
            ...successfullyCreatedPayerCustomerIDs,
            customer.customerId
          ]
        );
      }
    },
    onFinish({ successItems, failedItems }) {
      if (failedItems.length) {
        setError(
          `Sorry! Something went wrong while trying to create contractors for ${failedItems
            .map(item => item.email)
            .join(
              ", "
            )}. Please try again, or if this error persists, please remove from your list, continue, and contact support for a follow up.`
        );
      } else {
        history.push("/member/settings/integrations/quickbooks/setup/step/6");
      }
    }
  });

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 5}>
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksCustomers,
          queryPayers
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksCustomers,
          queryPayers: { data: payers }
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;

          const customers = getSortedAndGroupedQuickbooksUserList(
            qIntegrationsQuickbooksCustomers.data.filter(
              v => v.primaryEmailAddress
            )
          )
            .filter(
              v =>
                !payers.find(
                  payer =>
                    payer.payeeOwnedData?.integration?.quickbooks
                      ?.customerId === v.customerId
                )
            )
            .map(v => ({ email: v.primaryEmailAddress?.split(",")[0], ...v }));

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          const defaultFormData = customers.reduce(
            (res, customer) => ({
              ...res,
              [customer.customerId]: payers.find(
                payer =>
                  payer.payeeOwnedData?.integration?.quickbooks?.customerId ===
                  customer.customerId
              )
            }),
            {}
          );

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  5 of 8
                </WSText.ParagraphSm>
                <WSText.Heading4>Map Customers</WSText.Heading4>

                <WSPanel mt="2XL" mb="5XL">
                  <WSFormOld
                    defaultValues={defaultFormData}
                    onSubmit={async (formData: FormData) => {
                      const changedData = getChangedData(
                        defaultFormData,
                        formData
                      );
                      const customersToCreate = Object.keys(changedData).filter(
                        key => changedData[key]
                      );
                      await createPayerAction.run(
                        customers.filter(customer =>
                          customersToCreate.includes(customer.customerId)
                        )
                      );
                    }}
                  >
                    <WSText.Heading5 mt="XL">
                      Import your Quickbooks Customers and associate them to
                      Wingspan Clients
                    </WSText.Heading5>

                    {customers.length > 0 ? (
                      <>
                        <WSText mt="3XL">
                          For Customers that weren't mapped, select those to
                          import and create in Wingspan.
                        </WSText>
                        <WSText mt="XL">
                          Just a note, we can only import Customers from QBO
                          that have an email address filled out. We may also
                          have to make changes to the Customer's information to
                          match our validation requirements. For instance, no
                          special characters, etc.
                        </WSText>

                        <WSFlexBox.CenterY justify="flex-end" my="XL">
                          <WSFormOld.Context>
                            {({ setValue }) => {
                              const names = customers
                                .filter(
                                  customer =>
                                    !successfullyCreatedPayerCustomerIDs.includes(
                                      customer.customerId
                                    )
                                )
                                .map(customer => customer.customerId);

                              return (
                                <WSFormOld.Values names={names}>
                                  {values => {
                                    const currentValue =
                                      Object.values(values).every(Boolean);

                                    return (
                                      <>
                                        <WSText mr="M" color="gray500">
                                          {currentValue
                                            ? "Unselect "
                                            : "Select "}
                                          All
                                        </WSText>
                                        <WSCheckboxToggle
                                          label=""
                                          mr={isDesktop ? "M" : "NONE"}
                                          name="selectAll"
                                          value={currentValue}
                                          onChange={() => {
                                            Object.keys(values).forEach(key => {
                                              setValue(key, !currentValue);
                                            });
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                </WSFormOld.Values>
                              );
                            }}
                          </WSFormOld.Context>
                        </WSFlexBox.CenterY>

                        <WSTable
                          mt="XL"
                          tableData={customers.map(
                            (customer: IIntegrationCustomer) => ({
                              id: customer.customerId,
                              data: customer
                            })
                          )}
                          columns={[
                            {
                              config: {
                                gridTemplateSizeMax: "1fr",
                                header: { text: "Customer" }
                              },
                              renderFunction: ({ data }) => {
                                const customer: IIntegrationCustomer = data;
                                return (
                                  <WSTableCell
                                    avatar={
                                      successfullyCreatedPayerCustomerIDs.includes(
                                        customer.customerId
                                      )
                                        ? {
                                            type: "icon",
                                            color: "gray500",
                                            colorBackground: "green50",
                                            icon: "check"
                                          }
                                        : {
                                            type: "text",
                                            text: customer.displayName || ""
                                          }
                                    }
                                    text={customer.displayName}
                                    secondaryText={data.primaryEmailAddress}
                                  />
                                );
                              }
                            },
                            {
                              config: {
                                gridTemplateSizeMax: "1fr",
                                justify: "end",
                                header: { text: "Import?" }
                              },
                              renderFunction: ({ data }) =>
                                successfullyCreatedPayerCustomerIDs.includes(
                                  data.customerId
                                ) ? (
                                  <></>
                                ) : (
                                  <WSFormOld.Field
                                    name={data.customerId}
                                    component={WSCheckboxToggle}
                                    componentProps={{ label: "" }}
                                  />
                                )
                            }
                          ]}
                        />

                        <WSErrorMessage
                          mt="2XL"
                          contextKey="IntegrationsQuickbooksMapping"
                          error={error}
                        />
                      </>
                    ) : (
                      <>
                        <WSText mt="XL">
                          Ah! It appears you've already mapped all available
                          Customers. That's great! If you think this is an
                          error, please{" "}
                          <WSButton.Link onClick={openIntercom}>
                            contact support
                          </WSButton.Link>
                        </WSText>
                      </>
                    )}

                    <WSFlexBox.Center mt="2XL">
                      <WSButtons>
                        <WSFormOld.SubmitButton name="next">
                          Next
                        </WSFormOld.SubmitButton>
                      </WSButtons>
                    </WSFlexBox.Center>
                  </WSFormOld>
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
    </ProjectOnboardingLayout>
  );
};
