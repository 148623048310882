import { WSAlert } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";

export const AlertEnableTaxWithholdings = () => {
  const history = useHistory();

  return (
    <WSAlert
      theme="warning"
      icon="alert"
      title="Tax withholdings is not enabled"
      action={{
        text: "Learn more",
        onClick: () => {
          history.push("/member/taxes");
        }
      }}
    >
      Enable tax withholdings today to ensure you’re setting aside enough for{" "}
      {new Date().getFullYear()} taxes.
    </WSAlert>
  );
};
