import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  WSButton,
  WSFlexBox,
  WSText,
  WSControlGroup,
  WSCard,
  WSElement
} from "@wingspanhq/fe-component-library";
import { BulkPayableStatus } from "@wingspanhq/payments/dist/interfaces";

import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { useUpdateBulkPayableBatch } from "../../../../modules/BulkImporter/query/bulkPayable/mutations";
import { useUserId } from "../../../../query/hooks/helpers";
import { Layout } from "../../../../shared/components/Layout";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";

import { BulkUploadHeader } from "../../bulkUploadCollaborators/BulkUploadHeader";

import styles from "./bulkUploadPayables.module.scss";
import { useGoToContractorImportStart } from "../../../../modules/BulkImporter/paths";

export interface BulkUploadPayablesStatusProps {}

export const BulkUploadPayablesStatus: React.FC<
  BulkUploadPayablesStatusProps
> = () => {
  useBrowserPageTitle("Wingspan - Bulk import payables");
  const userId = useUserId();
  const [status, setStatus] = useLocalStorage(
    `${userId}:bulk-payable-status`,
    BulkPayableStatus.Draft
  );
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const bulkBatchId = match.params.bulkBatchId;
  const goToContractorImportStart = useGoToContractorImportStart();

  const [updateBulkPayableBatch, updateBulkPayableBatchMeta] =
    useUpdateBulkPayableBatch();

  const gotoBulkBatchDetails = async () => {
    await updateBulkPayableBatch(
      {
        id: bulkBatchId,
        data: {
          labels: {
            payableStatus: status
          }
        }
      },
      {
        onSuccess: () => {
          history.push(`/member/imports/payables/${bulkBatchId}`);
        }
      }
    );
  };

  return (
    <Layout customHeader={<BulkUploadHeader progress={(100 / 3) * 2} />} wide>
      <WSText.Heading4 mb="M">
        Bulk upload payables with a spreadsheet
      </WSText.Heading4>

      <WSText.ParagraphSm color="gray500" mb="L">
        Wingspan's payables bulk upload feature allows businesses to upload
        multiple payables at once.
      </WSText.ParagraphSm>

      <WSCard className={styles.panel} mb="2XL">
        <WSText.ParagraphSm color="gray500" mb="L">
          Select status for uploaded payables
        </WSText.ParagraphSm>
        <WSControlGroup
          mb="XL"
          name="status"
          type="radio"
          value={status}
          chip={true}
          onChange={(newStatus: any) => {
            setStatus(newStatus);
          }}
          options={[
            {
              value: BulkPayableStatus.Draft,
              label: BulkPayableStatus.Draft,
              description: (
                <WSText.ParagraphSm color="gray500">
                  Payables will be hidden to contractors until it is opened by
                  you or a team member
                </WSText.ParagraphSm>
              )
            },
            {
              value: BulkPayableStatus.Open,
              label: BulkPayableStatus.Open,
              description: (
                <WSText.ParagraphSm color="gray500">
                  Contractors can see payables, but it will not be paid until it
                  is approved by you or a team member
                </WSText.ParagraphSm>
              )
            },
            {
              value: BulkPayableStatus.Approved,
              label: BulkPayableStatus.Approved,
              description: (
                <WSText.ParagraphSm color="gray500">
                  Contractors will automatically be paid on the next payroll
                  date
                </WSText.ParagraphSm>
              )
            },
            {
              value: BulkPayableStatus.Paid,
              label: `${BulkPayableStatus.Paid} (Historical upload)`,
              description: (
                <>
                  <WSText.ParagraphSm color="gray500">
                    Payables will be visible to contractors and marked as paid
                    off of Wingspan
                  </WSText.ParagraphSm>

                  <WSElement
                    className={styles.elem}
                    p="M"
                    colorBackground="gray50"
                  >
                    Historical uploads are typically used for recording past
                    payments. Companies looking to file Form 1099-NECs can use
                    this option to include payments paid off of Wingspan for a
                    given filing year.
                  </WSElement>
                </>
              )
            }
          ]}
        />
      </WSCard>
      <WSButton
        mb="XL"
        name="importPayablesAndDeductions"
        icon="upload"
        fullWidth
        loading={updateBulkPayableBatchMeta.isLoading}
        onClick={gotoBulkBatchDetails}
      >
        Upload Payables
      </WSButton>
      <WSFlexBox.CenterY mb="L">
        <WSText.ParagraphSm singleLine color="gray500">
          Looking for past imports?
        </WSText.ParagraphSm>
        <WSButton.Link
          icon="details"
          rightIcon="chevron-right"
          size="M"
          name="viewPayablesImports"
          onClick={() => history.push("/member/imports/payables")}
        >
          See imports
        </WSButton.Link>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY>
        <WSText.ParagraphSm singleLine color="gray500">
          Looking to bulk upload contractors?
        </WSText.ParagraphSm>
        <WSButton.Link
          icon="upload"
          rightIcon="chevron-right"
          size="M"
          name="bulkUploadContractors"
          onClick={goToContractorImportStart}
        >
          Bulk upload contractors
        </WSButton.Link>
      </WSFlexBox.CenterY>
    </Layout>
  );
};
