import { useHistory } from "react-router-dom";
import { PATH_SETTINGS } from ".";

// Account settings

export const PATH_SETTINGS_ACCOUNT = PATH_SETTINGS + "/account";
export const PATH_SETTINGS_ACCOUNT_ACCOUNT = PATH_SETTINGS_ACCOUNT + "/account";
export const PATH_SETTINGS_ACCOUNT_INFO = PATH_SETTINGS_ACCOUNT + "/info";

export const PATH_SETTINGS_ACCOUNT_PERSONAL =
  PATH_SETTINGS_ACCOUNT + "/personal";
export const PATH_SETTINGS_ACCOUNT_BUSINESS =
  PATH_SETTINGS_ACCOUNT + "/business";
export const PATH_SETTINGS_ACCOUNT_MARKETING =
  PATH_SETTINGS_ACCOUNT + "/marketing";
export const PATH_SETTINGS_ACCOUNT_MEMBERSHIP =
  PATH_SETTINGS_ACCOUNT + "/membership";

export const useGotoAccountInfo = () => {
  const history = useHistory();
  return () => history.push(PATH_SETTINGS_ACCOUNT_INFO);
};

// Payroll settings
export const PATH_SETTINGS_PAYROLL = PATH_SETTINGS + "/payroll";
export const PATH_SETTINGS_PAYROLL_METHOD = PATH_SETTINGS_PAYROLL + "/funding";
export const PATH_SETTINGS_PAYROLL_SCHEDULE =
  PATH_SETTINGS_PAYROLL + "/schedule";
export const PATH_SETTINGS_PAYROLL_PAYABLES =
  PATH_SETTINGS_PAYROLL + "/payables";

export const PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD =
  PATH_SETTINGS_PAYROLL + "/manage-funding-method";
export const PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD =
  PATH_SETTINGS_PAYROLL + "/add-funding-method";

export const PATH_SETTINGS_EMAIL_INVITES =
  `${PATH_SETTINGS_PAYROLL}/email-invites` as const;
export const PATH_SETTINGS_EMAIL_INVITES_ADD_SNIPPET =
  `${PATH_SETTINGS_EMAIL_INVITES}/add-snippet` as const;
export const PATH_SETTINGS_EMAIL_INVITES_EDIT_SNIPPET =
  `${PATH_SETTINGS_EMAIL_INVITES}/edit-snippet` as const;

export const useGotoEmailInvites = () => {
  const history = useHistory();
  return () => history.push(PATH_SETTINGS_EMAIL_INVITES);
};

export const useGotoEmailInvitesAddOrUpdateSnippet = () => {
  const history = useHistory();
  return () => history.push(PATH_SETTINGS_EMAIL_INVITES_EDIT_SNIPPET);
};
