import { IIntegrationsQuicbooksSyncActivityAction } from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooksSyncActivity";
import flatten from "lodash/flatten";
import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import {
  DateRangeFilter,
  getDateRangeFilterRanges
} from "../../Invoices/utils";
import {
  getIntegrationsQuickbooks,
  getIntegrationsQuickbooksAccountAsset,
  getIntegrationsQuickbooksAccountAssets,
  getIntegrationsQuickbooksAccountExpense,
  getIntegrationsQuickbooksAccountExpenses,
  getIntegrationsQuickbooksAccountLiabilities,
  getIntegrationsQuickbooksAccountLiability,
  getIntegrationsQuickbooksAccountRevenue,
  getIntegrationsQuickbooksCustomer,
  getIntegrationsQuickbooksCustomers,
  getIntegrationsQuickbooksItem,
  getIntegrationsQuickbooksItems,
  getIntegrationsQuickbooksSyncActivities,
  getIntegrationsQuickbooksVendor,
  getIntegrationsQuickbooksVendors,
  IntegrationsQuickbooksSyncActivitiesFilter,
  IntegrationsQuickbooksSyncActivitiesWithSumamry
} from "../../services/integrations";
import { Await } from "../../utils";
import { getAllEntries, WSServiceError } from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_INTEGRATIONS_QUICKBOOKS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSET,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSE,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITY,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUE,
  QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMER,
  QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ITEM,
  QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS,
  QUERY_INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES,
  QUERY_INTEGRATIONS_QUICKBOOKS_VENDOR,
  QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS
} from "./keys";

export const useIntegrationsQuickbooks = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooks>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS,
    getIntegrationsQuickbooks,
    queryConfig
  );

export interface IntegrationsQuickbooksSyncActivitiesQueryFilters {
  onlyErrors: boolean;
  actions: IIntegrationsQuicbooksSyncActivityAction[];
  createdAtRange: DateRangeFilter;
  customCreatedAtRange?: Date[];
  entityId?: string;
  displayNameSearch?: string;
}

export const INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT: IntegrationsQuickbooksSyncActivitiesQueryFilters =
  {
    onlyErrors: false,
    actions: [
      IIntegrationsQuicbooksSyncActivityAction.Create,
      IIntegrationsQuicbooksSyncActivityAction.Delete,
      IIntegrationsQuicbooksSyncActivityAction.Update
    ],
    createdAtRange: DateRangeFilter.All
  };

export const useIntegrationsQuickbooksSyncActivities = (
  filters?: IntegrationsQuickbooksSyncActivitiesQueryFilters,
  config?: { size?: number } & WSInfiniteQueryConfig<
    IntegrationsQuickbooksSyncActivitiesWithSumamry,
    WSServiceError
  >
) => {
  // const userId = useUserId();

  const _filters =
    filters || INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT;

  const queryKey = QUERY_INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES;
  const size = config?.size || 100;

  const query = useWSInfiniteQuery<
    IntegrationsQuickbooksSyncActivitiesWithSumamry,
    WSServiceError
  >(
    [queryKey, { filters, size }],
    ({ pageParam = 1 }) => {
      const filter: IntegrationsQuickbooksSyncActivitiesFilter = {
        // userId
      };

      const createdAtRange = getDateRangeFilterRanges(
        _filters.createdAtRange,
        _filters.customCreatedAtRange
      );

      if (createdAtRange) {
        filter["createdAt"] = {
          ">": createdAtRange[0],
          "<": createdAtRange[1]
        };
      }

      if (_filters.entityId) {
        filter["entityId"] = _filters.entityId;
      }

      const allowedActions: IIntegrationsQuicbooksSyncActivityAction[] = [];

      if (_filters.actions) {
        _filters.actions.forEach(action => allowedActions.push(action));
        filter.action = {
          in: allowedActions
        };
      }

      if (_filters.onlyErrors) {
        filter.isError = true;
      }

      if (_filters.displayNameSearch) {
        filter.displayName = {
          like: _filters.displayNameSearch
        };
      }

      return getIntegrationsQuickbooksSyncActivities({
        filter,
        page: {
          size,
          number: pageParam
        },
        sort: {
          updatedAt: "desc"
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data
      ? {
          data: flatten(query.data.pages?.map(page => page.data)),
          summary: query.data.pages?.[0]?.summary
        }
      : undefined
  };
};

export const useIntegrationsQuickbooksSyncActivitiesPreview = (
  queryConfig?: WSQueryConfig<any, WSServiceError>
) => {
  return useIntegrationsQuickbooksSyncActivities(
    INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
    {
      size: 2,
      ...queryConfig
    }
  );
};

export const useIntegrationsQuickbooksAccountAssets = (
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS,
    () => getAllEntries(getIntegrationsQuickbooksAccountAssets),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountAsset = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSET, id],
    async () => await getIntegrationsQuickbooksAccountAsset(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountExpenses = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooksAccountExpenses>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES,
    () => getAllEntries(getIntegrationsQuickbooksAccountExpenses),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountExpense = (
  id: string,
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooksAccountExpense>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSE, id],
    async () => await getIntegrationsQuickbooksAccountExpense(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountLiabilities = (
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES,
    () => getAllEntries(getIntegrationsQuickbooksAccountLiabilities),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountLiability = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITY, id],
    async () => await getIntegrationsQuickbooksAccountLiability(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksAccountRevenue = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUE, id],
    async () => await getIntegrationsQuickbooksAccountRevenue(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksCustomers = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooksCustomers>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS,
    () => getAllEntries(getIntegrationsQuickbooksCustomers),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksCustomer = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMER, id],
    async () => await getIntegrationsQuickbooksCustomer(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksItems = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooksItems>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS,
    () => getAllEntries(getIntegrationsQuickbooksItems),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksItem = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_ITEM, id],
    async () => await getIntegrationsQuickbooksItem(id),
    { ...queryConfig, refetchOnMount: true }
  );

export const useIntegrationsQuickbooksVendors = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof getIntegrationsQuickbooksVendors>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS,
    () => getAllEntries(getIntegrationsQuickbooksVendors),
    queryConfig
  );

export const useIntegrationsQuickbooksVendor = (
  id: string,
  queryConfig?: WSQueryConfig<any, WSServiceError>
) =>
  useWSQuery(
    [QUERY_INTEGRATIONS_QUICKBOOKS_VENDOR, id],
    async () => await getIntegrationsQuickbooksVendor(id),
    queryConfig
  );
