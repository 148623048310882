import { WSMutationConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import {
  getPayeeEmailUpdate,
  PayeeEmailUpdateRequest
} from "../../../services/payees/getPayeeEmailUpdate";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const usePreviewPayeeEmailUpdate = (
  config?: WSMutationConfig<IPayeeEmailChangeResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();
  return useWSMutation(
    (request: PayeeEmailUpdateRequest) =>
      getPayeeEmailUpdate(request, xWingspanPayerId),
    {
      ...config
    }
  );
};
