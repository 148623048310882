import {
  useIsDesktop,
  WSDivider,
  WSPanel,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useTransaction } from "../../../query/bookkeeping/queries";
import { getAccountName } from "../../../query/bookkeeping/selectors";
import { useAccounts } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { getAmountValueAndFormat } from "../../utils";

type Props = RouteComponentProps<{
  transactionId: string;
}>;

export const CatchUpTransactionDetails: React.FC<Props> = ({
  history,
  match
}) => {
  const goBack = () => {
    history.push(match.path.substring(0, match.path.lastIndexOf("/")));
  };
  const qAccounts = useAccounts();
  const isDesktop = useIsDesktop();

  const qTransaction = useTransaction(match.params.transactionId, {
    onError(error: any) {
      if (error?.response?.status === 404) {
        goBack();
      }
    },
    retry: false,
    refetchOnMount: true
  });

  return (
    <>
      <WSSidebar onClose={goBack} header="Transaction details">
        <BrowserPageTitle title="Transaction details" />
        <WSQueries
          queries={{
            qAccounts,
            qTransaction
          }}
        >
          {({ qTransaction, qAccounts }) => {
            const transaction = qTransaction.data;
            const displayName =
              transaction.labels.overrideName ||
              transaction.merchantName ||
              transaction.name ||
              "Unknown";
            const account = qAccounts.data.find(
              a => a.accountId === transaction.accountId
            );

            return (
              <WSPanel noBorder>
                <WSText.Heading1
                  mb="M"
                  data-testid="transactionDetailsAmount"
                  {...getAmountValueAndFormat(transaction)}
                />
                <WSText
                  weight="medium"
                  mb="XS"
                  data-testid="transactionDetailsName"
                >
                  {displayName}
                </WSText>
                {account ? (
                  <WSText.ParagraphXs mb="XS">
                    {getAccountName(account)}
                  </WSText.ParagraphXs>
                ) : null}
                <WSText.ParagraphXs>
                  {moment(transaction.date).format("MMM D")}
                </WSText.ParagraphXs>
              </WSPanel>
            );
          }}
        </WSQueries>
        <WSDivider mt="XL" />
      </WSSidebar>
    </>
  );
};
