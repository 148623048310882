import {
  detachLayoutProps,
  WSButton,
  WSMarginProps
} from "@wingspanhq/fe-component-library";
import { CSVLink } from "react-csv";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";

type Props = WSMarginProps & {
  fullWidth?: boolean;
};

export function useDownloadContractorExampleTemplate() {
  const queryCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const downloadExampleCollaborators = [
    {
      firstName: "Wade",
      lastName: "Warren",
      email: "wade@wingspan.app",
      phoneNumber: "",
      contractorId: "",
      ...(queryCollaboratorCustomFields.data?.reduce(
        (acc: Record<string, string>, field) => {
          acc[field.name] = "";
          return acc;
        },
        {}
      ) || {})
    }
  ];
  const csvProps = {
    filename: `Bulk_Upload_Contractors_Template.csv`,
    headers: [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone number", key: "phoneNumber" },
      { label: "External Contractor ID", key: "contractorId" },
      ...(queryCollaboratorCustomFields.data?.map(field => ({
        label: field.name,
        key: field.name
      })) || [])
    ],
    data: downloadExampleCollaborators
  };

  return { csvProps, queryCollaboratorCustomFields };
}

export const DownloadContractorExampleTemplateButton: React.FC<Props> = ({
  fullWidth = true,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  const { csvProps, queryCollaboratorCustomFields } =
    useDownloadContractorExampleTemplate();
  return (
    <CSVLink {...csvProps}>
      <WSButton.Secondary
        disabled={queryCollaboratorCustomFields.isLoading}
        fullWidth={fullWidth}
        icon="download"
        {...layoutProps}
      >
        Download example
      </WSButton.Secondary>
    </CSVLink>
  );
};
