import {
  useModalOldContext,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSOverlay,
  WSScreen
} from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import React, { ReactNode, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logoMobile from "../../../assets/images/ws-logo-small.svg";
import logo from "../../../assets/images/ws-logo.svg";
import {
  CompanyLogo,
  CompanyLogoProps
} from "../../../components/CompanyLogo/CompanyLogo.component";
import { useSession } from "../../../query/session";
import { CLIENT_SIGN_IN_MODAL_KEY } from "../ClientSignInModal/ClientSignInModal";
import { HelpButton } from "../HelpButton/HelpButton";
import styles from "./Header.module.scss";

type Props = {
  clientInvoice?: IClientInvoice;
  clientEmail?: string;
  customDesktopAction?: ReactNode;
} & CompanyLogoProps &
  WSElementProps;

export const Header: React.FC<Props> = ({
  clientInvoice,
  companyName,
  companyLogoUrl,
  customDesktopAction,
  clientEmail
}) => {
  const history = useHistory();
  const sessionQuery = useSession();
  const { openModal } = useModalOldContext();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const location = useLocation();

  return (
    <WSElement as="header">
      <WSContainer>
        <WSScreen XS>
          <WSFlexBox
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
            py="XL"
          >
            <CompanyLogo
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
            />

            <WSFlexBox wrap="nowrap" alignItems="center">
              <WSElement mr="XL">
                <img src={logoMobile} height={22} alt="Wingspan logo" />
              </WSElement>
              <WSElement>
                <WSIcon
                  block
                  name="menu"
                  color="gray500"
                  onClick={() => {
                    setDrawerIsOpen(true);
                  }}
                />
              </WSElement>
            </WSFlexBox>
          </WSFlexBox>

          {drawerIsOpen && (
            <>
              <WSOverlay
                white
                onClick={() => {
                  setDrawerIsOpen(false);
                }}
              />
              <nav className={styles.mobileDrawer}>
                <WSElement
                  className={styles.closeIcon}
                  onClick={() => {
                    setDrawerIsOpen(false);
                  }}
                >
                  <WSIcon block name="exit" size="XS" color="gray500" />
                </WSElement>

                <WSFlexBox.Center mb="3XL">
                  <img src={logo} height={22} alt="Wingspan logo" />
                </WSFlexBox.Center>

                {sessionQuery.data?.sessionType === SessionType.user ? (
                  <>
                    <WSButton.Secondary
                      fullWidth
                      onClick={() => {
                        history.push("/sign-out#" + location.pathname);
                      }}
                    >
                      Sign out
                    </WSButton.Secondary>
                  </>
                ) : (
                  <>
                    <WSButton
                      fullWidth
                      onClick={() => {
                        openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                          clientEmailToPrefill: clientEmail,
                          memberId: clientInvoice?.memberClient.memberId,
                          member: clientInvoice?.memberClient.member
                        });
                        setDrawerIsOpen(false);
                      }}
                    >
                      Sign in
                    </WSButton>
                    <WSDivider my="XL" label="New here?" />
                    <WSButton.Secondary
                      fullWidth
                      onClick={() => {
                        openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                          signUp: true,
                          clientEmailToPrefill: clientEmail,
                          memberId: clientInvoice?.memberClient.memberId,
                          member: clientInvoice?.memberClient.member
                        });
                        setDrawerIsOpen(false);
                      }}
                    >
                      Sign up
                    </WSButton.Secondary>
                    <HelpButton />
                  </>
                )}
              </nav>
            </>
          )}
        </WSScreen>

        <WSScreen S M L XL>
          <WSFlexBox
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
            py="2XL"
          >
            <CompanyLogo
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
            />
            <WSElement>
              <img src={logo} height={22} alt="Wingspan logo" />
            </WSElement>
            <WSElement>
              {customDesktopAction ? (
                customDesktopAction
              ) : sessionQuery.data?.sessionType === SessionType.user ? (
                <WSButton
                  size="S"
                  onClick={() => {
                    history.push("/sign-out#" + location.pathname);
                  }}
                  name="cpeSignOut"
                >
                  Sign out
                </WSButton>
              ) : (
                <WSButton
                  size="S"
                  onClick={() => {
                    openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                      clientEmailToPrefill: clientEmail,
                      memberId: clientInvoice?.memberClient.memberId,
                      member: clientInvoice?.memberClient.member
                    });
                  }}
                  name="cpeSignIn"
                >
                  Sign in
                </WSButton>
              )}
            </WSElement>
          </WSFlexBox>
        </WSScreen>
      </WSContainer>
    </WSElement>
  );
};
