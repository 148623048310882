import { IBankTransferProcessingAccount } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { WingspanIsPCICertifiedSecure } from "../../../ClientPayments/components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { getPayeeName } from "../../../modules/Payees/selectors/getPayeeNames";
import { WSQueries } from "../../../query/WSQuery";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";
import { useUserTag } from "../../../query/users/queries";
import {
  LayoutSetup,
  LayoutSetupProps
} from "../../../shared/components/LayoutSetup";

type SetupAutopayLayoutProps = Omit<LayoutSetupProps, "title"> & {
  payeeId: string;
  title?(companyName: string): string;
  children(props: {
    payeeName: string;
    domesticBankAccount?: IBankTransferProcessingAccount;
    internationalBankAccount?: IBankTransferProcessingAccount;
  }): React.ReactNode;
};

export const SetupAutopayLayout: React.FC<SetupAutopayLayoutProps> = ({
  payeeId,
  title,
  children,
  ...props
}) => {
  const queryPayee = usePayeeQuery(payeeId);
  const queryUserTag = useUserTag(queryPayee.data?.user?.tag!, {
    enabled: !!queryPayee.data?.user?.tag
  });

  const logoUrl = queryUserTag.data?.member?.profile?.logoUrl;

  const payeeName = useMemo(() => {
    if (!queryPayee.data) {
      return "";
    }
    return getPayeeName(queryPayee.data);
  }, [queryPayee.data]);

  const domesticBankAccount = queryPayee.data?.bankTransferProcessingAccount;
  const internationalBankAccount =
    queryPayee.data?.internationalBankTransferProcessingAccount;

  return (
    <>
      <LayoutSetup
        customLogo={logoUrl}
        banner={<WingspanIsPCICertifiedSecure name={payeeName} />}
        title={title?.(payeeName)}
        {...props}
      >
        <WSQueries
          queries={{
            queryPayee
          }}
        >
          {() =>
            children({
              payeeName,
              domesticBankAccount,
              internationalBankAccount
            })
          }
        </WSQueries>
      </LayoutSetup>
    </>
  );
};
