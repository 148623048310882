import {
  WSCard,
  WSElement,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { BannerBankingVerification } from "./BannerBankingVerification";
import React from "react";

const ListOfFeatures: React.FC<{
  items: { title: string; subtitle?: string; content: string }[];
}> = ({ items }) => {
  return (
    <WSList gap="XL">
      {items.map((item, index) => (
        <WSCard
          key={`${index}-${item.title}`}
          header={{
            label: {
              text: item.title,
              avatar: {
                icon: "check",
                type: "icon",
                format: "circle",
                colorBackground: "green500"
              }
            }
          }}
          divider
        >
          {item.subtitle ? (
            <WSText.Paragraph weight="medium" mb="M">
              {item.subtitle}
            </WSText.Paragraph>
          ) : null}
          <WSText.ParagraphSm color="gray500">
            {item.content}
          </WSText.ParagraphSm>
        </WSCard>
      ))}
    </WSList>
  );
};

export const PendingState: React.FC<{ isVerified: boolean }> = ({
  isVerified
}) => {
  const history = useHistory();

  return (
    <WSList gap="2XL">
      {isVerified ? (
        <WSCard
          header={{
            label: {
              text: "Wingspan Wallet",
              avatar: {
                type: "wingspan"
              }
            }
          }}
          divider
          actions={{
            alignment: "fill",
            buttons: [
              {
                label: "Get started",
                kind: "Primary",
                onClick: () => {
                  history.push("/member/activate-banking");
                }
              }
            ]
          }}
        >
          <WSText.Paragraph weight="medium">
            Business banking with Wingspan
          </WSText.Paragraph>
          <WSList gap="XS" mt="M">
            <WSText.ParagraphSm color="gray500" mb="M">
              Add funds to a business bank account with Wingspan. Use virtual
              cards to perform transactions, make payments and so much more.
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray500">
              Get started and provide verification documents for compliance and
              the secure handling of your funds.
            </WSText.ParagraphSm>
          </WSList>
        </WSCard>
      ) : (
        <BannerBankingVerification />
      )}

      <WSElement>
        <WSText weight="medium" color="gray600" mb="M">
          Reasons people love Wingspan Wallet:
        </WSText>
        <ListOfFeatures
          items={[
            {
              title: "Rewards on your spending",
              subtitle: "Earn 1% cashback",
              content:
                "Earn 1% cash back on all purchases made with your Wingspan Visa® Debit Card. Receive a $50 gift card when you spend $1,000 in the first 30 days after sign up."
            },
            {
              title: "Business banking made better",
              content:
                "Receive your payouts 1 day sooner. No account, transaction, minimum balance or hidden fees. Virtual debit card via Apple Pay and Google Pay"
            },
            {
              title: "Save big on taxes with write-offs",
              content:
                "Automatic write-offs of business expenses, fully integrated with Wingspan Income & Expenses"
            }
          ]}
        />
      </WSElement>
    </WSList>
  );
};
