import { formatReportsDate } from "../../utils/formatReportsDate";
import { ReportsColumn } from "../../types";
import { IContractorReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";

export const getColumns = (
  hasAdminScope: boolean
): ReportsColumn<IContractorReportResponse>[] => [
  {
    label: "Contractor ID",
    value: "payeeId",
    getTableCell(data) {
      return data.payeeId;
    }
  },
  {
    label: "Display Name",
    value: "user.profile.displayName",
    getTableCell(data) {
      const displayName = wsName({
        user: data.user,
        member: data.member,
        payerOwnedData: data.payerOwnedData
      }).getResolvedName(false);
      return displayName;
    }
  },
  {
    label: "First Name",
    value: "user.profile.firstName",
    preselected: true,
    getTableCell(data) {
      return data.user.profile?.firstName;
    }
  },
  {
    label: "Last Name",
    value: "user.profile.lastName",
    preselected: true,
    getTableCell(data) {
      return data.user.profile?.lastName;
    }
  },
  {
    label: "Full Name",
    value: "user.profile.fullName",
    getTableCell(data) {
      return [data.user.profile?.firstName, data.user.profile?.lastName]
        .filter(Boolean)
        .join(" ");
    }
  },
  {
    label: "Contractor Company",
    value: "member.profile.company.legalBusinessName",
    getTableCell(data) {
      const legalBusinessName = wsName({
        user: data.user,
        member: data.member,
        payerOwnedData: data.payerOwnedData
      }).legalBusinessName;
      return legalBusinessName;
    }
  },
  {
    label: "Email",
    value: "user.email",
    preselected: true,
    getTableCell(data) {
      const userEmail = wsName({
        user: data.user,
        member: data.member,
        payerOwnedData: data.payerOwnedData
      }).email;
      return userEmail;
    }
  },
  {
    label: "Status",
    value: "status",
    preselected: true,
    getTableCell(data) {
      return data.payerOwnedData?.status === PayerPayeeStatus.Inactive
        ? "Archived"
        : data.payerOwnedData?.status;
    }
  },
  {
    label: "Tax info",
    value: "taxInfo",
    getTableCell(data) {
      return data.payeeOwnedData?.shareTaxDocument ===
        PayeeTaxDocumentSharePermission.Allow
        ? "Shared"
        : "Not shared";
    }
  },
  {
    label: "Autopay",
    value: "autopay",
    getTableCell(data) {
      return data.payerOwnedData?.autoPayStrategy ===
        PaymentRequirementStrategy.All
        ? "On"
        : "Off";
    }
  },
  {
    label: "External ID",
    value: "payerOwnedData.payeeExternalId",
    getTableCell(data) {
      return data.payerOwnedData?.payeeExternalId;
    }
  },
  ...(hasAdminScope
    ? [
        {
          label: "SSN",
          value: "payeeOwnedData.payeeW9Data.ssn",
          getTableCell(data: IContractorReportResponse) {
            return data.payeeOwnedData?.payeeW9Data?.ssn;
          }
        }
      ]
    : []),
  ...(hasAdminScope
    ? [
        {
          label: "EIN",
          value: "payeeOwnedData.payeeW9Data.ein",
          getTableCell(data: IContractorReportResponse) {
            return data.payeeOwnedData?.payeeW9Data?.ein;
          }
        }
      ]
    : []),
  {
    label: "Created At",
    value: "createdAt",
    getTableCell(data) {
      return formatReportsDate(data.createdAt);
    }
  },
  {
    label: "Updated At",
    value: "updatedAt",
    getTableCell(data) {
      return formatReportsDate(data.updatedAt);
    }
  },
  {
    label: "Signed Up Date",
    value: "events.signedUpAt",
    getTableCell(data) {
      return formatReportsDate(data.events?.signedUpAt);
    }
  },
  {
    label: "Payout Method Added Date",
    value: "events.payoutMethodFirstAddedAt",
    getTableCell(data) {
      return formatReportsDate(data.events?.payoutMethodFirstAddedAt);
    }
  },
  //* hide for now
  // {
  //   label: "Banking Enabled Date",
  //   value: "events.knowYourCustomerVerifiedAt",
  //   getTableCell(data) {
  //     return formatReportsDate(data.events?.knowYourCustomerVerifiedAt);
  //   }
  // },
  // {
  //   label: "Tax Documentation Verified Date",
  //   value: "events.taxDocumentationVerifiedAt",
  //   getTableCell(data) {
  //     return formatReportsDate(data.events?.taxDocumentationVerifiedAt);
  //   }
  // },

  {
    label: "Company Structure",
    value: "member.profile.company.structure",
    getTableCell(data) {
      // @ts-ignore
      return data.member?.profile?.company?.structure;
    }
  },
  {
    label: "Address Line 1",
    value: "member.profile.address.addressLine1",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.addressLine1;
    }
  },
  {
    label: "Address Line 2",
    value: "member.profile.address.addressLine2",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.addressLine2;
    }
  },
  {
    label: "City",
    value: "member.profile.address.city",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.city;
    }
  },
  {
    label: "State",
    value: "member.profile.address.state",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.state;
    }
  },
  {
    label: "Postal Code",
    value: "member.profile.address.postalCode",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.postalCode;
    }
  },
  {
    label: "Country",
    value: "member.profile.address.country",
    group: "Address fields",
    getTableCell(data) {
      return data.member?.profile?.address?.country;
    }
  },
  {
    label: "Income & Expenses Enabled",
    value: "servicesStatus.incomeAndExpenses",
    preselected: true,
    getTableCell(data) {
      return data.servicesStatus?.incomeAndExpenses ? "Yes" : "No";
    }
  },
  {
    label: "Tax Withholding Enabled",
    value: "servicesStatus.taxWithholdings",
    preselected: true,
    getTableCell(data) {
      return data.servicesStatus?.taxWithholdings ? "Yes" : "No";
    }
  },
  {
    label: "Engagements",
    value: "engagements",
    getTableCell(data) {
      return data.engagements?.length
        ? data.engagements
            .filter(
              engagement => engagement.engagementId && !engagement.isDefault
            )
            .map(engagement => engagement.engagementName)
            .join(", ")
        : "-";
    }
  }
];
