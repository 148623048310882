import { WSPillProps } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { getBulkQueriesByResource } from "../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../types";
import {
  isBatchFullyImported,
  isBatchPartiallyImported,
  isUploadFinished,
  isUploadInProgress
} from "../utils/bulkBatchUtils";

export function useBulkBatchStatus(
  bulkBatchId: string,
  bulkResource: BulkResource,
  showIcon?: boolean
): WSPillProps {
  const { useBulkBatch, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const queryBulkSummary = useBulkBatchSummary(bulkBatchId);
  const bulkBatch = queryBulkBatch.data as IBulkBatch;
  const isEmptyBatch = queryBulkSummary.data?.listSize === 0;

  const wsPillProps: WSPillProps = useMemo(() => {
    const getOpenBatchPillProps = (): WSPillProps => {
      if (isUploadFinished(bulkBatch, isEmptyBatch)) {
        return {
          theme: "warning",
          text: "Awaiting import",
          ...(showIcon && { icon: "alert" })
        };
      }
      return {
        theme: "neutral",
        text: isUploadInProgress(bulkBatch) ? "Uploading" : "Empty",
        ...(showIcon && {
          icon: isUploadInProgress(bulkBatch) ? "time" : "block"
        })
      };
    };

    if (isBatchFullyImported(bulkBatch)) {
      return {
        theme: "success",
        text: "Complete",
        ...(showIcon && { icon: "check-circle" })
      };
    } else if (isBatchPartiallyImported(bulkBatch)) {
      return {
        theme: "warning",
        text: "Partially imported",
        ...(showIcon && { icon: "alert" })
      };
    } else if (bulkBatch?.status === BulkStatus.Failed) {
      return {
        theme: "error",
        text: "Import failed",
        ...(showIcon && { icon: "alert-circle" })
      };
    } else if (
      [BulkStatus.Pending, BulkStatus.Processing].includes(bulkBatch?.status)
    ) {
      return {
        theme: "blue",
        text: "Importing"
      };
    }
    // open status
    return getOpenBatchPillProps();
  }, [bulkBatch, showIcon]);
  return wsPillProps;
}
