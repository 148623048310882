import React, { useMemo } from "react";
import {
  defaultFormatter,
  Formatter,
  FormatterInput,
  FormatterOptions
} from "../../../utils/formatters";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSPill, WSPillProps } from "../common/WSPill/WSPill.component";
import { WSButton, WSButtonProps, WSImage } from "../core";
import {
  WSIcon,
  WSIconName,
  WSIconProps
} from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSCard } from "../WSCard/WSCard";
import { WSElement } from "../WSElement/WSElement.component";
import { CopyButton } from "./CopyButton";
import styles from "./Value.module.scss";

const DEFAULT_ICON_SIZE = "S";
const DEFAULT_ICON_COLOR = "gray600";
const DEFAULT_AVATAR_SIZE = "S";

export type WSDataItemValueProps = {
  hideValue?: boolean;
  formatter?: Formatter;
  value?: FormatterInput;
  type?: "basic" | "image";
  formatterOptions?: FormatterOptions;
  pill?: WSPillProps;
  avatar?: WSAvatarProps;
  icon?: WSIconName | WSIconProps;
  copy?: boolean;
  fsExclude?: boolean;
  action?: WSButtonProps<"Link">;
};

export const Value: React.FC<WSDataItemValueProps> = (props) => {
  const formatter = useMemo(() => props.formatter || defaultFormatter, [
    props.formatter
  ]);

  const formattedValue = useMemo(
    () => formatter.format(props.value as any, props.formatterOptions as any),
    [formatter, props.formatterOptions, props.value]
  );

  const iconProps = useMemo((): WSIconProps | undefined => {
    if (typeof props.icon === "undefined") {
      return undefined;
    }

    if (typeof props.icon === "string") {
      return {
        name: props.icon,
        size: DEFAULT_ICON_SIZE,
        color: DEFAULT_ICON_COLOR
      };
    }

    return {
      size: DEFAULT_ICON_SIZE,
      color: DEFAULT_ICON_COLOR,
      ...props.icon
    };
  }, [props.icon]);

  return (
    <WSElement className={styles.valueWrapper}>
      <WSElement className={styles.value}>
        {iconProps && <WSIcon {...iconProps} />}

        {props.avatar && (
          <WSAvatar size={DEFAULT_AVATAR_SIZE} {...props.avatar} />
        )}
        {props.type === "image" &&
        props.value &&
        typeof props.value === "string" ? (
          <WSCard>
            <WSImage
              className={styles.image}
              src={props.value}
              placeholder={null}
            />
          </WSCard>
        ) : (
          <WSText kind="ParagraphSm" fsExclude={props.fsExclude}>
            {formattedValue}
          </WSText>
        )}

        {props.action && <WSButton kind="Link" size="S" {...props.action} />}

        {props.copy && formattedValue && <CopyButton value={formattedValue} />}
      </WSElement>

      {props.pill && <WSPill {...props.pill} />}
    </WSElement>
  );
};
