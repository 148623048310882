import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import {
  concurrentActions,
  ListRequestQuery,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../../../../query/helpers";
import {
  IBulkCollaboratorBatch,
  IBulkCollaboratorItem
} from "@wingspanhq/payments/dist/interfaces";
import {
  BULK_CONTRACTOR_BATCH_LIST_SUMMARY,
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "./keys";
import flatten from "lodash/flatten";
import { Await } from "../../../../utils";
import {
  bulkCollaboratorService,
  IContractorBatchesSummary,
  IContractorBatchListRequestQuery
} from "../../services/bulkCollaborator";
import { BulkBatchesFilters, BulkBatchItemsFilter } from "../../services/types";
import times from "lodash/times";

export const useBulkCollaboratorBatch = (
  batchId: string,
  config?: WSQueryConfig<IBulkCollaboratorBatch, WSServiceError>
) => {
  return useWSQuery<IBulkCollaboratorBatch, WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH, batchId],
    () => bulkCollaboratorService.batch.get(batchId),
    config
  );
};

function mapBulkCollaboratorsBatchFilters(filters?: BulkBatchesFilters) {
  const apiFilters: any = {};

  if (filters?.status && filters.status.length !== 5) {
    apiFilters.status = {
      in: filters.status
    };
  }
  if (filters?.year) {
    apiFilters.createdAt = {
      ">=": `${filters.year}-01-01`,
      "<=": `${filters.year}-12-31`
    };
  }
  return apiFilters;
}

export const useBulkCollaboratorBatchesQuery = (
  filters?: BulkBatchesFilters,
  config?: { size?: number } & WSInfiniteQueryConfig<
    IBulkCollaboratorBatch[],
    WSServiceError
  >
) => {
  const size = config?.size || 100;

  const query = useWSInfiniteQuery<IBulkCollaboratorBatch[], WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH_LIST, { filters, size }],
    ({ pageParam = 1 }) => {
      return bulkCollaboratorService.batch.list({
        filter: mapBulkCollaboratorsBatchFilters(filters),
        page: {
          size,
          number: pageParam
        },
        sort: filters?.sort
          ? filters.sort
          : {
              createdAt: "desc"
            }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};

export const useQueryCollaboratorBatchesSummary = (
  params?: IContractorBatchListRequestQuery,
  config?: WSQueryConfig<IContractorBatchesSummary, WSServiceError>
) => {
  return useWSQuery<IContractorBatchesSummary, WSServiceError>(
    [BULK_CONTRACTOR_BATCH_LIST_SUMMARY, params],
    () =>
      bulkCollaboratorService.batch.listSize({
        filter: mapBulkCollaboratorsBatchFilters(params),
        page: { size: 1, number: 1 },
        sort: params?.sort
          ? params.sort
          : {
              createdAt: "desc"
            }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useBulkCollaboratorBatchSummary = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<
    Await<ReturnType<typeof bulkCollaboratorService.batch.summary>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof bulkCollaboratorService.batch.summary>>,
    WSServiceError
  >(
    [QUERY_BULK_COLLABORATOR_BATCH_SUMMARY, [batchId, query]],
    () => bulkCollaboratorService.batch.summary(batchId, query),
    config
  );
};

export const useAllBulkCollaboratorBatchItems = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<IBulkCollaboratorItem[], WSServiceError>
) => {
  return useWSQuery<IBulkCollaboratorItem[], WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST, [batchId, query]],
    async () => {
      const pageSize = 100;
      const listSize = await bulkCollaboratorService.batchItem.listSize(
        batchId,
        query
      );
      const pages = Math.ceil(listSize / pageSize);

      const actions = times(pages).map(
        (_, i) => () =>
          bulkCollaboratorService.batchItem.list(batchId, {
            ...query,
            page: { size: pageSize, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const allBulkCollaboratorBatchItems = flatten(allPages);

      return allBulkCollaboratorBatchItems;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
