// method to mask sensitive data in object before logging

import cloneDeep from "lodash/cloneDeep";
import forOwn from "lodash/forOwn";
import isObject from "lodash/isObject";
import set from "lodash/set";

const SENSITIVE_KEYS = ["password", "ssn", "cvv", "otp", "token"];

export const maskSensitiveData = <
  Body extends Record<string, any> = Record<string, any>
>(
  body: Body
): Body => {
  const maskedBody = cloneDeep(body);

  const maskValue = (value: any) => {
    if (typeof value === "string") {
      return value.replace(/./g, "*");
    }
    return value;
  };

  const maskObject = (obj: any) => {
    forOwn(obj, (value, key) => {
      if (SENSITIVE_KEYS.includes(key)) {
        set(obj, key, maskValue(value));
      } else if (isObject(value)) {
        maskObject(value);
      }
    });
  };

  maskObject(maskedBody);
  return maskedBody;
};
