import {
  useIsMobile,
  WSCentered,
  WSElement,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSInfoBox,
  WSInputNumberOld,
  WSPage,
  WSPageToolbar,
  WSRadioInputGroup,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useBrowserPageTitle } from "../../../../../components/BrowserPageTitle/BrowserPageTitle";
import InfoPopup, { InfoPopupProps } from "../../../../../components/InfoPopup";
import { Tabs } from "../../../../../components/Tabs";
import { WSQueries } from "../../../../../query/WSQuery";

import { WSPageToolbarAction } from "@wingspanhq/fe-component-library/dist/lib/components/WSPageToolbarActions/WSPageToolbarActions";
import {
  TaxFormCorrectionStatus,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import {
  downloadAllTaxFormRowSearchResults,
  getTaxFormRowSearchQueryDefaultFilters,
  GROUPED_TIN_OPTIONS,
  TAX_FORM_CORRECTIONS_STATUS_OPTIONS,
  TAX_FORM_STATUS_OPTIONS,
  TaxFormRowSearchQueryFilters,
  usePaginatedTaxFormRowSearch,
  usePaginatedTaxFormRowSearchCount,
  W9_SHARED_OPTIONS
} from "../../../../../query/search/taxForm/queries/usePaginatedTaxFormRowSearch";
import { useAllOrganizationUsers } from "../../../../../query/users/queries";
import { getUserName } from "../../../../../query/users/selectors";
import { ButtonDownloadCsv } from "../../../../../shared/components/ButtonDownloadCsv";
import { useXWingspanExpansion } from "../../../../../shared/hooks/useXWingspanExpansion";
import { useAuthorizedScopeGroups } from "../../../../../shared/utils/teamUtils";
import { useUrlQueryFilters } from "../../../../../utils/router";
import { SelectTaxYear } from "../../../../TaxFiling/components/SelectYear";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { mapITaxFormSearchRowItemsToCSV } from "../../../utils/csvUtils";
import { NEC1099FilingSearchTable } from "./NEC1099FilingSearchTable";
import { TableNoDataMessage } from "./TableNoDataMessage";

export const ROOT_PATH = "/member/1099-filing/dashboard";
export const ROOT_PATH_ALL = `${ROOT_PATH}/all`;
export const ROOT_PATH_NEEDS_ACTION = `${ROOT_PATH}/needs-action`;
export const ROOT_PATH_READY = `${ROOT_PATH}/ready`;
export const ROOT_PATH_SUBMITTED = `${ROOT_PATH}/submitted`;
export const ROOT_PATH_EXCLUDED = `${ROOT_PATH}/excluded`;

export const getStatusBasedOnRoute = (pathname: string): string | undefined => {
  switch (pathname) {
    case ROOT_PATH_NEEDS_ACTION:
      return `(status:${TaxFormStatus.NeedsAction} OR lastCorrectionStatus:${TaxFormCorrectionStatus.Pending})`;
    case ROOT_PATH_READY:
      return `(status: ${TaxFormStatus.ReadyToSubmitToIrs})`;
    case ROOT_PATH_SUBMITTED:
      return `(status:${TaxFormStatus.SubmittedToIrs} OR status:${TaxFormStatus.RejectedByIrs} OR status:${TaxFormStatus.AcceptedByIrs})`;
    case ROOT_PATH_EXCLUDED:
      return `(status:${TaxFormStatus.Excluded})`;
    default:
      return undefined;
  }
};

export const NEC1099FilingDashboard: React.FC<RouteComponentProps> = ({
  history
}) => {
  useBrowserPageTitle(`1099 Filing Dashboard ${CURRENT_YEAR}`);

  const [infoPopupData, setInfoPopupData] = useState<Omit<
    InfoPopupProps,
    "onClose"
  > | null>(null);

  const userId = useUserId();
  const isMobile = useIsMobile();

  const defaultFilters: TaxFormRowSearchQueryFilters =
    getTaxFormRowSearchQueryDefaultFilters(CURRENT_YEAR);
  const { filters, setFilters } =
    useUrlQueryFilters<TaxFormRowSearchQueryFilters>(defaultFilters);

  const expandOrganizationsForUserId = useXWingspanExpansion();

  const queryFeatureFlags = useFeatureFlags();
  const queryOrgAccounts = useAllOrganizationUsers();
  const { hasAdminScope, hasCollaboratorsScope } = useAuthorizedScopeGroups();

  const currentFilters = useMemo((): TaxFormRowSearchQueryFilters => {
    return {
      ...filters,
      statuses: getStatusBasedOnRoute(history.location.pathname)
    };
  }, [filters, history.location.pathname]);

  const searchQueryConfig = useMemo(() => {
    return {
      enabled: queryOrgAccounts.isFetched
    };
  }, [queryOrgAccounts.isFetched]);

  const searchQueryTabCountFilterConfig = useMemo(() => {
    return {
      filters: defaultFilters
    };
  }, [defaultFilters]);

  const searchQueryFilterConfig = useMemo(() => {
    return {
      filters: currentFilters
    };
  }, [currentFilters, queryOrgAccounts.data?.length, userId]);

  const queryTaxFormRowSearch = usePaginatedTaxFormRowSearch(
    searchQueryFilterConfig,
    searchQueryConfig
  );

  const queryTaxFormRowSearchCount = usePaginatedTaxFormRowSearchCount(
    searchQueryFilterConfig,
    searchQueryConfig
  );

  const queryTaxFormRowSearchCountAll = usePaginatedTaxFormRowSearchCount(
    searchQueryTabCountFilterConfig,
    searchQueryConfig
  );

  const tabAllTitle = useMemo(
    () =>
      `All${
        queryTaxFormRowSearchCountAll.isFetched &&
        typeof queryTaxFormRowSearchCountAll.data === "number"
          ? ` (${queryTaxFormRowSearchCountAll.data})`
          : ""
      }`,
    [
      queryTaxFormRowSearchCountAll.data,
      queryTaxFormRowSearchCountAll.isFetched
    ]
  );

  const queryTaxFormRowSearchCountNeedsAction =
    usePaginatedTaxFormRowSearchCount(
      {
        ...searchQueryTabCountFilterConfig,
        filters: {
          ...searchQueryTabCountFilterConfig.filters,
          statuses: getStatusBasedOnRoute(ROOT_PATH_NEEDS_ACTION)
        }
      },
      searchQueryConfig
    );

  const tabNeedsActionTitle = useMemo(
    () =>
      `Action Required${
        queryTaxFormRowSearchCountNeedsAction.isFetched &&
        typeof queryTaxFormRowSearchCountNeedsAction.data === "number"
          ? ` (${queryTaxFormRowSearchCountNeedsAction.data})`
          : ""
      }`,
    [
      queryTaxFormRowSearchCountNeedsAction.data,
      queryTaxFormRowSearchCountNeedsAction.isFetched
    ]
  );

  const queryTaxFormRowSearchCountReady = usePaginatedTaxFormRowSearchCount(
    {
      ...searchQueryTabCountFilterConfig,
      filters: {
        ...searchQueryTabCountFilterConfig.filters,
        statuses: getStatusBasedOnRoute(ROOT_PATH_READY)
      }
    },
    searchQueryConfig
  );

  const tabReadyTitle = useMemo(
    () =>
      `Ready${
        queryTaxFormRowSearchCountReady.isFetched &&
        typeof queryTaxFormRowSearchCountReady.data === "number"
          ? ` (${queryTaxFormRowSearchCountReady.data})`
          : ""
      }`,
    [
      queryTaxFormRowSearchCountReady.data,
      queryTaxFormRowSearchCountReady.isFetched
    ]
  );

  const queryTaxFormRowSearchCountSubmitted = usePaginatedTaxFormRowSearchCount(
    {
      ...searchQueryTabCountFilterConfig,
      filters: {
        ...searchQueryTabCountFilterConfig.filters,
        statuses: getStatusBasedOnRoute(ROOT_PATH_SUBMITTED)
      }
    },
    searchQueryConfig
  );

  const tabSubmittedTitle = useMemo(
    () =>
      `Submitted${
        queryTaxFormRowSearchCountSubmitted.isFetched &&
        typeof queryTaxFormRowSearchCountSubmitted.data === "number"
          ? ` (${queryTaxFormRowSearchCountSubmitted.data})`
          : ""
      }`,
    [
      queryTaxFormRowSearchCountSubmitted.data,
      queryTaxFormRowSearchCountSubmitted.isFetched
    ]
  );

  const queryTaxFormRowSearchCountExcluded = usePaginatedTaxFormRowSearchCount(
    {
      ...searchQueryTabCountFilterConfig,
      filters: {
        ...searchQueryTabCountFilterConfig.filters,
        statuses: getStatusBasedOnRoute(ROOT_PATH_EXCLUDED)
      }
    },
    searchQueryConfig
  );

  const tabExcludedTitle = useMemo(
    () =>
      `Excluded${
        queryTaxFormRowSearchCountExcluded.isFetched &&
        typeof queryTaxFormRowSearchCountExcluded.data === "number"
          ? ` (${queryTaxFormRowSearchCountExcluded.data})`
          : ""
      }`,
    [
      queryTaxFormRowSearchCountExcluded.data,
      queryTaxFormRowSearchCountExcluded.isFetched
    ]
  );

  const menuItems = useMemo(
    () =>
      [
        {
          label: "Update payer information",
          onClick: () => {
            history.push("/member/1099-filing/update-payer-information");
          },
          enabled: hasAdminScope
        },
        {
          label: "Batch add recipients",
          onClick: async () => {
            history.push("/member/1099-filing/batch-add-recipients");
          },
          enabled: hasAdminScope
        },

        {
          label: "Batch upload payments",
          onClick: async () => {
            history.push("/member/1099-filing/batch-upload-payments");
          },
          enabled: hasAdminScope
        },
        {
          label: "Update calculation settings",
          onClick: () => {
            history.push("/member/1099-filing/update-calculation-settings");
          },
          enabled: hasAdminScope
        },
        {
          label: "Update state filing settings",
          onClick: () => {
            history.push("/member/1099-filing/update-state-filing-settings");
          },
          enabled: hasAdminScope
        },
        {
          label: "Generate amounts & status",
          onClick: () => {
            history.push("/member/1099-filing/generate-1099-nec-amounts");
          },
          enabled: hasAdminScope
        },
        {
          label: "Submit for filing",
          onClick: async () => {
            history.push("/member/1099-filing/filing");
          },
          enabled: hasAdminScope
        }
      ].filter(item => item.enabled),
    [history, hasAdminScope]
  );

  const actions: WSPageToolbarAction[] = useMemo(() => {
    const actions: WSPageToolbarAction[] = [];

    if (hasAdminScope) {
      return [
        {
          label: "Add recipient",
          onClick: () => {
            history.push("/member/1099-filing/add-recipient");
          },
          buttonKind: "Primary"
        },
        ...menuItems
      ];
    }

    return actions;
  }, [hasAdminScope, hasCollaboratorsScope, menuItems]);

  return (
    <WSElement>
      {infoPopupData && (
        <InfoPopup {...infoPopupData} onClose={() => setInfoPopupData(null)} />
      )}

      <WSPage>
        <WSPageToolbar
          title="1099-NEC filing"
          actions={actions}
          titleAfter={<SelectTaxYear />}
        />

        <WSQueries queries={{ queryFeatureFlags, queryOrgAccounts }}>
          {({ queryFeatureFlagsData, queryOrgAccountsData }) => {
            return (
              <>
                <Tabs
                  tabs={[
                    {
                      label: tabAllTitle,
                      path: ROOT_PATH_ALL
                    },
                    {
                      label: tabNeedsActionTitle,
                      path: ROOT_PATH_NEEDS_ACTION
                    },
                    {
                      label: tabReadyTitle,
                      path: ROOT_PATH_READY
                    },
                    {
                      label: tabSubmittedTitle,
                      path: ROOT_PATH_SUBMITTED
                    },
                    {
                      label: tabExcludedTitle,
                      path: ROOT_PATH_EXCLUDED
                    }
                  ]}
                />

                {/*{hasAdminScope &&
                  queryTaxFormRowSearchCountReady.isFetched &&
                  typeof queryTaxFormRowSearchCountReady.data === "number" &&
                  queryTaxFormRowSearchCountReady.data > 0 && (
                    <WSMessageBox.Info noBorder mt="XL">
                      <WSText weight="medium">
                        1099-NECs submit for filing is available
                      </WSText>
                      <WSText color="gray500" mt="XS">
                        There are {`${queryTaxFormRowSearchCountReady.data}`}{" "}
                        Form 1099-NECs in the “Ready” status. Only forms with
                        filing status “Ready” will be submitted. We recommend
                        reviewing forms with statuses “Action Required” and
                        “Excluded” to ensure they have the correct information
                        before filing.
                      </WSText>
                      {queryTaxFormRowSearchCountReady.data > 0 && (
                        <WSButton.Link
                          onClick={() =>
                            history.push("/member/1099-filing/filing")
                          }
                          mt="M"
                          size="S"
                          rightIcon="chevron-right"
                        >
                          Submit for filing
                        </WSButton.Link>
                      )}
                    </WSMessageBox.Info>
                  )}
                */}

                <WSInfoBox mt="XL" size="M">
                  Note: While we are upgrading the tax filing experience for
                  2024, previous years 1099 interfaces may differ from the new
                  experience. View the current tax filing experience on your new{" "}
                  <a
                    target="_self"
                    onClick={() => history.push("/member/tax-filing")}
                  >
                    2024 dashboard
                  </a>
                  .
                </WSInfoBox>

                <WSFlexBox
                  justify="space-between"
                  alignItems="center"
                  my={isMobile ? "M" : "2XL"}
                >
                  <WSFiltersOld
                    onSearch={(newSearchText: string) => {
                      setFilters({
                        ...filters,
                        search: newSearchText
                      });
                    }}
                    searchQuery={filters.search}
                    filters={filters}
                    defaultFilters={defaultFilters}
                    onFilter={newFilters =>
                      setFilters({
                        ...filters,
                        ...newFilters
                      })
                    }
                    showClearButton
                  >
                    {queryOrgAccountsData.length > 0 && (
                      <WSFormOld.Field
                        name="organizationId"
                        label="Organization"
                        component={WSSelectOld}
                        componentProps={{
                          searchable: true,
                          placeholder: "All organizations",
                          options: queryOrgAccountsData.map(orgAccount => ({
                            label: getUserName(orgAccount),
                            value: orgAccount.userId,
                            searchText: [
                              getUserName(orgAccount),
                              orgAccount.userId
                            ]
                              .filter(Boolean)
                              .join(" ")
                          }))
                        }}
                      />
                    )}

                    <WSText.ParagraphSm color="gray600" mt="XL" mb="M">
                      Status
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="taxFormStatus"
                      mb="M"
                      component={WSRadioInputGroup}
                      componentProps={{
                        options: TAX_FORM_STATUS_OPTIONS
                      }}
                    />

                    <WSText.ParagraphSm color="gray600" mt="XL" mb="M">
                      W-9 status
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="w9Shared"
                      mb="M"
                      component={WSRadioInputGroup}
                      componentProps={{
                        options: W9_SHARED_OPTIONS
                      }}
                    />

                    {queryFeatureFlagsData.nec1099AmountFilters && (
                      <>
                        <WSText.ParagraphSm color="gray600" mt="XL" mb="M">
                          Amount Maximum
                        </WSText.ParagraphSm>
                        <WSFormOld.Field
                          name="amountMax"
                          mb="M"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD",
                            min: 0
                          }}
                        />
                      </>
                    )}

                    {queryFeatureFlagsData.nec1099AmountFilters && (
                      <>
                        <WSText.ParagraphSm color="gray600" mt="XL" mb="M">
                          Amount Minimum
                        </WSText.ParagraphSm>
                        <WSFormOld.Field
                          name="amountMin"
                          mb="M"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD",
                            min: 0
                          }}
                        />
                      </>
                    )}

                    <WSText.ParagraphSm color="gray600" mt="M" mb="S">
                      Corrections
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="correctionStatus"
                      mb="S"
                      component={WSRadioInputGroup}
                      componentProps={{
                        options: TAX_FORM_CORRECTIONS_STATUS_OPTIONS
                      }}
                    />

                    <WSText.ParagraphSm color="gray600" mt="M" mb="S">
                      W-9 status
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="w9Shared"
                      mb="S"
                      component={WSRadioInputGroup}
                      componentProps={{
                        options: W9_SHARED_OPTIONS
                      }}
                    />

                    {queryFeatureFlagsData.nec1099AmountFilters && (
                      <>
                        <WSText.ParagraphSm color="gray600" mt="M" mb="S">
                          Amount Maximum
                        </WSText.ParagraphSm>
                        <WSFormOld.Field
                          name="amountMax"
                          mb="S"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD",
                            min: 0
                          }}
                        />
                      </>
                    )}

                    {queryFeatureFlagsData.nec1099AmountFilters && (
                      <>
                        <WSText.ParagraphSm color="gray600" mt="M" mb="S">
                          Amount Minimum
                        </WSText.ParagraphSm>
                        <WSFormOld.Field
                          name="amountMin"
                          mb="S"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD",
                            min: 0
                          }}
                        />
                      </>
                    )}

                    <WSText.ParagraphSm color="gray600" mt="M" mb="S">
                      Grouped TIN
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="groupedTin"
                      mb="S"
                      component={WSRadioInputGroup}
                      componentProps={{
                        options: GROUPED_TIN_OPTIONS
                      }}
                    />
                  </WSFiltersOld>

                  {queryTaxFormRowSearchCount.data &&
                  queryTaxFormRowSearchCount.data > 0 ? (
                    <ButtonDownloadCsv
                      mt="XL"
                      getData={async () => {
                        const allResults =
                          await downloadAllTaxFormRowSearchResults({
                            ...searchQueryFilterConfig,
                            expandOrganizationsForUserId
                          });
                        return mapITaxFormSearchRowItemsToCSV(allResults);
                      }}
                      fileName="1099-NEC Tax Forms"
                    />
                  ) : null}
                </WSFlexBox>
                {queryTaxFormRowSearchCount.isFetched &&
                  typeof queryTaxFormRowSearchCount.data === "number" && (
                    <WSText weight="medium" color="gray500" inline>
                      Showing {queryTaxFormRowSearchCount.data} items
                    </WSText>
                  )}

                <WSElement mt="2XL" data-testid="nec1099FilingContainer">
                  <WSQueries
                    queries={{ queryTaxFormRowSearch, queryOrgAccounts }}
                  >
                    {({ queryTaxFormRowSearchData, queryOrgAccountsData }) => {
                      return flatten(queryTaxFormRowSearchData).length > 0 ? (
                        <NEC1099FilingSearchTable
                          queryTaxFormRowSearch={queryTaxFormRowSearch}
                          organizations={queryOrgAccountsData}
                        />
                      ) : queryTaxFormRowSearch.isFetched ? (
                        <WSCentered span={{ m: "6" }}>
                          <TableNoDataMessage
                            search={filters.search}
                            isFiltered={
                              !!(
                                filters.confirmationStatus ||
                                filters.organizationId ||
                                filters.w9Shared
                              )
                            }
                          />
                        </WSCentered>
                      ) : null;
                    }}
                  </WSQueries>
                </WSElement>
              </>
            );
          }}
        </WSQueries>
      </WSPage>
    </WSElement>
  );
};
