import { useMemo } from "react";
import {
  BulkStatus,
  CustomFieldResourceType,
  IBulkCollaboratorBatch,
  IBulkCollaboratorItem
} from "@wingspanhq/payments/dist/interfaces";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { prepareCSVCollaboratorFromBatchItem } from "../../utils/prepareCSVCollaboratorFromBatchItem";
import { prepareCollaboratorCSVFile } from "../../utils/prepareCollaboratorCSVFile";

export function useDownloadErrorsCSV(
  bulkBatchId: string,
  bulkResource: BulkResource
) {
  const { useBulkBatch, useAllBulkBatchItems } =
    getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const queryCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const queryAllFailedBulkBatchItems = useAllBulkBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled:
        (queryBulkBatch.data?.statistics?.itemsFailed || 0) > 0 ||
        queryBulkBatch.data?.status === BulkStatus.Failed
    }
  );

  const errorsCSVFileProps = useMemo(() => {
    const failedCSVCollaborators = (
      queryAllFailedBulkBatchItems.data ?? []
    ).map(failedBatchItem =>
      prepareCSVCollaboratorFromBatchItem(
        failedBatchItem as IBulkCollaboratorItem,
        queryCollaboratorCustomFields.data || [],
        [],
        { includeErrors: true }
      )
    );
    const errorsCSVFileProps = prepareCollaboratorCSVFile(
      `Errors_${getUploadedFilename(
        queryBulkBatch.data as IBulkCollaboratorBatch,
        BulkResource.Collaborator
      )}`,
      queryCollaboratorCustomFields.data || [],
      failedCSVCollaborators,
      { includeErrors: true }
    );

    return errorsCSVFileProps;
  }, [
    queryAllFailedBulkBatchItems.data,
    queryCollaboratorCustomFields.data,
    queryBulkBatch.data
  ]);

  return { errorsCSVFileProps };
}
