import {
  TaxFormDeliveryMethod,
  TaxFormEventType,
  TaxFormStatus,
  TaxFormSubmissionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { ITaxFormRedactedEvent } from "@wingspanhq/payments/dist/interfaces/taxForm";

// copy from https://github.com/wingspanHQ/payments/blob/staging/src/services/taxFiling/taxFormSubmissionService.ts#L865-L884
const checkIfTaxFormDeliveredToday = (
  deliveryMethod: TaxFormDeliveryMethod,
  submissionId: string,
  eventHistory?: ITaxFormRedactedEvent[]
) => {
  // Get event type based on delivery method
  const taxFormEventType =
    deliveryMethod === TaxFormDeliveryMethod.Mail
      ? TaxFormEventType.Mailed1099CopySent
      : TaxFormEventType.Emailed1099CopyDelivered;

  // Check if the event has already happened today
  const isAlreadySentToday = eventHistory?.some(event => {
    const isMatchingEvent = event.eventType === taxFormEventType;

    const happenedToday =
      event.timestamp?.toDateString() === new Date().toDateString();
    const isMatchingSubmission = event.submissionId === submissionId;

    return isMatchingEvent && isMatchingSubmission && happenedToday;
  });

  return isAlreadySentToday;
};

export const selectorTaxFormIsResendDeliveryAvailable = (
  taxFormStatus: TaxFormStatus,
  submissionStatus: TaxFormSubmissionStatus,
  deliveryMethod: TaxFormDeliveryMethod,
  submissionId: string,
  eventHistory?: ITaxFormRedactedEvent[]
): boolean => {
  const isTaxFormPostFiling = [
    TaxFormStatus.SubmittedToIrs,
    TaxFormStatus.AcceptedByIrs,
    TaxFormStatus.RejectedByIrs
  ].includes(taxFormStatus);
  const isSubmissionPreFiling = [
    TaxFormSubmissionStatus.Created,
    TaxFormSubmissionStatus.PendingFiling
  ].includes(submissionStatus);

  const isAlreadySentToday = checkIfTaxFormDeliveredToday(
    deliveryMethod,
    submissionId,
    eventHistory
  );

  return !isAlreadySentToday && isTaxFormPostFiling && !isSubmissionPreFiling;
};
