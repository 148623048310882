import { WSQueryConfig } from "@ws-react-query";
import {
  getTaxFormSubmissionRowsSummary,
  TaxFormSubmissionRequest,
  TaxFormSubmissionRowsSummary
} from "../../../../services/search";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

export const QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY =
  "QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY";

export const useTaxFormSubmissionRowsSummaryQuery = (
  params?: TaxFormSubmissionRequest,
  config?: WSQueryConfig<TaxFormSubmissionRowsSummary, WSServiceError>
) => {
  const xWingspanExpansion = useXWingspanExpansion();

  return useWSQuery<TaxFormSubmissionRowsSummary, WSServiceError>(
    [QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY, { params, xWingspanExpansion }],
    () => {
      return getTaxFormSubmissionRowsSummary(
        {
          ...params,
          page: { size: 1, number: 1 }
        },
        xWingspanExpansion
      );
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
