import {
  useIsMobile,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { FreelanceType } from "@wingspanhq/users/dist/lib/interfaces";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../Auth/components/Header/Header";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCMSWebflowPartner } from "../../query/cms/queries";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useSetWSStore, useWSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";
import { OnboardingQuestion } from "../components/OnboardingQuestion/OnboardingQuestion";
import styles from "./styles.module.scss";

interface OnboardingStep1Props {}

export const OnboardingStep1: React.FC<OnboardingStep1Props> = () => {
  const [answer, setAnswer] = useLocalStorage("pre-sign-up:q1", "");

  const history = useHistory();
  const isMobile = useIsMobile();
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();

  const partnerId =
    (store.growthAttributionDetails?.growthSource ===
      GrowthSourceNames.Partner &&
      store.growthAttributionDetails?.growthName) ||
    "";
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: !!partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  const partner = qPartner.data;

  const onSubmit = async (data: any) => {
    setAnswer(data.freelanceType);
    history.push(`/member/pre-sign-up/questionnaire/2${location.search}`);
  };
  return (
    <WSLayout
      line
      onBack={isMobile ? undefined : () => history.goBack()}
      headerCenter={<Header hideBorder partnerLogo={partner?.logo?.src} />}
    >
      <BrowserPageTitle title="Onboarding: Step 1" />
      <WSMessageBox kind="Warning" noBorder centered className={styles.banner}>
        {" "}
      </WSMessageBox>
      <WSContainer verticalPadding>
        {isMobile ? (
          <WSFlexBox.CenterY mb="M" onClick={() => history.goBack()}>
            <WSIcon
              block
              name="chevron-left"
              size="XS"
              mr="XS"
              color="gray700"
            />
            <WSText.ParagraphSm color="gray500">
              <b>Back</b>
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : null}
        <OnboardingQuestion
          name="freelanceType"
          question={{
            question: (
              <WSText.Heading4 inline>
                First things first!
                <WSText.Heading4>Are you self-employed?</WSText.Heading4>
              </WSText.Heading4>
            ),
            options: [
              {
                label: "Studio/Team of Freelancers",
                value: FreelanceType.Team,
                virtualAssistantMsg:
                  "Well let’s get on the same team then! Wingspan helps you take care of your people and coordinate with subcontractors.",
                ctaBtnText: "See how Wingspan works for you"
              },
              {
                label: "Full-time 1099",
                value: FreelanceType.FullTime,
                virtualAssistantMsg:
                  "Get it! Wingspan has everything you need to work for yourself and feel in control.",
                ctaBtnText: "See how Wingspan works for you"
              },
              {
                label: "Part-time 1099",
                value: FreelanceType.PartTime,
                virtualAssistantMsg:
                  "Someone's skills are in demand! We know how much you're juggling. We're here to put the admin stuff on cruise control.",
                ctaBtnText: "See how Wingspan works for you"
              },
              {
                label: "Not freelancing currently",
                value: FreelanceType.NotFreelancing,
                virtualAssistantMsg:
                  "Well, freelancing isn't for everybody. But if you're not freelancing, Wingspan isn't for you. How about a blind date with a website?",
                ctaBtnText: "Take me to a random website"
              }
            ]
          }}
          onSubmit={onSubmit}
          defaultValues={{
            freelanceType: answer
          }}
        />
      </WSContainer>
    </WSLayout>
  );
};
