import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { useAccounts, useMemberProfile } from "../../../../query/users/queries";
import { FormAddDebitCard } from "../../../../shared/components/FormAddDebitCard";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { FormType } from "../../../../shared/components/FormManuallyAddAccount/FormType";
import {
  ManualAccountSubType,
  ManualAccountType
} from "../../../../shared/components/FormManuallyAddAccount/types";
import { FormManuallyAddInternationalAccount } from "../../../../shared/components/FormManuallyAddInternationalAccount";
import { StepLayoutWithOptions } from "../../../../shared/components/Layout/StepLayoutWithOptions";
import {
  avatarBankAccount,
  avatarBankCard,
  avatarInstantPayout,
  avatarInternationalBankAccount,
  avatarPlaid,
  avatarUSBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { usePaymentCardForm } from "../../../../shared/hooks/usePaymentCardForm";
import { selectorInstantPayoutDefaultFee } from "../../../../shared/selectors/selectorInstantPayoutDefaultFee";
import { selectorUseInternationalPayouts } from "../../../../shared/selectors/selectorUseInternationalPayouts";
import { useWSPlaidLinkExtended } from "../../../../utils/useWSPlaidLinkExtended";
import { FormCreateInternalAccount } from "../../../Onboarding/components/FormCreateInternalAccount";
import { selectorAccountRtpReady } from "../../../Transfer/components/FormTransfer/selectorAccountRtpReady";
import { selectorAccountWithdrawalReady } from "../../../Transfer/components/FormTransfer/selectorAccountWithdrawalReady";
import { FormSelectAccount } from "../FormSelectAccount";

export type PayoutMethodType =
  | "instant"
  | "standard"
  | "wallet"
  | "international";

export type PayoutMethodInstantType =
  | "instant-card"
  | "instant-plaid"
  | "instant-manual-type";

export type PayoutMethodStandardType =
  | "standard-plaid"
  | "standard-manual-type";

type Props = { basePath: string; onBack?: () => void; onNext?: () => void };

export const FlowAddPayoutMethod: React.FC<Props> = ({
  basePath,
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryCustomerEntity = useQueryCustomerEntity();
  const isInternational = queryMember.data
    ? selectorUseInternationalPayouts(
        queryMember.data,
        queryCustomerEntity.data
      )
    : false;

  const queryPayoutSettings = usePayoutSettings(userId);
  const queryFeatureFlags = useFeatureFlags();

  const paymentCardForm = usePaymentCardForm();

  const history = useHistory();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);
  const queryAccounts = useAccounts();
  const allStandardAccounts = useMemo(
    () => (queryAccounts.data || []).filter(selectorAccountWithdrawalReady),
    [queryAccounts.data]
  );
  const allRtpAccounts = useMemo(
    () => (queryAccounts.data || []).filter(selectorAccountRtpReady),
    [queryAccounts.data]
  );

  const { openSnackbar } = useWSSnackbar();

  const instantPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountRtpReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/instant-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  const standardPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountWithdrawalReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/standard-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  const [standardManualAccountType, setStandardManualAccountType] = useState<{
    type: ManualAccountType;
    subType: ManualAccountSubType;
  }>({
    type: "personal",
    subType: "checking"
  });

  const [instantManualAccountType, setInstantManualAccountType] = useState<{
    type: ManualAccountType;
    subType: ManualAccountSubType;
  }>({
    type: "personal",
    subType: "checking"
  });

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepLayoutWithOptions<PayoutMethodType>
          key="type"
          title="Add payout method"
          description="Add a payout method to deposit your income"
          options={
            isInternational
              ? [
                  {
                    header: {
                      label: {
                        text: "Non-U.S. account",
                        helperText:
                          "Wingspan will deposit into your bank account in the currency of that account in a non-U.S. country or in U.S.-dollars for USD accounts.",
                        avatar: avatarInternationalBankAccount
                      }
                    },
                    value: "international"
                  },
                  {
                    header: {
                      label: {
                        text: "U.S. based account",
                        helperText:
                          "Wingspan will keep your deposits in USD and deposit it in your U.S.-based account.",

                        avatar: avatarUSBankAccount
                      }
                    },
                    value: "standard"
                  }
                ]
              : [
                  {
                    header: {
                      label: {
                        text: `Instant payout (${selectorInstantPayoutDefaultFee(
                          queryPayoutSettings.data
                        )}% fee)`,
                        helperText:
                          "Fastest. Receive your payments immediately when sent to eligible bank & debit cards.",
                        avatar: avatarInstantPayout
                      }
                    },
                    value: "instant"
                  },
                  {
                    header: {
                      label: {
                        text: "Wingspan Wallet (free)",
                        helperText: "Faster. Free business bank & debit card.",
                        avatar: avatarWallet
                      }
                    },
                    value: "wallet"
                  },
                  {
                    header: {
                      label: {
                        text: "Bank account (free)",
                        helperText:
                          "Standard. Connect your existing bank account.",
                        avatar: avatarBankAccount
                      }
                    },
                    value: "standard"
                  }
                ]
          }
          onBack={onBack}
          onNext={value => {
            history.push(basePath + "/" + value);
          }}
        />
      </Route>

      <Route path={basePath + "/instant"}>
        <StepLayoutWithOptions<PayoutMethodInstantType>
          key="instant"
          title={`Add instant payout method (${selectorInstantPayoutDefaultFee(
            queryPayoutSettings.data
          )}% fee)`}
          description={`Receive your funds in under an hour. A ${selectorInstantPayoutDefaultFee(
            queryPayoutSettings.data
          )}% fee will be applied when the transaction is made.`}
          options={[
            {
              header: {
                label: {
                  text: "Connect a debit card",
                  helperText:
                    "Receive payments by connecting an eligible Visa or Mastercard debit card",
                  avatar: avatarBankCard
                }
              },
              value: "instant-card"
            },
            {
              header: {
                label: {
                  text: "Set up direct deposit with Plaid",
                  helperText:
                    "Instantly connect your bank accounts securely (Instant verification)",
                  avatar: avatarPlaid
                }
              },
              value: "instant-plaid"
            },
            {
              header: {
                label: {
                  text: "Set up direct deposit manually",
                  helperText: "Manually enter your routing and account numbers",
                  avatar: avatarBankAccount
                }
              },
              value: "instant-manual-type"
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          isLoading={paymentCardForm.isLoading}
          onNext={value => {
            if (value === "instant-plaid") {
              instantPlaidLink.open();
            } else if (value === "instant-card") {
              if (queryFeatureFlags.data?.paymentCardAsPayoutMethod) {
                paymentCardForm.open({
                  onSuccess: onNext,
                  setAsInstantPayoutMethod: true
                });
              } else {
                history.push(basePath + "/instant-card");
              }
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/standard"}>
        <StepLayoutWithOptions<PayoutMethodStandardType>
          key="stanrard"
          title="Add bank account (free)"
          description="Add a bank account and receive your funds in the standard 2-5 business days."
          options={[
            {
              header: {
                label: {
                  text: "Link account with Plaid",
                  helperText:
                    "Instantly verify and connect your bank accounts securely",
                  avatar: avatarPlaid
                }
              },
              value: "standard-plaid"
            },
            {
              header: {
                label: {
                  text: "Set up account manually",
                  helperText: "Manually enter your routing and account numbers",
                  avatar: avatarBankAccount
                }
              },
              value: "standard-manual-type"
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={value => {
            if (value === "standard-plaid") {
              standardPlaidLink.open();
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/wallet"}>
        <FormCreateInternalAccount
          type="Banking"
          currency="USD"
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={onNext}
        />
      </Route>

      <Route path={basePath + "/instant-card"}>
        <FormAddDebitCard
          setInstantPayoutPreference
          updateInstantDestinaiton
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onSuccess={onNext}
          withPanel
        />
      </Route>

      <Route path={basePath + "/instant-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your instant payout method"
          setInstantPayoutDestination
          accounts={
            createdAccounts.length > 0 ? createdAccounts : allRtpAccounts
          }
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onNext={onNext}
        />
      </Route>

      <Route path={basePath + "/instant-manual-type"}>
        <FormType
          value={instantManualAccountType}
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onNext={newValue => {
            setInstantManualAccountType(newValue);
            history.push(basePath + "/instant-manual");
          }}
        />
      </Route>

      <Route path={basePath + "/instant-manual"}>
        <FormManuallyAddAccount
          rtpRequired
          setInstantPayoutDestination
          onBack={() => {
            history.push(basePath + "/instant-manual-type");
          }}
          onSuccess={onNext}
          withPanel
          type={instantManualAccountType.type}
          subType={instantManualAccountType.subType}
        />
      </Route>

      <Route path={basePath + "/standard-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your payout method"
          setStandardPayoutDestination
          accounts={
            createdAccounts.length > 0 ? createdAccounts : allStandardAccounts
          }
          onBack={() => {
            history.push(basePath + "/standard");
          }}
          onNext={onNext}
        />
      </Route>

      <Route path={basePath + "/standard-manual-type"}>
        <FormType
          value={standardManualAccountType}
          onBack={() => {
            history.push(basePath + "/ach");
          }}
          onNext={newValue => {
            setStandardManualAccountType(newValue);
            history.push(basePath + "/standard-manual");
          }}
        />
      </Route>

      <Route path={basePath + "/standard-manual"}>
        <FormManuallyAddAccount
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/standard-manual-type");
          }}
          onSuccess={onNext}
          withPanel
          type={standardManualAccountType.type}
          subType={standardManualAccountType.subType}
        />
      </Route>

      <Route path={basePath + "/international"}>
        <FormManuallyAddInternationalAccount
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onSuccess={onNext}
          withPanel
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
