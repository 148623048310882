import {
  useWSModal,
  WSButton,
  WSInfoBox
} from "@wingspanhq/fe-component-library";
import React from "react";
import { PayableColumnsStackedView } from "../PayableColumnsStackedView";

const ModalPayableColumnsGuide: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <>
      <WSInfoBox my="L">
        <b>Upload a spreadsheet of line items:</b>
        <ul>
          <li>
            Upload a CSV or Excel spreadsheet of your line items. Don't worry if
            your spreadsheet has other details, but please make sure it contains
            the required columns to associate payables with the intended
            contractors.
          </li>
          <li>
            Optional fields capture more information when adding payables.
          </li>
        </ul>

        <PayableColumnsStackedView my="L" mx="XL" />
      </WSInfoBox>
      <WSButton fullWidth onClick={onClose}>
        Done
      </WSButton>
    </>
  );
};

export const useModalPayablesColumnsGuide = () =>
  useWSModal(ModalPayableColumnsGuide, {
    title: "Payables spreadsheet guide",
    size: "L"
  });
