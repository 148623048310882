import { textNumbersSymbolsStringValidator } from "../../utils/validators";

const REGEX_PO_BOX =
  /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/gim;
const testNoPOBox = {
  name: "No PO Box",
  message: "PO Boxes are not supported",
  test: (value?: string) => !REGEX_PO_BOX.test(value || "")
};

export const validatorAddress =
  textNumbersSymbolsStringValidator.test(testNoPOBox);

export const validatorAddressIncludePOBox = textNumbersSymbolsStringValidator;
