import {
  toWSDateString,
  useModalOldContext,
  WSButton,
  WSButtonProps,
  WSElement,
  WSElementColorsType,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSLoader,
  WSMenuItem,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus,
  IPayableSchema,
  IPayeeResponse,
  PayrollWorkflowStrategy
} from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Actions } from "../../../components/Actions";
import { getPayeeName } from "../../../modules/Payees/selectors/getPayeeNames";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useApprovePayable,
  useDownloadInvoicePdf,
  useDownloadInvoiceReceiptPdf,
  usePreApprovePayable,
  useSendPayable,
  useUnapprovePayable
} from "../../../query/payments/mutations";
import { usePayrollSettings } from "../../../query/payments/queries";
import {
  getHasPayableEditAccess,
  getIsPayableCreator
} from "../../../query/payments/selectors";
import { useClientQuery } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { selectorIsDefaultPaymentMethodSet } from "../../../shared/selectors/selectorIsDefaultPaymentMethodSet";
import { ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL } from "../../screens/AddDefaultPaymentMethodWarningModal";
import { calculateLineItemsTotal } from "../../utils";
import { getMatchingPayroll } from "../../utils/payables";
import { DELETE_PAYABLE_MODAL } from "../DeletePayableModal/DeletePayableModal";
import {
  InvoiceStatusTimeline,
  InvoiceTimelineStatus
} from "../InvoiceStatusTimeline/InvoiceStatusTimeline";
import { REJECT_PAYABLE_MODAL } from "../RejectPayableModal/RejectPayableModal";
import { selectorIsPayableApprovalPending } from "./selectorIsPayableApprovalPending";
import { selectorIsPayableApproved } from "./selectorIsPayableApproved";
import { selectorIsPayableFunded } from "./selectorIsPayableFunded";

type Props = { payable: IPayableSchema; payee: IPayeeResponse };

export const PayableDetailsHeader: React.FC<Props> = ({ payable, payee }) => {
  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryClient = useClientQuery(userId);

  const [sendPayable, sendPayableMeta] = useSendPayable();
  const [preApprovePayable, preApprovePayableMeta] = usePreApprovePayable();
  const [approvePayable, approvePayableMeta] = useApprovePayable();
  const [unapprovePayable, unapprovePayableMeta] = useUnapprovePayable();
  const { openModal } = useModalOldContext();
  const [downloadInvoicePdf, downloadInvoicePdfMeta] = useDownloadInvoicePdf();
  const [downloadInvoiceReceiptPdf, downloadInvoiceReceiptPdfMeta] =
    useDownloadInvoiceReceiptPdf();

  const history = useHistory();
  const location = useLocation();

  const payableId = payable.payableId;

  const onEdit = useCallback(() => {
    history.push({
      pathname: `/member/invoices/payables/${payableId}/edit`,
      state: {
        backPath: location.pathname + (location.search || "")
      }
    });
  }, [history, location.pathname, location.search, payableId]);

  return (
    <WSElement>
      <WSQueries
        queries={{
          queryPayrollSettings,
          queryClient
        }}
      >
        {({
          queryPayrollSettings: { data: payrollSettings },
          queryClient: { data: client }
        }) => {
          const openNowButtonProps = {
            children: "Open now",
            onClick: () => {
              sendPayable({ payableId, action: "open" });
            },
            loading: sendPayableMeta.isLoading
          };

          const preApproveNowButtonProps = {
            children: "Pre-Approve now",
            onClick: () => {
              preApprovePayable(payableId);
            },
            loading: preApprovePayableMeta.isLoading
          };

          const approveNowButtonProps = {
            children: "Approve now",
            onClick: () => {
              if (selectorIsDefaultPaymentMethodSet(client, payrollSettings)) {
                approvePayable(payableId);
              } else {
                openModal(ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL, {
                  nextUrl: "/member/invoices/payables"
                });
              }
            },
            loading: approvePayableMeta.isLoading
          };

          const unapproveButtonProps = {
            children: "Unapprove",
            onClick: () => {
              unapprovePayable(payableId);
            },
            loading: unapprovePayableMeta.isLoading
          };

          const downloadPDFButtonProps = {
            children: "Download Receipt PDF",
            onClick: () => {
              downloadInvoiceReceiptPdf({
                invoiceId: payable.payableId
              });
            },
            loading: downloadInvoiceReceiptPdfMeta.isLoading
          };

          const editButtonProps = {
            children: "Edit",
            onClick: onEdit
          };

          let icon: WSIconName | undefined;
          let iconColor: WSElementColorsType | undefined;
          let header: string = "";
          let subheader: string = "";
          let status: InvoiceTimelineStatus | undefined;
          let buttonProps: WSButtonProps | null = null;

          if (selectorIsPayableApprovalPending(payable)) {
            if (
              payrollSettings.workflow === PayrollWorkflowStrategy.DualStage
            ) {
              if (
                payable.client.workflowStatus ===
                ClientWorkFlowStatus.PreApproved
              ) {
                subheader = "Approve or pay now";
                buttonProps = approveNowButtonProps;
                status = InvoiceTimelineStatus.PreApproved;
              } else {
                subheader = "Pre-Approve";
                buttonProps = preApproveNowButtonProps;
                status = InvoiceTimelineStatus.Open;
              }
            } else {
              subheader = "Approve or pay now";
              buttonProps = approveNowButtonProps;
              status = InvoiceTimelineStatus.Open;
            }

            if (payable.status === InvoiceStatus.Open) {
              header = "Invoice open";
            } else if (payable.status === InvoiceStatus.Overdue) {
              header = "Invoice payment is overdue";
              icon = "info-circle";
              iconColor = "red400";
            } else if (payable.status === InvoiceStatus.Pending) {
              header = "Eligibility pending";
              subheader = "Waiting on contractor to enable digital payments";
            }
          } else if (selectorIsPayableApproved(payable)) {
            header = "Approved for payment";
            subheader = `Will be paid on ${toWSDateString(
              getMatchingPayroll(payrollSettings, payable)?.date,
              "monthDate"
            )}`;
            buttonProps = unapproveButtonProps;
            status = InvoiceTimelineStatus.Approved;
          } else if (selectorIsPayableFunded(payable)) {
            header = "Funded for payment";
            subheader =
              "Funded and waiting on contractor to enable digital payments";
            buttonProps = downloadPDFButtonProps;
            status = InvoiceTimelineStatus.Paid;
          } else if (payable.status === InvoiceStatus.Paid) {
            header = "Invoice Paid";
            subheader = payable.events.depositedAt
              ? `Invoice was deposited on ${toWSDateString(
                  payable.events.depositedAt,
                  "monthDate"
                )}`
              : payable.events.paidAt
              ? `Invoice was processed (paid) on ${toWSDateString(
                  payable.events.paidAt,
                  "monthDate"
                )}`
              : "Invoice was paid";
            buttonProps = downloadPDFButtonProps;

            status = payable.events.depositedAt
              ? InvoiceTimelineStatus.Deposited
              : InvoiceTimelineStatus.Paid;
          } else if (payable.status === InvoiceStatus.PaymentInTransit) {
            header = "Waiting on wire to arrive";
            subheader = "Invoice was marked paid via bank transfer";
          } else if (payable.status === InvoiceStatus.Draft) {
            header = "This is a draft";
            subheader = "Open invoice to pay";
            buttonProps =
              calculateLineItemsTotal(payable.lineItems) > 0
                ? openNowButtonProps
                : editButtonProps;
          }

          const menuItems: WSMenuItem[] = [];

          if (
            payable.client.workflowStatus ===
              ClientWorkFlowStatus.PreApproved ||
            payable.client.workflowStatus === ClientWorkFlowStatus.Approved
          ) {
            menuItems.push({
              icon: "block",
              label: "Unapprove",
              onClick: () => {
                unapprovePayable(payableId);
              }
            });
          }

          if (
            [
              InvoiceStatus.Draft,
              InvoiceStatus.Open,
              InvoiceStatus.Overdue,
              InvoiceStatus.Pending
            ].includes(payable.status)
          ) {
            menuItems.push({
              icon: "edit",
              label: "Edit",
              onClick: onEdit
            });
          }

          if (
            [
              InvoiceStatus.Pending,
              InvoiceStatus.Open,
              InvoiceStatus.Overdue
            ].includes(payable.status) &&
            !getIsPayableCreator(payable, userId) &&
            (!payable.client.workflowStatus ||
              payable.client.workflowStatus === ClientWorkFlowStatus.Pending ||
              payable.client.workflowStatus === ClientWorkFlowStatus.Declined)
          ) {
            menuItems.push({
              icon: "exit",
              label: "Reject",
              onClick: () => {
                openModal(REJECT_PAYABLE_MODAL, {
                  payee,
                  payable
                });
              }
            });
          }

          if (
            [InvoiceStatus.Draft].includes(payable.status) &&
            getHasPayableEditAccess(payable, userId)
          ) {
            menuItems.push({
              icon: "exit",
              label: "Remove",
              onClick: () => {
                openModal(DELETE_PAYABLE_MODAL, {
                  payable,
                  payee
                });
              }
            });
          }

          if (
            [
              InvoiceStatus.Open,
              InvoiceStatus.Overdue,
              InvoiceStatus.Pending
            ].includes(payable.status) &&
            getHasPayableEditAccess(payable, userId)
          ) {
            menuItems.push({
              icon: "exit",
              label: "Cancel invoice",
              onClick: () => {
                openModal(DELETE_PAYABLE_MODAL, {
                  payable,
                  payee
                });
              }
            });
          }

          // Download PDF
          if (
            payable.status !== InvoiceStatus.Draft &&
            payable.status !== InvoiceStatus.Pending
          ) {
            menuItems.push({
              icon: "download",
              label: "Download PDF",
              onClick: () => {
                downloadInvoicePdf({
                  invoiceId: payable.payableId
                });
              }
            });
          }

          // Download Receipt PDF
          if (payable.status === InvoiceStatus.Paid) {
            menuItems.push({
              icon: "download",
              label: "Download Receipt PDF",
              onClick: () => {
                downloadInvoiceReceiptPdf({
                  invoiceId: payable.payableId
                });
              }
            });
          }

          let timelineStatuses = [
            InvoiceTimelineStatus.Open,
            InvoiceTimelineStatus.Approved,
            InvoiceTimelineStatus.Paid,
            InvoiceTimelineStatus.Deposited
          ];
          if (payrollSettings.workflow === PayrollWorkflowStrategy.DualStage) {
            timelineStatuses = [
              InvoiceTimelineStatus.Open,
              InvoiceTimelineStatus.PreApproved,
              InvoiceTimelineStatus.Approved,
              InvoiceTimelineStatus.Paid,
              InvoiceTimelineStatus.Deposited
            ];
          }

          const collaboratorDetailsUrl = `/member/payees/${payable.memberId}`;
          function moveToCollaboratorDetails() {
            history.push(collaboratorDetailsUrl);
          }

          return (
            <>
              <WSFlexBox.CenterY mb="XS">
                {icon && <WSIcon block name={icon} color={iconColor} mr="M" />}
                <WSText.Heading5>{header}</WSText.Heading5>
              </WSFlexBox.CenterY>
              <WSText>{subheader}</WSText>

              <WSButton.Link mt="M" onClick={moveToCollaboratorDetails}>
                {getPayeeName(payee)}
              </WSButton.Link>
              <WSButton.Link mb="XL" onClick={moveToCollaboratorDetails}>
                {payee.user?.email}
              </WSButton.Link>

              <InvoiceStatusTimeline
                currentStatus={status}
                statuses={timelineStatuses}
                mb="XL"
              />

              <WSFlexBox wrap="nowrap" alignItems="center">
                {buttonProps && (
                  <WSButton
                    fullWidth
                    {...buttonProps}
                    mr={menuItems.length > 0 ? "M" : undefined}
                  />
                )}
                {unapprovePayableMeta.isLoading ? (
                  <WSLoader.Spinner size="S" />
                ) : (
                  menuItems.length > 0 && (
                    <Actions items={menuItems} name="payableActions" />
                  )
                )}
              </WSFlexBox>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
