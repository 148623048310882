import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { bulkCalculation1099Service } from "../../BulkImporter/services/bulkCalculation1099";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { IBulkCalculation1099Item } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";

export const QUERY_ACTIVE_BATCH_ITEMS = "QUERY_ACTIVE_BATCH_ITEMS";

export const useActiveBatchItems = (
  year: number,
  config?: WSQueryConfig<IBulkCalculation1099Item[], WSServiceError>
) => {
  return useWSQuery<IBulkCalculation1099Item[], WSServiceError>(
    [QUERY_ACTIVE_BATCH_ITEMS, year],
    async () => {
      const allBatches = await bulkCalculation1099Service.batch.list({
        filter: {
          "labels.filingYear": year,
          status: {
            in: [BulkStatus.Open, BulkStatus.Processing, BulkStatus.Pending]
          }
        } as any,
        page: {
          size: 100,
          number: 1
        }
      });

      if (Array.isArray(allBatches) && allBatches.length) {
        const filteredBatches = allBatches.filter(batch =>
          [BulkStatus.Processing, BulkStatus.Open, BulkStatus.Pending].includes(
            batch.status
          )
        );

        const currentBatch = filteredBatches[0];

        if (currentBatch) {
          const batchItems = await bulkCalculation1099Service.batchItem.list(
            currentBatch.bulkCalculation1099BatchId
          );

          return batchItems as any;
        }
      }
      return [];
    },
    {
      refetchOnMount: true,
      ...config
    }
  );
};
