import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useCreateOrUpdateSubscriptionV3 } from "../../query/subscriptions/mutations";
import { FREE_TRIAL_DAYS } from "../../Settings/components/MembershipPackages/subscriptionPackages";

export const BENEFITS_START_FREE_TRIAL = "BENEFITS_START_FREE_TRIAL";

export const BenefitsStartFreeTrial: React.FC = () => {
  const { closeModal } = useModalOldContext();
  const userId = useUserId();
  const [createOrUpdateSubscription, createOrUpdateSubscriptionMeta] =
    useCreateOrUpdateSubscriptionV3(userId);

  return (
    <WSModalOld name={BENEFITS_START_FREE_TRIAL} size="M">
      {({ setUp }) => (
        <>
          <WSText.Heading5 mb="XL">
            Your {FREE_TRIAL_DAYS} days free trial for Premium Perks starts now!
          </WSText.Heading5>

          <WSButtons format="modal">
            <WSButton.Primary
              loading={createOrUpdateSubscriptionMeta.isLoading}
              onClick={async () => {
                await createOrUpdateSubscription({
                  package: SubscriptionPackage.Benefits,
                  packageTier: SubscriptionPackageTier.Basic
                });
                closeModal(BENEFITS_START_FREE_TRIAL);
                setUp();
              }}
              name="continueSetUpBenefits"
            >
              Continue set up benefits
            </WSButton.Primary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
