import {
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSModalOld
} from "@wingspanhq/fe-component-library";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { useSession } from "../../../query/session";
import { LogoApp } from "../../../shared/components/LogoApp";
import { Auth } from "../../components/Auth";
import { Account } from "./Account";
import { History } from "./History";
import { RouteAddBankAccountManually } from "./RouteAddBankAccountManually";
import { RouteAddPaymentMethod } from "./RouteAddPaymentMethod";
import { RouteManagePaymentMethod } from "./RouteManagePaymentMethod";

export const ProtectedRoutes: React.FC<RouteComponentProps> = () => {
  const sessionQuery = useSession();

  if (sessionQuery.isLoading) {
    return (
      <Layout>
        <WSLoader.Spinner />
      </Layout>
    );
  }

  if (sessionQuery.data?.sessionType !== SessionType.user) {
    return (
      <WSModalOld size="S" blockClose>
        <Auth />
      </WSModalOld>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route path="/payment/history" component={History} />
        <Route path="/payment/account" component={Account} />
        <Route
          path="/payment/add-bank-account-manually"
          component={RouteAddBankAccountManually}
        />

        <Route
          path="/payment/manage-payment-method"
          component={RouteManagePaymentMethod}
        />

        <Route
          path="/payment/add-payment-method"
          component={RouteAddPaymentMethod}
        />
      </Switch>
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => {
  const history = useHistory();
  const sessionQuery = useSession();
  const location = useLocation();

  return (
    <>
      <WSElement py="XL">
        <WSContainer>
          <WSFlexBox wrap="nowrap" justify="space-between" alignItems="center">
            <LogoApp />
            <WSFlexBox.CenterY>
              {location.pathname === "/payment/account" && (
                <WSButton.Link
                  size="S"
                  mr="XL"
                  onClick={() => {
                    history.push("/payment/history");
                  }}
                >
                  History
                </WSButton.Link>
              )}
              {location.pathname === "/payment/history" && (
                <WSButton.Link
                  size="S"
                  mr="XL"
                  onClick={() => {
                    history.push("/payment/account");
                  }}
                >
                  Settings
                </WSButton.Link>
              )}
              {sessionQuery.data?.sessionType === SessionType.user && (
                <WSButton.Secondary
                  size="S"
                  onClick={() => {
                    history.push("/sign-out#/payment/history");
                  }}
                >
                  Sign out
                </WSButton.Secondary>
              )}
            </WSFlexBox.CenterY>
          </WSFlexBox>
        </WSContainer>
      </WSElement>
      <WSDivider />
      <WSElement py="3XL">
        <WSContainer>{children}</WSContainer>
      </WSElement>
    </>
  );
};
