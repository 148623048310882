import { WSQueryConfig } from "@ws-react-query";

import { useWSQuery } from "../../helpers";
import { IPayerEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { QUERY_PAYER_ENGAGEMENTS_LIST } from "../keys";
import { getPayerEngagements } from "../../../services/payerEngagements";

export const usePayerEngagementsListQuery = (
  payerId: string,
  queryConfig?: WSQueryConfig<IPayerEngagementListResponse, unknown>
) =>
  useWSQuery(
    [QUERY_PAYER_ENGAGEMENTS_LIST, payerId],
    () => getPayerEngagements(payerId),
    {
      retry: false,
      ...queryConfig
    }
  );
