import {
  toWSDateString,
  toWSMoneyString,
  WSAvatarProps,
  WSElementColorsType,
  WSIconName,
  WSPillProps,
  WSTooltipIconProps
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import addDays from "date-fns/addDays";
import {
  getHasInvoiceEditAccess,
  getIsInvoiceAccepted,
  getIsInvoiceCreator,
  getIsInvoiceRejected,
  getIsInvoiceResubmitted
} from "../../../../query/payments/selectors";
import { addBusinessDays, isSameDate } from "../../../../utils/dates";
import { IInvoiceRow } from "../../service";
import { calculateAmountAvailableToRefund } from "../../../../Invoices/utils/calculateAmountAvailableToRefund";

// These props are not exported from FECL at the moment
// TODO: replace this with FECL interface once it's exported
type TableCellProps = {
  text?: string;
  secondaryText?: string;
  secondaryTextColor?: WSElementColorsType;
  icon?: WSIconName;
  avatar?: WSAvatarProps;
  pill?: WSPillProps;
  truncationOptions?: {
    text?: {
      allowTwoLines?: boolean;
    };
  };
  onClick?: React.MouseEventHandler<HTMLElement>;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export function getSecondaryTextProps(
  invoice: IInvoiceRow
): TableCellProps | undefined {
  if (invoice.oldStatus === InvoiceStatus.Draft) {
    if (isSameDate(invoice.createdAt, invoice.updatedAt)) {
      return {
        secondaryText: `Created ${toWSDateString(
          invoice.createdAt,
          "monthDate"
        )}`
      };
    }

    return {
      secondaryText: `Last updated ${toWSDateString(
        invoice.updatedAt,
        "monthDate"
      )}`
    };
  }

  if (invoice.oldStatus === InvoiceStatus.Paid) {
    if (invoice.events.markedPaidAt) {
      const isClientOwner = getHasInvoiceEditAccess(invoice, invoice.clientId);

      if (isClientOwner) {
        return {
          secondaryText: "Client’s historical records",
          tooltip:
            "Off-platform payments represent invoices that the payer uploaded from their records for record keeping and/or tax reporting purposes and were not paid through Wingspan."
        };
      }
    }

    if (invoice.events.refundedAt) {
      const amountAvailableToRefund = calculateAmountAvailableToRefund(invoice);
      const isPartialRefund = amountAvailableToRefund > 0;

      if (isPartialRefund) {
        return {
          secondaryText: `Partial refund • ${toWSDateString(
            invoice.events.refundedAt,
            "monthDate"
          )}`
        };
      } else {
        return {
          secondaryText: `Full refund • ${toWSDateString(
            invoice.events.refundedAt,
            "monthDate"
          )}`
        };
      }
    }

    if (invoice.events.instantPayoutAt) {
      return {
        secondaryText: `Deposited instantly on ${toWSDateString(
          invoice.events.instantPayoutAt,
          "monthDate"
        )}`
      };
    }

    if (invoice.events.depositedAt || !!invoice.events.estimatedDepositAt) {
      const arrivalDate = addBusinessDays(
        invoice.events.depositedAt ||
          (invoice.events.estimatedDepositAt as Date),
        1
      );
      return arrivalDate < new Date()
        ? {
            secondaryText: `Expected • ${toWSDateString(
              arrivalDate,
              "monthDate"
            )}`,
            tooltip:
              "If you haven’t received your payout by the expected date, please contact your bank."
          }
        : {
            secondaryText: `Expected • ${toWSDateString(
              arrivalDate,
              "monthDate"
            )}`,
            tooltip: `${toWSMoneyString(
              Math.abs(invoice.amountDetails?.memberGross || 0)
            )} expected to arrive by ${toWSDateString(
              arrivalDate,
              "monthDate"
            )}`
          };
    }

    return {
      secondaryText: `Paid outside of Wingspan on ${toWSDateString(
        invoice.events.paidAt,
        "monthDate"
      )}`
    };
  }

  if (invoice.oldStatus === InvoiceStatus.PaymentInTransit) {
    return { secondaryText: "Waiting on client's wire transfer to arrive" };
  }

  if (getIsInvoiceRejected(invoice)) {
    return {
      secondaryText: "Client rejected invoice",
      tooltip: {
        title: "Reject comment:",
        children: invoice.client.comment
      }
    };
  }

  if (getIsInvoiceResubmitted(invoice)) {
    return {
      secondaryText: `Client resolved dispute on ${toWSDateString(
        invoice.events.clientResolvedDisputeAt,
        "monthDate"
      )}`
    };
  }

  if (invoice.oldStatus === InvoiceStatus.Pending) {
    return { secondaryText: "Invoice is pending" };
  }

  if (invoice.oldStatus === InvoiceStatus.Open) {
    if (
      addDays(new Date(), 3) > invoice.dueDate &&
      getIsInvoiceCreator(invoice, invoice.memberId)
    ) {
      return {
        secondaryText: `Due soon (${toWSDateString(
          invoice.dueDate,
          "monthDate"
        )})`,
        secondaryTextColor: "amber400"
      };
    }

    if (
      invoice.events.emailViewedAt &&
      invoice.events.emailViewedAt.length > 0
    ) {
      return {
        secondaryText: `Last viewed ${toWSDateString(
          invoice.events.emailViewedAt[invoice.events.emailViewedAt.length - 1],
          "monthDate"
        )}`
      };
    }

    if (getIsInvoiceAccepted(invoice)) {
      return {
        secondaryText: `Accepted by you on ${toWSDateString(
          invoice.events.memberAcceptedAt,
          "monthDate"
        )}`
      };
    }

    return {
      secondaryText: `Sent ${toWSDateString(
        invoice.events.openedAt,
        "monthDate"
      )}`
    };
  }

  if (invoice.oldStatus === InvoiceStatus.Overdue) {
    return {
      secondaryText: `Overdue (${toWSDateString(
        invoice.dueDate,
        "monthDate"
      )})`,
      secondaryTextColor: "red400"
    };
  }
}
