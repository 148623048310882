import {
  WSAvatar,
  WSAvatarProps,
  WSElement,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { StepPoint } from "../../../../shared/components/Layout/StepPoint";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";

import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  isBatchFullyImported,
  isBatchPartiallyImported,
  isUploadFinished,
  isUploadInProgress
} from "../../utils/bulkBatchUtils";
import styles from "./styles.module.scss";

type Props = {
  bulkBatchId: string;
  bulkResource: BulkResource;
};

export const BulkBatchImportSteps: React.FC<Props> = ({
  bulkBatchId,
  bulkResource
}) => {
  const { useBulkBatch, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const queryBulkSummary = useBulkBatchSummary(bulkBatchId);
  const isEmptyBatch = queryBulkSummary.data?.listSize === 0;

  const uploadFileStepStatus = useMemo(() => {
    if (isUploadFinished(queryBulkBatch.data, isEmptyBatch)) {
      return "Complete";
    } else if (isUploadInProgress(queryBulkBatch.data)) {
      return "InProgress";
    }
    return "Active";
  }, [queryBulkBatch.data, isEmptyBatch]);

  const completeImportStepStatus = useMemo(() => {
    if (isBatchFullyImported(queryBulkBatch.data)) {
      return "Complete";
    } else if (isBatchPartiallyImported(queryBulkBatch.data)) {
      return "Warning";
    } else if (queryBulkBatch.data?.status === "Failed") {
      return "Failed";
    } else if (
      [BulkStatus.Pending, BulkStatus.Processing].includes(
        queryBulkBatch.data?.status as BulkStatus
      )
    ) {
      return "InProgress";
    } else if (isUploadFinished(queryBulkBatch.data, isEmptyBatch)) {
      return "Active";
    }
    return "Pending";
  }, [queryBulkBatch.data, isEmptyBatch]);
  return (
    <WSQueries queries={{ queryBulkBatch }}>
      {({ queryBulkBatchData: bulkBatch }) => {
        return (
          <WSPanel className={styles.container}>
            <WSList gap="L">
              <WSText.Paragraph weight="medium" color="gray600">
                Batch import steps
              </WSText.Paragraph>
              <Steps
                steps={[
                  { title: "Create batch", status: "Complete" },
                  { title: "Upload spreadsheet", status: uploadFileStepStatus },
                  { title: "Complete import", status: completeImportStepStatus }
                ]}
              />
            </WSList>
          </WSPanel>
        );
      }}
    </WSQueries>
  );
};

type StepItem = {
  title: string;
  status:
    | "Pending"
    | "Active"
    | "InProgress"
    | "Complete"
    | "Warning"
    | "Failed";
};

type StepsProps = {
  steps: StepItem[];
} & WSMarginProps;

// TODO: Maybe move this to a shared component in the future
const Steps: React.FC<StepsProps> = ({ steps }) => {
  return (
    <WSElement className={styles.steps}>
      {steps.map((step, index) => (
        <Step key={index} {...step} />
      ))}
    </WSElement>
  );
};

const Step: React.FC<StepItem> = ({ title, status }) => {
  const avatarProps = useMemo(() => {
    const statusToPropsMap: Record<StepItem["status"], WSAvatarProps> = {
      Pending: { type: "icon", color: "gray200", icon: "time" }, // not required, we will render StepPoint instead
      Active: { type: "icon", color: "blue500", icon: "time" }, // not required, we will render StepPoint--active instead
      InProgress: { type: "icon", color: "blue500", icon: "time" },
      Complete: { type: "icon", color: "green400", icon: "check" },
      Warning: { type: "icon", color: "amber300", icon: "alert" },
      Failed: { type: "icon", color: "red300", icon: "alert-circle" }
    };
    return statusToPropsMap[status];
  }, [status]);

  return (
    <WSElement className={styles.step}>
      <WSText.ParagraphXs
        className={styles.title}
        weight="medium"
        color="gray600"
      >
        {title}
      </WSText.ParagraphXs>
      {["Pending", "Active"].includes(status) ? (
        <StepPoint className={styles.point} isActive={status === "Active"} />
      ) : (
        <WSAvatar
          className={styles.point}
          size="S"
          format="circle"
          {...avatarProps}
          colorBackground={avatarProps.color}
          color="white"
        />
      )}
    </WSElement>
  );
};
