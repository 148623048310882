import classNames from "classnames";
import React, { useRef } from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import styles from "./WSSectionToolbar.module.scss";
import { WSPill, WSPillProps } from "../common";
import { WSTooltip } from "../WSTooltip/WSTooltip";
import {
  WSActions,
  WSActionsButton,
  WSActionsMenuItem
} from "../WSActions/WSActions";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";

export interface WSSectionToolbarButton extends WSActionsButton {}

export type WSSectionToolbarTooltip = { title?: string; text: React.ReactNode };

export interface WSSectionToolbarProps extends WSMarginProps {
  title: string;
  className?: string;
  button?: WSSectionToolbarButton;
  pill?: WSPillProps;
  tooltip?: WSSectionToolbarTooltip;
  actions?: WSActionsMenuItem[];
  hasBorder?: boolean;
}

export const WSSectionToolbar: React.FC<WSSectionToolbarProps> = ({
  className,
  title,
  actions,
  button,
  pill,
  tooltip,
  children,
  hasBorder,
  ...elementProps
}) => {
  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  return (
    <WSElement
      className={classNames(styles.wrapper, className, {
        [styles.hasBorder]: hasBorder
      })}
      {...elementProps}
    >
      <WSText.Paragraph weight="medium" singleLine className={styles.title}>
        {title}
      </WSText.Paragraph>
      {tooltip ? (
        <>
          <WSTooltip target={tooltipIconRef} dark title={tooltip.title}>
            {tooltip.text}
          </WSTooltip>
          <WSIcon
            className={styles.tooltipIcon}
            ref={tooltipIconRef}
            size="S"
            color="gray500"
            name="info-circle-fill"
          />
        </>
      ) : null}
      {pill ? <WSPill {...pill} className={styles.pill} /> : null}
      <WSElement className={styles.space} />
      {actions?.length || button ? (
        <WSActions
          alignment="right"
          menuItems={actions || []}
          buttons={
            button
              ? [
                  {
                    kind: "Link",
                    ...button,
                    className: classNames(styles.button, button.className)
                  }
                ]
              : undefined
          }
        />
      ) : null}
    </WSElement>
  );
};
