import { WSQueryConfig } from "@ws-react-query";
import { useWSQuery } from "../../../query/helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  getInvoiceRowsSummary,
  InvoiceRowsQuery,
  InvoiceRowsSummary
} from "../service";

export const QUERY_KEY_INVOICES_ROWS_SUMMARY =
  "QUERY_KEY_INVOICES_ROWS_SUMMARY";

export const useQueryInvoicesRowsSummary = (
  requestQuery?: InvoiceRowsQuery,
  config?: WSQueryConfig<InvoiceRowsSummary, WSServiceError>
) =>
  useWSQuery<InvoiceRowsSummary, WSServiceError>(
    [QUERY_KEY_INVOICES_ROWS_SUMMARY, requestQuery],
    () => getInvoiceRowsSummary(requestQuery),
    config
  );
