import { useRouteMatch } from "react-router-dom";
import { useBulkPayableBatch } from "../../query/bulkPayable/queries";
import { WSQueries } from "../../../../query/WSQuery";
import {
  toWSDateString,
  WSCard,
  WSDataItem,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPill,
  WSText,
  WSTimeline
} from "@wingspanhq/fe-component-library";
import { useGoToPayableBatchDetails, useGoToPayableBatches } from "../../paths";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkResource } from "../../types";
import { useBulkBatchStatus } from "../../hooks/useBulkBatchStatus";
import { getTimelineItems } from "../../components/BulkBatchEventHistoryWidget";
import { BULK_BATCH_EVENT_HISTORY_START_DATE } from "../../constants";
import { useMemo } from "react";

export const RouteImportPayableBatchEventHistory: React.FC = () => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const queryBulkPayableBatch = useBulkPayableBatch(bulkBatchId);
  const pillProps = useBulkBatchStatus(bulkBatchId, BulkResource.Payable, true);
  const bulkBatch = queryBulkPayableBatch.data!;
  const timelineItems = useMemo(() => {
    if (!bulkBatch) {
      return [];
    }
    return getTimelineItems(bulkBatch, BulkResource.Payable, {
      showDetailedContent: true
    });
  }, [bulkBatch]);
  const goToPayableBatches = useGoToPayableBatches();
  const gotoPayableBatchDetails = useGoToPayableBatchDetails();
  return (
    <WSQueries queries={{ queryBulkPayableBatch }}>
      {({ queryBulkPayableBatchData: bulkBatch }) => {
        const date = toWSDateString(bulkBatch.createdAt, "monthDayYear");
        return (
          <WSPage>
            <WSPageToolbar
              mb="XL"
              breadcrumbs={[
                {
                  label: "Bulk imports (payables)",
                  onClick: () => {
                    goToPayableBatches();
                  }
                },
                {
                  label:
                    (bulkBatch.labels?.[
                      BATCH_LABELS_WS_KEYS.bulkBatchName
                    ] as string) || date,
                  onClick: () => {
                    gotoPayableBatchDetails(bulkBatchId);
                  }
                },
                { label: "Batch history" }
              ]}
            />

            <WSText.Heading3 weight="medium" color="gray700" mb="2XL">
              Batch history
            </WSText.Heading3>

            <WSGrid>
              <WSGrid.Item span={{ s: "12", m: "8" }}>
                <WSCard mb="L">
                  <WSList.Horizontal equalItems>
                    <WSDataItem
                      label="Name"
                      value={
                        (bulkBatch.labels?.[
                          BATCH_LABELS_WS_KEYS.bulkBatchName
                        ] as string) ||
                        getUploadedFilename(bulkBatch, BulkResource.Payable)
                      }
                    />
                    <WSFlexBox direction="column">
                      <WSText.ParagraphSm weight="book" color="gray500" mb="XS">
                        Status
                      </WSText.ParagraphSm>
                      <WSPill {...pillProps} />
                    </WSFlexBox>
                  </WSList.Horizontal>
                </WSCard>

                <WSCard
                  header={{
                    label: "History"
                  }}
                  divider
                >
                  <WSList gap="L">
                    {timelineItems.length === 0 ? (
                      <WSText.ParagraphXs
                        weight="book"
                        color="gray400"
                        align="center"
                      >
                        No history events available
                      </WSText.ParagraphXs>
                    ) : (
                      <>
                        <WSTimeline items={timelineItems} />
                        <WSText.ParagraphXs
                          weight="book"
                          color="gray400"
                          align="center"
                        >
                          All available history events shown
                        </WSText.ParagraphXs>
                      </>
                    )}
                    <WSInfoBox>
                      <WSText.ParagraphXs weight="book" color="gray500">
                        Note: Payable history events are only available from the
                        start date of the history feature:{" "}
                        {toWSDateString(
                          new Date(BULK_BATCH_EVENT_HISTORY_START_DATE),
                          "monthDayYear"
                        )}
                        .
                      </WSText.ParagraphXs>
                    </WSInfoBox>
                  </WSList>
                </WSCard>
              </WSGrid.Item>
            </WSGrid>
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
