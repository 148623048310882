import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces";

export const CASHBACK_PERCENTAGE = 0.01;

export const selectorTransactionCashbackAmount = (
  transaction: ITransaction
) => {
  const amount = transaction.amount;
  const rewardTransactionId = transaction.labels?.rewardTransactionId;
  const rewardAmount = transaction.labels?.rewardAmount;

  if (amount && rewardTransactionId && rewardAmount) {
    return Number(rewardAmount);
  } else if (amount && rewardTransactionId) {
    // Legacy cashback calculation
    return amount * CASHBACK_PERCENTAGE;
  } else {
    return 0;
  }
};
