import {
  toWSDateString,
  WSElement,
  WSElementProps,
  WSInfiniteScroll,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableColumnSortDirection,
  WSTableHeaderAction,
  WSTableItem
} from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";

import {
  TaxFormDeliveryMethod,
  TaxFormSubmissionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { IMember } from "@wingspanhq/users/dist/lib/interfaces/member";
import { useHistory } from "react-router-dom";
import { useTaxFormSubmissionRowsQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { TaxFormSubmissionRow } from "../../../../../services/search";
import { UrlQuerySort } from "../../../../../shared/hooks/useUrlQuery";
import { downloadFileFromBlob } from "../../../../../utils/files";
import { FillingStatusBadge } from "../../../components/FillingStatusBadge";
import { useRecipientCorrectionExistsModal } from "../../../components/ModalRecipientCorrectionExists/ModalRecipientCorrectionExists";
import { NameCell } from "../../../components/NameCell";
import { NEC1099TotalCell } from "../../../components/NEC1099TotalCell";
import { OrganizationCell } from "../../../components/OrganizationCell";
import { TaxID } from "../../../components/TaxID";
import {
  useGoToTaxFormDetails,
  useGoToTaxFormNewCorrection
} from "../../../paths";
import { useModalResendElectronicCopy } from "./useModalResendElectronicCopy";
import { useModalResendMailCopy } from "./useModalResendMailCopy";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { getFormFileName } from "../../../utils/formFileName";
import {
  copyTaxFormSubmissionSecureLink,
  getExpirationDate,
  getIsSecureLinkExpired
} from "../../../utils/secureLink";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { getExpirationLinkMessage } from "../../../../../shared/utils/getExpirationLinkMessage";
import { useModalGenerateSecureLink } from "./useModalGenerateSecureLink";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { useGoToReviewContractorCorrection } from "../../../../TaxCorrections/utils/useGoToReviewContractorCorrection";
import { selectorTaxFormIsResendDeliveryAvailable } from "../../../selectors/selectorTaxFormIsResendDeliveryAvailable";

export interface NEC1099FilingTableProps extends WSElementProps {
  queryTaxFormSubmissions: ReturnType<typeof useTaxFormSubmissionRowsQuery>;
  organizations: INewUser[];
  currentMember: IMember;
  headerAction?: WSTableHeaderAction;
  sort?: UrlQuerySort<"name" | "totalAmount">;
  onSort: (
    field: "name" | "totalAmount",
    direction: WSTableColumnSortDirection
  ) => void;
}

export const NEC1099FilingTable: React.FC<NEC1099FilingTableProps> = ({
  queryTaxFormSubmissions,
  organizations,
  headerAction,
  currentMember,
  sort,
  onSort,
  ...elementProps
}) => {
  const history = useHistory();
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();
  const modalResendElectronicCopy = useModalResendElectronicCopy();
  const modalResendMailCopy = useModalResendMailCopy();
  const recipientCorrectionExistsModal = useRecipientCorrectionExistsModal();
  const goToDetails = useGoToTaxFormDetails();
  const generateSecureLinkModal = useModalGenerateSecureLink();
  const queryFeatureFlags = useFeatureFlags();
  const goToTaxFormNewCorrection = useGoToTaxFormNewCorrection();
  const goToReviewContractorCorrection = useGoToReviewContractorCorrection();

  const { success } = useSnackbar();

  const onView = (item: WSTableItem<TaxFormSubmissionRow>) => {
    goToDetails(item.data.taxFormId, item.data.taxFormSubmissionId);
  };

  const list = useMemo(() => {
    const taxForms: TaxFormSubmissionRow[] = queryTaxFormSubmissions.data || [];
    return taxForms.map(row => ({
      id: row.id,
      data: row
    }));
  }, [queryTaxFormSubmissions.data]);

  const firstColumns: WSTableColumn<TaxFormSubmissionRow>[] = [
    {
      config: {
        gridTemplateSizeMax: "1.5fr",
        header: {
          text: "Name"
        },
        sortDirection: sort?.field === "name" ? sort?.direction : undefined,
        onColumnSort: direction => {
          onSort("name", direction);
        }
      },
      renderFunction: ({ data }) => {
        return <NameCell taxFormSubmission={data} />;
      }
    }
  ];

  if (organizations.length > 0) {
    firstColumns.push({
      config: {
        gridTemplateSizeMax: "1.2fr",
        hideOnScreens: ["XS", "S"],
        header: {
          text: "Org Account Name"
        }
      },
      renderFunction: ({ data }) => {
        return <OrganizationCell taxFormSubmission={data} />;
      }
    });
  }

  return (
    <WSElement {...elementProps}>
      <WSInfiniteScroll
        onLoad={() => {
          queryTaxFormSubmissions.fetchNextPage();
        }}
        loadMore={list.length > 0}
        endOfList={!queryTaxFormSubmissions.hasNextPage}
        loading={!!queryTaxFormSubmissions.isFetchingNextPage}
      >
        <WSTable<TaxFormSubmissionRow>
          onRowClick={onView}
          columns={[
            ...firstColumns,
            {
              config: {
                gridTemplateSizeMax: "1.25fr",
                hideOnScreens: ["XS", "S"],
                header: {
                  text: "Tax ID"
                }
              },
              renderFunction: ({ data }) => {
                return <TaxID taxFormSubmission={data} />;
              }
            },
            {
              config: {
                gridTemplateSizeMax: "0.5fr",
                hideOnScreens: ["XS", "S", "M"],
                header: {
                  text: "Delivery"
                }
              },
              renderFunction: ({ data }) => {
                return (
                  <WSTableCell
                    text={
                      data.data.deliveryMethod ===
                      TaxFormDeliveryMethod.Electronic
                        ? "Electronic"
                        : "Mail"
                    }
                  />
                );
              }
            },

            {
              config: {
                gridTemplateSizeMax: "1fr",
                header: {
                  text: "1099-NEC Total"
                },
                sortDirection:
                  sort?.field === "totalAmount" ? sort?.direction : undefined,
                onColumnSort: direction => {
                  onSort("totalAmount", direction);
                }
              },
              renderFunction: ({ data }) => {
                return <NEC1099TotalCell taxFormSubmission={data} />;
              }
            },
            {
              config: {
                gridTemplateSizeMax: "1fr",
                header: {
                  text: "Filing Status"
                }
              },
              renderFunction: ({ data }) => {
                let customDescription = "";
                let supersededPostfix =
                  data.isSupercededString === "true" ? " (superseded)" : "";

                if (data.hasPendingCorrectionString === "true") {
                  customDescription = "Correction requested by contractor";
                } else if (data.isCorrectionRejectedString === "true") {
                  customDescription = "Correction rejected by you";
                } else if (data.isCorrectionAcceptedString === "true") {
                  customDescription = "Correction accepted by you";
                }

                if (data.isVoidedString === "true") {
                  customDescription = "Voided form";
                } else if (data.isCorrectedString === "true") {
                  customDescription = "Corrected form" + supersededPostfix;
                } else if (data.isSupercededString === "true") {
                  customDescription = "Original form" + supersededPostfix;
                }

                return (
                  <FillingStatusBadge
                    status={data.searchableStatus}
                    correctionStatus={data.lastCorrectionStatus}
                    pendingCorrectionId={data.taxForm.pendingCorrectionId}
                    exclusionReason={data.taxForm.exclusionReason}
                    customDescription={customDescription}
                  />
                );
              }
            }
          ]}
          headerAction={headerAction}
          rowMenuActions={item => {
            const status = item.data
              .searchableStatus as TaxFormSubmissionStatus;

            const menuItems: WSMenuItem[] = [];

            const isCurrentSubmission =
              item.data.taxForm.currentSubmissionId ===
              item.data.taxFormSubmissionId;

            menuItems.push({
              label: "View",
              icon: "view",
              onClick: () => {
                onView(item);
              }
            });

            if (
              queryFeatureFlags.data?.taxDocumentsSeason2024PayerCorrections &&
              isCurrentSubmission
            ) {
              const isPendingCorrection =
                !!item.data.taxForm.pendingCorrectionId;

              if (isPendingCorrection) {
                menuItems.push({
                  label: "Review correction request",
                  icon: "clipboard-check",
                  onClick: () => {
                    goToReviewContractorCorrection(
                      status,
                      item.data.taxFormId,
                      item.data.taxFormSubmissionId
                    );
                  }
                });
              }

              menuItems.push({
                label: "Start new correction",
                icon: "clipboard",
                disabled:
                  isPendingCorrection ||
                  ![
                    TaxFormSubmissionStatus.Corrected,
                    TaxFormSubmissionStatus.AcceptedByIrs
                  ].includes(status),
                onClick: () => {
                  goToTaxFormNewCorrection(
                    item.data.taxFormId,
                    item.data.taxFormSubmissionId
                  );
                }
              });
            }

            if (
              [
                TaxFormSubmissionStatus.SubmittedToIrs,
                TaxFormSubmissionStatus.AcceptedByIrs,
                TaxFormSubmissionStatus.RejectedByIrs,
                TaxFormSubmissionStatus.Corrected,
                TaxFormSubmissionStatus.Voided
              ].includes(status)
            ) {
              const secureSharing = item.data.secureSharing;

              const isSecureLinkExpired = secureSharing?.createdAt
                ? getIsSecureLinkExpired(secureSharing?.createdAt)
                : false;

              const isSecureLinkReadyToCopy =
                !isSecureLinkExpired && !!secureSharing?.token;

              if (isSecureLinkReadyToCopy) {
                menuItems.push({
                  label: "Copy secure share link",
                  icon: "copy",
                  helpText: getExpirationLinkMessage(
                    getExpirationDate(secureSharing?.createdAt!)
                  ),
                  onClick: () => {
                    copyTaxFormSubmissionSecureLink(secureSharing?.token!);
                    success("Secure link copied to clipboard");
                  }
                });
              } else {
                menuItems.push({
                  label: "Generate secure share link",
                  icon: "link",
                  helpText: `Secure links expire ${toWSDateString(
                    getExpirationDate(new Date()),
                    "monthDate"
                  )}`,
                  onClick: () => {
                    generateSecureLinkModal.open({
                      taxFormId: item.data.taxFormId,
                      submissionId: item.data.taxFormSubmissionId
                    });
                  }
                });
              }

              menuItems.push({
                label: "Download payer copy",
                icon: "download",
                onClick: () => {
                  downloadDocument(
                    {
                      taxFormId: item.data.taxFormId,
                      submissionId: item.data.taxFormSubmissionId,
                      viewer: TaxFormViewer.payer
                    },
                    {
                      onSuccess(pdf) {
                        // get current user name
                        const memberName = wsName({
                          user: { email: "company" },
                          member: currentMember
                        }).getResolvedName();
                        // get payer name from the item if org account
                        let payerName = item.data.payer
                          ? wsName({
                              user: item.data.payer?.user,
                              member: item.data.payer?.member
                            }).getResolvedName()
                          : undefined;

                        const formPayerFileName = getFormFileName({
                          viewer: TaxFormViewer.payer,
                          year: item.data.yearString,
                          payerName: payerName ?? memberName, // prefer payer name if available
                          payeeW9: item.data.data?.w9Info
                        });

                        downloadFileFromBlob(pdf, formPayerFileName);
                      }
                    }
                  );
                }
              });
              menuItems.push({
                label: "Download recipient copy",
                icon: "download",
                onClick: () => {
                  downloadDocument(
                    {
                      taxFormId: item.data.taxFormId,
                      submissionId: item.data.taxFormSubmissionId,
                      viewer: TaxFormViewer.payee
                    },
                    {
                      onSuccess(pdf) {
                        // get current user name
                        const memberName = wsName({
                          user: { email: "company" },
                          member: currentMember
                        }).getResolvedName();
                        // get payer name from the item if org account
                        let payerName = item.data.payer
                          ? wsName({
                              user: item.data.payer?.user,
                              member: item.data.payer?.member
                            }).getResolvedName()
                          : undefined;

                        const formPayeeFileName = getFormFileName({
                          viewer: TaxFormViewer.payee,
                          year: item.data.yearString,
                          payerName: payerName ?? memberName, // prefer payer name if available
                          payeeW9: item.data.data?.w9Info
                        });

                        downloadFileFromBlob(pdf, formPayeeFileName);
                      }
                    }
                  );
                }
              });
            }

            if (
              selectorTaxFormIsResendDeliveryAvailable(
                item.data.taxForm.status,
                item.data.searchableStatus as TaxFormSubmissionStatus,
                TaxFormDeliveryMethod.Electronic,
                item.data.taxFormSubmissionId,
                item.data.taxForm.eventHistory
              )
            ) {
              menuItems.push({
                label: "Re-send electronic copy",
                icon: "send",
                onClick: () => {
                  modalResendElectronicCopy.open({
                    taxForm: item.data
                  });
                }
              });
            }

            if (
              selectorTaxFormIsResendDeliveryAvailable(
                item.data.taxForm.status,
                item.data.searchableStatus as TaxFormSubmissionStatus,
                TaxFormDeliveryMethod.Mail,
                item.data.taxFormSubmissionId,
                item.data.taxForm.eventHistory
              )
            ) {
              menuItems.push({
                label: "Re-mail tax forms",
                icon: "send-mail",
                onClick: () => {
                  modalResendMailCopy.open({
                    taxForm: item.data
                  });
                }
              });
            }

            return menuItems;
          }}
          tableData={list}
        />
      </WSInfiniteScroll>
    </WSElement>
  );
};
