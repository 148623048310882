import {
  toWSDateString,
  toWSMoneyString,
  useWSModal,
  WSCard,
  WSInfoBox,
  WSLoader
} from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useEffect, useState } from "react";
import { useUserId } from "../../query/hooks/helpers";
import { usePayeeQuery } from "../../query/payee/queries/usePayeeQuery";
import { useGetGuestAccountNumbers } from "../../query/users/mutations";
import {
  useAccount,
  useMemberProfile,
  useUserProfile
} from "../../query/users/queries";
import { getRedactedMemberName } from "../../query/users/selectors";
import { WS_LINKS } from "../../types/wsLinks";

type Props = {
  onClose: () => void;
  accountId?: string;
  clientInvoice: IClientInvoice;
  onSubmit: () => void;
  plaidAccountId?: string;
  plaidPublicToken?: string;

  isAutopay?: boolean;
};

export const ModalAuthorizeACH: React.FC<Props> = props => {
  const accountQuery = useAccount(props.accountId as string, {
    enabled: !!props.accountId
  });
  const account = accountQuery.data;

  const [plaidAccountNumber, setPlaidAccountNumber] = useState("");
  const [plaidRoutingNumber, setPlaidRoutingNumber] = useState("");
  const [getGuestAccountNumbers, getGuestAccountNumbersMeta] =
    useGetGuestAccountNumbers();

  const userId = useUserId();
  const queryPayee = usePayeeQuery(props.clientInvoice.memberClient.memberId);
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);

  useEffect(() => {
    const callback = async () => {
      if (
        props.plaidAccountId &&
        props.plaidPublicToken &&
        !plaidAccountNumber &&
        !plaidRoutingNumber
      ) {
        await getGuestAccountNumbers(
          {
            accountId: props.plaidAccountId,
            publicToken: props.plaidPublicToken
          },
          {
            onSuccess: numbers => {
              setPlaidAccountNumber(numbers.account);
              setPlaidRoutingNumber(numbers.routing || "");
            }
          }
        );
      }
    };

    callback();
  }, [
    getGuestAccountNumbers,
    plaidAccountNumber,
    plaidRoutingNumber,
    props.plaidAccountId,
    props.plaidPublicToken
  ]);

  const isLoading =
    (props.accountId && accountQuery.isInitialLoading) ||
    getGuestAccountNumbersMeta.isLoading;

  const payerName =
    queryUser.data && queryMember.data
      ? wsName({
          user: queryUser.data,
          member: queryMember.data
        }).getResolvedName()
      : undefined;

  const payeeName =
    queryPayee.data?.user && queryPayee.data?.member
      ? wsName({
          user: queryPayee.data?.user,
          member: queryPayee.data?.member
        }).getResolvedName()
      : getRedactedMemberName(props.clientInvoice.memberClient.member);

  if (props.isAutopay) {
    return (
      <WSCard
        header={"Authorization for Recurring ACH Debits – from " + payeeName}
        divider
        actions={{
          alignment: "fill",
          buttons: [
            {
              kind: "Secondary",
              label: "Cancel",
              onClick: props.onClose
            },
            {
              label: "Continue",
              onClick: () => {
                props.onSubmit();
                props.onClose();
              }
            }
          ]
        }}
      >
        <WSInfoBox>
          <p>
            I, {payerName}, authorize {payeeName} to initiate recurring ACH
            debits from my designated bank account as of{" "}
            {toWSDateString(new Date(), "monthDayYear")}. The first debit will
            occur on {toWSDateString(new Date(), "monthDayYear")} and will recur
            as defined by your client.
          </p>
          <p>
            You may revoke this authorization at any time by opening your payee
            profile in Wingspan and clicking "Turn off" next to Autopay.
          </p>
          <p>
            Please review our complete{" "}
            <a
              href={WS_LINKS.wingspanTosAcceptance}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            . By clicking Continue below, you grant authorization for these ACH
            debits and agree to the terms.
          </p>
        </WSInfoBox>
      </WSCard>
    );
  }

  return (
    <WSCard
      header="Review & Pay"
      divider
      actions={{
        alignment: "fill",
        buttons: [
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: props.onClose
          },
          {
            label: "Authorize",
            onClick: () => {
              props.onSubmit();
              props.onClose();
            }
          }
        ]
      }}
    >
      <WSInfoBox>
        <p>
          I, {payerName}, authorize {payeeName} to debit{" "}
          {toWSMoneyString(props.clientInvoice.amount)} from my designated bank
          account on {toWSDateString(new Date(), "monthDayYear")}.
        </p>

        {isLoading ? (
          <WSLoader.Spinner size="XS" />
        ) : (
          <p>
            Amount: {toWSMoneyString(props.clientInvoice.amount)}
            <br />
            Date: {toWSDateString(new Date(), "monthDayYear")}
            <br />
            Routing #: {account?.numbers?.routing ?? plaidRoutingNumber ?? "–"}
            <br />
            Account #: {account?.numbers?.account ?? plaidAccountNumber ?? "–"}
            <br />
          </p>
        )}

        <p>
          I understand that:
          <ul>
            <li>This authorization is for a single transaction only.</li>
            <li>
              Once I authorize this payment, changes or corrections may not be
              possible.
            </li>
            <li>
              Transactions initiated after 3:00 PM ET or on weekends/holidays
              will be processed on the next business day.
            </li>
            <li>
              I have the right to receive a copy of this authorization. It is
              recommended that I print or save a copy for my records.
            </li>
            <li>
              If the debit is returned unpaid, I may be charged a fee by my
              financial institution.
            </li>
          </ul>
        </p>
        <p>
          By clicking "Authorize", I confirm that I have read and agree to these
          terms and authorize this one-time payment.
        </p>
      </WSInfoBox>
    </WSCard>
  );
};

export const useModalAuthorizeACH = () =>
  useWSModal(ModalAuthorizeACH, {
    style: {
      padding: 0,
      borderRadius: 8
    }
  });
