import {
  IServiceEnablementResponse,
  IServiceEnablementUpdate
} from "@wingspanhq/benefits/dist/lib/interfaces/api/service";
import { IEnrollment } from "@wingspanhq/benefits/dist/lib/interfaces/enrollment";
import {
  WSMutationsConfig,
  WSQueryCache,
  WSQueryConfig
} from "@ws-react-query";
import { benefitsService } from "../services/benefits";
import { WSServiceError } from "../utils/serviceHelper";
import { useWSMutation, useWSQuery } from "./helpers";

// keys
export const QUERY_BENEFITS_SERVICE = "QUERY_BENEFITS_SERVICE";
export const QUERY_BENEFITS_ENROLLMENT = "QUERY_BENEFITS_ENROLLMENT";

// queries
export const useBenefitsService = (
  queryConfig?: WSQueryConfig<IServiceEnablementResponse, WSServiceError>
) => {
  return useWSQuery(
    QUERY_BENEFITS_SERVICE,
    async () => {
      try {
        return await benefitsService.service.get();
      } catch {
        return { enabled: false } as IServiceEnablementResponse;
      }
    },
    queryConfig
  );
};

export const useBenefitsEnrollment = (
  userId: string,
  queryConfig?: WSQueryConfig<IEnrollment, WSServiceError>
) => {
  return useWSQuery(
    QUERY_BENEFITS_ENROLLMENT,
    async () => {
      try {
        return await benefitsService.enrollment.get(userId);
      } catch {
        return {} as IEnrollment;
      }
    },
    queryConfig
  );
};

// mutations
export const useUpdateBenefitsService = (
  config?: WSMutationsConfig<IServiceEnablementResponse, WSServiceError>
) =>
  useWSMutation(
    (request: IServiceEnablementUpdate & { userId: string }) =>
      benefitsService.service.update(request.userId, request),
    {
      onSuccess: async () => {
        await WSQueryCache.refetchQueries(QUERY_BENEFITS_SERVICE);
        await WSQueryCache.refetchQueries(QUERY_BENEFITS_ENROLLMENT);
      },
      ...config
    }
  );
