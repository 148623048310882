import { WSFlexBox, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import queryString from "qs";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { pushSessionToken } from "../../services/sessionStorage";

export const EmailMagicLinkHandler: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const queries = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });

  useEffect(() => {
    if (queries.token) {
      pushSessionToken(queries.token as string);
    }

    setTimeout(() => {
      let callbackUrl = queries.callbackUrl as string;
      if (callbackUrl) {
        // check url has query string or not
        if (/[?&]/.test(callbackUrl)) {
          callbackUrl = callbackUrl + `&newEmail=${queries.newEmail}`;
        } else {
          callbackUrl = callbackUrl + `?newEmail=${queries.newEmail}`;
        }
      } else {
        callbackUrl = "/member/dashboard";
      }
      history.replace(callbackUrl);
    }, 500);
  }, []);

  return (
    <OnboardingLayout>
      <WSFlexBox alignContent="center" alignItems="stretch" direction="column">
        <WSLoader.Spinner size="L" mb="2XL" />
        <WSText color="gray700">
          Please wait a moment until we confirm the action.
          <br />
          You will be redirected soon to corresponding page.
        </WSText>
      </WSFlexBox>
    </OnboardingLayout>
  );
};
