import {
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSCheckboxToggle,
  WSFlexBox,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IClientInvoice,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  AccountType,
  IAccount,
  SessionType
} from "@wingspanhq/users/dist/lib/interfaces";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { WSQueryCache } from "@ws-react-query";
import { formatMoney } from "accounting";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import { PlaidReAuth } from "../../components/PlaidReAuth/PlaidReAuth";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { usePayClientInvoice } from "../../query/payments/mutations";
import { useClientInvoiceQuery } from "../../query/payments/queries";
import {
  getClientInvoiceMemberName,
  getClientName
} from "../../query/payments/selectors";
import { useSession } from "../../query/session";
import {
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_CLIENT
} from "../../query/users/keys";
import {
  useCreateAccount,
  useUpdateAccount
} from "../../query/users/mutations";
import { useAccounts } from "../../query/users/queries";
import {
  getPossiblePaymentsAccounts,
  getRedactedMemberName
} from "../../query/users/selectors";
import { updatePayee } from "../../services/payees";
import { paymentsService } from "../../services/payments";
import { usersService } from "../../services/users";
import { VerticalDivider } from "../../shared/components/VerticalDivider";
import { useWSStore } from "../../store";
import { addBusinessDays } from "../../utils/dates";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSPlaidLink, WSPlaidLinkConfig } from "../../utils/usePlaidLink";
import {
  ACH_ERROR_MODAL,
  ACHErrorModal,
  ACHErrorType
} from "../components/ACHErrorModal/ACHErrorModal";
import {
  AUTHORIZE_ACH_PAYMENT_MODAL,
  AuthorizeACHPaymentModal
} from "../components/AuthorizeACHPaymentModal/AuthorizeACHPaymentModal";
import { AutopayCard } from "../components/AutopayCard";
import { BankAccountPreview } from "../components/BankAccountPreview/BankAccountPreview";
import { BankAccountSelect } from "../components/BankAccountSelect/BankAccountSelect";
import {
  BANK_ACCOUNT_SELECT_MODAL_KEY,
  BankAccountSelectoModalProps,
  BankAcocuntSelectModal
} from "../components/BankAcocuntSelectModal/BankAcocuntSelectModal";
import { Card } from "../components/Card/Card";
import { ClientInvoiceDetailsDrawer } from "../components/ClientInvoiceDetailsDrawer/ClientInvoiceDetailsDrawer";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { CLIENT_SIGN_IN_MODAL_KEY } from "../components/ClientSignInModal/ClientSignInModal";
import { DonwloadPdfButton } from "../components/DownloadPdfButton/DonwloadPdfButton";
import { InfoRow } from "../components/InfoRow/InfoRow";
import { useModalAuthorizeACH } from "../components/ModalAuthorizeACH";
import { PrivacyPolicy } from "../components/PrivacyPolicy/PrivacyPolicy";
import {
  getIsAutopayRequired,
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";

export const PLAID_LOGIN_REQUIRED_MODAL = "PLAID_LOGIN_REQUIRED_MODAL";

type Props = RouteComponentProps<{ invoiceId: string }>;

const ACHContext = React.createContext<{
  shouldOpenPlaid: boolean;
  setShouldOpenPlaid: (value: boolean) => void;
  plaidPublicToken: string;
  setPlaidPublicToken: (value: string) => void;
  selectedPlaidAccount?: IAccount;
  setSelectedPlaidAccount: (value: IAccount | undefined) => void;
  clientInvoice: IClientInvoice;
}>({
  shouldOpenPlaid: false,
  setShouldOpenPlaid: () => {},
  plaidPublicToken: "",
  setPlaidPublicToken: () => {},
  setSelectedPlaidAccount: () => {},
  clientInvoice: {} as IClientInvoice
});

const useAchContext = () => {
  return useContext(ACHContext);
};

const useClientPlaidLink = ({ onSuccess, ...options }: WSPlaidLinkConfig) => {
  const { openModal } = useModalOldContext();

  return useWSPlaidLink({
    onSuccess: (publicToken, meta) => {
      if (
        meta?.institution?.institution_id === "ins_13" ||
        meta?.institution?.institution_id === "ins_9"
      ) {
        openModal(ACH_ERROR_MODAL, {
          errorType: ACHErrorType.PlaidInstitutionForbidden,
          institutionName: meta?.institution?.name
        });

        return;
      }

      onSuccess(publicToken, meta);
    },
    onExit: (error, meta) => {
      if (meta.status === "institution_not_found") {
        openModal(ACH_ERROR_MODAL, {
          errorType: ACHErrorType.PlaidInstitutionNotFound
        });
      } else if (
        ["INSTITUTION_ERROR", "API_ERROR"].includes(error?.error_type)
      ) {
        openModal(ACH_ERROR_MODAL, {
          errorType: ACHErrorType.PlaidInstitutionDidNotRespond,
          institutionName: meta?.institution?.name
        });
      } else {
        openModal(ACH_ERROR_MODAL);
      }
    },
    product: ["auth"],
    ...options
  });
};

export const ClientPaymentsInvoicePaymentACH: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const invoiceId = match.params.invoiceId;
  const sessionQuery = useSession({ refetchOnWindowFocus: false });
  const isGuest = sessionQuery.data?.sessionType !== SessionType.user;

  const [plaidPublicToken, setPlaidPublicToken] = useState("");
  const [selectedPlaidAccount, setSelectedPlaidAccount] = useState<
    IAccount | undefined
  >();
  const [shouldOpenPlaid, setShouldOpenPlaid] = useState(false);

  const contextValue = useMemo(
    () => ({
      plaidPublicToken,
      setPlaidPublicToken,
      selectedPlaidAccount,
      setSelectedPlaidAccount,
      shouldOpenPlaid,
      setShouldOpenPlaid
    }),
    [plaidPublicToken, selectedPlaidAccount, shouldOpenPlaid]
  );

  return (
    <>
      <ClientInvoiceWrapper invoiceId={invoiceId}>
        {clientInvoice => {
          return isClientInvoicePaid(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}/success`} />
          ) : isClientInvoiceCancelled(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}`} />
          ) : (
            <ClientPaymentsWrapper
              clientInvoice={clientInvoice}
              companyName={clientInvoice.memberCompany}
              clientEmail={clientInvoice.memberClient.emailTo}
              memberName={getClientInvoiceMemberName(clientInvoice)}
              companyLogoUrl={clientInvoice.memberLogoUrl}
              onBack={() => {
                history.goBack();
              }}
            >
              <ACHContext.Provider value={{ ...contextValue, clientInvoice }}>
                {isGuest ? <GuestExperience /> : <UserExperience />}
              </ACHContext.Provider>

              <CertifiedSecureDigitalPayments mb="M" />
              <PrivacyPolicy />
            </ClientPaymentsWrapper>
          );
        }}
      </ClientInvoiceWrapper>

      <BankAcocuntSelectModal />
      <AuthorizeACHPaymentModal />
    </>
  );
};

const UserExperience: React.FC = () => {
  const {
    plaidPublicToken,
    selectedPlaidAccount,
    clientInvoice,
    shouldOpenPlaid
  } = useAchContext();
  const userId = useUserId();
  const invoiceId = clientInvoice.invoiceId;
  const history = useHistory();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { openModal, closeModal } = useModalOldContext();
  const [payClientInvoice, payClientInvoiceMeta] = usePayClientInvoice(
    invoiceId,
    {
      onError: error => {
        if (error.response?.data.errorSubType === "PlaidLoginRequired") {
          WSQueryCache.invalidateQueries(QUERY_USERS_ACCOUNTS);
          openModal(PLAID_LOGIN_REQUIRED_MODAL, {
            accountId: selectedAccountId
          });
        } else {
          openModal(ACH_ERROR_MODAL, { error });
        }
      }
    }
  );
  const [createAccount] = useCreateAccount();
  const [createAccountOnSubmit, createAccountOnSubmitMeta] = useCreateAccount();
  const [updateAccount, updateAccountMeta] = useUpdateAccount();
  const accountsQuery = useAccounts();
  const queryFlags = useFeatureFlags();
  const modalAuthorizeACH = useModalAuthorizeACH();

  const filteredAccounts = useMemo(() => {
    return accountsQuery.data
      ? getPossiblePaymentsAccounts(accountsQuery.data)
      : [];
  }, [accountsQuery.data]);

  const [selectedAccountId, setSelectedAccountId] = useState("");

  const [autopay, setAutopay] = useState<"enable" | "disable">("enable");

  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId);

  useEffect(() => {
    if (
      clientInvoiceQuery.isFetched &&
      clientInvoiceQuery.data &&
      getIsAutopayRequired(clientInvoiceQuery.data)
    ) {
      setAutopay("enable");
    }
  }, [clientInvoiceQuery.isFetched]);

  const [tryingToPayOwnInvoice, setTryingToPayOwnInvoice] =
    useState<ReactNode>("");
  const [canNotBePaid, setCanNotBePaid] = useState(false);
  const [isPaymentAuthorized, setIsPaymentAuthorized] = useState(false);
  const [enableAutopay] = useWSMutation<
    void,
    WSServiceError,
    {
      invoiceTemplateId: string;
      accountId: string;
      payeeId: string;
    }
  >(
    async ({ invoiceTemplateId, accountId, payeeId }) => {
      await usersService.client.update(userId, {
        clientId: userId,
        profile: {
          defaultPaymentMethod: {
            accountId
          }
        }
      });

      await updatePayee(payeeId, {
        payerOwnedData: {
          autoPayStrategy: PaymentRequirementStrategy.All
        }
      });

      await paymentsService.client.invoiceTemplate.update(invoiceTemplateId, {
        clientId: userId,
        accountId
      });
    },
    {
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

  const store = useWSStore();

  const isMobile = useIsMobile();

  const scrollToPayButton = useCallback(() => {
    if (isMobile) {
      window.scrollBy({ top: 1000 });
    }
  }, [isMobile]);

  const [didPlaidLoad, setDidPlaidLoad] = useState(false);

  const plaidHandler = useClientPlaidLink({
    onSuccess: async (publicToken: string) => {
      let accounts: IAccount[] | undefined;

      const wsAccounts = await createAccount(
        {
          publicToken,
          status: AccountStatus.Pending
        },
        {
          onError: () => {
            openModal(ACH_ERROR_MODAL);
          }
        }
      );

      if (wsAccounts) {
        accounts = (
          Array.isArray(wsAccounts) ? wsAccounts : [wsAccounts]
        ).filter(
          (a: IAccount) =>
            a.type === AccountType.Depository && a.canBeUsedFor?.payments
        );
      } else {
        accounts = undefined;
      }

      if (accounts) {
        openModal(BANK_ACCOUNT_SELECT_MODAL_KEY, {
          accounts,
          title: "Choose account to pay with",
          onSelect: async accountId => {
            await updateAccount(
              {
                accountId,
                status: AccountStatus.Active,
                usedFor: {
                  payments: true
                }
              },
              {
                onSuccess: () => {
                  setSelectedAccountId(accountId);
                }
              }
            );

            scrollToPayButton();
          }
        } as BankAccountSelectoModalProps);
      } else {
        openModal(ACH_ERROR_MODAL);
      }
    },
    onLoad: () => {
      setDidPlaidLoad(true);
    },
    context: {
      invoiceId
    }
  });

  useEffect(() => {
    if (
      accountsQuery.isFetched &&
      filteredAccounts.length === 0 &&
      didPlaidLoad &&
      shouldOpenPlaid
    ) {
      plaidHandler.open();
    }
  }, [
    accountsQuery.isFetched,
    didPlaidLoad,
    filteredAccounts.length,
    shouldOpenPlaid
  ]);

  const allowAutopay = !!clientInvoice.invoiceTemplateId;

  const paymentsV2 = clientInvoice.memberPaymentsVersion === 2;

  useEffect(() => {
    if (!selectedAccountId && filteredAccounts.length > 0) {
      setSelectedAccountId(filteredAccounts[0].accountId);
    }
  }, [filteredAccounts, selectedAccountId]);

  const submitWrapper = (callback: Function) => () => {
    if (clientInvoice.memberClient.memberId === userId) {
      setTryingToPayOwnInvoice(true);
      return;
    }

    if (!clientInvoice.memberAcceptsPayments) {
      setCanNotBePaid(true);
      return;
    }

    if (paymentsV2) {
      if (isPaymentAuthorized) {
        callback();
      } else {
        if (queryFlags.data?.achPayerAuthorization) {
          modalAuthorizeACH.open({
            accountId: selectedAccountId,
            plaidAccountId: selectedPlaidAccount?.accountId,
            plaidPublicToken,
            clientInvoice,
            onSubmit: () => {
              setIsPaymentAuthorized(true);
              callback();
            },
            isAutopay: autopay === "enable" && !!clientInvoice.invoiceTemplateId
          });
        } else {
          openModal(AUTHORIZE_ACH_PAYMENT_MODAL, {
            accountId: selectedAccountId,
            plaidAccountId: selectedPlaidAccount?.accountId,
            plaidPublicToken,
            clientInvoice,
            onSubmit: () => {
              setIsPaymentAuthorized(true);
              callback();
            }
          });
        }
      }
    } else {
      callback();
    }
  };

  const handleSubmitWithExistingAccount = () => {
    closeModal(ACH_ERROR_MODAL);
    payClientInvoice(
      {
        accountId: selectedAccountId
      },
      {
        onSuccess: () => {
          if (autopay === "enable" && clientInvoice.invoiceTemplateId) {
            enableAutopay({
              accountId: selectedAccountId,
              invoiceTemplateId: clientInvoice.invoiceTemplateId,
              payeeId: clientInvoice.memberClient.memberId
            });
          }
          history.push(`/payment/${invoiceId}/success`, {
            account: filteredAccounts.find(
              account => account.accountId === selectedAccountId
            )
          });
        }
      }
    );
  };

  const submitSection = (
    <>
      {selectedPlaidAccount && plaidPublicToken ? (
        <WSButton
          fullWidth
          icon="lock"
          mt="2XL"
          loading={
            createAccountOnSubmitMeta.isLoading ||
            updateAccountMeta.isLoading ||
            payClientInvoiceMeta.isLoading
          }
          onClick={submitWrapper(() => {
            createAccountOnSubmit(
              {
                publicToken: plaidPublicToken,
                status: AccountStatus.Pending
              },
              {
                onSuccess: accounts => {
                  const filteredAccounts = (
                    Array.isArray(accounts) ? accounts : [accounts]
                  ).filter(
                    (a: IAccount) =>
                      a.type === AccountType.Depository &&
                      a.canBeUsedFor?.payments
                  );

                  const matchingAccount = filteredAccounts.find(
                    account =>
                      account.externalIds?.plaidAccountId ===
                      selectedPlaidAccount.accountId
                  );

                  if (matchingAccount) {
                    updateAccount(
                      {
                        accountId: matchingAccount.accountId,
                        status: AccountStatus.Active,
                        usedFor: {
                          payments: true
                        }
                      },
                      {
                        onSuccess: () => {
                          payClientInvoice(
                            {
                              accountId: matchingAccount.accountId
                            },
                            {
                              onSuccess: () => {
                                if (
                                  autopay === "enable" &&
                                  clientInvoice.invoiceTemplateId
                                ) {
                                  enableAutopay({
                                    accountId: matchingAccount.accountId,
                                    invoiceTemplateId:
                                      clientInvoice.invoiceTemplateId,
                                    payeeId: clientInvoice.memberClient.memberId
                                  });
                                }
                                history.push(`/payment/${invoiceId}/success`, {
                                  account: matchingAccount
                                });
                              }
                            }
                          );
                        }
                      }
                    );
                  }
                }
              }
            );
          })}
          name="pay"
        >
          Confirm payment
        </WSButton>
      ) : selectedAccountId ? (
        <PlaidReAuth
          key={`reauth-${selectedAccountId}`}
          accountId={selectedAccountId}
          onReAuth={handleSubmitWithExistingAccount}
        >
          {({ onOpen, status }) => {
            return (
              <WSButton
                fullWidth
                icon="lock"
                mt="2XL"
                loading={payClientInvoiceMeta.isLoading}
                onClick={submitWrapper(
                  status ? onOpen : handleSubmitWithExistingAccount
                )}
                name="pay"
              >
                Confirm payment
              </WSButton>
            );
          }}
        </PlaidReAuth>
      ) : (
        <WSButton
          fullWidth
          icon="lock"
          mt="2XL"
          onClick={submitWrapper(() => {
            plaidHandler.open();
          })}
          name="pay"
        >
          Confirm payment
        </WSButton>
      )}

      {userId && clientInvoice.memberClient.memberId === userId && (
        <WSText.ParagraphSm mt="XL" color="gray500">
          You are the link owner. This is what your customers will see.
        </WSText.ParagraphSm>
      )}

      {canNotBePaid && (
        <WSText.ParagraphSm color="garnet" mt="XL">
          {clientInvoice.memberCompany ||
            getClientInvoiceMemberName(clientInvoice)}{" "}
          does not currently accept digital payments. Please contact{" "}
          <a href="mailto:support@wingspan.app">support@wingspan.app</a> for
          more details.
        </WSText.ParagraphSm>
      )}

      {tryingToPayOwnInvoice && (
        <WSText.ParagraphSm color="garnet" mt="XL">
          You cannot pay your own invoices
        </WSText.ParagraphSm>
      )}
    </>
  );

  return (
    <>
      <WSModalOld
        name={PLAID_LOGIN_REQUIRED_MODAL}
        size="S"
        title="Reconnect account"
      >
        {({ accountId }) => (
          <>
            <WSText mb="XL">We need to reconnect your bank account</WSText>

            <PlaidReAuth
              key={`reauth-${accountId}`}
              accountId={accountId}
              onReAuth={handleSubmitWithExistingAccount}
            >
              {({ onOpen, status }) => {
                return (
                  <>
                    {status}
                    <WSButton
                      fullWidth
                      mt="XL"
                      loading={payClientInvoiceMeta.isLoading}
                      onClick={submitWrapper(
                        status ? onOpen : handleSubmitWithExistingAccount
                      )}
                      name="pay"
                    >
                      Reconnect and pay
                    </WSButton>
                  </>
                );
              }}
            </PlaidReAuth>
          </>
        )}
      </WSModalOld>
      <ACHErrorModal
        onCreditCard={() => {
          history.push(`/payment/${invoiceId}/3/creditCard`);
        }}
        onManualACH={() => {
          history.push(`/payment/add-bank-account-manually`, {
            backPath: `/payment/${invoiceId}/3/ach`
          });
        }}
        clientInvoice={clientInvoice}
      />
      {drawerVisible && (
        <ClientInvoiceDetailsDrawer
          invoiceId={invoiceId}
          onClose={() => {
            setDrawerVisible(false);
          }}
        />
      )}
      <WSText.ParagraphSm>
        {getPaymentStepText(clientInvoice, "payment")}
      </WSText.ParagraphSm>
      <WSText.Heading4 mb="2XL">Review & pay</WSText.Heading4>
      {selectedPlaidAccount ? (
        <Card title="Payment account" mb="M">
          <BankAccountPreview
            institutionId={selectedPlaidAccount.institutionId}
            name={selectedPlaidAccount.name}
            mask={selectedPlaidAccount.mask}
            mb="M"
          />
        </Card>
      ) : filteredAccounts.length > 0 ? (
        <Card
          title="Payment account"
          mb="M"
          action={{
            icon: "plus-circle",
            children: "Add",
            onClick: () => {
              plaidHandler.open();
            },
            kind: "Link"
          }}
        >
          <BankAccountSelect
            value={selectedAccountId}
            accounts={filteredAccounts}
            onChange={setSelectedAccountId}
            allowRemove
          />
        </Card>
      ) : (
        <Card title="Payment account" mb="M">
          <WSButton.Link
            icon="plus-circle"
            onClick={() => {
              plaidHandler.open();
            }}
          >
            Add bank account
          </WSButton.Link>
        </Card>
      )}
      <Card mb="M">
        <WSText.Heading5 mb="XS">Payment timing</WSText.Heading5>
        <WSText mb="XS">Within 5 business days</WSText>
        <WSText.ParagraphSm color="gray500">
          Estimated arrival:{" "}
          {moment(addBusinessDays(new Date(), 5)).format("MMMM D, YYYY")}
        </WSText.ParagraphSm>
      </Card>
      <Card mb={allowAutopay ? "M" : "3XL"}>
        <WSFlexBox justify="space-between" mb="XS">
          <WSText.ParagraphSm>Total</WSText.ParagraphSm>
          <WSFlexBox.CenterY>
            <WSButton.Link
              onClick={() => {
                setDrawerVisible(true);
              }}
            >
              View
            </WSButton.Link>
            {clientInvoice.attachments?.invoicePdf && (
              <>
                <VerticalDivider mx="M" my="XL" />
                <DonwloadPdfButton clientInvoice={clientInvoice} />
              </>
            )}
          </WSFlexBox.CenterY>
        </WSFlexBox>
        <WSText.Heading1 weight="book" mb="2XL">
          {formatMoney(clientInvoice.amount)}
        </WSText.Heading1>
        <InfoRow
          label={store.createdWithPPL ? "From" : "To"}
          value={getClientName(clientInvoice.memberClient)}
          mb="M"
        />
        <InfoRow
          label={store.createdWithPPL ? "To" : "From"}
          value={getRedactedMemberName(clientInvoice.memberClient.member)}
          mb="M"
        />
        {clientInvoice.invoiceNotes && (
          <InfoRow label="Note" value={clientInvoice.invoiceNotes} mb="M" />
        )}
        {!allowAutopay && submitSection}
      </Card>
      {allowAutopay && (
        <AutopayCard
          mb="XL"
          clientInvoice={clientInvoice}
          agreementValue={autopay === "enable"}
          onAgreemenChange={newValue => {
            if (newValue) {
              setAutopay("enable");
            } else {
              setAutopay("disable");
            }
          }}
          submitSection={submitSection}
        />
      )}
    </>
  );
};

const GuestExperience: React.FC = () => {
  const { clientInvoice, setShouldOpenPlaid } = useAchContext();
  const invoiceId = clientInvoice.invoiceId;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const { openModal } = useModalOldContext();

  const [autopay, setAutopay] = useState<"enable" | "disable">("disable");

  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId);

  useEffect(() => {
    if (
      clientInvoiceQuery.isFetched &&
      clientInvoiceQuery.data &&
      getIsAutopayRequired(clientInvoiceQuery.data)
    ) {
      setAutopay("enable");
    }
  }, [clientInvoiceQuery.isFetched]);

  const store = useWSStore();

  const allowAutopay = !!clientInvoice.invoiceTemplateId;

  const openSignIn = useCallback(() => {
    openModal(CLIENT_SIGN_IN_MODAL_KEY, {
      clientEmailToPrefill: clientInvoice.memberClient.emailTo,
      memberId: clientInvoice.memberClient.memberId,
      member: clientInvoice.memberClient.member,
      onSuccess: () => {
        setShouldOpenPlaid(true);
      }
    });
  }, [
    clientInvoice.memberClient.emailTo,
    clientInvoice.memberClient.member,
    clientInvoice.memberClient.memberId,
    openModal
  ]);

  const submitSection = (
    <WSButton fullWidth icon="lock" mt="2XL" onClick={openSignIn} name="pay">
      Confirm payment
    </WSButton>
  );

  return (
    <>
      {drawerVisible && (
        <ClientInvoiceDetailsDrawer
          invoiceId={invoiceId}
          onClose={() => {
            setDrawerVisible(false);
          }}
        />
      )}
      <WSText.ParagraphSm>
        {getPaymentStepText(clientInvoice, "payment")}
      </WSText.ParagraphSm>
      <WSText.Heading4 mb="2XL">Review & pay</WSText.Heading4>
      <Card title="Payment account" mb="M">
        <WSButton.Link
          icon="plus-circle"
          onClick={openSignIn}
          mb="M"
          name="connectBankAccount"
        >
          Connect bank account
        </WSButton.Link>
        <WSCheckboxToggle
          label="Save for 1-click payment"
          name="savePaymentMethod"
          value={false}
          onChange={openSignIn}
        />
      </Card>
      <Card mb="M">
        <WSText.Heading5 mb="XS">Payment timing</WSText.Heading5>
        <WSText mb="XS">Within 5 business days</WSText>
        <WSText.ParagraphSm color="gray500">
          Estimated arrival:{" "}
          {moment(addBusinessDays(new Date(), 5)).format("MMMM D, YYYY")}
        </WSText.ParagraphSm>
      </Card>
      <Card mb={allowAutopay ? "M" : "3XL"}>
        <WSFlexBox justify="space-between" mb="XS">
          <WSText.ParagraphSm>Total</WSText.ParagraphSm>
          <WSFlexBox.CenterY>
            <WSButton.Link
              onClick={() => {
                setDrawerVisible(true);
              }}
            >
              View
            </WSButton.Link>
            {clientInvoice.attachments?.invoicePdf && (
              <>
                <VerticalDivider mx="M" my="XL" />
                <DonwloadPdfButton clientInvoice={clientInvoice} />
              </>
            )}
          </WSFlexBox.CenterY>
        </WSFlexBox>
        <WSText.Heading1 weight="book" mb="2XL">
          {formatMoney(clientInvoice.amount)}
        </WSText.Heading1>
        <InfoRow
          label={store.createdWithPPL ? "From" : "To"}
          value={getClientName(clientInvoice.memberClient)}
          mb="M"
        />
        <InfoRow
          label={store.createdWithPPL ? "To" : "From"}
          value={getRedactedMemberName(clientInvoice.memberClient.member)}
          mb="M"
        />
        {clientInvoice.invoiceNotes && (
          <InfoRow label="Note" value={clientInvoice.invoiceNotes} mb="M" />
        )}
        {!allowAutopay && submitSection}
      </Card>
      {allowAutopay && (
        <AutopayCard
          mb="XL"
          clientInvoice={clientInvoice}
          agreementValue={autopay === "enable"}
          onAgreemenChange={openSignIn}
          submitSection={submitSection}
        />
      )}
    </>
  );
};
