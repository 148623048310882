import {
  toWSMoneyString,
  WSCard,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useQueryInternalAccountBalance } from "../../../query/banking/queries/useQueryInternalAccountBalance";

type Props = {
  internalAccountId: string;
};

export const InternalAccountBalance: React.FC<Props> = props => {
  const queryInternalAccountBalance = useQueryInternalAccountBalance(
    props.internalAccountId
  );

  const availableBalance = queryInternalAccountBalance.data?.available || 0;
  const currentBalance = queryInternalAccountBalance.data?.balance || 0;
  const pendingAmounts = queryInternalAccountBalance.data?.hold || 0;

  return (
    <WSCard
      shimmer={queryInternalAccountBalance.isInitialLoading}
      fullHeight
      header={{
        label: {
          text: "Available balance"
        }
      }}
      divider
    >
      <WSText.Heading1
        weight="book"
        color={availableBalance > 0 ? "gray600" : "gray200"}
        mb="XL"
      >
        {toWSMoneyString(availableBalance)}
      </WSText.Heading1>

      <WSFlexBox.CenterY justify="space-between" mb="M">
        <WSText.ParagraphSm color="gray500">Current balance</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray600">
          {toWSMoneyString(currentBalance)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>

      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="gray500">Pending amounts</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">
          {toWSMoneyString(pendingAmounts)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
    </WSCard>
  );
};
