import {
  toWSDateString,
  WSAlert,
  WSButton,
  WSCard,
  WSDataItem,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSList,
  WSPage,
  WSPageToolbar,
  WSTabs,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  TaxFormDeliveryMethod,
  TaxFormSubmissionStatus,
  TaxFormType
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../../query/payments/queries";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { useXWingspanExpansion } from "../../../../../shared/hooks/useXWingspanExpansion";
import { useAuthorizedScopeGroups } from "../../../../../shared/utils/teamUtils";
import { useGoToPayeeDetails } from "../../../../Payees/utils";
import {
  useGoToTaxForm1099NECPreview,
  useGoToTaxFormEditContractor,
  useGoToTaxFormNewCorrection,
  useTaxFormSubmissionParamsIds
} from "../../../paths";
import { selectorTaxFormIsEditable } from "../../../selectors/selectorTaxFormIsEditable";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { getTaxFormExtendedBySubmission } from "../../../utils/getTaxFormExtendedBySubmission";
import { useBreadcrumbs } from "../useBreadcrumbs";
import { GroupedForms } from "./GroupedForms";
import { HistorySection } from "./HistorySection";
import { MainInfo } from "./MainInfo";
import { useModalBeforeEdit } from "./ModalBeforeEdit";
import { useModalRevertingChanges } from "./ModalRevertingChanges";
import { useModalReviewChanges } from "./ModalReviewChanges";
import { Payables } from "./Payables";
import { RecipientDetails } from "./RecipientDetails";
import { selectorTaxFormIsInPostFilingState } from "../../../selectors/selectorTaxFormIsInPostFilingState";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { downloadFileFromBlob } from "../../../../../utils/files";
import { useModalResendElectronicCopy } from "../../Dashboard/RouteDashboard/useModalResendElectronicCopy";
import { useModalResendMailCopy } from "../../Dashboard/RouteDashboard/useModalResendMailCopy";
import { selectorTaxFormIsResendDeliveryAvailable } from "../../../selectors/selectorTaxFormIsResendDeliveryAvailable";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import useSnackbar from "../../../../../hooks/useSnackbar";
import {
  copyTaxFormSubmissionSecureLink,
  getExpirationDate,
  getIsSecureLinkExpired
} from "../../../utils/secureLink";
import { getExpirationLinkMessage } from "../../../../../shared/utils/getExpirationLinkMessage";
import { getFormFileName } from "../../../utils/formFileName";
import { useModalGenerateSecureLink } from "../../Dashboard/RouteDashboard/useModalGenerateSecureLink";
import { RelatedForms } from "./RelatedForms";
import { useGoToReviewContractorCorrection } from "../../../../TaxCorrections/utils/useGoToReviewContractorCorrection";

export const RouteTaxFormDetails: React.FC<RouteComponentProps> = () => {
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const queryFeatureFlags = useFeatureFlags();

  const queryPayerTaxForm = usePayerTaxForm(taxFormId);
  const isOrganizationExist = useXWingspanExpansion();
  const beforeEditModal = useModalBeforeEdit();
  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const reviewRedactedData = useModalReviewChanges();
  const revertChanges = useModalRevertingChanges();
  const { hasAdminScope, hasCollaboratorsScope } = useAuthorizedScopeGroups();
  const goToEdit = useGoToTaxFormEditContractor();
  const goToPreview = useGoToTaxForm1099NECPreview();
  const goToPayeeDetails = useGoToPayeeDetails();
  const goToNewCorrection = useGoToTaxFormNewCorrection();
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();
  const generateSecureLinkModal = useModalGenerateSecureLink();
  const goToReviewContractorCorrection = useGoToReviewContractorCorrection();

  const { success } = useSnackbar();

  const modalResendElectronicCopy = useModalResendElectronicCopy();
  const modalResendMailCopy = useModalResendMailCopy();

  const breadcrumbs = useBreadcrumbs(queryPayerTaxForm.data);

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryPayerTaxForm
        }}
      >
        {({ queryPayerTaxFormData }) => {
          const taxForm = getTaxFormExtendedBySubmission(
            queryPayerTaxFormData,
            submissionId
          );

          const isEditable = selectorTaxFormIsEditable(taxForm);
          const isPostFiling = selectorTaxFormIsInPostFilingState(taxForm);

          const submission = taxForm.submissions?.find(
            submission => submission.taxFormSubmissionId === submissionId
          )!;

          const isCurrentSubmission =
            taxForm.currentSubmissionId === submissionId;

          const name = selectorTaxFormRecipientName(taxForm);
          const grouped = !!taxForm.groupSize;

          const openRevertModal = async () => {
            const startReverting = await reviewRedactedData.open({
              taxForm
            });

            if (startReverting) {
              await revertChanges.open({
                taxForm
              });
            }
          };

          const isSecureLinkExpired = submission.secureSharing?.createdAt
            ? getIsSecureLinkExpired(submission.secureSharing?.createdAt)
            : false;

          const isSecureLinkReadyToGenerate =
            isPostFiling &&
            (submission.secureSharing?.createdAt ? isSecureLinkExpired : true);

          const isSecureLinkReadyToCopy =
            !isSecureLinkExpired && !!submission.secureSharing?.token;

          return (
            <>
              <WSPageToolbar
                breadcrumbs={breadcrumbs}
                title={name}
                titleAfter={
                  <WSList.Horizontal gap="M">
                    {grouped ? (
                      <WSFlexBox.CenterY>
                        <WSIcon block size="S" name="group" color="gray400" />
                        <WSText.ParagraphSm mx="M" color="gray400">
                          Grouped forms
                        </WSText.ParagraphSm>
                      </WSFlexBox.CenterY>
                    ) : null}
                    <WSButton.Link
                      size="S"
                      onClick={() => {
                        const payerId =
                          isOrganizationExist && taxForm.payer
                            ? taxForm.clientId
                            : undefined;
                        goToPayeeDetails(taxForm.memberId, payerId);
                      }}
                    >
                      View contractor
                    </WSButton.Link>
                  </WSList.Horizontal>
                }
                actions={[
                  {
                    label: "View Form 1099-NEC",
                    icon: "view",
                    buttonKind: "Secondary",
                    onClick() {
                      goToPreview(taxFormId, submissionId);
                    },
                    hidden: isPostFiling
                  },
                  {
                    label: "Download Form 1099-NEC payer copy",
                    icon: "download",
                    buttonKind: "Secondary",
                    async onAsyncClick() {
                      await downloadDocument(
                        {
                          taxFormId,
                          submissionId,
                          viewer: TaxFormViewer.payer
                        },
                        {
                          throwOnError: true,
                          onSuccess(pdf) {
                            const formPayerFileName = getFormFileName({
                              viewer: TaxFormViewer.payer,
                              year: submission.year || 2024,
                              payerName:
                                submission.payerData.disregardedEntityName ??
                                submission.payerData.name,
                              payeeW9: submission.data?.w9Info
                            });

                            downloadFileFromBlob(pdf, formPayerFileName);
                          }
                        }
                      );
                    },
                    visible: isPostFiling
                  },
                  {
                    label: "Download Form 1099-NEC payee copy",
                    icon: "download",
                    async onAsyncClick() {
                      await downloadDocument(
                        {
                          taxFormId,
                          submissionId,
                          viewer: TaxFormViewer.payee
                        },
                        {
                          throwOnError: true,
                          onSuccess(pdf) {
                            const formPayeeFileName = getFormFileName({
                              viewer: TaxFormViewer.payee,
                              year: submission.year || 2024,
                              payerName:
                                submission.payerData.disregardedEntityName ??
                                submission.payerData.name,
                              payeeW9: submission.data?.w9Info
                            });

                            downloadFileFromBlob(pdf, formPayeeFileName);
                          }
                        }
                      );
                    },
                    visible: isPostFiling
                  },
                  {
                    label: "Review correction request",
                    buttonKind: "Tertiary",
                    icon: "clipboard-check",
                    onClick() {
                      goToReviewContractorCorrection(
                        submission.status,
                        taxFormId,
                        submissionId
                      );
                    },
                    visible:
                      queryFeatureFlags.data
                        ?.taxDocumentsSeason2024PayerCorrections &&
                      !!taxForm.pendingCorrectionId &&
                      isCurrentSubmission
                  },
                  {
                    label: "Start a new correction",
                    icon: "clipboard",
                    onClick() {
                      goToNewCorrection(taxFormId, submissionId);
                    },
                    disabled:
                      !!taxForm.pendingCorrectionId ||
                      ![
                        TaxFormSubmissionStatus.Corrected,
                        TaxFormSubmissionStatus.AcceptedByIrs
                      ].includes(submission.status),
                    visible:
                      queryFeatureFlags.data
                        ?.taxDocumentsSeason2024PayerCorrections &&
                      isCurrentSubmission
                  },

                  {
                    label: "Generate secure share link",
                    helpText: `Secure links expire ${toWSDateString(
                      getExpirationDate(new Date()),
                      "monthDate"
                    )}`,
                    icon: "link",
                    onClick() {
                      generateSecureLinkModal.open({
                        taxFormId,
                        submissionId
                      });
                    },
                    visible: isSecureLinkReadyToGenerate
                  },
                  {
                    label: "Copy secure share link",
                    icon: "copy",
                    helpText: getExpirationLinkMessage(
                      getExpirationDate(submission.secureSharing?.createdAt!)
                    ),
                    onClick() {
                      copyTaxFormSubmissionSecureLink(
                        submission.secureSharing?.token!
                      );
                      success("Secure link copied to clipboard");
                    },
                    visible: isSecureLinkReadyToCopy
                  },
                  {
                    label: "Re-send electronic copy",
                    icon: "send",
                    onClick() {
                      modalResendElectronicCopy.open({
                        taxForm
                      });
                    },
                    visible: selectorTaxFormIsResendDeliveryAvailable(
                      taxForm.status,
                      submission.status,
                      TaxFormDeliveryMethod.Electronic,
                      submissionId!,
                      taxForm.eventHistory
                    )
                  },
                  {
                    label: "Re-mail tax forms",
                    icon: "send-mail",
                    onClick() {
                      modalResendMailCopy.open({
                        taxForm
                      });
                    },
                    visible: selectorTaxFormIsResendDeliveryAvailable(
                      taxForm.status,
                      submission.status,
                      TaxFormDeliveryMethod.Mail,
                      submissionId!,
                      taxForm.eventHistory
                    )
                  }
                ]}
              />

              {!!taxForm.pendingCorrection ? (
                // this flag enablement means payer can respond to corrections
                queryFeatureFlags.data
                  ?.taxDocumentsSeason2024PayerCorrections ? (
                  <WSAlert
                    icon="alert"
                    theme="warning"
                    mb="M"
                    title="Correction requested by recipient"
                  >
                    The recipient has requested corrections to their Form
                    1099-NEC. Review the request, verify the corrections, and
                    determine whether to accept the changes and submit to the
                    IRS or reject the changes.
                    {/*<WSButton.Link
                            size="S"
                            mt="S"
                            onClick={() => {
                              if (taxForm.status === TaxFormStatus.AcceptedByIrs) {
                                history.push(
                                  `/member/1099-filing/details/${taxFormId}/review-correction-request`
                                );
                              } else {
                                history.push(
                                  `/member/1099-filing/details/${taxFormId}/view-correction`
                                );
                              }
                            }}
                            rightIcon="chevron-right"
                          >
                            Review correction request
                          </WSButton.Link>
                          */}
                  </WSAlert>
                ) : (
                  <WSAlert
                    icon="alert"
                    theme="warning"
                    mb="M"
                    title="Preview correction requested by contractor"
                  >
                    The contractor has requested corrections to their Form
                    1099-NEC. To ensure accurate correction requests are being
                    made, there is no further action to take at the moment.
                    <br />
                    <br />
                    <b>Starting January 24th</b> - you can determine whether to
                    accept the changes and submit to the IRS or reject the
                    changes. This allows time for other potential corrections to
                    surface and sufficient time for the recipients to review and
                    verify their requests in case they were made erroneously.
                  </WSAlert>
                )
              ) : null}

              <WSGrid gutter={{ xs: "L", s: "XL", m: "2XL" }}>
                <WSGrid.Item span={{ s: "12", m: "8" }}>
                  <WSList gap="2XL">
                    <MainInfo
                      taxForm={taxForm}
                      submission={submission}
                      openRevertModal={openRevertModal}
                    />
                    <WSTabs
                      mb="2XL"
                      tabs={[
                        {
                          label: "Summary",
                          render: () => (
                            <RecipientDetails
                              taxForm={taxForm}
                              onEdit={
                                (hasAdminScope || hasCollaboratorsScope) &&
                                isEditable
                                  ? async () => {
                                      if (taxForm.recipientSyncEnabled) {
                                        const response =
                                          await beforeEditModal.open();
                                        if (!response) {
                                          return;
                                        }
                                      }

                                      goToEdit(taxFormId, submissionId);
                                    }
                                  : undefined
                              }
                              onRevert={openRevertModal}
                            />
                          )
                        },
                        ...(taxForm.status !== TaxFormStatus.Pending
                          ? [
                              {
                                label: "Payments",
                                render: () => <Payables taxForm={taxForm} />
                              }
                            ]
                          : [])
                      ]}
                    />
                  </WSList>
                </WSGrid.Item>

                <WSGrid.Item span={{ s: "12", m: "4" }}>
                  <WSList gap="2XL">
                    <RelatedForms
                      taxForm={taxForm}
                      submissionId={submissionId!}
                    />
                    <GroupedForms
                      taxForm={taxForm}
                      submissionId={submissionId!}
                    />
                    <HistorySection taxForm={taxForm} />
                    <WSCard header="Form & calculation settings" divider>
                      <WSQueries queries={{ queryPayrollSettings }}>
                        {({ queryPayrollSettingsData: payrollSettings }) => (
                          <WSList gap="XL">
                            <WSDataItem
                              label="Off-platform payments"
                              value={
                                payrollSettings.calculationSettings1099
                                  ?.offPlatformPayments
                              }
                            />
                            <WSDataItem
                              label="Card processing fees"
                              value={
                                payrollSettings.calculationSettings1099
                                  ?.cardProcessingFees
                              }
                            />
                            <WSDataItem
                              label="Reimbursable expenses"
                              value={
                                payrollSettings.calculationSettings1099
                                  ?.reimbursableExpenses
                              }
                            />
                            <WSDataItem
                              label="Form type"
                              value={
                                taxForm.type === TaxFormType.Form1099Nec
                                  ? "1099-NEC"
                                  : "-"
                              }
                            />
                          </WSList>
                        )}
                      </WSQueries>
                    </WSCard>
                  </WSList>
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
