import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import {
  IPaymentCard,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { useWSMutation } from "@ws-react-query";
import { useCallback, useMemo } from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useMutationCompleteOneTimePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationCompleteOneTimePaymentCardCreate";
import { useMutationCompletePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationCompletePaymentCardCreate";
import { useMutationInitiateOneTimePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationInitiateOneTimePaymentCardCreate";
import { useMutationInitiatePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationInitiatePaymentCardCreate";
import { paymentsService } from "../../services/payments";
import { WSServiceError } from "../../utils/serviceHelper";
import { footprintAppearanceConfig } from "../constants/footprint";

type Config = {
  clientId?: string;
  oneTime?: boolean;
};

type OpenConfig = {
  onCancel?: () => void;
  onSuccess?: (paymentCard: IPaymentCard) => void;
  variant?: "modal" | "inline";
  containerId?: string;
  setAsInstantPayoutMethod?: boolean;
};

export const usePaymentCardForm = (config?: Config) => {
  const [initiateCreate, initiateCreateMeta] =
    useMutationInitiatePaymentCardCreate();
  const [completeCreate, completeCreateMeta] =
    useMutationCompletePaymentCardCreate();
  const [initiateOneTimeCreate, initiateOneTimeCreateMeta] =
    useMutationInitiateOneTimePaymentCardCreate();
  const [completeOneTimeCreate, completeOneTimeCreateMeta] =
    useMutationCompleteOneTimePaymentCardCreate();

  const userId = useUserId();
  const oneTimeUserId = useMemo(
    () => (config?.oneTime ? config.clientId : undefined),
    [config?.oneTime, config?.clientId]
  );

  const initiate = useCallback(async () => {
    if (oneTimeUserId) {
      return initiateOneTimeCreate({
        userId: oneTimeUserId
      });
    }

    return initiateCreate();
  }, [initiateCreate, initiateOneTimeCreate, oneTimeUserId]);

  const [setDefaultInstantPayoutMethod, setDefaultInstantPayoutMethodMeta] =
    useWSMutation<void, WSServiceError, { paymentCardId: string }>(
      async ({ paymentCardId }) => {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Instant,
          instant: {
            destinationId: paymentCardId,
            destinationType: PayoutDestinationType.PaymentCard
          }
        });
      }
    );

  const complete = useCallback(
    async (paymentCardId: string, config?: OpenConfig) => {
      if (oneTimeUserId) {
        return completeOneTimeCreate(
          {
            paymentCardId,
            userId: oneTimeUserId
          },
          { onSuccess: config?.onSuccess }
        );
      }

      return completeCreate(
        {
          paymentCardId,
          saveForFutureUse: true
        },
        {
          onSuccess: paymentCard => {
            if (config?.setAsInstantPayoutMethod) {
              setDefaultInstantPayoutMethod(
                {
                  paymentCardId
                },
                {
                  onSuccess: () => {
                    config?.onSuccess?.(paymentCard);
                  }
                }
              );
            } else {
              config?.onSuccess?.(paymentCard);
            }
          }
        }
      );
    },
    [
      completeCreate,
      completeOneTimeCreate,
      oneTimeUserId,
      setDefaultInstantPayoutMethod
    ]
  );

  const open = useCallback(async (config?: OpenConfig) => {
    const paymentCardCreateInitiate = await initiate();

    if (!paymentCardCreateInitiate) return;

    const component = footprint.init({
      appearance: footprintAppearanceConfig,
      authToken: paymentCardCreateInitiate.footprintToken,
      kind: FootprintComponentKind.Form,
      variant: config?.variant || "modal",
      containerId: config?.containerId || "root",
      onCancel: config?.onCancel,
      onClose: config?.onCancel,
      options: {
        hideFootprintLogo: true
      },
      onComplete: () => {
        complete(paymentCardCreateInitiate.paymentCardId, config);

        component.destroy();
      }
    });

    component.render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useMemo(
    () =>
      initiateCreateMeta.isLoading ||
      completeCreateMeta.isLoading ||
      initiateOneTimeCreateMeta.isLoading ||
      completeOneTimeCreateMeta.isLoading ||
      setDefaultInstantPayoutMethodMeta.isLoading,
    [
      initiateCreateMeta.isLoading,
      completeCreateMeta.isLoading,
      initiateOneTimeCreateMeta.isLoading,
      completeOneTimeCreateMeta.isLoading,
      setDefaultInstantPayoutMethodMeta.isLoading
    ]
  );

  return {
    open,
    isLoading
  };
};
