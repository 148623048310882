import {
  WSButton,
  WSCard,
  WSElement,
  WSFlexBox,
  WSList,
  WSLoader,
  WSPage,
  WSPageToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IRequirementResponse,
  RequirementEmbeddedStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import {
  ISignedDocumentResponse,
  SignerRole
} from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import React, { useMemo } from "react";
import { WSQueryConfig } from "@ws-react-query";
import { useCountersignDocument } from "../../../query/files/mutations";
import { useWSQuery } from "../../../query/helpers";
import { useQueryAllPayeeRows } from "../../../query/search/payee/queries/useQueryAllPayeeRows";
import {
  IPayeeRow,
  ISearchablePayerPayeeEngagement
} from "../../../services/search";
import { getSignedDocument } from "../../../services/signedDocuments";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";

const QUERY_SIGNED_DOCUMENTS_BY_ID = "QUERY_SIGNED_DOCUMENTS_BY_ID";

function useQueryDocumentsToSign(
  documentIds: string[],
  config?: WSQueryConfig<ISignedDocumentResponse[], WSServiceError>
) {
  return useWSQuery<ISignedDocumentResponse[], WSServiceError>(
    [QUERY_SIGNED_DOCUMENTS_BY_ID, documentIds],
    async () => {
      const actions = documentIds.map(
        documentId => () => getSignedDocument(documentId)
      );

      const signedDocuments = await concurrentActions(actions);

      return signedDocuments;
    },
    config
  );
}

function getPayeeEngagements(payees: IPayeeRow[]) {
  return payees.reduce<ISearchablePayerPayeeEngagement[]>(
    (acc, payee) => [...acc, ...payee.engagements],
    []
  );
}

// Filter by type and status
function getEngagementRequirements(
  engagements: ISearchablePayerPayeeEngagement[]
) {
  return engagements
    .reduce<IRequirementResponse[]>(
      (acc, engagement) => [...acc, ...engagement.requirements],
      []
    )
    .filter(
      requirement =>
        requirement.requirementType === RequirementType.Signature &&
        requirement.status === RequirementEmbeddedStatus.Incomplete
    );
}

function getHasPayeeSignature(document: ISignedDocumentResponse) {
  return document.signatures.some(signature => {
    return signature.signerRole === SignerRole.Payee && signature.signedAt;
  });
}

function getRequiresPayerSignature(document: ISignedDocumentResponse) {
  return document.signatures.some(signature => {
    const isPayerSignatureRequired = signature.signerRole === SignerRole.Payer;
    const hasSignature = signature.signedAt;

    return isPayerSignatureRequired && !hasSignature;
  });
}

function isDocumentCountersignReady(document: ISignedDocumentResponse) {
  const hasPayeeSignature = getHasPayeeSignature(document);
  const requiresPayerSignature = getRequiresPayerSignature(document);
  return hasPayeeSignature && requiresPayerSignature;
}

function getData(
  payees: IPayeeRow[],
  engagements: ISearchablePayerPayeeEngagement[],
  requirements: IRequirementResponse[],
  documents: ISignedDocumentResponse[]
) {
  const data: {
    document: ISignedDocumentResponse;
    payee: IPayeeRow;
    engagement: ISearchablePayerPayeeEngagement;
  }[] = [];

  requirements.forEach(requirement => {
    const document = documents.find(
      document => document.signedDocumentId === requirement.dataSourceId
    );
    if (!document) return;

    const countersignReady = isDocumentCountersignReady(document);
    if (!countersignReady) return;

    const payee = payees.find(payee => payee.payeeId === document.memberId);
    const engagement = engagements.find(engagement =>
      engagement.requirements.some(
        r => r.requirementId === requirement.requirementId
      )
    );

    if (!payee || !engagement) return;

    data.push({
      document,
      payee,
      engagement
    });
  });

  return data;
}

export const RouteRequirements: React.FC = () => {
  const queryPayeeRows = useQueryAllPayeeRows({
    filter: {
      "engagements.requirements.requirementType": RequirementType.Signature,
      "engagements.requirements.status": RequirementEmbeddedStatus.Incomplete
    }
  });

  const payees = useMemo(
    () => queryPayeeRows.data || [],
    [queryPayeeRows.data]
  );
  const engagements = useMemo(() => getPayeeEngagements(payees), [payees]);
  const requirements = useMemo(
    () => getEngagementRequirements(engagements),
    [engagements]
  );

  const documentIds = useMemo(() => {
    return requirements
      .filter(
        requirement => requirement.requirementType === RequirementType.Signature
      )
      .map(requirement => requirement.dataSourceId);
  }, [requirements]);

  const queryDocuments = useQueryDocumentsToSign(documentIds);

  const documents = useMemo(
    () => queryDocuments.data || [],
    [queryDocuments.data]
  );

  const [countersign] = useCountersignDocument({
    dependencies: [QUERY_SIGNED_DOCUMENTS_BY_ID]
  });

  const items = useMemo(
    () =>
      getData(payees, engagements, requirements, documents).map(item => {
        const name = wsName({
          member: item.payee.member,
          user: item.payee.user,
          payerOwnedData: item.payee.payerOwnedData
        }).getResolvedName();

        return {
          title: item.document?.title || "",
          description: `Your contractor ${name} already signed this document`,
          actionTitle: "Countersign",
          onClick: async () => {
            await countersign({
              documentId: item.document.signedDocumentId,
              payeeId: item.payee.payeeId,
              payerPayeeEngagementId: item.engagement.payerPayeeEngagementId
            });
          }
        };
      }),
    [countersign, documents, engagements, payees, requirements]
  );

  if (queryPayeeRows.isInitialLoading || queryDocuments.isInitialLoading) {
    return (
      <WSPage>
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar title={`E-signatures required (${items.length})`} />

      <WSList gap="L">
        {items.length === 0 ? (
          <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
            No documents to sign
          </WSText.ParagraphSm>
        ) : (
          items.map(({ title, onClick, description, actionTitle }) => {
            return (
              <WSCard key={title}>
                <WSFlexBox
                  wrap="nowrap"
                  alignItems="center"
                  justify="space-between"
                >
                  <WSElement>
                    <WSText weight="medium">{title}</WSText>
                    <WSText.ParagraphSm color="gray600">
                      {description}
                    </WSText.ParagraphSm>
                  </WSElement>

                  <WSButton.Secondary onAsyncClick={onClick}>
                    {actionTitle}
                  </WSButton.Secondary>
                </WSFlexBox>
              </WSCard>
            );
          })
        )}
      </WSList>
    </WSPage>
  );
};
