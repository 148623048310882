import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSFormOld,
  WSMessageBox,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUpdateActivity } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";

export const MODAL_CONSENT_EFTPS = "MODAL_CONSENT_EFTPS";

export const TaxesModalConsentEFTPS: React.FC = () => {
  const { closeModal } = useModalOldContext();
  const memberId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(memberId);

  return (
    <WSModalOld name={MODAL_CONSENT_EFTPS} size="M">
      {({ onSuccess, onFail }) => (
        <>
          <WSText.Heading5 mb="M">
            Terms of Service for EFTPS Enrollment
          </WSText.Heading5>
          <WSText weight="medium" my="XL">
            When you make a Federal Tax Payment with Wingspan, you agree that
            Wingspan can create an electronic payment account on your behalf.
          </WSText>

          <WSText my="XL">
            In order for Wingspan to submit a Quarterly Tax Payment to the IRS,
            you must be enrolled with EFTPS (Electronic Federal Tax Payment
            System). Enrollment with EFTPS is completely free, and enables you
            to look up a payment on the EFTPS website.
          </WSText>

          <WSText my="XL">
            Once enrolled, you will receive a letter from EFTPS in the mail
            within 2 weeks. This letter will provide an Inquiry PIN that you can
            use to create an account with and log into the{" "}
            <a
              href="https://www.eftps.gov/eftps/"
              target="_blank"
              rel="noreferrer"
            >
              EFTPS website
            </a>{" "}
            to view your payment history.
          </WSText>
          <WSText my="XL">
            I authorize Wingspan Networks, Inc. dba "Wingspan" to enroll me with
            EFTPS and submit estimated tax payments to the IRS and/or state tax
            authorities on my behalf. I certify under penalty of perjury that
            the personal and bank information provided to this service is
            accurate and my own. I understand that payments submitted through
            this service to the federal and/or state tax authorities are
            finalized and cannot be reversed. I acknowledge that the specific
            use is not recorded on the Centralized Authorization File (CAF). I
            authorize Wingspan to receive and/or inspect estimated tax payments
            and tax information related to Form 1040 and 1040ES for the years
            2023-2027 for the purpose of submitting estimated tax payments. My
            agreement to these terms on today’s date represents my digital
            signature for the written equivalent of Form 8821 and authorization
            to file Form 8821 on my behalf with the information herein.
          </WSText>
          <WSFormOld
            onSubmit={async () => {
              await updateActivity({
                events: {
                  eftpsEnrolledAt: new Date()
                }
              });
              closeModal(MODAL_CONSENT_EFTPS);
              onSuccess();
            }}
          >
            {updateActivityMeta.isError ? (
              <WSMessageBox.Error>Something went wrong</WSMessageBox.Error>
            ) : null}

            <WSButtons format="modal">
              <WSButton.Primary
                size="M"
                type="submit"
                loading={updateActivityMeta.isLoading}
                data-testid="agree"
              >
                I agree
              </WSButton.Primary>
              <WSButton.Secondary
                size="M"
                onClick={() => {
                  closeModal(MODAL_CONSENT_EFTPS);
                  onFail();
                }}
              >
                Cancel
              </WSButton.Secondary>
            </WSButtons>
          </WSFormOld>
        </>
      )}
    </WSModalOld>
  );
};
