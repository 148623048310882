import React, { useState } from "react";
import {
  useModalOldContext,
  WSButton,
  WSContainer,
  WSCentered,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useUpdateAccounts } from "../../../query/users/mutations";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import {
  AccountsCheckList,
  AccountsCheckLoading
} from "../../components/AccountsCheckList/AccountsCheckList";
import {
  BOOKKEEPING_NO_ACCOUNTS_SELECTED,
  NoAccountsSelected
} from "../modals/NoAccountsSelected";
import { useAccounts } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { updateForBookkeeping } from "./BookkeepingSetUpPlaidInstitutionSync";

export const BookkeepingSetUpAccountsSync: React.FC<
  RouteComponentProps<{
    institutionId: string;
  }>
> = ({ match }) => {
  const history = useHistory();

  const { openModal } = useModalOldContext();

  const institutionId = match.params.institutionId;

  const { updateAccounts, updateAccountsLoading } = useUpdateAccounts();

  const [accounts, setAccounts] = useState<IAccount[]>();

  const qAccounts = useAccounts();

  useBrowserPageTitle("Bookkeeping - Setup");

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 2} noBack>
      <NoAccountsSelected />
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "6" }}>
          <WSText.Heading4>Sync ongoing business transactions</WSText.Heading4>

          <WSPanel mt="2XL">
            <WSText.Heading5 mt="XL">
              Which accounts contain business transactions?
            </WSText.Heading5>

            <WSText mt="XL">
              We make reviewing easier, so connect all applicable accounts.
            </WSText>

            <WSQueries
              queries={{ qAccounts }}
              renderLoader={() => <AccountsCheckLoading />}
            >
              {({ qAccounts }) => {
                const currentAccounts =
                  accounts ??
                  qAccounts.data.filter(
                    a =>
                      a.canBeUsedFor?.bookkeeping &&
                      a.institutionId === institutionId &&
                      a.status !== AccountStatus.Inactive
                  );

                const onSubmit = async () => {
                  await updateForBookkeeping(currentAccounts, updateAccounts);

                  history.push(
                    `/member/bookkeeping/setup/accounts/${institutionId}/business-only`
                  );
                };

                return (
                  <>
                    <AccountsCheckList
                      data-testId="syncAccountsChecklist"
                      mt="XL"
                      accounts={currentAccounts}
                      onToggle={account => ({
                        ...account,
                        usedFor: {
                          ...account.usedFor,
                          bookkeeping: !account.usedFor?.bookkeeping
                        }
                      })}
                      onChange={setAccounts}
                      getValue={account => !!account.usedFor?.bookkeeping}
                    />
                    <WSButton.Primary
                      mt="2XL"
                      fullWidth
                      data-testId="continue"
                      loading={updateAccountsLoading}
                      onClick={async () => {
                        if (
                          currentAccounts.some(
                            account => !!account.usedFor?.bookkeeping
                          )
                        ) {
                          await onSubmit();
                        } else {
                          openModal(BOOKKEEPING_NO_ACCOUNTS_SELECTED, {
                            onContinue: onSubmit
                          });
                        }
                      }}
                    >
                      Continue
                    </WSButton.Primary>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
