import {
  WSAlert,
  WSButton,
  WSCard,
  WSDataItem,
  WSEmptyState,
  WSFlexBox,
  WSIcon,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import queryString from "qs";
import {
  BulkStatus,
  IBulkCollaboratorBatch
} from "@wingspanhq/payments/dist/interfaces";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkResource } from "../../types";
import { useModalContractorColumnsGuide } from "../../components/ModalContractorColumnsGuide";

import {
  isBatchFullyImported,
  isBatchPartiallyImported,
  isFileUploaded,
  isUploadFinished,
  isUploadInProgress
} from "../../utils/bulkBatchUtils";
import { BulkBatchUploadProgress } from "../../components/BulkBatchUploadProgress";
import { BulkBatchImportProgress } from "../../components/BulkBatchImportProgress";
import { ICollaboratorBatchImportSummary } from "../../services/types";
import { PayeeRowsQuery } from "../../../../services/search";
import { useHistory } from "react-router-dom";
import { pluralize } from "../../../../shared/utils/pluralize";
import { useBulkBatchStatus } from "../../hooks/useBulkBatchStatus";
import { useBulkBatchStatusActions } from "../../hooks/useBulkBatchActions";
import { useFailedBatchItems } from "../../hooks/useFailedBatchItems";

type Props = {
  bulkBatch: IBulkCollaboratorBatch;
  bulkBatchSummary: ICollaboratorBatchImportSummary;
  downloadExampleCSV: () => void;
  downloadErrorsCSV: () => void;
};

export const ContractorBatchStatusWidget: React.FC<Props> = ({
  bulkBatch,
  bulkBatchSummary,
  downloadExampleCSV,
  downloadErrorsCSV
}) => {
  const history = useHistory();
  const contractorColumnsGuideModal = useModalContractorColumnsGuide();
  const { failedBatchItems, queryAllFailedBulkBatchItems } =
    useFailedBatchItems(
      bulkBatch.bulkCollaboratorBatchId,
      BulkResource.Collaborator
    );
  const isBatchProcessing = [
    BulkStatus.Pending,
    BulkStatus.Processing
  ].includes(bulkBatch.status);
  const isEmptyBatch = bulkBatchSummary.listSize === 0;

  const itemPluralizeOptions = {
    one: "item",
    other: "items"
  };

  const renderStats = (showSeeAll: boolean = true) => {
    return (
      <WSList gap="XS">
        <WSFlexBox.CenterY justify="space-between">
          <WSText.Paragraph weight="medium" color="gray600">
            Contractors
          </WSText.Paragraph>
          {showSeeAll && (
            <WSButton.Link
              rightIcon="chevron-right"
              onClick={() => {
                history.push(
                  `/member/payees?${queryString.stringify({
                    bulkBatchId: [bulkBatch.bulkCollaboratorBatchId]
                  } as PayeeRowsQuery)}`
                );
              }}
            >
              See all
            </WSButton.Link>
          )}
        </WSFlexBox.CenterY>
        <WSDataItem
          inline
          label="Total contractors"
          value={bulkBatchSummary.collaboratorsCount}
        />
      </WSList>
    );
  };

  const renderBatchStates = () => {
    // Completed state UI
    if (isBatchFullyImported(bulkBatch)) {
      return (
        <WSList gap="XL">
          <BulkBatchImportProgress
            batchId={bulkBatch.bulkCollaboratorBatchId}
            bulkResource={BulkResource.Collaborator}
          />
          {renderStats()}
        </WSList>
      );
    }
    // Partial imported state UI
    if (isBatchPartiallyImported(bulkBatch)) {
      return (
        <WSList gap="L">
          <BulkBatchImportProgress
            batchId={bulkBatch.bulkCollaboratorBatchId}
            bulkResource={BulkResource.Collaborator}
          />
          <WSAlert icon="alert" theme="warning" title="Errors found">
            <ul>
              {failedBatchItems.map(failedItem => (
                <li>
                  {pluralize(failedItem.value, itemPluralizeOptions)}:{" "}
                  {failedItem.label}
                </li>
              ))}
            </ul>
          </WSAlert>
          {renderStats()}
        </WSList>
      );
    }
    // Failed state UI
    if (bulkBatch.status === BulkStatus.Failed) {
      return (
        <WSList gap="L">
          <BulkBatchImportProgress
            batchId={bulkBatch.bulkCollaboratorBatchId}
            bulkResource={BulkResource.Collaborator}
          />
          <WSAlert icon="alert" theme="warning" title="Errors found">
            <ul>
              {failedBatchItems.map(failedItem => (
                <li>
                  {pluralize(failedItem.value, itemPluralizeOptions)}:{" "}
                  {failedItem.label}
                </li>
              ))}
            </ul>
          </WSAlert>
          {renderStats(false)}
        </WSList>
      );
    }

    // Importing state UI
    if (isBatchProcessing) {
      return (
        <WSList gap="L">
          <BulkBatchImportProgress
            batchId={bulkBatch.bulkCollaboratorBatchId}
            bulkResource={BulkResource.Collaborator}
          />
          {renderStats(false)}
        </WSList>
      );
    }

    // Awaiting import state UI
    if (isUploadFinished(bulkBatch, isEmptyBatch)) {
      return (
        <WSList gap="L">
          <WSFlexBox.CenterY>
            <WSIcon name="details" mr="XS" />
            <WSText.ParagraphSm weight="book" color="gray600">
              A spreadsheet is uploaded and ready to be imported and processed
              as contractors.
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>

          <WSAlert icon="bulb" size="M" theme="info" title="Pro tip">
            View upload to check that all information is accurate prior to
            completing the import process. If there are any changes to make to
            the spreadsheet, delete this import and start a new batch after
            you’ve updated your spreadsheet.
          </WSAlert>

          {renderStats(false)}
        </WSList>
      );
    }

    // Uploading state UI
    if (isUploadInProgress(bulkBatch)) {
      return (
        <WSList gap="L">
          <BulkBatchUploadProgress
            bulkBatchId={bulkBatch.bulkCollaboratorBatchId}
            bulkResource={BulkResource.Collaborator}
          />
          <WSFlexBox wrap="nowrap">
            <WSIcon size="S" name="time" mr="XS" />
            <WSText.ParagraphSm weight="book" color="gray600">
              A spreadsheet is being uploaded. If you close this page, the
              progress will continue. Ensure you complete the last step to
              import and process the spreadsheet once it has been uploaded.
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSList>
      );
    }

    // File is selected but not uploaded yet
    if (isEmptyBatch && isFileUploaded(bulkBatch)) {
      return (
        <WSFlexBox wrap="nowrap">
          <WSIcon name="details" mr="XS" />
          <WSText.ParagraphSm weight="book" color="gray600">
            A spreadsheet is selected but not uploaded yet. Click the view
            upload button to upload your formatted spreadsheet.
          </WSText.ParagraphSm>
        </WSFlexBox>
      );
    }

    // Show empty state if there are no batch items
    if (isEmptyBatch && !isFileUploaded(bulkBatch)) {
      return (
        <WSEmptyState
          hasBorder={false}
          orientation="horizontal"
          size="M"
          type="documents"
          title="File not uploaded"
          description={
            <WSText.ParagraphSm weight="book" color="gray600" inline>
              Upload your formatted spreadsheet to the batch. Need help setting
              up your spreadsheet?
              <WSButton.Link onClick={() => contractorColumnsGuideModal.open()}>
                Learn more here.
              </WSButton.Link>
            </WSText.ParagraphSm>
          }
        />
      );
    }
  };

  const statusPillProps = useBulkBatchStatus(
    bulkBatch.bulkCollaboratorBatchId,
    BulkResource.Collaborator
  );

  const { menuItems, buttons } = useBulkBatchStatusActions({
    bulkBatchId: bulkBatch.bulkCollaboratorBatchId,
    bulkResource: BulkResource.Collaborator,
    isEmptyBatch,
    downloadExampleCSV,
    downloadErrorsCSV,
    hideButtonOn: false
  });

  return (
    <WSCard
      header={{
        label:
          getUploadedFilename(bulkBatch, BulkResource.Collaborator) ||
          "Upload spreadsheet",
        value: {
          pill: statusPillProps
        }
      }}
      actions={{
        orientation: { xs: "vertical", s: "horizontal" },
        alignment: "fill",
        menuItems,
        buttons
      }}
      divider
    >
      {renderBatchStates()}
    </WSCard>
  );
};
