import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { FormPartialDataPerson, FormPartialPersonType } from ".";
import { getProfileDataAddress } from "../FormPartialAddress";
import { emptyValues } from "./emptyValues";

export function getProfileDataPerson(
  type: FormPartialPersonType,
  user?: INewUser,
  member?: IMember
): Partial<FormPartialDataPerson> {
  if (type === "BeneficialOwner") {
    return {};
  }

  const profileAddress = getProfileDataAddress(member, {
    homeAddress: type === "Representative"
  });

  return {
    country: member?.profile.address?.country || "US",
    firstName: user?.profile.firstName,
    lastName: user?.profile.lastName,
    middleName: user?.profile.middleName,
    birthday: user?.profile.dob,
    address: { ...emptyValues.address, ...profileAddress }
  };
}
