import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  IInvoiceTemplate,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useWSMutation } from "../../../query/helpers";
import { QUERY_INVOICE_TEMPLATES } from "../../../query/payments/keys";
import { useInvoiceTemplatesQuery } from "../../../query/payments/queries";
import { getInvoiceTemplate } from "../../../query/payments/selectors";
import { paymentsService } from "../../../services/payments";

export const SERIES_INVOICE_ACTIONS_MODAL = "SERIES_INVOICE_ACTIONS_MODAL";

type Action = "edit" | "revert" | "skip" | "renew" | "renewAndEdit";

type ModalProps = {
  parentInvoiceTemplateId: string;
  scheduleDateIndex: number;
};

export const SeriesInvoiceActionsModal: React.FC = () => {
  return (
    <WSModalOld name={SERIES_INVOICE_ACTIONS_MODAL} size="XS">
      {(props: ModalProps) => {
        return <SeriesInvoiceActions {...props} />;
      }}
    </WSModalOld>
  );
};

const SeriesInvoiceActions: React.FC<ModalProps> = ({
  parentInvoiceTemplateId,
  scheduleDateIndex
}) => {
  const history = useHistory();
  const location = useLocation();
  const { closeModal } = useModalOldContext();
  const invoiceTemplatesQuery = useInvoiceTemplatesQuery();

  const parentInvoiceTemplate = getInvoiceTemplate(
    invoiceTemplatesQuery.data || [],
    parentInvoiceTemplateId
  ) as IInvoiceTemplate;

  const scheduleDate =
    parentInvoiceTemplate?.scheduleDates?.[scheduleDateIndex];

  const invoiceTemplate = scheduleDate?.invoiceTemplateId
    ? getInvoiceTemplate(
        invoiceTemplatesQuery.data || [],
        scheduleDate.invoiceTemplateId
      )
    : undefined;

  const isSkipped =
    (scheduleDate?.status === ScheduleStatus.Modified &&
      invoiceTemplate &&
      invoiceTemplate.scheduleDates?.[0].status === ScheduleStatus.Skipped) ||
    scheduleDate?.status === ScheduleStatus.Skipped;

  const [action, setAction] = useState<Action>(isSkipped ? "renew" : "edit");

  const edit = (scheduleDateIndex: number) => {
    history.push({
      pathname: location.pathname + "/" + scheduleDateIndex,
      state: {
        backPath: location.pathname
      },
      search: location.search
    });
    closeModal(SERIES_INVOICE_ACTIONS_MODAL);
  };

  const [submit, submitMeta] = useWSMutation(
    async () => {
      switch (action) {
        case "skip":
          if (
            scheduleDate?.status === ScheduleStatus.Modified &&
            scheduleDate.invoiceTemplateId
          ) {
            // Update invoice template from modified schedule date
            await paymentsService.invoiceTemplate.update(
              scheduleDate.invoiceTemplateId,
              {
                scheduleDates: [
                  {
                    status: ScheduleStatus.Skipped
                  }
                ]
              }
            );
          } else {
            // Patch parent invoice template if schedule date is not modified
            await paymentsService.invoiceTemplate.update(
              parentInvoiceTemplate.invoiceTemplateId,
              {
                scheduleDates: (parentInvoiceTemplate?.scheduleDates || []).map(
                  (_, index) =>
                    index === scheduleDateIndex
                      ? {
                          status: ScheduleStatus.Skipped
                        }
                      : {}
                )
              }
            );
          }

          break;
        case "revert":
          await paymentsService.invoiceTemplate.update(
            parentInvoiceTemplate.invoiceTemplateId,
            {
              scheduleDates: (parentInvoiceTemplate?.scheduleDates || []).map(
                (_, index) =>
                  index === scheduleDateIndex
                    ? {
                        invoiceTemplateId: null as any,
                        status: ScheduleStatus.Pending
                      }
                    : {}
              )
            }
          );

          // Cancel invoice template specified in schedule date. Otherwise it will keep being in active status and will be sent to the client
          if (scheduleDate?.invoiceTemplateId) {
            await paymentsService.invoiceTemplate.update(
              scheduleDate.invoiceTemplateId,
              {
                status: FrequencyAndScheduleStatus.Cancelled
              }
            );
          }

          break;
        case "renew":
          if (
            scheduleDate?.status === ScheduleStatus.Modified &&
            scheduleDate?.invoiceTemplateId
          ) {
            // If schedule date is modified – renew scecified there invoice template
            await paymentsService.invoiceTemplate.update(
              scheduleDate.invoiceTemplateId,
              {
                scheduleDates: [
                  {
                    status: ScheduleStatus.Pending
                  }
                ]
              }
            );
          } else {
            await paymentsService.invoiceTemplate.update(
              parentInvoiceTemplate.invoiceTemplateId,
              {
                scheduleDates: (parentInvoiceTemplate?.scheduleDates || []).map(
                  (_, index) =>
                    index === scheduleDateIndex
                      ? {
                          status: ScheduleStatus.Pending
                        }
                      : {}
                )
              }
            );
          }

          break;
        case "renewAndEdit":
          if (
            scheduleDate?.status === ScheduleStatus.Modified &&
            scheduleDate?.invoiceTemplateId
          ) {
            // If schedule date is modified – renew scecified there invoice template
            await paymentsService.invoiceTemplate.update(
              scheduleDate.invoiceTemplateId,
              {
                scheduleDates: [
                  {
                    status: ScheduleStatus.Pending
                  }
                ]
              }
            );
          } else {
            await paymentsService.invoiceTemplate.update(
              parentInvoiceTemplate.invoiceTemplateId,
              {
                scheduleDates: (parentInvoiceTemplate?.scheduleDates || []).map(
                  (_, index) =>
                    index === scheduleDateIndex
                      ? {
                          status: ScheduleStatus.Pending
                        }
                      : {}
                )
              }
            );
          }
          edit(scheduleDateIndex);
          break;
        case "edit":
        default:
          edit(scheduleDateIndex);
      }

      closeModal(SERIES_INVOICE_ACTIONS_MODAL);
    },
    {
      dependencies: [QUERY_INVOICE_TEMPLATES]
    }
  );

  return (
    <>
      <WSText.Heading5 mb="M">Invoice actions</WSText.Heading5>
      <WSRadioInputGroup
        mb="2XL"
        name="action"
        value={action}
        options={
          isSkipped
            ? [
                {
                  value: "renew",
                  label: "Renew"
                },
                {
                  value: "renewAndEdit",
                  label: "Renew and edit"
                }
              ]
            : scheduleDate?.invoiceTemplateId
            ? [
                {
                  value: "edit",
                  label: "Edit invoice"
                },
                {
                  value: "revert",
                  label: "Revert changes"
                },
                {
                  value: "skip",
                  label: "Skip this instance"
                }
              ]
            : [
                {
                  value: "edit",
                  label: "Edit invoice"
                },
                {
                  value: "skip",
                  label: "Skip this instance"
                }
              ]
        }
        onChange={(event: any) => {
          setAction(event.target.value);
        }}
      />

      {submitMeta.error && (
        <WSText color="garnet" my="XL">
          {submitMeta.error.message}
        </WSText>
      )}

      <WSButtons format="modal">
        <WSButton
          loading={submitMeta.isLoading}
          onClick={() => {
            submit();
          }}
        >
          Continue
        </WSButton>
        <WSButton.Secondary
          onClick={() => {
            closeModal(SERIES_INVOICE_ACTIONS_MODAL);
          }}
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </>
  );
};
