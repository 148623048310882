import cn from "classnames";
import React, {
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSPortal } from "../../core/WSPortal/WSPortal.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSScreen } from "../../layout";
import {
  WSElement,
  WSElementProps,
  WSElementSpacingsType
} from "../../WSElement/WSElement.component";
import { ModalOldBaseProps, useModalOldContext } from "./WSModalOldContext";
import styles from "./WSModalOld.module.scss";
import { WSSidebar } from "../WSSidebar/WSSidebar.component";

export enum WSModalOldSizeMap {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}

export type WSModalOldSize = keyof typeof WSModalOldSizeMap;

export interface WSModalOldProps<Props extends ModalOldBaseProps = any>
  extends WSElementProps {
  name?: string;
  title?: string;
  onClose?: () => void;
  blockClose?: boolean;
  children: ((props: Props) => ReactNode) | ReactNode;
  size?: WSModalOldSize;
  pullOver?: boolean;
  showCloseIcon?: boolean;
  centered?: boolean;
  fullScreen?: boolean /** For tablet & desktop */;
  fullScreenOnMobile?: boolean;
  allowClickOutsideToClose?: boolean;
  allowClickOutsideWithConfirmationToClose?: boolean;
}

const modalPaddingMap: { [key in WSModalOldSizeMap]: WSElementSpacingsType } = {
  XS: "L",
  S: "L",
  M: "XL",
  L: "XL",
  XL: "XL"
};

const CONFIRM_CLOSE_MESSAGE =
  "Are you sure you want to close? Your work won't be saved.";

export const WSModalOld: React.FC<WSModalOldProps> = (props) => {
  const { name, blockClose, title, ...otherProps } = props;
  const { modals, addModal, closeModal } = useModalOldContext();
  const [visible, setVisible] = useState(!name);
  const [childrenProps, setChildrenProps] = useState<ModalOldBaseProps>({});

  // Hande internal modal state
  useEffect(() => {
    if (name) {
      const modal = modals.find((m) => m.name === name);

      if (modal) {
        setVisible(modal.visible);
        setChildrenProps(modal.props);
      } else {
        addModal(name);
        setVisible(false);
      }
    }
  }, [name, modals, addModal, closeModal]);

  // Lock body scroll
  useEffect(() => {
    if (visible) {
      document.body.classList.add(styles.withModal);
    } else {
      document.body.classList.remove(styles.withModal);
    }
    return () => {
      document.body.classList.remove(styles.withModal);
    };
  }, [visible]);

  const { onClose, children } = otherProps;

  // Single close handler
  const handleClose = useCallback(() => {
    if (!blockClose) {
      if (name) {
        closeModal(name);
      }

      if (onClose) {
        onClose();
      }
    }
  }, [name, onClose, closeModal, blockClose]);

  // Render children depending on children type
  const renderChildren = useCallback(
    () => (
      <>
        {title && <WSText.Heading5 mb="M">{title}</WSText.Heading5>}
        {children instanceof Function ? children(childrenProps) : children}
      </>
    ),
    [title, children, childrenProps]
  );

  // Handle Escape button press
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  if (visible) {
    const {
      className,
      pullOver,
      showCloseIcon = true,
      fullScreen,
      centered = false,
      fullScreenOnMobile,
      size = "M",
      ...elementProps
    } = otherProps;

    return (
      <WSPortal>
        <WSScreen.Mobile>
          {pullOver || fullScreenOnMobile ? (
            <>
              <WSElement className={styles.overlay} onClick={handleClose} />
              <WSElement
                data-active-modal
                className={cn(
                  styles.base,
                  {
                    [styles.fullScreen]: fullScreenOnMobile,
                    [styles.centered]: centered
                  },
                  className
                )}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                ref={(element) => {
                  if (element) {
                    element.focus();
                    element.scrollTop = 0;
                  }
                }}
                p={modalPaddingMap[size]}
                {...elementProps}
              >
                {renderChildren()}
              </WSElement>
            </>
          ) : (
            <WSSidebar
              data-active-modal
              onClose={handleClose}
              className={cn(styles.drawerBase, className)}
              onKeyDown={handleKeyDown}
              position="bottom"
              {...elementProps}
            >
              {renderChildren()}
            </WSSidebar>
          )}
        </WSScreen.Mobile>
        <WSScreen.TabletAndDesktop>
          <WSElement
            data-active-modal
            className={cn(styles.wrapper, blockClose && styles.blockClose)}
            onClick={
              props.allowClickOutsideToClose ||
              props.allowClickOutsideWithConfirmationToClose
                ? (event) => {
                    if (event.target === event.currentTarget) {
                      if (props.allowClickOutsideToClose) {
                        handleClose();
                      } else if (
                        props.allowClickOutsideWithConfirmationToClose
                      ) {
                        if (window.confirm(CONFIRM_CLOSE_MESSAGE)) {
                          handleClose();
                        }
                      }
                    }
                  }
                : undefined
            }
          >
            <WSElement
              className={cn(
                styles.base,
                {
                  [styles.fullScreen]: fullScreen,
                  [styles.centered]: centered
                },
                className,
                styles[`size-${size}`]
              )}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              ref={(element) => {
                if (element) {
                  element.focus();
                  element.scrollTop = 0;
                }
              }}
              p={modalPaddingMap[size]}
              {...elementProps}
            >
              {renderChildren()}
              {/* stick the close button in the end */}
              {showCloseIcon && (
                <WSIcon
                  block
                  name="exit"
                  size="L"
                  onClick={handleClose}
                  className={cn(
                    styles.closeIcon,
                    blockClose && styles.blockClose
                  )}
                />
              )}
            </WSElement>
          </WSElement>
        </WSScreen.TabletAndDesktop>
      </WSPortal>
    );
  }
  return null;
};
