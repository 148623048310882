import { WSButton } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useModalFormW9Consent } from "../../ModalW9Consent/useModalFormW9Consent";
import { getClientNameFromContext, NotificationTemplate } from "../index";

export const W9Consent: NotificationTemplate<{
  client: {
    clientId: string;
    companyName: string;
  };
}> = {
  renderTitle: props => "W-9 consent required",
  renderBody: props => {
    return (
      <>
        {props.group.length === 1 ? (
          <>
            <b>{getClientNameFromContext(props.context)}</b> requires
          </>
        ) : props.group.length === 2 ? (
          <>
            <b>{getClientNameFromContext(props.group[0].context)}</b> and{" "}
            <b>{getClientNameFromContext(props.group[1].context)}</b> require
          </>
        ) : props.group.length === 3 ? (
          <>
            <b>{getClientNameFromContext(props.group[0].context)}</b>,{" "}
            <b>{getClientNameFromContext(props.group[1].context)}</b> and{" "}
            <b>{getClientNameFromContext(props.group[2].context)}</b> require
          </>
        ) : (
          <>
            <b>{getClientNameFromContext(props.group[0].context)}</b>,{" "}
            <b>{getClientNameFromContext(props.group[1].context)}</b> and others{" "}
            require
          </>
        )}{" "}
        that you consent to share your W-9 information in order to get paid.
      </>
    );
  },
  Footer: props => {
    const history = useHistory();
    const consentModal = useModalFormW9Consent();

    return (
      <WSButton.Link
        size="S"
        rightIcon="chevron-right"
        onClick={() => {
          if (props.group.length === 1) {
            consentModal.open(
              {
                clients: [
                  {
                    clientId: props.context?.client?.clientId!,
                    name: getClientNameFromContext(props.context!)
                  }
                ]
              },
              {
                title: `Share your W-9 information with ${getClientNameFromContext(
                  props.context
                )}`
              }
            );
          } else {
            history.push("/member/dashboard/w9-consent");
          }
        }}
      >
        {props.group.length === 1
          ? `Consent to share W-9 information`
          : `Share W-9 information`}
      </WSButton.Link>
    );
  }
};
