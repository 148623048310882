import {
  WSChip,
  WSElement,
  WSEmptyState,
  WSFlexBox,
  WSInfiniteScroll,
  WSPage,
  WSPageToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { ClientsTable } from "../components/ClientsTable";
import { WSQueries } from "../../../query/WSQuery";
import { useAddClientModal } from "../components/AddClientModal/useAddClientModal";
import { useHistory } from "react-router-dom";

import {
  PayerPayeeEngagementSearchableStatus,
  PayerRowsQuery
} from "../../../services/search";
import { useUrlQueryFilters } from "../../../utils/router";
import { useCallback } from "react";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { useQueryPayerRowsSummary } from "../../../query/search/payer/queries/useQueryPayerRowsSummary";

import styles from "./styles.module.scss";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities } from "../../../query/users/queries";
import { PayerPayeeStatus } from "@wingspanhq/payments/dist/interfaces";
import { useOpenModalBySearchParam } from "../../../utils/useOpenModalBySearchParam";
import { useRedirectToByKeyPress } from "../../../utils/useRedirectToByKeyPress";
import { KEYBOARD_SHORTCUT_SEARCH } from "../../../constants/keyboardShortcuts";
import { useCustomization } from "../../customization";
import { plural } from "../../../shared/utils/plural";

export const PAYERS_PATH = "/member/payers";

type FiltersValues = {
  searchableStatus?:
    | PayerPayeeEngagementSearchableStatus
    | PayerPayeeEngagementSearchableStatus[];
};

const DEFAULT_FILTERS = {
  "payeeOwnedData.status": PayerPayeeStatus.Active
};

export const RouteClients: React.FC = () => {
  const { terminology } = useCustomization();
  const { filters, setFilters, changedFilters } =
    useUrlQueryFilters<PayerRowsQuery>({
      filter: DEFAULT_FILTERS,
      sort: {
        updatedAt: "desc"
      }
    });
  const history = useHistory();
  const userId = useUserId();
  const addClientModal = useAddClientModal();
  useOpenModalBySearchParam("add_client", addClientModal);

  const queryPayerRows = useQueryPayerRows(filters);
  const queryPayerRowsSummary = useQueryPayerRowsSummary(filters);
  const queryActivity = useActivities(userId);

  const onAddClient = useCallback(async () => {
    const clientResponse = await addClientModal.open();

    if (clientResponse) {
      history.push(`${PAYERS_PATH}/${clientResponse.payerId}`);
    }
  }, [addClientModal, history]);

  // const values = useMemo(() => {
  //   return queryParamsToFiltersValues(filters.filter || {});
  // }, [filters.filter]);

  const activity = queryActivity.data;

  useRedirectToByKeyPress(KEYBOARD_SHORTCUT_SEARCH, `${PAYERS_PATH}/search`);

  return (
    <WSPage>
      <WSPageToolbar
        title={plural(terminology().getPaidClient)}
        actions={[
          {
            label: "Search",
            icon: "search",
            buttonKind: "Tertiary",
            hideButtonOn: false,
            buttonOrder: 0,
            onClick: () => history.push(`${PAYERS_PATH}/search`)
          },
          {
            label: "Add client",
            icon: "plus",
            buttonKind: "Primary",
            onClick: onAddClient
          },
          activity?.flows?.personalPayLinkSetup?.complete
            ? {
                icon: "link",
                label: "View custom pay link",
                onClick: () => {
                  history.push("/member/personal-pay-link/dashboard");
                }
              }
            : {
                icon: "link",
                label: "Get a custom pay link",
                helpText: "Click to learn more",
                onClick: () => {
                  history.push("/member/personal-pay-link/overview");
                }
              },
          {
            icon: "archive",
            label: "View archived clients",
            onClick: () => {
              history.push(`${PAYERS_PATH}/archived`);
            }
          }
        ]}
      />
      <WSQueries
        queries={{
          queryPayerRows,
          queryPayerRowsSummary
        }}
      >
        {({ queryPayerRowsData, queryPayerRowsSummaryData }) => {
          return (
            <>
              {/* <WSFilters<FiltersValues>
                mt="L"
                mb="XL"
                values={values}
                onFilter={newValues => {
                  setFilters({
                    filter: filtersValuesToQueryParams(newValues)
                  });
                }}
                info={{
                  count: queryPayerRowsSummaryData.listSize
                }}
              /> */}
              <WSFlexBox.CenterY mb="L" className={styles.statsContainer}>
                <WSElement className={styles.resultsStats}>
                  <WSText.ParagraphXs color="gray500">
                    Showing:
                  </WSText.ParagraphXs>
                  <WSChip
                    pillText={`${queryPayerRowsSummaryData.listSize} item${
                      queryPayerRowsSummaryData.listSize === 1 ? "" : "s"
                    }`}
                    theme="neutral"
                    pillType="noBackground"
                  />
                </WSElement>
              </WSFlexBox.CenterY>
              {Object.keys(changedFilters).length &&
              queryPayerRowsData.length === 0 ? (
                <WSEmptyState
                  hasBorder
                  fullWidth
                  bgColor="gray"
                  type="search"
                  title="No results found"
                  description="Try adjusting your search or filter to find what you’re looking for."
                />
              ) : queryPayerRowsData.length === 0 ? (
                <WSEmptyState
                  hasBorder
                  fullWidth
                  bgColor="gray"
                  type="people"
                  title="No clients yet"
                  description="Once a client is added, they will appear here."
                  buttons={[
                    {
                      label: "Add client",
                      kind: "Secondary",
                      onClick: onAddClient
                    }
                  ]}
                />
              ) : (
                <WSInfiniteScroll
                  onLoad={() => {
                    queryPayerRows.fetchNextPage();
                  }}
                  loadMore={
                    queryPayerRows.data && queryPayerRows.data.length > 0
                  }
                  endOfList={!queryPayerRows.hasNextPage}
                  loading={!!queryPayerRows.isFetchingNextPage}
                >
                  <ClientsTable
                    listPath={PAYERS_PATH}
                    clients={queryPayerRowsData}
                  />
                </WSInfiniteScroll>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
