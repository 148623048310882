import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../../query/integrations/selectors";
import { usePayerQuery } from "../../../../query/payers/queries/usePayerQuery";
import { useInvoicesFormContext } from "../InvoicesForm";

export const useQuickbooksProps = ({
  isIntegrationQuickbooksAccounts,
  isIntegrationQuickbooksItems
}: {
  isIntegrationQuickbooksAccounts?: boolean;
  isIntegrationQuickbooksItems?: boolean;
}) => {
  const { watch } = useFormContext();
  const { invoice } = useInvoicesFormContext();
  const payerId = watch("client.payerId");
  const queryPayer = usePayerQuery(payerId!, {
    enabled: !!payerId
  });
  const qFeatureFlags = useFeatureFlags();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks({
    enabled: isIntegrationQuickbooksAccounts || isIntegrationQuickbooksItems
  });

  const isQuickbooksActive =
    qFeatureFlags.data?.qboOnboarding &&
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems({
    enabled: isQuickbooksActive && isIntegrationQuickbooksItems
  });

  const qIntegrationsQuickbooksAccountExpenses =
    useIntegrationsQuickbooksAccountExpenses({
      enabled: isQuickbooksActive && isIntegrationQuickbooksAccounts
    });

  return useMemo(() => {
    const quickbooksOptions = isIntegrationQuickbooksItems
      ? qIntegrationsQuickbooksItems.data?.map(item => ({
          label: item.name,
          value: item.itemId
        }))
      : isIntegrationQuickbooksAccounts
      ? qIntegrationsQuickbooksAccountExpenses.data?.map(item => ({
          label: item.name,
          value: item.accountId
        }))
      : undefined;

    const isQuickbooksItem = isIntegrationQuickbooksItems && isQuickbooksActive;
    const isQuickbooksAccount =
      isIntegrationQuickbooksAccounts && isQuickbooksActive;
    const quickbooksDefaultItemId = isQuickbooksItem
      ? invoice?.integration?.quickbooks?.itemId ||
        queryPayer.data?.payeeOwnedData?.integration?.quickbooks?.itemId ||
        qIntegrationsQuickbooks.data?.defaults?.itemId
      : undefined;
    const quickbooksDefaultAccountId = isQuickbooksAccount
      ? invoice?.integration?.quickbooks?.expenseAccountId ||
        qIntegrationsQuickbooks.data?.defaults?.expenseAccountId
      : undefined;

    return {
      isQuickbooksItem,
      isQuickbooksAccount,
      quickbooksOptions: quickbooksOptions || [],
      quickbooksDefaultItemId,
      quickbooksDefaultAccountId
    };
  }, [
    invoice?.integration?.quickbooks?.expenseAccountId,
    invoice?.integration?.quickbooks?.itemId,
    isIntegrationQuickbooksAccounts,
    isIntegrationQuickbooksItems,
    isQuickbooksActive,
    qIntegrationsQuickbooks.data?.defaults?.expenseAccountId,
    qIntegrationsQuickbooks.data?.defaults?.itemId,
    qIntegrationsQuickbooksAccountExpenses.data,
    qIntegrationsQuickbooksItems.data,
    queryPayer.data?.payeeOwnedData?.integration?.quickbooks?.itemId
  ]);
};
