import React from "react";
import {
  WSButton,
  WSFlexBox,
  WSModalOld,
  WSModalOldProps,
  WSText
} from "@wingspanhq/fe-component-library";

import styles from "./InfoPopup.module.scss";

const Paragraph: React.FC<{ children: string }> = ({ children }) => (
  <WSText color="gray600" my="XL">
    {children}
  </WSText>
);

type TextNode = string | JSX.Element;

const Text: React.FC<{ text: TextNode }> = ({ text }) =>
  typeof text === "string" ? <Paragraph>{text}</Paragraph> : text;

interface InfoPopupMainProps
  extends Omit<WSModalOldProps, "children" | "name"> {
  title?: string;
  text: TextNode | TextNode[];
  showOkButton?: boolean;
  allowClickOutsideToClose?: boolean;
}

export type InfoPopupProps = InfoPopupMainProps &
  (
    | {
        name: string;
        onClose?: () => void;
      }
    | {
        name?: string;
        onClose: () => void;
      }
  );

const InfoPopup: React.FC<InfoPopupProps> = ({
  name,
  title,
  text,
  showOkButton,
  allowClickOutsideToClose = true,
  onClose,
  ...props
}) => {
  return (
    <WSModalOld
      name={name}
      allowClickOutsideToClose={allowClickOutsideToClose}
      onClose={onClose}
      size="S"
      className={styles.card}
      {...props}
    >
      <WSText.Heading5 my="XL">{title}</WSText.Heading5>
      {Array.isArray(text) ? (
        text.map(textItem => <Text text={textItem} />)
      ) : (
        <Text text={text} />
      )}

      {showOkButton && (
        <WSFlexBox justify="center" mt="2XL">
          <WSButton.Primary type="button" ml="XS" onClick={onClose}>
            OK
          </WSButton.Primary>
        </WSFlexBox>
      )}
    </WSModalOld>
  );
};

export default InfoPopup;
