import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useDeleteCollaboratorsGroup } from "../../../query/payments/mutations";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

type Props = WSElementProps;

export const DELETE_COLLABORATOR_GROUP_MODAL =
  "DELETE_COLLABORATOR_GROUP_MODAL";

export const DeleteCollaboratorGroupModal: React.FC<Props> = () => {
  const { closeModal } = useModalOldContext();

  const [deleteGroup, deleteGroupMeta] = useDeleteCollaboratorsGroup();

  return (
    <WSModalOld
      name={DELETE_COLLABORATOR_GROUP_MODAL}
      size="S"
      title="Delete group"
    >
      {props => (
        <>
          <WSText my="2XL">
            Are you sure you want to delete <b>{props.name}</b> group?
          </WSText>

          <WSErrorMessage
            mt="XL"
            error={deleteGroupMeta.error}
            contextKey="DeleteCollaboratorsGroup"
          />

          <WSButtons mt="3XL" forceFullWidth>
            <WSButton
              destructive
              onAsyncClick={async () => {
                await deleteGroup(props.id);

                closeModal(DELETE_COLLABORATOR_GROUP_MODAL);
              }}
            >
              Delete group
            </WSButton>
            <WSButton.Tertiary
              onClick={() => {
                closeModal(DELETE_COLLABORATOR_GROUP_MODAL);
              }}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
