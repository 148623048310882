import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_PAYOUT_SETTINGS } from "../../../../query/payments/keys";
import { paymentsService } from "../../../../services/payments";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (
  config?: WSMutationConfig<{ payoutMethodType: PayoutMethodType }>
) => {
  const userId = useUserId();

  return useWSMutation<
    { payoutMethodType: PayoutMethodType },
    WSServiceError,
    { payoutMethodType?: PayoutMethodType }
  >(
    async ({ payoutMethodType }) => {
      if (!payoutMethodType) throw new Error("No payout method provided");

      if (payoutMethodType === PayoutMethodType.Wallet) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard
        });
      } else if (payoutMethodType === PayoutMethodType.GiftCard) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard,
          standard: {
            destinationType: PayoutDestinationType.WeGift,
            destinationId: "WeGift"
          }
        });
      }

      return { payoutMethodType };
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS],
      ...config
    }
  );
};
