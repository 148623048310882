import { WSGrid, WSList } from "@wingspanhq/fe-component-library";
import { AlertPendingAccountMicroDeposit } from "../../../components/AlertPendingAccountMicroDeposit";
import { DefaultFundingMethod } from "../../../components/funding/DefaultFundingMethod";

export const RouteMethod: React.FC = () => {
  return (
    <WSGrid>
      <WSGrid.Item span={{ m: "8" }}>
        <WSList gap="2XL">
          <AlertPendingAccountMicroDeposit />
          <DefaultFundingMethod />
        </WSList>
      </WSGrid.Item>
    </WSGrid>
  );
};
