import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { WSControl } from "../WSControl/WSControl";
import { WSElement } from "../WSElement/WSElement.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSLoader } from "../core/WSLoader/WSLoader.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSTable.module.scss";
import {
  WSTableColumn,
  WSTableColumnSortDirection,
  WSTableHeaderAction,
  WSTableItem
} from "./types";

type HeaderProps = {
  columns: WSTableColumn<any>[];
  tableData: Array<WSTableItem>;
  selection?: Array<WSTableItem>;
  onSelectionChange?: (selection: Array<WSTableItem>) => void;
  onSelectAll?: () => void;
  headerAction?: WSTableHeaderAction;
};

export const Header: React.FC<HeaderProps> = ({
  columns,
  tableData = [],
  selection = [],
  onSelectionChange,
  onSelectAll,
  headerAction
}) => {
  const isAllSelected = useMemo(() => {
    if (selection.length > 0 && selection.length < tableData.length) {
      return "indeterminate";
    }

    return selection.length === tableData.length;
  }, [selection.length, tableData.length]);

  const handleSelectAll = useCallback(() => {
    if (isAllSelected === true) {
      onSelectionChange?.([]);
    } else {
      onSelectionChange?.([...tableData]);
      onSelectAll?.();
    }
  }, [isAllSelected, onSelectAll, onSelectionChange, tableData]);

  return (
    <WSElement className={styles.header}>
      {columns.map((column, columnIndex) => (
        <WSElement
          key={`header-${columnIndex}`}
          className={classNames(
            styles.headerCell,
            column.config?.type === "actions" && !!headerAction
              ? styles["justify-end"]
              : column.config?.justify &&
                  styles[`justify-${column.config.justify}`]
          )}
          onClick={
            column.config?.onColumnSort
              ? () => {
                  if (column.config?.onColumnSort) {
                    let newDirection: WSTableColumnSortDirection;
                    if (column.config.sortDirection === "ascending") {
                      newDirection = "none";
                    } else if (column.config.sortDirection === "descending") {
                      newDirection = "ascending";
                    } else {
                      newDirection = "descending";
                    }
                    column.config.onColumnSort(newDirection);
                  }
                }
              : undefined
          }
        >
          {column.config?.type === "checkbox" ? (
            <WSControl
              type="multistep-checkbox"
              value={isAllSelected}
              onChange={handleSelectAll}
            />
          ) : column.config?.type === "actions" && !!headerAction ? (
            <HeaderAction {...headerAction} />
          ) : (
            <>
              {column.config?.header?.text && (
                <WSText.ParagraphSm color="gray500">
                  {column.config.header.text}
                </WSText.ParagraphSm>
              )}
              {column.config?.onColumnSort && (
                <WSIcon
                  ml="L"
                  size="XS"
                  color="gray500"
                  name={
                    column.config.sortDirection === "ascending"
                      ? "sort-up"
                      : column.config.sortDirection === "descending"
                      ? "sort-down"
                      : "sort"
                  }
                />
              )}
            </>
          )}
        </WSElement>
      ))}
    </WSElement>
  );
};

const HeaderAction: React.FC<WSTableHeaderAction> = ({
  loading,
  icon,
  onClick
}) => {
  if (loading) {
    return <WSLoader.Spinner size="XS" />;
  }

  return <WSIcon size="S" color="gray500" name={icon} onClick={onClick} />;
};
