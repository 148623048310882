import {
  toWSDateString,
  WSCard,
  WSDataItem,
  WSList
} from "@wingspanhq/fe-component-library";
import { IBulkPayableBatch } from "@wingspanhq/payments/dist/interfaces";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkResource } from "../../types";
import { getDisplayImportStatusValue } from "../../utils/getDisplayImportStatusValue";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { IPayableBatchImportSummary } from "../../services/types";

type Props = {
  bulkBatch: IBulkPayableBatch;
  bulkBatchSummary?: IPayableBatchImportSummary;
};

export const PayableBatchDetailsWidget: React.FC<Props> = ({
  bulkBatch,
  bulkBatchSummary
}) => {
  return (
    <WSCard header="Details" divider>
      <WSList gap="XL">
        <WSDataItem
          inline
          label="Original spreadsheet"
          value={getUploadedFilename(bulkBatch, BulkResource.Payable) || "--"}
        />
        <WSDataItem
          inline
          label="Imported to payables"
          value={getDisplayImportStatusValue(bulkBatch.status)}
        />
        <WSDataItem
          inline
          label="Created"
          value={toWSDateString(bulkBatch.createdAt)}
        />
        <WSDataItem
          inline
          label="Creator"
          value={getBatchCreatorName(bulkBatch)}
        />
        <WSDataItem
          inline
          label="Contractors impacted"
          value={bulkBatchSummary?.collaboratorsImpactedCount}
        />
        <WSDataItem
          inline
          label="Import strategy"
          value={bulkBatch.processingStrategy}
        />
        <WSDataItem
          inline
          label="Initial status of created payables"
          value={bulkBatch.labels.payableStatus}
        />
      </WSList>
    </WSCard>
  );
};
