import {
  toWSMoneyString,
  WSInfiniteScroll,
  WSPillProps,
  WSTable,
  WSTableCell,
  WSTableColumnSortDirection,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import React, { useCallback } from "react";
import { WSInfiniteQueryConfig } from "@ws-react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { getPayrollRunTitle } from "../../../query/payments/selectors";
import { InvoiceListQuery, paymentsService } from "../../../services/payments";
import { useUrlQuery } from "../../../shared/hooks/useUrlQuery";
import { WSServiceError } from "../../../utils/serviceHelper";
import { PayablesEmptyState } from "../../components/NewPayablesTable/PayablesTable";
import { calculateLineItemsTotal } from "../../utils";

export const QUERY_KEY_PAYROLL_INVOICES = "PAYROLL_INVOICES";

export const useQueryPayrollInvoices = (
  params: InvoiceListQuery,
  config?: WSInfiniteQueryConfig<IInvoice[], WSServiceError>
) => {
  const PAGE_SIZE = 100;

  return useWSInfiniteQuery<IInvoice[], WSServiceError>(
    [QUERY_KEY_PAYROLL_INVOICES, params],
    async ({ pageParam = 1 }) => {
      return await paymentsService.invoice.list({
        filter: {
          "labels.invoiceType": "approvedInvoicesPayment"
        },
        page: {
          size: PAGE_SIZE,
          number: pageParam
        },
        sort: isEmpty(params.sort)
          ? {
              "events.paidAt": "desc"
            }
          : params.sort
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );
};

type PayrollRunsTableItem = WSTableItem<IInvoice>;

export type PayablesTableItemData = {
  invoice: IInvoice;
};

const sortMap: Record<WSTableColumnSortDirection, "asc" | "desc" | undefined> =
  {
    ascending: "asc",
    descending: "desc",
    none: undefined
  };

export const PayablesPayrollRuns: React.FC = () => {
  useBrowserPageTitle("Payroll Runs");

  const history = useHistory();
  const location = useLocation();

  const { urlQuery, setUrlQuery } = useUrlQuery<{}, "paidAt">();

  const payrollRunsQuery = useQueryPayrollInvoices({
    sort: {
      "events.paidAt": urlQuery.sort?.direction
        ? sortMap[urlQuery.sort.direction]
        : "desc"
    }
  });

  const onView = useCallback(
    (item: PayrollRunsTableItem) => {
      history.push({
        pathname: `/member/invoices/payables/payroll-runs/${item.id}`,
        search: location.search
      });
    },
    [history, location.search]
  );

  return (
    <>
      <WSText.Heading1 mb="M">Payroll Runs</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        The full history of payments sent.
      </WSText>

      <WSQueries queries={{ payrollRunsQuery }}>
        {() => {
          const payrollRuns = flatten(payrollRunsQuery.data?.pages);

          const data = [
            ...payrollRuns.map(invoice => ({
              id: invoice.invoiceId,
              data: invoice
            }))
          ];

          return (
            <>
              <WSInfiniteScroll
                onLoad={() => {
                  payrollRunsQuery.fetchNextPage();
                }}
                loadMore={payrollRuns.length > 0}
                endOfList={!payrollRunsQuery.hasNextPage}
                loading={!!payrollRunsQuery.isFetchingNextPage}
              >
                <WSTable<IInvoice>
                  tableData={data}
                  columns={[
                    {
                      config: {
                        header: {
                          text: "Date paid"
                        },
                        gridTemplateSizeMax: "1fr",
                        sortDirection: urlQuery.sort?.direction,
                        onColumnSort(direction) {
                          setUrlQuery(prev => ({
                            ...prev,
                            sort: {
                              field: "paidAt",
                              direction
                            }
                          }));
                        }
                      },
                      renderFunction: ({ data }) => (
                        <WSTableCell text={getPayrollRunTitle(data)} />
                      )
                    },
                    {
                      config: {
                        hideOnScreens: ["XS"],
                        header: {
                          text: "Total payouts"
                        },
                        gridTemplateSizeMax: "1fr"
                      },
                      renderFunction: ({ data: invoice }) => (
                        <WSTableCell
                          text={String(
                            invoice.collaborators?.length ||
                              invoice.labels.payableCount ||
                              0
                          )}
                        />
                      )
                    },
                    {
                      config: {
                        header: {
                          text: "Status"
                        },
                        gridTemplateSizeMax: "1fr"
                      },
                      renderFunction: ({ data: invoice }) => (
                        <WSTableCell
                          {...getPayrollInvoiceStatusCell(invoice)}
                        />
                      )
                    },
                    {
                      config: {
                        header: {
                          text: "Amount"
                        },
                        justify: "end"
                      },
                      renderFunction: ({ data: invoice }) => (
                        <WSTableCell
                          text={toWSMoneyString(
                            calculateLineItemsTotal(invoice.lineItems)
                          )}
                        />
                      )
                    }
                  ]}
                  onRowClick={onView}
                />
                {data.length === 0 && (
                  <PayablesEmptyState
                    firstTime="A record of every payroll run and its corresponding details will display here."
                    standart="No payroll runs."
                  />
                )}
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};

function getPayrollInvoiceStatusCell(invoice: IInvoice): {
  pill?: WSPillProps;
  secondaryText?: string;
} {
  switch (invoice.status) {
    case "Paid":
      return {
        pill: {
          theme: "success",
          icon: true,
          text: "Processed"
        }
      };

    case "Overdue":
      return {
        pill: {
          theme: "warning",
          icon: true,
          text: "Action required"
        },
        secondaryText: "Overdue (Returned)"
      };

    default:
      return {};
  }
}
