import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../query/users/keys";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { usersService } from "../../../services/users";
import { getChangedData } from "../../../utils/getChangedData";
import { WSServiceError } from "../../../utils/serviceHelper";
import { selectorIsDomesticMember } from "../../selectors/selectorIsDomesticMember";
import { stringToUndefinedIfEmpty } from "../../utils/stringToUndefinedIfEmpty";
import { formDataToRequestAddress } from "../FormPartialAddress";
import { DEFAULT_OCCUPATION_CATEGORY } from "./constants";
import { FormData } from "./types";

export const useMutationSubmit = (config?: WSMutationConfig<void>) => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const memberQuery = useMemberProfile(userId);

  return useWSMutation<void, WSServiceError, FormData>(
    async values => {
      if (!memberQuery.data) throw new Error("Something went wrong");

      await usersService.user.update(userId, {
        profile: getChangedData(
          userQuery.data?.profile,
          {
            firstName: stringToUndefinedIfEmpty(values.firstName),
            lastName: stringToUndefinedIfEmpty(values.lastName),
            preferredName: stringToUndefinedIfEmpty(values.preferredName),
            dob: values.dob
          },
          { dismissTimeInDates: true }
        )
      });

      const isDomesticMember = selectorIsDomesticMember(memberQuery.data);

      await usersService.member.update(userId, {
        memberId: userId,
        profile: getChangedData(memberQuery.data.profile, {
          ssn:
            (isDomesticMember || values.isUSPerson) &&
            !memberQuery.data.profile.ssnProvided &&
            values.ssn
              ? values.ssn
              : undefined,
          taxInfo: isDomesticMember
            ? {
                occupations: [
                  {
                    taxCategory: DEFAULT_OCCUPATION_CATEGORY,
                    category: DEFAULT_OCCUPATION_CATEGORY
                  }
                ]
              }
            : undefined,
          homeAddress: values.isPersonalAddressTheSame
            ? undefined
            : formDataToRequestAddress(values.personalAddress),
          foreignTaxId: isDomesticMember ? undefined : values.foreignTaxId,
          foreignTaxCountry: isDomesticMember
            ? undefined
            : memberQuery.data.profile.address?.country
        })
      });
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE, QUERY_USERS_MEMBER_PROFILE],
      ...config
    }
  );
};
