import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { getBulkQueriesByResource } from "../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../types";
import { getFailedBatchItemsDisplayErrors } from "../utils/getFailedBatchItemsDisplayErrors";

type IFailedBatchItem = {
  id: string;
  label: string;
  value: number;
};

export function useFailedBatchItems(
  bulkBatchId: string,
  bulkResource: BulkResource
) {
  const { useBulkBatch, useAllBulkBatchItems } =
    getBulkQueriesByResource(bulkResource);

  const queryBulkBatch = useBulkBatch(bulkBatchId);

  const queryAllFailedBulkBatchItems = useAllBulkBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled:
        (queryBulkBatch.data?.statistics?.itemsFailed || 0) > 0 ||
        queryBulkBatch.data?.status === BulkStatus.Failed
    }
  );

  const failedBatchItems = useMemo(
    () =>
      getFailedBatchItemsDisplayErrors(queryAllFailedBulkBatchItems.data ?? []),
    [queryAllFailedBulkBatchItems.data]
  );

  return {
    failedBatchItems: failedBatchItems as unknown as IFailedBatchItem[],
    queryAllFailedBulkBatchItems
  };
}
