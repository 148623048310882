import * as Yup from "yup";
import { EntityType } from "../../modules/Onboarding/types";
import { IS_PRODUCTION_ENV } from "../constants/environment";
import {
  INTERNATIONAL_TAX_ID_VALIDATIONS,
  TaxIdValidation,
  TEST_TAX_IDS
} from "../constants/taxId";
import { selectorCountryName } from "../selectors/selectorCountryName";

function createGenericValidation(
  country: string
): Record<EntityType, TaxIdValidation> {
  const countryName = selectorCountryName(country) || country;
  return {
    Individual: {
      regex: /^[A-Z0-9]{6,12}$/,
      errorMessage: `Please enter a valid ${countryName} Tax ID for individuals. It should be 6-12 alphanumeric characters.`,
      name: `${countryName} Individual Tax ID`,
      description: `Tax identification number for individuals in ${countryName}.`,
      placeholder: "ABC123456789"
    },
    Business: {
      regex: /^[A-Z0-9]{6,15}$/,
      errorMessage: `Please enter a valid ${countryName} Tax ID for businesses. It should be 6-15 alphanumeric characters.`,
      name: `${countryName} Business Tax ID`,
      description: `Tax identification number for businesses in ${countryName}.`,
      placeholder: "BUS123456789012"
    }
  };
}

function validateTaxId(
  country: string,
  type: EntityType,
  taxId: string
): { isValid: boolean; errorMessage?: string } {
  // Skip validation in non-production environments and test values
  if (!IS_PRODUCTION_ENV) {
    if (TEST_TAX_IDS.includes(taxId)) {
      return { isValid: true };
    }
  }

  const countryValidations =
    INTERNATIONAL_TAX_ID_VALIDATIONS[country] ||
    createGenericValidation(country);
  const validation = countryValidations[type];

  if (validation?.regex.test(taxId)) {
    return { isValid: true };
  } else {
    return { isValid: false, errorMessage: validation?.errorMessage };
  }
}

export const validatorTaxId = (
  fieldName: string,
  type: EntityType,
  meCountry?: string
) =>
  Yup.string().test(fieldName, "Not valid", function (formTaxIdValue, context) {
    // This validator should not check for value presence
    if (!formTaxIdValue) {
      return true;
    }

    const _country = meCountry || context.parent?.country;
    const taxIdValidation = validateTaxId(_country, type, formTaxIdValue);
    if (!taxIdValidation.isValid) {
      return this?.createError({ message: taxIdValidation.errorMessage });
    }
    return true;
  });
