import {
  toWSDateString,
  toWSMoneyString,
  WSElement,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useInvoiceQuery } from "../../../query/payments/queries";
import { getPayrollRunTitle } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";
import { PayablesSummary } from "../../components/PayablesSummary/PayablesSummary";
import { Summary } from "../../components/Summary/Summary";
import { calculateLineItemsTotal } from "../../utils";
import { AlertPayrollDebitReturn } from "./AlertPayrollDebitReturn";

export const PayrollRun: React.FC<
  RouteComponentProps<{
    payrollRunId: string;
  }>
> = ({ match }) => {
  useBrowserPageTitle("Payroll Runs");

  const payrollRunId = match.params.payrollRunId;
  const payrollRunQuery = useInvoiceQuery(payrollRunId);
  const history = useHistory();
  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <WSQueries queries={{ payrollRunQuery }}>
      {({ payrollRunQuery: { data: payrollRun } }) => (
        <WSPage>
          <WSPageToolbar
            breadcrumbs={[
              {
                label: "Payables",
                onClick: () => {
                  history.push("/member/invoices/payables");
                }
              },
              {
                label: "Payroll runs",
                onClick: () => {
                  history.push("/member/invoices/payables/payroll-runs");
                }
              },
              {
                label: getPayrollRunTitle(payrollRun)
              }
            ]}
            title={
              getPayrollRunTitle(payrollRun) +
              (payrollRun.status === InvoiceStatus.Overdue ? " (Overdue)" : "")
            }
            actions={[
              {
                label: "Show details",
                buttonKind: "Secondary",
                onClick: () => {
                  setDetailsVisible(true);
                },
                visible:
                  payrollRun.labels &&
                  !!Object.keys(payrollRun.labels).find(
                    key => key[0] === key[0].toUpperCase()
                  )
              }
            ]}
          />
          {detailsVisible && (
            <WSSidebar
              size="S"
              onClose={() => {
                setDetailsVisible(false);
              }}
            >
              <WSText.Heading5>Payroll run details</WSText.Heading5>
              {payrollRun.labels &&
                Object.keys(payrollRun.labels)
                  .filter(key => key[0] === key[0].toUpperCase())
                  .map(key => (
                    <WSElement mb="XL" key={key}>
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </WSText.ParagraphSm>
                      <WSText>
                        {(payrollRun.labels[key] as any) instanceof Date
                          ? toWSDateString(payrollRun.dueDate, "monthDayYear")
                          : payrollRun.labels[key]}
                      </WSText>
                    </WSElement>
                  ))}
            </WSSidebar>
          )}

          <WSList gap="2XL">
            {payrollRun.status === InvoiceStatus.Overdue && (
              <AlertPayrollDebitReturn />
            )}

            <WSGrid>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Total amount"
                  value={toWSMoneyString(
                    calculateLineItemsTotal(payrollRun.lineItems)
                  )}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <PayablesSummary
                  label="Pending disbursement"
                  filter={{
                    status: [
                      PayablesStatusFilter.ToApprove,
                      PayablesStatusFilter.Scheduled
                    ],
                    payrollRunId
                  }}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Total 1099s"
                  value={
                    payrollRun?.collaborators?.length ||
                    payrollRun?.labels.payableCount ||
                    0
                  }
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Expected pay date"
                  value={
                    payrollRun.events.estimatedDepositAt
                      ? toWSDateString(
                          wsMoment(payrollRun.events.estimatedDepositAt)
                            .nextBusinessDay()
                            .toDate(),
                          "monthDayYear"
                        )
                      : "–"
                  }
                />
              </WSGrid.Item>
            </WSGrid>

            <PayablesTable
              basePath={
                "/member/invoices/payables/payroll-runs/" + payrollRunId
              }
              visibleFilters={{
                payee: true,
                openedAtRange: true
              }}
              defaultFilters={
                payrollRunQuery.data?.collaborators &&
                payrollRunQuery.data?.collaborators.length > 0
                  ? { payrollRunIdOldStack: payrollRunId }
                  : {
                      payrollRunId
                    }
              }
              showHeaders
              showDatePaid
            />
          </WSList>
        </WSPage>
      )}
    </WSQueries>
  );
};
