import {
  WSPage,
  WSGrid,
  WSPageProps,
  WSButton,
  WSDivider,
  WSTableColumn,
  WSFlexBox,
  WSText,
  WSTable,
  useIsDesktop,
  useModalOldContext,
  copyText
} from "@wingspanhq/fe-component-library";
import { IApiSessionResponse } from "@wingspanhq/users";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { useUpdateApiSession } from "../../query/users/mutations";
import { useAPISessionList } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { AddNewToken } from "./AddNewToken";
import {
  DeleteApiTokenModal,
  DELETE_API_TOKEN_MODAL
} from "./DeleteApiTokenModal";
import { EditNewToken } from "./EditNewToken";

interface DisplayTokenProps {
  session: IApiSessionResponse;
}

const DisplayToken: React.FC<DisplayTokenProps> = ({ session }) => {
  const [copied, setCopied] = useState(false);
  const isDesktop = useIsDesktop();
  const [updateApiSession, updateApiSessionMeta] = useUpdateApiSession();

  const { labels, sessionId } = session;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copied]);
  const onClick = async () => {
    setCopied(true);
    await updateApiSession(
      {
        id: sessionId
      },
      {
        onSuccess: (response: IApiSessionResponse) => {
          copyText(response?.token as string);
        }
      }
    );
  };
  return (
    <WSFlexBox direction="column">
      <WSFlexBox.Center wrap="nowrap">
        <WSText mr="M">**********</WSText>
        <WSButton.Link onClick={onClick}>
          {copied && updateApiSessionMeta.isSuccess ? "Copied" : "Copy"}
        </WSButton.Link>
      </WSFlexBox.Center>
      {isDesktop ? null : (
        <WSText.ParagraphSm>{labels?.description}</WSText.ParagraphSm>
      )}
    </WSFlexBox>
  );
};

export interface DeveloperProps {}

export const Developer: React.FC<DeveloperProps> = () => {
  const history = useHistory();
  const { openModal } = useModalOldContext();
  const apiSessionListQuery = useAPISessionList();
  const content = (
    <>
      <DeleteApiTokenModal />
      <WSDivider />
      <Switch>
        <Route
          key="add-api-token"
          exact
          path="/member/settings/tokens/new"
          component={AddNewToken}
        />
        <Route
          key="edit-api-token"
          exact
          path={`/member/settings/tokens/edit/:sessionId`}
          component={EditNewToken}
        />
      </Switch>
      <WSQueries queries={{ apiSessionListQuery }}>
        {({ apiSessionListQuery: { data: apiSessions } }) => {
          const columnsNoHeader: Array<WSTableColumn> = [
            {
              config: {
                gridTemplateSizeMax: "0.7fr"
              },
              renderFunction: (rowItem: any) => {
                return (
                  <DisplayToken session={rowItem.data as IApiSessionResponse} />
                );
              }
            },
            {
              config: {
                gridTemplateSizeMax: "0.4fr",
                hideOnScreens: ["XS", "S"],
                justify: "end"
              },
              renderFunction: (rowItem: any) => {
                return <WSText>{rowItem.data.labels?.description}</WSText>;
              }
            },
            {
              config: {
                gridTemplateSizeMax: "0.5fr",
                justify: "end"
              },
              renderFunction: (rowItem: any) => {
                return rowItem.data.isUnlimitedExpiration ? (
                  <WSText.ParagraphSm color="garnet">
                    No expiry
                  </WSText.ParagraphSm>
                ) : (
                  <WSText formatDate="monthDayYear">
                    {rowItem.data.expiration}
                  </WSText>
                );
              }
            }
          ];
          const tableData = apiSessions.map(session => {
            return {
              id: session.sessionId,
              data: session
            };
          });
          return (
            <WSTable
              tableData={tableData}
              columns={columnsNoHeader}
              rowMenuActions={(rowItem: any) => [
                {
                  label: "Edit",
                  onClick: () =>
                    history.push(
                      `/member/settings/tokens/edit/${rowItem.data.sessionId}`
                    )
                },
                {
                  label: "Delete",
                  onClick: () =>
                    openModal(DELETE_API_TOKEN_MODAL, {
                      session: rowItem.data
                    })
                }
              ]}
              bottomMessage={
                tableData.length === 0 ? (
                  <WSFlexBox justify="center" mt="3XL">
                    <WSText.Paragraph>
                      You haven't generated any tokens yet.{" "}
                      <WSText.Paragraph
                        inline
                        color="blue400"
                        onClick={() => {
                          history.push("/member/settings/tokens/new");
                        }}
                      >
                        Generate new token
                      </WSText.Paragraph>
                    </WSText.Paragraph>
                  </WSFlexBox>
                ) : (
                  ""
                )
              }
            />
          );
        }}
      </WSQueries>
    </>
  );
  const wsPageProps: WSPageProps = {};
  wsPageProps.primaryButtonComponent = props => {
    return (
      <WSButton
        onClick={() => {
          history.push("/member/settings/tokens/new");
        }}
        name="addNewToken"
        {...props}
      >
        Generate new token
      </WSButton>
    );
  };
  return (
    <WSPage title="Developer" {...wsPageProps}>
      <WSGrid>
        <WSGrid.Item span={{ m: "12" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
