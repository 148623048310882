export const QUERY_BOOKKEEPING_STATUS = "QUERY_BOOKKEEPING_STATUS";
export const QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME =
  "QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME";
export const QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE =
  "QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE";
export const QUERY_BOOKKEEPING_TRANSACTIONS = "QUERY_BOOKKEEPING_TRANSACTIONS";
export const QUERY_BOOKKEEPING_TRANSACTIONS_SUMMARY =
  "QUERY_BOOKKEEPING_TRANSACTIONS_SUMMARY";

export const QUERY_BOOKKEEPING_TRANSACTION = "QUERY_BOOKKEEPING_TRANSACTION";
export const QUERY_BOOKKEEPING_PROFIT_AND_LOSS =
  "QUERY_BOOKKEEPING_PROFIT_AND_LOSS";
export const QUERY_BOOKKEEPING_SUBCATEGORIES =
  "QUERY_BOOKKEEPING_SUBCATEGORIES";
export const QUERY_BOOKKEEPING_BANKING_BALANCE =
  "QUERY_BOOKKEEPING_BANKING_BALANCE";
export const QUERY_BOOKKEEPING_BANKING_ACCOUNT =
  "QUERY_BOOKKEEPING_BANKING_ACCOUNT";
export const QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS =
  "QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS";
export const QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTION =
  "QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTION";
export const QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE =
  "QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE";
export const QUERY_BOOKKEEPING_PENDING_CASHBACK_BALANCE =
  "QUERY_BOOKKEEPING_PENDING_CASHBACK_BALANCE";
export const QUERY_BOOKKEEPING_POSTED_CASHBACK_ELIGIBILITY =
  "QUERY_BOOKKEEPING_POSTED_CASHBACK_ELIGIBILITY";
