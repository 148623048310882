import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSMessageBox,
  WSPill,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  DeductionType,
  ICollaboratorSchema,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces/invoice";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { WSQueryConfig } from "@ws-react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card } from "../../../ClientPayments/components/Card/Card";
import { PublicAttachmentDownloadLink } from "../../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { UrlIdKey } from "../../../constants/routing";
import { getPayeeName } from "../../../modules/Payees/selectors/getPayeeNames";
import { WSQueries } from "../../../query/WSQuery";
import { useWSQuery } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";
import { QUERY_PAYABLE } from "../../../query/payments/keys";
import { useCollaboratorDeductions } from "../../../query/payments/queries";
import { getIsPayableCreator } from "../../../query/payments/selectors";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { getParentPath } from "../../../utils/goToParentRoute";
import {
  PaidDeductionsList,
  UnpaidDeductionsList
} from "../../components/Deductions/DeductionsList";
import { PayableActivity } from "../../components/PayableActivity";
import { PayableDetailsHeader } from "../../components/PayableDetailsHeader";
import { PayableParentInvoice } from "../../components/PayableParentInvoice";
import { QuickbooksHistory } from "../../components/QuickbooksHistory/QuickbooksHistory";
import { QuickbooksWrapper } from "../../components/QuickbooksWrapper/QuickbooksWrapper";
import { Totals, TotalsSection } from "../../components/Totals/Totals";
import { calculateLineItemsTotal } from "../../utils";
import { getLineItemsTotals } from "../../utils/getLineItemsTotals";
import styles from "../InvoicesInvoiceDetails.module.scss";

export const usePayableQuery = (
  payableId: string,
  config?: WSQueryConfig<IPayableSchema>
) =>
  useWSQuery<IPayableSchema>(
    [QUERY_PAYABLE, payableId],
    () => paymentsService.payable.get(payableId),
    {
      ...config
    }
  );

type Props = RouteComponentProps<
  { payableId: string; year: string },
  {},
  { backPath?: string }
>;

export const PayableDetails: React.FC<Props> = ({
  match,
  history,
  location
}) => {
  const backPath = location.state?.backPath || getParentPath(history);
  const year = parseInt(
    match.params[UrlIdKey.Year]
  ) as keyof ICollaboratorSchema["form1099Balances"];
  const is1099Flow = !isNaN(year);
  const onBack = () => {
    if (backPath) {
      history.push({
        pathname: backPath,
        search: location.search,
        state: {
          ...(location.state ?? {})
        }
      });
    }
  };

  return (
    <WSSidebar.Container onClose={onBack}>
      <Inner
        year={year}
        is1099Flow={is1099Flow}
        payableId={match.params.payableId}
        onBack={onBack}
      />
    </WSSidebar.Container>
  );
};

type InnerProps = {
  year?: keyof ICollaboratorSchema["form1099Balances"];
  is1099Flow?: boolean;
  payableId: string;
  onBack: () => void;
};

const Inner: React.FC<InnerProps> = ({
  payableId,
  onBack,
  year,
  is1099Flow
}) => {
  const history = useHistory();
  const userId = useUserId();

  const [detailsVisible, setDetailsVisible] = useState(true);

  const queryPayable = usePayableQuery(payableId);
  const queryPayee = usePayeeQuery(queryPayable.data?.memberId!, {
    enabled: !!queryPayable.data?.memberId
  });
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryDeductions = useCollaboratorDeductions(
    {
      memberId: queryPayable.data?.memberId as string,
      clientId: queryPayable.data?.clientId as string,
      type: DeductionType.PostPayment
    },
    {
      enabled: !!queryPayable.data
    }
  );

  const parentInvoiceId = (queryPayable.data as any)?.parentInvoiceId;

  return (
    <WSQueries
      queries={{
        queryPayable,
        queryPayee,
        queryUser,
        queryMember,
        queryDeductions
      }}
    >
      {({
        queryPayable: { data: payable },
        queryPayee: { data: payee },
        queryUser: { data: user },
        queryMember: { data: member },
        queryDeductions: { data: deductions }
      }) => {
        const totalsSections: TotalsSection[] = [];

        const payableLineItems = sortBy(payable?.lineItems, lineItem =>
          lineItem.labels.bulkImporterItemNumber
            ? Number(lineItem.labels.bulkImporterItemNumber)
            : lineItem.createdAt
        );

        const totalsLineItemsSection = getLineItemsTotals(
          payableLineItems,
          is1099Flow,
          payable.currency
        );

        deductions = deductions.filter(deduction => {
          if (deduction.application?.length > 0) {
            return deduction.application.some(
              application => application.payableId === payable.payableId
            );
          }

          return false;
        });

        if (payable.chargedFees?.lateFee) {
          totalsLineItemsSection.items.push([
            "Late fee",
            toWSMoneyString(
              payable.chargedFees?.lateFee.amount,
              "default",
              payable.currency
            )
          ]);
        }

        totalsSections.push(totalsLineItemsSection);

        totalsSections.push({
          items: [
            {
              bold: true,
              left: "Total",
              right: toWSMoneyString(
                calculateLineItemsTotal(payable.lineItems) +
                  (payable.chargedFees?.lateFee?.amount || 0),
                "default",
                payable.currency
              )
            },
            ...deductions.map(d => ({
              left: d.name,
              right: toWSMoneyString(-d.amount, "default", payable.currency)
            }))
          ]
        });

        if (deductions.length) {
          totalsSections.push({
            items: [
              {
                bold: true,
                left: "Contractor gross income",
                right: toWSMoneyString(
                  calculateLineItemsTotal(payable.lineItems) +
                    (payable.chargedFees?.lateFee?.amount || 0) -
                    deductions.reduce(
                      (partialSum, a) => partialSum + a.amount,
                      0
                    ),
                  "default",
                  payable.currency
                )
              }
            ]
          });
        }

        const payerName = wsName({ user, member }).getResolvedName();
        const payeeName = getPayeeName(payee);

        return (
          <WSSidebar.Layout
            header={`Invoice #${payable.invoiceNumber || " Pending"}`}
          >
            <WSElement className={styles.main}>
              <Card mb="XL">
                <WSText.Heading4 mb="M">{getPayeeName(payee)}</WSText.Heading4>
                <PayableDetailsHeader payable={payable} payee={payee} />

                {payable.status === InvoiceStatus.Pending ? (
                  <WSMessageBox.Warning noBorder mt="XL">
                    <WSFlexBox.Center>
                      <WSFlexBox.CenterY mt="M">
                        <WSIcon
                          block
                          name="alert-circle"
                          size="S"
                          color="amber400"
                          mr="M"
                        />
                        <WSText weight="medium" inline>
                          Not yet eligible for payments
                        </WSText>
                      </WSFlexBox.CenterY>
                    </WSFlexBox.Center>
                    <WSText.ParagraphSm mt="XL">
                      Your invite was sent. We're still waiting for this person
                      to complete their onboarding requirements before they can
                      receive payments.
                    </WSText.ParagraphSm>
                    {/*<WSButton destructive
                        mt="XL"
                        fullWidth
                        onClick={() => {
                          sendCollaboratorInvite(collaborator.collaboratorId, {
                            onSuccess: () => {
                              openSnackbar({
                                message: `Invite re-sent to ${collaborator
                                  .member.user.profile?.firstName ||
                                  collaborator.member.user.email}`
                              });
                            }
                          });
                        }}
                      >
                        Resend invite
                      </WSButton>
                      */}
                  </WSMessageBox.Warning>
                ) : null}
              </Card>

              <Card mb="XL">
                <WSFlexBox wrap="nowrap" justify="space-between">
                  <WSButton.Link
                    onClick={() => {
                      setDetailsVisible(!detailsVisible);
                    }}
                    rightIcon={detailsVisible ? "caret-up" : "caret-down"}
                  >
                    {detailsVisible ? "Hide" : "Show"} invoice details
                  </WSButton.Link>
                  <WSText.Heading5>
                    {toWSMoneyString(
                      calculateLineItemsTotal(payable.lineItems),
                      "default",
                      payable.currency
                    )}
                  </WSText.Heading5>
                </WSFlexBox>
                {detailsVisible && (
                  <WSElement mt="XL">
                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Bill to
                      </WSText.ParagraphSm>
                      <WSFlexBox.CenterY mb="XL" gap="M">
                        <WSText inline>{payerName}</WSText>
                        {getIsPayableCreator(payable, userId) ? (
                          <WSPill theme="blue" text="Created By" />
                        ) : null}
                      </WSFlexBox.CenterY>

                      <WSText.ParagraphSm mb="XS" color="gray500">
                        From
                      </WSText.ParagraphSm>

                      <WSFlexBox.CenterY mb="XL">
                        <WSText inline>{payeeName}</WSText>
                        {getIsPayableCreator(payable, payee.payeeId) ? (
                          <WSPill theme="blue" ml="M" text="Created By" />
                        ) : null}
                      </WSFlexBox.CenterY>
                    </WSElement>

                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Due date{!!payable.client?.payDate && " (updated)"}
                      </WSText.ParagraphSm>
                      <WSText>
                        {toWSDateString(
                          payable.client?.payDate || payable.dueDate,
                          "monthDayYear"
                        )}
                      </WSText>
                    </WSElement>
                    {payable.metadata?.purchaseOrderNumber ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          PO Number
                        </WSText.ParagraphSm>
                        <WSText>{payable.metadata?.purchaseOrderNumber}</WSText>
                      </WSElement>
                    ) : null}
                    {payable.labels?.projectName ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Project Name
                        </WSText.ParagraphSm>
                        <WSText>{payable.labels?.projectName}</WSText>
                      </WSElement>
                    ) : null}
                    {payable.invoiceNotes ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Notes
                        </WSText.ParagraphSm>
                        <WSText>{payable.invoiceNotes}</WSText>
                      </WSElement>
                    ) : null}

                    <WSElement>
                      <Totals sections={totalsSections} />
                    </WSElement>

                    {payable.lateFeeHandling &&
                      (!!payable.lateFeeHandling.lateFeePercentage ||
                        !!payable.lateFeeHandling.lateFeeAmount) && (
                        <WSElement mt="XL">
                          <WSText.ParagraphSm mb="XS" color="gray500">
                            Late fee
                          </WSText.ParagraphSm>
                          <WSText>
                            {payable.lateFeeHandling.lateFeePercentage
                              ? `${payable.lateFeeHandling.lateFeePercentage}%`
                              : toWSMoneyString(
                                  payable.lateFeeHandling.lateFeeAmount,
                                  "default",
                                  payable.currency
                                )}{" "}
                            every {payable.lateFeeHandling.frequency.every}{" "}
                            {payable.lateFeeHandling.frequency.interval} overdue
                          </WSText>
                        </WSElement>
                      )}

                    {payable.attachments?.customAttachmentIds && (
                      <WSElement mt="XL">
                        {payable.attachments?.customAttachmentIds.map(id => (
                          <PublicAttachmentDownloadLink id={id} mb="M" />
                        ))}
                      </WSElement>
                    )}
                  </WSElement>
                )}

                {parentInvoiceId && (
                  <WSElement mt="2XL">
                    <PayableParentInvoice parentInvoiceId={parentInvoiceId} />
                  </WSElement>
                )}
              </Card>

              {payable.status === InvoiceStatus.Paid ? (
                <PaidDeductionsList
                  payableId={payable.payableId}
                  collaboratorId={payable.collaboratorId}
                  clientId={payable.clientId}
                  memberId={payable.memberId}
                  collaboratorEmail={payee.user?.email}
                  onRowClick={d => {
                    history.push(
                      `${history.location.pathname}/deduction/scheduled/${d.deductionId}?${history.location.search}`
                    );
                  }}
                />
              ) : (
                <UnpaidDeductionsList
                  collaboratorId={payable.collaboratorId}
                  clientId={payable.clientId}
                  memberId={payable.memberId}
                  collaboratorEmail={payee.user?.email}
                  onRowClick={d => {
                    history.push(
                      `${history.location.pathname}/deduction/scheduled/${d.deductionId}?${history.location.search}`
                    );
                  }}
                />
              )}

              <Card>
                <WSText.Heading5 mb="XL">All activity</WSText.Heading5>
                <PayableActivity payable={payable} payee={payee} user={user} />
              </Card>
              <QuickbooksWrapper>
                <QuickbooksHistory
                  Wrapper={Card}
                  mt="XL"
                  entityId={payable.payableId}
                />
              </QuickbooksWrapper>
            </WSElement>
          </WSSidebar.Layout>
        );
      }}
    </WSQueries>
  );
};
