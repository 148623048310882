import {
  toWSDateString,
  WSButton,
  WSChip,
  WSElement,
  WSFlexBox,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import queryString from "qs";

import { IBulkPayableBatch } from "@wingspanhq/payments/dist/interfaces";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useBulkPayablesBatchesQuery,
  useQueryPayableBatchesSummary
} from "../../query/bulkPayable/queries";

import format from "date-fns/format";
import { Route, useHistory, useLocation } from "react-router-dom";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";
import { BulkBatchImportTotal } from "../../components/BulkBatchImportTotal";
import { BulkResource } from "../../types";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { BulkImportPayableBatchDetails } from "../BulkImportPayableBatchDetails";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useGoToPayableBatchDetails,
  useGoToPayablesImportStart
} from "../../paths";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import styles from "./styles.module.scss";
import { pluralize } from "../../../../shared/utils/pluralize";
import { BulkBatchesFilters } from "../../services/types";
import { useUrlQueryFilters } from "../../../../utils/router";

export function BulkImportPayables() {
  const history = useHistory();
  const location = useLocation();
  const queries: { year?: string } = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const { filters } = useUrlQueryFilters<BulkBatchesFilters>({
    year: queries.year
  });
  const queryBulkPayableBatches = useBulkPayablesBatchesQuery(filters);
  const queryPayableBatchesSummary = useQueryPayableBatchesSummary(filters);
  const queryFeatureFlags = useFeatureFlags();
  useBrowserPageTitle("Bulk imports: Payables & deductions");

  const goToPayablesImportStart = useGoToPayablesImportStart();
  const goToPayableBatchDetails = useGoToPayableBatchDetails();

  const doFirstImport = () => {
    goToPayablesImportStart();
  };

  const columns: Array<WSTableColumn<IBulkPayableBatch>> = [
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Created" }
      },
      renderFunction: row => (
        <WSTableCell
          text={toWSDateString(row.data.createdAt, "monthDayYear")}
          secondaryText={format(row.data.createdAt, "hh:mm aaa")}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.8fr",
        justify: "start",
        header: { text: "Name" }
      },
      renderFunction: row => (
        <WSTableCell
          text={
            (row.data.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName] as string) ||
            getUploadedFilename(row.data, BulkResource.Payable) ||
            "--"
          }
          secondaryText={
            row.data.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName]
              ? getUploadedFilename(row.data, BulkResource.Collaborator)
              : undefined
          }
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        justify: "start",
        header: { text: "Imported by" },
        hideOnScreens: ["XS"]
      },
      renderFunction: row => (
        <WSTableCell text={getBatchCreatorName(row.data)} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import total" },
        hideOnScreens: ["XS"]
      },
      renderFunction: row => (
        <BulkBatchImportTotal batchId={row.data.bulkPayableBatchId} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import status" }
      },
      renderFunction: row => (
        <BulkBatchImportStatus
          showIcon
          batchId={row.data.bulkPayableBatchId}
          bulkResource={BulkResource.Payable}
        />
      )
    }
  ];

  return (
    <>
      {!queryFeatureFlags.data?.newBulkImporterUIForPayables ? (
        <Route
          path="/member/imports/payables/:bulkBatchId"
          component={BulkImportPayableBatchDetails}
          exact
        />
      ) : null}
      <WSQueries
        queries={{ queryBulkPayableBatches, queryPayableBatchesSummary }}
      >
        {({
          queryBulkPayableBatchesData: bulkPayableBatches,
          queryPayableBatchesSummaryData: batchesSummary
        }) => {
          const tableData = bulkPayableBatches.map(payableBatch => {
            return {
              data: payableBatch,
              id: payableBatch.bulkPayableBatchId
            };
          });

          return (
            <>
              <WSFlexBox.CenterY className={styles.statsContainer}>
                <WSElement className={styles.resultsStats}>
                  <WSText.ParagraphXs color="gray500">
                    Showing:
                  </WSText.ParagraphXs>
                  <WSChip
                    pillText={pluralize(batchesSummary?.listSize)}
                    theme="neutral"
                    pillType="noBackground"
                  />
                </WSElement>
              </WSFlexBox.CenterY>
              <WSInfiniteScroll
                onLoad={() => {
                  queryBulkPayableBatches.fetchNextPage();
                }}
                loadMore={bulkPayableBatches.length > 0}
                endOfList={!queryBulkPayableBatches.hasNextPage}
                loading={!!queryBulkPayableBatches.isFetchingNextPage}
              >
                <WSTable<IBulkPayableBatch>
                  mt="L"
                  bottomMessage={
                    queryBulkPayableBatches.isFetched &&
                    tableData.length === 0 ? (
                      <WSFlexBox.Center direction="column">
                        <WSText mt="2XL">No imports found. </WSText>
                        <WSButton.Link onClick={doFirstImport}>
                          Import payables & deductions
                        </WSButton.Link>
                      </WSFlexBox.Center>
                    ) : null
                  }
                  tableData={tableData}
                  columns={columns}
                  onRowClick={rowItem => {
                    if (queryFeatureFlags.data?.newBulkImporterUIForPayables) {
                      goToPayableBatchDetails(rowItem.id);
                    } else {
                      history.push(`/member/imports/payables/${rowItem.id}`);
                    }
                  }}
                />
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
}
