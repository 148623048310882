import {
  useWSModal,
  WSForm,
  WSInputDate
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueryCache } from "@ws-react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_USER_PROFILE } from "../../../../../query/users/keys";
import { useUserProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorDob } from "../../../../../shared/validators/validatorDob";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: Date };

type Props = {
  onClose: () => void;
};

export const FormEditBirthday: React.FC<Props> = props => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const user = await usersService.user.update(userId, {
        profile: {
          dob: data.newValue
        }
      });

      WSQueryCache.setQueryData(QUERY_USERS_USER_PROFILE, user);
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE],
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      defaultValues={{
        newValue: queryUser.data?.profile.dob
      }}
      validationSchema={Yup.object({
        newValue: validatorDob.required("Required")
      })}
      onSubmit={mutate}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <WSForm.Field
          name="newValue"
          label="Birthday"
          component={WSInputDate}
          componentProps={{ required: true }}
        />
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditBirthday = () =>
  useWSModal(FormEditBirthday, {
    title: "Birthday",
    size: "S"
  });
