import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSCentered,
  WSPanel,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountAssets,
  useIntegrationsQuickbooksAccountLiabilities
} from "../../../../query/integrations/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useAccounts, useClientQuery } from "../../../../query/users/queries";
import { WSAccountsList } from "../../../../components/WSAccountsList/WSAccountsList.component";
import { IIntegrationAccountResponse } from "@wingspanhq/integrations/dist/lib/interfaces/account";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  useCreateClient,
  useUpdateAccounts,
  useUpdateClient
} from "../../../../query/users/mutations";
import { AccountUpdateRequest } from "../../../../services/users";
import * as Yup from "yup";
import { useUserId } from "../../../../query/hooks/helpers";
import { CreditCardBrand } from "../../../../components/WSAccountsListV2/types";
import { CreditCardBrandTemplate } from "../../../../components/WSAccountsListV2/CreditCardBrandTemplate";
import {
  IAccountUpdateRequest,
  IClientUpdateRequest,
  SessionType
} from "@wingspanhq/users/dist/lib/interfaces";
import { useSession } from "../../../../query/session";
import { getEnabledPaymentsAccounts } from "../../../../query/bookkeeping/selectors";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "./RefreshButton";
import { IntegrationsQuickbooksSyncStatus } from "@wingspanhq/integrations/dist/lib/interfaces";

export const QuickbooksSetupStep2: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const { openModal } = useModalOldContext();

  const [formError, setFormError] = useState("");

  const sessionQuery = useSession();
  const userId = useUserId();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksAccountAssets =
    useIntegrationsQuickbooksAccountAssets();
  const qIntegrationsQuickbooksAccountLiabilities =
    useIntegrationsQuickbooksAccountLiabilities();

  const [updateClient, updateClientMeta] = useUpdateClient(userId);
  const [createClient] = useCreateClient(userId);
  const qClientQuery = useClientQuery(userId, {
    refetchOnMount: "always",
    enabled: sessionQuery.data?.sessionType === SessionType.user,
    onError: async error => {
      if (
        error.response?.status === 400 &&
        error.response?.data?.error === "User is not a client"
      ) {
        await createClient({ userId });
      }
    }
  });

  const { updateAccounts } = useUpdateAccounts();
  const qAccounts = useAccounts();

  return (
    <ProjectOnboardingLayout
      noBack={
        qIntegrationsQuickbooks.data?.syncStatus !==
        IntegrationsQuickbooksSyncStatus.Synced
      }
      progress={(100 / 8) * 2}
    >
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksAccountAssets,
          qIntegrationsQuickbooksAccountLiabilities,
          qAccounts,
          qClientQuery
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksAccountAssets,
          qIntegrationsQuickbooksAccountLiabilities,
          qAccounts,
          qClientQuery
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;

          const quickbooksAccounts: IIntegrationAccountResponse[] = [
            ...qIntegrationsQuickbooksAccountAssets.data,
            ...qIntegrationsQuickbooksAccountLiabilities.data
          ].filter(account =>
            ["Bank", "Credit Card"].includes(account.accountType)
          );

          const quickbooksAccountsOptions = quickbooksAccounts
            .map(qboAccount => {
              return {
                label: qboAccount.fullyQualifiedName,
                value: qboAccount.accountId
              };
            })
            .sort((a, b) =>
              a.label
                .toLocaleLowerCase()
                .localeCompare(b.label.toLocaleLowerCase())
            );

          const accounts = getEnabledPaymentsAccounts(qAccounts.data);

          const paymentMethods =
            qClientQuery.data.profile.savedPaymentMethods || [];
          const defaultPaymentMethod =
            qClientQuery.data.profile.defaultPaymentMethod;

          // group credit cards by brands
          const creditCardsByBrand: {
            [institutionId: string]: CreditCardBrand;
          } = {};
          paymentMethods.forEach(cc => {
            const { cardBrand } = cc;
            if (cardBrand) {
              if (creditCardsByBrand[cardBrand]) {
                creditCardsByBrand[cardBrand].cards.push(cc);
              } else {
                creditCardsByBrand[cardBrand] = {
                  name: cc.cardBrand,
                  cards: [cc]
                };
              }
            }
          });
          const creditCardBrands = Object.keys(creditCardsByBrand);

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  2 of 8
                </WSText.ParagraphSm>
                <WSText.Heading4>
                  Map Accounts for Bill Payments
                </WSText.Heading4>

                <WSPanel mt="2XL">
                  <WSText.Heading5 mt="XL">
                    You have {quickbooksAccounts.length} payment account
                    {quickbooksAccounts.length === 1 ? "" : "s"} in Quickbooks
                    and {paymentMethods.length + accounts.length} payment method
                    {paymentMethods.length + accounts.length === 1
                      ? ""
                      : "s"}{" "}
                    in Wingspan
                  </WSText.Heading5>
                  {(paymentMethods.length > 0 || accounts.length > 0) &&
                  quickbooksAccounts.length > 0 ? (
                    <>
                      <WSText mt="XL">
                        In order to correctly record your Wingspan payment
                        activity in your Quickbooks, we need to map one or more
                        of these accounts to one or more of your payment methods
                      </WSText>

                      <WSText mt="XL">
                        If you do not map any of these, Bill Payments will not
                        be recorded
                      </WSText>
                      <WSFormOld
                        onSubmit={async formData => {
                          setFormError("");

                          const previouslySetAccountMappings = accounts
                            .filter(
                              account =>
                                account.integration?.quickbooks?.bankAccountId
                            )
                            .map(account => account.accountId);

                          const updateAccountsPayload: Array<
                            IAccountUpdateRequest & {
                              accountId: string;
                            }
                          > = [];
                          Object.keys(formData.accounts || {}).forEach(key => {
                            const mappedIntegrationAccount =
                              formData.accounts[key];
                            if (mappedIntegrationAccount) {
                              updateAccountsPayload.push({
                                accountId: key,
                                integration: {
                                  quickbooks: {
                                    bankAccountId: mappedIntegrationAccount
                                  }
                                }
                              });
                            } else if (
                              previouslySetAccountMappings.includes(key)
                            ) {
                              updateAccountsPayload.push({
                                accountId: key,
                                integration: {
                                  quickbooks: {
                                    bankAccountId: null
                                  }
                                }
                              });
                            }
                          });

                          const previouslySetPaymentMethodMappings =
                            paymentMethods
                              .filter(
                                paymentMethod =>
                                  paymentMethod.integration?.quickbooks
                                    ?.bankAccountId
                              )
                              .map(
                                paymentMethod => paymentMethod.paymentMethodId
                              );

                          const changedPaymentMethodBankAcccountMappings = [];

                          const savedPaymentMethods = Object.keys(
                            formData.paymentMethods || {}
                          ).map(paymentMethodId => {
                            const value =
                              formData.paymentMethods[paymentMethodId];
                            const savedPaymentMethod = paymentMethods.find(
                              pm => pm.paymentMethodId === paymentMethodId
                            );

                            const bankAccountId =
                              previouslySetPaymentMethodMappings.includes(
                                paymentMethodId
                              )
                                ? value === undefined
                                  ? null
                                  : value
                                : value;

                            if (bankAccountId !== undefined) {
                              changedPaymentMethodBankAcccountMappings.push(
                                bankAccountId
                              );
                            }

                            return {
                              ...savedPaymentMethod,
                              integration: {
                                quickbooks: {
                                  bankAccountId
                                }
                              }
                            };
                          });

                          const updatePaymentMethodsPayload: IClientUpdateRequest =
                            {
                              clientId: userId,
                              profile: {
                                savedPaymentMethods
                              }
                            };

                          if (
                            updateAccountsPayload.length ||
                            changedPaymentMethodBankAcccountMappings.length
                          ) {
                            if (updateAccountsPayload.length) {
                              await updateAccounts(updateAccountsPayload);
                            }

                            if (
                              changedPaymentMethodBankAcccountMappings.length
                            ) {
                              await updateClient(updatePaymentMethodsPayload);
                            }
                          }

                          history.push(
                            "/member/settings/integrations/quickbooks/setup/step/3"
                          );
                        }}
                        defaultValues={{
                          accounts: accounts.reduce(
                            (res, account) => ({
                              ...res,
                              [account.accountId]:
                                account.integration?.quickbooks?.bankAccountId
                            }),
                            {}
                          ) as { [k: string]: string },
                          paymentMethods: paymentMethods.reduce(
                            (res, pm) => ({
                              ...res,
                              [pm.paymentMethodId || ""]:
                                pm.integration?.quickbooks?.bankAccountId
                            }),
                            {}
                          ) as { [k: string]: string }
                        }}
                      >
                        <WSAccountsList
                          noIcon
                          getAccountDisplayComponent={account => {
                            return (
                              <WSFormOld.Field
                                name={`accounts.${account.accountId}`}
                                component={WSSelectOld}
                                componentProps={{
                                  cleanable: true,
                                  options: quickbooksAccountsOptions,
                                  searchable: true,
                                  placeholder: "Not mapped",
                                  placeholderActions: [
                                    {
                                      label: "Resync QBO Accounts",
                                      icon: "refresh-v",
                                      callback() {
                                        openModal(
                                          INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                          { entity: QUICKBOOKS_ENTITY.ACCOUNTS }
                                        );
                                      }
                                    }
                                  ]
                                }}
                                label=""
                              />
                            );
                          }}
                          mt="XL"
                          accounts={accounts}
                        />

                        {creditCardBrands.map((ccBrandName, index) => {
                          const creditCardBrand =
                            creditCardsByBrand[ccBrandName];
                          return (
                            <WSElement
                              key={ccBrandName}
                              mt="XL"
                              data-testid={`credit-card-brand-container-${ccBrandName}`}
                            >
                              <CreditCardBrandTemplate
                                creditCardBrand={creditCardBrand}
                                defaultPaymentMethod={defaultPaymentMethod}
                                customEdit={card => {
                                  return (
                                    <WSFormOld.Field
                                      ml="XL"
                                      name={`paymentMethods.${card.paymentMethodId}`}
                                      component={WSSelectOld}
                                      componentProps={{
                                        cleanable: true,
                                        options: quickbooksAccountsOptions,
                                        searchable: true,
                                        placeholder: "Not mapped",
                                        placeholderActions: [
                                          {
                                            label: "Resync QBO Accounts",
                                            icon: "refresh-v",
                                            callback() {
                                              openModal(
                                                INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                                {
                                                  entity:
                                                    QUICKBOOKS_ENTITY.ACCOUNTS
                                                }
                                              );
                                            }
                                          }
                                        ]
                                      }}
                                      label=""
                                    />
                                  );
                                }}
                              />
                            </WSElement>
                          );
                        })}

                        <WSElement mt="2XL">
                          {updateClientMeta.error && (
                            <WSErrorMessage
                              mt="2XL"
                              contextKey="IntegrationsQuickbooksMapping"
                              error={updateClientMeta.error}
                            />
                          )}
                          {updateClientMeta.error && (
                            <WSErrorMessage
                              mt="2XL"
                              contextKey="IntegrationsQuickbooksMapping"
                              error={updateClientMeta.error}
                            />
                          )}
                          {formError !== "" && (
                            <WSText mt="2XL" color="red400">
                              {formError}
                            </WSText>
                          )}
                        </WSElement>

                        <WSFlexBox.Center mt="3XL">
                          <WSButtons>
                            <WSFormOld.SubmitButton name="next">
                              Next
                            </WSFormOld.SubmitButton>
                          </WSButtons>
                        </WSFlexBox.Center>
                      </WSFormOld>
                    </>
                  ) : quickbooksAccounts.length > 0 ? (
                    <>
                      <WSText mt="XL">
                        Since you have no payment methods listed in Wingspan, we
                        won't be able to record Bill Payment activity in
                        Quickbooks yet
                      </WSText>
                      <WSText mt="XL">
                        If you plan to start paying contractors, please exit
                        setup and{" "}
                        <WSButton.Link
                          onClick={() =>
                            history.push("/member/settings/payment-methods")
                          }
                        >
                          add your payment methods
                        </WSButton.Link>{" "}
                        and then return here to complete setup
                      </WSText>
                      <WSFlexBox.Center mt="3XL">
                        <WSButton
                          onClick={() =>
                            history.push(
                              "/member/settings/integrations/quickbooks/setup/step/3"
                            )
                          }
                        >
                          Skip account mapping
                        </WSButton>
                      </WSFlexBox.Center>
                    </>
                  ) : (
                    <>
                      <WSText mt="XL">
                        Since you have no payment accounts created in
                        Quickbooks, we won't be able to record Bill Payment
                        activity in Quickbooks yet
                      </WSText>
                      <WSText mt="XL">
                        If you plan to start paying contractors, please open
                        your Quickbooks and create the payment accounts you plan
                        to use with Wingspan. Then click here to{" "}
                        <WSButton.Link
                          onClick={() =>
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: QUICKBOOKS_ENTITY.ACCOUNTS
                            })
                          }
                        >
                          refresh your QBO account data
                        </WSButton.Link>{" "}
                        and we'll sync these new accounts
                      </WSText>
                      <WSFlexBox.Center mt="3XL">
                        <WSButton
                          onClick={() =>
                            history.push(
                              "/member/settings/integrations/quickbooks/setup/step/3"
                            )
                          }
                        >
                          Skip account mapping
                        </WSButton>
                      </WSFlexBox.Center>
                    </>
                  )}
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
      <RefreshModal />
    </ProjectOnboardingLayout>
  );
};
