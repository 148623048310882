import React, { useCallback } from "react";
import { WSCard, WSCardProps } from "../WSCard/WSCard";
import { InputProps, WSField, WSFieldProps } from "../WSField/WSField";
import { WSList } from "../WSList/WSList.component";

export type WSControlGroupCardOption = {
  value: string;
} & WSCardProps;

export interface WSControlGroupCardsProps
  extends Omit<InputProps<any>, "name" | "value" | "onChange">,
    WSFieldProps {
  options: WSControlGroupCardOption[];
  chip?: boolean;
  value: string;
  onChange: (
    value: string,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  orientation?: "vertical" | "horizontal";
}

export const WSControlGroupCards = ({
  value,
  onChange,
  disabled,
  options,
  chip,
  orientation = "vertical",
  ...fieldProps
}: WSControlGroupCardsProps) => {
  const handleChange = useCallback(
    (value: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onChange(value, event);
    },
    [onChange]
  );

  return (
    <WSField as="div" {...fieldProps}>
      <WSList
        gap="L"
        kind={orientation === "vertical" ? "Vertical" : "Horizontal"}
        equalItems
      >
        {options.map(({ value: optionValue, ...cardProps }) => {
          const isActive = optionValue === value;

          return (
            <WSCard
              key={optionValue}
              active={isActive}
              {...cardProps}
              onClick={(event) => {
                handleChange(optionValue, event);
                cardProps.onClick?.(event);
              }}
            />
          );
        })}
      </WSList>
    </WSField>
  );
};
