import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { IPayerRow } from "../../../services/search";

export const getPayerNames = (payer: IPayerRow | IPayerResponse) => {
  if (!payer.user) {
    console.error(`Payer ${payer.payerId} has no user`);
  }

  return wsName({
    user: payer.user!,
    member: payer.member,
    payeeOwnedData: payer.payeeOwnedData
  });
};

export const getPayerName = (payer: IPayerRow | IPayerResponse) =>
  getPayerNames(payer).getResolvedName();

export const getPayerSecondaryName = (payer: IPayerRow | IPayerResponse) =>
  getPayerNames(payer).getResolvedSecondaryName();
