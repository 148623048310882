import {
  IBulkCollaboratorItem,
  ICustomField
} from "@wingspanhq/payments/dist/interfaces";
import { INewUser } from "@wingspanhq/users";
import { ICollaboratorCSVRow } from "../types";
import { ILabelsDictionary } from "./getUploadedFilename";

export function prepareCSVCollaboratorFromBatchItem(
  collaboratorBatchItem: IBulkCollaboratorItem,
  customFields: ICustomField[] = [],
  organizationAccounts: INewUser[] = [],
  options?: { includeErrors?: boolean }
): ICollaboratorCSVRow {
  let subOrgName = "";
  if (collaboratorBatchItem.orgSubAccountId) {
    subOrgName = organizationAccounts.find(
      orgAcc => orgAcc.userId === collaboratorBatchItem.orgSubAccountId
    )?.profile.preferredName as string;
  }
  const baseCollaboratorColumns = {
    email: collaboratorBatchItem.email,
    contractorId: collaboratorBatchItem.externalId,
    organizationAccount: subOrgName || "",
    firstName: collaboratorBatchItem.firstLastName?.split(" ")[0],
    lastName: collaboratorBatchItem.firstLastName?.split(" ")[1],
    phoneNumber: collaboratorBatchItem.labels.phoneNumber as string
  };
  const { formW9Data } = collaboratorBatchItem;
  const w9InfoColumns = {
    w9FirstName: formW9Data?.firstName,
    w9LastName: formW9Data?.lastName,
    dob: formW9Data?.dob,
    legalBusinessName: formW9Data?.legalBusinessName,
    companyStructure: formW9Data?.companyStructure,
    ein: formW9Data?.ein,
    ssn: formW9Data?.ssn,
    addressLine1: formW9Data?.addressLine1,
    addressLine2: formW9Data?.addressLine2,
    city: formW9Data?.city,
    state: formW9Data?.state,
    postalCode: formW9Data?.postalCode,
    country: formW9Data?.country
  };
  const customFieldsColumns = customFields.reduce(
    (acc: ILabelsDictionary, cf) => {
      acc[cf.key] = collaboratorBatchItem.labels[cf.key];
      return acc;
    },
    {}
  );
  return {
    ...baseCollaboratorColumns,
    ...(formW9Data?.addressLine1 || formW9Data?.ein || formW9Data?.ssn
      ? w9InfoColumns
      : {}),
    ...customFieldsColumns,
    ...(options?.includeErrors
      ? { error: collaboratorBatchItem.metadata?.errorMessage }
      : {})
  };
}
