import {
  toWSMoneyString,
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoiceCollaborator,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { getPayeeName } from "../../modules/Payees/selectors/getPayeeNames";
import { useWSQuery, WSQueryConfig } from "../../query/helpers";
import { paymentsService } from "../../services/payments";
import { getPayeeRows, IPayeeRow } from "../../services/search";
import { concurrentActions } from "../../utils/serviceHelper";

const QUERY_KEY_INVOICE_COLLABORATORS = "QUERY_KEY_INVOICE_COLLABORATORS";

export type IInvoiceCollaboratorExtended = IInvoiceCollaborator & {
  payee?: IPayeeRow;
  payable?: IPayableSchema;
};

export function useQueryInvoiceCollaborators(
  id: string, // invoice/invoiceTemplate id
  collaborators?: IInvoiceCollaborator[],
  config?: WSQueryConfig<IInvoiceCollaboratorExtended[]>
) {
  return useWSQuery<IInvoiceCollaboratorExtended[]>(
    [QUERY_KEY_INVOICE_COLLABORATORS, id],
    async () => {
      const invoiceCollaborators = collaborators || [];

      if (invoiceCollaborators.length === 0) {
        return [];
      }

      const actions = invoiceCollaborators.map(
        invoiceCollaborator => async () => {
          const search = await getPayeeRows({
            filter: {
              "engagements.payerPayeeEngagementId":
                invoiceCollaborator.memberClientId
            }
          });

          const payee: IPayeeRow | undefined = search[0];

          const payable = invoiceCollaborator.invoiceId
            ? await paymentsService.payable.get(invoiceCollaborator.invoiceId)
            : undefined;

          return {
            ...invoiceCollaborator,
            payee,
            payable
          };
        }
      );

      return await concurrentActions(actions, {
        concurrentLimit: 8
      });
    },
    config
  );
}

type Props = {
  invoice: IInvoice;
};

export const InvoiceCollaborators: React.FC<Props> = ({ invoice }) => {
  const queryInvoiceCollaborators = useQueryInvoiceCollaborators(
    invoice.invoiceId,
    invoice.collaborators
  );
  const history = useHistory();

  return (
    <WSList>
      {(queryInvoiceCollaborators.data || []).map(invoiceCollaborator => {
        if (!invoiceCollaborator.payee) {
          return null;
        }

        const payeeName = getPayeeName(invoiceCollaborator.payee);

        return (
          <WSFlexBox.CenterY
            key={invoiceCollaborator.memberClientId}
            wrap="nowrap"
            onClick={
              invoiceCollaborator.invoiceId
                ? () => {
                    history.push(
                      `/member/invoices/payables/all/${invoiceCollaborator.invoiceId}`
                    );
                  }
                : undefined
            }
          >
            <WSAvatar.Text text={payeeName} mr="M" />
            <WSElement style={{ flex: 1 }}>
              <WSText mb="XS">{payeeName}</WSText>
              <WSText.ParagraphXs color="gray600">
                {invoiceCollaborator.description}
              </WSText.ParagraphXs>
            </WSElement>
            <WSText>{toWSMoneyString(invoiceCollaborator.amount)}</WSText>
          </WSFlexBox.CenterY>
        );
      })}
    </WSList>
  );
};
