import React from "react";
import * as Yup from "yup";
import { useRequestPrincipalUser } from "../../../../query/users/mutations";
import { WSFormOld, WSTextInput } from "@wingspanhq/fe-component-library";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../../shared/validators/validatorPassword";
import { IAuthorizedAccount } from "@wingspanhq/users";
import { ISession } from "@wingspanhq/users/dist/lib/interfaces";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

type ModalPasswordProps = {
  onClose: (account: ISession) => void;
  account: IAuthorizedAccount;
};

export const ModalPassword: React.FC<ModalPasswordProps> = ({
  account,
  onClose
}) => {
  const [requestPrincipalUser, requestPrincipalUserMeta] =
    useRequestPrincipalUser();

  return (
    <WSFormOld
      onSubmit={async values => {
        await requestPrincipalUser(values, {
          onSuccess: account => {
            onClose?.(account);
            window.location.reload();
          }
        });
      }}
      defaultValues={{
        email: account.accessibleMember.user.email,
        password: ""
      }}
      validationSchema={Yup.object().shape({
        email: validatorEmail.required("Email is required"),
        password: validatorPassword.required("Password is required")
      })}
    >
      <WSFormOld.Field
        name="email"
        label="Member's email"
        mb="XL"
        component={WSTextInput}
        componentProps={{
          type: "email",
          disabled: true
        }}
      />
      <WSFormOld.Field
        name="password"
        label="Your password"
        mb="XL"
        component={WSTextInput}
        componentProps={{
          type: "password"
        }}
      />
      <WSErrorMessage
        contextKey="Other"
        error={requestPrincipalUserMeta.error}
        mb="XL"
        color="red400"
      />
      <WSFormOld.SubmitButton
        fullWidth
        loading={requestPrincipalUserMeta.isLoading}
      >
        Sign in
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
