import React from "react";
import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSElement,
  WSImage,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { PrivateURL } from "../../../components/PrivateFileURL/PrivateFileURL";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import { ViewPDF } from "../../components/ViewPDF/ViewPDF";
import { isPDFSupported } from "../../../utils/isPDFSupported";
import { downloadFile } from "../../../utils/files";

export const EDIT_TRANSACTION_RECEIPT = "EDIT_TRANSACTION_RECEIPT";

export const EditTransactionReceipt: React.FC = () => {
  const [updateTransaction, updateTransactionMeta] = useUpdateTransaction();
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld size="M" name={EDIT_TRANSACTION_RECEIPT} title="Receipt">
      {(transaction: ITransaction) => (
        <>
          <PrivateURL fileId={transaction.receiptFileId}>
            {(status, url, type) =>
              status === "success" ? (
                type === "application/pdf" ? (
                  <ViewPDF
                    height={isPDFSupported() ? 500 : 60}
                    isSmall={false}
                    text={"Download Receipt"}
                    url={url}
                    onClick={() => {
                      downloadFile(url, transaction.name + ".pdf");
                    }}
                  />
                ) : (
                  <WSImage
                    src={url}
                    placeholder={<WSElement py="6XL" shimmer />}
                  />
                )
              ) : status === "loading" ? (
                <WSElement
                  style={{ height: isPDFSupported() ? 500 : 60 }}
                  shimmer
                />
              ) : (
                <WSText py="6XL" color="red500">
                  Something went wrong!
                </WSText>
              )
            }
          </PrivateURL>

          <WSButtons mt="XL" forceFullWidth format="modal">
            <WSButton
              destructive
              loading={updateTransactionMeta.isLoading}
              onClick={async () => {
                await updateTransaction({
                  transactionId: transaction.transactionId,
                  receiptFileId: ""
                });
                closeModal(EDIT_TRANSACTION_RECEIPT);
              }}
            >
              Delete receipt
            </WSButton>
            <WSButton.Secondary
              onClick={() => {
                closeModal(EDIT_TRANSACTION_RECEIPT);
              }}
            >
              Cancel
            </WSButton.Secondary>
          </WSButtons>
        </>
      )}
    </WSModalOld>
  );
};
