import React, { ReactNode, useEffect, useState } from "react";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import {
  useModalOldContext,
  WSButton,
  WSContainer,
  WSFlexBox,
  WSCentered,
  WSLoader,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useIntegrationsQuickbooks } from "../../../../query/integrations/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import {
  INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL,
  IntegrationsQuickbooksCompletingOAuthModal
} from "./modals/IntegrationsQuickbooksCompletingOAuthModal";
import {
  INTEGRATIONS_QUICKBOOKS_EXTERNAL_NOTICE_MODAL,
  IntegrationsQuickbooksExternalNoticeModal
} from "./modals/IntegrationsQuickbooksExternalNoticeModal";
import {
  isQuickbooksAuthenticated,
  isQuickbooksServiceActive,
  isQuickbooksSyncComplete
} from "../../../../query/integrations/selectors";
import {
  IntegrationsQuickbooksResyncModal,
  INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL
} from "./modals/IntegrationsQuickbooksResyncModal";
import { IntegrationsQuickbooksAuthStatus } from "@wingspanhq/integrations/dist/lib/interfaces";

export const QuickbooksSetupStep1: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const { openModal } = useModalOldContext();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isPerformingQBOAuth = params.get("oAuthTarget") === "quickbooks";
  const isPerformingResync = params.get("resync") === "true";

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  useEffect(() => {
    if (
      isPerformingQBOAuth &&
      qIntegrationsQuickbooks.data?.authStatus !==
        IntegrationsQuickbooksAuthStatus.Connected
    ) {
      openModal(INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL, {
        onSuccess: async () => {
          history.push("/member/settings/integrations/quickbooks/setup/step/2");
        },
        onError: async () => {
          history.push("/member/settings/integrations/quickbooks/setup/step/1");
        }
      });
    }
  }, [isPerformingQBOAuth, qIntegrationsQuickbooks]);

  useEffect(() => {
    if (
      isPerformingResync &&
      qIntegrationsQuickbooks.data?.authStatus ===
        IntegrationsQuickbooksAuthStatus.Connected
    ) {
      openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_MODAL, {
        onSuccess: async () => {
          history.push("/member/settings/integrations/quickbooks/setup/step/2");
        },
        onError: async () => {
          history.push("/member/settings/integrations/quickbooks/setup/step/1");
        }
      });
    }
  }, [isPerformingResync, qIntegrationsQuickbooks]);

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 1}>
      <IntegrationsQuickbooksExternalNoticeModal />
      <IntegrationsQuickbooksCompletingOAuthModal />
      <IntegrationsQuickbooksResyncModal />
      <WSContainer verticalPadding>
        {isPerformingQBOAuth ? (
          <WSLoader.Spinner mt="5XL" />
        ) : (
          <WSQueries queries={{ qIntegrationsQuickbooks }}>
            {({ qIntegrationsQuickbooks }) => {
              const integrationState = qIntegrationsQuickbooks.data;
              let content: ReactNode;
              if (
                isQuickbooksAuthenticated(integrationState) &&
                isQuickbooksSyncComplete(integrationState)
              ) {
                content = (
                  <>
                    <WSText.Heading5 mt="XL">
                      Quickbooks Already Authenticated and Synced 😀
                    </WSText.Heading5>

                    <WSText mt="XL">
                      Your Quickbooks Online account is already connected to
                      Wingspan and everything is up to date. To edit your data
                      mappings, please continue below
                    </WSText>

                    <WSFlexBox.Center mt="2XL">
                      <WSButton.Primary
                        data-testId="submitGoToStep2"
                        onClick={() =>
                          history.push(
                            "/member/settings/integrations/quickbooks/setup/step/2"
                          )
                        }
                      >
                        Edit Mappings
                      </WSButton.Primary>
                    </WSFlexBox.Center>
                  </>
                );
              } else if (
                isQuickbooksAuthenticated(integrationState) &&
                !isQuickbooksSyncComplete(integrationState)
              ) {
                content = (
                  <>
                    <WSText.Heading5 mt="XL">
                      Quickbooks Already Authenticated but out of Sync 🤕
                    </WSText.Heading5>

                    <WSText mt="XL">
                      Your Quickbooks Online account is already connected to
                      Wingspan but it looks like we're not fully synced up. To
                      fix this, please continue below
                    </WSText>

                    <WSFlexBox.Center mt="2XL">
                      <WSButton.Primary
                        data-testId="submitFixSyncState"
                        onClick={() => {
                          history.push({
                            search: "?resync=true"
                          });
                        }}
                      >
                        Sync Quickbooks with Wingspan
                      </WSButton.Primary>
                    </WSFlexBox.Center>
                  </>
                );
              } else {
                content = (
                  <>
                    <WSText.Heading5 mt="XL">
                      Authenticate Quickbooks
                    </WSText.Heading5>

                    <WSText mt="XL">
                      In order to authenticate Quickbooks Online with Wingspan,
                      we must send you to Intuit's website
                    </WSText>

                    <WSFlexBox.Center mt="2XL">
                      <WSButton.Primary
                        data-testId="submitSetupTransactionSync"
                        onClick={() =>
                          openModal(
                            INTEGRATIONS_QUICKBOOKS_EXTERNAL_NOTICE_MODAL,
                            {
                              url: integrationState.authorizeUrl
                            }
                          )
                        }
                      >
                        Set up Quickbooks
                      </WSButton.Primary>
                    </WSFlexBox.Center>
                  </>
                );
              }
              return (
                <WSCentered span={{ m: "8" }}>
                  <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                    1 of 8
                  </WSText.ParagraphSm>
                  <WSText.Heading4>
                    Quickbooks Integration - Connect your account
                  </WSText.Heading4>
                  <WSPanel mt="2XL">{content}</WSPanel>
                </WSCentered>
              );
            }}
          </WSQueries>
        )}
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
