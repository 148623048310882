import { IAccountRequirementsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/account-requirements";
import * as Yup from "yup";

export const getValidationSchema = (
  accountRequirements?: IAccountRequirementsResponse
) =>
  Yup.object().shape({
    country: Yup.string().nullable().required("Required"),
    currency: Yup.string().nullable().required("Required"),
    accountNumber: accountRequirements
      ? Yup.string()
          .required("Required")
          .matches(
            new RegExp(accountRequirements.accountNumber.format),
            "Not valid"
          )
      : Yup.string().required("Required"),
    confirmAccountNumber: Yup.string()
      .oneOf([Yup.ref("accountNumber"), null], "Account number should match")
      .required("Required"),
    routing: accountRequirements?.routing?.format
      ? Yup.string()
          .required("Required")
          .matches(new RegExp(accountRequirements.routing.format), "Not valid")
      : Yup.string(),
    routingSecondary: accountRequirements?.routingSecondary
      ? Yup.string()
          .required("Required")
          .matches(
            new RegExp(accountRequirements.routingSecondary.format),
            "Not valid"
          )
      : Yup.string()
  });
