import {
  hasAdminScope,
  hasCollaboratorsScope,
  hasDocumentsScope,
  hasFinancesScope,
  hasPaymentsScope
} from "../../../shared/utils/teamUtils";
import { IAuthorizedAccount } from "@wingspanhq/users";
import { useHistory } from "react-router-dom";
import { useResetWSStore } from "../../../store";
import { WSQueryCache } from "@ws-react-query";

export const redirectByScope = (
  account: IAuthorizedAccount,
  history: ReturnType<typeof useHistory>,
  resetWSStore: ReturnType<typeof useResetWSStore>,
  reload = true
) => {
  if (hasAdminScope(account.allowedScopeGroupIds)) {
    history.push("/member/dashboard");
  } else if (hasPaymentsScope(account.allowedScopeGroupIds)) {
    history.push("/member/invoices");
  } else if (hasCollaboratorsScope(account.allowedScopeGroupIds)) {
    history.push("/member/invoices/contacts/collaborators");
  } else if (hasFinancesScope(account.allowedScopeGroupIds)) {
    history.push("/member/bookkeeping");
  } else if (hasDocumentsScope(account.allowedScopeGroupIds)) {
    history.push("/member/documents");
  }

  if (reload) {
    if (window.Cypress) {
      resetWSStore();
      WSQueryCache.clear();
    } else {
      window.location.reload();
    }
  }
};
