import { WSModalOld } from "@wingspanhq/fe-component-library";
import { UserType } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormRequestReset } from "./FormRequestReset";
import { FormUpdatePassword } from "./FormUpdatePassword";

export const RoutePasswordReset: React.FC = () => {
  const location = useLocation();
  const [userId, setUserId] = useState<string>("");
  const [resetToken, setResetToken] = useState<string>("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenParam = query.get("token");
    const userIdParam = query.get("userId");

    if (typeof userIdParam === "string" && typeof tokenParam === "string") {
      setUserId(userIdParam);
      setResetToken(tokenParam);
    } else {
      setUserId("");
      setResetToken("");
    }
  }, [location.search]);

  const hasResetToken = !!resetToken && !!userId;

  const userType = UserType.Client;
  const signInPath = "/payment/history";
  const requestResetPath = "/payment/reset-password";

  return (
    <WSModalOld size="S" title="Reset password" blockClose>
      {hasResetToken ? (
        <FormUpdatePassword
          signInPath={signInPath}
          userId={userId}
          resetToken={resetToken}
          requestResetPath={requestResetPath}
        />
      ) : (
        <FormRequestReset userType={userType} signInPath={signInPath} />
      )}
    </WSModalOld>
  );
};
