import { WSButton, WSMessageBox } from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { INewUser } from "@wingspanhq/users";
import { useUserId } from "../../query/hooks/helpers";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import {
  deleteRequestingUserSessionToken,
  getRequestingUserSessionToken,
  pushSessionToken
} from "../../services/sessionStorage";
import { WSQueryCache } from "@ws-react-query";
import { useResetWSStore } from "../../store";
import { getAuthorizedAccountName } from "../../shared/utils/teamUtils";
import { reset } from "../../utils/analytics";

export const BackToRequestingUserAccountBanner: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const memberQuery = useMemberProfile(userId);
  const token = getRequestingUserSessionToken();
  const resetWSStore = useResetWSStore();

  const handleClick = () => {
    const token = getRequestingUserSessionToken();
    if (token) {
      reset();
      pushSessionToken(token);
      deleteRequestingUserSessionToken();
      history.push("/member/dashboard");
      // only for dev tests
      if (window.Cypress) {
        resetWSStore();
        WSQueryCache.clear();
      } else {
        window.location.reload();
      }
    }
  };

  const name = getAuthorizedAccountName(
    memberQuery.data,
    userQuery.data as INewUser
  );

  return token ? (
    <>
      <WSMessageBox centered onClick={handleClick}>
        You're currently logged in as a team member of "{name}". Click{" "}
        <WSButton.Link onClick={handleClick} name="backToYourAccount">
          here
        </WSButton.Link>{" "}
        to return to your account
      </WSMessageBox>
    </>
  ) : null;
};
