import { WSQueryConfig } from "@ws-react-query";
import { QUERY_PAYOUT } from "../keys";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getPayout } from "../../../services/api/payments/payouts";
import { IPayoutResponse } from "../../../services/api/payments/payouts/types";

export const usePayoutQuery = (
  payoutId: string,
  queryConfig?: WSQueryConfig<IPayoutResponse, WSServiceError>
) => {
  return useWSQuery<IPayoutResponse, WSServiceError>(
    [QUERY_PAYOUT, payoutId],
    async () => await getPayout(payoutId),
    queryConfig
  );
};
