import {
  WSButton,
  WSControlGroup,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSPanel,
  WSPill,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IAdditionalData,
  IInvoice
} from "@wingspanhq/payments/dist/interfaces";
import { IPayrollReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatMoney } from "accounting";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayablesReportsQuery } from "../../../../query/payments/queries";
import { getPayrollRunTitle } from "../../../../query/payments/selectors";
import { VerticalDivider } from "../../../../shared/components/VerticalDivider";
import { track } from "../../../../utils/analytics";
import { DownloadReportButton } from "../../components/DownloadReportButton/DownloadReportButton";
import { useSelectColumnsModal } from "../../components/ModalSelectColumns";
import { useReportColumns } from "../../hooks/useReportColumns";
import { ReportFormat, ReportType } from "../../types";
import { formatReportsValue } from "../../utils/formatReportsValue";
import { selectorColumnsWithAdditionalData } from "../../utils/selectorColumnsWithAdditionalData";
import { COLUMNS, PayrollTableItem } from "./columns";
import { selectorTableColumnsFromLabels } from "./selectorTableColumnsFromLabels";
import { ReportsTable } from "../../components/ReportsTable/ReportsTable";

export const REPORTS_PAYROLL_SELECTED_COLUMNS_STORAGE_KEY =
  "REPORTS_PAYROLL_SELECTED_COLUMNS_STORAGE_KEY";

const getSelectedIds = (values: Record<string, boolean>) => {
  return Object.keys(values).filter(key => values[key]);
};

const getSelectItemText = (invoice: IInvoice) =>
  `${getPayrollRunTitle(invoice)} (${formatMoney(invoice.amount)})`;

const getPayrollTitleById = (id: string, invoices: IInvoice[]) => {
  const invoice =
    invoices.find(invoice => invoice.invoiceId === id) || invoices[0];

  return invoice ? getSelectItemText(invoice) : "Select payroll";
};

const SelectPayrollSidebar: React.FC<{
  onSubmit: (values: Record<string, boolean>) => void;
  values: Record<string, boolean>;
  payrollLineItemsData: IInvoice[];
}> = ({ onSubmit, payrollLineItemsData, values }) => {
  const [showPayrollSidebar, setShowPayrollSidebar] = useState(false);
  const [currentValues, setCurrentValues] = useState(values);
  const selectedIds = getSelectedIds(values);

  return (
    <>
      <WSButton.Secondary
        badgeNumber={selectedIds.length ? selectedIds.length : undefined}
        type="button"
        rightIcon="chevron-down"
        onClick={() => setShowPayrollSidebar(true)}
      >
        {selectedIds.length ? "Selected payrolls" : "Select payrolls"}
      </WSButton.Secondary>
      <WSSidebar
        size="S"
        visible={showPayrollSidebar}
        onClose={() => setShowPayrollSidebar(false)}
        header="Select payrolls"
        footer={
          <WSFlexBox wrap="nowrap" justify="flex-end">
            <WSButton.Tertiary
              size="M"
              mr="S"
              onClick={() => {
                setCurrentValues(values);
                setShowPayrollSidebar(false);
              }}
            >
              Cancel
            </WSButton.Tertiary>
            <WSButton
              disabled={
                Object.values(currentValues).filter(Boolean).length === 0
              }
              size="M"
              onClick={() => {
                onSubmit(currentValues);
                setShowPayrollSidebar(false);
              }}
            >
              Save
            </WSButton>
          </WSFlexBox>
        }
      >
        <WSPanel noBorder>
          {payrollLineItemsData.length > 0 ? (
            <WSControlGroup
              mt="NONE"
              type="checkbox"
              name="payrolls"
              value={currentValues}
              onChange={setCurrentValues}
              options={payrollLineItemsData.map(invoice => ({
                value: invoice.invoiceId,
                label: getSelectItemText(invoice)
              }))}
            />
          ) : (
            <WSText color="gray500" align="center" my="XL">
              No payrolls available
            </WSText>
          )}
        </WSPanel>
      </WSSidebar>
    </>
  );
};

export const PayrollLineItemsReportsDashboard: React.FC<{
  payrollLineItemsData: IInvoice[];
  customFieldsData: IAdditionalData[];
}> = ({ payrollLineItemsData, customFieldsData }) => {
  const userId = useUserId();
  const [selectedPayrolls, setSelectedPayrolls] = useState<
    Record<string, boolean>
  >({ [payrollLineItemsData[0]?.invoiceId]: true });

  const selectedPayrollIds = getSelectedIds(selectedPayrolls);
  const selectedPayrollsKey = selectedPayrollIds.join(",");

  const columns = selectorColumnsWithAdditionalData<PayrollTableItem>(
    COLUMNS,
    customFieldsData
  );

  const [selectedColumns, setSelectedColumns] = useReportColumns(
    REPORTS_PAYROLL_SELECTED_COLUMNS_STORAGE_KEY,
    userId,
    columns
  );

  const modalSelectColumns = useSelectColumnsModal();

  const history = useHistory();

  const getColumnByKey = (key: string) =>
    columns.find(_column => key === _column.value)!;

  const [reportsData, setReportsData] = useState<{
    [key: string]: IPayrollReportResponse;
  }>({});

  const isRunButtonDisabled =
    !!selectedPayrolls.length || !selectedColumns.length;

  const isDownloadButtonDisabled = !reportsData[selectedPayrollsKey]?.length;

  const queryReport = usePayablesReportsQuery(selectedPayrollIds, {
    enabled: false
  });

  const currentReportsData = reportsData[selectedPayrollsKey];
  const tableData = useMemo(() => {
    let result: PayrollTableItem[] = [];

    if (currentReportsData) {
      const reports = currentReportsData;

      reports.forEach(invoice => {
        invoice.lineItems.forEach(lineItem => {
          const parentInvoice = payrollLineItemsData.find(
            payroll => payroll.invoiceId === invoice.payrollId
          )!;

          result.push({
            lineItem,
            invoice,
            parentInvoice,
            key: `${parentInvoice.invoiceId}-${invoice.invoiceId}-${lineItem.detail}-${lineItem.description}`
          });
        });
      });
    }

    return result;
  }, [selectedPayrollsKey, currentReportsData]);

  const getReportJSON = () => {
    return tableData.map(data => {
      return selectedColumns.reduce((result, column) => {
        const currentColumn = getColumnByKey(column)!;
        let field = {};

        try {
          if (currentColumn?.value === "Line Item Labels") {
            field = Object.entries(data.lineItem.labels || {})?.reduce(
              (acc, [labelName, labelValue]) => {
                return {
                  ...acc,
                  [`Custom Field ${labelName}`]: formatReportsValue(labelValue)
                };
              },
              {}
            );
          } else {
            field = {
              [currentColumn?.label]:
                currentColumn.getReportCell?.(data) ||
                currentColumn?.getTableCell?.(data)
            };
          }
        } catch (e) {
          console.error(`REPORT ERROR! "${currentColumn?.label}": `, e);
        }

        return { ...result, ...field };
      }, {});
    });
  };

  return (
    <>
      <WSDivider mb="M" />
      <WSGrid>
        <WSGrid.Item span={{ s: "4" }}>
          <SelectPayrollSidebar
            payrollLineItemsData={payrollLineItemsData}
            values={selectedPayrolls}
            onSubmit={setSelectedPayrolls}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "8" }}>
          <WSFlexBox.CenterY>
            <WSFlexBox.CenterY
              onClick={() =>
                modalSelectColumns.open({
                  selectedColumns,
                  columns,
                  onSubmit: ({ columns }) => {
                    setSelectedColumns(columns);
                  }
                })
              }
              data-testid="selectColumns"
            >
              <WSButton.Link icon="menu">Selected columns?</WSButton.Link>
              <WSPill ml="M" theme="blue" text={`${selectedColumns.length}`} />
            </WSFlexBox.CenterY>

            <VerticalDivider mx="XL" />
            <WSButton.Primary
              name="runReport"
              disabled={isRunButtonDisabled}
              onAsyncClick={async () => {
                track("Report Ran", {
                  reportName: ReportType.PayrollLineItems,
                  selectedColumnsCount: selectedColumns.length,
                  selectedColumns,
                  filters: { selectedPayrolls: selectedPayrollIds }
                });
                const result = await queryReport.refetchData();

                setReportsData(data => ({
                  ...data,
                  ...(result ? { [selectedPayrollsKey]: result } : {})
                }));
              }}
            >
              Run report
            </WSButton.Primary>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
      </WSGrid>
      <WSDivider mt="M" />
      <WSFlexBox.CenterY mt="2XL" mb="XL" justify="flex-end">
        <DownloadReportButton
          disabled={isDownloadButtonDisabled}
          getData={getReportJSON}
          onClick={(format: ReportFormat) => {
            track("Report Downloaded", {
              reportName: ReportType.PayrollLineItems,
              selectedColumnsCount: selectedColumns.length,
              selectedColumns,
              filters: { selectedPayrolls: selectedPayrollIds },
              format
            });
          }}
          fileName={`Wingspan Payroll Report`}
          sheetName={`Sheet ${getPayrollTitleById(
            selectedPayrollIds[0],
            payrollLineItemsData
          )}`.slice(0, 30)}
        />
      </WSFlexBox.CenterY>

      {tableData.length ? (
        <ReportsTable<PayrollTableItem>
          mb="M"
          mt="XL"
          getGroupName={item => {
            return `Invoice #${item.data.invoice.invoiceNumber}, ${formatMoney(
              item.data.invoice.amount
            )}`;
          }}
          columns={selectorTableColumnsFromLabels(
            selectedColumns.map(getColumnByKey).filter(Boolean),
            tableData
          )}
          tableData={tableData.map((data, i) => ({
            data,
            id: `id_${i}_${data.key}`
          }))}
        />
      ) : (
        <WSFlexBox.Center mt="2XL">
          <WSText color="gray500">
            {currentReportsData?.length === 0
              ? `No payroll details available`
              : `Select a payroll date then click 'Run Report'`}
          </WSText>
        </WSFlexBox.Center>
      )}
    </>
  );
};
