import {
  WSButton,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSPill,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  CollaboratorsReportStatusType,
  IAdditionalData
} from "@wingspanhq/payments/dist/interfaces";
import { IContractorReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import React, { useState } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCollaboratorsReportsQuery } from "../../../../query/payments/queries";
import { track } from "../../../../utils/analytics";
import { DownloadReportButton } from "../../components/DownloadReportButton/DownloadReportButton";
import { useSelectColumnsModal } from "../../components/ModalSelectColumns";
import { useReportColumns } from "../../hooks/useReportColumns";
import { ReportFormat, ReportType } from "../../types";
import { formatReportsValue } from "../../utils/formatReportsValue";
import { selectorColumnsWithAdditionalData } from "../../utils/selectorColumnsWithAdditionalData";
import { getColumns } from "./columns";
import { VerticalDivider } from "../../../../shared/components/VerticalDivider";
import { selectorTableColumns } from "../../utils/selectorTableColumns";
import { ReportsTable } from "../../components/ReportsTable/ReportsTable";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";

export const REPORTS_COLLABORATORS_SELECTED_COLUMNS_STORAGE_KEY =
  "REPORTS_COLLABORATORS_SELECTED_COLUMNS_STORAGE_KEY";

export const CollaboratorReportDashboard: React.FC<{
  collaboratorsAdditionalData: IAdditionalData[];
}> = ({ collaboratorsAdditionalData }) => {
  const { hasAdminScope } = useAuthorizedScopeGroups();
  const userId = useUserId();
  const [selectedCollaboratorStatus, setSelectedCollaboratorStatus] =
    useState<CollaboratorsReportStatusType>(
      CollaboratorsReportStatusType.Onboarded
    );

  const columns = selectorColumnsWithAdditionalData<IContractorReportResponse>(
    getColumns(hasAdminScope),
    collaboratorsAdditionalData,
    { prefixGroupName: false }
  );

  const getColumnByKey = (key: string) =>
    columns.find(_column => key === _column.value)!;

  const [selectedColumns, setSelectedColumns] = useReportColumns(
    REPORTS_COLLABORATORS_SELECTED_COLUMNS_STORAGE_KEY,
    userId,
    columns
  );

  const modalSelectColumns = useSelectColumnsModal();

  const [reportsData, setReportsData] = useState<{
    [key in CollaboratorsReportStatusType]?: IContractorReportResponse[];
  }>({});

  const isRunButtonDisabled =
    !selectedCollaboratorStatus || !selectedColumns.length;

  const queryReport = useCollaboratorsReportsQuery(
    {
      status: selectedCollaboratorStatus
    },
    {
      enabled: false
    }
  );

  const currentReportsData = reportsData[selectedCollaboratorStatus];
  const tableData = reportsData[selectedCollaboratorStatus] || [];
  const isDownloadButtonDisabled = !tableData.length;

  const getReportJSON = () => {
    if (!tableData.length) {
      return [];
    }
    return [
      selectedColumns.map(getColumnByKey).map(c => c?.label),
      ...tableData
        .map(data => {
          return selectedColumns.map(column => {
            let result;
            const currentColumn = getColumnByKey(column);

            try {
              result = formatReportsValue(currentColumn?.getTableCell?.(data));
            } catch (e) {
              console.error(`REPORT ERROR! "${currentColumn?.label}": `, e);
              result = undefined;
            }

            return result;
          });
        })
        .filter(arr => arr.some(Boolean))
    ];
  };

  return (
    <>
      <WSDivider mb="M" />
      <WSGrid>
        <WSGrid.Item span={{ s: "4" }}>
          <WSSelectOld
            searchable={false}
            onChange={(v: any) => {
              setSelectedCollaboratorStatus(v);
            }}
            name="collaborator"
            value={selectedCollaboratorStatus}
            placeholder="Select contractor status"
            error={false}
            options={[
              {
                value: CollaboratorsReportStatusType.All,
                label: "All contractors"
              },
              {
                value: CollaboratorsReportStatusType.Onboarded,
                label: "Active contractors"
              },
              // {
              //   value: CollaboratorsReportStatusType.Pending,
              //   label: "Pending contractors"
              // },
              {
                value: CollaboratorsReportStatusType.Deleted,
                label: "Archived contractors"
              }
            ]}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "8" }}>
          <WSFlexBox.CenterY>
            <VerticalDivider mr="XL" />
            <WSFlexBox.CenterY
              onClick={() =>
                modalSelectColumns.open({
                  selectedColumns,
                  columns,
                  onSubmit: ({ columns }) => {
                    setSelectedColumns(columns);
                  }
                })
              }
              data-testid="selectColumns"
            >
              <WSButton.Link icon="menu">Selected columns</WSButton.Link>
              <WSPill ml="M" theme="blue" text={`${selectedColumns.length}`} />
            </WSFlexBox.CenterY>

            <VerticalDivider mx="XL" />
            <WSButton.Primary
              name="runReport"
              disabled={isRunButtonDisabled}
              onAsyncClick={async () => {
                track("Report Ran", {
                  reportName: ReportType.Collaborator,
                  selectedColumnsCount: selectedColumns.length,
                  selectedColumns,
                  filters: { status: selectedCollaboratorStatus }
                });
                const result = await queryReport.refetchData();

                setReportsData(data => ({
                  ...data,
                  ...{ [selectedCollaboratorStatus]: result }
                }));
              }}
            >
              Run report
            </WSButton.Primary>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
      </WSGrid>
      <WSDivider mt="M" />
      <WSFlexBox.CenterY mt="2XL" mb="XL" justify="flex-end">
        <DownloadReportButton
          disabled={isDownloadButtonDisabled}
          getData={getReportJSON}
          onClick={(format: ReportFormat) => {
            track("Report Downloaded", {
              reportName: ReportType.Collaborator,
              selectedColumnsCount: selectedColumns.length,
              selectedColumns,
              filters: { status: selectedCollaboratorStatus },
              format
            });
          }}
          fileName={`Wingspan Contractor Report`}
          sheetName={`Sheet ${selectedCollaboratorStatus}`}
        />
      </WSFlexBox.CenterY>

      {tableData.length ? (
        <ReportsTable<IContractorReportResponse>
          mb="M"
          mt="XL"
          columns={selectorTableColumns(
            selectedColumns.map(getColumnByKey).filter(Boolean)
          )}
          tableData={tableData.map((data, i) => ({
            data,
            id: `id_${i}_${data.payeeId}`
          }))}
        />
      ) : (
        <WSFlexBox.Center mt="2XL">
          <WSText color="gray500">
            {currentReportsData?.length === 0
              ? `No contractors with this attributes found`
              : `Adjust contractors filter then click 'Run Report'`}
          </WSText>
        </WSFlexBox.Center>
      )}
    </>
  );
};
