import React, { useEffect } from "react";
import {
  WSTable,
  WSTableColumn,
  WSTableItem
} from "@wingspanhq/fe-component-library";
import {
  ContextItem,
  RequirementContextType,
  RequirementTableItemData
} from "../types";
import { selectorIsMemberClientRequirementResponse } from "../selectors/selectorIsMemberClientRequirementResponse";
import { useRequirementTableConfig } from "../index";
import { ALL_REQUIREMENT_EVENTS, RequirementEvent } from "../utils";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS } from "../../Onboarding/queries/useQueryAllPayersWithEngagements";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL
} from "../../../query/payeeEngagements/keys";
import { QUERY_SIGNED_DOCUMENT } from "../../../query/signedDocuments/keys";
import { selectorIsRequirement } from "../selectors/selectorIsRequirement";
import { QUERY_PAYER_ENGAGEMENT } from "../../../query/payerEngagements/keys";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_SHARED_FILE } from "../../../query/sharedFile/keys";

type PayersRequirementsTableProps = {
  payerId: string | undefined;
  payeeId: string | undefined;
  requirements: Array<RequirementTableItemData>;
  contextType: RequirementContextType;
  hideActions?: boolean;
  compact?: boolean;
  showOverrideActions?: boolean;
  onSuccessAction?: (
    eventName: RequirementEvent,
    item: RequirementTableItemData
  ) => void;
};

export const RequirementsTable: React.FC<PayersRequirementsTableProps> = ({
  requirements,
  payeeId,
  payerId,
  contextType,
  hideActions,
  compact,
  onSuccessAction,
  showOverrideActions
}) => {
  const tableConfig = useRequirementTableConfig(
    contextType,
    (eventName, requirement) => {
      onSuccessAction?.(eventName, requirement);
      if (
        ALL_REQUIREMENT_EVENTS.SignAsPayer === eventName ||
        ALL_REQUIREMENT_EVENTS.SignAsPayee === eventName ||
        ALL_REQUIREMENT_EVENTS.UploadFile === eventName
      ) {
        if (
          requirement.requirementType === RequirementType.Signature &&
          "dataSourceId" in requirement &&
          requirement.dataSourceId
        ) {
          WSQueryCache.removeQueries([
            QUERY_SIGNED_DOCUMENT,
            requirement.dataSourceId
          ]);
        }

        if (
          requirement.requirementType === RequirementType.SharedFile &&
          "dataSourceId" in requirement &&
          requirement.dataSourceId
        ) {
          WSQueryCache.removeQueries([
            QUERY_SHARED_FILE,
            requirement.dataSourceId
          ]);
        }

        WSQueryCache.removeQueries([QUERY_PAYER_ENGAGEMENT]);
        WSQueryCache.removeQueries([QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS]);
        WSQueryCache.removeQueries([QUERY_PAYEE_ENGAGEMENTS_LIST_ALL]);
        WSQueryCache.removeQueries([QUERY_PAYEE_ENGAGEMENT]);
      }
    }
  );

  const [loading, setLoading] = React.useState(true);
  const [context, setContext] = React.useState<ContextItem>({
    payerId,
    payeeId,
    showOverrideActions
  });

  useEffect(() => {
    if (tableConfig.fetchContext) {
      tableConfig
        .fetchContext?.(requirements)
        .then((context: ContextItem) => {
          setContext(prev => ({ ...prev, ...context }));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [
    requirements
      .map(r =>
        selectorIsRequirement(r) ? r.requirementId : r.eligibilityRequirementId
      )
      .join(",")
  ]);

  const tableData: WSTableItem<RequirementTableItemData>[] = requirements.map(
    requirement => ({
      id: selectorIsMemberClientRequirementResponse(requirement)
        ? requirement.eligibilityRequirementId
        : requirement.requirementId,
      data: requirement
    })
  );

  const columns: Array<WSTableColumn<RequirementTableItemData>> = [
    {
      config: {
        header: {
          text: "Requirement"
        },
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => tableConfig.renderName(rowItem.data, context)
    },
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: compact ? ["XS", "S", "M", "L", "XL"] : ["XS"],
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => tableConfig.renderType(rowItem.data, context)
    },
    {
      config: {
        header: {
          text: "Status"
        },
        gridTemplateSizeMax: "0.5fr"
      },
      renderFunction: rowItem => tableConfig.renderStatus(rowItem.data, context)
    }
  ];

  return (
    <WSTable<RequirementTableItemData>
      loading={loading}
      rowActions={({ data }) =>
        hideActions ? [] : tableConfig.renderRowActions(data, context)
      }
      rowMenuActions={({ data }) =>
        hideActions ? [] : tableConfig.renderRowMenuActions(data, context)
      }
      columns={columns}
      tableData={tableData}
      data-testid="requirementsTable"
    />
  );
};
