import {
  useWSModal,
  WSButton,
  WSCard,
  WSControl,
  WSControlGroup,
  WSElement,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { IFilingSettings1099 } from "@wingspanhq/payments/dist/interfaces";
import { IFilingSettings1099ByYear } from "@wingspanhq/payments/dist/interfaces/paymentSettings";
import React, { useCallback, useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYROLL_SETTINGS } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";

type Value = "yes" | "no";

type Props = {
  onClose: () => void;
  period: keyof IFilingSettings1099;
  onSubmit: (value: Value) => void;
};

export const ModalOptInTaxFilling: React.FC<Props> = props => {
  const [value, setValue] = useState<Value>("yes");
  const userId = useUserId();

  const [mutate, meta] = useWSMutation(
    async () => {
      if (value === "no") {
        const payrollSettings = await paymentsService.payrollSettings.update(
          userId,
          {
            calculationSettings1099: {
              filingSettings: {
                [props.period]: {
                  filingEnabled: false
                } as IFilingSettings1099ByYear
              }
            }
          }
        );

        WSQueryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      }
    },
    {
      onSuccess: () => {
        props.onSubmit(value);
        props.onClose();
      }
    }
  );

  return (
    <WSCard
      divider
      header={{
        label: props.period + " Tax filing"
      }}
      actions={{
        alignment: "fill",
        buttons: [
          {
            label: "Confirm & continue",
            loading: meta.isLoading,
            onClick: mutate
          }
        ]
      }}
    >
      <WSList gap="XL">
        <WSElement>
          <WSText.ParagraphSm color="gray500" mb="S">
            Do you plan on filing {props.period} Form 1099-NECs through
            Wingspan?
          </WSText.ParagraphSm>

          <WSInfoBox>
            If not opted-in by the January 31st filing deadline, the dashboard
            will be set to opted-out of tax filing for the year.
          </WSInfoBox>
        </WSElement>

        <WSControlGroup
          name="enable"
          type="radio"
          value={value}
          onChange={newValue => {
            setValue(newValue as Value);
          }}
          chip
          options={[
            {
              value: "yes",
              label: `Yes, I am filing ${props.period} Form 1099-NECs through Wingspan`,
              description: (
                <p>
                  <b>
                    Your tax filing dashboard will be updated for the current
                    tax year.
                  </b>{" "}
                  Review the Wingspan filing process and preparation guides from
                  your dashboard to ensure you have all the contractor and
                  payments information in Wingspan to generate and file tax
                  forms. Learn more about the filing process{" "}
                  <a
                    href={WS_LINKS.taxFillingOverview}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              ),
              activeDescription: (
                <>
                  <p>
                    <b>Contractor experience:</b>
                  </p>
                  <ul>
                    <li>
                      All contractors you have added will be invited to sign-up
                      for a Wingspan account. Based on the eligibility criteria,
                      your contractors will be informed about whether a 1099-NEC
                      will be filed for them or not through Wingspan.
                    </li>
                  </ul>
                </>
              )
            },
            {
              value: "no",
              label: `No, I am not filing ${props.period} Form 1099-NECs through Wingspan`,
              description: (
                <p>
                  <b>
                    Your tax filing dashboard will be updated to reflect opting
                    out of filing for the current tax year (this cannot be
                    reverted after the tax filing deadline).
                  </b>{" "}
                  You can still access previous years tax forms and you’ll have
                  the option to opt-in to future filing years when the time
                  arrives.
                </p>
              ),
              activeDescription: (
                <>
                  <p>
                    <b>Contractor experience:</b>
                  </p>
                  <ul>
                    <li>
                      All contractors will be informed that they will not
                      receive a 1099-NEC form for the current filing year (
                      {props.period}) through Wingspan.
                    </li>
                  </ul>
                </>
              )
            }
          ]}
        />

        <WSText.ParagraphSm color="gray500" mb="S">
          If not opted-in by the filing deadline, the dashboard will be set to
          opted-out of tax filing for the year.
        </WSText.ParagraphSm>

        <WSErrorMessage contextKey="TaxFilling" error={meta.error} />
      </WSList>
    </WSCard>
  );
};

export const ModalTaxFillingReminders: React.FC<{
  onClose: () => void;
  period: keyof IFilingSettings1099;
}> = props => {
  const [consent, setConsent] = useState(false);
  const userId = useUserId();

  const [mutate, meta] = useWSMutation(
    async () => {
      const payrollSettings = await paymentsService.payrollSettings.update(
        userId,
        {
          calculationSettings1099: {
            filingSettings: {
              [props.period]: {
                filingEnabled: true
              } as IFilingSettings1099ByYear
            }
          }
        }
      );

      WSQueryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
    },
    {
      onSuccess: () => {
        props.onClose();
      }
    }
  );

  return (
    <WSCard
      divider
      header={{
        label: "Important tax filing reminders"
      }}
      actions={{
        alignment: "fill",
        buttons: [
          {
            label: "Continue",
            disabled: !consent,
            onClick: mutate,
            loading: meta.isLoading
          }
        ]
      }}
    >
      <WSList gap="XL">
        <WSText.ParagraphSm color="gray500" mb="S">
          Key steps to take to in advance of tax filing on the Wingspan platform
          to set your contractors up for success this tax filing season:
        </WSText.ParagraphSm>

        <WSInfoBox>
          <p>
            <b>Attend a 1099 filing training</b>
            <ul>
              <li>
                Contact your Wingspan account manager to attend a live 1099
                training session or watch a recorded playback (if available).
              </li>
            </ul>
          </p>

          <p>
            <b>Review the contractor communication guide</b>
            <ul>
              <li>
                Review the{" "}
                <a
                  href={WS_LINKS.communicationTimeline}
                  target="_blank"
                  rel="noreferrer"
                >
                  communication timeline
                </a>
                . Preparing your contractors for messages from Wingspan helps to
                make a smoother experience during tax season.
              </li>
            </ul>
          </p>

          <p>
            <b>Review your responsibilities</b>
            <ul>
              <li>
                Review your responsibilities in detail and complete the
                acknowledgement.
              </li>
            </ul>
          </p>

          <WSButton.Secondary
            fullWidth
            onClick={() => {
              openInNewTab(WS_LINKS.reviewPayerResponsibilities);
            }}
            mb="L"
            rightIcon="open-tab"
          >
            Review payer responsibilities
          </WSButton.Secondary>

          <WSControl
            type="checkbox"
            name="consent"
            value={consent}
            onChange={setConsent}
            label="By checking this box, I confirm that I have reviewed and agree with the payer responsibilities."
            size="S"
          />
        </WSInfoBox>

        <WSErrorMessage contextKey="TaxFilling" error={meta.error} />
      </WSList>
    </WSCard>
  );
};

export const useOptInTaxFilling = (year: number) => {
  const period = `${year}` as keyof IFilingSettings1099;

  const modalOptInTaxFilling = useWSModal(ModalOptInTaxFilling, {
    style: { padding: 0, borderRadius: 8 }
  });
  const modalTaxFillingReminders = useWSModal(ModalTaxFillingReminders, {
    style: { padding: 0, borderRadius: 8 }
  });

  return useCallback(() => {
    modalOptInTaxFilling.open({
      period,
      onSubmit: value => {
        if (value === "yes") {
          modalTaxFillingReminders.open({
            period
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
