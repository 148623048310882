import { WSText } from "@wingspanhq/fe-component-library";
import { WS_LINKS } from "../../types/wsLinks";

export const OTPDisclosure = () => (
  <WSText.ParagraphXs color="gray600">
    You may receive messages and secure links for account-related purposes from
    different phone numbers or trusted providers (such as Footprint) that help
    us deliver these notifications. We’ll only use SMS for security and
    account-related purposes - you won’t get promotional texts from us. Do not
    share any sensitive information through text messages. Learn more in our{" "}
    <a
      href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>{" "}
    or{" "}
    <a href={WS_LINKS.helpCenter} target="_blank" rel="noreferrer">
      Help Center
    </a>
    .
  </WSText.ParagraphXs>
);
