import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { addNotification } from "../../../components/Notification/Notification";
import { QUERY_PAYABLES } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { PayablesTableItemData } from "./PayablesTable";

type BulkApproveModalProps = {
  data: PayablesTableItemData[];
  onFinish?(): void;
};

export const PAYABLES_BULK_DELETE = "PAYABLES_BULK_DELETE";

export const BulkDeleteModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  const [isUpdating, setIsUpdating] = useState(false);

  let paidItems: string[] = [];

  const bulkDelete = bulkActionFactory({
    onStart: () => {
      setIsUpdating(true);
      paidItems = [];
    },
    action: async (item: PayablesTableItemData) => {
      const payableId = item.payable?.payableId || "";

      if (
        item.payable?.status === InvoiceStatus.Paid ||
        item.payable?.status === InvoiceStatus.PaymentInTransit
      ) {
        return paidItems.push(payableId);
      } else if (
        item.payable?.status === InvoiceStatus.Pending ||
        item.payable?.status === InvoiceStatus.Open ||
        item.payable?.status === InvoiceStatus.Overdue
      ) {
        return await paymentsService.payable.update(payableId, {
          status: InvoiceStatus.Cancelled
        });
      } else {
        return await paymentsService.payable.delete(payableId);
      }
    },
    onFinish: ({ successItems, failedItems }) => {
      WSQueryCache.invalidateQueries(QUERY_PAYABLES);

      addNotification({
        timeout: 6000,
        text:
          `${successItems.length} payables have been deleted` +
          (paidItems.length > 0
            ? `, ${paidItems.length} couldn't be deleted because it's paid`
            : "") +
          (failedItems.length > 0 ? `, ${failedItems.length} failed` : "")
      });

      setIsUpdating(false);
    }
  });

  return (
    <WSModalOld blockClose={isUpdating} name={PAYABLES_BULK_DELETE} size="S">
      {({ data, onFinish }: BulkApproveModalProps) => (
        <>
          {data && data.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Delete {data.length} payable{data.length > 1 && "s"}?
              </WSText.Heading5>
              <WSText mb="XL">This action can not be undone</WSText>
              <WSButtons format="modal">
                <WSButton
                  loading={isUpdating}
                  onClick={async () => {
                    await bulkDelete.run(data);

                    onFinish?.();
                    closeModal(PAYABLES_BULK_DELETE);
                  }}
                >
                  Delete
                </WSButton>
                <WSButton.Tertiary
                  disabled={isUpdating}
                  onClick={() => closeModal(PAYABLES_BULK_DELETE)}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          ) : (
            closeModal(PAYABLES_BULK_DELETE)
          )}
        </>
      )}
    </WSModalOld>
  );
};
