import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../query/payments/queries";
import { QUERY_USERS_ACCOUNTS } from "../../../query/users/keys";
import { useAccounts } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useLinkPayoutAccount } from "../../hooks/useLinkPayoutAccount";
import { Form } from "./Form";

type Props = {
  onSuccess?: () => void;
};

export const FormContainer: React.FC<Props> = ({ onSuccess }) => {
  const userId = useUserId();
  const queryAccounts = useAccounts({ refetchOnMount: true });
  const queryPayoutSettings = usePayoutSettings(userId);

  const [submit, submitMeta] = useWSMutation<
    any,
    WSServiceError,
    { accountId?: string }
  >(
    async ({ accountId }) => {
      if (accountId) {
        await paymentsService.payoutSettings.update(userId, {
          standard: {
            destinationType: PayoutDestinationType.Account,
            destinationId: accountId
          }
        });
      } else {
        throw new Error("Please, choose one account");
      }
    },
    {
      dependencies: [QUERY_USERS_ACCOUNTS],
      onSuccess
    }
  );

  const { linkPayoutAccount } = useLinkPayoutAccount({
    forcePlaidOnLink: true
  });

  return (
    <WSQueries queries={{ queryAccounts, queryPayoutSettings }}>
      {({
        queryAccounts: { data: accounts },
        queryPayoutSettings: { data: payoutSettings }
      }) => (
        <Form
          accounts={accounts}
          payoutSettings={payoutSettings}
          onSubmit={accountId => submit({ accountId })}
          onLinkAnotherAccount={linkPayoutAccount}
          error={submitMeta.error}
          isLoading={submitMeta.isLoading}
        />
      )}
    </WSQueries>
  );
};
