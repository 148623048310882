import { ISessionResponse } from "@wingspanhq/users";
import { WSQueryConfig } from "@ws-react-query";
import { usersService } from "../services/users";
import { WSServiceError } from "../utils/serviceHelper";
import { useWSQuery } from "./helpers";

// keys
export const QUERY_USERS_SESSION = "QUERY_USERS_SESSION";

// queries
export const useSession = (
  queryConfig?: WSQueryConfig<ISessionResponse, WSServiceError>
) => {
  return useWSQuery(QUERY_USERS_SESSION, usersService.session.get, {
    ...queryConfig
  });
};

// mutations
