import {
  toWSMoneyString,
  WSCard,
  WSElement,
  WSFlexBox,
  WSList,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useBankingBalance } from "../../../../query/bookkeeping/queries";
import { useCardLimits } from "../../../../query/cards/queries/useCardLimits";
import {
  ICardResponse,
  IPaymentCardLimitType
} from "../../../../services/api/banking/cards/types";

export const CardBalance: React.FC<{ card: ICardResponse }> = ({ card }) => {
  const queryBankingBalance = useBankingBalance();
  const queryCardLimits = useCardLimits(card.cardId);

  const availableBalance = queryBankingBalance.data?.available || 0;

  const limitType = queryCardLimits.data?.limitType || "Monthly";
  const limit = (queryCardLimits.data?.limitInCents || 0) / 100;
  const availableLimit =
    (queryCardLimits.data?.availableLimitInCents || 0) / 100;
  const spendLimit = limit - availableLimit;

  const limitTexts: Record<IPaymentCardLimitType, string> = {
    Monthly: `of ${toWSMoneyString(availableLimit)} daily limit`,
    Forever: `of ${toWSMoneyString(availableLimit)} limit`,
    Annually: `of ${toWSMoneyString(availableLimit)} annual limit`,
    Transaction: `of ${toWSMoneyString(availableLimit)} transaction limit`
  };

  return (
    <WSCard>
      <WSList gap="XL">
        <WSElement>
          <WSText weight="medium" color="gray600">
            Available balance
          </WSText>
          <WSText.Heading1
            weight="book"
            color="gray500"
            shimmer={!queryBankingBalance.isSuccess}
          >
            {toWSMoneyString(availableBalance)}
          </WSText.Heading1>
        </WSElement>

        <WSList>
          <WSFlexBox.CenterY justify="space-between">
            <WSText.ParagraphSm color="gray500">
              Daily spend limit
            </WSText.ParagraphSm>

            <WSPill theme="neutral" text="$1,250.00" />
          </WSFlexBox.CenterY>

          <WSFlexBox.CenterY justify="space-between">
            <WSText.ParagraphSm color="gray500">
              Monthly spend limit
            </WSText.ParagraphSm>

            <WSPill theme="neutral" text="$10,000.00" />
          </WSFlexBox.CenterY>
        </WSList>
      </WSList>
    </WSCard>
  );
};
