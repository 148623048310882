import { WSQueryCache } from "@ws-react-query";
import {
  FormDataPhoneNumber,
  phoneFormDataToE164
} from "../../modules/Onboarding/components/FormPartialPhoneNumber";
import { QUERY_USERS_MEMBER_PROFILE } from "../../query/users/keys";
import { updateCustomerData } from "../../services/api/onboarding/customer";
import { usersService } from "../../services/users";

type EntityRequest = {
  dba?: string;
  phone?: FormDataPhoneNumber;
  email?: string;
  website?: string;
  stateOfIncorporation?: string;

  address?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string | null;
    postalCode?: string;
    country?: string | null;
  };
};

class EntityDataService {
  async updateEntity(
    data: EntityRequest,
    options?: {
      userId?: string;
    }
  ) {
    await updateCustomerData("Entity", {
      customerData: {
        doingBusinessAs: data.dba || undefined,
        phoneNumber: data.phone ? phoneFormDataToE164(data.phone) : undefined,
        email: data.email || undefined,
        website: data.website || undefined,
        regionOfFormation: data.stateOfIncorporation || undefined,

        country: data.address?.country || undefined,
        addressLine1: data.address?.addressLine1 || undefined,
        addressLine2: data.address?.addressLine2 || undefined,
        city: data.address?.city || undefined,
        region: data.address?.state || undefined,
        postalCode: data.address?.postalCode || undefined
      }
    });

    if (options?.userId) {
      const userId = options.userId;

      const member = await usersService.member.get(userId);
      WSQueryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    }
  }
}

export const entityDataServiceService = new EntityDataService();
