import { toWSDateString } from "@wingspanhq/fe-component-library";
import { WSCardHeaderProps } from "@wingspanhq/fe-component-library/dist/lib/components/WSCard/WSCardHeader";
import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import merge from "lodash/merge";
import { useMemo } from "react";
import { DataView } from "../../../shared/components/DataView";
import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { formatPhone } from "../../../shared/utils/formatPhone";
import { getLast4 } from "../../../shared/utils/getLast4";
import { getTaxIdLabel } from "../../../shared/utils/taxId";
import {
  FormPartialDataPerson,
  FormPartialPersonType
} from "./FormPartialPerson";
import { getProfileDataPerson } from "./FormPartialPerson/getProfileDataPerson";

type Props = {
  title?: string;
  formData?: FormPartialDataPerson;
  onEdit?: () => void;
  type: FormPartialPersonType;
  showOwnershipStake?: boolean;
  user?: INewUser;
  member?: IMember;
  header?: WSCardHeaderProps;
};

export const ReviewPerson: React.FC<Props> = ({
  title,
  formData,
  onEdit,
  type,
  showOwnershipStake,
  user,
  member,
  header
}) => {
  const profileData = useMemo(() => {
    return getProfileDataPerson(type, user, member);
  }, [member, type, user]);

  const data = useMemo(() => {
    if (!formData) {
      return profileData;
    }

    return merge(profileData, formData);
  }, [formData, profileData]);

  return (
    <DataView
      title={title}
      onEdit={onEdit}
      header={header}
      data={[
        {
          label: "Legal first name",
          value: data.firstName,
          fsExclude: true
        },
        {
          label: "Middle name",
          value: data.middleName,
          fsExclude: true
        },
        {
          label: "Legal last name",
          value: data.lastName,
          fsExclude: true
        },
        {
          label: "Job title / occupation",
          value: data.jobTitle
        },
        showOwnershipStake
          ? {
              label: "Ownership stake",
              value: data.ownershipStake ? `${data.ownershipStake}%` : undefined
            }
          : null,
        {
          label: "Birthday",
          value: data.birthday
            ? toWSDateString(data.birthday, "monthDayYear")
            : undefined,
          fsExclude: true
        },
        type === "BeneficialOwner"
          ? {
              label: "Email",
              value: data.email
            }
          : null,
        type === "BeneficialOwner"
          ? {
              label: "Phone",
              value: data.phone
                ? formatPhone(
                    `+${data.phone.countryCode}${data.phone.phoneNumber}`
                  )
                : undefined
            }
          : null,
        {
          label: "Country",
          value: data.country ? selectorCountryName(data.country) : undefined
        },
        {
          label: getTaxIdLabel("Individual", data.country || "US"),
          value:
            !!formData?.taxId && typeof formData?.taxId === "string"
              ? `••• ${getLast4(formData.taxId)}`
              : member?.profile.ssnProvided
              ? "*********"
              : undefined,
          fsExclude: true
        },
        {
          label: "Residential address",
          value: data.address
            ? [
                data.address.addressLine1,
                data.address.addressLine2,
                [
                  data.address.city,
                  data.address.state,
                  data.address.postalCode
                ].join(" ")
              ]
                .filter(Boolean)
                .join("\n")
            : undefined,
          fsExclude: true
        }
      ]}
    />
  );
};
