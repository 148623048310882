import {
  IEngagement,
  IEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { updateEngagement } from "../../../services/engagements";
import {
  QUERY_ENGAGEMENT,
  QUERY_ENGAGEMENT_LIST,
  QUERY_ENGAGEMENT_LIST_SIZE
} from "../keys";

type EngagementUpdateRequest = IEngagementUpdateRequest & {
  engagementId: string;
};

export const useUpdateEngagement = (
  config?: WSMutationConfig<IEngagement, WSServiceError>
) =>
  useWSMutation(
    ({ engagementId, ...request }: EngagementUpdateRequest) =>
      updateEngagement(engagementId, request),
    {
      ...config,
      dependencies: [QUERY_ENGAGEMENT_LIST, QUERY_ENGAGEMENT_LIST_SIZE],
      awaitDependencies(result, { engagementId }) {
        return [[QUERY_ENGAGEMENT, engagementId]];
      }
    }
  );
