import {
  toWSMoneyString,
  useWSSnackbar,
  WSCard,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useRedeemCashbackBalance } from "../../../query/bookkeeping/mutations";
import {
  useBankingBalance,
  usePendingCashbackBalance,
  usePostedCashbackBalance
} from "../../../query/bookkeeping/queries";
import { useCashbackDetailsModal } from "../modals/useCashbackDetailsModal";
import { InfoTooltip } from "../../../shared/components/InfoTooltip/InfoTooltip";
import { TextBulleted } from "../../../shared/components/TextBulleted";

export const CashbackBalance: React.FC = () => {
  const history = useHistory();
  const queryBankingBalance = useBankingBalance();
  const cashbackDetailsModal = useCashbackDetailsModal();
  const queryPostedCashbackBalance = usePostedCashbackBalance();
  const queryPendingCashbackBalance = usePendingCashbackBalance();

  const [redeemCashbackBalance] = useRedeemCashbackBalance();

  const cashbackBalance =
    queryPostedCashbackBalance.data?.postedCashbackBalance || 0;
  const currentBalance =
    queryPostedCashbackBalance.data?.postedCashbackBalance || 0;

  const pendingBalance =
    queryPendingCashbackBalance.data?.pendingCashbackBalance || 0;

  const availableBalance = queryBankingBalance.data?.available || 0;

  const { openSnackbar } = useWSSnackbar();

  const isLoading =
    queryPendingCashbackBalance.isInitialLoading ||
    queryPostedCashbackBalance.isInitialLoading;

  const showWarning =
    !isLoading && (availableBalance < 100 || pendingBalance === 0);

  return (
    <WSCard
      shimmer={isLoading}
      fullHeight
      header={{
        value: {
          action: {
            label: "Details",
            rightIcon: "chevron-right",
            onClick: () => {
              cashbackDetailsModal.open();
            }
          }
        },
        label: {
          text: "Cashback balance"
        }
      }}
      divider
      actions={{
        alignment: "fill",
        sortButtons: false,
        buttons: [
          {
            label: "Redeem",
            kind: "Secondary",
            disabled: !cashbackBalance,
            onAsyncClick: async () => {
              await redeemCashbackBalance(
                {},
                {
                  onSuccess() {
                    openSnackbar({
                      type: "success",
                      message: "Cashback successfully sent!"
                    });
                  },
                  onError() {
                    openSnackbar({
                      type: "warning",
                      message: "Failed to send cashback!"
                    });
                  }
                }
              );
            }
          }
          /*
          {
            label: "View transactions",
            kind: "Link",
            disabled: !cashbackBalance,
            rightIcon: "chevron-right",
            onClick: () => {
              history.push("/member/wallet/transactions");
            }
          }
          */
        ]
      }}
    >
      <WSText.Heading1
        weight="book"
        color={cashbackBalance > 0 ? "green500" : "gray200"}
        mb="XL"
      >
        {toWSMoneyString(cashbackBalance)}
      </WSText.Heading1>

      <WSFlexBox.CenterY justify="space-between" mb="M">
        <WSText.ParagraphSm color="gray500">Current balance</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray600">
          {toWSMoneyString(currentBalance)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY justify="space-between">
        <WSFlexBox.CenterY>
          <WSText.ParagraphSm color="gray500" mr="S">
            Pending amounts
          </WSText.ParagraphSm>
          <InfoTooltip
            text={
              <>
                Cashback: pending amounts
                <TextBulleted>
                  <li>
                    Pending amounts from eligible purchases during the current
                    statement period.
                  </li>
                  <li>
                    Maintain $100+ average daily balance in your Wingspan Wallet
                    to earn cash back.
                  </li>
                  <li>
                    Returned or canceled purchases will reduce the eligible
                    cashback amounts.
                  </li>
                  <li>
                    Cashback monthly total posts to your account 5 business days
                    after each monthly cycle closes when all transactions are
                    settled in full.
                  </li>
                </TextBulleted>
              </>
            }
          />
        </WSFlexBox.CenterY>
        <WSText.ParagraphSm color="gray500">
          {toWSMoneyString(pendingBalance)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      {/*<WSText.ParagraphXs color="gray500">
        Period ending {"TODO"}
      </WSText.ParagraphXs>*/}
      {showWarning ? (
        <WSPanel noBorder colorBackground="amber50" mt="L" p="M">
          <WSText.ParagraphXs color="gray600">
            You’re missing out on cardholder benefits! To earn cashback—use your
            card on eligible purchases every month and keep your average daily
            balance above $100.
          </WSText.ParagraphXs>
        </WSPanel>
      ) : null}
    </WSCard>
  );
};
