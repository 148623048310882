import {
  useWSModal,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";

export interface RecipientNotConfirmedW9InfoProps {
  taxForm: IPayeeTaxFormResponse;
  onClose: () => void;
}

const RecipientNotConfirmedW9Info: React.FC<
  RecipientNotConfirmedW9InfoProps
> = ({ onClose, taxForm }) => {
  const payerName = selectorPayeeTaxFormPayerName(taxForm);
  const currentYear = taxForm.year;

  return (
    <WSList gap="L">
      <WSInfoBox>
        <WSText.ParagraphSm>
          You were not issued a digital Form 1099-NEC from {payerName} for tax
          year {currentYear} because taxpayer information was not shared through
          the Wingspan platform. The payer used the information they had on file
          for this year’s tax filing. You will receive a copy of the form in the
          mail based on the address on file.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm>
          Please contact the payer for additional support.
        </WSText.ParagraphSm>
      </WSInfoBox>
    </WSList>
  );
};

export const useModalRecipientNotConfirmedW9Info = () =>
  useWSModal(RecipientNotConfirmedW9Info, {
    title: "Taxpayer information was not shared",
    size: "S"
  });
