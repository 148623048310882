import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { Form } from "./Form";
import { ManualAccountSubType, ManualAccountType } from "./types";
import { useMutationSubmit } from "./useMutationSubmit";

export type FormManuallyAddAccountProps = {
  rtpRequired?: boolean;
  onBack?: () => void;
  onSuccess?: (account: IAccount) => void;
  submitButtonText?: string;
  setStandardPayoutDestination?: boolean;
  setInstantPayoutDestination?: boolean;
  setDefaultPaymentMethod?: boolean;
  setFundingSource?: boolean;
  requestVerification?: boolean;
  withPanel?: boolean;
  type?: ManualAccountType;
  subType?: ManualAccountSubType;
};

export const FormManuallyAddAccount: React.FC<FormManuallyAddAccountProps> = ({
  onSuccess,
  onBack,
  submitButtonText,
  rtpRequired,
  setStandardPayoutDestination,
  setInstantPayoutDestination,
  setDefaultPaymentMethod,
  setFundingSource,
  requestVerification,
  withPanel,
  type,
  subType
}) => {
  const [submit, submitMeta] = useMutationSubmit(
    {
      setStandardPayoutDestination,
      setInstantPayoutDestination,
      requestVerification,
      setDefaultPaymentMethod,
      setFundingSource,
      type,
      subType
    },
    { onSuccess }
  );

  return (
    <Form
      rtpRequired={rtpRequired}
      onSubmit={submit}
      submitButtonText={submitButtonText}
      error={submitMeta.error}
      isLoading={submitMeta.isLoading}
      withPanel={withPanel}
      onBack={onBack}
      typeProvided={!!type}
    />
  );
};
