import {
  toWSDateString,
  WSButton,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { DetailsLayout } from "../../components/DetailsLayout/DetailsLayout";
import { UrlIdKey } from "../../constants/routing";
import { getPayerName } from "../../modules/Payers/utils/getPayerNames";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import {
  useDownloadInvoicePdf,
  useDownloadInvoiceReceiptPdf,
  useSendInvoice,
  useSendInvoiceReminder
} from "../../query/payments/mutations";
import { useInvoiceQuery } from "../../query/payments/queries";
import { getIsInstantPayoutAllowed } from "../../query/payments/selectors";
import { useMemberProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { openIntercom } from "../../shared/utils/intercom";
import { useSetWSStore } from "../../store";
import { copyText } from "../../utils/copyText";
import { InvoiceActivity } from "../components/InvoiceActivity";
import { InvoiceDetails } from "../components/InvoiceDetails";
import { InvoiceDetailsHeader } from "../components/InvoiceDetailsHeader/InvoiceDetailsHeader";
import { InvoicePayments } from "../components/InvoicePayments";
import { InvoiceRefunds } from "../components/InvoiceRefunds";
import { QuickbooksHistory } from "../components/QuickbooksHistory/QuickbooksHistory";
import { QuickbooksWrapper } from "../components/QuickbooksWrapper/QuickbooksWrapper";
import { calculateLineItemsTotal } from "../utils";
import { calculateAmountAvailableToRefund } from "../utils/calculateAmountAvailableToRefund";
import { getInvoicePayoutSummary } from "../utils/invoicePayouts";

type Props = RouteComponentProps<
  {
    invoiceId: string;
    year: string;
    listName?: string;
  },
  {},
  { backPath?: string }
>;

export const RouteInvoiceDetails: React.FC<Props> = ({
  match,
  location,
  history
}) => {
  const userId = useUserId();
  const invoiceId = match.params.invoiceId;

  const queryInvoice = useInvoiceQuery(invoiceId);
  const queryPayer = usePayerQuery(queryInvoice.data?.clientId!, {
    enabled: !!queryInvoice.data?.clientId
  });
  const queryMember = useMemberProfile(userId);
  const queryFeatureFlags = useFeatureFlags();

  const year = parseInt(
    match.params[UrlIdKey.Year]
  ) as keyof ICollaboratorSchema["form1099Balances"];
  const is1099Flow = !isNaN(year);

  const [sendInvoice, sendInvoiceMeta] = useSendInvoice();
  const [sendInvoiceReminder, sendInvoiceReminderMeta] =
    useSendInvoiceReminder();
  const [downloadInvoiceReceiptPdf, downloadInvoiceReceiptPdfMeta] =
    useDownloadInvoiceReceiptPdf();

  const setStore = useSetWSStore();

  return (
    <DetailsLayout
      onBack={() => {
        history.push({
          pathname: location.state?.backPath
            ? location.state.backPath
            : `/member/invoices${
                match.params.listName ? `/${match.params.listName}` : ""
              }`,
          search: location.search
        });
      }}
      title={
        queryInvoice.isLoading
          ? ""
          : `Invoice #${queryInvoice.data?.invoiceNumber || " Pending"}`
      }
    >
      <WSQueries
        queries={{
          queryInvoice,
          queryPayer,
          queryMember,
          queryFeatureFlags
        }}
      >
        {({
          queryInvoice: { data: invoice },
          queryPayer: { data: payer },
          queryMember: { data: member },
          queryFeatureFlags: { data: featureFlags }
        }) => {
          const payerEmail = payer.user?.email;

          const editButtonProps = {
            children: "Edit",
            onClick: () => {
              setStore({ invoiceFormBackPath: location.pathname });
              history.push(`/member/invoices/${invoice.invoiceId}/edit`);
            }
          };

          const amountAvailableToRefund =
            calculateAmountAvailableToRefund(invoice);

          const payoutDestinationsText = getInvoicePayoutSummary(invoice);

          return (
            <WSList gap="XL">
              <Card>
                <WSText.Heading4 mb="M">{getPayerName(payer)}</WSText.Heading4>
                {invoice.events.refundedAt ? (
                  <InvoiceDetailsHeader
                    title="You got paid"
                    description={
                      amountAvailableToRefund > 0
                        ? `Partial refund • ${toWSDateString(
                            invoice.events.refundedAt,
                            "monthDate"
                          )}`
                        : `Full refund • ${toWSDateString(
                            invoice.events.refundedAt,
                            "monthDate"
                          )}`
                    }
                    status="Deposited"
                    buttonProps={{
                      children: "Download Receipt PDF",
                      onClick: () => {
                        downloadInvoiceReceiptPdf({
                          invoiceId: invoice.invoiceId
                        });
                      },
                      loading: downloadInvoiceReceiptPdfMeta.isLoading
                    }}
                    invoice={invoice}
                  />
                ) : invoice.events.depositedAt ||
                  invoice.events.instantPayoutAt ? (
                  <InvoiceDetailsHeader
                    title="You got paid"
                    description={payoutDestinationsText}
                    status="Deposited"
                    buttonProps={{
                      children: "Download Receipt PDF",
                      onClick: () => {
                        downloadInvoiceReceiptPdf({
                          invoiceId: invoice.invoiceId
                        });
                      },
                      loading: downloadInvoiceReceiptPdfMeta.isLoading
                    }}
                    invoice={invoice}
                  />
                ) : invoice.events.paidAt ? (
                  <InvoiceDetailsHeader
                    title={
                      invoice.amountDetails
                        ? "Your money is on its way!"
                        : "Invoice was paid outside of Wingspan"
                    }
                    description={`Paid ${toWSDateString(
                      invoice.events.paidAt,
                      "monthDayYear"
                    )}`}
                    status="Paid"
                    buttonProps={
                      getIsInstantPayoutAllowed(invoice, member)
                        ? {
                            children: "Request instant payout",
                            onClick: () => {
                              history.push(
                                `${location.pathname}/instant-deposit`
                              );
                            }
                          }
                        : {
                            children: "Download Receipt PDF",
                            onClick: () => {
                              downloadInvoiceReceiptPdf({
                                invoiceId: invoice.invoiceId
                              });
                            },
                            loading: downloadInvoiceReceiptPdfMeta.isLoading
                          }
                    }
                    invoice={invoice}
                  />
                ) : invoice.status === InvoiceStatus.PaymentInTransit ? (
                  <InvoiceDetailsHeader
                    title="Waiting on client's wire transfer to arrive"
                    description="Your money is on its way!"
                    status="Paid"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                  />
                ) : invoice.events.emailViewedAt &&
                  invoice.events.emailViewedAt.length > 0 &&
                  invoice.status === InvoiceStatus.Overdue ? (
                  <InvoiceDetailsHeader
                    title="Invoice payment is overdue"
                    description="We recommend personally following up"
                    status="Viewed"
                    buttonProps={{
                      children: "Email client",
                      onClick: () => {
                        sendInvoiceReminder({ invoice });
                      },
                      loading: sendInvoiceReminderMeta.isLoading
                    }}
                    icon="info-circle"
                    iconColor="red400"
                    invoice={invoice}
                  />
                ) : invoice.events.emailViewedAt &&
                  invoice.events.emailViewedAt.length > 0 ? (
                  <InvoiceDetailsHeader
                    title="Your invoice has been viewed"
                    description={`Seen by ${payerEmail}`}
                    status="Viewed"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                  />
                ) : invoice.status === InvoiceStatus.Overdue ? (
                  <InvoiceDetailsHeader
                    title="Invoice payment is overdue"
                    description="We recommend personally following up"
                    status="Sent"
                    buttonProps={{
                      children: "Email client",
                      onClick: () => {
                        sendInvoiceReminder({ invoice });
                      },
                      loading: sendInvoiceReminderMeta.isLoading
                    }}
                    icon="info-circle"
                    iconColor="red400"
                    invoice={invoice}
                  />
                ) : invoice.status === InvoiceStatus.Open ? (
                  <InvoiceDetailsHeader
                    title="Your invoice has been sent"
                    description={`Sent to ${payerEmail}`}
                    status="Sent"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                  />
                ) : invoice.status === InvoiceStatus.Pending ? (
                  <InvoiceDetailsHeader
                    title="This is pending"
                    description={
                      <>
                        <WSText>Will be sent to {payerEmail}</WSText>

                        <WSButton.Link my="M" onClick={openIntercom}>
                          Ask about pending invoices
                        </WSButton.Link>
                      </>
                    }
                    buttonProps={editButtonProps}
                    invoice={invoice}
                  />
                ) : (
                  <InvoiceDetailsHeader
                    title="This is a draft"
                    description="Send now or schedule for later"
                    buttonProps={
                      calculateLineItemsTotal(invoice.lineItems) > 0
                        ? {
                            children: "Send now",
                            onClick: () => {
                              sendInvoice({
                                invoiceId
                              });
                            },
                            loading: sendInvoiceMeta.isLoading
                          }
                        : editButtonProps
                    }
                    invoice={invoice}
                  />
                )}
              </Card>

              <InvoiceDetails
                invoice={invoice}
                payer={payer}
                is1099Flow={is1099Flow}
              />

              {featureFlags.invoicePaymentsDetails &&
                invoice.payments &&
                invoice.payments.length > 0 && (
                  <Card>
                    <WSText.Heading5 mb="XL">Payments</WSText.Heading5>
                    <InvoicePayments invoice={invoice} />
                  </Card>
                )}

              {featureFlags.refundsPane &&
                invoice.refundDestinations &&
                invoice.refundDestinations.length > 0 && (
                  <Card>
                    <WSText.Heading5 mb="XL">Refunds</WSText.Heading5>
                    <InvoiceRefunds invoice={invoice} />
                  </Card>
                )}

              <InvoiceActivity invoice={invoice} payer={payer} />

              <QuickbooksWrapper>
                <QuickbooksHistory
                  entityId={invoice.invoiceId}
                  Wrapper={Card}
                />
              </QuickbooksWrapper>
            </WSList>
          );
        }}
      </WSQueries>
    </DetailsLayout>
  );
};
