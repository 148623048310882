import {
  WSMessageBox,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const TaxesModalTips: React.FC = () => (
  <WSModalOld name="tips">
    <WSText.Heading4 mb="M">Choose the best tax rate for you.</WSText.Heading4>
    <WSText mb="M">
      Your tax rate is the percentage of your pay that gets set aside for taxes.
      It’s always an estimate.
    </WSText>
    <WSMessageBox.Regular mb="M" noBorder>
      Wingspan’s recommended tax rate is the most accurate projection for the
      exact amount you’ll need to pay the IRS.
    </WSMessageBox.Regular>
    <WSText weight="medium" mb="M">
      Worried you’ll spend too much now and not have enough for taxes later?
      Increase your rate.
    </WSText>
    <WSText mb="M">
      Increasing your tax rate is the conservative choice. It means an
      above-average portion of your pay will be set aside for taxes. You’ll have
      less money flowing into your direct deposit, but you’re more likely to get
      a tax refund later.
    </WSText>
    <WSText weight="medium" mb="M">
      Does having money now outweigh being prepared for tax season? Decrease
      your rate.
    </WSText>
    <WSText mb="M">
      Decreasing your tax rate is the agressive choice. It means a below-average
      portion of your pay will be set aside for taxes. You’ll have more money
      flowing into your direct deposit, but you’re more likely to pay more
      later.
    </WSText>
  </WSModalOld>
);
