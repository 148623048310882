import {
  WSChip,
  WSDateRangeSelectOptions,
  WSElement,
  WSElementProps,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSInputDateOld,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { formatNumber } from "accounting";
import React, { useMemo } from "react";
import { InvoicesDateRangeFilter } from "../../../../query/payments/queries";
import { SelectPayer } from "../../../Payers/components/SelectPayer";
import styles from "./index.module.scss";

export const getDateRange = (
  dateRange?: InvoicesDateRangeFilter,
  custom?: Date[]
) => {
  switch (dateRange) {
    case InvoicesDateRangeFilter.Custom: {
      return custom;
    }
    case InvoicesDateRangeFilter.PastWeek: {
      return WSDateRangeSelectOptions.PastWeek.range;
    }
    case InvoicesDateRangeFilter.PastMonth: {
      return WSDateRangeSelectOptions.PastMonth.range;
    }
    case InvoicesDateRangeFilter.PastYear: {
      return WSDateRangeSelectOptions.Past12Months.range;
    }
    default:
      return undefined;
  }
};

export interface Filters {
  memberId?: string;
  clientId?: string;
  memberClientId: string;
  dueDateRange?: InvoicesDateRangeFilter;
  customDueDateRange?: Date[];
  paidDateRange?: InvoicesDateRangeFilter;
  customPaidDateRange?: Date[];
  search?: string;
}

type ToolbarProps = {
  filters?: Filters;
  setFilters?: (newFilters: Filters) => void;
  count?: number;
  totalAmount?: number;
  defaultFilters?: Filters;
} & WSElementProps;

export const Toolbar: React.FC<ToolbarProps> = ({
  filters,
  setFilters,
  count,
  totalAmount,
  defaultFilters,
  ...elementProps
}) => {
  const filtersWithoutSearch = useMemo(() => {
    const filtersWithoutSearch = { ...filters };
    delete filtersWithoutSearch.search;
    return filtersWithoutSearch;
  }, [filters]);

  return (
    <WSElement {...elementProps} className={styles.toolbar}>
      {filters && setFilters ? (
        <WSFiltersOld
          filters={filtersWithoutSearch}
          defaultFilters={defaultFilters}
          onFilter={newFilters =>
            setFilters({
              ...filters,
              ...newFilters
            })
          }
        >
          <WSFormOld.Field
            name="clientId"
            label="Client"
            mb="2XL"
            component={SelectPayer}
          />

          {defaultFilters?.dueDateRange && (
            <WSFormOld.Field
              name="dueDateRange"
              label="Due date range"
              mb="2XL"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    label: "All time",
                    value: InvoicesDateRangeFilter.All
                  },
                  {
                    label: "Past week",
                    value: InvoicesDateRangeFilter.PastWeek
                  },
                  {
                    label: "Past month",
                    value: InvoicesDateRangeFilter.PastMonth
                  },
                  {
                    label: "Past year",
                    value: InvoicesDateRangeFilter.PastYear
                  },
                  {
                    label: (
                      <WSFormOld.Context>
                        {({ setValue }) => (
                          <WSFlexBox alignItems="center">
                            <WSText mr="M">Custom range</WSText>
                            <WSFormOld.Field
                              name="customDueDateRange"
                              component={WSInputDateOld}
                              componentProps={{
                                selectionMode: "range",
                                onFocus() {
                                  setValue(
                                    "dueDateRange",
                                    InvoicesDateRangeFilter.Custom
                                  );
                                }
                              }}
                            />
                          </WSFlexBox>
                        )}
                      </WSFormOld.Context>
                    ),
                    value: InvoicesDateRangeFilter.Custom
                  }
                ]
              }}
            />
          )}
          {defaultFilters?.paidDateRange && (
            <WSFormOld.Field
              name="paidDateRange"
              label="Paid date range"
              mb="2XL"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    label: "All time",
                    value: InvoicesDateRangeFilter.All
                  },
                  {
                    label: "Past week",
                    value: InvoicesDateRangeFilter.PastWeek
                  },
                  {
                    label: "Past month",
                    value: InvoicesDateRangeFilter.PastMonth
                  },
                  {
                    label: "Past year",
                    value: InvoicesDateRangeFilter.PastYear
                  },
                  {
                    label: (
                      <WSFormOld.Context>
                        {({ setValue }) => (
                          <WSFlexBox alignItems="center">
                            <WSText mr="M">Custom range</WSText>
                            <WSFormOld.Field
                              name="customPaidDateRange"
                              component={WSInputDateOld}
                              componentProps={{
                                selectionMode: "range",
                                onFocus() {
                                  setValue(
                                    "paidDateRange",
                                    InvoicesDateRangeFilter.Custom
                                  );
                                }
                              }}
                            />
                          </WSFlexBox>
                        )}
                      </WSFormOld.Context>
                    ),
                    value: InvoicesDateRangeFilter.Custom
                  }
                ]
              }}
            />
          )}
        </WSFiltersOld>
      ) : (
        <WSElement></WSElement>
      )}
      {count !== undefined && (
        <WSFlexBox alignItems="center" wrap="nowrap">
          <WSText.ParagraphXs color="gray500" mr="M">
            Showing
          </WSText.ParagraphXs>

          <WSChip
            pillType="noBackground"
            pillText={`${formatNumber(count)} item${count === 1 ? "" : "s"}`}
            rightText={totalAmount}
          />
        </WSFlexBox>
      )}
    </WSElement>
  );
};
