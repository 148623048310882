import { WSMutationConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYER_ENGAGEMENT, QUERY_PAYER_ENGAGEMENTS_LIST } from "../keys";
import {
  IPayerEngagementResponse,
  IPayerEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { updatePayerEngagement } from "../../../services/payerEngagements";
import { QUERY_PAYER_ROWS } from "../../search/payer/queries/useQueryPayerRows";

type IPayerEngagementUpdateParams = IPayerEngagementUpdateRequest & {
  payerId: string;
  engagementId: string;
};

export const useUpdatePayerEngagement = (
  config?: WSMutationConfig<IPayerEngagementResponse, WSServiceError>
) =>
  useWSMutation(
    (request: IPayerEngagementUpdateParams) => {
      const { engagementId, payerId, ...rest } = request;
      return updatePayerEngagement(payerId, engagementId, rest);
    },
    {
      ...config,
      dependencies: [
        QUERY_PAYER_ENGAGEMENT,
        QUERY_PAYER_ENGAGEMENTS_LIST,
        QUERY_PAYER_ROWS
      ]
    }
  );
