import {
  toWSDateString,
  WSEmptyState,
  WSFilters,
  WSInfiniteScroll,
  WSPage,
  WSPageToolbar,
  WSSelect,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableColumnSortDirection
} from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../query/WSQuery";
import React, { useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  ARCHIVED_STATEMENTS,
  LAST_STATEMENT_YEAR,
  MAIN_FILTER_OPTIONS
} from "../utils/constants";
import styles from "./styles.module.scss";
import { useInfinityStatementsQuery } from "../../../query/bankStatements/queries/useStatementsQuery";
import { useInternalAccountsForStatements } from "../queries/useInternalAccountsForStatements";
import { IStatementResponse } from "../../../services/api/banking/statements/types";
import { BankingInternalAccount } from "../../../services/api/banking/internalAccounts";
import { convertDateStringToUTCMidday } from "../../../utils/dates";
import { accountTypeToHumanReadable } from "../utils/accountTypeToHumanReadable";
import { filesService } from "../../../services/files";
import { downloadFileFromBuffer } from "../../../utils/files";
import {
  createRequest,
  SortOrderType
} from "../../../query/bankStatements/queries/filtering";
import { useDownloadBankStatements } from "../../../query/bankStatements/mutations/useDownloadBankStatements";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { UrlQueryFilter, useUrlQuery } from "../../../shared/hooks/useUrlQuery";
import { InternalAccountType } from "../../../services/api/banking/types";

export interface StatementsFilters extends UrlQueryFilter {
  accountType?: InternalAccountType[];
}

export type ViewInternalAccountStatement = IStatementResponse & {
  internalAccount: BankingInternalAccount;
};

export type StatementQuerySort = {
  internalAccountType?: SortOrderType;
  startDate?: SortOrderType;
  // accountNumber?: SortOrderType;
};

export const RouteStatements: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  const { urlQuery, setUrlQuery } = useUrlQuery<
    StatementsFilters,
    keyof StatementQuerySort
  >();

  const [type, setType] = useState<string>(LAST_STATEMENT_YEAR);

  const queryFeatureFlags = useFeatureFlags();
  const isShowImportedUnitStatements =
    queryFeatureFlags.data?.showImportedUnitStatements;

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const request = useMemo(
    () => createRequest(urlQuery.filter, type, urlQuery.sort),
    [urlQuery.filter, type, urlQuery.sort]
  );

  const queryBankStatements = useInfinityStatementsQuery(request);

  const internalAccountIds =
    queryBankStatements.data?.map(statement => statement.internalAccountId) ||
    [];

  const queryInternalAccounts = useInternalAccountsForStatements(
    internalAccountIds,
    {
      enabled: !!internalAccountIds.length
    }
  );

  const onSort = (
    field: keyof StatementQuerySort,
    direction: WSTableColumnSortDirection
  ) => {
    setUrlQuery(prev => ({
      ...prev,
      sort: {
        field: field,
        direction
      }
    }));
  };

  const getSortDirection = (field: keyof StatementQuerySort) =>
    urlQuery.sort?.field === field ? urlQuery.sort?.direction : undefined;

  const columns: Array<WSTableColumn<ViewInternalAccountStatement>> = [
    {
      config: {
        header: {
          text: "Statement period"
        },
        gridTemplateSizeMax: "1fr",
        sortDirection: getSortDirection("startDate"),
        onColumnSort: direction => {
          onSort("startDate", direction);
        }
      },
      renderFunction: ({ data }) => (
        <WSTableCell
          text={`${toWSDateString(
            convertDateStringToUTCMidday(data.startDateString),
            "monthDayYear"
          )} - ${toWSDateString(
            convertDateStringToUTCMidday(data.endDateString),
            "monthDayYear"
          )}`}
        />
      )
    },
    {
      config: {
        header: {
          text: "Account number"
        },
        gridTemplateSizeMax: "1fr",
        hideOnScreens: ["XS"]
        // sortDirection: getSortDirection("accountNumber"),
        // onColumnSort: direction => {
        //   onSort("accountNumber", direction);
        // }
      },
      renderFunction: ({ data }) => (
        <WSTableCell text={data.internalAccount?.numbers?.account || "N/A"} />
      )
    },
    {
      config: {
        header: {
          text: "Account type"
        },
        gridTemplateSizeMax: "1fr",
        sortDirection: getSortDirection("internalAccountType"),
        onColumnSort: direction => {
          onSort("internalAccountType", direction);
        }
      },
      renderFunction: ({ data }) => (
        <WSTableCell
          text={accountTypeToHumanReadable(data.internalAccountType)}
        />
      )
    },
    {
      config: {
        header: {
          text: "Partner bank"
        },
        hideOnScreens: ["XS"],
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => {
        return <WSTableCell text={"Lead Bank"} />;
      }
    }
  ];

  const [download, downloadMeta] = useDownloadBankStatements();

  const downloadArchiveStatements = async (id: string) => {
    try {
      const file = await filesService.member.private.get(id);
      const data = await filesService.member.private.download(id);

      downloadFileFromBuffer(data, file.filename, file.mimetype);
    } catch {
      //download failed, do nothing
    }
  };

  return (
    <WSPage>
      <WSPageToolbar
        title={"Statements"}
        titleAfter={
          <WSSelect
            hideClearAction={true}
            mode="single"
            value={type}
            onChange={period => {
              if (
                !isShowImportedUnitStatements &&
                period === ARCHIVED_STATEMENTS
              ) {
                history.push(`/member/statements/Archived`);
              } else {
                setType(period as string);
              }
            }}
            options={MAIN_FILTER_OPTIONS}
          />
        }
      />
      <WSQueries queries={{ queryBankStatements, queryMember }}>
        {({ queryBankStatementsData, queryMemberData }) => {
          const tableList = queryBankStatementsData.map(bankStatement => ({
            id: bankStatement.accountStatementId,
            data: {
              ...bankStatement,
              internalAccount: queryInternalAccounts.data?.find(
                account =>
                  account.internalAccountId === bankStatement.internalAccountId
              )!
            }
          }));

          return (
            <>
              <WSFilters<StatementsFilters>
                values={urlQuery.filter}
                onFilter={newFilter => {
                  setUrlQuery(prev => ({ ...prev, filter: newFilter }));
                }}
                info={{ count: tableList.length }}
                primaryFilters={[
                  {
                    name: "accountType",
                    title: "Account Type",
                    type: "checkbox",
                    options: [
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.Clearing
                        ),
                        name: InternalAccountType.Clearing
                      },
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.TaxWithholding
                        ),
                        name: InternalAccountType.TaxWithholding
                      },
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.Banking
                        ),
                        name: InternalAccountType.Banking
                      }
                    ]
                  }
                ]}
                quickFilters={[
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.Clearing
                    ),
                    preset: {
                      accountType: [InternalAccountType.Clearing]
                    },
                    shortLabel: "Clearing",
                    theme: "neutral"
                  },
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.TaxWithholding
                    ),
                    preset: {
                      accountType: [InternalAccountType.TaxWithholding]
                    },
                    shortLabel: "Tax Withholding",
                    theme: "neutral"
                  },
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.Banking
                    ),
                    preset: {
                      accountType: [InternalAccountType.Banking]
                    },
                    shortLabel: "Wingspan Wallet",
                    theme: "neutral"
                  }
                ]}
              />
              {queryBankStatementsData.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No statement available yet"
                  description="Statements will appear here once available."
                />
              ) : tableList.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No results"
                  description="Try adjusting your filters to find what you're looking for. Clear filters to see all items."
                  buttons={[
                    {
                      label: "Clear filters",
                      kind: "Secondary",
                      onClick: () => {
                        setUrlQuery({ filter: {} });
                      }
                    }
                  ]}
                />
              ) : (
                <WSInfiniteScroll
                  mt="M"
                  onLoad={() => {
                    queryBankStatements.fetchNextPage();
                  }}
                  loadMore={queryBankStatementsData.length > 0}
                  endOfList={!queryBankStatements.hasNextPage}
                  loading={!!queryBankStatements.isFetchingNextPage}
                >
                  <WSTable<ViewInternalAccountStatement>
                    mt="XL"
                    loading={queryInternalAccounts.isLoading}
                    showHeader={true}
                    rowActions={({ data }) => [
                      {
                        label: " ",
                        text: "Download",
                        icon: "download",
                        className: styles.downloadIcon,
                        disabled: downloadMeta.isLoading,
                        hideOn: [],
                        onAsyncClick: async () => {
                          if (type === ARCHIVED_STATEMENTS) {
                            if (data.fileId) {
                              await downloadArchiveStatements(data.fileId);
                            }
                          } else {
                            await download({
                              statementId: data.accountStatementId,
                              member: queryMemberData
                            });
                          }
                        }
                      }
                    ]}
                    columns={columns}
                    tableData={tableList}
                  />
                </WSInfiniteScroll>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
