import { WSMutationConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYEE } from "../../payee/keys";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
  QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE
} from "../keys";
import {
  IPayeeEngagementResponse,
  IPayeeEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { updatePayeeEngagement } from "../../../services/payeeEngagement";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

type IPayeeEngagementUpdateParams = IPayeeEngagementUpdateRequest & {
  payeeId: string;
  engagementId: string;
};

export const useUpdatePayeeEngagement = (
  config?: WSMutationConfig<IPayeeEngagementResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();
  return useWSMutation(
    (request: IPayeeEngagementUpdateParams) => {
      const { engagementId, payeeId, ...rest } = request;
      return updatePayeeEngagement(
        payeeId,
        engagementId,
        rest,
        xWingspanPayerId
      );
    },
    {
      ...config,
      dependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        QUERY_PAYEE,
        QUERY_PAYEE_ROWS,
        QUERY_PAYEE_ROWS_SUMMARY
      ]
    }
  );
};
