import { WSQueryCache } from "@ws-react-query";
import { FlowMEChangeContext } from "..";
import { useWSMutation, WSMutationConfig } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_VERIFICATION_MISSING_DATA } from "../../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../../../../../query/onboarding/queries/useQueryVerifications";
import {
  createCustomerEntity,
  getCustomerEntity,
  requestCustomerEntityChange,
  updateCustomerData
} from "../../../../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../../../../services/api/onboarding/missingFields";
import {
  getVerifications,
  performVerification
} from "../../../../../services/api/onboarding/verifications";
import { usersService } from "../../../../../services/users";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { verificationService } from "../../../../../shared/services/verificaiton";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import {
  getBusinessData,
  getIndividualData,
  getRepresentativeData
} from "../../../steps/CreateAccount/helpers";

export const useMutationSubmitMaterial = (
  config?: WSMutationConfig<void, WSServiceError, any>
) => {
  const userId = useUserId();
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();

  return useWSMutation(async () => {
    if (!context.accountType?.country) {
      throw new Error("No country set");
    }

    const verificationLevel = "Tax";

    const user = await usersService.user.get(userId);

    const initialVerifications = await getVerifications();
    const initialVerificationStatus = verificationService.getVerificationStatus(
      verificationLevel,
      initialVerifications
    );
    const currentEntity = await getCustomerEntity();
    const dataMatchesCurrentEntity =
      currentEntity.type === context.accountType.type &&
      currentEntity.country === context.accountType.country;

    // 1. Request change
    if (context.accountType?.type === "Individual") {
      // 1.1. Request change for individual
      if (!context.personalInformation) {
        throw new Error("No personal information set");
      }

      const individualData = await getIndividualData(
        context.personalInformation,
        user,
        verificationLevel,
        true
      );

      if (initialVerificationStatus === "Verified") {
        // Current entity is verified, so we have to request a change instead of creating new entity
        await requestCustomerEntityChange({
          changeType: "Material",
          effectiveAt: context.reason?.effectiveAt,
          reason: context.reason?.reason,
          type: "Individual",
          country: context.accountType.country,
          customerData: individualData
        });
      } else {
        if (!dataMatchesCurrentEntity) {
          // Create a new entity if type and country don't match
          await createCustomerEntity({
            type: "Individual",
            country: context.accountType.country
          });
        }

        await updateCustomerData("Entity", {
          customerData: individualData
        });
      }
    } else {
      // 1.2. Request change for business

      if (!context.businessInformation) {
        throw new Error("No business information set");
      }

      if (!context.representative) {
        throw new Error("No representative set");
      }

      // 1.2.1 Make change request
      const businessData = getBusinessData(
        context.businessInformation,
        context.accountType.country,
        user.email
      );

      if (initialVerificationStatus === "Verified") {
        // Current entity is verified, so we have to request a change instead of creating new entity
        await requestCustomerEntityChange({
          changeType: "Material",
          effectiveAt: context.reason?.effectiveAt,
          reason: context.reason?.reason,
          type: "Business",
          country: context.accountType.country,
          customerData: businessData
        });
      } else {
        if (!dataMatchesCurrentEntity) {
          // Create a new entity if type and country don't match
          await createCustomerEntity({
            type: "Business",
            country: context.accountType.country
          });
        }

        await updateCustomerData("Entity", {
          customerData: businessData
        });
      }

      // 1.2.2 Follow up with update for representative
      const representativeData = await getRepresentativeData(
        context.representative,
        user,
        verificationLevel
      );

      await updateCustomerData("Representative", {
        customerData: representativeData
      });
    }

    // 2. Perform verification
    await performVerification(verificationLevel);

    // 3. Update cache
    const [verifications, missingData] = await Promise.all([
      getVerifications(),
      getVerificationMissingData(verificationLevel)
    ]);

    setContext(prev => ({ ...prev, newMEcreated: true }));

    WSQueryCache.setQueryData(
      [QUERY_VERIFICATION_MISSING_DATA, verificationLevel],
      missingData
    );
    WSQueryCache.setQueryData(QUERY_VERIFICATIONS, verifications);
  }, config);
};
