import { useModalOldContext } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useEffect } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink as usePlaidLinkNative
} from "react-plaid-link";
import { NoAccountsConnected } from "../Bookkeeping/screens/modals/NoAccountsConnected";
import {
  BOOKKEEPING_TROUBLE_WITH_SYNCING,
  TroubleWithSyncing
} from "../Bookkeeping/screens/modals/TroubleWithSyncing";
import { UnableToSync } from "../Bookkeeping/screens/modals/UnableToSync";
import {
  NotificationStatus,
  addNotification
} from "../components/Notification/Notification";
import { useUserAccountLinkToken } from "../query/users/queries";
import { IS_DEV_ENV } from "../shared/constants/environment";
import { track } from "./analytics";

export interface WSPlaidLinkConfig {
  onSuccess: PlaidLinkOnSuccess;
  onExit?: (error: any, meta: any) => void;
  onLoad?: () => void;
  product?: ("auth" | "transactions")[];
  token?: string;
  context?: any;
  receivedRedirectUri?: string;
}

export const useWSPlaidLink = (config: WSPlaidLinkConfig) => {
  const qAccountLinkToken = useUserAccountLinkToken();

  const linkToken = config.token || qAccountLinkToken.data?.linkToken;
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

  const extendedConfig: PlaidLinkOptions = {
    clientName: "Wingspan",
    env: IS_DEV_ENV ? "sandbox" : "production",
    product: config.product || ["auth", "transactions"],
    token: linkToken,
    publicKey: linkToken
      ? (undefined as any)
      : (process.env.REACT_APP_PLAID_PUBLIC_KEY as string),
    webhook: linkToken
      ? (undefined as any)
      : process.env.REACT_APP_WEBHOOK_PLAID,
    onExit(error: any, meta: any) {
      config.onExit?.(error, meta);
    },
    onLoad() {
      const iframe = window.document.querySelector(
        `iframe[src*="${linkToken || process.env.REACT_APP_PLAID_PUBLIC_KEY}"]`
      );
      iframe?.setAttribute("data-ready-pathname", window.location.pathname);
      iframe?.setAttribute("data-ready-status", "ready");
      config.onLoad?.();
    },
    async onSuccess(token: string, info: any) {
      config.onSuccess(token, info);
    },
    onEvent(name: string, event: any) {
      track("Plaid Link Event Occurred", {
        products: config.product,
        eventName: name,
        ...config.context,
        ...event
      });

      //TODO: https://github.com/plaid/react-plaid-link/issues/110
      switch (name) {
        case "OPEN": {
          window.document
            .querySelectorAll('[id^="plaid-link-iframe"]')
            .forEach(
              el => ((el as HTMLIFrameElement).style.zIndex = "2147483647")
            );
          break;
        }
        case "EXIT": {
          window.document
            .querySelectorAll('[id^="plaid-link-iframe"]')
            .forEach(el => ((el as HTMLIFrameElement).style.zIndex = "-999"));
          document.body.style.overflow = "";

          break;
        }
        default: {
        }
      }
    }
  };

  useEffect(() => {
    if (linkToken && isOAuthRedirect) {
      const OAuthPlaid = window.Plaid.create({
        token: linkToken,
        receivedRedirectUri: window.location.href,
        ...extendedConfig
      });

      OAuthPlaid.open();
    }
  }, [linkToken, isOAuthRedirect]);

  return usePlaidLinkNative(extendedConfig);
};

export const MemberPlaidModals: React.FC = () => (
  <>
    <NoAccountsConnected />
    <TroubleWithSyncing />
    <UnableToSync />
  </>
);

export interface MemberPlaidLinkConfig extends WSPlaidLinkConfig {
  skipError?: boolean;
  onSuccess: MemberPlaidLinkOnSuccess;
}

export type MemberPlaidLinkOnSuccess = (
  public_token: string,
  metadata: PlaidLinkOnSuccessMetadata
) => Promise<
  | {
      success: true;
      data: IAccount[];
    }
  | {
      success: false;
    }
>;

export const useMemberPlaidLink = (config: MemberPlaidLinkConfig) => {
  const { openModal } = useModalOldContext();

  return useWSPlaidLink({
    ...config,
    onSuccess: async (publicToken, info) => {
      const result = await config.onSuccess(publicToken, info);

      const isValid = result.success && result.data?.length >= 1;

      if (!config.skipError) {
        if (result.success) {
          if (!isValid) {
            addNotification({
              status: NotificationStatus.error,
              timeout: 5000,
              text: (
                <>
                  <b>No compatible accounts found.</b> Eligible accounts are
                  credit card, checking, and savings accounts. Please try link a
                  different bank.
                </>
              )
            });
          }
        } else {
          console.error(
            `LOG_PlaidLinkResult: ${JSON.stringify(result.success)}`
          );
          addNotification({
            status: NotificationStatus.error,
            timeout: 5000,
            text: (
              <>
                <b>Linking failed.</b> Plaid was unable to successfully connect
                to {info?.institution?.name}. Please try again or{" "}
                <a href="mailto:team@wingspan.app">contact member support.</a>
              </>
            )
          });
        }
      }
    },
    onExit: (error, meta) => {
      if (["INSTITUTION_ERROR", "API_ERROR"].includes(error?.error_type)) {
        openModal(BOOKKEEPING_TROUBLE_WITH_SYNCING, {
          institution: meta?.institution?.name
        });
      }

      config.onExit?.(error, meta);
    }
  });
};

/*
Error codes:
"INSTITUTION_DOWN"
"INSTITUTION_NOT_AVAILABLE"
"INSTITUTION_NOT_RESPONDING"
"INSTITUTION_NO_LONGER_SUPPORTED"
"INVALID_CREDENTIALS"
"INVALID_LINK_TOKEN"
"INVALID_MFA"
"ITEM_LOCKED"
"ITEM_LOGIN_REQUIRED"
"ITEM_NOT_SUPPORTED"
"MFA_NOT_SUPPORTED"
"NO_ACCOUNTS"
"PLAID_ERROR"
"PRODUCTS_NOT_SUPPORTED"
"USER_SETUP_REQUIRED"


For test use:
username: user_good
password: "error_INSTITUTION_DOWN"
 */
