import {
  FrequencyAndScheduleStatus,
  IClientOptions,
  IFrequency,
  IInvoice,
  IInvoiceAmountDetails,
  IInvoiceAttachments,
  IInvoiceEvents,
  IInvoiceLineItem,
  IInvoiceRefundDestination,
  IMemberOptions,
  InvoiceStatus,
  IScheduleDate
} from "@wingspanhq/payments/dist/interfaces";
import { WSService } from "../../utils/WSService";
import { ListRequestQuery } from "../../utils/serviceHelper";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";

export enum InvoiceRowStatus {
  Draft = "Draft",
  ActionRequired = "ActionRequired",
  AwaitingClient = "AwaitingClient",
  InProgress = "InProgress",
  Deposited = "Deposited",
  Refunded = "Refunded"
}

export interface IInvoiceRow {
  invoiceId: string;
  parentInvoiceId?: string;
  invoiceTemplateId?: string;
  memberClientId?: string;
  currency: Currency;

  status: InvoiceRowStatus;
  oldStatus: InvoiceStatus;
  userRoles: {
    ownerIds: Array<string>;
    viewerIds: Array<string>;
  };
  client: IClientOptions & {
    profile?: {
      company?: {
        name?: string;
        legalBusinessName?: string;
      };
    };
  };
  member: IMemberOptions;
  events: IInvoiceEvents;
  memberId: string;
  clientId: string;
  dueDate: Date;
  createdAt: Date;
  updatedAt: Date;
  labels?: {
    invoiceType?: string;
    creationSource?: string;
    taxForm1099?: string;
  };
  amount: number;
  clientEmail: string;
  clientName?: string;
  clientCompany?: string;

  invoiceNotes?: string;
  projectName?: string;
  purchaseOrderNumber?: string;

  amountDetails?: IInvoiceAmountDetails;
  lineItems: IInvoiceLineItem[];
  refundDestinations?: IInvoiceRefundDestination[];
  invoiceNumber?: string;
  attachments?: IInvoiceAttachments;

  primaryName?: string;
  secondaryName?: string;
}

export type InvoiceRowsQueryFilter = {
  status?:
    | InvoiceRowStatus
    | {
        "!="?: InvoiceRowStatus;
        in?: InvoiceRowStatus[];
      };
  memberClientId?:
    | string
    | {
        in?: string[];
      };
  memberId?: string;
  clientId?: string;
  dueDate?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  "events.paidAt"?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  searchString?: string;
  "labels.invoiceType"?: {
    "!=": string;
  };
};

export type InvoiceRowsQuerySort = {
  createdAt?: "asc" | "desc";
  updatedAt?: "asc" | "desc";
};

export type InvoiceRowsQuery = ListRequestQuery<
  InvoiceRowsQueryFilter,
  InvoiceRowsQuerySort
>;

export type InvoiceRowsSummary = {
  listSize: number;
  totalValue: number;
};

const service = new WSService("/search");

export const getInvoiceRows = async (
  query?: InvoiceRowsQuery
): Promise<IInvoiceRow[]> => {
  const response = await service.get("/invoice-row", {
    params: query
  });

  return response.data;
};

export const getInvoiceRowsSummary = async (
  query?: InvoiceRowsQuery
): Promise<InvoiceRowsSummary> => {
  const response = await service.get("/invoice-row", {
    params: query,
    headers: {
      "X-WS-Get-List-Summary": "true"
    }
  });

  return {
    listSize: Number(response.headers["x-ws-list-size"]),
    totalValue: Number(response.headers["x-ws-custom-invoice-amount-total"])
  };
};

export const getInvoiceRow = async (
  invoiceId: string
): Promise<IInvoiceRow> => {
  const response = await service.get(`/invoice-row/${invoiceId}`);

  return response.data;
};

export interface IInvoiceTemplateRow {
  invoiceTemplateId: string;
  invoiceData: IInvoice & {
    clientEmail: string;
    clientName?: string;
    clientCompany?: string;
  };
  frequency?: IFrequency;
  scheduleDates?: Array<IScheduleDate>;
  status: FrequencyAndScheduleStatus;
  nextInvoiceDate?: Date;
  createdAt: Date;
  updatedAt: Date;

  primaryName?: string;
  secondaryName?: string;
}

export type InvoiceTemplateRowsQueryFilter = {
  status?:
    | FrequencyAndScheduleStatus
    | {
        "!="?: FrequencyAndScheduleStatus;
        in?: FrequencyAndScheduleStatus[];
      };
  ["invoiceData.memberClientId"]?: string;
};

export type InvoiceTemplateRowsQuerySort = {
  createdAt?: "asc" | "desc";
  updatedAt?: "asc" | "desc";
};

export type InvoiceTemplateRowsQuery = ListRequestQuery<
  InvoiceTemplateRowsQueryFilter,
  InvoiceTemplateRowsQuerySort
>;

export type InvoiceTemplateRowsSummary = {
  listSize: number;
  totalValue: number;
};

export const getInvoiceTemplateRows = async (
  query?: InvoiceTemplateRowsQuery
): Promise<IInvoiceTemplateRow[]> => {
  const response = await service.get("/invoice-template-row", {
    params: query
  });

  return response.data;
};

export const getInvoiceTemplateRowsSummary = async (
  query?: InvoiceTemplateRowsQuery
): Promise<InvoiceTemplateRowsSummary> => {
  const response = await service.get("/invoice-template-row", {
    params: query,
    headers: {
      "X-WS-Get-List-Summary": "true"
    }
  });

  return {
    listSize: Number(response.headers["x-ws-list-size"]),
    totalValue: Number(response.headers["x-ws-custom-invoice-amount-total"])
  };
};
