import { WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useFocusedFeatures } from "../../../../../FocusedFeatures";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../../query/onboarding/queries/useQueryVerifications";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { DataView } from "../../../../../shared/components/DataView";
import { selectorAddressString } from "../../../../../shared/selectors/selectorAddressString";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { PATH_ONBOARDING_VERIFICAIONS_TAX } from "../../../../Onboarding";
import { ReviewPerson } from "../../../../Onboarding/components/ReviewPerson";
import { useIsTaxDocumentsExist } from "../../../../TaxDocuments/utils/useIsTaxDocumentsExist";
import { DataViewTaxpayerInformation } from "../../../components/DataViewTaxpayerInformation";
import { useModalEditAddress } from "../components/FormEditAddress";
import { useModalEditBusinessPhoneNumber } from "../components/FormEditBusinessPhoneNumber";
import { useModalEditDBA } from "../components/FormEditDBA";
import { useModalEditStateOfInroproration } from "../components/FormEditStateOfInroproration";
import { useModalEditSupportEmail } from "../components/FormEditSupportEmail";
import { useModalEditWebsite } from "../components/FormEditWebsite";

type Props = {};

export const RouteBusinessInformation: React.FC<Props> = () => {
  const userId = useUserId();
  const history = useHistory();
  const focusedFeatures = useFocusedFeatures();
  const showOldTaxDocuments = useIsTaxDocumentsExist(2023);
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity({
    retryOnMount: false
  });
  const queryVerifications = useQueryVerifications({
    retryOnMount: false
  });
  const modalEditDBA = useModalEditDBA();
  const modalEditSupportEmail = useModalEditSupportEmail();
  const modalEditBusinessPhoneNumber = useModalEditBusinessPhoneNumber();
  const modalEditWebsite = useModalEditWebsite();
  const modalEditAddress = useModalEditAddress();
  const modalEditStateOfInroproration = useModalEditStateOfInroproration();

  const country = useMemo(
    () =>
      queryMonitoredEntity.data?.country ||
      queryMember.data?.profile.address?.country ||
      "US",
    [
      queryMember.data?.profile.address?.country,
      queryMonitoredEntity.data?.country
    ]
  );

  if (!queryUser.data || !queryMember.data) {
    return null;
  }

  const user = queryUser.data;
  const member = queryMember.data;

  const verificationStatus = queryVerifications.data?.tax;
  const notVerified = !verificationStatus || verificationStatus === "None";

  return (
    <WSList gap="2XL">
      <WSInfoBox title="Please ensure your information is up to date and accurate">
        <ol>
          <li>Click edit to edit your information.</li>
          <li>
            Your account type and fields with a green checkmark cannot be edited
            once your account has been verified.
          </li>
          <li>To make changes to verified fields, click “Request update”.</li>
          {showOldTaxDocuments ||
          focusedFeatures.showSideNavTaxDocumentsSettings ? (
            <li>
              To view your tax form delivery preferences, go to{" "}
              <a
                onClick={() => history.push("/member/tax-documents")}
                about="_self"
              >
                Tax Documents
              </a>
            </li>
          ) : null}
        </ol>
      </WSInfoBox>

      <DataViewTaxpayerInformation
        monitoredEntity={queryMonitoredEntity.data}
        user={user}
        member={member}
        verificationStatus={verificationStatus}
      />

      <DataView
        title="Business information"
        data={[
          {
            label: {
              text: "Doing business as (DBA)",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditDBA.open();
                }
              }
            },
            value: member.profile.company?.name
          },
          {
            label: {
              text: "Support email",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditSupportEmail.open();
                }
              }
            },
            value: member.profile.company?.supportEmail
          },
          {
            label: {
              text: "Business phone number",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditBusinessPhoneNumber.open();
                }
              }
            },
            value: member.profile.company?.phoneNumber
          },
          {
            label: {
              text: "Business website",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditWebsite.open();
                }
              }
            },
            value: member.profile.company?.website || undefined
          },
          {
            label: {
              text: "Business address",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditAddress.open();
                }
              }
            },
            value: selectorAddressString(member.profile.address, true),
            fsExclude: true
          },
          country === "US" || country === "CA"
            ? {
                label: {
                  text: "State/Territory of incorporation",
                  actionPosition: "left",
                  action: {
                    label: "Edit",
                    onClick: () => {
                      modalEditStateOfInroproration.open();
                    }
                  }
                },
                value: member.profile.company?.stateOfIncorporation
              }
            : null
        ]}
      />

      <ReviewPerson
        type="Representative"
        user={user}
        member={member}
        header={{
          label: "Representative information (You)",
          value: {
            action: notVerified
              ? {
                  label: "Request update",
                  onClick: () => {
                    history.push(PATH_ONBOARDING_VERIFICAIONS_TAX);
                  }
                }
              : {
                  label: "Contact support",
                  onClick: openIntercom
                }
          }
        }}
      />
    </WSList>
  );
};
