import { Route, Switch } from "react-router-dom";
import { Invoicing } from "../modules/Invoicing";
import { useFeatureFlags } from "../query/hooks/useFeatureFlags";
import { DeletePayableModal } from "./components/DeletePayableModal/DeletePayableModal";
import { InvoiceActions } from "./components/InvoiceActions/InvoiceActions";
import { PaymentsVerificationModal } from "./components/PaymentsVerificationModal/PaymentsVerificationModal";
import { RejectPayableModal } from "./components/RejectPayableModal/RejectPayableModal";
import { AddDefaultPaymentMethodWarningModal } from "./screens/AddDefaultPaymentMethodWarningModal";
import { BulkUploadCollaboratorsStep1 } from "./screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep1";
import { BulkUploadCollaboratorsStep2 } from "./screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep2";
import { AddDeduction } from "./screens/deductions/modals/AddDeduction";
import { DeductionInfo } from "./screens/deductions/modals/DeductionsInfo";
import { DeleteDeduction } from "./screens/deductions/modals/DeleteDeduction";
import { Payables } from "./screens/payables";
import { BulkUploadPayablesInfo } from "./screens/payables/bulkUploadPayables/BulkUploadPayablesInfo";
import { BulkUploadPayablesStatus } from "./screens/payables/bulkUploadPayables/BulkUploadPayablesStatus";

export const Invoices = () => {
  const featureFlagsQuery = useFeatureFlags();

  if (!featureFlagsQuery.data) {
    return null;
  }

  return (
    <>
      <PaymentsVerificationModal />
      <AddDefaultPaymentMethodWarningModal />
      <InvoiceActions />
      <DeletePayableModal />
      <RejectPayableModal />
      <AddDeduction />
      <DeductionInfo />
      <DeleteDeduction />

      <Switch>
        <Route
          path="/member/invoices/contacts/collaborators/bulk-create/info"
          component={BulkUploadCollaboratorsStep1}
        />

        <Route
          path="/member/invoices/contacts/collaborators/bulk-create/:bulkBatchId/upload"
          component={BulkUploadCollaboratorsStep2}
        />

        <Route
          path="/member/invoices/payables/bulk-upload/info"
          component={BulkUploadPayablesInfo}
        />
        <Route
          path="/member/invoices/payables/bulk-upload/:bulkBatchId/status"
          component={BulkUploadPayablesStatus}
        />

        <Route path="/member/invoices/payables" component={Payables} />

        <Route path="/member/invoices" component={Invoicing} />
      </Switch>
    </>
  );
};
