import { WSLoader, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";
import { selectorActiveTaxWithholdingAccount } from "../../../shared/selectors/selectorActiveTaxWithholdingAccount";
import { TaxesDashboard } from "./TaxesDashboard";
import { TaxesPreview } from "./TaxesPreview";

export const TaxesMain: React.FC = () => {
  const queryInternalAccounts = useQueryInternalAccounts();

  if (queryInternalAccounts.isInitialLoading) {
    return (
      <WSPage title="Taxes">
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  const hasActiveTaxWithholdingAccount =
    queryInternalAccounts.data &&
    queryInternalAccounts.data.some(selectorActiveTaxWithholdingAccount);

  if (hasActiveTaxWithholdingAccount) {
    return <TaxesDashboard />;
  }

  return <TaxesPreview />;
};
