import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { WSControl } from "../WSControl/WSControl";
import { WSElement } from "../WSElement/WSElement.component";
import { WSText } from "../core/WSText/WSText.component";
import { useCurrentScreenSize } from "../layout/WSScreen/WSScreen.component";
import { GroupHeader } from "./GroupHeader";
import { Header } from "./Header";
import { Loading } from "./Loading";
import { RowActions } from "./RowActions";
import { TableData } from "./TableData";
import styles from "./WSTable.module.scss";
import {
  WSTableColumn,
  WSTableGroup,
  WSTableItem,
  WSTableProps
} from "./types";

export type * from "./types";

const defaultColumnConfig = {
  gridTemplateSizeMax: "auto",
  gridTemplateSizeMin: "auto"
};

export const WSTable = <ItemData extends object = any>({
  loading = false,
  showHeader = true,
  tableData,
  columns: initialColumns = [],
  rowActions,
  rowMenuActions,
  headerAction,
  onRowClick,
  onRowControlClick,
  onRowMousewheelClick,
  onRowSelect,
  selection = [],
  onSelectionChange,
  onSelectAll,
  bottomMessage,
  getGroupName,
  forceShowGroups,
  showHeaderForEmpty,
  ...elementProps
}: WSTableProps<ItemData>) => {
  const currentScreenSize = useCurrentScreenSize();
  const isInSelectionMode = useMemo(() => selection.length > 0, [
    selection.length
  ]);

  const showSelectionColumn = useMemo(
    () => (currentScreenSize === "XS" ? false : !!onSelectionChange),
    [currentScreenSize, onSelectionChange]
  );

  const showActionsColumn = useMemo(
    () =>
      (rowMenuActions && rowMenuActions.length > 0) ||
      (rowActions && rowActions.length > 0) ||
      !!onRowClick ||
      !!headerAction,
    [headerAction, onRowClick, rowActions, rowMenuActions]
  );

  const handleSelection = useCallback(
    (item: WSTableItem<any>) => {
      if (!onSelectionChange) {
        return;
      }

      if (selection.some((selectedItem) => selectedItem.id === item.id)) {
        onSelectionChange(
          selection.filter((selectedItem) => selectedItem.id !== item.id)
        );
      } else {
        onSelectionChange([...selection, item]);
      }
    },
    [onSelectionChange, selection]
  );

  const columns = useMemo(() => {
    const result: Array<WSTableColumn> = initialColumns
      .filter(
        (column) => !column.config?.hideOnScreens?.includes(currentScreenSize)
      )
      .map((column) => ({
        ...column,
        config: { ...defaultColumnConfig, ...column.config }
      }));

    if (showSelectionColumn) {
      result.unshift({
        renderFunction: (rowItem) => {
          const isSelected = selection.some((item) => item.id === rowItem.id);
          return (
            <WSControl
              type="checkbox"
              value={isSelected}
              onChange={() => {
                handleSelection(rowItem);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          );
        },
        config: {
          type: "checkbox",
          gridTemplateSizeMin: "auto",
          gridTemplateSizeMax: "56px"
        }
      });
    }

    if (showActionsColumn) {
      result.push({
        config: {
          type: "actions",
          justify: "end",
          gridTemplateSizeMax: "auto",
          gridTemplateSizeMin: "auto"
        },
        renderFunction: (item) => {
          return (
            <RowActions
              item={item}
              rowActions={rowActions}
              rowMenuActions={rowMenuActions}
              onRowClick={onRowClick}
              disabled={isInSelectionMode}
            />
          );
        }
      });
    }

    return result;
  }, [
    currentScreenSize,
    handleSelection,
    initialColumns,
    isInSelectionMode,
    onRowClick,
    rowActions,
    rowMenuActions,
    selection,
    showActionsColumn,
    showSelectionColumn
  ]);

  const gridTemplate = useMemo(() => {
    return columns.reduce((acc, column) => {
      return `${acc} minmax(${column.config?.gridTemplateSizeMin}, ${column.config?.gridTemplateSizeMax})`;
    }, "");
  }, [columns]);

  const shouldShowHeader = useMemo(() => {
    if (!showHeader) {
      return false;
    }

    return columns.some((column) => !!column.config?.header);
  }, [columns, showHeader]);

  const getCellOnClick = useCallback(
    (column: WSTableColumn, rowItem: WSTableItem) => {
      if (column.config?.type === "checkbox" || isInSelectionMode) {
        return () => handleSelection(rowItem);
      }

      if (!onRowClick) {
        return undefined;
      }

      return () => onRowClick(rowItem);
    },
    [handleSelection, isInSelectionMode, onRowClick]
  );

  const getCellOnControlClick = useCallback(
    (column: WSTableColumn, rowItem: WSTableItem) => {
      if (!onRowControlClick || column.config?.type === "checkbox") {
        return undefined;
      }

      return () => onRowControlClick(rowItem);
    },
    [onRowControlClick]
  );

  const getCellOnMousewheelClick = useCallback(
    (column: WSTableColumn, rowItem: WSTableItem) => {
      if (!onRowMousewheelClick || column.config?.type === "checkbox") {
        return undefined;
      }

      return () => onRowMousewheelClick(rowItem);
    },
    [onRowMousewheelClick]
  );
  const groups = useMemo(() => {
    if (!getGroupName) {
      return [];
    }

    const groups: WSTableGroup[] = [];

    for (let index = 0; index < tableData.length; index++) {
      let groupName = "--";

      try {
        groupName = getGroupName(tableData[index]);
      } catch (error) {
        console.error("ERROR RENDER GROUP NAME", tableData[index], error);
      }

      if (groups.length === 0 || groups[groups.length - 1].name !== groupName) {
        groups.push({
          name: groupName,
          data: [tableData[index]]
        });
      } else {
        groups[groups.length - 1].data.push(tableData[index]);
      }
    }

    return groups;
  }, [getGroupName, tableData]);

  const showGroups = useMemo(
    () => (forceShowGroups ? groups.length > 0 : groups.length > 1),
    [groups, forceShowGroups]
  );

  if (tableData.length === 0) {
    return (
      <WSElement {...elementProps}>
        {showHeaderForEmpty ? (
          <WSElement
            className={styles.table}
            style={{
              gridTemplateColumns: gridTemplate
            }}
          >
            <Header columns={columns} tableData={tableData} />
          </WSElement>
        ) : null}
        {bottomMessage && (
          <WSText.ParagraphSm color="gray600" align="center" mt="M">
            {bottomMessage}
          </WSText.ParagraphSm>
        )}
      </WSElement>
    );
  }

  if (showGroups) {
    return (
      <WSElement
        {...elementProps}
        className={classNames(styles.tableGroups, elementProps.className)}
      >
        {shouldShowHeader && (
          <WSElement
            className={styles.table}
            style={{
              gridTemplateColumns: gridTemplate
            }}
          >
            <Header
              columns={columns}
              tableData={tableData}
              selection={selection}
              onSelectionChange={onSelectionChange}
              onSelectAll={onSelectAll}
              headerAction={headerAction}
            />
          </WSElement>
        )}

        {groups.map((group) => (
          <WSElement
            key={group.name}
            className={styles.table}
            style={{
              gridTemplateColumns: gridTemplate
            }}
          >
            <GroupHeader
              name={group.name}
              columns={columns}
              tableData={group.data}
              showSelectionColumn={showSelectionColumn}
              selection={selection}
              onSelectionChange={onSelectionChange}
            />
            <TableData
              tableData={group.data}
              columns={columns}
              selection={selection}
              getCellOnClick={getCellOnClick}
              getCellOnControlClick={getCellOnControlClick}
              getCellOnMousewheelClick={getCellOnMousewheelClick}
              showHover={!!onRowClick}
            />
          </WSElement>
        ))}

        {bottomMessage && (
          <WSText.ParagraphSm color="gray600" align="center" mt="M">
            {bottomMessage}
          </WSText.ParagraphSm>
        )}
      </WSElement>
    );
  }

  return (
    <WSElement {...elementProps}>
      <WSElement
        className={styles.table}
        style={{
          gridTemplateColumns: gridTemplate
        }}
      >
        {shouldShowHeader && (
          <Header
            columns={columns}
            tableData={tableData}
            selection={selection}
            onSelectionChange={onSelectionChange}
            onSelectAll={onSelectAll}
            headerAction={headerAction}
          />
        )}

        {loading ? (
          <Loading columns={columns} />
        ) : (
          <TableData
            tableData={tableData}
            columns={columns}
            selection={selection}
            getCellOnClick={getCellOnClick}
            getCellOnControlClick={getCellOnControlClick}
            getCellOnMousewheelClick={getCellOnMousewheelClick}
            showHover={!!onRowClick}
          />
        )}
      </WSElement>
      {bottomMessage && (
        <WSText.ParagraphSm color="gray600" align="center" mt="M">
          {bottomMessage}
        </WSText.ParagraphSm>
      )}
    </WSElement>
  );
};
