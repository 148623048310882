import {
  toWSDateString,
  WSCard,
  WSDataItem,
  WSList
} from "@wingspanhq/fe-component-library";
import {
  IBulkCollaboratorBatch,
  ICollaboratorGroupResponse,
  IEngagementResponse
} from "@wingspanhq/payments/dist/interfaces";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { BulkResource } from "../../types";
import { getDisplayImportStatusValue } from "../../utils/getDisplayImportStatusValue";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";

type Props = {
  bulkBatch: IBulkCollaboratorBatch;
  engagement?: IEngagementResponse;
  collaboratorGroup?: ICollaboratorGroupResponse;
};

export const ContractorBatchDetailsWidget: React.FC<Props> = ({
  bulkBatch,
  engagement,
  collaboratorGroup
}) => {
  const isEngagementsActive = useIsEngagementsActive();
  return (
    <WSCard header="Details" divider>
      <WSList gap="XL">
        <WSDataItem
          inline
          label="Original spreadsheet"
          value={
            getUploadedFilename(bulkBatch, BulkResource.Collaborator) || "--"
          }
        />
        <WSDataItem
          inline
          label="Imported to contractors"
          value={getDisplayImportStatusValue(bulkBatch.status)}
        />
        <WSDataItem
          inline
          label="Created"
          value={toWSDateString(bulkBatch.createdAt)}
        />
        <WSDataItem
          inline
          label="Creator"
          value={getBatchCreatorName(bulkBatch)}
        />
        {isEngagementsActive ? (
          <WSDataItem inline label="Engagement" value={engagement?.name} />
        ) : (
          <WSDataItem
            inline
            label="Collaborator group"
            value={collaboratorGroup?.name}
          />
        )}
      </WSList>
    </WSCard>
  );
};
