import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import { useCancelSubscriptionV3 } from "../../../query/subscriptions/mutations";
import { useUserProfile } from "../../../query/users/queries";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../../utils/analytics";
import styles from "./styles.module.scss";

export const CancelMembershipModal = () => {
  const { closeModal } = useModalOldContext();
  const userId = useUserId();
  const [cancelSubscription, cancelSubscriptionMeta] =
    useCancelSubscriptionV3(userId);
  const anonymousId = getSegmentAnonymousIdFromLocalStorage();
  const userProfileQuery = useUserProfile(userId);
  return (
    <WSModalOld
      name="cancel-subscription"
      size="XS"
      title="Pause your subscription"
    >
      <WSText mb="XL" color="gray500">
        We hate to see you go, would you rather take a break? Paused accounts
        are never charged.
      </WSText>

      {cancelSubscriptionMeta.error ? (
        <WSText.ParagraphSm
          weight="medium"
          className={styles.dangerous}
          color="red500"
        >
          {String(cancelSubscriptionMeta.error)}
        </WSText.ParagraphSm>
      ) : null}

      <WSButtons forceFullWidth format="modal">
        <WSButton.Secondary onClick={() => closeModal("cancel-subscription")}>
          Keep my membership
        </WSButton.Secondary>
        <WSButton.Primary
          loading={cancelSubscriptionMeta.isLoading}
          onClick={async () => {
            await cancelSubscription();
            closeModal("cancel-subscription");
            const {
              email,
              profile: { firstName, lastName }
            } = userProfileQuery.data as INewUser;
            const fullname = `${firstName} ${lastName}`;
            const typeFormReference = createPopup(
              `https://wingspan.typeform.com/to/PmkR6dXh#name=${fullname}&email=${email}&userid=${userId}&anonymousid=${anonymousId}`,
              {
                onSubmit: responseId => {
                  track("Churn typeform submitted", {
                    responseId: responseId
                  });
                }
              }
            );
            typeFormReference.open();
          }}
          name="confirmCancelMembership"
        >
          Pause my account
        </WSButton.Primary>
      </WSButtons>
    </WSModalOld>
  );
};
