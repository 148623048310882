import {
  toWSMoneyString,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { AutopaySetupParams } from ".";
import { PayablesMiniTable } from "../../../Invoices/components/PayablesMiniTable";
import { calculateLineItemsTotal } from "../../../Invoices/utils";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../query/WSQuery";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayeeOpenPayblesQuery } from "../../../query/payments/queries";
import { useClientQuery } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { BankAccount } from "../../../shared/components/BankAccount";
import { BankCard } from "../../../shared/components/BankCard";
import { SetupRouteComponentProps } from "../../../shared/components/FlowSetup";
import { pluralize } from "../../../utils/stringHelper";
import { SetupAutopayLayout } from "./SetupAutopayLayout";

export const SetupConfirm: React.FC<
  SetupRouteComponentProps<AutopaySetupParams>
> = ({ onBack, onNext, params }) => {
  const userId = useUserId();
  const payeeId = params?.payeeId || "";
  const clientQuery = useClientQuery(userId);
  const queryPayables = usePayeeOpenPayblesQuery(payeeId);

  const [payInvoices, payInvoicesMeta] = useWSMutation(async () => {
    const payables = queryPayables.data || [];

    const accountId =
      clientQuery.data?.profile.defaultPaymentMethod?.accountId || undefined;
    const paymentMethodId =
      clientQuery.data?.profile.defaultPaymentMethod?.paymentMethodId ||
      undefined;

    await Promise.all(
      payables.map(payable =>
        paymentsService.client.invoice.pay(payable.payableId, {
          accountId,
          paymentMethodId
        })
      )
    );
  });

  return (
    <SetupAutopayLayout payeeId={payeeId} onBack={onBack}>
      {({ payeeName }) => (
        <>
          <WSQueries queries={{ clientQuery }}>
            {({ clientQuery: { data: client } }) => (
              <>
                <WSFlexBox wrap="nowrap" mb="XL" alignItems="center">
                  <WSIcon name="check" color="green500" mr="M" size="M" />
                  <WSText.Heading5>
                    Autopay is enabled for payments to {payeeName}.
                  </WSText.Heading5>
                </WSFlexBox>

                <WSText weight="medium" mb="M">
                  Payment method
                </WSText>

                {client.profile.defaultPaymentMethod.accountId && (
                  <WSPanel>
                    <BankAccount
                      accountId={client.profile.defaultPaymentMethod.accountId}
                    />
                  </WSPanel>
                )}

                {client.profile.defaultPaymentMethod.paymentMethodId && (
                  <WSPanel>
                    <BankCard
                      type="credit"
                      cardId={
                        client.profile.defaultPaymentMethod.paymentMethodId
                      }
                    />
                  </WSPanel>
                )}

                <WSElement mt="2XL">
                  <WSQueries
                    queries={{
                      queryPayables
                    }}
                  >
                    {({ queryPayables: { data: payables } }) => {
                      const payablesTotalAmount = payables.reduce(
                        (total, payable) =>
                          total + calculateLineItemsTotal(payable.lineItems),
                        0
                      );

                      return payables.length > 0 ? (
                        <>
                          <WSText.Heading5 mb="XL">
                            You have{" "}
                            {pluralize(
                              payables.length,
                              {
                                one: "invoice",
                                other: "invoices"
                              },
                              {
                                withCount: true
                              }
                            )}{" "}
                            from {payeeName}
                          </WSText.Heading5>

                          <PayablesMiniTable payables={payables} mb="XL" />

                          {payInvoicesMeta.isSuccess ? (
                            <WSFlexBox.CenterY wrap="nowrap">
                              <WSIcon
                                block
                                name="check"
                                color="green500"
                                mr="M"
                              />
                              <WSText>
                                {pluralize(
                                  payables.length,
                                  {
                                    one: "invoice was",
                                    other: "invoices were"
                                  },
                                  {
                                    withCount: true
                                  }
                                )}{" "}
                                paid successfully{" "}
                              </WSText>
                            </WSFlexBox.CenterY>
                          ) : (
                            <>
                              <WSErrorMessage
                                mb="XL"
                                error={payInvoicesMeta.error}
                                contextKey="PayInvoice"
                              />
                              <WSButton
                                fullWidth
                                onClick={payInvoices}
                                loading={payInvoicesMeta.isLoading}
                              >
                                Pay invoices{" "}
                                {toWSMoneyString(payablesTotalAmount)}
                              </WSButton>
                            </>
                          )}
                        </>
                      ) : (
                        <WSFlexBox.Center direction="column">
                          <WSText.ParagraphSm mb="M">
                            No invoices to pay.
                          </WSText.ParagraphSm>
                          <WSButton.Link
                            onClick={() => {
                              onNext();
                            }}
                          >
                            Skip
                          </WSButton.Link>
                        </WSFlexBox.Center>
                      );
                    }}
                  </WSQueries>
                </WSElement>
              </>
            )}
          </WSQueries>
        </>
      )}
    </SetupAutopayLayout>
  );
};
