import { WSElementColorsType } from "@wingspanhq/fe-component-library";
import {
  CompanyStructure,
  ISharedUser
} from "@wingspanhq/users/dist/lib/interfaces";

export interface ErrorCodeUserMessageMap {
  [key: string]: string;
}

export enum BulkResource {
  Payable = "Payable",
  Collaborator = "Collaborator",
  Calculate1099 = "Calculate1099"
}

export type BulkImportResource = Exclude<
  BulkResource,
  BulkResource.Calculate1099
>;

export interface ILabelInfoValueOptions {
  labelColor?: WSElementColorsType;
  valueColor?: WSElementColorsType;
  onClickInfo?: (modalKey: string) => void;
}

export interface ICollaboratorCSVRow {
  email?: string;
  contractorId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  w9FirstName?: string;
  w9LastName?: string;
  dob?: Date;
  legalBusinessName?: string;
  companyStructure?: CompanyStructure;
  ein?: string | null;
  ssn?: string | null;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export enum BulkBatchEventDriver {
  System = "System",
  Payer = "Payer"
}

export enum BulkBatchEventType {
  BatchCreated = "BatchCreated",

  UploadStarted = "UploadStarted",
  UploadCompleted = "UploadCompleted",

  ImportStarted = "ImportStarted",
  ImportCompleted = "ImportCompleted",

  BatchInfoUpdated = "BatchInfoUpdated"
}

export interface IBulkBatchEvent {
  eventType: BulkBatchEventType;
  triggeredBy: BulkBatchEventDriver;
  timestamp: Date;
  eventActor?: ISharedUser;
}
