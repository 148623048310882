import { useEffect, useMemo, useState } from "react";
import { WSQueryResult } from "@ws-react-query";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";

import { WSFlexBox, WSLoader } from "@wingspanhq/fe-component-library";

import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryAcknowledgement } from "../../../query/onboarding/queries/useQueryAcknowledgement";
import { useQueryVerificationMissingData } from "../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useAllPayerListQuery } from "../../../query/payers/queries/useAllPayerListQuery";
import {
  usePaymentsStatusQuery,
  usePayoutSettings,
  usePayoutSettingsDebitCards,
  usePayrollSettings
} from "../../../query/payments/queries";
import {
  useAccounts,
  useActivities,
  useClientQuery
} from "../../../query/users/queries";
import { Layout } from "../../../shared/components/Layout";
import { usePayrollFundingMethodsManagedByOrganization } from "../../../userFeatures/usePayrollFundingMethodsManagedByOrganization";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getBusinessProfileStepConfig } from "../config/businessProfile";
import { getCertifyTaxPayerInfoStepConfig } from "../config/certifyTaxPayerInfo";
import { getCreateAccountStepConfig } from "../config/createAccount";
import { getEligibilityRequirementsStepConfig } from "../config/eligibilityRequirements";
import { getPayoutMethodStepConfig } from "../config/payoutMethod";
import { getPayrollFundingMethodStepConfig } from "../config/payrollFundingMethod";
import { getPayrollSettingsStepConfig } from "../config/payrollSettings";
import { Context } from "../Context";
import { useQueryAllPayersWithEngagements } from "../queries/useQueryAllPayersWithEngagements";
import { OnboardingContext, OnboardingModule, OnboardingStep } from "../types";
import { StepResult } from "./StepResult";

type Props = {
  basePath: string;
  modules: OnboardingModule[];
  onBack?: () => void;
  onSuccess?: () => void;
};

const loadStep = (
  step: OnboardingStep | null,
  ...dependencies: WSQueryResult<any, WSServiceError>[]
): OnboardingStep | null => {
  if (step === null) return null;

  if (dependencies.every(query => query.isFetched)) {
    return {
      ...step
    };
  }

  return {
    ...step,
    component: () => (
      <WSFlexBox.Center py="3XL">
        <WSLoader.Spinner size="M" />
      </WSFlexBox.Center>
    ),
    status: "Loading"
  };
};

export const OnboardingFlow: React.FC<Props> = ({
  basePath,
  modules,
  onBack,
  onSuccess
}) => {
  const userId = useUserId();
  const location = useLocation();

  usePaymentsStatusQuery();
  const queryVerifications = useQueryVerifications();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryAccounts = useAccounts();
  const queryActivity = useActivities(userId);
  const queryAllPayerList = useAllPayerListQuery();
  const queryClient = useClientQuery(userId);
  const queryInternalAccounts = useQueryInternalAccounts();
  const queryDebitCards = usePayoutSettingsDebitCards(userId);
  const queryMissingDataTax = useQueryVerificationMissingData("Tax");
  const queryMissingDataBanking = useQueryVerificationMissingData("Banking");

  const isPayrollFundingMethodManagedByOrg =
    usePayrollFundingMethodsManagedByOrganization();

  const queryPayersEngagements = useQueryAllPayersWithEngagements({
    refetchOnMount: false
  });
  const queryElectronicTaxFormConsentAcknowledgement = useQueryAcknowledgement(
    "ElectronicTaxFormConsent"
  );

  const [contextValue, setContextValue] = useState<OnboardingContext>({});

  const steps = useMemo(
    () =>
      modules
        .map(onboardingModule => {
          switch (onboardingModule.type) {
            case "create_account":
              return loadStep(
                getCreateAccountStepConfig(
                  onboardingModule,
                  contextValue,
                  queryVerifications.data,
                  queryMissingDataTax.data,
                  queryMissingDataBanking.data
                ),
                queryVerifications,
                queryMissingDataTax,
                queryMissingDataBanking
              );

            case "payroll_funding_method":
              return loadStep(
                getPayrollFundingMethodStepConfig(
                  onboardingModule,
                  contextValue,
                  queryPayrollSettings.data,
                  queryAccounts.data,
                  queryActivity.data,
                  queryClient.data,
                  isPayrollFundingMethodManagedByOrg
                ),
                queryPayrollSettings,
                queryAccounts,
                queryActivity,
                queryClient
              );

            case "payroll_settings":
              return loadStep(
                getPayrollSettingsStepConfig(
                  onboardingModule,
                  contextValue,
                  queryActivity.data
                ),
                queryActivity
              );

            case "business_profile":
              return loadStep(
                getBusinessProfileStepConfig(
                  onboardingModule,
                  contextValue,
                  queryActivity.data
                ),
                queryActivity
              );
            case "certify_tax_payer_info":
              return loadStep(
                getCertifyTaxPayerInfoStepConfig(
                  onboardingModule,
                  contextValue,
                  queryAllPayerList.data,
                  queryElectronicTaxFormConsentAcknowledgement.data
                ),
                queryAllPayerList,
                queryElectronicTaxFormConsentAcknowledgement
              );
            case "payout_method":
              return loadStep(
                getPayoutMethodStepConfig(
                  onboardingModule,
                  contextValue,
                  queryPayoutSettings.data,
                  queryAccounts.data,
                  queryDebitCards.data,
                  queryInternalAccounts.data
                ),
                queryPayoutSettings,
                queryAccounts,
                queryDebitCards,
                queryInternalAccounts
              );
            case "eligibility_requirements":
              return loadStep(
                getEligibilityRequirementsStepConfig(
                  onboardingModule,
                  contextValue,
                  queryPayersEngagements.data
                ),
                queryPayersEngagements
              );

            default:
              throw new Error(
                `No step config found for module type: ${
                  (onboardingModule as OnboardingModule).type
                }`
              );
          }
        })
        .filter(step => step !== null) as OnboardingStep[],
    [
      modules,
      contextValue,
      queryVerifications,
      queryMissingDataTax,
      queryMissingDataBanking,
      queryPayrollSettings,
      queryAccounts,
      queryActivity,
      queryClient,
      isPayrollFundingMethodManagedByOrg,
      queryAllPayerList,
      queryElectronicTaxFormConsentAcknowledgement,
      queryPayoutSettings,
      queryDebitCards,
      queryInternalAccounts,
      queryPayersEngagements
    ]
  );

  const currentStep = useMemo(() => {
    return steps.find(step => location.pathname.includes(step.slug));
  }, [location.pathname, steps]);

  if (!steps.length) {
    return null;
  }

  return (
    <Context.Provider
      value={{
        value: contextValue,
        setValue: setContextValue,
        modules
      }}
    >
      <Layout
        title={currentStep?.title}
        navigation={steps.length > 1 ? steps : undefined}
      >
        <FlowSwitch
          path={basePath}
          slug={basePath}
          title="Onboarding"
          steps={steps}
          onBack={onBack}
          onNext={onSuccess}
        />
      </Layout>
    </Context.Provider>
  );
};

type StepProps = { path: string } & OnboardingStep;

export const FlowSwitch: React.FC<StepProps> = props => {
  const history = useHistory();

  const handleLastStepOnNext = useMemo(() => {
    if (!!props.result?.Complete || !!props.result?.Pending) {
      // Do nothing on last step if parent can have Complete or Pending states
      return;
    }

    return props.onNext;
  }, [props.onNext, props.result]);

  const stepsWithProps: OnboardingStep[] = useMemo(() => {
    const steps = props.steps || [];
    return steps.map((step, index) => {
      return {
        ...step,
        onBack:
          index === 0
            ? props.onBack
            : () => {
                history.push(`${props.path}/${steps[index - 1].slug}`);
              },
        onSkip: handleLastStepOnNext,
        onNext:
          index === steps.length - 1
            ? handleLastStepOnNext
            : () => {
                history.push(`${props.path}/${steps[index + 1].slug}`);
              }
      };
    });
  }, [handleLastStepOnNext, history, props.onBack, props.path, props.steps]);

  return (
    <Switch>
      {(!props.status || props.status === "None") &&
        stepsWithProps.map(step => {
          const path = `${props.path}/${step.slug}`;

          return (
            <Route key={step.slug} path={path}>
              <FlowSwitch {...step} path={path} />
            </Route>
          );
        })}

      <Route path={props.path + "/complete"}>
        {props.result?.Complete ? (
          <StepResult
            status="Complete"
            onNext={props.onNext}
            {...props.result?.Complete}
          />
        ) : (
          <GoNext onNext={props.onNext} />
        )}
      </Route>

      <Route path={props.path + "/pending"}>
        {props.result?.Pending ? (
          <StepResult
            status="Pending"
            onNext={props.onNext}
            {...props.result?.Pending}
          />
        ) : (
          <GoNext onNext={props.onNext} />
        )}
      </Route>

      <Route path={props.path}>
        {props.status === "Complete" ? (
          <Redirect to={props.path + "/complete"} />
        ) : props.status === "Pending" ? (
          <Redirect to={props.path + "/pending"} />
        ) : props.component ? (
          <props.component {...props} onNext={handleLastStepOnNext} />
        ) : (
          <Redirect to={props.path + "/" + stepsWithProps[0].slug} />
        )}
      </Route>
    </Switch>
  );
};

const GoNext: React.FC<{ onNext?: () => void }> = props => {
  useEffect(() => {
    props.onNext?.();
  }, [props]);

  return null;
};
