import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useWSModal } from "@wingspanhq/fe-component-library";

type WSModalType = ReturnType<typeof useWSModal>;

export const useOpenModalBySearchParam = <M extends WSModalType = WSModalType>(
  param: string,
  modal: M,
  modalProps?: Parameters<M["open"]>
) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const isModalOpen = !!params.get(param);

  useEffect(() => {
    if (isModalOpen) {
      modal.open(modalProps).then(result => {
        history.replace(history.location.pathname);
      });
    }
  }, [isModalOpen, history]);

  return {};
};
