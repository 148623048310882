import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useCountersignDocument,
  useSignDocument
} from "../../../../query/files/mutations";
import { useUserId } from "../../../../query/hooks/helpers";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { buildPayeeDetailsPath } from "../../../Payees/utils";
import { selectorSignatureClientNotifications } from "../../selectors/selectorSignatureClientNotifications";
import { selectorSignatureCollaboratorNotifications } from "../../selectors/selectorSignatureCollaboratorNotifications";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";

type DocumentToSign = {
  notificationId: string;
  title: string;
  description: ReactNode;
  actionTitle: string;
  onClick: () => Promise<void>;
};

export const RouteDocumentsToSign: React.FC<{
  type: "client" | "collaborator";
}> = ({ type }) => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryNotifications = useNotifications();

  const [countersign] = useCountersignDocument();
  const [signDocument] = useSignDocument();

  const documents = useMemo(() => {
    if (!queryNotifications.data) {
      return [];
    }

    const documents: DocumentToSign[] = [];

    if (type === "collaborator") {
      const notifications = selectorSignatureCollaboratorNotifications(
        queryNotifications.data
      );
      notifications.forEach(notification => {
        const document = notification.context?.document;
        const clientName = notification.context?.client?.companyName;
        const memberClient = notification.context?.memberClient;

        documents.push({
          notificationId: notification.notificationId,
          title: document?.title || "",
          description: `${clientName} requires that you sign this document to complete the onboarding process`,
          actionTitle: "Sign document",
          onClick: async () => {
            trackClickNotification(notification);
            await signDocument({
              notificationId: notification.notificationId,
              documentId: document?.documentId,
              payerId: notification.context?.client.clientId,
              payerPayeeEngagementId: memberClient?.memberClientId
            });
          }
        });
      });
    } else {
      const notifications = selectorSignatureClientNotifications(
        queryNotifications.data
      );
      notifications.forEach(notification => {
        const member = notification.context.member;
        const document = notification.context?.document;

        documents.push({
          notificationId: notification.notificationId,
          title: document?.title || "",
          description: (
            <>
              Your contractor{" "}
              <a
                onClick={() => {
                  history.push(buildPayeeDetailsPath(member.memberId));
                }}
              >
                <b>{member?.fullName}</b>
              </a>{" "}
              already signed this document
            </>
          ),
          actionTitle: "Countersign",
          onClick: async () => {
            trackClickNotification(notification);
            await countersign({
              documentId: notification.context.document.documentId,
              payeeId: member.memberId,
              payerPayeeEngagementId:
                notification.context.memberClient?.memberClientId ||
                notification.context.payerPayeeEngagement
                  ?.payerPayeeEngagementId,
              notificationId: notification.notificationId
            });
          }
        });
      });
    }

    return documents;
  }, [
    countersign,
    history,
    queryNotifications.data,
    signDocument,
    trackClickNotification,
    type
  ]);

  if (queryNotifications.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <WSQueries queries={{ queryNotifications }}>
      {() => {
        return (
          <WSPage
            breadcrumb={{
              label: "Back",
              onClick() {
                history.push("/member/dashboard");
              },
              icon: "arrow-left"
            }}
          >
            <WSText.Heading4 my="XL">
              E-signatures required ({documents.length})
            </WSText.Heading4>
            {documents.length === 0 ? (
              <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
                No documents to sign
              </WSText.ParagraphSm>
            ) : (
              documents.map(
                ({
                  notificationId,
                  title,
                  onClick,
                  description,
                  actionTitle
                }) => {
                  return (
                    <WSPanel key={notificationId} mb="M">
                      <WSFlexBox.CenterY justify="space-between">
                        <WSElement>
                          <WSText weight="medium" pb="M">
                            {title}
                          </WSText>
                          <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
                        </WSElement>
                        <WSButton.Secondary size="S" onAsyncClick={onClick}>
                          {actionTitle}
                        </WSButton.Secondary>
                      </WSFlexBox.CenterY>
                    </WSPanel>
                  );
                }
              )
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
