import { WSText } from "@wingspanhq/fe-component-library";
import {
  CustomFieldResourceType,
  ICollaboratorSchema,
  ITaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { SetupParams } from "..";
import { WSQueries } from "../../../../../query/WSQuery";
import { useCustomFieldsAll } from "../../../../../query/customFields/queries/useCustomFieldsAll";
import { useWSMutation } from "../../../../../query/helpers";
import { useAllOrganizationUsers } from "../../../../../query/users/queries";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import { Form, FormData } from "./Form";

type Props = SetupRouteComponentProps<SetupParams>;

export const RoutePersonalDetails: React.FC<Props> = ({ onBack, onNext }) => {
  const queryOrganizations = useAllOrganizationUsers();
  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  const [submit, submitMeta] = useWSMutation<
    {
      collaborator: ICollaboratorSchema;
      taxForm: ITaxFormResponse;
    },
    WSServiceError,
    FormData
  >(
    async () => {
      throw new Error("Deprecated");
    },
    {
      onSuccess: onNext
    }
  );

  return (
    <EditLayout onBack={onBack} title="Add recipient">
      <WSText.Heading4>Personal details</WSText.Heading4>
      <WSText color="gray500" mt="M" mb="2XL">
        Please fill out the personal details of the new recipient you would like
        to add. Once invited, the organization and email address cannot be
        edited.
      </WSText>

      <WSQueries
        queries={{
          queryOrganizations,
          queryCustomFields
        }}
      >
        {({ queryCustomFieldsData, queryOrganizationsData }) => (
          <Form
            organizations={queryOrganizationsData}
            customFields={queryCustomFieldsData}
            onCancel={onBack}
            onSubmit={submit}
            isLoading={submitMeta.isLoading}
            error={submitMeta.error}
          />
        )}
      </WSQueries>
    </EditLayout>
  );
};
