import { WSList } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYMENT_METHODS,
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD
} from "..";
import { Layout } from "../../../../../shared/components/Layout";
import { AlertPendingAccountMicroDeposit } from "../../../components/AlertPendingAccountMicroDeposit";
import { ManagePaymentMethod } from "../../../components/payment/ManagePaymentMethod";

export const RouteManagePaymentMethod: React.FC = () => {
  const history = useHistory();

  return (
    <Layout
      title="Manage payment method"
      onClose={() => {
        history.push(PATH_SETTINGS_PAYMENT_METHODS);
      }}
    >
      <WSList gap="2XL">
        <AlertPendingAccountMicroDeposit />

        <ManagePaymentMethod
          onNext={() => {
            history.push(PATH_SETTINGS_PAYMENT_METHODS);
          }}
          onAddNew={() => {
            history.push(PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD);
          }}
        />
      </WSList>
    </Layout>
  );
};
