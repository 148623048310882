import { WSQueryConfig } from "@ws-react-query";
import { IPayerListResponse } from "@wingspanhq/payments/dist/interfaces";

import { getPayers } from "../../../services/payers";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYER_LIST } from "../keys";

export const usePayerListQuery = (
  queryConfig?: WSQueryConfig<IPayerListResponse, unknown>
) =>
  useWSQuery(QUERY_PAYER_LIST, getPayers, {
    retry: false,
    ...queryConfig
  });
