import { toWSMoneyString } from "@wingspanhq/fe-component-library";
import { IInvoiceTemplate } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { calculateLineItemsTotal } from "../utils";
import { getLineItemsTotals } from "../utils/getLineItemsTotals";
import { Totals, TotalsSection } from "./Totals/Totals";

type Props = {
  invoiceTemplate: IInvoiceTemplate;
};

export const InvoiceTemplateTotals: React.FC<Props> = props => {
  const totals = useMemo(() => {
    const totalsSections: TotalsSection[] = [];

    const totalsLineItemsSection = getLineItemsTotals(
      props.invoiceTemplate.invoiceData.lineItems,
      undefined,
      props.invoiceTemplate.invoiceData.currency
    );

    totalsSections.push(totalsLineItemsSection);

    const totalsWithholdingsSection: TotalsSection = {
      items: [
        {
          bold: true,
          left: "Total",
          right: toWSMoneyString(
            calculateLineItemsTotal(
              props.invoiceTemplate.invoiceData.lineItems
            ),
            "default",
            props.invoiceTemplate.invoiceData.currency
          )
        }
      ]
    };

    totalsSections.push(totalsWithholdingsSection);

    return totalsSections;
  }, [
    props.invoiceTemplate.invoiceData.currency,
    props.invoiceTemplate.invoiceData.lineItems
  ]);

  return <Totals sections={totals} />;
};
