import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { OLD_BULK_BATCHES_THRESHOLD_IN_DAYS } from "../constants";
import formatDistance from "date-fns/formatDistance";

export const isAPIUpload = (bulkBatch: IBulkBatch | undefined) => {
  return !bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId];
};

/**
 * This function checks if the bulk batch items are being created or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isFileUploaded(bulkBatch?: IBulkBatch): boolean {
  // If the bulk batch has a flatfileBatchId and bulkImporterFilename, it means that the file has been uploaded.
  return (
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.bulkImporterFilename]
  );
}

/**
 * This function checks if the bulk batch items are being created or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isUploadInProgress(bulkBatch?: IBulkBatch): boolean {
  // If the bulk batch has a flatfileBatchId and flatfileJobId, it means the batch is being uploaded and the upload has not finished yet.
  return (
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileJobId] &&
    !bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.uploadFinishedAt]
  );
}

/**
 * This function checks if the creation of bulk batch items is finished or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isUploadFinished(
  bulkBatch: IBulkBatch | undefined,
  isEmptyBatch: boolean | undefined
): boolean {
  // debugger;
  // If the bulk batch has a flatfileBatchId and flatfileJobId, and uploadFinishedAt, it means the batch has finished uploading.
  const isFlatFileUploadFinished =
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileJobId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.uploadFinishedAt];

  const isAPIUploadFinished = isAPIUpload(bulkBatch) && !isEmptyBatch;

  return isFlatFileUploadFinished || isAPIUploadFinished;
}

/**
 * The function checks if the batch is fully imported or not
 * @param bulkBatch
 * @returns boolean
 */
export function isBatchFullyImported(bulkBatch?: IBulkBatch): boolean {
  return (
    bulkBatch?.status === BulkStatus.Complete &&
    bulkBatch.statistics?.itemsFailed === 0
  );
}

/**
 * The function checks if the batch is partially imported or not
 * @param bulkBatch
 * @returns boolean
 */
export function isBatchPartiallyImported(bulkBatch?: IBulkBatch): boolean {
  return (
    bulkBatch?.status === BulkStatus.Complete &&
    (bulkBatch.statistics?.itemsFailed || 0) > 0
  );
}

/**
 * The function checks if the batch is older than the threshold
 * @param bulkBatch
 * @returns boolean
 */
export function isOldBulkBatch(bulkBatch?: IBulkBatch): boolean {
  const createdAt = bulkBatch?.createdAt;
  if (!createdAt) {
    return false;
  }
  const today = new Date();
  today.setDate(today.getDate() - OLD_BULK_BATCHES_THRESHOLD_IN_DAYS);
  return new Date(createdAt) < today;
}

export function getBulkBatchAge(bulkBatch?: IBulkBatch): string {
  if (!bulkBatch?.createdAt) {
    return "";
  }
  const createdAt = new Date(bulkBatch.createdAt);
  const today = new Date();
  return formatDistance(createdAt, today);
}
