import { WSInfiniteQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../helpers";
import { QUERY_REQUIREMENT_DEFINITIONS_LIST } from "../keys";
import {
  listRequirementDefinitions,
  RequirementDefinitionListResponse
} from "../../../services/requirementDefinitions/listRequirementDefinitions";

export const useRequirementDefinitionListQuery = (
  params = {} as any,
  config?: WSInfiniteQueryConfig<
    RequirementDefinitionListResponse,
    WSServiceError
  >
) => {
  const { sort, ...filter } = params;

  return useWSInfiniteQuery<RequirementDefinitionListResponse, WSServiceError>(
    [QUERY_REQUIREMENT_DEFINITIONS_LIST, params],
    async ({ pageParam = 1 }) => {
      return listRequirementDefinitions({
        filter,
        page: { size: 10, number: pageParam },
        sort
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      retry: false,
      ...config
    }
  );
};
