import { useWSModal, WSForm } from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../../query/users/queries";
import { selectorMemberCountry } from "../../../../../shared/selectors/selectorMemberCountry";
import { entityDataServiceService } from "../../../../../shared/services/entityData";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import {
  FormDataAddress,
  FormPartialAddress,
  getProfileDataAddress,
  getValidationSchemaAddress
} from "../../../../Onboarding/components/FormPartialAddress";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: FormDataAddress };

type Props = {
  onClose: () => void;
};

export const FormEditAddress: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const country = useMemo(
    () => (queryMember.data ? selectorMemberCountry(queryMember.data) : "US"),
    [queryMember.data]
  );

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      await entityDataServiceService.updateEntity(
        {
          address: data.newValue
        },
        {
          userId
        }
      );
    },
    {
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      defaultValues={{ newValue: getProfileDataAddress(queryMember.data) }}
      validationSchema={Yup.object({
        newValue: getValidationSchemaAddress(country)
      })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <FormPartialAddress country={country} name="newValue" />
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditAddress = () =>
  useWSModal(FormEditAddress, {
    title: "Address",
    size: "S"
  });
