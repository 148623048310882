import { useHistory } from "react-router-dom";
import { FlowAddPaymentMethod } from "../../../modules/Settings/components/payment/FlowAddPaymentMethod";
import { Layout } from "../../../shared/components/Layout";

export const RouteAddPaymentMethod: React.FC = () => {
  const history = useHistory();
  return (
    <Layout
      title="Add payment method"
      onClose={() => {
        history.push("/payment/history");
      }}
    >
      <FlowAddPaymentMethod
        basePath="/payment/add-payment-method"
        onBack={() => {
          history.push("/payment/manage-payment-method");
        }}
        onNext={() => {
          history.push("/payment/history");
        }}
      />
    </Layout>
  );
};
