import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSList,
  WSLoader,
  WSPage
} from "@wingspanhq/fe-component-library";
import { PayrollWorkflowStrategy } from "@wingspanhq/payments/dist/interfaces";
import queryString from "qs";
import React, { useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { Actions } from "../../../components/Actions";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { Tabs } from "../../../components/Tabs";
import { useGoToPayablesImportStart } from "../../../modules/BulkImporter/paths";
import { useCustomization } from "../../../modules/customization";
import { PaymentsSetup } from "../../../modules/PaymentsSetup";
import { PATH_SETTINGS_PAYROLL } from "../../../modules/Settings/paths";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { plural } from "../../../shared/utils/plural";
import { AllDeductions } from "../payableDeductions/AllDeductions";
import { AlertPayrollDebitReturn } from "./AlertPayrollDebitReturn";
import { BulkUploadedPayablesById } from "./BulkUploadedPayablesById";
import { CreatePayable } from "./CreatePayable";
import { EditPayable } from "./EditPayable";
import { OffCyclePayroll } from "./OffCyclePayroll";
import { PayablesAll } from "./PayablesAll";
import { PayablesDashboard } from "./PayablesDashboard";
import { PayablesDisputed } from "./PayablesDisputed";
import { PayablesDrafts } from "./PayablesDrafts";
import { PayablesDualStageToApprove } from "./PayablesDualStageToApprove";
import { PayablesDualStageToPreApprove } from "./PayablesDualStageToPreApprove";
import { PayablesPaid } from "./PayablesPaid";
import { PayablesPayrollRuns } from "./PayablesPayrollRuns";
import { PayablesScheduled } from "./PayablesScheduled";
import { PayablesToApprove } from "./PayablesToApprove";
import { PayrollRun } from "./PayrollRun";
import { PayrollSettingsModal } from "./PayrollSettingsModal";
import { useQueryOverduePayrollInvoices } from "./queries/useQueryOverduePayrollInvoices";

export const Payables: React.FC = () => {
  const { terminology } = useCustomization();
  const userId = useUserId();
  const history = useHistory();
  const location = useLocation();
  const payrollSettingsQuery = usePayrollSettings(userId, { retry: false });
  const isMobile = useIsMobile();

  const queries: { uploadBatchId?: string } = queryString.parse(
    location.search,
    {
      ignoreQueryPrefix: true
    }
  );

  const queryOverduePayrollInvoices = useQueryOverduePayrollInvoices();
  const hasOverduePayrollInvoices = useMemo(
    () =>
      !!queryOverduePayrollInvoices.data &&
      queryOverduePayrollInvoices.data.length > 0,
    [queryOverduePayrollInvoices.data]
  );
  const goToPayablesImportStart = useGoToPayablesImportStart();

  if (payrollSettingsQuery.isLoading) {
    return <WSLoader.Spinner />;
  }

  let tabs = [
    {
      label: "Dashboard",
      path: "/member/invoices/payables/dashboard"
    },
    {
      label: "To Approve",
      path: "/member/invoices/payables/to-approve"
    },
    {
      label: "Scheduled",
      path: "/member/invoices/payables/scheduled"
    },
    {
      label: "Paid",
      path: "/member/invoices/payables/paid"
    },
    {
      label: "Payroll Runs",
      path: "/member/invoices/payables/payroll-runs"
    },
    ...(queries.uploadBatchId
      ? [
          {
            label: "Bulk uploaded",
            path: "/member/invoices/payables/bulk-uploaded",
            params: {
              uploadBatchId: queries.uploadBatchId
            }
          }
        ]
      : []),
    {
      label: "All Payables",
      path: "/member/invoices/payables/all"
    },

    {
      label: "All Deductions",
      path: "/member/invoices/payables/deductions"
    }
  ];
  if (
    payrollSettingsQuery.data?.workflow === PayrollWorkflowStrategy.DualStage
  ) {
    tabs = [
      {
        label: "Dashboard",
        path: "/member/invoices/payables/dashboard"
      },
      {
        label: "To Pre-Approve",
        path: "/member/invoices/payables/to-preapprove"
      },
      {
        label: "To Approve",
        path: "/member/invoices/payables/to-approve"
      },
      {
        label: "Scheduled",
        path: "/member/invoices/payables/scheduled"
      },
      {
        label: "Paid",
        path: "/member/invoices/payables/paid"
      },
      {
        label: "Payroll Runs",
        path: "/member/invoices/payables/payroll-runs"
      },
      ...(queries.uploadBatchId
        ? [
            {
              label: "Bulk uploaded",
              path: "/member/invoices/payables/bulk-uploaded",
              params: {
                uploadBatchId: queries.uploadBatchId
              }
            }
          ]
        : []),
      {
        label: "All Payables",
        path: "/member/invoices/payables/all"
      },

      {
        label: "All Deductions",
        path: "/member/invoices/payables/deductions"
      }
    ];
  }
  return (
    <Switch>
      <Route
        path="/member/invoices/payables/create"
        component={CreatePayable}
      />

      <Route
        path="/member/invoices/payables/:payableId/edit"
        component={EditPayable}
      />

      <Route
        path="/member/invoices/payables/draft"
        component={PayablesDrafts}
      />

      <Route
        path="/member/invoices/payables/disputed"
        component={PayablesDisputed}
      />

      <Route
        path="/member/invoices/payables/payroll-runs/:payrollRunId"
        component={PayrollRun}
      />

      <Route
        path="/member/invoices/payables/set-up"
        render={() => (
          <PaymentsSetup
            basePath="/member/invoices/payables/set-up"
            onBack={() => {
              history.push("/member/invoices/payables");
            }}
            onFinish={() => {
              history.push("/member/invoices/payables");
            }}
          />
        )}
      />

      <Route
        path="/member/invoices/payables"
        render={() => (
          <WSPage
            title={plural(terminology().sendPaymentsPayable)}
            primaryButtonComponent={
              isMobile
                ? props => (
                    <WSPersistentUpgradeButton
                      name="createPayable"
                      feature={WSFrontendFeature.InviteAndPayCollaborator}
                      onClick={() => {
                        history.push({
                          pathname: "/member/invoices/payables/create",
                          state: {
                            backPath: location.pathname
                          }
                        });
                      }}
                      {...props}
                    >
                      Create payable
                    </WSPersistentUpgradeButton>
                  )
                : undefined
            }
            sideContent={
              isMobile ? undefined : (
                <WSFlexBox.CenterY>
                  <WSButton
                    mr="M"
                    name="createPayable"
                    onClick={() => {
                      history.push({
                        pathname: "/member/invoices/payables/create",
                        state: {
                          backPath: location.pathname
                        }
                      });
                    }}
                  >
                    Create payable
                  </WSButton>
                  <Actions
                    name="actions"
                    items={[
                      {
                        label: "Bulk upload payables",
                        onClick() {
                          goToPayablesImportStart();
                        }
                      },
                      {
                        label: "Run off-cycle payroll",
                        onClick() {
                          history.push(
                            "/member/invoices/payables/off-cycle-payroll"
                          );
                        }
                      },

                      {
                        label: "Payroll settings",
                        onClick() {
                          history.push(PATH_SETTINGS_PAYROLL);
                        }
                      }
                    ]}
                  />
                </WSFlexBox.CenterY>
              )
            }
          >
            <PayrollSettingsModal />
            <WSList gap="2XL">
              {hasOverduePayrollInvoices && <AlertPayrollDebitReturn />}

              <Tabs tabs={tabs} />

              <WSElement data-testid="payablesContainer">
                <Switch>
                  <Route
                    path="/member/invoices/payables/dashboard"
                    component={PayablesDashboard}
                  />

                  <Route
                    path="/member/invoices/payables/to-preapprove"
                    component={PayablesDualStageToPreApprove}
                  />

                  <Route
                    path="/member/invoices/payables/to-approve"
                    component={
                      payrollSettingsQuery.data?.workflow ===
                      PayrollWorkflowStrategy.DualStage
                        ? PayablesDualStageToApprove
                        : PayablesToApprove
                    }
                  />

                  <Route
                    path="/member/invoices/payables/scheduled"
                    component={PayablesScheduled}
                  />

                  <Route
                    path="/member/invoices/payables/paid"
                    component={PayablesPaid}
                  />

                  <Route
                    path="/member/invoices/payables/payroll-runs"
                    component={PayablesPayrollRuns}
                  />

                  <Route
                    path="/member/invoices/payables/bulk-uploaded"
                    component={BulkUploadedPayablesById}
                  />

                  <Route
                    path="/member/invoices/payables/all"
                    component={PayablesAll}
                  />

                  <Route
                    path="/member/invoices/payables/deductions"
                    component={AllDeductions}
                  />

                  <Route
                    path="/member/invoices/payables/off-cycle-payroll"
                    component={OffCyclePayroll}
                  />

                  <Redirect
                    from="/member/invoices/payables"
                    to="/member/invoices/payables/dashboard"
                  />
                </Switch>
              </WSElement>
            </WSList>
          </WSPage>
        )}
      />
    </Switch>
  );
};
