import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IS_DEV_ENV } from "../shared/constants/environment";
import { WSQueryClient } from "@ws-react-query";

export const WSQueryProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={WSQueryClient}>
      {children}
      {IS_DEV_ENV ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
};
