import { useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYMENT_METHODS,
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD,
  PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD
} from "..";
import { Layout } from "../../../../../shared/components/Layout";
import { FlowAddPayoutMethod } from "../../../components/payout/FlowAddPayoutMethod";

export const RouteAddPayoutMethod: React.FC = () => {
  const history = useHistory();
  return (
    <Layout
      title="Add payment method"
      onClose={() => {
        history.push(PATH_SETTINGS_PAYMENT_METHODS);
      }}
    >
      <FlowAddPayoutMethod
        basePath={PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD}
        onBack={() => {
          history.push(PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD);
        }}
        onNext={() => {
          history.push(PATH_SETTINGS_PAYMENT_METHODS);
        }}
      />
    </Layout>
  );
};
