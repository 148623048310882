import { IInvoice, InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { WSQueryConfig } from "@ws-react-query";
import { useWSQuery } from "../../../../query/helpers";
import { paymentsService } from "../../../../services/payments";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const QUERY_KEY_OVERDUE_PAYROLL_INVOICES =
  "QUERY_KEY_OVERDUE_PAYROLL_INVOICES";

export const useQueryOverduePayrollInvoices = (
  config?: WSQueryConfig<IInvoice[], WSServiceError>
) =>
  useWSQuery<IInvoice[], WSServiceError>(
    QUERY_KEY_OVERDUE_PAYROLL_INVOICES,
    () =>
      paymentsService.invoice.list({
        filter: {
          "labels.invoiceType": "approvedInvoicesPayment",
          status: InvoiceStatus.Overdue
        },
        page: {
          size: 1
        }
      }),
    config
  );
