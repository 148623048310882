import flatten from "lodash/flatten";
import { useWSInfiniteQuery, WSInfiniteQueryConfig } from "@ws-react-query";
import {
  getPayeeRows,
  IPayeeRow,
  PayeeRowsQuery
} from "../../../../services/search";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

const PAGE_SIZE = 20;

export const QUERY_PAYEE_ROWS = "QUERY_PAYEE_ROWS";

export const usePayeeRowsQuery = (
  params?: PayeeRowsQuery,
  config?: WSInfiniteQueryConfig<IPayeeRow[], WSServiceError>,
  noExpansion?: boolean
) => {
  const size = params?.page?.size ?? PAGE_SIZE;

  const defaultXWingspanExpansion = useXWingspanExpansion();
  const xWingspanExpansion = noExpansion
    ? undefined
    : defaultXWingspanExpansion;

  const query = useWSInfiniteQuery<IPayeeRow[], WSServiceError>(
    [QUERY_PAYEE_ROWS, params, xWingspanExpansion],
    ({ pageParam = 1 }) => {
      return getPayeeRows(
        {
          ...params,
          page: {
            size,
            number: pageParam
          }
        },
        xWingspanExpansion
      );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};
