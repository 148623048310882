import { WSLabelProps } from "@wingspanhq/fe-component-library";
import {
  ICheckbookCard,
  IPaymentCard
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  IAccount,
  ICreditCard
} from "@wingspanhq/users/dist/lib/interfaces";
import capitalize from "lodash/capitalize";
import { ManageMethodsProps, Method } from ".";
import {
  components,
  InternalAccountType
} from "../../../../services/api/banking/types";
import {
  avatarBankAccount,
  avatarBankCard,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { creditCardBrandNameMap } from "../../../../shared/constants/bankCard";
import { usePlaidReconnect } from "../../../../shared/hooks/usePlaidReconnect";
import { selectorAccountInstitution } from "../../../../shared/selectors/selectorAccountInstitution";
import { selectorAccountName } from "../../../../shared/selectors/selectorAccountName";
import { selectorAccountVerificationPending } from "../../../../shared/selectors/selectorAccountVerificationPending";
import { selectorDebitCardBrand } from "../../../../shared/selectors/selectorDebitCardBrand";
import { useModalMicrodepositsVerify } from "../ModalMicrodepositsVerify";

export function getAccountLabelProps(account: IAccount): WSLabelProps {
  return {
    text: selectorAccountInstitution(account),
    secondaryText: selectorAccountName(account),
    avatar: avatarBankAccount
  };
}

export function getInternalAccountLabelProps(
  internalAccount: components["schemas"]["InternalAccount"]
): WSLabelProps {
  let text = "Unknown";

  switch (internalAccount.type) {
    case InternalAccountType.Banking:
      text = "Wingspan Wallet";
      break;
    case InternalAccountType.TaxWithholding:
      text = "Tax Withholding";
      break;
  }

  const mask = internalAccount.numbers?.account?.slice(-4);

  return {
    text,
    secondaryText: mask ? `(${mask})` : undefined,
    avatar: avatarWallet
  };
}

export function getCreditCardLabelProps(creditCard: ICreditCard): WSLabelProps {
  return {
    text: creditCardBrandNameMap[creditCard.cardBrand || ""] || "Credit Card",
    secondaryText: creditCard.last4 ? `(${creditCard.last4})` : undefined,
    avatar: avatarBankCard
  };
}

export function getCheckbookCardLabelProps(
  checkbookCard: ICheckbookCard
): WSLabelProps {
  return {
    text: selectorDebitCardBrand(checkbookCard),
    secondaryText: checkbookCard.last4 ? `(${checkbookCard.last4})` : undefined,
    avatar: avatarBankCard
  };
}

export function getPaymentCardLabelProps(
  paymentCard: IPaymentCard
): WSLabelProps {
  return {
    text: capitalize(paymentCard.brand),
    secondaryText: paymentCard.mask ? `(${paymentCard.mask})` : undefined,
    avatar: avatarBankCard
  };
}

export function getMethods(
  data: ManageMethodsProps["data"],
  handlers: {
    openMicrodepositsVerify: ReturnType<
      typeof useModalMicrodepositsVerify
    >["open"];
    openPlaidReconnect: ReturnType<typeof usePlaidReconnect>;
  }
) {
  const methods: Method[] = [];

  data.accounts?.forEach(account => {
    const microdepositsVerificationPending =
      selectorAccountVerificationPending(account);

    methods.push({
      id: account.accountId,
      type: "Account",
      label: getAccountLabelProps(account),
      action: microdepositsVerificationPending
        ? {
            label: "Verify",
            onClick: () => {
              handlers.openMicrodepositsVerify({ account });
            }
          }
        : account.status === AccountStatus.Disconnected && account.publicToken
        ? {
            label: "Reconnect",
            onClick: () => {
              handlers.openPlaidReconnect(account);
            }
          }
        : undefined
    });
  });

  data.internalAccounts?.forEach(internalAccount => {
    methods.push({
      id: internalAccount.internalAccountId,
      type: "InternalAccount",
      label: getInternalAccountLabelProps(internalAccount)
    });
  });

  data.creditCards?.forEach(creditCard => {
    methods.push({
      id: creditCard.paymentMethodId || "",
      type: "CreditCard",
      label: getCreditCardLabelProps(creditCard)
    });
  });

  data.checkbookCards?.forEach(checkbookCard => {
    methods.push({
      id: checkbookCard.cardId,
      type: "CheckbookCard",
      label: getCheckbookCardLabelProps(checkbookCard)
    });
  });

  data.paymentCards?.forEach(paymentCard => {
    methods.push({
      id: paymentCard.paymentCardId,
      type: "PaymentCard",
      label: getPaymentCardLabelProps(paymentCard)
    });
  });

  return methods;
}
