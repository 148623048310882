import {
  WSLoader,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tab, Tabs } from "../../../../components/Tabs";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import {
  useNextGenSubscriptions,
  useSubscriptionV3Query
} from "../../../../query/subscriptions/queries";
import {
  PATH_SETTINGS_ACCOUNT_ACCOUNT,
  PATH_SETTINGS_ACCOUNT_BUSINESS,
  PATH_SETTINGS_ACCOUNT_PERSONAL,
  PATH_SETTINGS_ACCOUNT_MARKETING,
  PATH_SETTINGS_ACCOUNT_MEMBERSHIP,
  PATH_SETTINGS_ACCOUNT_INFO
} from "../../paths";
import { useMemberProfile } from "../../../../query/users/queries";
import { selectorAccountType } from "../../../../shared/selectors/selectorAccountType";
import { useShowMembershipSettings } from "../../../../userFeatures/useShowMembershipSettings";
import { RouteAccount as RouteAccountTab } from "./routes/RouteAccount";
import { RouteBusinessInformation } from "./routes/RouteBusinessInformation";
import { RouteMarketing } from "./routes/RouteMarketing";
import { RouteMembership } from "./routes/RouteMembership";
import { RoutePersonalInformation } from "./routes/RoutePersonalInformation";

export const RouteAccount = () => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const querySubscription = useSubscriptionV3Query(userId);
  const queryNextGenSubscriptions = useNextGenSubscriptions(userId);

  const accountType = useMemo(
    () => selectorAccountType(queryMonitoredEntity.data, queryMember.data),
    [queryMember.data, queryMonitoredEntity.data]
  );

  const hasSubscription = useMemo(
    () =>
      !!querySubscription.data ||
      (!!queryNextGenSubscriptions.data &&
        queryNextGenSubscriptions.data.length > 0),
    [queryNextGenSubscriptions.data, querySubscription.data]
  );

  const showMembership = useShowMembershipSettings();

  const tabs = useMemo(() => {
    const tabs: Tab[] = [];

    tabs.push({
      label: "Account",
      path: PATH_SETTINGS_ACCOUNT_ACCOUNT
    });

    if (accountType === "Business") {
      tabs.push({
        label: "Business information",
        path: PATH_SETTINGS_ACCOUNT_BUSINESS
      });
    } else {
      tabs.push({
        label: "Personal information",
        path: PATH_SETTINGS_ACCOUNT_PERSONAL
      });
    }

    tabs.push({
      label: "Marketing",
      path: PATH_SETTINGS_ACCOUNT_MARKETING
    });

    if (showMembership && hasSubscription) {
      tabs.push({
        label: "Membership",
        path: PATH_SETTINGS_ACCOUNT_MEMBERSHIP
      });
    }

    return tabs;
  }, [accountType, hasSubscription, showMembership]);

  if (queryMonitoredEntity.isInitialLoading) {
    return (
      <WSPage>
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar title="Account settings" />

      <Tabs mb="2XL" tabs={tabs} />

      <Switch>
        <Route
          path={PATH_SETTINGS_ACCOUNT_INFO}
          render={() => {
            if (accountType === "Business") {
              return <Redirect to={PATH_SETTINGS_ACCOUNT_BUSINESS} />;
            } else {
              return <Redirect to={PATH_SETTINGS_ACCOUNT_PERSONAL} />;
            }
          }}
        />

        <Route
          path={PATH_SETTINGS_ACCOUNT_ACCOUNT}
          component={RouteAccountTab}
        />

        <Route
          path={PATH_SETTINGS_ACCOUNT_PERSONAL}
          component={RoutePersonalInformation}
        />
        <Route
          path={PATH_SETTINGS_ACCOUNT_BUSINESS}
          component={RouteBusinessInformation}
        />
        <Route
          path={PATH_SETTINGS_ACCOUNT_MARKETING}
          component={RouteMarketing}
        />
        <Route
          path={PATH_SETTINGS_ACCOUNT_MEMBERSHIP}
          component={RouteMembership}
        />

        <Redirect to={PATH_SETTINGS_ACCOUNT_ACCOUNT} />
      </Switch>
    </WSPage>
  );
};
