import { operations } from "../../../services/api/onboarding/types";
import { getTaxIdLabel } from "../../../shared/utils/taxId";
import { AccountType } from "../steps/CreateAccount/AccountType";
import { BusinessInformation } from "../steps/CreateAccount/BusinessInformation";
import { PersonalInformation } from "../steps/CreateAccount/PersonalInformation";
import { Representative } from "../steps/CreateAccount/Representative";
import { Review } from "../steps/CreateAccount/Review";
import { SecureAccount } from "../steps/CreateAccount/SecureAccount";
import {
  OnboardingContext,
  OnboardingModuleCreateAccount,
  OnboardingStep,
  OnboardingStepStatus
} from "../types";

const getStatus = (
  onboardingModule: OnboardingModuleCreateAccount,
  verifications?: operations["getVerifications"]["responses"]["200"]["content"]["application/json"],
  missingDataTax?: operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"],
  missingDataBanking?: operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"]
): OnboardingStepStatus => {
  const verificationLevel =
    onboardingModule.options?.verificationLevel || "Tax";

  const status =
    verificationLevel === "Tax" ? verifications?.tax : verifications?.banking;
  const missingData =
    verificationLevel === "Tax" ? missingDataTax : missingDataBanking;

  if (status === "Verified") {
    return "Complete";
  }

  if (status === "Pending" || status === "Failed") {
    return "Pending";
  }

  const hasNoMissingFields =
    !missingData?.requiredFields || missingData?.requiredFields?.length === 0;

  if (status === "UpdateRequired" && hasNoMissingFields) {
    return "Pending";
  }

  return "None";
};

export const getCreateAccountStepConfig = (
  onboardingModule: OnboardingModuleCreateAccount,
  context: OnboardingContext,
  verifications?: operations["getVerifications"]["responses"]["200"]["content"]["application/json"],
  missingDataTax?: operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"],
  missingDataBanking?: operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"]
): OnboardingStep => {
  const slug = "create-account";

  const steps: OnboardingStep[] = [];

  steps.push({
    title: "Secure account",
    slug: "secure",
    component: SecureAccount
  });

  steps.push({
    title: "Account type",
    slug: "account-type",
    component: AccountType
  });

  if (context?.accountType?.type === "Individual") {
    steps.push({
      title: "Personal information",
      slug: "personal-information",
      component: PersonalInformation
    });
  } else {
    steps.push({
      title: "Business information",
      slug: "business-information",
      component: BusinessInformation
    });

    steps.push({
      title:
        onboardingModule.options?.verificationLevel === "Banking"
          ? "Representative & beneficial owner(s)"
          : "Representative",
      slug: "representative",
      component: Representative
    });
  }

  steps.push({
    title: "Review & submit",
    slug: "review",
    component: Review
  });

  const status = getStatus(
    onboardingModule,
    verifications,
    missingDataTax,
    missingDataBanking
  );

  const taxIdLabel = getTaxIdLabel(
    context?.accountType?.type || "Individual",
    context?.accountType?.country || "US"
  );

  return {
    title: onboardingModule.title || "Create account",
    slug,
    steps,
    status,
    result: {
      Complete: {
        title: `Your ${taxIdLabel} was verified`,
        description: `Your ${taxIdLabel} was verified. Click continue for the next step.`
      },

      Pending: {
        type: "search",
        title: `Awaiting ${taxIdLabel} verification`,
        description: `We were not able to verify your ${taxIdLabel}. We will attempt to verify it again in 24 hours and will reach out if there are any issues. Click continue for the next step.`
      }
    }
  };
};
