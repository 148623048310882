import { WSAlert } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useMethodLabelProps } from "../../../modules/Settings/components/LabelMethod/useMethodLabelProps";
import { PATH_SETTINGS_PAYROLL_METHOD } from "../../../modules/Settings/paths";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";

type Props = {};

export const AlertPayrollDebitReturn: React.FC<Props> = () => {
  const history = useHistory();

  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);

  const { isLoading, label } = useMethodLabelProps(
    queryPayrollSettings.data?.fundingSource?.fundingSourceId,
    queryPayrollSettings.data?.fundingSource?.fundingSourceType
  );

  if (isLoading) {
    return null;
  }

  return (
    <WSAlert
      theme="warning"
      icon="alert"
      title="A payroll run was unsuccessful due to a return from your bank"
      action={{
        text: "View payroll funding account settings",
        onClick: () => {
          history.push(PATH_SETTINGS_PAYROLL_METHOD);
        }
      }}
    >
      A payroll run was funded with a debit that was returned from your bank.
      Please make sure you have sufficient funds in your {label?.text}{" "}
      {label?.secondaryText}, or update the account you use for payroll funding.
      Once complete, please contact us to reprocess payment.
    </WSAlert>
  );
};
