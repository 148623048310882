import { IBulkCalculation1099Batch } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import { useWSInfiniteQuery, useWSQuery } from "../../../../query/helpers";
import {
  ListRequestQuery,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { bulkCalculation1099Service } from "../../services/bulkCalculation1099";
import {
  BULK_CALCULATE_1099_BATCH_LIST,
  QUERY_BULK_CALCULATE_1099_BATCH,
  QUERY_BULK_CALCULATE_1099_BATCH_SUMMARY
} from "./keys";
import {
  BulkBatchItemsFilter,
  BulkCalculate1099BatchesFilters,
  IBulkCalculate1099BatchSummary
} from "../../services/types";
import flatten from "lodash/flatten";

export const useBulkCalculate1099Batch = (
  batchId: string,
  config?: WSQueryConfig<IBulkCalculation1099Batch, WSServiceError>
) => {
  return useWSQuery<IBulkCalculation1099Batch, WSServiceError>(
    [QUERY_BULK_CALCULATE_1099_BATCH, batchId],
    () => bulkCalculation1099Service.batch.get(batchId),
    config
  );
};

export const useBulkCalculate1099BatchSummary = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<IBulkCalculate1099BatchSummary, WSServiceError>
) => {
  return useWSQuery<IBulkCalculate1099BatchSummary, WSServiceError>(
    [QUERY_BULK_CALCULATE_1099_BATCH_SUMMARY, [batchId, query]],
    () => bulkCalculation1099Service.batch.summary(batchId, query),
    config
  );
};

function mapBulkCalculate1099BatchFilters(
  filters?: BulkCalculate1099BatchesFilters
) {
  // TODO: Remove any
  const apiFilters: any = {};

  if (filters?.status && filters.status.length !== 5) {
    apiFilters.status = {
      in: filters.status
    };
  }

  if (filters?.filingYear) {
    apiFilters["labels.filingYear"] = `${filters?.filingYear}`;
  }
  return apiFilters;
}

export const useBulkCalculate1099BatchesQuery = (
  filters?: BulkCalculate1099BatchesFilters,
  config?: { size?: number } & WSInfiniteQueryConfig<
    IBulkCalculation1099Batch[],
    WSServiceError
  >
) => {
  const size = config?.size || 100;

  const query = useWSInfiniteQuery<IBulkCalculation1099Batch[], WSServiceError>(
    [BULK_CALCULATE_1099_BATCH_LIST, { filters, size }],
    ({ pageParam = 1 }) => {
      return bulkCalculation1099Service.batch.list({
        filter: mapBulkCalculate1099BatchFilters(filters),
        page: {
          size,
          number: pageParam
        },
        sort: filters?.sort
          ? filters.sort
          : {
              createdAt: "desc"
            }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};
