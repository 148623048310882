import { Route, Switch } from "react-router-dom";
import { InvoicesCreate } from "../../Invoices/screens/InvoicesCreate";
import { InvoicesDuplicateInvoice } from "../../Invoices/screens/InvoicesDuplicateInvoice";
import { InvoicesDuplicateInvoiceTemplate } from "../../Invoices/screens/InvoicesDuplicateInvoiceTemplate";
import { InvoicesInvoiceEdit } from "../../Invoices/screens/InvoicesInvoiceEdit";
import { InvoicesInvoiceTemplateEdit } from "../../Invoices/screens/InvoicesInvoiceTemplateEdit";
import { RouteInvoiceConfirmation } from "../../Invoices/screens/RouteInvoiceConfirmation";
import { RouteInvoiceTemplateConfirmation } from "../../Invoices/screens/RouteInvoiceTemplateConfirmation";
import { useBrowserPageTitle } from "../../shared/hooks/useBrowserPageTitle";
import { RouteDashboard } from "./routes/RouteDashboard";
import { RouteScheduled } from "./routes/RouteScheduled";
import { RouteSearch } from "./routes/RouteSearch";

export const INVOICING_PATH = "/member/invoices";

export const Invoicing: React.FC = () => {
  useBrowserPageTitle("Invoicing");

  return (
    <Switch>
      <Route
        path={INVOICING_PATH + "/:invoiceId/edit"}
        component={InvoicesInvoiceEdit}
      />
      <Route
        path={INVOICING_PATH + "/:invoiceId/duplicate"}
        exact
        component={InvoicesDuplicateInvoice}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/edit"}
        component={InvoicesInvoiceTemplateEdit}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/duplicate"}
        exact
        component={InvoicesDuplicateInvoiceTemplate}
      />
      <Route
        path={INVOICING_PATH + "/:invoiceId/send-success"}
        exact
        component={RouteInvoiceConfirmation}
      />
      <Route
        path={INVOICING_PATH + "/template/:invoiceTemplateId/send-success"}
        exact
        component={RouteInvoiceTemplateConfirmation}
      />

      <Route path={INVOICING_PATH + "/scheduled"} component={RouteScheduled} />
      <Route path={INVOICING_PATH + "/create"} component={InvoicesCreate} />
      <Route path={INVOICING_PATH + "/search"} component={RouteSearch} />
      <Route path={INVOICING_PATH} component={RouteDashboard} />
    </Switch>
  );
};
