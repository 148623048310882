import {
  WSButton,
  WSIconList,
  WSModalOld,
  WSText,
  useModalOldContext
} from "@wingspanhq/fe-component-library";
import React from "react";

export const MODAL_WALLET_INFO = "MODAL_WALLET_INFO";

export const ModalWalletInfo: React.FC = () => {
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld name={MODAL_WALLET_INFO} size="S">
      <WSText.Heading5 mb="M">Wingspan Wallet</WSText.Heading5>
      <WSText mb="M">
        Wingspan Wallet is an optional & free bank account and debit card for
        your business. You can choose how much of your income you want to set
        aside in your Wingspan Wallet, and how much you would like deposited to
        your bank account.
      </WSText>

      <WSIconList
        iconProps={{ name: "check" }}
        items={[
          "Receive your payments 1-2 day sooner",
          "Automatic write-offs of business expenses, fully integrated with Wingspan Bookkeeping",
          "No account, transaction, minimum balance or hidden fees",
          "Unlimited ACH and wire transfers for free",
          "Fee-free cash deposits and withdrawals at 40,000 ATMs nationwide."
        ]}
      />

      <WSButton.Link
        mt="XL"
        onClick={() => {
          closeModal(MODAL_WALLET_INFO);
        }}
        icon="arrow-left"
      >
        Get back
      </WSButton.Link>
    </WSModalOld>
  );
};
