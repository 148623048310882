import { useWSInfiniteQuery, WSInfiniteQueryConfig } from "@ws-react-query";
import { listInternalAccountTransactions } from "../../../services/api/banking/internalAccounts";
import { operations } from "../../../services/api/banking/types";
import { WSServiceError } from "../../../utils/serviceHelper";

export const QUERY_LIST_INTERNAL_ACCOUNT_TRANSACTIONS =
  "QUERY_LIST_INTERNAL_ACCOUNT_TRANSACTIONS";

export const useQueryListInternalAccountTransactions = (
  internalAccountId: Parameters<typeof listInternalAccountTransactions>[0],
  query?: operations["listInternalAccountTransactions"]["parameters"]["query"],
  config?: WSInfiniteQueryConfig<
    Awaited<ReturnType<typeof listInternalAccountTransactions>>,
    WSServiceError
  >
) =>
  useWSInfiniteQuery(
    [QUERY_LIST_INTERNAL_ACCOUNT_TRANSACTIONS, internalAccountId, query],
    () => listInternalAccountTransactions(internalAccountId, query),
    {
      getNextPageParam: () => false,
      ...config
    }
  );
