import { toWSDateString, WSAlert } from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { getPayeeName } from "../../../modules/Payees/selectors/getPayeeNames";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";
import { usePayablesQuery } from "../NewPayablesTable/PayablesTable";

interface Props {
  payeeId: string;
}

const payableStatusToSentenceMap = {
  [InvoiceStatus.Cancelled]: "A cancelled",
  [InvoiceStatus.Draft]: "A draft",
  [InvoiceStatus.Open]: "An open",
  [InvoiceStatus.Overdue]: "An overdue",
  [InvoiceStatus.Paid]: "A paid",
  [InvoiceStatus.PaymentInTransit]: "A payment in transit",
  [InvoiceStatus.Pending]: "A pending"
};

export const AlertExistingPayable: React.FC<Props> = props => {
  const history = useHistory();

  const queryPayables = usePayablesQuery(
    {
      payeeId: props.payeeId
    },
    {
      size: 1
    }
  );
  const queryPayee = usePayeeQuery(props.payeeId);

  const payable = queryPayables.data?.data[0];
  const payee = queryPayee.data;

  if (!payable || !payee) {
    return null;
  }

  return (
    <WSAlert
      mt="L"
      theme="warning"
      action={{
        text: "Edit payable",
        onClick: () => {
          history.push(`/member/invoices/payables/${payable.payableId}/edit`);
        }
      }}
    >
      {payableStatusToSentenceMap[payable.status]} payable exists to{" "}
      {getPayeeName(payee)} due{" "}
      {toWSDateString(payable.dueDate, "monthDayYear")}.
    </WSAlert>
  );
};
