import React from "react";
import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { selectorFileSharingRequestContractorNotifications } from "../../selectors/selectorFileSharingRequestContractorNotifications";
import { useUserId } from "../../../../query/hooks/helpers";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import styles from "./styles.module.scss";
import { useUploadFileModal } from "../../../Requirements/modules/SharedFile/components/useUploadFileModal";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";

type RequestsToComplete = {
  title: string;
  description: string;
  onClick: () => Promise<void>;
};

export const RouteFilesToShare: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryNotifications = useNotifications();
  const uploadFileModal = useUploadFileModal();

  return (
    <WSQueries queries={{ queryNotifications }}>
      {({ queryNotificationsData }) => {
        const requests: RequestsToComplete[] = [];

        const notifications = selectorFileSharingRequestContractorNotifications(
          queryNotificationsData
        );
        notifications.forEach(notification => {
          const title = notification.context.title;
          const payerName = notification.context.payer.payerName;
          const payerPayeeEngagementId =
            notification.context.payerPayeeEngagementId;
          const sharedFileRequestId = notification.context.sharedFileRequestId;
          const sharedFileRequestSettingsId =
            notification.context.sharedFileRequestSettingsId;
          const payerId = notification.context.payer.payerId;
          const payeeId = notification.context.payerPayeeId;

          requests.push({
            title,
            description: `${payerName} requires you sharing ${title} to complete the onboarding process`,
            onClick: async () => {
              trackClickNotification(notification);
              await uploadFileModal.open({
                sharedFileRequestId,
                sharedFileRequestSettingsId,
                payerId,
                payeeId,
                onSuccess: () => {
                  WSQueryCache.refetchQueries(
                    QUERY_NOTIFICATIONS_NOTIFICATIONS
                  );
                }
              });
            }
          });
        });

        return (
          <WSPage
            breadcrumb={{
              label: "Back",
              onClick() {
                history.push("/member/dashboard");
              },
              icon: "arrow-left"
            }}
          >
            <WSText.Heading4 my="XL">
              File sharing required ({requests.length})
            </WSText.Heading4>
            {requests.length === 0 ? (
              <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
                No sharing requests to complete
              </WSText.ParagraphSm>
            ) : (
              requests.map(({ title, description, onClick }) => {
                return (
                  <WSPanel
                    key={title}
                    mb="M"
                    as="label"
                    className={styles.item}
                  >
                    <WSFlexBox.CenterY justify="space-between">
                      <WSElement>
                        <WSText weight="medium" pb="M">
                          {title}
                        </WSText>
                        <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
                      </WSElement>
                      <WSButton.Secondary size="S" onAsyncClick={onClick}>
                        Start
                      </WSButton.Secondary>
                    </WSFlexBox.CenterY>
                  </WSPanel>
                );
              })
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
