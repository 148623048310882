import { WSList } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { AlertPendingAccountMicroDeposit } from "../../../modules/Settings/components/AlertPendingAccountMicroDeposit";
import { ManagePaymentMethod } from "../../../modules/Settings/components/payment/ManagePaymentMethod";
import { Layout } from "../../../shared/components/Layout";

export const RouteManagePaymentMethod: React.FC = () => {
  const history = useHistory();

  return (
    <Layout
      title="Manage payment method"
      onClose={() => {
        history.push("/payment/history");
      }}
    >
      <WSList gap="2XL">
        <AlertPendingAccountMicroDeposit />

        <ManagePaymentMethod
          onNext={() => {
            history.push("/payment/history");
          }}
          onAddNew={() => {
            history.push("/payment/add-payment-method");
          }}
        />
      </WSList>
    </Layout>
  );
};
