import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYEE_ENGAGEMENT } from "../keys";
import { getPayeeEngagement } from "../../../services/payeeEngagement";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const usePayeeEngagementQuery = (
  payeeId: string,
  engagementId: string,
  config?: WSQueryConfig<IPayeeEngagementResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSQuery<IPayeeEngagementResponse, WSServiceError>(
    [QUERY_PAYEE_ENGAGEMENT, payeeId, engagementId, xWingspanPayerId],
    async () => {
      return await getPayeeEngagement(payeeId, engagementId, xWingspanPayerId);
    },
    {
      ...config
    }
  );
};
