import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { QUERY_PUBLIC_CUSTOMIZATION } from "../keys";
import { getCustomizationForUser } from "../../../services/customizations";
import { IPublicCustomizationSettingsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/organizationAccountConfig";

export const usePublicCustomizationQuery = (
  userId: string,
  config?: WSQueryConfig<IPublicCustomizationSettingsResponse, WSServiceError>
) => {
  return useWSQuery<IPublicCustomizationSettingsResponse, WSServiceError>(
    [QUERY_PUBLIC_CUSTOMIZATION, userId],
    () => getCustomizationForUser(userId),
    {
      ...config,
      onSuccess: result => {
        window.organizationAccountConfig = result;
        config?.onSuccess?.(result);
      }
    }
  );
};
