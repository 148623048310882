import { WSQueryConfig } from "@ws-react-query";
import {
  IPayerListResponse,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";

import {
  getClientsListSize,
  getPayers,
  PayersApiFilter,
  PayersFilter
} from "../../../services/payers";
import { useWSQuery } from "../../helpers";
import { QUERY_ALL_PAYER_LIST } from "../keys";
import {
  concurrentActions,
  ListRequestQuery,
  WSServiceError
} from "../../../utils/serviceHelper";
import times from "lodash/times";
import flatten from "lodash/flatten";

export const mapClientFilters = (filters?: PayersFilter) => {
  let filter: PayersApiFilter = {};

  if (filters?.status) {
    filter["payeeOwnedData.status"] = {
      in: [filters.status] as PayerPayeeStatus[]
    };
  }

  return filter;
};

export const useAllPayerListQuery = (
  query?: ListRequestQuery<
    PayersFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  queryConfig?: WSQueryConfig<IPayerListResponse, WSServiceError>
) =>
  useWSQuery(
    QUERY_ALL_PAYER_LIST,
    async () => {
      const pageSize = 100;
      const _query = {
        filter: mapClientFilters(query?.filter)
      };
      const listSize = await getClientsListSize(_query);
      const pages = Math.ceil(listSize / pageSize);

      const actions = times(pages).map(
        (_, i) => () =>
          getPayers({
            ..._query,
            page: { size: pageSize, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const allClients = flatten(allPages);

      return allClients;
    },
    {
      retry: false,
      ...queryConfig
    }
  );
