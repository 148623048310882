import flatten from "lodash/flatten";
import { WSInfiniteQueryConfig } from "@ws-react-query";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getInvoiceRows, IInvoiceRow, InvoiceRowsQuery } from "../service";

const PAGE_SIZE = 20;

export const QUERY_KEY_INVOICES_ROWS = "QUERY_KEY_INVOICES_ROWS";

export const useQueryInvoicesRows = (
  requestQuery?: InvoiceRowsQuery,
  config?: WSInfiniteQueryConfig<IInvoiceRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IInvoiceRow[], WSServiceError>(
    [QUERY_KEY_INVOICES_ROWS, requestQuery],
    ({ pageParam = 1 }) => {
      return getInvoiceRows({
        ...requestQuery,
        page: {
          size: requestQuery?.page?.size || PAGE_SIZE,
          number: pageParam
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};
