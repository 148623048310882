import StackdriverErrorReporter from "stackdriver-errors-js";
import { BUILD_ENV } from "./shared/constants/environment";

const errorReporter = new StackdriverErrorReporter();

const apiKey = process.env.REACT_APP_STACKDRIVER_ERRORS;

if (!apiKey) {
  console.warn("Error reporting is disabled. No API key provided.");
}

errorReporter.start({
  key: apiKey! || "ABC", // Putting ABC, because it fails if we put undefined
  disabled: !apiKey,
  projectId: `wingspan-${BUILD_ENV}`,
  service: "wingspan-app",
  version: process.env.version
});
