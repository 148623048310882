import {
  useModalOldContext,
  WSAvatar,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSTable,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import { DocumentRole, ITemplate } from "@wingspanhq/files/dist/lib/interfaces";
import { IEligibilityRequirement } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React from "react";
import {
  Route,
  RouteComponentProps,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { CollaboratorGroupAddRequirement } from "./CollaboratorGroupAddRequirement";
import { CollaboratorGroupRequirementDetails } from "./CollaboratorGroupRequirementDetails";
import { UPDATE_COLLABORATOR_GROUP_MODAL } from "../../components/UpdateCollaboratorGroupModal";
import {
  useCollaboratorGroupQuery,
  useEligibilityRequirementsQuery
} from "../../../../query/payments/queries";
import { DetailsLayout } from "../../../../components/DetailsLayout/DetailsLayout";
import { WSQueries } from "../../../../query/WSQuery";
import { useTemplatesQuery } from "../../../../query/files/queries";
import {
  buildGroupAddRequirementPath,
  buildGroupRequirementDetailsPath,
  PAYEES_ENGAGEMENTS_ADD_REQUIREMENT_ROUTE,
  PAYEES_GROUPS_LIST_PATH,
  PAYEES_ENGAGEMENTS_REQUIREMENTS_DETAILS_ROUTE
} from "../../utils";
import { useTaxFormRequirementInfoModal } from "../../components/TaxFormRequirementInfoModal";
import { usePayoutMethodRequirementInfoModal } from "../../components/PayoutMethodRequirementInfoModal";

export const CollaboratorGroupDetails: React.FC<
  RouteComponentProps<{
    collaboratorGroupId: string;
  }>
> = ({
  match: {
    params: { collaboratorGroupId }
  }
}) => {
  const history = useHistory();
  const collaboratorGroupQuery = useCollaboratorGroupQuery(collaboratorGroupId);

  const { openModal } = useModalOldContext();

  return (
    <DetailsLayout
      onBack={() => {
        history.push(PAYEES_GROUPS_LIST_PATH);
      }}
      title={collaboratorGroupQuery.data?.name || ""}
    >
      <Route
        path={PAYEES_ENGAGEMENTS_ADD_REQUIREMENT_ROUTE}
        component={CollaboratorGroupAddRequirement}
      />
      <Route
        path={PAYEES_ENGAGEMENTS_REQUIREMENTS_DETAILS_ROUTE}
        component={CollaboratorGroupRequirementDetails}
      />
      <WSQueries queries={{ collaboratorGroupQuery }}>
        {({ collaboratorGroupQuery: { data: collaboratorGroup } }) => (
          <>
            <DetailsLayout.Card mb="XL">
              <WSFlexBox wrap="nowrap" justify="space-between">
                <WSElement>
                  <WSText weight="medium" mb="M">
                    {collaboratorGroup.name}
                  </WSText>
                  <WSText>{collaboratorGroup.description}</WSText>
                </WSElement>
                <WSButton.Link
                  onClick={() => {
                    openModal(UPDATE_COLLABORATOR_GROUP_MODAL, {
                      id: collaboratorGroupId,
                      name: collaboratorGroup.name,
                      description: collaboratorGroup.description
                    });
                  }}
                >
                  Edit
                </WSButton.Link>
              </WSFlexBox>
            </DetailsLayout.Card>

            <DetailsLayout.Card mb="XL">
              <WSText weight="medium" mb="M">
                Requirements
              </WSText>

              <RequirementsTable
                ids={(collaboratorGroup.eligibilityRequirements || []).map(
                  e => e.eligibilityRequirementId
                )}
              />
            </DetailsLayout.Card>

            <DetailsLayout.Card>
              <WSButton
                onClick={() => {
                  history.push(
                    buildGroupAddRequirementPath(collaboratorGroupId)
                  );
                }}
                fullWidth
                mb="M"
              >
                Add requirement
              </WSButton>

              <WSText.ParagraphSm color="gray500">
                Contractors in this group will be required to meet these
                requirements before they can receive payments. You can still pay
                these contractors, but the funds will be held until their
                requirements are complete. Contractors only need to complete a
                requirement once, even if they are in multiple groups that
                require it.
              </WSText.ParagraphSm>
            </DetailsLayout.Card>
          </>
        )}
      </WSQueries>
    </DetailsLayout>
  );
};

const RequirementsTable: React.FC<{ ids: string[] }> = ({ ids }) => {
  const eligibilityRequirementsQuery = useEligibilityRequirementsQuery();
  const templatesQuery = useTemplatesQuery();
  const taxInfoModal = useTaxFormRequirementInfoModal();
  const payoutMethodInfoModal = usePayoutMethodRequirementInfoModal();
  const history = useHistory();
  const {
    params: { collaboratorGroupId }
  } = useRouteMatch<{ collaboratorGroupId: string }>();

  return (
    <>
      <WSQueries queries={{ eligibilityRequirementsQuery, templatesQuery }}>
        {({
          eligibilityRequirementsQuery: { data: eligibilityRequirements },
          templatesQuery: { data: templates }
        }) => {
          const visibleEligibilityRequirements = eligibilityRequirements.filter(
            e => ids.includes(e.eligibilityRequirementId)
          );

          return (
            <WSTable<{
              eligibilityRequirement: IEligibilityRequirement;
              template?: ITemplate;
            }>
              tableData={[
                {
                  id: "payoutMethod",
                  data: {} as any
                },
                {
                  id: "taxForm",
                  data: {} as any
                },
                ...visibleEligibilityRequirements.map(
                  eligibilityRequirement => {
                    const template = templates.find(
                      t => t.templateId === eligibilityRequirement.templateId
                    );
                    return {
                      id: eligibilityRequirement.eligibilityRequirementId,
                      data: {
                        eligibilityRequirement,
                        template
                      },
                      icon: (template?.roles || []).includes(
                        DocumentRole.Client
                      )
                        ? {
                            icon: "group",
                            color: "green500",
                            colorBackground: "green50"
                          }
                        : {
                            icon: "user",
                            color: "blue500",
                            colorBackground: "blue50"
                          }
                    } as WSTableItem;
                  }
                )
              ]}
              onRowClick={({ id, data }) => {
                if (id === "payoutMethod") {
                  payoutMethodInfoModal.open();
                } else if (id === "taxForm") {
                  taxInfoModal.open();
                } else {
                  history.push(
                    buildGroupRequirementDetailsPath(
                      collaboratorGroupId,
                      data.eligibilityRequirement.eligibilityRequirementId
                    )
                  );
                }
              }}
              columns={[
                {
                  config: {
                    gridTemplateSizeMax: "3fr"
                  },
                  renderFunction: ({ id, data }) => (
                    <WSFlexBox alignItems="center">
                      <WSAvatar
                        mr="S"
                        type="icon"
                        {...((data.template?.roles || []).includes(
                          DocumentRole.Client
                        )
                          ? {
                              icon: "group",
                              color: "green500",
                              colorBackground: "green50"
                            }
                          : {
                              icon: "user",
                              color: "blue500",
                              colorBackground: "blue50"
                            })}
                      />
                      <WSElement>
                        <WSFlexBox.CenterY wrap="nowrap">
                          <WSText>
                            {id === "payoutMethod"
                              ? "Payout Method"
                              : id === "taxForm"
                              ? "Tax Form"
                              : data.template?.title}
                          </WSText>
                          {id === "payoutMethod" ? (
                            <WSIcon
                              block
                              ml="M"
                              name="info-circle"
                              color="gray500"
                              onClick={() => {
                                payoutMethodInfoModal.open();
                              }}
                            />
                          ) : id === "taxForm" ? (
                            <WSIcon
                              block
                              ml="M"
                              name="info-circle"
                              color="gray500"
                              onClick={() => {
                                taxInfoModal.open();
                              }}
                            />
                          ) : null}
                        </WSFlexBox.CenterY>
                        {id === "taxForm" && (
                          <WSText.ParagraphXs color="gray400" mt="XS">
                            W-9 or W-8 BEN
                          </WSText.ParagraphXs>
                        )}
                      </WSElement>
                    </WSFlexBox>
                  )
                },
                {
                  config: {
                    gridTemplateSizeMax: "1fr",
                    justify: "end"
                  },
                  renderFunction: ({ id }) =>
                    id === "payoutMethod" || id === "taxForm" ? (
                      <WSPill text="Default" />
                    ) : null
                }
              ]}
            />
          );
        }}
      </WSQueries>
    </>
  );
};
