import {
  WSSelectOption,
  WSSelectSearch,
  WSSelectSearchProps
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { Nullable } from "primereact/ts-helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePayerQuery } from "../../../../query/payers/queries/usePayerQuery";
import { useQueryPayerRows } from "../../../../query/search/payer/queries/useQueryPayerRows";

type Value = string | null;

type Props = {
  value: Value;
  onChange: (value: Value) => void;
} & Omit<WSSelectSearchProps, "options" | "value" | "onChange">;

export const SelectPayer: React.FC<Props> = ({
  value,
  onChange,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectValue, setSelectValue] =
    useState<Nullable<WSSelectOption>>(null);

  const queryPayerRows = useQueryPayerRows({
    filter: {
      searchString: searchText || undefined
    }
  });

  const queryPayer = usePayerQuery(value as string, {
    enabled: !!value
  });

  const payer = queryPayer.data;

  useEffect(() => {
    if (!payer || !payer.user) {
      if (value) {
        return;
      }

      setSelectValue(null);
      return;
    }

    const payerNames = wsName({
      user: payer.user,
      member: payer.member,
      payeeOwnedData: payer.payeeOwnedData
    });

    const name = payerNames.getResolvedName();
    const secondaryName = payerNames.getResolvedSecondaryName();

    setSelectValue({
      value: payer.payerId,
      label: name,
      labels: [secondaryName].filter(Boolean) as string[],
      avatar: {
        type: "text",
        text: name
      }
    });
  }, [payer, value]);

  const options = useMemo(() => {
    const options: WSSelectOption[] = [];

    (queryPayerRows.data || []).forEach(payer => {
      if (!payer.user) {
        return;
      }

      const payerNames = wsName({
        user: payer.user,
        member: payer.member,
        payeeOwnedData: payer.payeeOwnedData
      });

      const name = payerNames.getResolvedName();
      const secondaryName = payerNames.getResolvedSecondaryName();

      options.push({
        value: payer.payerId,
        label: name,
        labels: [secondaryName].filter(Boolean) as string[],
        avatar: {
          type: "text",
          text: name
        }
      });
    });

    return options;
  }, [queryPayerRows.data]);

  const onReachMenuEnd = useCallback(() => {
    if (queryPayerRows.hasNextPage) {
      queryPayerRows.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayerRows.hasNextPage]);

  return (
    <WSSelectSearch
      {...otherProps}
      value={selectValue}
      onChange={newOption => {
        if (newOption === null) {
          onChange(null);
          setSelectValue(null);
          return;
        } else if (newOption?.value) {
          onChange(newOption?.value as string);
          setSelectValue(newOption);
        }
      }}
      placeholder="All clients"
      options={options}
      status={queryPayerRows.isInitialLoading ? "loading" : otherProps.status}
      externalSearchText={searchText}
      onExternalSearchChange={setSearchText}
      isListLoading={
        (queryPayerRows.isFetching && !queryPayerRows.isFetchingNextPage) ||
        queryPayer.isInitialLoading
      }
      onReachMenuEnd={onReachMenuEnd}
    />
  );
};
