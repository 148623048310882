import {
  WSActions,
  WSCard,
  WSControlGroup,
  WSControlGroupCards,
  WSElement,
  WSList,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { ManualAccountSubType, ManualAccountType } from "./types";

type Value = {
  type: ManualAccountType;
  subType: ManualAccountSubType;
};

type Props = {
  onBack?: () => void;
  onNext: (value: Value) => void;
  value: Value;
};

export const FormType: React.FC<Props> = props => {
  const [value, setValue] = useState<Value>(props.value);

  return (
    <WSList gap="2XL">
      <WSCard>
        <WSList gap="2XL">
          <WSSectionToolbar title="Select the type of bank account to add" />

          <WSControlGroupCards
            value={value.type}
            onChange={newValue => {
              setValue(prev => ({
                ...prev,
                type: newValue as ManualAccountType
              }));
            }}
            orientation="horizontal"
            options={[
              {
                header: {
                  label: {
                    text: "Personal account",
                    avatar: {
                      type: "icon",
                      icon: "user",
                      color: "white",
                      colorBackground: "gray700"
                    }
                  }
                },
                children: (
                  <>
                    <WSText.ParagraphSm color="gray500">
                      A personal bank account owned by an individual for banking
                      needs.
                    </WSText.ParagraphSm>
                    {value.type === "personal" ? (
                      <WSElement mt="L" pl="XL">
                        <WSControlGroup
                          type="radio"
                          name="subtype"
                          value={value.subType}
                          onChange={newValue => {
                            setValue(prev => ({
                              ...prev,
                              subType: newValue as ManualAccountSubType
                            }));
                          }}
                          options={[
                            {
                              value: "checking",
                              label: "Checking account"
                            },
                            {
                              value: "savings",
                              label: "Savings account"
                            }
                          ]}
                        />
                      </WSElement>
                    ) : null}
                  </>
                ),
                value: "personal"
              },
              {
                header: {
                  label: {
                    text: "Business account",
                    avatar: {
                      type: "icon",
                      icon: "briefcase",
                      color: "white",
                      colorBackground: "gray700"
                    }
                  }
                },
                value: "business",
                children: (
                  <WSText.ParagraphSm color="gray500">
                    A business bank account owned by any business structure
                    including corporations, LLCs, partnerships, and sole
                    proprietorships.
                  </WSText.ParagraphSm>
                )
              }
            ]}
          />
        </WSList>
      </WSCard>

      <WSActions
        mb="2XL"
        alignment="fill"
        buttons={[
          {
            label: "Back",
            kind: "Secondary",
            onClick: props.onBack,
            visible: !!props.onBack
          },
          {
            label: "Continue",
            onClick: () => {
              props.onNext(value);
            }
          }
        ]}
      />
    </WSList>
  );
};
