import React, { useState } from "react";
import {
  AccountUsage,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces/account";
import { useUpdateAccount } from "../../../query/users/mutations";
import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSModalOld
} from "@wingspanhq/fe-component-library";
import { DELETE_ACCOUNT_MODAL, DeleteAccount } from "./DeleteAccount";

export const EDIT_ACCOUNT_MODAL = "EDIT_ACCOUNT_MODAL";

const EditAccountBody: React.FC<{ account: IAccount }> = ({ account }) => {
  const [updateAccount, updateAccountMeta] = useUpdateAccount();
  const { closeModal, openModal } = useModalOldContext();
  const [isBusiness, setIsBusiness] = useState<boolean>();

  return (
    <>
      <WSCheckboxToggle
        name={account.accountId}
        value={isBusiness ?? account.usage === AccountUsage.Business}
        label="Dedicated business account"
        description="We’ll automatically mark all transactions as business so you can skip manual review."
        onChange={value => setIsBusiness(value)}
      />

      <WSButton.Secondary
        destructive
        mt="XL"
        onClick={() => {
          openModal(DELETE_ACCOUNT_MODAL, account);
        }}
      >
        Delete account
      </WSButton.Secondary>

      <WSButtons mt="2XL" forceFullWidth format="modal">
        <WSButton.Primary
          loading={updateAccountMeta.isLoading}
          onClick={async () => {
            const business =
              isBusiness ?? account.usage === AccountUsage.Business;

            await updateAccount({
              accountId: account.accountId,
              usage: business ? AccountUsage.Business : AccountUsage.Mixed
            });

            closeModal(EDIT_ACCOUNT_MODAL);
          }}
        >
          Update
        </WSButton.Primary>
        <WSButton.Secondary
          onClick={() => {
            closeModal(EDIT_ACCOUNT_MODAL);
          }}
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </>
  );
};

export const EditAccount: React.FC = () => {
  const { closeModal, openModal } = useModalOldContext();
  return (
    <>
      <DeleteAccount
        onSuccess={() => {
          closeModal(EDIT_ACCOUNT_MODAL);
        }}
      />
      <WSModalOld size="XS" name={EDIT_ACCOUNT_MODAL} title="Edit account">
        {(account: IAccount) => <EditAccountBody account={account} />}
      </WSModalOld>
    </>
  );
};
