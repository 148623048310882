import { WSSelect } from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { BulkBatchesFilters } from "../../services/types";
import { useUrlQueryFilters } from "../../../../utils/router";

type Props = {
  defaultYear?: number;
};

export const FilterSelectYear: React.FC<Props> = ({ defaultYear }) => {
  const currentYear = new Date().getFullYear();
  const defaultYearValue = (defaultYear || currentYear).toString();
  const { filters, setFilters } = useUrlQueryFilters<BulkBatchesFilters>({
    year: defaultYearValue
  });

  const options = useMemo(() => {
    // create an array of years from 2021 to current year
    const years = Array.from(
      new Array(currentYear - 2020),
      (val, index) => 2021 + index
    ).reverse();
    return years.map(year => ({
      value: year.toString(),
      label: year.toString()
    }));
  }, [currentYear]);

  const onChange = (newValue: unknown) => {
    setFilters({ year: newValue as string });
  };

  return (
    <WSSelect
      hideClearAction={true}
      mode="single"
      options={options}
      value={filters.year}
      onChange={onChange}
    />
  );
};
