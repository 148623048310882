import React, { useMemo } from "react";
import {
  GeneralRequirementType,
  IPayeeResponse
} from "@wingspanhq/payments/dist/interfaces";
import {
  toWSDateString,
  WSElementProps,
  WSMenuItem,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import {
  IGeneralEligibilityRequirement,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { usePayeeDownloadW9 } from "../../../../query/payee/mutations/usePayeeDownloadW9";
import { getTaxFormRequirementName } from "./utils";

export const GeneralRequirementsTable: React.FC<
  {
    payee: IPayeeResponse;
    requirements?: IGeneralEligibilityRequirement[];
    noActions?: boolean;
    payerCountry?: string;
  } & WSElementProps
> = ({
  payee,
  payerCountry,
  requirements = [],
  noActions,
  ...elementProps
}) => {
  const [downloadW9, downloadW9Meta] = usePayeeDownloadW9();

  const isDomesticPayee = payee.member?.profile?.address?.country
    ? payee.member?.profile?.address?.country === "US"
    : true;

  const tableData = useMemo(() => {
    return requirements.map((requirement, index) => ({
      id: `${index}`,
      data: requirement
    }));
  }, [requirements]);

  const isInactive = payee.payerOwnedData.status === PayerPayeeStatus.Inactive;

  return (
    <>
      {downloadW9Meta.isLoading && <OverlaySpinner />}

      <WSTable
        tableData={tableData}
        columns={[
          {
            config: {
              header: {
                text: "Requirement"
              },
              gridTemplateSizeMax: "1.5fr"
            },
            renderFunction: ({ data }) => {
              const requirementType = data.requirementType;

              return (
                <WSTableCell
                  avatar={{
                    format: "square",
                    // colorBackground: "gray100",
                    type: "icon",
                    ...(requirementType === GeneralRequirementType.PayoutMethod
                      ? {
                          icon: "bank",
                          colorBackground: "gray500",
                          color: "white"
                        }
                      : requirementType ===
                        GeneralRequirementType.TaxVerification
                      ? {
                          icon: "clipboard",
                          colorBackground: "gray500",
                          color: "white"
                        }
                      : requirementType === GeneralRequirementType.Registration
                      ? {
                          icon: "user-check",
                          colorBackground: "gray500",
                          color: "white"
                        }
                      : {
                          icon: "paperclip",
                          colorBackground: "gray500",
                          color: "white"
                        })
                  }}
                  text={
                    requirementType === GeneralRequirementType.PayoutMethod
                      ? "Payout method"
                      : requirementType ===
                        GeneralRequirementType.TaxVerification
                      ? "Taxpayer information"
                      : requirementType === GeneralRequirementType.Registration
                      ? "Sign up"
                      : "-"
                  }
                  secondaryText={
                    data.requirementType === GeneralRequirementType.PayoutMethod
                      ? undefined
                      : data.requirementType ===
                        GeneralRequirementType.TaxVerification
                      ? getTaxFormRequirementName(payee, payerCountry)
                      : undefined
                  }
                />
              );
            }
          },
          {
            config: {
              gridTemplateSizeMax: "1fr",
              hideOnScreens: ["XS"],
              header: {
                text: "Type"
              }
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                text={
                  data.requirementType === GeneralRequirementType.PayoutMethod
                    ? "Bank account"
                    : data.requirementType ===
                      GeneralRequirementType.TaxVerification
                    ? "Form"
                    : data.requirementType ===
                      GeneralRequirementType.Registration
                    ? "Onboarding"
                    : "-"
                }
              />
            )
          },
          {
            config: {
              header: {
                text: "Status"
              }
            },
            renderFunction: ({ data }) => {
              if (isInactive) {
                return "--";
              }
              return (
                <WSTableCell
                  pill={
                    data.status === RequirementStatus.Complete
                      ? {
                          theme: "success",
                          text: "Complete",
                          icon: true
                        }
                      : {
                          theme: "warning",
                          text: "Pending",
                          icon: true
                        }
                  }
                />
              );
            }
          }
        ]}
        rowMenuActions={({ data }) => {
          const actions: WSMenuItem[] = [];

          if (
            payee.payeeOwnedData.shareTaxDocument ===
              PayeeTaxDocumentSharePermission.Allow &&
            isDomesticPayee &&
            data.requirementType === GeneralRequirementType.TaxVerification &&
            data.status === RequirementStatus.Complete
          ) {
            actions.push({
              onClick: () => {
                downloadW9({
                  payeeId: payee.payeeId,
                  filename:
                    payee.user?.email ||
                    `${toWSDateString(new Date(), "monthDayYear")}`
                });
              },
              label: "Download PDF",
              icon: "download"
            });
          }

          return actions;
        }}
        {...elementProps}
      />
    </>
  );
};
