import { WSModalOld, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

export const BOOKKEEPING_WHICH_TRANSACTIONS_MATTER =
  "BOOKKEEPING_WHICH_TRANSACTIONS_MATTER";

export const WhichTransactionsMatter: React.FC = () => {
  return (
    <WSModalOld
      name={BOOKKEEPING_WHICH_TRANSACTIONS_MATTER}
      title="Which transactions matter?"
      size="S"
    >
      <WSText mb="M">
        When you're self-employed, the IRS considers you a business. That means
        you can deduct reasonable and necessary business expenses from your
        profit on your tax return, reducing your taxable profit.
      </WSText>
      <WSText mb="M">
        You can deduct expenses like advertising, software subscriptions,
        vehicle expenses, contract labor, home office expenses and more.
      </WSText>
    </WSModalOld>
  );
};
