import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { TAXES_MODAL_REQUEST_SCHEDULE_C_PDF } from "./TaxesModalRequestScheduleCPDF";

import { CURRENT_TAX_DOCUMENTS_YEAR } from "../../../shared/utils/taxes";

export const TAXES_MODAL_ENABLE_BOOKKEEPING = "TAXES_MODAL_ENABLE_BOOKKEEPING";

export const TaxesModalEnableBookkeeping: React.FC = () => {
  const history = useHistory();
  const { closeModal, openModal } = useModalOldContext();

  return (
    <WSModalOld
      name={TAXES_MODAL_ENABLE_BOOKKEEPING}
      title="No transactions in Wingspan"
      size="S"
    >
      <WSText mb="3XL">
        You don’t have any transaction records in Wingspan for{" "}
        {CURRENT_TAX_DOCUMENTS_YEAR}. Your document will be blank.
      </WSText>

      <WSButtons forceFullWidth format="modal">
        <WSButton.Primary
          onClick={() => {
            history.push("/member/bookkeeping");
          }}
        >
          Import transactions
        </WSButton.Primary>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(TAXES_MODAL_ENABLE_BOOKKEEPING);
            openModal(TAXES_MODAL_REQUEST_SCHEDULE_C_PDF);
          }}
        >
          Proceed anyway
        </WSButton.Tertiary>
      </WSButtons>
    </WSModalOld>
  );
};
