import { WSQueryCache } from "@ws-react-query";
import * as Yup from "yup";
import {
  useWSSnackbar,
  WSActions,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSInputText,
  WSText,
  WSTooltip
} from "@wingspanhq/fe-component-library";

import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../../utils/validators";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { AdditionalEmailsField } from "../../../../Invoices/components/AdditionalEmailsField/AdditionalEmailsField";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayer } from "../../../../query/payers/mutations/useUpdatePayer";
import { useRef } from "react";
import { QUERY_PAYER, QUERY_PAYER_LIST } from "../../../../query/payers/keys";
import { updateArrayString } from "../../../../utils/serviceHelper";

export interface EditClientModalProps extends WSElementProps {
  client: IPayerResponse;
  onSuccess?: (client: IPayerResponse) => void;
  onClose: () => void;
}

export const EditClientModal: React.FC<EditClientModalProps> = ({
  client,
  onSuccess,
  onClose,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const tooltipIconRef = useRef<HTMLElement>(null);

  const [updateClient, updateClientMeta] = useUpdatePayer();

  return (
    <WSElement {...elementProps}>
      <WSDivider mb="XL" />
      <WSForm<{
        company: string;
        contact: string;
        additionalEmails?: { email: string }[];
      }>
        defaultValues={{
          company: client.payeeOwnedData.payerCompanyName || "",
          contact: client.payeeOwnedData.payerName || "",
          additionalEmails:
            client.payeeOwnedData.payerEmailCC?.map(email => ({
              email
            })) || []
        }}
        onSubmit={values => {
          const updatedPayerEmailCC = updateArrayString(
            client.payeeOwnedData.payerEmailCC ?? [],
            (values.additionalEmails || [])?.map(e => e.email)
          );
          const request = {
            id: client.payerId,
            payeeOwnedData: {
              payerName: values.contact,
              payerCompanyName: values.company,
              payerEmailCC: updatedPayerEmailCC
            }
          };

          updateClient(request, {
            onSuccess: client => {
              // Pre-populate the query cache with the new client
              const previous: undefined | IPayerResponse[] =
                WSQueryCache.getQueryData(QUERY_PAYER_LIST) || [];
              const newData = [client, ...previous];
              WSQueryCache.setQueryData(QUERY_PAYER_LIST, newData);
              WSQueryCache.invalidateQueries(QUERY_PAYER);

              onSuccess?.(client);
              onClose();

              openSnackbar({
                duration: 5000,
                type: "success",
                message: "Client updated successfully"
              });
            }
          });
        }}
        validationSchema={Yup.object()
          .shape(
            {
              company: Yup.string().when("contact", {
                is: (contact: string) => contact?.length > 0,
                then: contactCompanyValidator,
                otherwise: contactCompanyValidator.required(
                  "At least one of these fields muse be provided"
                )
              }),
              contact: Yup.string().when("company", {
                is: (company: string) => company?.length > 0,
                then: contactNameValidator,
                otherwise: contactNameValidator.required(
                  "At least one of these fields muse be provided"
                )
              })
            },
            [["company", "contact"]]
          )
          .shape({
            additionalEmails: Yup.array()
              .of(
                Yup.object().shape({
                  email: validatorEmail.notOneOf(
                    [client.user?.email],
                    "Additional email can't be the same as the client email"
                  )
                })
              )
              .uniqueByProperty("Additional email must be unique", "email")
          })}
      >
        <WSText.ParagraphSm color="gray500">
          Client company or name *
        </WSText.ParagraphSm>
        <WSText.ParagraphXs color="gray400">
          At least one of these fields must be provided
        </WSText.ParagraphXs>
        <WSForm.Field
          mt="XL"
          name="company"
          label="Client company"
          component={WSInputText}
          mb="XL"
        />
        <WSForm.Field
          name="contact"
          label="Contact name"
          component={WSInputText}
        />

        <WSDivider my="2XL" />

        <WSFlexBox.CenterY mb="XS">
          <WSText.ParagraphSm color="gray500" mr="XS">
            Client email
          </WSText.ParagraphSm>
          <WSTooltip dark target={tooltipIconRef}>
            Client email can not be changed
          </WSTooltip>
          <WSIcon
            ref={tooltipIconRef}
            name="info-circle-fill"
            color="gray600"
            size="S"
          />
        </WSFlexBox.CenterY>
        <WSText.ParagraphSm weight="book" mb="XL">
          {client.user?.email}
        </WSText.ParagraphSm>
        <AdditionalEmailsField
          name="additionalEmails"
          inputLabel="Contact email CC"
          buttonLabel="Add email recipient"
          buttonDescription="Additional email addresses will be cc’d"
          mb="2XL"
        />
        <WSErrorMessage
          mb="XL"
          error={updateClientMeta.error}
          contextKey="UpdateClient"
        />

        <WSDivider my="2XL" />

        <WSActions
          alignment="fill"
          buttons={[
            {
              name: "updateClient",
              type: "submit",
              kind: "Primary",
              loading: updateClientMeta.isLoading,
              label: "Save changes"
            },
            {
              name: "cancel",
              type: "button",
              kind: "Tertiary",
              label: "Cancel",
              onClick: onClose
            }
          ]}
        />
      </WSForm>
    </WSElement>
  );
};
