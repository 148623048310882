import {
  useModalOldContext,
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import groupBy from "lodash/groupBy";
import React from "react";
import { InstitutionLogo } from "../../../components/InstitutionLogo/InstitutionLogo";
import {
  ConfirmRemoving,
  REMOVE_ACCOUNT_CONFIRMATION_MODAL
} from "../../modals/onboarding/ConfirmRemoving";

export type AccountsFinishSetupProps = WSElementProps & {
  accounts: Array<IAccount>;
  onFinish(institutionId: string, accounts: IAccount[]): void;
};

export const AccountsFinishSetup: React.FC<AccountsFinishSetupProps> = ({
  accounts,
  onFinish,
  ...props
}) => {
  const { openModal } = useModalOldContext();
  const institutions = groupBy(accounts, "institutionId");

  return (
    <WSElement {...props}>
      <WSFlexBox.CenterY>
        <WSIcon block name="alert-circle" color="amber400" mr="M" />
        <WSText.Heading5>Finish Setup</WSText.Heading5>
      </WSFlexBox.CenterY>
      <WSText.ParagraphSm color="gray600" mt="M" mb="XL">
        Finish configuration to begin sync.
      </WSText.ParagraphSm>

      {Object.entries(institutions)
        .sort(([idA, accountsA], [idB, accountsB]) => {
          if (!accountsA[0].institution || !accountsB[0].institution) {
            return 0;
          }

          return accountsA[0].institution > accountsB[0].institution ? 1 : -1;
        })
        .map(([institutionId, accounts], index) => {
          const { institution } = accounts[0];

          return (
            <WSElement key={institutionId}>
              <ConfirmRemoving accounts={accounts} onSubmit={() => {}} />
              {index ? <WSDivider /> : null}
              <WSFlexBox.CenterY py="M" justify="space-between">
                <WSFlexBox.CenterY>
                  <InstitutionLogo institutionId={institutionId} />
                  <WSText weight="medium" ml="M">
                    {institution}
                  </WSText>
                </WSFlexBox.CenterY>
                <WSFlexBox.CenterY>
                  <WSButton.Link
                    ml="M"
                    onClick={() => {
                      onFinish(institutionId, accounts);
                    }}
                  >
                    Finish Setup
                  </WSButton.Link>
                  <WSIcon
                    block
                    name="trash"
                    ml="M"
                    size="M"
                    color="gray600"
                    onClick={() => openModal(REMOVE_ACCOUNT_CONFIRMATION_MODAL)}
                  />
                </WSFlexBox.CenterY>
              </WSFlexBox.CenterY>
            </WSElement>
          );
        })}
    </WSElement>
  );
};
