import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import { AxiosError } from "axios";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import { NotFoundMessage } from "../components/NotFoundMessage";
import RenderDefaultPageErrorMessage from "../components/RenderDefaultPageErrorMessage";

import { IS_DEV_ENV } from "../shared/constants/environment";
import { track } from "../utils/analytics";
import { openIntercom } from "../shared/utils/intercom";
import { FetchStatus, QueryStatus } from "@tanstack/query-core/src/types";

const ErrorViewForbidden = () => {
  useEffect(() => {
    track("Forbidden Component Rendered");
  }, []);

  return (
    <WSFlexBox.Center
      p="XL"
      direction="column"
      wrap="nowrap"
      style={{ height: "100%", flex: "1" }}
      data-testid="serviceError"
    >
      <WSIcon block name="alert-circle" size="L" color="amber400" />
      <WSText.Heading5 color="gray600" mt="XL">
        You don't have enough permissions to access this page
      </WSText.Heading5>

      <WSFlexBox.Center pt="2XL">
        <WSText.ParagraphSm align="center">
          Please,{" "}
          <WSButton.Link onClick={openIntercom}>
            contact our support
          </WSButton.Link>{" "}
          and our team will be happy to help you
        </WSText.ParagraphSm>
      </WSFlexBox.Center>
    </WSFlexBox.Center>
  );
};

const ErrorViewGeneral = ({ refetch }: { refetch: () => void }) => {
  useEffect(() => {
    track("Failure Component Rendered");
  }, []);

  return (
    <WSFlexBox.Center
      p="XL"
      direction="column"
      wrap="nowrap"
      style={{ height: "100%", flex: "1" }}
      data-testid="serviceError"
    >
      <WSIcon block name="alert-circle" size="L" color="amber400" />
      <WSText.Heading5 color="gray600" mt="XL">
        We had trouble loading part of this page
      </WSText.Heading5>

      <WSFlexBox.Center pt="3XL">
        <WSButton onClick={refetch}>Retry</WSButton>
      </WSFlexBox.Center>

      <WSFlexBox.Center pt="2XL">
        <WSText.ParagraphSm align="center">
          If this persists, please{" "}
          <WSButton.Link onClick={openIntercom}>
            contact our support
          </WSButton.Link>{" "}
          and our team will be happy to help you
        </WSText.ParagraphSm>
      </WSFlexBox.Center>
    </WSFlexBox.Center>
  );
};

type Refetch = () => Promise<unknown>;

export type WSQueryRenderErrorMessage = (
  errors: any,
  refetch: Refetch
) => React.ReactNode;

const renderDefaultErrorMessage: WSQueryRenderErrorMessage = (
  error,
  refetch
) => {
  switch ((error as AxiosError)?.response?.status) {
    case 403:
      return <ErrorViewForbidden />;
    case 404:
      return <NotFoundMessage />;
    default:
      return <ErrorViewGeneral refetch={refetch} />;
  }
};

export type WSQueryRenderErrorsMessage = (
  errors: any[],
  refetch: Refetch
) => React.ReactNode;

const renderDefaultErrorsMessage: WSQueryRenderErrorsMessage = (
  errors,
  refetch
) => {
  return Array.isArray(errors) && errors.length > 0
    ? renderDefaultErrorMessage(errors[0], refetch)
    : null;
};

type ErrorBoundaryProps = {};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
  errors?: Error[];
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errors: [error] };
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <WSContainer
          verticalPadding
          style={{ height: "100%", display: "flex" }}
          data-testid="serviceError"
        >
          <WSCentered span={{ xs: "8", m: "6" }}>
            <WSText.Heading4>Uh-oh! Error! 🤕</WSText.Heading4>
            <WSText mt="XL">
              Sorry about that, it appears we've hit a snag. Please reload your
              browser and try again, or{" "}
              <WSButton.Link onClick={openIntercom}>
                contact support
              </WSButton.Link>{" "}
              if the problem persists.
            </WSText>
            {IS_DEV_ENV && (
              <WSText color="red400" mt="M">
                One or more errors are occurring in this component
                ErrorBoundary:
              </WSText>
            )}
            {IS_DEV_ENV &&
              this.state.errors &&
              (this.state.errors as any[]).map((err) => {
                console.log("ErrorBoundary", err);
                return (
                  <WSText color="red400" mt="M">
                    {err.message}
                  </WSText>
                );
              })}
          </WSCentered>
        </WSContainer>
      );
    }

    return <>{this.props.children}</>;
  }
}

export const defaultRenderLoader = () => (
  <WSElement my="5XL">
    <WSLoader.Spinner />
  </WSElement>
);

type Query = {
  data: any;
  isLoading: boolean;
  isError: boolean;
  error: any;
  refetch: Refetch;
  isSuccess: boolean;
  status: QueryStatus
  fetchStatus: FetchStatus
  dataUpdatedAt: number;
  errorUpdatedAt: number
  failureCount: number
  failureReason: any
  errorUpdateCount: number
  isFetched: boolean
  isFetchedAfterMount: boolean
  isFetching: boolean
  isLoadingError: boolean
  isInitialLoading: boolean
  isPaused: boolean
  isPlaceholderData: boolean
  isPreviousData: boolean
  isRefetchError: boolean
  isRefetching: boolean
  isStale: boolean
  remove: () => void
};

export type ChildQuery<T> = T extends { data: infer U }
  ? T & { data: NonNullable<U> }
  : never;

export type WSQueryRenderLoader = () => React.ReactNode;

type PropsQuery<Q extends Query> = {
  query: Q;
  renderLoader?: WSQueryRenderLoader;
  renderError?: WSQueryRenderErrorMessage;
  /**
   * @deprecated This property is deprecated. Let's use hooks instead.
   */
  onSuccess?: (props: ChildQuery<Q>) => void;
  isPage?: boolean;
} & (
  | {
  children(props: ChildQuery<Q>): React.ReactNode;
  renderChildren?: false;
}
  | {
  children: ReactNode;
  renderChildren: true;
}
  );

const QueryComponent = <Q extends Query = Query>({
                                                   children,
                                                   renderError = renderDefaultErrorMessage,
                                                   renderLoader = defaultRenderLoader,
                                                   onSuccess,
                                                   query,
                                                   isPage,
                                                   renderChildren
                                                 }: PropsQuery<Q>): any => {
  const isSuccess = query.isSuccess;
  const successRef = useRef(false);

  useEffect(() => {
    if (!successRef.current && isSuccess) {
      successRef.current = true;
      onSuccess?.(query as any);
    }
  }, [isSuccess, onSuccess, query]);


  if (query.isSuccess) {
    return !renderChildren && typeof children === "function"
      ? children(query as any)
      : children;
  }

  if (query.isError) {
    if (isPage && renderDefaultErrorMessage === renderError) {
      return <RenderDefaultPageErrorMessage refetch={query.refetch} />;
    } else {
      return renderError(query.error, query.refetch);
    }
  }

  return renderLoader();
};

type ChildQueries<T> = {
  [K in keyof T]: T[K] extends { data: infer U }
    ? T[K] & { data: NonNullable<U> }
    : never;
};

type ChildQueriesData<T> =
  {
    [K in keyof T as `${string & K}Data`]: T[K] extends { data: infer U } ? NonNullable<U> : never;
  };

type ReactFC = React.FC<any> | React.LazyExoticComponent<React.FC<any>>;

type ChildProps<Q> = ChildQueriesData<Q> & ChildQueries<Q>;

type Queries = { [key: string]: Query };

type PropsQueriesFuncAsChild<Q extends Queries> = {
  queries: Q;
  renderLoader?: WSQueryRenderLoader;
  renderErrors?: WSQueryRenderErrorsMessage;
  onSuccess?(props: ChildQueries<Q>): void;
  onError?(props: ChildQueries<Q>): void;
  isPage?: boolean;
  children(props: ChildProps<Q>): React.ReactNode;
};

type ChildComponentProps<P extends ReactFC, Q extends Queries> = Omit<
  React.ComponentProps<P>,
  keyof ChildProps<Q> | "children"
>;

type PropsQueriesCompAsProp<
  Q extends Queries,
  P extends React.FC<ChildProps<Q>>
> = ChildComponentProps<P, Q> extends Record<string, never>
  ? {
    queries: Q;
    renderLoader?: WSQueryRenderLoader;
    renderErrors?: WSQueryRenderErrorsMessage;
    /**
     * @deprecated This property is deprecated. Let's use hooks instead.
     */
    onSuccess?(props: ChildQueries<Q>): void;
    /**
     * @deprecated This property is deprecated. Let's use hooks instead.
     */
    onError?(props: ChildQueries<Q>): void;
    isPage?: boolean;
    children?: never;
    component: P;
    componentProps?: Record<string, never>;
  }
  : {
    queries: Q;
    renderLoader?: WSQueryRenderLoader;
    renderErrors?: WSQueryRenderErrorsMessage;
    /**
     * @deprecated This property is deprecated. Let's use hooks instead.
     */
    onSuccess?(props: ChildProps<Q>): void;
    /**
     * @deprecated This property is deprecated. Let's use hooks instead.
     */
    onError?(props: ChildProps<Q>): void;
    isPage?: boolean;
    children?: never;
    component: P;
    componentProps: ChildComponentProps<P, Q>;
  };

const QueriesComponent = <Q extends Queries, P extends ReactFC>({
                                                                  children,
                                                                  renderErrors = renderDefaultErrorsMessage,
                                                                  renderLoader = defaultRenderLoader,
                                                                  onSuccess,
                                                                  onError,
                                                                  queries,
                                                                  isPage,
                                                                  component: Component,
                                                                  componentProps = {} as ChildComponentProps<P, Q>
                                                                }: P extends ReactFC
  ? PropsQueriesCompAsProp<Q, P>
  : PropsQueriesFuncAsChild<Q>): any => {
  const queriesHash = Object.values(queries).map(q => [q.dataUpdatedAt, q.status, q.fetchStatus].join("-")).join("--");

  const isSuccess = Object.values(queries).every((value) => value.isSuccess);
  const successRef = useRef(false);

  useEffect(() => {
    if (!successRef.current && isSuccess) {
      successRef.current = true;
      onSuccess?.(queries as any);
    }
  }, [isSuccess, onSuccess, queries, queriesHash]);

  const childParams = useMemo(() => {
    const queriesData = Object.keys(queries).reduce((acc, key) => {
      acc[`${key}Data`] = queries[key].data;
      return acc;
    }, {} as any);

    return { ...componentProps, ...queries, ...queriesData };
  }, [queries, queriesHash, componentProps]);

  if (
    Object.values(queries).every((value) => value.isSuccess)
  ) {
    return typeof children === "function" ? (
      (children as any)(childParams)
    ) : Component ? (
      <Component {...childParams} />
    ) : null;
  }

  if (Object.values(queries).some((value) => value.isError)) {
    const failedQueries = Object.values(queries).filter((v) => v.isError);
    const errors = failedQueries.map((value) => value.error);
    onError?.(queries as any);
    if (isPage && renderDefaultErrorsMessage === renderErrors) {
      return (
        <RenderDefaultPageErrorMessage
          refetch={() => failedQueries.forEach((q) => q.refetch())}
        />
      );
    } else {
      return renderErrors(errors, () =>
        Promise.all(Object.values(failedQueries).map((q) => q.refetch()))
      );
    }
  }

  return renderLoader();
};

export function WSQuery<Q extends Query = Query>(
  props: PropsQuery<Q>
): React.FunctionComponentElement<{}> {
  return (
    <ErrorBoundary>
      <QueryComponent {...props} />
    </ErrorBoundary>
  );
}

export function WSQueries<
  Q extends Queries = Queries,
  P extends ReactFC = React.FC<unknown>
>(
  props: PropsQueriesFuncAsChild<Q> | PropsQueriesCompAsProp<Q, P>
): React.FunctionComponentElement<{}> {
  return (
    <ErrorBoundary>
      <QueriesComponent {...(props as any)} />
    </ErrorBoundary>
  );
}
