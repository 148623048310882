import {
  useModalOldContext,
  WSButton,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useMemberProfile } from "../../../query/users/queries";
import { WSQuery } from "../../../query/WSQuery";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces/member";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";

export const TaxesModalTurnOnWithholdings: React.FC = () => {
  const memberId = useUserId();
  const { closeModal } = useModalOldContext();
  const qMemberProfile = useMemberProfile(memberId);
  const [loading, setLoading] = useState(false);

  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();

  return (
    <WSModalOld name="turn-on-withholdings" size="S">
      <WSQuery query={qMemberProfile}>
        {member => {
          const withholdingTax = member.data.profile?.withholdings?.tax;
          const withholdingEnabled =
            withholdingTax?.status === MemberWithholdingStatus.Active;

          return (
            <>
              <WSText.Heading4 mb="M">Tax withholdings</WSText.Heading4>
              <WSText mb="XS">
                Set aside {withholdingTax?.rate}% of each invoice paid through
                Wingspan.
              </WSText>

              <WSButton.Primary
                mt="2XL"
                fullWidth
                size="M"
                mr="M"
                mb="M"
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  await updateMemberProfile({
                    memberId,
                    profile: {
                      withholdings: {
                        tax: {
                          rate: withholdingTax?.rate,
                          status:
                            withholdingTax?.status ===
                            MemberWithholdingStatus.Active
                              ? MemberWithholdingStatus.Paused
                              : MemberWithholdingStatus.Active
                        }
                      }
                    }
                  });
                  setLoading(false);
                  closeModal("turn-on-withholdings");
                }}
              >
                {withholdingEnabled ? "Don't save" : "Save"} when I get paid
              </WSButton.Primary>
              <WSButton.Tertiary
                size="M"
                fullWidth
                onClick={() => {
                  closeModal("turn-on-withholdings");
                }}
              >
                Cancel
              </WSButton.Tertiary>
            </>
          );
        }}
      </WSQuery>
    </WSModalOld>
  );
};
