import * as Yup from "yup";
import { validatorAddress } from "../../validators/validatorAddress";
import { validatorStringTextOnly } from "../../validators/validatorStringTextOnly";

export const validationSchema = Yup.object().shape({
  addressLine1: validatorAddress.required("Required"),
  addressLine2: validatorAddress,
  city: validatorStringTextOnly.required("Required"),
  state: Yup.string().when("country", {
    is: "US",
    then: Yup.string().required("Required"),
    otherwise: validatorStringTextOnly
  }),
  zipCode: Yup.string().when("country", {
    is: "US",
    then: Yup.string().length(5, "Should be 5 characters").required("Required"),
    otherwise: Yup.string().required("Required")
  })
});
