import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { getCard } from "../../../services/api/banking/cards";
import { ICardResponse } from "../../../services/api/banking/cards/types";

export const QUERY_CARD = "QUERY_CARD";

export const useCardQuery = (
  cardId: string,
  config?: WSQueryConfig<ICardResponse, WSServiceError>
) => {
  return useWSQuery<ICardResponse, WSServiceError>(
    [QUERY_CARD, cardId],
    () => getCard(cardId),
    {
      retry: false,
      ...config
    }
  );
};
