import React, { useState } from "react";
import {
  useWSSnackbar,
  WSButton,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./ActiveCard.module.scss";
import { ICardResponse } from "../../../../services/api/banking/cards/types";
import { useCardEmbedUrlQuery } from "../../../../query/cards/queries/useCardEmbedUrlQuery";
import classNames from "classnames";

type Props = {
  card: ICardResponse;
};

export const ActiveCard: React.FC<Props> = ({ card }) => {
  return card.isVirtualCard ? (
    <VirtualCard card={card} />
  ) : (
    <PhysicalCard card={card} />
  );
};

const VirtualCard: React.FC<Props> = ({ card }) => {
  const [visible, setVisible] = useState<boolean>();
  const [embedUrl, setEmbedUrl] = useState("");
  const { openSnackbar } = useWSSnackbar();

  const cardEmbedUrlQuery = useCardEmbedUrlQuery(card.cardId, {
    enabled: false
  });

  return (
    <WSElement className={classNames(styles.card, styles.virtualCard)}>
      <WSButton.Link
        className={styles.visibilityToggle}
        onAsyncClick={async () => {
          if (embedUrl) {
            setVisible(visible => !visible);
            return;
          }

          const response = await cardEmbedUrlQuery.refetchData();

          if (response) {
            setEmbedUrl(response?.embedUrl || "");
            setVisible(visible => !visible);
          } else {
            openSnackbar({
              type: "error",
              message: "Failed to fetch card details"
            });
          }
        }}
        label={visible ? "Hide details" : "Show details"}
        rightIcon={visible ? "hide" : "view"}
      />
      {visible && embedUrl ? (
        <iframe
          id="card-iframe"
          allow="clipboard-write"
          scrolling="no"
          width="334"
          height="209"
          allowTransparency
          className={styles.iframe}
          src={embedUrl}
        />
      ) : (
        <div id="card">
          <div id="pan">
            ••••
            <span className="pan-separator" />
            ••••
            <span className="pan-separator" />
            ••••
            <span className="pan-separator" />
            {card.mask}
          </div>
          <div id="expiry">
            <span id="month">••</span>
            <span id="separator">/</span>
            <span id="year">••</span>
          </div>
          <div id="cvv">•••</div>
        </div>
      )}
    </WSElement>
  );
};

const PhysicalCard: React.FC<Props> = ({ card }) => {
  return (
    <WSElement className={classNames(styles.card, styles.physicalCard)}>
      <WSText.Paragraph color="white" className={styles.physicalCardMask}>
        •••• {card.mask}
      </WSText.Paragraph>
    </WSElement>
  );
};
