import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { paymentsService } from "../../../services/payments";

export const useMutationsGetUnitApplication = (
  config?: WSMutationConfig<string | undefined>
) => {
  const userId = useUserId();

  return useWSMutation<string | undefined>(
    async () => {
      let applicationLink;

      try {
        const response = await paymentsService.service.banking.application.get(
          userId
        );
        applicationLink = response.applicationLink;
      } catch (error) {
        console.error("Failed to get application link");
        console.error(error);
      }

      return applicationLink;
    },
    {
      ...config
    }
  );
};
