import {
  useWSSnackbar,
  WSElement,
  WSElementProps,
  WSEmptyState,
  WSMenuItem,
  WSSectionToolbar,
  WSTable,
  WSTableCell,
  WSTableItem
} from "@wingspanhq/fe-component-library";
import { IMemberFileResponse } from "@wingspanhq/files/dist/lib/interfaces";
import { selectorIsFileVisibleByUserId } from "../../../../Invoices/selectors/selectorIsFileVisibleByUserId";
import { useDownloadPrivateDocument } from "../../../../query/files/mutations";
import { useMemberPrivateFiles } from "../../../../query/files/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { downloadFileFromBuffer } from "../../../../utils/files";
import { DocumentsSectionType } from "./types";
import { useConfirmDeleteDocumentModal } from "./useConfirmDeleteDocumentModal";
import { useUploadDocumentModal } from "./useUploadDocumentModal";

export interface DocumentsSectionProps extends WSElementProps {
  type: DocumentsSectionType;
  payerId: string;
  payeeId: string;
  isInactive: boolean;
}

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  type,
  payerId,
  payeeId,
  isInactive,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const uploadDocumentModal = useUploadDocumentModal();
  const confirmDeleteDocumentModal = useConfirmDeleteDocumentModal();
  const queryDocuments = useMemberPrivateFiles({
    filter: {
      hidden: true,
      tags: {
        in: [
          type === DocumentsSectionType.Collaborator
            ? `payee:${payeeId}`
            : `payer:${payerId}`
        ]
      }
    }
  });
  const [downloadDocument] = useDownloadPrivateDocument();

  const onClickAddDocument = () => {
    uploadDocumentModal.open({
      payerId,
      payeeId
    });
  };
  const onDownload = async (row: WSTableItem<IMemberFileResponse>) => {
    await downloadDocument(row.data?.fileId, {
      onSuccess: pdfBlob => {
        downloadFileFromBuffer(pdfBlob, row.data?.filename, row.data?.mimetype);
        openSnackbar({
          message: "File downloaded successfully",
          type: "success"
        });
      },
      onError: () => {
        openSnackbar({
          message: "Download failed",
          type: "warning"
        });
      }
    });
  };

  const rowMenuActions = (
    row: WSTableItem<IMemberFileResponse>
  ): WSMenuItem[] => [
    {
      label: "Download",
      icon: "download",
      onClick: () => onDownload(row)
    },
    ...(type === DocumentsSectionType.Collaborator
      ? [
          {
            label: "Delete",
            icon: "trash",
            onClick: () =>
              confirmDeleteDocumentModal.open({
                documentId: row.data.fileId
              })
          } as WSMenuItem
        ]
      : [])
  ];

  return (
    <WSElement {...elementProps}>
      <WSSectionToolbar
        mb="S"
        title="Documents"
        button={
          !isInactive && type === DocumentsSectionType.Collaborator
            ? {
                onClick: onClickAddDocument,
                label: "Add document"
              }
            : undefined
        }
      />

      <WSQueries queries={{ queryDocuments }}>
        {({ queryDocumentsData: documents }) => {
          return documents.length > 0 ? (
            <WSTable<IMemberFileResponse>
              tableData={documents.map((document: IMemberFileResponse) => {
                return {
                  id: document.fileId,
                  data: document
                };
              })}
              columns={[
                {
                  config: {
                    gridTemplateSizeMin: "auto",
                    gridTemplateSizeMax: "1fr"
                  },
                  renderFunction: row => {
                    let description;

                    if (
                      selectorIsFileVisibleByUserId(payeeId, row.data.userRoles)
                    ) {
                      description =
                        type === DocumentsSectionType.Collaborator
                          ? "Visible to contractor"
                          : undefined;
                    }

                    return (
                      <WSTableCell
                        avatar={{
                          format: "square",
                          type: "icon",
                          icon: "file",
                          colorBackground: "gray500",
                          color: "white"
                        }}
                        text={row.data.filename}
                        secondaryText={description}
                      />
                    );
                  }
                }
              ]}
              rowMenuActions={isInactive ? undefined : rowMenuActions}
            />
          ) : (
            <WSEmptyState
              size="S"
              orientation="horizontal"
              hasBorder
              type="images"
              title="No documents yet"
              description="Only PDF, PNG, JPEG, and GIF files are supported. File limit of 100MB per file."
              buttons={
                !isInactive && type === DocumentsSectionType.Collaborator
                  ? [
                      {
                        label: "Add document",
                        kind: "Secondary",
                        onClick: onClickAddDocument
                      }
                    ]
                  : []
              }
            />
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
