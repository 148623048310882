import {
  ITaxEstimate,
  ITaxEstimateBase
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  IBalanceResponse,
  IWithholdingTransactionResponse
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/withholdingTransaction";
import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import bookkeepingService from "../../services/bookkeeping";
import { Await } from "../../utils";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_TAXES_PENDING_WITHHOLDING_LIST,
  QUERY_TAXES_TAX_ESTIMATE,
  QUERY_TAXES_WITHHOLDING_BALANCE,
  QUERY_TAXES_WITHHOLDING_TRANSACTIONS
} from "./keys";

export const useTaxEstimate = (
  options: ITaxEstimateBase,
  queryConfig?: WSQueryConfig<ITaxEstimate, unknown>
) =>
  useWSQuery(
    [QUERY_TAXES_TAX_ESTIMATE, options],
    async () => await bookkeepingService.createTaxEstimate(options),
    {
      retry: false,
      ...queryConfig
    }
  );

export const useWithholdingBalance = (
  queryConfig?: WSQueryConfig<IBalanceResponse, unknown>
) =>
  useWSQuery(
    QUERY_TAXES_WITHHOLDING_BALANCE,
    async () => await bookkeepingService.getWithholdingBalance(),
    { retry: false, ...queryConfig }
  );

export const useWithholdingTransactions = (
  queryConfig?: WSInfiniteQueryConfig<
    IWithholdingTransactionResponse[],
    unknown
  >
) =>
  useWSInfiniteQuery(
    QUERY_TAXES_WITHHOLDING_TRANSACTIONS,
    async ({ pageParam = 1 }) => {
      return await bookkeepingService.getWithholdingTransactions({
        page: {
          size: 10,
          number: pageParam
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );

export const usePendingWithholdingList = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getPendingWithholdingList>>,
    WSServiceError
  >
) =>
  useWSQuery(
    QUERY_TAXES_PENDING_WITHHOLDING_LIST,
    async () => await bookkeepingService.getPendingWithholdingList(),
    queryConfig
  );
