import { WSQueryConfig } from "@ws-react-query";
import { IS_LOCAL_ENV } from "../../shared/constants/environment";
import { EnabledExperimentsResponse } from "../../types/experiments";
import { useWSQuery } from "../helpers";
import {
  getEnabledAnonymousExperiments,
  getEnabledExperiments
} from "../../services/experiments";
import { getAnonymousStamp } from "../../utils/anonymousStamp";
import { pullSessionToken } from "../../services/sessionStorage";

export const experimentFlags = {
  qboOnboarding: IS_LOCAL_ENV,
  editAllInvoices: IS_LOCAL_ENV,
  nextGenSubscription: IS_LOCAL_ENV,
  collaboratorOnboardingRequirements: IS_LOCAL_ENV,
  purchaseOrderNumber: IS_LOCAL_ENV,
  deduplicateCollaboratorList: IS_LOCAL_ENV,
  physicalCardReplace: IS_LOCAL_ENV,
  electronic1099ConsentRequired: IS_LOCAL_ENV,
  forceShowQBO: IS_LOCAL_ENV,
  autopayV1: IS_LOCAL_ENV,
  meridio: IS_LOCAL_ENV,
  reports: IS_LOCAL_ENV,
  invoicePaymentsDetails: IS_LOCAL_ENV,
  refundButton: IS_LOCAL_ENV,
  organizationCollaboratorExternalIdResolution: IS_LOCAL_ENV,
  refundsPane: IS_LOCAL_ENV,
  nec1099AmountFilters: IS_LOCAL_ENV,
  nec1099Only: IS_LOCAL_ENV,
  accountPause: IS_LOCAL_ENV,
  showNewStatements: IS_LOCAL_ENV,
  showArAgingReport: IS_LOCAL_ENV,
  prefundingMethods: IS_LOCAL_ENV,
  showCustomFieldFIltersInContractors: IS_LOCAL_ENV,
  flatfilePlatform: IS_LOCAL_ENV,
  editContractorEmail: IS_LOCAL_ENV,
  currencyCloudVerification: IS_LOCAL_ENV,
  monitoredEntityChange: IS_LOCAL_ENV,
  paymentCardAsPaymentMethod: IS_LOCAL_ENV,
  paymentCardAsPayoutMethod: IS_LOCAL_ENV,
  paymentCardsCPE: IS_LOCAL_ENV,
  allowDefaultEngagementPaymentsFallback: IS_LOCAL_ENV,
  nec1099season2024: IS_LOCAL_ENV,
  enableContractorOrgExpansion: IS_LOCAL_ENV,
  currencyConversionToUSD: IS_LOCAL_ENV,
  nec1099season2024generation: IS_LOCAL_ENV,
  showImportedUnitStatements: IS_LOCAL_ENV,
  taxDocumentsSeason2024List: IS_LOCAL_ENV,
  taxDocumentsSeason2024PayerCorrections: IS_LOCAL_ENV,
  flatfileEngagements: IS_LOCAL_ENV,
  achPayerAuthorization: IS_LOCAL_ENV,
  cashback2025: IS_LOCAL_ENV,
  newBulkImporterUI: IS_LOCAL_ENV,
  newBulkImporterUIForPayables: IS_LOCAL_ENV
};

export const anonExperimentFlags = {
  paymentCardsCPEGuest: IS_LOCAL_ENV
};

type ExperimentFlags = typeof experimentFlags;
type AnonExperimentFlags = typeof anonExperimentFlags;

export interface IFeatureFlags extends ExperimentFlags, AnonExperimentFlags {}

const transformExperimentsData = (
  anonymousExperiments: EnabledExperimentsResponse,
  enabledExperiments: EnabledExperimentsResponse,
  defaultExperiments:
    | typeof experimentFlags
    | typeof anonExperimentFlags
    | (typeof experimentFlags & typeof anonExperimentFlags)
) => {
  const anonymousFeatureFlags = anonymousExperiments.reduce<string[]>(
    (result, { featureFlags }) => result.concat(featureFlags),
    []
  );

  const enabledFeatureFlags = enabledExperiments.reduce<string[]>(
    (result, { featureFlags }) => result.concat(featureFlags),
    []
  );

  const allFeatureFlags = [...anonymousFeatureFlags, ...enabledFeatureFlags];

  const newState = Object.keys(defaultExperiments).reduce(
    (acc: any, name: string) => {
      if (allFeatureFlags?.includes(name)) {
        acc[name as any] = true;
      }
      return acc;
    },
    { ...defaultExperiments }
  );

  return { ...newState };
};

export const QUERY_FEATURE_FLAGS = "QUERY_FEATURE_FLAGS";
export const QUERY_ANONYMOUS_FEATURE_FLAGS = "QUERY_ANONYMOUS_FEATURE_FLAGS";

export const useAnonymousFeatureFlags = (
  queryConfig?: WSQueryConfig<typeof anonExperimentFlags, unknown>
) => {
  const anonymousId = getAnonymousStamp();

  return useWSQuery(
    [QUERY_ANONYMOUS_FEATURE_FLAGS, anonymousId],
    async () => {
      let anonymousExperiments: EnabledExperimentsResponse = [];

      try {
        anonymousExperiments = await getEnabledAnonymousExperiments(
          anonymousId
        );
      } catch {}

      const result = transformExperimentsData(
        anonymousExperiments,
        [],
        anonExperimentFlags
      );

      return result as typeof anonExperimentFlags;
    },
    {
      ...queryConfig,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
};

export const useFeatureFlags = (
  queryConfig?: WSQueryConfig<
    typeof experimentFlags & typeof anonExperimentFlags,
    unknown
  >
) => {
  const sessionToken = pullSessionToken();
  const anonymousId = getAnonymousStamp();

  return useWSQuery(
    [QUERY_FEATURE_FLAGS, anonymousId, sessionToken],
    async () => {
      let anonymousExperiments: EnabledExperimentsResponse = [];
      let enabledExperiments: EnabledExperimentsResponse = [];

      try {
        anonymousExperiments = await getEnabledAnonymousExperiments(
          anonymousId
        );
      } catch {}

      try {
        enabledExperiments = await getEnabledExperiments();
      } catch {}

      const result = transformExperimentsData(
        anonymousExperiments,
        enabledExperiments,
        { ...experimentFlags, ...anonExperimentFlags }
      );

      return {
        ...result
      } as typeof experimentFlags & typeof anonExperimentFlags;
    },
    {
      ...queryConfig,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
};
