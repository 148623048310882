export const OLD_BULK_BATCHES_THRESHOLD_IN_DAYS = 14;

export const BULK_BATCH_EVENT_HISTORY_START_DATE = "2025-03-10";

export const BULK_IMPORT_PAYABLES_NAMESPACE = "payables";
export const PAYABLES_SHEET_NAME = "Payables";
export const PAYABLES_SHEET_SLUG = PAYABLES_SHEET_NAME.toLowerCase();
export const PAYABLES_ACTION_SLUG = "finalizePayablesUpload";

export const BULK_IMPORT_CONTRACTORS_NAMESPACE = "contractors";
export const CONTRACTORS_SHEET_NAME = "Contractors";
export const CONTRACTORS_SHEET_SLUG = CONTRACTORS_SHEET_NAME.toLowerCase();
export const CONTRACTORS_ACTION_SLUG = "finalizeContractorsUpload";
