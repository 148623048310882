import {
  WSCard,
  WSElement,
  WSElementProps,
  WSEmptyState,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryInternalAccounts } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { selectorActiveBankingAccount } from "../../../../shared/selectors/selectorActiveBankingAccount";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { BadgePayoutPreference } from "../../../PaymentMethods/components/BadgePayoutPreference";
import { NotificationBanner } from "../../../PaymentMethods/components/NotificationBanner";
import {
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD,
  PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD
} from "../../../Settings/routes/RoutePaymentMethods";
import { LabelMethod } from "../LabelMethod";
import { MethodType } from "../ManageMethods";

type Props = WSElementProps;

export const DefaultPayoutMethod: React.FC<Props> = ({ ...elementProps }) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryInternalAccounts = useQueryInternalAccounts();
  const history = useHistory();

  const [type, id] = useMemo((): [
    MethodType | undefined,
    string | undefined
  ] => {
    const payoutSettings = queryPayoutSettings.data;

    if (!payoutSettings) {
      return [undefined, undefined];
    }

    const destination = selectorDefaultDestination(payoutSettings);

    // If no payout destination is set, check if there is Wallet
    if (!destination) {
      const bankingInternalAccount = queryInternalAccounts.data?.find(
        selectorActiveBankingAccount
      );

      if (!bankingInternalAccount) {
        return [undefined, undefined];
      }

      return ["InternalAccount", bankingInternalAccount.internalAccountId];
    }

    switch (destination.destinationType) {
      case PayoutDestinationType.Account:
        return ["Account", destination.destinationId];

      case PayoutDestinationType.Card:
        return ["CheckbookCard", destination.destinationId];

      case PayoutDestinationType.PaymentCard:
        return ["PaymentCard", destination.destinationId];

      default:
        return [undefined, undefined];
    }
  }, [queryInternalAccounts.data, queryPayoutSettings.data]);

  return (
    <WSElement {...elementProps}>
      <NotificationBanner mb="XL" />

      <WSCard
        shimmer={queryPayoutSettings.isLoading}
        divider={true}
        header={{
          label: {
            text: "Payout method",
            helperText: "Default method to receive payments from clients"
          },
          value: {
            pill: {
              theme: "neutral",
              text: "Default",
              badge: true
            }
          }
        }}
        actions={{
          alignment: "left",
          buttons: [
            {
              kind: "Link",
              label: "Manage payout method",

              onClick: () => {
                history.push(
                  PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD
                );
              }
            }
          ]
        }}
      >
        {type && id ? (
          <WSFlexBox justify="space-between" alignItems="center">
            <LabelMethod type={type} id={id} />
            {queryPayoutSettings.data && (
              <BadgePayoutPreference
                payoutPreference={queryPayoutSettings.data?.payoutPreferences}
              />
            )}
          </WSFlexBox>
        ) : (
          <WSEmptyState
            type="card"
            title="No payout method added"
            orientation="horizontal"
            description="Add a default payout method to get paid."
            size="S"
            fullWidth
            buttons={[
              {
                label: "Add payout method",
                kind: "Secondary",
                onClick: () => {
                  history.push(PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD);
                }
              }
            ]}
          />
        )}
      </WSCard>
    </WSElement>
  );
};
