const cardNumberFormatsBase = [
  {
    name: "uatp",
    // starts with 1 but not 1800
    rx: /^(?!1800)1\d{3}/,
    blocks: [4, 5, 6]
  },
  {
    name: "amex",
    // starts with 34 or 37
    rx: /^3[47]/,
    blocks: [4, 6, 5]
  },
  {
    name: "dinners",
    // starts with 300-305/309 or 36/38/39
    rx: /^3(0([0-5]|9)|[689])/,
    blocks: [4, 6, 4]
  },
  {
    name: "jcb15",
    // starts with 2131/1800; 15 digits
    rx: /^(2131|1800)/,
    blocks: [4, 6, 5]
  },
  {
    name: "regular",
    rx: /^\d{0,16}/,
    blocks: [4, 4, 4, 4]
  }
];

const getBlocksRx = (blocks: number[]) => {
  const [firstBlockRx, ...otherBlockRxes] = blocks.map(
    (block) => `(\\d{1,${block}})`
  );
  const otherBlockRxesOptional = otherBlockRxes
    .map((blockRx) => `${blockRx}?`)
    .join("");

  return new RegExp(`^${firstBlockRx}${otherBlockRxesOptional}`);
};

export const cardNumberFormats = cardNumberFormatsBase.map(
  ({ name, rx, blocks }) => ({
    name,
    rx,
    blocksRx: getBlocksRx(blocks),
    length: blocks.reduce((a, v) => a + v)
  })
);

export const getCardNumberFormat = (cardNumber: string) => {
  const cardNumberFormat = cardNumberFormats.find((format) =>
    format.rx.test(cardNumber)
  );
  if (!cardNumberFormat) throw new Error("Wrong card number format!");

  return cardNumberFormat;
};

export const getCardNumberDisplayValue = (cardNumber: string) => {
  const { blocksRx } = getCardNumberFormat(cardNumber);

  const digitGroups = blocksRx.exec(cardNumber)?.slice(1);

  return digitGroups ? digitGroups.filter(Boolean).join(" ") : "";
};

export const getCardNumberRawValue = (cardNumber: string) => {
  const cardNumberDigits = cardNumber.replace(/\D/g, "");
  const { length } = getCardNumberFormat(cardNumberDigits);

  return cardNumberDigits.substring(0, length);
};
