import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import {
  IMemberFileResponse,
  WSFileAccessScope
} from "@wingspanhq/files/dist/lib/interfaces";
import { SignerRole } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { WSMutationsConfig, WSQueryCache } from "@ws-react-query";
import { QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS } from "../../modules/Onboarding/queries/useQueryAllPayersWithEngagements";
import { filesService } from "../../services/files";
import {
  getPayeeEngagement,
  getPayeeEngagements
} from "../../services/payeeEngagement";
import {
  getPayerEngagement,
  getPayerEngagements
} from "../../services/payerEngagements";
import { getSignedDocument } from "../../services/signedDocuments";
import { downloadFileFromBuffer } from "../../utils/files";
import { helloSignClient, helloSignPromise } from "../../utils/helloSign";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../helpers";
import { removeNotificationFromCache } from "../notifications/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../notifications/keys";
import { QUERY_PAYEE } from "../payee/keys";
import { QUERY_PAYER } from "../payers/keys";
import { QUERY_PAYEE_ROWS } from "../search/payee/queries/usePayeeRowsQuery";
import {
  QUERY_FILES_MEMBER_PRIVATE_FILES,
  QUERY_FILES_MEMBER_PUBLIC_FILES
} from "./keys";

export const useMemberPrivateCreate = () =>
  useWSMutation(filesService.member.private.create, {
    dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
  });

export const useMemberPrivateHiddenCreate = () =>
  useWSMutation(
    (formData: FormData) => filesService.member.private.create(formData, true),
    {
      dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
    }
  );

export const useMemberPrivateFileCreate = (
  params: Partial<{
    hidden: boolean;
    tags: string[];
    viewerIds: string[];
  }>
) =>
  useWSMutation(
    (formData: FormData) =>
      filesService.member.private.create(
        formData,
        params.hidden,
        params.tags,
        params.viewerIds
      ),
    {
      dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
    }
  );

export const useMemberPublicCreate = () =>
  useWSMutation(filesService.member.public.create, {
    dependencies: [QUERY_FILES_MEMBER_PUBLIC_FILES]
  });

export const useMemberPublicDelete = () =>
  useWSMutation(filesService.member.public.delete, {
    dependencies: [QUERY_FILES_MEMBER_PUBLIC_FILES]
  });

export const useMemberPrivateDelete = () =>
  useWSMutation(filesService.member.private.delete, {
    dependencies: [QUERY_FILES_MEMBER_PRIVATE_FILES]
  });

type SignRequest = {
  documentId: string;
  payerId?: string;
  payerPayeeEngagementId?: string;
  notificationId?: string;
};

export const useSignDocument = (
  config?: WSMutationConfig<any, WSServiceError, SignRequest>
) => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, SignRequest>(
    async ({ documentId, payerId, payerPayeeEngagementId }) => {
      let signedDocument = await getSignedDocument(documentId);

      const payeeSignature = signedDocument.signatures.find(
        signature => signature.signerRole === SignerRole.Payee
      );

      if (!payeeSignature?.url) {
        throw new Error("No sign url on a document");
      }

      helloSignClient.open(payeeSignature.url);

      const hsStatus = await helloSignPromise();

      // Refresh document status
      signedDocument = await getSignedDocument(documentId);

      // Refresh engagemnet status
      if (payerId) {
        if (payerPayeeEngagementId) {
          await getPayerEngagement(payerId, payerPayeeEngagementId);
        } else {
          await getPayerEngagements(payerId);
        }
      }

      if (hsStatus === "signed") {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document signed"
        });
      }
    },
    {
      awaitDependencies: [QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS],
      onSuccess: (result, variables, context) => {
        if (variables.notificationId) {
          // It's taking too long to update all notificaitons, so making optimistic update
          removeNotificationFromCache(variables.notificationId);
        }

        if (variables.payerId) {
          WSQueryCache.invalidateQueries([QUERY_PAYER, variables.payerId]);
        }
        WSQueryCache.invalidateQueries(QUERY_PAYEE_ROWS);
        WSQueryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);

        config?.onSuccess?.(result, variables, context);
      },
      onError: (...args) => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
        config?.onError?.(...args);
      }
    }
  );
};

type CountersignRequest = {
  documentId: string;
  payeeId?: string;
  payerPayeeEngagementId?: string;
  notificationId?: string;
};

export const useCountersignDocument = (
  config?: WSMutationConfig<any, WSServiceError, CountersignRequest>
) => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, CountersignRequest>(
    async ({ documentId, payeeId, payerPayeeEngagementId }) => {
      let signedDocument = await getSignedDocument(documentId);

      const payerSignature = signedDocument.signatures.find(
        signature => signature.signerRole === SignerRole.Payer
      );

      if (!payerSignature?.url) {
        throw new Error("No sign url on a document");
      }

      helloSignClient.open(payerSignature.url);

      const hsStatus = await helloSignPromise();

      // Refresh document status
      signedDocument = await getSignedDocument(documentId);

      // Refresh engagemnet status
      if (payeeId) {
        if (payerPayeeEngagementId) {
          await getPayeeEngagement(payeeId, payerPayeeEngagementId);
        } else {
          await getPayeeEngagements(payeeId);
        }
      }

      if (hsStatus === "signed") {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document signed"
        });
      }
    },
    {
      ...config,
      onSuccess: (result, variables, context) => {
        if (variables.notificationId) {
          // It's taking too long to update all notificaitons, so making optimistic update
          removeNotificationFromCache(variables.notificationId);
        }

        if (variables.payeeId) {
          WSQueryCache.invalidateQueries([QUERY_PAYEE, variables.payeeId]);
        }
        WSQueryCache.invalidateQueries(QUERY_PAYEE_ROWS);
        WSQueryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);

        config?.onSuccess?.(result, variables, context);
      },
      onError: (...args) => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
        config?.onError?.(...args);
      }
    }
  );
};

export const useDownloadPrivateDocument = (
  config?: WSMutationsConfig<ArrayBuffer, WSServiceError>
) =>
  useWSMutation(filesService.member.private.download, {
    ...config
  });

export const useDownloadFile = (
  config?: WSMutationsConfig<
    void,
    WSServiceError,
    {
      accessScope: WSFileAccessScope;
      fileId: string;
    }
  >
) => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    void,
    WSServiceError,
    {
      accessScope: WSFileAccessScope;
      fileId: string;
    }
  >(
    async ({ fileId, accessScope }) => {
      let file: IMemberFileResponse, blob: ArrayBuffer;

      switch (accessScope) {
        case WSFileAccessScope.Public:
          file = await filesService.member.public.get(fileId);
          blob = await filesService.member.public.download(fileId);

          break;
        case WSFileAccessScope.Private:
          file = await filesService.member.private.get(fileId);
          blob = await filesService.member.private.download(fileId);
          break;

        default:
          throw new Error("Not implemented");
      }

      downloadFileFromBuffer(blob, file.filename, file.mimetype);
    },
    {
      ...config,
      onError: (...args) => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
        config?.onError?.(...args);
      },
      onSuccess: (...args) => {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document downloaded"
        });
        config?.onSuccess?.(...args);
      }
    }
  );
};

export const useDownloadMemberDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, { documentId: string }>(
    async ({ documentId }) => {
      const documentFiles = await filesService.document.saveFiles(documentId);

      if (!documentFiles.files?.member) {
        throw new Error("No file provided for client");
      }

      const fileId = documentFiles.files.member;
      const file = await filesService.member.private.get(fileId);
      const data = await filesService.member.private.download(fileId);

      downloadFileFromBuffer(data, file.filename, file.mimetype);
    },
    {
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      },
      onSuccess: () => {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document downloaded"
        });
      }
    }
  );
};

export const useDownloadClientDocument = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<any, WSServiceError, { documentId: string }>(
    async ({ documentId }) => {
      const documentFiles = await filesService.document.saveFiles(documentId);

      if (!documentFiles.files?.client) {
        throw new Error("No file provided for client");
      }

      const fileId = documentFiles.files.client;
      const file = await filesService.member.private.get(fileId);
      const data = await filesService.member.private.download(fileId);

      downloadFileFromBuffer(data, file.filename, file.mimetype);
    },
    {
      onError: () => {
        openSnackbar({
          message: "Sorry, something went wrong."
        });
      },
      onSuccess: () => {
        openSnackbar({
          icon: {
            name: "check"
          },
          message: "Document downloaded"
        });
      }
    }
  );
};
