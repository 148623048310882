import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";

export function convertDateToEndDay(date: Date) {
  date.setMilliseconds(999);
  date.setSeconds(59);
  date.setMinutes(59);
  date.setHours(23);
  return date;
}

export function convertDateStringToUTCMidday(date: string): Date {
  // Check if the string matches the format YYYY-MM-DD using a regular expression
  const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(date);

  if (!isValidFormat) {
    throw new Error("Invalid date format.");
  }

  // Append midday time (12:00:00) to the date string in UTC format
  const dateTimeString = date + "T12:00:00Z";

  const parsedDate = new Date(dateTimeString);

  return parsedDate;
}

export function convertDateToMidday(date: Date) {
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(12);
  return date;
}

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

export function calculateDayDifference(dateEnd: Date, dateStart: Date) {
  const rawDayDiff =
    (dateEnd.valueOf() - dateStart.valueOf()) / MILLISECONDS_IN_A_DAY;
  return rawDayDiff > 0 ? Math.floor(rawDayDiff) : Math.ceil(rawDayDiff);
}

export function getWSDay(date: Date) {
  return date.getDay() === 0 ? 7 : date.getDay();
}

export function format(date: Date | string) {
  const dateObj = new Date(date);
  let year = dateObj.getFullYear();
  let month = (1 + dateObj.getMonth()).toString().padStart(2, "0");
  let day = dateObj.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
}

export function isToday(date: Date) {
  const today = new Date();
  return isSameDate(date, today);
}

export function isSameDate(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function addBusinessDays(date: Date, days: number) {
  return wsMoment(date).businessAdd(days).toDate();
}

export function getStartDayByUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0,
    0
  );
}

export function getEndDayByUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    23,
    59,
    59,
    0
  );
}

export function getDaysBetween(startDate: Date, endDate: Date) {
  const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
  return Math.round(
    (endDate.getTime() - startDate.getTime()) / MILLISECONDS_IN_A_DAY
  );
}
