import {
  FundingSourceType,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { Currency, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { WSQueryCache } from "@ws-react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../query/payments/keys";
import { QUERY_USERS_CLIENT } from "../../../query/users/keys";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { StepLayoutWithOptions } from "../../../shared/components/Layout/StepLayoutWithOptions";
import { avatarBankAccount } from "../../../shared/constants/avatars";
import { selectorAccountInstitution } from "../../../shared/selectors/selectorAccountInstitution";
import { selectorAccountName } from "../../../shared/selectors/selectorAccountName";
import { WSServiceError } from "../../../utils/serviceHelper";

type Props = {
  title: string;
  description?: string;
  accounts: IAccount[];
  onBack?: () => void;
  onNext?: () => void;
  setFundingSource?: boolean;
  setPaymentMethod?: boolean;
  setStandardPayoutDestination?: boolean;
  setInstantPayoutDestination?: boolean;
};

export const FormSelectAccount: React.FC<Props> = ({
  title,
  description,
  accounts,
  onBack,
  onNext,
  setFundingSource,
  setPaymentMethod,
  setStandardPayoutDestination,
  setInstantPayoutDestination
}) => {
  const userId = useUserId();
  const [submit, meta] = useWSMutation<
    void,
    WSServiceError,
    { accountId: string }
  >(
    async ({ accountId }) => {
      if (setFundingSource) {
        const payrollSettings = await paymentsService.payrollSettings.update(
          userId,
          {
            fundingSource: {
              fundingSourceCurrency: Currency.USD,
              fundingSourceId: accountId,
              fundingSourceType: FundingSourceType.Account
            }
          }
        );

        WSQueryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      }

      if (setPaymentMethod) {
        const client = await usersService.client.update(userId, {
          clientId: userId,
          profile: {
            defaultPaymentMethod: {
              accountId,
              paymentMethodId: null
            }
          }
        });

        WSQueryCache.setQueryData(QUERY_USERS_CLIENT, client);
      }

      if (setStandardPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Standard,
            standard: {
              destinationId: accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        WSQueryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      }

      if (setInstantPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Instant,
            instant: {
              destinationId: accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        WSQueryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      }
    },
    {
      onSuccess: onNext,
      dependencies: [QUERY_PAYROLL_SETTINGS, QUERY_PAYOUT_SETTINGS]
    }
  );

  return (
    <StepLayoutWithOptions
      key="accounts"
      title={title}
      description={description}
      options={accounts.map(account => ({
        header: {
          label: {
            text: selectorAccountInstitution(account),
            secondaryText: selectorAccountName(account),
            avatar: avatarBankAccount
          }
        },
        value: account.accountId
      }))}
      onBack={onBack}
      onNext={accountId => {
        submit({ accountId });
      }}
      error={meta.error}
      isLoading={meta.isLoading}
    />
  );
};
