import { WSPage, WSPageToolbar } from "@wingspanhq/fe-component-library";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs } from "../../../../components/Tabs";
import { RouteAddFundingMethod } from "./routes/RouteAddFundingMethod";
import { RouteManageFundingMethod } from "./routes/RouteManageFundingMethod";
import { RouteMethod } from "./routes/RouteMethod";
import { RoutePayables } from "./routes/RoutePayables";
import { RouteSchedule } from "./routes/RouteSchedule";
import { RouteAddEmailSnippet } from "./routes/RouteAddEmailSnippet";
import { RouteEmailInvites } from "./routes/RouteEmailInvites";
import {
  PATH_SETTINGS_EMAIL_INVITES,
  PATH_SETTINGS_EMAIL_INVITES_ADD_SNIPPET,
  PATH_SETTINGS_EMAIL_INVITES_EDIT_SNIPPET,
  PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD,
  PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD,
  PATH_SETTINGS_PAYROLL_METHOD,
  PATH_SETTINGS_PAYROLL_PAYABLES,
  PATH_SETTINGS_PAYROLL_SCHEDULE
} from "../../paths";

export const RoutePayroll: React.FC = () => {
  return (
    <WSPage>
      <WSPageToolbar title="Send payments settings" />

      <Tabs
        mb="2XL"
        tabs={[
          {
            label: "Payroll funding method",
            path: PATH_SETTINGS_PAYROLL_METHOD
          },
          {
            label: "Payroll schedule",
            path: PATH_SETTINGS_PAYROLL_SCHEDULE
          },
          {
            label: "Payables settings",
            path: PATH_SETTINGS_PAYROLL_PAYABLES
          },
          {
            label: "Email invites",
            path: PATH_SETTINGS_EMAIL_INVITES
          }
        ]}
      />

      <Switch>
        <Route path={PATH_SETTINGS_PAYROLL_METHOD} component={RouteMethod} />
        <Route
          path={PATH_SETTINGS_PAYROLL_SCHEDULE}
          component={RouteSchedule}
        />
        <Route
          path={PATH_SETTINGS_PAYROLL_PAYABLES}
          component={RoutePayables}
        />

        <Route
          path={PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD}
          component={RouteManageFundingMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD}
          component={RouteAddFundingMethod}
        />
        <Route
          path={[
            PATH_SETTINGS_EMAIL_INVITES_ADD_SNIPPET,
            PATH_SETTINGS_EMAIL_INVITES_EDIT_SNIPPET
          ]}
          component={RouteAddEmailSnippet}
        />
        <Route
          path={PATH_SETTINGS_EMAIL_INVITES}
          component={RouteEmailInvites}
        />

        <Redirect to={PATH_SETTINGS_PAYROLL_METHOD} />
      </Switch>
    </WSPage>
  );
};
