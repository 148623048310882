import { WSActionsButton, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayee";
import { TaxFormCardView } from "./TaxFormCardView";
import { getPaymentsLinkForTaxDocument } from "../utils/getPaymentsLinkForTaxDocument";
import { useHistory } from "react-router-dom";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { getYearDateRange } from "../utils/getYearDateRange";

export type GetTaxFormCardButton = () => WSActionsButton | null;

export type OptOutPayerCardProps = {
  payer: IPayerResponse;
  year: number;
};

export const OptOutPayerCard: React.FC<OptOutPayerCardProps> = ({
  payer,
  year
}) => {
  const history = useHistory();

  const payerName = wsName({
    member: payer.member,
    user: payer.user!
  }).getResolvedName();

  const supportEmail =
    payer.member?.profile?.company?.supportEmail || payer.user?.email;
  const supportPhoneNumber = payer.member?.profile?.company?.supportPhoneNumber;

  const getContactPayerButton: GetTaxFormCardButton = () =>
    supportEmail
      ? {
          onClick: () => {
            openInNewTab("mailto:" + supportEmail);
          },
          label: "Contact payer support",
          kind: "Secondary",
          rightIcon: "comment"
        }
      : null;

  const getViewPaymentsButton: GetTaxFormCardButton = () => {
    return {
      kind: "Tertiary",
      label: "View payments",
      rightIcon: "chevron-right",
      onClick: () => {
        const defaultRange = getYearDateRange(year);

        history.push(
          getPaymentsLinkForTaxDocument(
            payer.payerId,
            defaultRange.startDate,
            defaultRange.endDate
          )
        );
      }
    };
  };

  return (
    <TaxFormCardView
      content={
        <WSText.ParagraphSm color="gray500" weight="book">
          You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
          {year} because your payer {payerName} did not file with Wingspan for
          the {year} tax year.
        </WSText.ParagraphSm>
      }
      supportEmail={supportEmail}
      supportPhone={supportPhoneNumber}
      buttons={[getContactPayerButton, getViewPaymentsButton]}
      payerName={payerName}
    />
  );
};
