import {
  toWSDate,
  useWSSnackbar,
  WSAlert,
  WSElement,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import {
  buildPayeeEditTaxInfoPath,
  PAYEES_ARCHIVED_PATH,
  PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE,
  PAYEES_LIST_PATH
} from "../../utils";
import { getPayeeNames } from "../../selectors/getPayeeNames";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import { EligibilityRequirements } from "./EligibilityRequirements";
import { usePayeeEngagementsListAllQuery } from "../../../../query/payeeEngagements/queries/usePayeeEngagementsListAllQuery";
import { EngagementsTable } from "./EngagementsTable";
import { EngagementDetailsSidebar } from "./EngagementDetailsSidebar";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useEditContractorModal } from "../../components/UpdateContractorModal/useUpdateContractorModal";
import { ContractorInformation } from "../../components/ContractorInformation";
import { CustomFieldsWidget } from "../../components/CustomFieldsWidget/CustomFieldsWidget";
import { RecentPayablesWidget } from "../../components/RecentPayablesWidget";
import { DocumentsSection } from "../../../Payers/components/DocumentsSection";
import { DocumentsSectionType } from "../../../Payers/components/DocumentsSection/types";
import { useUserId } from "../../../../query/hooks/helpers";
import { DeductionsWidget } from "../../components/DeductionWidget";
import { AutopayWidget } from "../../components/AutopayWidget/AutopayWidget";
import { useCreatePayeeEngagementModal } from "../../components/CreatePayeeEngagementModal/useCreateEngagementModal";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useArchiveContractorModal } from "../../components/ArchiveContractorModal";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { PayeeIntegrationWidget } from "../../../Payers/components/IntegrationsSection/PayeeIntegrationWidget";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useIntegrationsQuickbooks } from "../../../../query/integrations/queries";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";
import { selectorPayeeEngagementsTmp } from "../../selectors/selectorPayeeEngagementsTmp";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";
import { useResendPayeeInvite } from "../../../../query/payee/mutations/useResendPayeeInvite";
import { useAddToGroupModal } from "../../components/AddToGroupModal/useAddToGroupModal";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { selectorPayerPayeeEngagements } from "../../selectors/selectorPayerPayeeEngagements";
import { useInternalNotesModal } from "../../components/InternalNotesModal/useInternalNotesModal";
import { InternalNotesWidget } from "../../components/InternalNotesWidget";
import { ESignatureWidget } from "../../components/ESignatureWidget";
import { useXWingspanPayerId } from "./useXWingspanPayerId";
import { UnavailableOrgWidget } from "./UnavailableOrgWidget";
import { useGoTo } from "../../../../shared/utils/useGoTo";
import { isPayeeProvidedInfo } from "./utils";
import { TaxFilingWidget } from "../../components/TaxFilingWidget/TaxFilingWidget";
import {
  useGetOrganizationUser,
  useMemberProfile
} from "../../../../query/users/queries";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";

export const RoutePayeeDetails: React.FC<
  RouteComponentProps<{
    payeeId: string;
  }>
> = ({ match, history, location }) => {
  const goTo = useGoTo();
  const { payeeId } = match.params;

  const organizationId = useXWingspanPayerId();
  const isOrgContext = !!organizationId;

  const userId = useUserId();
  const queryPayee = usePayeeQuery(payeeId);
  const queryEngagements = usePayeeEngagementsListAllQuery(payeeId);
  const queryOrganization = useGetOrganizationUser(organizationId!, {
    enabled: isOrgContext && organizationId !== userId
  });
  const queryMember = useMemberProfile(userId);

  const queryGroups = useCollaboratorGroupsQuery();
  const modalAddEngagement = useCreatePayeeEngagementModal();
  const modalAddToGroup = useAddToGroupModal();
  const isEngagementsActive = useIsEngagementsActive();
  const modalEditContractor = useEditContractorModal();
  const modalInternalNotes = useInternalNotesModal();
  const modalArchiveContractor = useArchiveContractorModal();
  const [resendInvite, resendInviteMeta] = useResendPayeeInvite();

  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const queryQBO = useIntegrationsQuickbooks();
  const queryFeatureFlags = useFeatureFlags({
    enabled: queryQBO.isFetched
  });
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const { openSnackbar } = useWSSnackbar();
  const [hideInfo, setHideInfo] = useState<boolean>(false);

  return (
    <WSPage>
      <Route
        path={PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE}
        component={EngagementDetailsSidebar}
      />
      <WSQueries
        queries={{
          queryPayee,
          queryFeatureFlags,
          queryEngagements,
          queryGroups,
          queryMember
        }}
      >
        {({
          queryPayeeData,
          queryEngagementsData,
          queryFeatureFlagsData,
          queryGroupsData,
          queryMemberData
        }) => {
          const names = getPayeeNames(queryPayeeData);

          const isQuickbooksActive = selectorIsQBOActive(
            queryFeatureFlagsData,
            queryQBO.data
          );

          const isInactive =
            queryPayeeData.payerOwnedData.status === PayerPayeeStatus.Inactive;
          const isNotRegistered =
            queryPayeeData.user?.status !== UserStatus.Active;
          const isNotShared =
            queryPayeeData.payeeOwnedData?.shareTaxDocument !==
            PayeeTaxDocumentSharePermission.Allow;

          const forceShowAllEngagements =
            isQuickbooksActive ||
            queryEngagementsData.some(
              eng => eng.status === PayerPayeeEngagementStatus.Inactive
            );

          const filteredEngagements = isEngagementsActive
            ? selectorPayerPayeeEngagements(queryEngagementsData, false, true)
            : selectorPayeeEngagementsTmp(
                queryEngagementsData,
                forceShowAllEngagements,
                false,
                true
              );

          const headerActions: WSPageToolbarAction[] = [
            ...(isInactive || !hasPaymentsScope
              ? []
              : ([
                  {
                    label: "Create payable",
                    icon: "plus",
                    disabled: isOrgContext,
                    buttonKind:
                      queryPayeeData.user?.status === UserStatus.Pending
                        ? undefined
                        : "Primary",
                    onClick: () => {
                      history.push("/member/invoices/payables/create", {
                        backPath: location.pathname,
                        email: names.email
                      });
                    }
                  }
                ] as const)),
            ...(isInactive
              ? ([
                  {
                    label: "Restore",
                    onAsyncClick: async () => {
                      await updatePayee(
                        {
                          payeeId: queryPayeeData.payeeId,
                          payerOwnedData: {
                            status: PayerPayeeStatus.Active
                          }
                        },
                        {
                          onSuccess: () => {
                            openSnackbar({
                              duration: 5000,
                              type: "success",
                              message: "Contractor restored successfully"
                            });
                          },
                          onError: () => {
                            openSnackbar({
                              duration: 5000,
                              type: "warning",
                              message: `Failed to restore contractor`
                            });
                          }
                        }
                      );
                    },
                    icon: "repeat-circle"
                  }
                ] as const)
              : ([
                  ...(queryPayeeData.user?.status === UserStatus.Pending
                    ? [
                        {
                          label: "Resend invite",
                          buttonKind: "Primary",
                          onAsyncClick: async () => {
                            await resendInvite(queryPayeeData.payeeId);
                          },
                          icon: "send"
                        }
                      ]
                    : []),
                  ...(isPayeeProvidedInfo(queryPayeeData)
                    ? []
                    : [
                        {
                          label: "Edit contractor",
                          onClick: () => {
                            goTo(buildPayeeEditTaxInfoPath(payeeId));

                            // modalEditContractor.open({
                            //   payee: queryPayeeData
                            // });
                          },
                          icon: "edit"
                        }
                      ]),
                  {
                    label: "Archive contractor",
                    onClick: () => {
                      modalArchiveContractor.open({
                        payee: queryPayeeData,
                        engagements: queryEngagementsData,
                        groups: queryGroupsData
                      });
                    },
                    icon: "archive"
                  },
                  {
                    label: "Edit internal notes",
                    onClick: () => {
                      modalInternalNotes.open({
                        payee: queryPayeeData
                      });
                    },
                    icon: "edit"
                  },
                  // {
                  //   label: "Delete contractor",
                  //   onClick: () => {
                  //     history.push(`${match.url}/delete`);
                  //   },
                  //   destructive: true,
                  //   icon: "trash"
                  // },
                  ...(isEngagementsActive
                    ? [
                        {
                          label: "Add engagement",
                          onClick: () => {
                            modalAddEngagement.open({
                              payeeId: queryPayeeData.payeeId,
                              engagements: queryEngagementsData
                            });
                          },
                          icon: "plus"
                        }
                      ]
                    : [
                        {
                          label: "Add to group",
                          onClick: () => {
                            modalAddToGroup.open({
                              payeeId: queryPayeeData.payeeId,
                              engagements: queryEngagementsData
                            });
                          },
                          icon: "plus"
                        }
                      ])
                ] as WSPageToolbarAction[]))
          ];

          const organizationName = queryOrganization.data
            ? wsName({
                user: queryOrganization.data
              }).getResolvedName(true)
            : undefined;

          return (
            <>
              <WSPageToolbar
                breadcrumbs={[
                  {
                    label: "Contractors",
                    onClick: () => {
                      history.push(PAYEES_LIST_PATH);
                    }
                  },
                  ...(organizationName
                    ? [
                        {
                          label: organizationName,
                          onClick: () => {
                            history.push(
                              PAYEES_LIST_PATH +
                                `?payerId%5B0%5D=${organizationId}`
                            );
                          }
                        }
                      ]
                    : []),
                  ...(queryPayeeData.payerOwnedData.status ===
                  PayerPayeeStatus.Inactive
                    ? [
                        {
                          label: "Archived contractors",
                          onClick: () => {
                            history.push(PAYEES_ARCHIVED_PATH);
                          }
                        }
                      ]
                    : []),
                  { label: names.getResolvedName() }
                ]}
                title={names.getResolvedName()}
                titlePill={
                  queryPayeeData.payerOwnedData.status ===
                  PayerPayeeStatus.Inactive
                    ? {
                        theme: "neutral",
                        text: "Archived",
                        icon: false
                      }
                    : queryPayeeData.user?.status === UserStatus.Active
                    ? { theme: "success", text: "Signed-up", icon: false }
                    : {
                        theme: "warning",
                        text: "Not signed-up",
                        icon: false
                      }
                }
                titleAfter={
                  <WSText.ParagraphXs color="gray500">
                    Last modified{" "}
                    {toWSDate(queryPayeeData.updatedAt).formattedValue}
                  </WSText.ParagraphXs>
                }
                actions={headerActions}
              />
              <WSGrid gutter="XL">
                <WSGrid.Item span={{ m: "8" }}>
                  <WSList gap="XL">
                    {isNotRegistered && !hideInfo && (
                      <WSAlert
                        icon="user"
                        onDismiss={() => {
                          setHideInfo(prev => !prev);
                        }}
                        size="M"
                        theme="info"
                        title="Contractor invited"
                      >
                        <WSText.ParagraphSm weight="book" color="gray700">
                          The contractor has been invited by the email provided
                          to sign-up for a Wingspan account in order to receive
                          payments from you.
                        </WSText.ParagraphSm>
                      </WSAlert>
                    )}
                    {
                      // show if registered but not shared
                      isNotShared && !isNotRegistered && (
                        <WSAlert
                          icon="alert"
                          size="M"
                          theme="warning"
                          title="Tax information not shared"
                        >
                          <WSText.ParagraphSm weight="book" color="gray700">
                            The contractor has not consented to share their tax
                            information with you. Payments to the contractor
                            will still be processed. They have been notified to
                            share their information for tax filing purposes.
                          </WSText.ParagraphSm>
                        </WSAlert>
                      )
                    }
                    <WSElement>
                      <WSSectionToolbar
                        pill={{
                          text: isPayeeProvidedInfo(queryPayeeData)
                            ? "Contractor shared"
                            : "Provided by you",
                          theme: isPayeeProvidedInfo(queryPayeeData)
                            ? "blue"
                            : "neutral"
                        }}
                        title="Contractor information"
                        button={
                          isPayeeProvidedInfo(queryPayeeData)
                            ? undefined
                            : {
                                label: "Edit",
                                onClick: () => {
                                  goTo(buildPayeeEditTaxInfoPath(payeeId));
                                }
                              }
                        }
                      />
                      <WSPanel mt="S">
                        <WSList gap="2XL">
                          <ContractorInformation
                            payee={queryPayeeData}
                            editable
                          />

                          {/*
                          <WSInfoBox>
                            {isPayeeProvidedInfo(queryPayeeData)
                              ? `Contractor shared: last updated on ${toWSDateString(
                                  queryPayeeData.updatedAt
                                )}`
                              : `Payer provided: last updated on ${toWSDateString(
                                  queryPayeeData.updatedAt
                                )}`}
                          </WSInfoBox>
                          */}
                        </WSList>
                      </WSPanel>
                    </WSElement>
                    <EligibilityRequirements
                      payee={queryPayeeData}
                      engagements={queryEngagementsData}
                      noActions={isOrgContext}
                      member={queryMemberData}
                    />
                    {filteredEngagements.length ? (
                      <WSElement>
                        <WSSectionToolbar
                          title="Engagements"
                          button={
                            isEngagementsActive
                              ? {
                                  label: "Add engagement",
                                  onClick: () => {
                                    modalAddEngagement.open({
                                      payeeId: queryPayeeData.payeeId,
                                      engagements: queryEngagementsData
                                    });
                                  }
                                }
                              : undefined
                          }
                        />
                        <EngagementsTable
                          groups={queryGroupsData}
                          engagements={filteredEngagements}
                          payee={queryPayeeData}
                        />
                      </WSElement>
                    ) : null}
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ m: "4" }}>
                  <WSList gap="XL">
                    {hasPaymentsScope ? (
                      isOrgContext ? null : (
                        <RecentPayablesWidget payeeId={payeeId} />
                      )
                    ) : null}

                    <InternalNotesWidget payee={queryPayeeData} />

                    <CustomFieldsWidget
                      payee={queryPayeeData}
                      isInactive={isInactive}
                    />

                    <TaxFilingWidget payeeId={payeeId} />

                    {isOrgContext ? null : (
                      <ESignatureWidget
                        payee={queryPayeeData}
                        engagements={queryEngagementsData}
                      />
                    )}

                    <DocumentsSection
                      isInactive={isInactive}
                      payeeId={queryPayeeData.payeeId}
                      payerId={userId}
                      type={DocumentsSectionType.Collaborator}
                    />

                    {isOrgContext ? null : (
                      <DeductionsWidget
                        clientId={userId}
                        memberId={queryPayeeData.payeeId}
                        onRowClick={row => {
                          history.push(
                            `/member/invoices/payables/deductions/scheduled/${row.deductionId}`
                          );
                        }}
                        onShowAllClick={() => {
                          history.push(
                            `/member/invoices/payables/deductions/scheduled`
                          );
                        }}
                      />
                    )}

                    <AutopayWidget
                      isInactive={isInactive}
                      payee={queryPayeeData}
                    />

                    {isOrgContext ? null : (
                      <PayeeIntegrationWidget
                        isInactive={isInactive}
                        payeeId={queryPayeeData.payeeId}
                        vendorId={
                          queryPayeeData.payerOwnedData.integration?.quickbooks
                            ?.vendorId
                        }
                        expenseAccountId={
                          queryPayeeData.payerOwnedData.integration?.quickbooks
                            ?.expenseAccountId
                        }
                      />
                    )}

                    {isOrgContext ? (
                      <>
                        <UnavailableOrgWidget title="Recent payables" />
                        <UnavailableOrgWidget title="Scheduled deductions" />
                        <UnavailableOrgWidget title="Integration mappings" />
                      </>
                    ) : null}
                  </WSList>
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
