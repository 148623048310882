import React, { useState } from "react";
import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { OnboardingLayout } from "../../../../components/OnboardingLayout/OnboardingLayout";
import {
  useIsMobile,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSScreen,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { FAQ1099 } from "../../components/FAQ1099";
import { useSetWSStore, useWSStore } from "../../../../store";
import styles from "./styles.module.scss";
import { GoogleAuthButton } from "../../../../Auth/components/GoogleAuthButton/GoogleAuthButton";
import { Divider } from "../../../../Auth/components/Divider/Divider";
import * as Yup from "yup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../../shared/validators/validatorPassword";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { WS_LINKS } from "../../../../types/wsLinks";
import {
  useCreateSession,
  useCreateUser,
  useIdentifyAuthentication,
  useSignInWithGoogle
} from "../../../../query/users/mutations";
import {
  ISignInWithGoogleRequest,
  usersService
} from "../../../../services/users";
import { init1099Activity } from "../../../../Auth/components/SignUpForm/update1099Activity";
import { useWSAnalytics } from "../../../../utils/WSAnalytics";
import { useHistory } from "react-router-dom";
import { useNotYouInfoModal } from "./ModalNotYouInfo";
import { useModalGoogleLoginInfo } from "./ModalGoogleLoginInfo";
import { IUserCreateRequest } from "@wingspanhq/users";
import { UserAccountType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { pushSessionToken } from "../../../../services/sessionStorage";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_USERS_SESSION } from "../../../../query/session";

export const RouteSignUpIn1099: React.FC = () => {
  const analytics = useWSAnalytics();
  const setStore = useSetWSStore();
  const store = useWSStore();
  const history = useHistory();

  const modalNotYou = useNotYouInfoModal();
  const modalGoogleLogin = useModalGoogleLoginInfo();

  const inviteEmail = store?.signUp1099?.inviteeEmail;
  const companyName = store?.signUp1099?.companyName;
  const isExistingUser =
    !!inviteEmail && store?.signUp1099?.status === "active";

  const [signUpWithGoogleError, setSignUpWithGoogleError] = useState("");
  const [captchaLoadError, setCaptchaLoadError] = useState(false);
  const [ssoData, setSsoData] = useState({
    url: "",
    domain: ""
  });

  const [createUser, createUserMeta] = useCreateUser();
  const [createSession, createSessionMeta] = useCreateSession();
  const [signUpWithGoogle] = useSignInWithGoogle();
  const [is1099LoginProcess, setIs1099LoginProcess] = useState(false);

  const [identifyAuth, identifyAuthMeta] = useIdentifyAuthentication();

  const isMobile = useIsMobile();

  const onGoogleLoginSuccess = (request: ISignInWithGoogleRequest) => {
    signUpWithGoogle(request, {
      onSuccess: async response => {
        const user =
          response.userId && (await usersService.user.get(response.userId));

        if (user) {
          await init1099Activity(user.userId);
        }

        setStore({ signUpMethod: "Google" });
        analytics.track.signUp();
        history.replace("/member/dashboard");
      }
    });
  };
  const onGoogleLoginFailure = (errorRes: any) => {
    setSignUpWithGoogleError(errorRes.error);
  };

  return (
    <React.Fragment>
      <BrowserPageTitle title="Sign up" />
      <OnboardingLayout>
        <WSGrid>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.Heading4 mb="2XL">
              {companyName} partners with Wingspan to manage and deliver your
              tax forms.
            </WSText.Heading4>
            <WSElement
              mb={isMobile ? "3XL" : "5XL"}
              data-testid="1099-sign-up-content"
            >
              <WSText mt="XL">
                {companyName} partners with Wingspan to file 1099 tax forms that
                report your earnings. You'll need this form to file your 2023
                taxes.
              </WSText>
              <WSText mt="M">
                Confirm your tax information and 1099 delivery preferences with
                Wingspan to ensure your 1099-NEC form is accurate and sent to
                the right place.
              </WSText>
              <WSScreen.Desktop>
                <FAQ1099 mt="2XL" />
              </WSScreen.Desktop>
            </WSElement>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSFlexBox.CenterX
              ml={isMobile ? "NONE" : "3XL"}
              direction="column"
              px={isMobile ? "XL" : "3XL"}
              py="XL"
              className={styles.formContainer}
              alignItems="stretch"
            >
              <WSText.Heading4 mb="2XL">
                {isExistingUser
                  ? "Sign in with your Wingspan account"
                  : "First, create an account"}
              </WSText.Heading4>

              <WSFormOld
                defaultValues={{
                  email: inviteEmail!,
                  password: "",
                  remember: true
                }}
                validationSchema={Yup.object().shape({
                  email: validatorEmail.required("Email is required"),
                  password: (isExistingUser
                    ? Yup.string()
                    : validatorPassword
                  ).required("Password is required"),
                  remember: Yup.boolean()
                })}
                onSubmit={async data => {
                  createUserMeta.reset();
                  createSessionMeta.reset();
                  setCaptchaLoadError(false);
                  setIs1099LoginProcess(false);

                  const request: IUserCreateRequest = {
                    ...data,
                    notificationSettings: {
                      reviewNotifications: true,
                      newsletters: true
                    },
                    ...(store.isEmployerSignUp
                      ? {
                          settings: {
                            userAccountType: UserAccountType.enterprise
                          }
                        }
                      : {})
                  };

                  try {
                    const identifyResult = await identifyAuth(
                      { email: request.email },
                      {
                        throwOnError: true
                      }
                    );

                    if (
                      identifyResult?.authenticationStrategy === "SingleSignOn"
                    ) {
                      setSsoData({
                        url: identifyResult?.authenticationUrl || "",
                        domain: request.email.split("@").pop() || ""
                      });
                      return;
                    } else {
                      setSsoData({
                        url: "",
                        domain: ""
                      });
                    }
                  } catch {
                    setSsoData({
                      url: "",
                      domain: ""
                    });
                  }

                  if (isExistingUser) {
                    setIs1099LoginProcess(true);
                    await createSession(
                      {
                        email: data.email,
                        password: data.password,
                        remember: true
                      },
                      {
                        onSuccess: async session => {
                          const userId =
                            typeof session.userId === "string"
                              ? session.userId
                              : session.userId?.userId;
                          if (
                            userId &&
                            store.signUp1099?.inviteeEmail === data.email
                          ) {
                            await init1099Activity(userId);
                          }
                        }
                      }
                    );
                  } else {
                    await createUser(request, {
                      onSuccess: async user => {
                        if (user.session?.token) {
                          pushSessionToken(user.session.token);
                          WSQueryCache.setQueryData(
                            QUERY_USERS_SESSION,
                            user.session
                          );
                        } else {
                          await createSession(data);
                        }

                        if (store.signUp1099?.inviteeEmail === user.email) {
                          await init1099Activity(user.userId);
                        }

                        setStore({ signUpMethod: "Email" });
                        analytics.track.signUp();
                      },
                      onError: async error => {
                        if (
                          error.response?.status === 401 &&
                          error.response?.data?.errorSubType ===
                            "EmailVerificationRequired"
                        ) {
                          history.push({
                            pathname: "/member/verification-sent-to-email",
                            search: history.location.search,
                            state: {
                              email: request.email,
                              growthSource:
                                store.growthAttributionDetails?.growthSource
                            }
                          });
                        }

                        if (
                          error.response?.status === 400 &&
                          error.response?.data?.error ===
                            "User for email already exists"
                        ) {
                          setIs1099LoginProcess(true);
                          await createSession(
                            {
                              email: data.email,
                              password: data.password,
                              remember: true
                            },
                            {
                              onSuccess: async session => {
                                const userId =
                                  typeof session.userId === "string"
                                    ? session.userId
                                    : session.userId?.userId;
                                if (
                                  userId &&
                                  store.signUp1099?.inviteeEmail === data.email
                                ) {
                                  await init1099Activity(userId);
                                }
                              }
                            }
                          );
                        }
                      }
                    });
                  }
                }}
              >
                <WSFormOld.Field
                  name="email"
                  component={WSTextInput}
                  componentProps={{
                    disabled: true,
                    placeholder: "Email Address",
                    type: "email",
                    className: styles.input
                  }}
                />

                <WSButton.Link
                  my="M"
                  type="button"
                  onClick={async () => {
                    const result = await modalNotYou.open({
                      email: inviteEmail!
                    });

                    if (result) {
                      history.push("/member/sign-up");
                    }
                  }}
                >
                  Not you?
                </WSButton.Link>

                <WSFormOld.Field
                  mb="2XL"
                  name="password"
                  component={WSTextInput}
                  componentProps={{
                    type: "password",
                    placeholder: isExistingUser
                      ? "Password"
                      : "Create a password",
                    className: styles.input
                  }}
                />

                <WSFormOld.Field
                  mb="XL"
                  name="remember"
                  component={WSCheckboxToggle}
                  componentProps={{ label: "Stay signed in" }}
                />

                <WSErrorMessage
                  my="XL"
                  contextKey="Auth"
                  forceShowApiErrors
                  error={
                    (is1099LoginProcess ? undefined : createUserMeta.error) ||
                    createSessionMeta.error ||
                    signUpWithGoogleError
                  }
                />

                <WSFormOld.SubmitButton
                  size="L"
                  name="signUp"
                  fullWidth
                  type="submit"
                  className={styles.tryWSBtn}
                >
                  {isExistingUser ? "Login" : "Get started with Wingspan"}
                </WSFormOld.SubmitButton>

                {isExistingUser && (
                  <WSButton.Link
                    mt="M"
                    type="button"
                    onClick={() => {
                      history.push("/member/reset-password");
                    }}
                  >
                    Forgot password?
                  </WSButton.Link>
                )}

                {ssoData.url ? (
                  <WSText.ParagraphSm color="red500" mt="XL">
                    Your domain <b>{ssoData.domain}</b> has SSO enabled.{" "}
                    <a href={ssoData.url} target="_self">
                      <b>Sign in with SSO →</b>
                    </a>
                  </WSText.ParagraphSm>
                ) : null}

                <Divider or />

                <GoogleAuthButton
                  onLoginSuccess={onGoogleLoginSuccess}
                  onLoginFailure={onGoogleLoginFailure}
                  loginHint={inviteEmail}
                  wrapClick={async callback => {
                    const result = await modalGoogleLogin.open(
                      {
                        email: inviteEmail!
                      },
                      {
                        title: isExistingUser
                          ? "Sign in with Google"
                          : "Sign up with Google"
                      }
                    );

                    if (result) {
                      return callback();
                    }
                  }}
                >
                  {isExistingUser
                    ? "Sign in with Google"
                    : "Sign up with Google"}
                </GoogleAuthButton>

                {captchaLoadError && (
                  <WSText color="garnet" mb="XL">
                    Sorry, something went wrong.{" "}
                    <WSButton.Link
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Reload and try again
                    </WSButton.Link>
                  </WSText>
                )}
              </WSFormOld>
              <WSText.ParagraphSm mt="3XL" color="gray600">
                By proceeding you agree to Wingspan's{" "}
                <a
                  href={WS_LINKS.wingspanTosAcceptance}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                {"& "}
                <a
                  href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                . This site is protected by reCAPTCHA and the Google{" "}
                <a
                  href={WS_LINKS.googlePrivacyPolicy}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href={WS_LINKS.googleTermOfService}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{" "}
                apply.
              </WSText.ParagraphSm>
            </WSFlexBox.CenterX>
          </WSGrid.Item>
          <WSScreen.MobileAndTablet>
            <WSGrid.Item span={{ m: "6" }}>
              <FAQ1099 mt="2XL" />
            </WSGrid.Item>
          </WSScreen.MobileAndTablet>
        </WSGrid>
      </OnboardingLayout>
    </React.Fragment>
  );
};
