import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useInvoiceTemplateQuery } from "../../query/payments/queries";
import { useQueryInvoiceCollaborators } from "../components/InvoiceCollaborators";
import { InvoicesForm } from "../components/InvoicesForm/InvoicesForm";
import { useDefaultInvoiceSubject } from "../utils";
import {
  attachmentsToFormData,
  convertToLineItemFormData,
  creditFeeToFormData,
  defaultFrequency,
  invoiceCollaboratorToFormData,
  lateFeeToFormData,
  paymentMethodsToFormData
} from "../utils/invoicesFormUtils";

type Props = RouteComponentProps<{ invoiceTemplateId: string }>;

export const InvoicesInvoiceTemplateEdit: React.FC<Props> = ({ match }) => {
  const invoiceTemplateId = match.params.invoiceTemplateId;
  const queryInvoiceTemplate = useInvoiceTemplateQuery(invoiceTemplateId);
  const queryInvoiceCollaborators = useQueryInvoiceCollaborators(
    invoiceTemplateId,
    queryInvoiceTemplate.data?.invoiceData.collaborators,
    { enabled: !!queryInvoiceTemplate.data?.invoiceData }
  );

  const defaultSubject = useDefaultInvoiceSubject();

  return (
    <WSQueries
      queries={{
        queryInvoiceTemplate,
        queryInvoiceCollaborators
      }}
    >
      {({
        queryInvoiceTemplate: { data: invoiceTemplate },
        queryInvoiceCollaborators: { data: invoiceCollaborators }
      }) => (
        <InvoicesForm
          context={{
            invoiceTemplate
          }}
          defaultValues={{
            subject:
              String(invoiceTemplate.invoiceData.labels?.subject) ||
              defaultSubject,
            client: {
              payerId: invoiceTemplate.invoiceData.clientId || null,
              payerPayeeEngagementId:
                (invoiceTemplate.invoiceData.memberClientId as string) || null,
              emailsCC: []
            },
            lineItems: (invoiceTemplate?.invoiceData?.lineItems || []).map(
              lineItem => convertToLineItemFormData(lineItem)
            ),
            other: {
              projectName: String(
                invoiceTemplate?.invoiceData?.labels?.projectName || ""
              ),
              notes: invoiceTemplate?.invoiceData?.invoiceNotes || "",
              attachments: attachmentsToFormData(
                invoiceTemplate?.invoiceData.attachments?.customAttachmentIds
              )
            },
            due: "custom",
            customDueDate: invoiceTemplate?.invoiceData?.dueDate || new Date(),
            includesLateFee:
              !!invoiceTemplate.invoiceData.lateFeeHandling &&
              !!invoiceTemplate.invoiceData.lateFeeHandling.frequency,
            lateFee: lateFeeToFormData(
              invoiceTemplate.invoiceData.lateFeeHandling
            ),
            send: {
              type: "date",
              date: invoiceTemplate?.scheduleDates?.[0]?.date || new Date()
            },
            recurring: !!invoiceTemplate?.frequency,
            frequency: {
              every: invoiceTemplate?.frequency?.every
                ? invoiceTemplate.frequency.every
                : defaultFrequency.every,
              interval:
                invoiceTemplate?.frequency?.interval ||
                defaultFrequency.interval,
              end: invoiceTemplate?.frequency?.endDate
                ? "date"
                : defaultFrequency.end,
              endDate:
                invoiceTemplate?.frequency?.endDate || defaultFrequency.endDate,
              endAfter: defaultFrequency.endAfter,
              start: invoiceTemplate?.frequency
                ? "custom"
                : defaultFrequency.start,
              startDate:
                invoiceTemplate?.frequency?.startDate ||
                defaultFrequency.startDate
            },
            invoiceCollaborators:
              invoiceCollaboratorToFormData(invoiceCollaborators),
            purchaseOrderNumber:
              invoiceTemplate.invoiceData.metadata?.purchaseOrderNumber || "",
            paymentMethods: paymentMethodsToFormData(
              invoiceTemplate.invoiceData.acceptedPaymentMethods
            ),
            advanced: {
              sendEmails:
                !!invoiceTemplate?.invoiceData?.notificationPreferences
                  ?.sendInvoice,
              sendReminders:
                !!invoiceTemplate?.invoiceData?.notificationPreferences
                  ?.sendReminders,
              creditFeeHandling: creditFeeToFormData(
                invoiceTemplate.invoiceData
              ),
              date:
                invoiceTemplate?.invoiceData.metadata?.invoiceDate ||
                (invoiceTemplate?.status === FrequencyAndScheduleStatus.Draft
                  ? new Date()
                  : invoiceTemplate.createdAt) ||
                new Date()
            }
          }}
        />
      )}
    </WSQueries>
  );
};
