export const isValidJSON = (body?: string): boolean => {
  try {
    if (body) {
      JSON.parse(body);
      return true;
    }
  } catch (e) {}

  return false;
};
