import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useCallback, useState } from "react";
import { getAllEntries } from "../../../utils/serviceHelper";

import { downloadCSV } from "../../../modules/Reports/utils/sheetjs/downloadCSV";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";
import { getPayables } from "../../../query/payments/selectors";
import { useQueryAllPayeeRows } from "../../../query/search/payee/queries/useQueryAllPayeeRows";
import { paymentsService } from "../../../services/payments";
import { mapPayablesTableToCSV } from "../../screens/payables/csvUtils";
import { getMatchingPayroll } from "../../utils/payables";
import { mapPayableFilters, PayablesFilters } from "./PayablesTable";

export const useDownloadCsv = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const payrollSettingsQuery = usePayrollSettings(userId);
  const queryAllPayeeRows = useQueryAllPayeeRows();

  const allPayeeRows = queryAllPayeeRows.data || [];

  const downloadCsv = useCallback(
    async (filters: PayablesFilters) => {
      try {
        setIsLoading(true);
        setError(null);

        const filter = mapPayableFilters(filters);

        const payables = getPayables(
          await getAllEntries(async (...args) => {
            return (await paymentsService.payable.list(...(args as any))).data;
          }, filter)
        );

        const csvData = mapPayablesTableToCSV(
          payables.map(payable => {
            const payeeRow = allPayeeRows.find(
              row => row.payeeId === payable.memberId
            );

            const payrollRun = payrollSettingsQuery.data
              ? getMatchingPayroll(payrollSettingsQuery.data, payable)
              : undefined;

            return {
              payable,
              payeeRow,
              payrollRun
            };
          })
        );

        downloadCSV(csvData, "Invoices");

        openSnackbar({
          type: "success",
          message: "CSV downloaded"
        });
      } catch (error) {
        setError(error as any);

        console.error("Failed to download CSV", error);

        openSnackbar({
          type: "warning",
          message: "Failed to download CSV"
        });
      } finally {
        setIsLoading(false);
      }
    },
    [openSnackbar, payrollSettingsQuery.data, allPayeeRows]
  );

  return {
    downloadCsv,
    isLoading:
      queryAllPayeeRows.isLoading ||
      payrollSettingsQuery.isLoading ||
      isLoading,
    error
  };
};
