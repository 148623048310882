import {
  FrequencyAndScheduleStatus,
  IBankStatement,
  IClientInvoice,
  ICollaboratorGroupResponse,
  ICollaboratorsReportRequest,
  IInvoice,
  IInvoiceFeeCalculation,
  IInvoiceTemplate,
  InvoiceStatus,
  IPayableSchema,
  IPayrollSettings,
  IServiceStatusResponse
} from "@wingspanhq/payments/dist/interfaces";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import {
  IContractorPayoutsSummaryReportResponse,
  IContractorReportResponse,
  IInvoiceAgingReportRequest,
  IInvoiceAgingReportResponse,
  ILineItemsAgingReportRequest,
  ILineItemsAgingReportResponse,
  IPayableAgingReportRequest,
  IPayableAgingReportResponse,
  IPayrollReportResponse
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import {
  DeductionStatus,
  DeductionType
} from "@wingspanhq/payments/dist/interfaces/deductions";
import { IEligibilityRequirement } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import {
  WSInfiniteQueryConfig,
  WSQueryCache,
  WSQueryConfig
} from "@ws-react-query";
import flatten from "lodash/flatten";
import times from "lodash/times";
import { TRACK_FE_PERFORMANCE_DURATION } from "../../constants/analytics";
import { useXWingspanPayerId } from "../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";
import { ICardResponse } from "../../services/api/banking/cards/types";
import { getPayeeEngagements } from "../../services/payeeEngagement";
import {
  ICardDetailsResponse,
  InvoiceListFilter,
  InvoiceListQuery,
  InvoiceTemplateListQuery,
  PayablesFilter,
  PayablesWithSummary,
  paymentsService
} from "../../services/payments";
import { useAuthorizedScopeGroups } from "../../shared/utils/teamUtils";
import { Await } from "../../utils";
import { track } from "../../utils/analytics";
import {
  concurrentActions,
  getAllEntries,
  ListRequestQuery,
  WSServiceError
} from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_ALL_COLLABORATOR_GROUPS,
  QUERY_BANK_INSTITUTION,
  QUERY_BANK_STATEMENT,
  QUERY_BANK_STATEMENTS_LIST,
  QUERY_CARD,
  QUERY_CARD_LIST,
  QUERY_CLIENT_INVOICE,
  QUERY_CLIENT_INVOICE_FEES,
  QUERY_COLLABORATOR_DEDUCTION,
  QUERY_COLLABORATOR_DEDUCTIONS,
  QUERY_COLLABORATOR_GROUP,
  QUERY_COLLABORATOR_GROUPS,
  QUERY_COLLABORATOR_OPEN_PAYABLES,
  QUERY_ELIGIBILITY_REQUIREMENT,
  QUERY_ELIGIBILITY_REQUIREMENTS,
  QUERY_INVOICE,
  QUERY_INVOICE_TEMPLATE,
  QUERY_INVOICE_TEMPLATES,
  QUERY_INVOICES,
  QUERY_PAYABLES,
  QUERY_PAYMENTS_STATUS,
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYOUT_SETTINGS_DEBIT_CARDS,
  QUERY_PAYROLL_IMMEDIATE_PAYABLES,
  QUERY_PAYROLL_SETTINGS,
  QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY,
  QUERY_REPORTS_COLLABORATORS,
  QUERY_REPORTS_OPEN_LINE_ITEM_AGING,
  QUERY_REPORTS_OPEN_PAYABLES_AGING,
  QUERY_REPORTS_OPEN_RECEIVABLE_AGING,
  QUERY_REPORTS_PAYROLL
} from "./keys";

export const usePaymentsStatusQuery = (
  queryConfig?: WSQueryConfig<IServiceStatusResponse, WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  return useWSQuery(QUERY_PAYMENTS_STATUS, paymentsService.service.get, {
    ...queryConfig,
    enabled: hasPaymentsScope
  });
};

export enum InvoicesDateRangeFilter {
  All = "All",
  PastWeek = "PastWeek",
  PastMonth = "PastMonth",
  PastYear = "PastYear",
  Custom = "Custom"
}

export interface IContractorPayoutsSummaryReportRequest {
  paidFromDate?: Date;
  paidToDate?: Date;
}

export const useInvoicesQuery = (
  config?: WSQueryConfig<IInvoice[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();
  const startTime = Date.now();
  return useWSQuery<IInvoice[], WSServiceError>(
    QUERY_INVOICES,
    () =>
      getAllByParts<InvoiceListQuery>(
        "invoice",
        QUERY_INVOICES,
        {
          filter: {
            status: {
              in: [
                InvoiceStatus.Draft,
                InvoiceStatus.Open,
                InvoiceStatus.Overdue,
                InvoiceStatus.Paid,
                InvoiceStatus.PaymentInTransit,
                InvoiceStatus.Pending
              ]
            },
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            }
          },
          sort: {
            createdAt: "desc"
          }
        },
        undefined,
        () => {
          track(TRACK_FE_PERFORMANCE_DURATION, {
            duration: Date.now() - startTime,
            name: "QueryInvoices",
            is_ended_because_of_error: false
          });
        }
      ),
    {
      onError: () => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryInvoices",
          is_ended_because_of_error: true
        });
      },
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );
};

export const useInvoicesFilteredQuery = (
  filters?: InvoiceListFilter,
  config?: WSQueryConfig<IInvoice[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();
  const startTime = Date.now();
  return useWSQuery<IInvoice[], WSServiceError>(
    [QUERY_INVOICES, filters],
    async () => {
      const results = await getAllEntries(
        paymentsService.invoice.list,
        filters,
        {
          createdAt: "desc"
        }
      );
      track(TRACK_FE_PERFORMANCE_DURATION, {
        duration: Date.now() - startTime,
        name: "QueryInvoicesFiltered",
        is_ended_because_of_error: false
      });
      return results;
    },
    {
      onError: () => {
        track(TRACK_FE_PERFORMANCE_DURATION, {
          duration: Date.now() - startTime,
          name: "QueryInvoicesFiltered",
          is_ended_because_of_error: true
        });
      },
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );
};

export const useInvoiceQuery = (
  invoiceId: string,
  config?: WSQueryConfig<IInvoice, WSServiceError>
) => {
  return useWSQuery<IInvoice, WSServiceError>(
    [QUERY_INVOICE, invoiceId],
    () => paymentsService.invoice.get(invoiceId),
    {
      ...config
    }
  );
};

export const useInvoiceTemplatesQuery = (
  config?: WSQueryConfig<IInvoiceTemplate[], WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const query = useWSQuery<IInvoiceTemplate[], WSServiceError>(
    QUERY_INVOICE_TEMPLATES,
    () =>
      getAllByParts<InvoiceTemplateListQuery>(
        "invoiceTemplate",
        QUERY_INVOICE_TEMPLATES,
        {
          filter: {
            status: {
              in: [
                FrequencyAndScheduleStatus.Active,
                FrequencyAndScheduleStatus.Draft
              ]
            }
          },
          sort: {
            createdAt: "desc"
          }
        }
      ),
    {
      refetchOnMount: false,
      retry: false,
      enabled: hasPaymentsScope,
      ...config
    }
  );

  if (WSQueryCache.getQueryData(getPartialQueryKey(QUERY_INVOICE_TEMPLATES))) {
    query.status = "loading";
    query.isLoading = true;
  }

  return query;
};

export const useInvoiceTemplateQuery = (
  invoiceTemplateId: string,
  config?: WSQueryConfig<IInvoiceTemplate, WSServiceError>
) => {
  return useWSQuery<IInvoiceTemplate, WSServiceError>(
    [QUERY_INVOICE_TEMPLATE, invoiceTemplateId],
    () => paymentsService.invoiceTemplate.get(invoiceTemplateId),
    {
      ...config
    }
  );
};

export const useClientInvoiceQuery = (
  id: string,
  config?: WSQueryConfig<IClientInvoice, WSServiceError>
) => {
  return useWSQuery<IClientInvoice, WSServiceError>(
    [QUERY_CLIENT_INVOICE, id],
    () => paymentsService.client.invoice.get(id),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useClientInvoiceFeesQuery = (
  id: string,
  config?: WSQueryConfig<IInvoiceFeeCalculation, WSServiceError>
) => {
  return useWSQuery<IInvoiceFeeCalculation, WSServiceError>(
    [QUERY_CLIENT_INVOICE_FEES, id],
    () => paymentsService.client.invoice.getFees(id),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useBankStatementsList = (
  config?: WSQueryConfig<IBankStatement[], WSServiceError>
) => {
  return useWSQuery<IBankStatement[], WSServiceError>(
    QUERY_BANK_STATEMENTS_LIST,
    paymentsService.banking.statement.list,
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useGetBankStatement = (
  statementId: string,
  config?: WSQueryConfig<any, WSServiceError>
) => {
  return useWSQuery<any, WSServiceError>(
    QUERY_BANK_STATEMENT,
    () => paymentsService.banking.statement.get(statementId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const usePayrollSettings = (
  memberId: string,
  config?: WSQueryConfig<IPayrollSettings, WSServiceError>
) => {
  return useWSQuery<IPayrollSettings, WSServiceError>(
    QUERY_PAYROLL_SETTINGS,
    () => paymentsService.payrollSettings.get(memberId),
    config
  );
};

export const useCardsQuery = (
  config?: WSQueryConfig<ICardResponse[], WSServiceError>
) => {
  return useWSQuery<ICardResponse[], WSServiceError>(
    QUERY_CARD_LIST,
    paymentsService.banking.card.list,
    {
      retry: false,
      refetchOnMount: false,
      ...config
    }
  );
};

export const useCardQuery = (
  id: string,
  config?: WSQueryConfig<ICardDetailsResponse, WSServiceError>
) => {
  return useWSQuery<ICardDetailsResponse, WSServiceError>(
    [QUERY_CARD, id],
    () => paymentsService.banking.card.get(id),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useCollaboratorGroupsQuery = (
  config?: WSQueryConfig<ICollaboratorGroupResponse[], WSServiceError>
) => {
  return useWSQuery<ICollaboratorGroupResponse[], WSServiceError>(
    QUERY_COLLABORATOR_GROUPS,
    () => getAllEntries(paymentsService.collaboratorGroup.list),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorGroupQuery = (
  collaboratorGroupId: string,
  config?: WSQueryConfig<ICollaboratorGroupResponse, WSServiceError>
) => {
  return useWSQuery<ICollaboratorGroupResponse, WSServiceError>(
    [QUERY_COLLABORATOR_GROUP, collaboratorGroupId],
    () => paymentsService.collaboratorGroup.get(collaboratorGroupId),
    config
  );
};

export const useAllCollaboratorGroupsQuery = (
  sort: { [key: string]: "asc" | "desc" | undefined },
  queryConfig?: WSInfiniteQueryConfig<
    ICollaboratorGroupResponse[],
    WSServiceError
  >
) =>
  useWSInfiniteQuery(
    [QUERY_ALL_COLLABORATOR_GROUPS, sort],
    async ({ pageParam = 1 }) => {
      return await paymentsService.collaboratorGroup.list({
        page: {
          size: 10,
          number: pageParam
        },
        sort
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );

export const useEligibilityRequirementQuery = (
  id: string,
  config?: WSQueryConfig<IEligibilityRequirement, WSServiceError>
) => {
  return useWSQuery<IEligibilityRequirement, WSServiceError>(
    [QUERY_ELIGIBILITY_REQUIREMENT, id],
    () => paymentsService.collaboratorSettings.eligibilityRequirements.get(id),
    config
  );
};

export const useEligibilityRequirementsQuery = (
  config?: WSQueryConfig<IEligibilityRequirement[], WSServiceError>
) => {
  return useWSQuery<IEligibilityRequirement[], WSServiceError>(
    QUERY_ELIGIBILITY_REQUIREMENTS,
    () =>
      getAllEntries(
        paymentsService.collaboratorSettings.eligibilityRequirements.list
      ),
    config
  );
};

export const usePayoutSettings = (
  memberId: string,
  config?: WSQueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.get>>,
    WSServiceError
  >(QUERY_PAYOUT_SETTINGS, () => paymentsService.payoutSettings.get(memberId), {
    refetchOnMount: false,
    ...config
  });
};

export const usePayoutSettingsDebitCards = (
  memberId: string,
  config?: WSQueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.list>>,
    WSServiceError
  >(
    [QUERY_PAYOUT_SETTINGS_DEBIT_CARDS, memberId],
    () => paymentsService.payoutSettings.debitCard.list(memberId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const usePayoutSettingsDebitCard = (
  memberId: string,
  debitCardId: string,
  config?: WSQueryConfig<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.payoutSettings.debitCard.get>>,
    WSServiceError
  >(
    [`QUERY_PAYOUT_SETTINGS_DEBIT_CARD-${debitCardId}`, memberId],
    () => paymentsService.payoutSettings.debitCard.get(memberId, debitCardId),
    {
      refetchOnMount: false,
      ...config
    }
  );
};

export const useBankInstitution = (
  institutionId: string,
  config?: WSQueryConfig<
    Await<ReturnType<typeof paymentsService.banking.institution.get>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof paymentsService.banking.institution.get>>,
    WSServiceError
  >(
    [QUERY_BANK_INSTITUTION, institutionId],
    () => paymentsService.banking.institution.get(institutionId),
    config
  );
};

export const useCollaboratorDeduction = (
  id: string,
  config?: WSQueryConfig<IDeductionResponse, WSServiceError>
) => {
  return useWSQuery<IDeductionResponse, WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTION, id],
    () => paymentsService.collaboratorDeductions.get(id),
    config
  );
};

export const useCollaboratorDeductions = (
  params: {
    memberId?: string;
    clientId?: string;
    status?: DeductionStatus | { in: DeductionStatus[] };
    type?: DeductionType | { in: DeductionType[] };
  },
  config?: WSQueryConfig<IDeductionResponse[], WSServiceError>
) => {
  return useWSQuery<IDeductionResponse[], WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTIONS, params],
    () =>
      paymentsService.collaboratorDeductions.list({
        filter: params,
        page: { size: 100 }
      }),
    config
  );
};

export const useAllCollaboratorDeductions = (
  params: {
    memberId?: string;
    clientId?: string;
    status?: DeductionStatus | { in: DeductionStatus[] };
    "labels.bulkBatchId"?: string;
    type?: DeductionType | { in: DeductionType[] };
    sort?: { name?: "desc" | "asc"; "events.createdAt"?: "desc" | "asc" };
  },
  config?: WSInfiniteQueryConfig<IDeductionResponse[], WSServiceError>
) => {
  const { sort, ...filter } = params;

  return useWSInfiniteQuery<IDeductionResponse[], WSServiceError>(
    [QUERY_COLLABORATOR_DEDUCTIONS, params],
    async ({ pageParam = 1 }) => {
      return paymentsService.collaboratorDeductions.list({
        filter,
        page: { size: 10, number: pageParam },
        sort
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};

export const useQueryPayeeOutstandingInvoices = (
  payeeId: string,
  config?: WSQueryConfig<IPayableSchema[], WSServiceError>
) => {
  return useWSQuery<IPayableSchema[], WSServiceError>(
    [QUERY_COLLABORATOR_OPEN_PAYABLES, payeeId],
    async () => {
      const { data: payables } = await paymentsService.payable.list({
        filter: {
          memberId: payeeId,
          status: {
            in: [
              InvoiceStatus.Pending,
              InvoiceStatus.Open,
              InvoiceStatus.Overdue
            ]
          }
        }
      });
      return payables;
    },

    config
  );
};

export const useQueryEngagementOutstandingInvoices = (
  payerPayeeEngagementId: string,
  config?: WSQueryConfig<IPayableSchema[], WSServiceError>
) => {
  return useWSQuery<IPayableSchema[], WSServiceError>(
    [QUERY_COLLABORATOR_OPEN_PAYABLES, payerPayeeEngagementId],
    async () => {
      const { data: payables } = await paymentsService.payable.list({
        filter: {
          memberClientId: payerPayeeEngagementId,
          status: {
            in: [
              InvoiceStatus.Pending,
              InvoiceStatus.Open,
              InvoiceStatus.Overdue
            ]
          }
        }
      });
      return payables;
    },

    config
  );
};

export const usePayeeOpenPayblesQuery = (
  payeeId: string,
  config?: WSQueryConfig<IPayableSchema[], WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSQuery<IPayableSchema[], WSServiceError>(
    [QUERY_COLLABORATOR_OPEN_PAYABLES, payeeId, xWingspanPayerId],
    async () => {
      const { data: engagements } = await getPayeeEngagements(
        payeeId,
        xWingspanPayerId
      );
      const engagementIds = engagements.map(e => e.payerPayeeEngagementId);
      const { data: payables } = await paymentsService.payable.list({
        filter: {
          memberClientId: {
            in: engagementIds
          },
          status: {
            in: [InvoiceStatus.Open, InvoiceStatus.Overdue]
          }
        }
      });
      return payables;
    },

    config
  );
};

export const useCollaboratorsReportsQuery = (
  params: ICollaboratorsReportRequest,
  config?: WSQueryConfig<IContractorReportResponse[], WSServiceError>
) => {
  return useWSQuery<IContractorReportResponse[], WSServiceError>(
    [QUERY_REPORTS_COLLABORATORS, params],
    async () => {
      const pageSize = 500;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.collaborators(params, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.collaborators(params, {
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 1
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useCollaboratorPayablesReportsQuery = (
  params: IContractorPayoutsSummaryReportRequest,
  config?: WSQueryConfig<
    IContractorPayoutsSummaryReportResponse[],
    WSServiceError
  >
) => {
  return useWSQuery<IContractorPayoutsSummaryReportResponse[], WSServiceError>(
    [QUERY_REPORTS_COLLABORATOR_PAYABLES_SUMMARY, params],
    async () => {
      const pageSize = 500;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.collaboratorPayable(params, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.collaboratorPayable(params, {
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 1
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const usePayablesReportsQuery = (
  payrollIds: string[],
  config?: WSQueryConfig<IPayrollReportResponse, WSServiceError>
) => {
  return useWSQuery<IPayrollReportResponse, WSServiceError>(
    [QUERY_REPORTS_PAYROLL, payrollIds],
    async () => {
      const pageSize = 1000;
      const filter = {
        invoiceId:
          payrollIds.length > 1
            ? { in: payrollIds }
            : {
                "=": payrollIds[0]
              }
      };

      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.payroll({
        filter,
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.payroll({
            filter,
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenPayableAgingReportsQuery = (
  request: IPayableAgingReportRequest,
  config?: WSQueryConfig<IPayableAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<IPayableAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_PAYABLES_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openPayableAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.openPayableAging(request, {
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenReceivableAgingReportsQuery = (
  request: IInvoiceAgingReportRequest,
  config?: WSQueryConfig<IInvoiceAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<IInvoiceAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_RECEIVABLE_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openReceivableAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.openReceivableAging(request, {
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useOpenLineItemAgingReportsQuery = (
  request: ILineItemsAgingReportRequest,
  config?: WSQueryConfig<ILineItemsAgingReportResponse[], WSServiceError>
) => {
  return useWSQuery<ILineItemsAgingReportResponse[], WSServiceError>(
    [QUERY_REPORTS_OPEN_LINE_ITEM_AGING, request],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await paymentsService.reports.openLineItemAging(request, {
        page: {
          size: pageSize,
          number: 1
        }
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          paymentsService.reports.openLineItemAging(request, {
            page: { size: pageSize, number: i + 2 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useQueryPayrollImmediatePayables = (
  config?: WSInfiniteQueryConfig<PayablesWithSummary, WSServiceError>
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const size = 100;

  const query = useWSInfiniteQuery<PayablesWithSummary, WSServiceError>(
    [QUERY_PAYROLL_IMMEDIATE_PAYABLES, { size }],
    ({ pageParam = 1 }) => {
      return paymentsService.payroll.immediate.payable.list({
        page: {
          size,
          number: pageParam
        },
        sort: {
          updatedAt: "desc"
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      enabled: hasPaymentsScope,
      ...config
    }
  );

  const data = query.data
    ? {
        data: flatten(query.data.pages.map(page => page.data)),
        summary: query.data.pages[0]?.summary
      }
    : undefined;

  return {
    ...query,
    data
  };
};

export const useQueryPayables = (
  pageSize: number = 20,
  params?: {
    filters?: PayablesFilter;
    userId?: string;
  },
  config?: WSInfiniteQueryConfig<PayablesWithSummary, WSServiceError>
) => {
  const query = useWSInfiniteQuery<PayablesWithSummary, WSServiceError>(
    [
      QUERY_PAYABLES,
      { filters: params?.filters, size: pageSize, userId: params?.userId }
    ],
    ({ pageParam = 1 }) => {
      return paymentsService.payable.list(
        {
          filter: params?.filters,
          page: {
            size: pageSize,
            number: pageParam
          },
          sort: {
            "events.paidAt": "desc"
          }
        },
        params?.userId
      );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < pageSize) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  const data = query.data
    ? {
        data: flatten(query.data.pages.map(page => page.data)),
        summary: query.data.pages[0]?.summary
      }
    : undefined;

  return {
    ...query,
    data
  };
};

const getPartialQueryKey = (queryKey: string) => queryKey + "_PARTIAL";

export const getAllByParts = async <L extends ListRequestQuery<any, any>>(
  entity: "invoice" | "invoiceTemplate",
  queryKey: string,
  query: L,
  concurrentLimit: number = 1,
  onFinished?: () => void
) => {
  const isCacheEmpty = !WSQueryCache.getQueryData(queryKey);
  const listSize = await paymentsService[entity].listSize(query);
  const pagesSize = Math.ceil(listSize / 100);
  const pages: any[][] = [];

  const actions = times(pagesSize).map(n => async () => {
    const page = await paymentsService[entity].list({
      ...query,
      page: { size: 100, number: n + 1 }
    });

    pages.push(page);

    if (isCacheEmpty) {
      WSQueryCache.setQueryData(getPartialQueryKey(queryKey), true);
      WSQueryCache.setQueryData(queryKey, flatten(pages));
    }
  });

  await concurrentActions(actions, { concurrentLimit });

  WSQueryCache.setQueryData(getPartialQueryKey(queryKey), false);
  const flattened = flatten(pages);

  if (onFinished) {
    onFinished();
  }

  return flattened;
};
