import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { Await } from "../../../utils";
import bookkeepingService from "../../../services/bookkeeping";

export const QUERY_CASHBACK_PROMOTION = "QUERY_CASHBACK_PROMOTION";

export const useCashbackPromotionQuery = (
  queryConfig?: WSQueryConfig<
    Await<ReturnType<typeof bookkeepingService.getCashbackPromotion>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_CASHBACK_PROMOTION],
    async () => await bookkeepingService.getCashbackPromotion(),
    {
      retry: 1,
      ...queryConfig
    }
  );
