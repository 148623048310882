import { TransferType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IPayerResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { CreditCardPreview } from "../../ClientPayments/components/CreditCardPreview/CreditCardPreview";
import { PublicAttachmentDownloadLink } from "../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { getPayerName } from "../../modules/Payers/utils/getPayerNames";
import { calculateLineItemsTotal } from "../utils";
import { InvoiceCollaborators } from "./InvoiceCollaborators";
import { InvoiceTotals } from "./InvoiceTotals/InvoiceTotals";

type Props = { invoice: IInvoice; payer: IPayerResponse; is1099Flow?: boolean };

export const InvoiceDetails: React.FC<Props> = ({
  invoice,
  payer,
  is1099Flow
}) => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const payerName = getPayerName(payer);
  const payerEmail = payer.user?.email;

  return (
    <Card>
      <WSFlexBox wrap="nowrap" justify="space-between">
        <WSButton.Link
          onClick={() => {
            setVisible(prev => !prev);
          }}
          rightIcon={visible ? "caret-up" : "caret-down"}
        >
          {visible ? "Hide" : "Show"} invoice details
        </WSButton.Link>
        <WSText.Heading5>
          {toWSMoneyString(
            calculateLineItemsTotal(invoice.lineItems),
            "default",
            invoice.currency
          )}
        </WSText.Heading5>
      </WSFlexBox>

      {visible && (
        <WSElement mt="XL">
          <WSElement mb="XL">
            <WSText.ParagraphSm mb="XS" color="gray500">
              Bill to
            </WSText.ParagraphSm>
            {payerName !== payerEmail && <WSText>{payerName}</WSText>}
            <WSText.ParagraphSm
              color="blue400"
              weight="medium"
              onClick={() => {
                history.push({
                  pathname: `/member/payers/${payer.payerId}`
                });
              }}
            >
              {[payerEmail, ...(payer.payeeOwnedData.payerEmailCC || [])].join(
                ", "
              )}
            </WSText.ParagraphSm>
          </WSElement>
          {invoice.metadata?.purchaseOrderNumber ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                PO Number
              </WSText.ParagraphSm>
              <WSText>{invoice.metadata?.purchaseOrderNumber}</WSText>
            </WSElement>
          ) : null}
          <WSElement mb="XL">
            <WSText.ParagraphSm mb="XS" color="gray500">
              Due date
            </WSText.ParagraphSm>
            <WSText>{toWSDateString(invoice.dueDate, "monthDayYear")}</WSText>
          </WSElement>
          {invoice.invoiceNotes && (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Invoice notes
              </WSText.ParagraphSm>
              <WSText>{invoice.invoiceNotes}</WSText>
            </WSElement>
          )}
          {invoice.labels &&
            Object.keys(invoice.labels)
              .filter(key => key[0] === key[0].toUpperCase())
              .map(key => (
                <WSElement mb="XL" key={key}>
                  <WSText.ParagraphSm mb="XS" color="gray500">
                    {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </WSText.ParagraphSm>
                  <WSText>
                    {(invoice.labels[key] as any) instanceof Date
                      ? toWSDateString(invoice.dueDate, "monthDayYear")
                      : invoice.labels[key]}
                  </WSText>
                </WSElement>
              ))}
          {invoice.paymentInfo?.meansType && (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Paid with
              </WSText.ParagraphSm>
              {invoice.paymentInfo.meansType === TransferType.Account ? (
                <WSText>
                  ACH transfer: {invoice.paymentInfo.meansDescription}
                </WSText>
              ) : (
                <>
                  <WSText>Credit card</WSText>
                  <CreditCardPreview
                    paymentMethod={{
                      last4: invoice.paymentInfo.meansLast4,
                      cardBrand: invoice.paymentInfo.cardBrand
                    }}
                  />
                </>
              )}
            </WSElement>
          )}

          {invoice.events.markedPaidAt ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Paid date
              </WSText.ParagraphSm>
              <WSText>
                {toWSDateString(invoice.events.markedPaidAt, "monthDayYear")}
              </WSText>
            </WSElement>
          ) : invoice.events.instantPayoutAt ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Instant payout date
              </WSText.ParagraphSm>
              <WSText>
                {toWSDateString(invoice.events.instantPayoutAt, "monthDayYear")}
              </WSText>
            </WSElement>
          ) : invoice.events.depositedAt ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Payout initiated date
              </WSText.ParagraphSm>
              <WSText>
                {toWSDateString(invoice.events.depositedAt, "monthDayYear")}
              </WSText>
              <WSText color="gray500" mt="XS">
                Funds may take 1–2 business days to arrive.
              </WSText>
            </WSElement>
          ) : invoice.member.payoutPreferences === PayoutPreferences.Instant ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Estimated payout initiation date
              </WSText.ParagraphSm>
              <WSText>
                {toWSDateString(new Date(), "monthDate")} (instant deposit)
              </WSText>
            </WSElement>
          ) : invoice.events.estimatedDepositAt ? (
            <WSElement mb="XL">
              <WSText.ParagraphSm mb="XS" color="gray500">
                Payout initiation date
              </WSText.ParagraphSm>
              <WSText>
                {toWSDateString(invoice.events.estimatedDepositAt, "monthDate")}
              </WSText>
              <WSText color="gray500" mt="XS">
                Funds may take 1–2 business days to arrive.
              </WSText>
            </WSElement>
          ) : null}
          <InvoiceTotals
            invoiceId={invoice.invoiceId}
            is1099Flow={is1099Flow}
          />
          {invoice.lateFeeHandling &&
            (!!invoice.lateFeeHandling.lateFeePercentage ||
              !!invoice.lateFeeHandling.lateFeeAmount) && (
              <WSElement mt="XL">
                <WSText.ParagraphSm mb="XS" color="gray500">
                  Late fee
                </WSText.ParagraphSm>
                <WSText>
                  {invoice.lateFeeHandling.lateFeePercentage
                    ? `${invoice.lateFeeHandling.lateFeePercentage}%`
                    : toWSMoneyString(
                        invoice.lateFeeHandling.lateFeeAmount,
                        "default",
                        invoice.currency
                      )}{" "}
                  every {invoice.lateFeeHandling.frequency.every}{" "}
                  {invoice.lateFeeHandling.frequency.interval} overdue
                </WSText>
              </WSElement>
            )}
          {invoice.attachments?.customAttachmentIds && (
            <WSElement mt="XL">
              {invoice.attachments?.customAttachmentIds.map(id => (
                <PublicAttachmentDownloadLink id={id} mb="M" />
              ))}
            </WSElement>
          )}
          {invoice.collaborators && invoice.collaborators.length > 0 && (
            <WSElement mt="3XL">
              <WSText.Heading5 mb="M">Collaborators</WSText.Heading5>
              <InvoiceCollaborators invoice={invoice} />
            </WSElement>
          )}
        </WSElement>
      )}
    </Card>
  );
};
