import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { downloadFileFromBlob } from "../../../../../utils/files";
import useSnackbar from "../../../../../hooks/useSnackbar";
import {
  copyTaxFormSubmissionSecureLink,
  getIsSecureLinkExpired
} from "../../../../TaxFiling/utils/secureLink";
import { selectorTaxFormIsPDFDownloadAvailableForPayer } from "../../../selectors/selectorTaxFormIsPDFDownloadAvailableForPayer";
import { useModalGenerateSecureLink } from "../../../../TaxFiling/routes/Dashboard/RouteDashboard/useModalGenerateSecureLink";

type Props = {
  taxForm: IPayerTaxFormResponse;
} & WSElementProps;

export const Documents: React.FC<Props> = ({ taxForm, ...elementProps }) => {
  // reverse performed in place
  const documents = taxForm.submissions
    ? [...taxForm.submissions].reverse()
    : [];
  const [downloadDocument, _downloadDocumentMeta] =
    useTaxFormDownloadDocument();

  const generateSecureLinkModal = useModalGenerateSecureLink();
  const { success } = useSnackbar();

  return (
    <WSElement {...elementProps}>
      {documents.map((document, index) => {
        let postfix = "";

        if (documents.length > 1) {
          if (index === documents.length - 1) {
            postfix = "(original)";
          } else if (index === 0) {
            postfix = "(current)";
          } else {
            postfix = "(updated)";
          }
        }

        const date = document.events?.submittedAt;
        const name = `Form 1099-NEC ${postfix}`;

        const isSecureLinkExpired = document?.secureSharing?.createdAt
          ? getIsSecureLinkExpired(document.secureSharing?.createdAt)
          : false;

        const isSecureLinkReadyToGenerate =
          selectorTaxFormIsPDFDownloadAvailableForPayer(taxForm) &&
          (document?.secureSharing?.createdAt ? isSecureLinkExpired : true);

        const isSecureLinkReadyToCopy =
          !isSecureLinkExpired && !!document?.secureSharing?.token;

        return (
          <WSPanel key={`${document.taxFormSubmissionId}-${index}`} mb="M">
            <WSFlexBox
              wrap="nowrap"
              alignItems="stretch"
              justify="space-between"
            >
              <WSFlexBox.CenterX direction="column">
                <WSText.Heading4>{name}</WSText.Heading4>
                {date && (
                  <WSText.ParagraphSm
                    mt="S"
                    color="gray500"
                    formatDate="monthDayYear"
                  >
                    {date}
                  </WSText.ParagraphSm>
                )}
              </WSFlexBox.CenterX>
              <WSList gap="S">
                <WSButton.Secondary
                  fullWidth
                  size="S"
                  onAsyncClick={async () => {
                    await downloadDocument(
                      {
                        viewer: TaxFormViewer.payer,
                        taxFormId: taxForm.taxFormId,
                        submissionId: document.taxFormSubmissionId
                      },
                      {
                        throwOnError: true,
                        onSuccess(pdf) {
                          downloadFileFromBlob(pdf, `${name} payer copy.pdf`);
                        }
                      }
                    );
                  }}
                >
                  Download payer copy
                </WSButton.Secondary>
                <WSButton.Secondary
                  fullWidth
                  size="S"
                  onAsyncClick={async () => {
                    await downloadDocument(
                      {
                        viewer: TaxFormViewer.payee,
                        taxFormId: taxForm.taxFormId,
                        submissionId: document.taxFormSubmissionId
                      },
                      {
                        throwOnError: true,
                        onSuccess(pdf) {
                          downloadFileFromBlob(pdf, `${name} payee copy.pdf`);
                        }
                      }
                    );
                  }}
                >
                  Download payee copy
                </WSButton.Secondary>

                {isSecureLinkReadyToGenerate ? (
                  <WSButton.Secondary
                    fullWidth
                    size="S"
                    onAsyncClick={async () => {
                      generateSecureLinkModal.open({
                        taxFormId: taxForm.taxFormId,
                        submissionId: document.taxFormSubmissionId
                      });
                    }}
                  >
                    Generate secure share link
                  </WSButton.Secondary>
                ) : isSecureLinkReadyToCopy ? (
                  <WSButton.Secondary
                    fullWidth
                    size="S"
                    onAsyncClick={async () => {
                      copyTaxFormSubmissionSecureLink(
                        document?.secureSharing?.token!
                      );
                      success("Secure link copied to clipboard");
                    }}
                  >
                    Copy secure share link
                  </WSButton.Secondary>
                ) : null}
              </WSList>
            </WSFlexBox>
          </WSPanel>
        );
      })}
    </WSElement>
  );
};
