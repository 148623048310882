import { WSGrid, WSList } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AlertPendingAccountMicroDeposit } from "../../../modules/Settings/components/AlertPendingAccountMicroDeposit";
import { DefaultPaymentMethod } from "../../../modules/Settings/components/payment/DefaultPaymentMethod";
import { PaymentHistory } from "../../components/PaymentHistory";

export const History: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  return (
    <WSGrid gutter={{ m: "3XL" }}>
      <WSGrid.Item span={{ m: "7" }}>
        <PaymentHistory />
      </WSGrid.Item>
      <WSGrid.Item span={{ m: "5" }}>
        <WSList gap="2XL">
          <AlertPendingAccountMicroDeposit />

          <DefaultPaymentMethod
            helperText=""
            onManage={() => {
              history.push("/payment/manage-payment-method");
            }}
            onAddNew={() => {
              history.push("/payment/add-payment-method");
            }}
          />
        </WSList>
      </WSGrid.Item>
    </WSGrid>
  );
};
