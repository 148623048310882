import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_USERS_ACTIVITIES } from "../../../../query/users/keys";
import { usersService } from "../../../../services/users";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";

export const useMutationSubmit = (config?: WSMutationConfig<void>) => {
  const userId = useUserId();

  return useWSMutation<any, WSServiceError>(
    async () => {
      await usersService.activity.update(userId, {
        events: {
          w8BenFormAcknowledged: new Date()
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES, QUERY_NOTIFICATIONS_NOTIFICATIONS],
      ...config
    }
  );
};
