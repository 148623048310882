import {
  WSButton,
  WSCentered,
  WSFormOld,
  WSText,
  WSTextInput,
  useIsDesktop
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import queryString from "qs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../query/hooks/helpers";
import { useBeginEmailVerification } from "../../query/users/mutations";
import { useUserProfile } from "../../query/users/queries";
import { ErrorContextKey } from "../../services/platform";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { censorEmail } from "../../utils/stringHelper";
import styles from "./styles.module.scss";

export interface BeginEmailVerificationProps {}

export const BeginEmailVerification: React.FC<
  BeginEmailVerificationProps
> = () => {
  const location = useLocation<{ email?: string; redirectPath?: string }>();
  const isDesktop = useIsDesktop();
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const [email, setEmail] = useState(location.state?.email || "");
  const [showInstructions, setShowInstructions] = useState(false);
  const history = useHistory();
  const [beginEmailVerification, beginEmailVerificationMeta] =
    useBeginEmailVerification(userId);
  const queries = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const redirectPath = location.state?.redirectPath;

  useEffect(() => {
    const newEmail = queries.newEmail as string;
    if (newEmail) {
      const data = {
        email: newEmail
      };
      onFormSubmit(data);
    }
  }, []);

  const onFormSubmit = async (data: any) => {
    setEmail(data.email);
    const request = {
      email: data.email,
      redirectPath
    };
    await beginEmailVerification(request, {
      onSuccess: () => {
        setShowInstructions(true);
      }
    });
  };

  const onResend = async () => {
    const request = {
      email
    };
    await beginEmailVerification(request);
  };

  const isEdit = queries.edit ? Number(queries.edit) : 0;

  return (
    <OnboardingLayout
      onBack={
        redirectPath
          ? () => {
              if (redirectPath) {
                history.push(redirectPath);
              }
            }
          : undefined
      }
    >
      <BrowserPageTitle title="Verify Email | Wingspan" />

      <WSCentered span={{ m: "6", xl: "4" }}>
        {showInstructions ? (
          <>
            <WSText.Heading5 mb="M">Verify your e-mail</WSText.Heading5>
            <WSText color="gray600" mb="XL">
              {`A verification e-mail has been sent to 
${censorEmail(email)}. Please check your 
E-mail inbox for further instructions.`}
            </WSText>
            <WSText color="gray600" mb="XL">
              Didn't receive e-mail yet?{" "}
              <WSButton.Link
                onClick={onResend}
                loading={beginEmailVerificationMeta.isLoading}
              >
                Resend
              </WSButton.Link>
            </WSText>
          </>
        ) : (
          <>
            <WSText.Heading5 mb="M">
              {isEdit ? "Update your e-mail" : "Verify your e-mail"}
            </WSText.Heading5>
            <WSText color="gray600" mb="XL">
              Please specify your {isEdit ? "new " : ""}email address <br />
              to receive further instructions.
            </WSText>
            <WSFormOld
              onSubmit={onFormSubmit}
              defaultValues={{
                email: location.state?.email || queries.newEmail || ""
              }}
              validationSchema={Yup.object().shape({
                email: isEdit
                  ? validatorEmail
                      .notOneOf(
                        [userQuery.data?.email],
                        "Please use an email address that is different from your current one"
                      )
                      .required("Required")
                  : validatorEmail.required("Required")
              })}
            >
              <WSFormOld.Field
                name="email"
                component={WSTextInput}
                componentProps={{
                  type: "email",
                  className: styles.input
                }}
                label="Email"
              />

              <WSErrorMessage
                mt="M"
                error={beginEmailVerificationMeta.error}
                contextKey={ErrorContextKey.VerifyEmail}
              />
              <WSFormOld.SubmitButton
                mt={isDesktop ? "3XL" : "2XL"}
                name="sendLink"
                fullWidth
                className={cn(styles.button, styles.sendCodeBtn)}
                loading={beginEmailVerificationMeta.isLoading}
              >
                Continue
              </WSFormOld.SubmitButton>
            </WSFormOld>
          </>
        )}
      </WSCentered>
    </OnboardingLayout>
  );
};
