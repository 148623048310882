import {
  IPayeeTaxFormResponse,
  TaxFormType
} from "@wingspanhq/payments/dist/interfaces";
import { WSQueryConfig } from "@ws-react-query";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_LIST_PAYEE } from "../keys";

export const usePayeeTaxForms = (
  query: {
    year: number;
    type?: TaxFormType;
  },
  queryConfig?: WSQueryConfig<IPayeeTaxFormResponse[], WSServiceError>
) =>
  useWSQuery<IPayeeTaxFormResponse[], WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYEE, query],
    () => paymentsService.taxForm.payeeList(query.year, query.type),
    queryConfig
  );
