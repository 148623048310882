import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import React, { useCallback, useEffect } from "react";
import { WSQueryCache } from "@ws-react-query";
import { RouteComponentProps } from "react-router-dom";
import { usersService } from "../services/users";
import { useResetWSStore } from "../store";
import { reset as analyticsReset, track } from "../utils/analytics";
import { KEY_SELECTED_ORGANIZATION_ACCOUNTS } from "../modules/SelectOrganizationAccount/constants/storage";

export const SignOut: React.FC<
  RouteComponentProps<
    {},
    {},
    {
      forceLogout?: boolean;
    }
  >
> = ({ history, location }) => {
  const resetWSStore = useResetWSStore();
  const snackbar = useWSSnackbar();

  const reset = useCallback(() => {
    WSQueryCache.clear();
    window.sessionStorage.removeItem("partner");
    window.sessionStorage.removeItem(KEY_SELECTED_ORGANIZATION_ACCOUNTS);
    window.localStorage.removeItem("signUp1099");
    window.localStorage.removeItem("invoiceCurrency");
    resetWSStore();

    analyticsReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    try {
      if (location.state?.forceLogout) {
        snackbar.openSnackbar({
          message:
            "For security reasons, your login has timed out. Please log in again to continue.",
          icon: {
            name: "logout"
          }
        });
      }

      const redirectTo = location.hash
        ? location.hash.slice(1)
        : "/member/sign-in";

      usersService.session.delete().catch(console.error);

      reset();

      track("Auth", { action: "Log Out" });
      history.replace(redirectTo);
    } catch (error) {
      console.log("logout error");
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
