import cn from "classnames";
import React, { KeyboardEventHandler, useCallback, useEffect } from "react";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSScreen } from "../layout";
import { WSElement } from "../WSElement/WSElement.component";
import styles from "./WSModal.module.scss";
import { WSSidebar } from "../common/WSSidebar/WSSidebar.component";
import { WSDivider } from "../WSDivider/WSDivider";
import { WSAvatar } from "../common/WSAvatar/WSAvatar.component";
import { WSActions } from "../WSActions/WSActions";
import { modalIconSizeMap, modalPaddingMap, WSModalProps } from "./utils";

const CONFIRM_CLOSE_MESSAGE =
  "Are you sure you want to close? Your work won't be saved.";

export const WSModal: React.FC<WSModalProps> = (props) => {
  const {
    blockClose,
    onClose,
    title,
    titleAlign = "left",
    children,
    className,
    pullOver,
    showCloseIcon = true,
    fullScreen,
    centered = false,
    fullScreenOnMobile,
    size = "M",
    allowClickOutsideToClose,
    allowClickOutsideWithConfirmationToClose,
    divider = true,
    avatar,
    actions,
    headerActions,
    footer,
    ...elementProps
  } = props;

  // Lock body scroll
  useEffect(() => {
    document.body.classList.add(styles.withModal);
    return () => {
      document.body.classList.remove(styles.withModal);
    };
  }, []);

  useEffect(
    () => () => {
      onClose?.(null);
    },
    [onClose]
  );

  // Single close handler
  const handleClose = useCallback(() => {
    if (!blockClose) {
      onClose?.(null);
    }
  }, [onClose, blockClose]);

  const renderBody = useCallback(
    () => (
      <>
        <WSElement
          className={styles.header}
          pt={modalPaddingMap[size]}
          pl={modalPaddingMap[size]}
          pr={modalPaddingMap[size]}
          pb="M"
        >
          {avatar && <WSAvatar {...avatar} />}
          <WSText.Paragraph
            align={titleAlign}
            className={styles.title}
            weight="medium"
          >
            {title}
          </WSText.Paragraph>
          {headerActions && (
            <WSActions
              className={styles.headerActions}
              orientation="horizontal"
              alignment="right"
              {...headerActions}
            />
          )}
          {showCloseIcon && (
            <WSIcon
              block
              name="exit"
              size={modalIconSizeMap[size]}
              onClick={handleClose}
              className={cn(styles.closeIcon, blockClose && styles.blockClose)}
            />
          )}
        </WSElement>
        {divider && <WSDivider />}
        <WSElement p={modalPaddingMap[size]} className={styles.content}>
          {children}
        </WSElement>
        {actions && (
          <>
            {divider && <WSDivider />}
            <WSElement
              className={styles.actions}
              p={modalPaddingMap[size]}
              pb={modalPaddingMap[size]}
            >
              <WSActions
                orientation="horizontal"
                alignment="right"
                {...actions}
              />
            </WSElement>
          </>
        )}
        {footer}
      </>
    ),
    [
      title,
      children,
      handleClose,
      showCloseIcon,
      blockClose,
      size,
      avatar,
      actions,
      footer
    ]
  );

  // Handle Escape button press
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  return (
    <>
      <WSScreen.Mobile>
        {pullOver || fullScreenOnMobile ? (
          <>
            <WSElement className={styles.overlay} onClick={handleClose} />
            <WSElement
              className={cn(
                styles.base,
                {
                  [styles.fullScreen]: fullScreenOnMobile,
                  [styles.centered]: centered
                },
                className
              )}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              ref={(element) => {
                if (element) {
                  element.focus();
                  element.scrollTop = 0;
                }
              }}
              {...elementProps}
            >
              {renderBody()}
            </WSElement>
          </>
        ) : (
          <WSSidebar
            onClose={handleClose}
            noCloseIcon
            className={cn(styles.drawerBase, className)}
            onKeyDown={handleKeyDown}
            position="bottom"
            contentElementProps={{
              colorBackground: "white",
              p: "NONE"
            }}
            size="AUTO"
            {...elementProps}
          >
            {renderBody()}
          </WSSidebar>
        )}
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSElement
          data-active-modal
          className={cn(styles.wrapper, {
            [styles.blockClose]: blockClose,
            [styles.wrapperFullScreen]: fullScreen
          })}
          onClick={
            allowClickOutsideToClose || allowClickOutsideWithConfirmationToClose
              ? (event) => {
                  if (event.target === event.currentTarget) {
                    if (allowClickOutsideToClose) {
                      handleClose();
                    } else if (allowClickOutsideWithConfirmationToClose) {
                      if (window.confirm(CONFIRM_CLOSE_MESSAGE)) {
                        handleClose();
                      }
                    }
                  }
                }
              : undefined
          }
        >
          <WSElement
            className={cn(
              styles.base,
              {
                [styles.fullScreen]: fullScreen,
                [styles.centered]: centered
              },
              className,
              styles[`size-${size}`]
            )}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            ref={(element) => {
              if (element) {
                element.focus();
                element.scrollTop = 0;
              }
            }}
            {...elementProps}
          >
            {renderBody()}
          </WSElement>
        </WSElement>
      </WSScreen.TabletAndDesktop>
    </>
  );
};

WSModal.displayName = "WSModal";
