import {
  toWSDateString,
  useIsDesktop,
  WSAlert,
  WSButton,
  WSDataItem,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import api from "@flatfile/api";
import { CSVLink } from "react-csv";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { WSQueries } from "../../../../query/WSQuery";
import { useGoToPayableBatches } from "../../paths";

import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { PayableBatchStatusWidget } from "./PayableBatchStatusWidget";
import { PayableBatchDetailsWidget } from "./PayableBatchDetailsWidget";
import { useFlatfileSpaceConfig } from "../../hooks/useFlatfileSpaceConfig";
import { BulkResource } from "../../types";
import {
  FlatfileProvider,
  Space,
  useFlatfile,
  useListener
} from "@flatfile/react";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { flatfileProviderConfig } from "../../utils/flatfileThemeConfig";
import {
  getBulkBatchAge,
  isFileUploaded,
  isOldBulkBatch,
  isUploadInProgress
} from "../../utils/bulkBatchUtils";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import FlatfileListener, { FlatfileEvent } from "@flatfile/listener";
import { PAYABLES_ACTION_SLUG } from "../../constants";
import { WSQueryCache } from "@ws-react-query";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { useDownloadErrorsCSV } from "./useDownloadErrorsCSV";
import { useModalEditBulkBatchName } from "../../components/ModalEditBulkBatchName";
import {
  BulkStatus,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { BulkBatchImportSteps } from "../../components/BulkBatchImportSteps";
import {
  useBulkPayableBatch,
  useBulkPayableBatchSummary
} from "../../query/bulkPayable/queries";
import {
  BULK_PAYABLE_BATCH_LIST,
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_SUMMARY
} from "../../query/bulkPayable/keys";
import { useModalNewPayableBatchCreated } from "../../components/ModalNewPayableBatchCreated";
import { useDownloadPayableExampleTemplate } from "../../components/DownloadPayableExampleTemplateButton";
import { BulkBatchStatusAlert } from "../../components/BulkBatchStatusAlert";
import { useBulkBatchActions } from "../../hooks/useBulkBatchActions";
import { useModalConfirmBulkBatchDelete } from "../../components/ModalConfirmBulkBatchDelete";
import { BulkBatchEventHistoryWidget } from "../../components/BulkBatchEventHistoryWidget";

const PayableBatchDetails: React.FC<{
  bulkBatch: IBulkPayableBatch;
}> = ({ bulkBatch }) => {
  const history = useHistory();
  const location = useLocation<{ showBatchCreatedModal?: boolean }>();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const [showOldBatchAlert, setShowOldBatchAlert] = useState(true);
  const exampleTemplateCSVLinkRef = useRef<any>(null);
  const errorsCSVLinkRef = useRef<any>(null);

  const queryBulkPayableBatch = useBulkPayableBatch(bulkBatchId);
  const queryBulkPayableBatchSummary = useBulkPayableBatchSummary(bulkBatchId);
  const isEmptyBatch = !!(
    queryBulkPayableBatchSummary.data &&
    queryBulkPayableBatchSummary.data.listSize === 0
  );

  const isEngagementsActive = useIsEngagementsActive();

  const goToPayableBatches = useGoToPayableBatches();
  const { csvProps: exampleTemplateCSVProps } =
    useDownloadPayableExampleTemplate();
  const { errorsCSVFileProps } = useDownloadErrorsCSV(
    bulkBatchId,
    BulkResource.Payable
  );
  const downloadExampleCSV = () => {
    exampleTemplateCSVLinkRef.current.link.click();
  };
  const downloadErrorsCSV = () => {
    errorsCSVLinkRef.current.link.click();
  };
  let actions = useBulkBatchActions({
    bulkBatchId,
    bulkResource: BulkResource.Payable,
    isEmptyBatch,
    downloadExampleCSV,
    downloadErrorsCSV
  });
  // hide the download spreadsheet button for failed/partially imported batches
  actions = actions.filter(action => action.name !== "downloadErrorsCSV");

  const newBatchCreatedModal = useModalNewPayableBatchCreated();
  const editBulkBatchNameModal = useModalEditBulkBatchName();
  const deleteBulkBatchModal = useModalConfirmBulkBatchDelete();

  const onCloseFlatfileModal = () => {
    WSQueryCache.invalidateQueries(BULK_PAYABLE_BATCH_LIST);
    WSQueryCache.invalidateQueries([QUERY_BULK_PAYABLE_BATCH, bulkBatchId]);
    WSQueryCache.invalidateQueries(QUERY_BULK_PAYABLE_BATCH_SUMMARY);
  };
  const { openPortal, closePortal } = useFlatfile({
    onClose: onCloseFlatfileModal
  });

  // Open the new batch created modal only once
  useEffect(() => {
    if (location.state?.showBatchCreatedModal) {
      const onDelete = () => {
        deleteBulkBatchModal.open({
          bulkBatchId,
          bulkResource: BulkResource.Payable,
          isEmptyBatch
        });
      };
      newBatchCreatedModal.open({
        bulkBatchId,
        onDelete,
        onAck: () => {
          history.replace(location.pathname);
          openPortal();
        }
      });
    }
  }, []);

  const spaceProps = useFlatfileSpaceConfig(bulkBatch, BulkResource.Payable);

  useListener((listener: FlatfileListener) => {
    listener.on("space:created", (event: FlatfileEvent) => {
      const { spaceId } = event.context;
      console.log("spaceId", spaceId);
    });

    listener.on(
      "job:outcome-acknowledged",
      { job: `workbook:${PAYABLES_ACTION_SLUG}` },
      async event => {
        const { spaceId } = event.context;
        console.log(
          `[job:outcome-acknowledged]:workbook:${PAYABLES_ACTION_SLUG} -- spaceId`,
          spaceId
        );

        const space = await api.spaces.get(spaceId);

        // close the flatfile portal if there are no rejections
        const { data: workbookSheets } = await api.sheets.list({
          workbookId: space.data.metadata?.wsInternalWorkbookId
        });
        const rejectionSheet = workbookSheets.find(s =>
          s.slug.includes("rejections")
        );
        if (rejectionSheet) {
          const rejectionRecords = rejectionSheet.recordCounts?.total;
          if (rejectionRecords === 0) {
            closePortal();
          }
        }
      }
    );
  });

  const isDesktop = useIsDesktop();

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryBulkPayableBatch,
          queryBulkPayableBatchSummary
        }}
      >
        {({
          queryBulkPayableBatchData: bulkBatch,
          queryBulkPayableBatchSummaryData: bulkBatchSummary
        }) => {
          const date = toWSDateString(bulkBatch.createdAt, "monthDayYear");
          return (
            <>
              {/* Render Flatfile space component */}
              <Space {...spaceProps} />
              {/* Render hidden example template csv link component */}
              <CSVLink
                ref={exampleTemplateCSVLinkRef}
                className={styles.hiddenButton}
                {...exampleTemplateCSVProps}
              />
              {/* Render hidden errors csv link component */}
              <CSVLink
                ref={errorsCSVLinkRef}
                className={styles.hiddenButton}
                {...errorsCSVFileProps}
              />
              <WSPageToolbar
                breadcrumbs={[
                  {
                    label: "Bulk imports (payables)",
                    onClick: () => {
                      goToPayableBatches();
                    }
                  },
                  {
                    label:
                      (bulkBatch.labels?.[
                        BATCH_LABELS_WS_KEYS.bulkBatchName
                      ] as string) || date
                  }
                ]}
                actions={actions}
              />

              {/* Batch name and edit action  */}
              <WSFlexBox.CenterY mb="S">
                <WSText.Heading3 weight="medium" mr="L">
                  {bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName] ||
                    date}
                </WSText.Heading3>
                <WSButton.Link
                  onClick={() => {
                    editBulkBatchNameModal.open({
                      bulkBatchId: bulkBatch.bulkPayableBatchId,
                      bulkResource: BulkResource.Payable
                    });
                  }}
                >
                  Edit name
                </WSButton.Link>
              </WSFlexBox.CenterY>

              {/* Show original spreadsheet filename if file is uploaded */}
              {isFileUploaded(bulkBatch) && (
                <WSFlexBox.CenterY>
                  <WSIcon name="file" mr="XS" size="XS" />
                  <WSText.ParagraphXs weight="book" color="gray600">
                    {getUploadedFilename(bulkBatch, BulkResource.Payable)}
                  </WSText.ParagraphXs>
                </WSFlexBox.CenterY>
              )}

              <WSGrid gutter="XL" my="2XL">
                <WSGrid.Item span={{ s: "12", m: "8" }}>
                  <WSList>
                    {!isDesktop && (
                      <BulkBatchImportSteps
                        bulkBatchId={bulkBatchId}
                        bulkResource={BulkResource.Payable}
                      />
                    )}
                    {/* Batch notes if any */}
                    {bulkBatch.labels?.[
                      BATCH_LABELS_WS_KEYS.bulkBatchNotes
                    ] && (
                      <WSPanel>
                        <WSDataItem
                          label={{
                            text: "Notes",
                            action: {
                              label: "Edit",
                              onClick: () => {
                                editBulkBatchNameModal.open({
                                  bulkBatchId: bulkBatch.bulkPayableBatchId,
                                  bulkResource: BulkResource.Payable
                                });
                              }
                            }
                          }}
                          value={
                            bulkBatch.labels?.[
                              BATCH_LABELS_WS_KEYS.bulkBatchNotes
                            ]
                          }
                        />
                      </WSPanel>
                    )}
                    {/* Show old bulk batch import alert */}
                    {showOldBatchAlert &&
                      bulkBatch.status === BulkStatus.Open &&
                      isOldBulkBatch(bulkBatch) && (
                        <WSAlert
                          theme="info"
                          icon="alert-circle"
                          title={`This upload is over ${getBulkBatchAge(
                            bulkBatch
                          )} old`}
                          onDismiss={() => setShowOldBatchAlert(false)}
                        >
                          This batch contains an upload over{" "}
                          {getBulkBatchAge(bulkBatch)} old that was not yet
                          imported and may contain old validation data from the
                          time it was uploaded. To ensure up-to-date validation
                          for your formatted spreadsheet, delete this batch and
                          start a new one to upload and import your spreadsheet.
                          We recommend completing the import as soon as your
                          spreadsheet is finished uploading so it doesn’t sit
                          for an extended period of time.
                        </WSAlert>
                      )}

                    {/* Show Bulk batch status alert */}
                    <BulkBatchStatusAlert
                      bulkBatchId={bulkBatch.bulkPayableBatchId}
                      bulkBatch={bulkBatch}
                      bulkResource={BulkResource.Payable}
                    />

                    <PayableBatchStatusWidget
                      bulkBatch={bulkBatch}
                      bulkBatchSummary={bulkBatchSummary}
                      downloadExampleCSV={downloadExampleCSV}
                      downloadErrorsCSV={downloadErrorsCSV}
                    />

                    {/* Show details widget */}
                    <PayableBatchDetailsWidget
                      bulkBatch={bulkBatch}
                      bulkBatchSummary={bulkBatchSummary}
                    />

                    {!isDesktop && (
                      <BulkBatchEventHistoryWidget
                        bulkBatch={bulkBatch}
                        bulkResource={BulkResource.Payable}
                      />
                    )}
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "12", m: "4" }}>
                  {isDesktop && (
                    <WSList gap="L">
                      <BulkBatchImportSteps
                        bulkBatchId={bulkBatchId}
                        bulkResource={BulkResource.Payable}
                      />
                      <BulkBatchEventHistoryWidget
                        bulkBatch={bulkBatch}
                        bulkResource={BulkResource.Payable}
                      />
                    </WSList>
                  )}
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};

export const RouteImportPayableBatchDetails: React.FC = () => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const queryBulkPayableBatch = useBulkPayableBatch(bulkBatchId);

  return (
    <WSQueries queries={{ queryBulkPayableBatch }}>
      {({ queryBulkPayableBatchData: bulkBatch }) => {
        const ffProviderProps = (bulkBatch.labels[
          BATCH_LABELS_WS_KEYS.flatfileAccessToken
        ] as string)
          ? {
              accessToken: bulkBatch.labels[
                BATCH_LABELS_WS_KEYS.flatfileAccessToken
              ] as string
            }
          : {
              publishableKey: process.env
                .REACT_APP_FLATFILE_PUBLISHABLE_KEY as string
            };

        return (
          <FlatfileProvider
            // Do not set key prop if upload is in progress
            {...(isUploadInProgress(bulkBatch)
              ? {}
              : {
                  key:
                    ffProviderProps.accessToken ??
                    ffProviderProps.publishableKey
                })}
            config={{
              debug: !IS_PRODUCTION_ENV,
              ...flatfileProviderConfig
            }}
            {...ffProviderProps}
          >
            <PayableBatchDetails bulkBatch={bulkBatch} />
          </FlatfileProvider>
        );
      }}
    </WSQueries>
  );
};
