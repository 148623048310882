import {
  WSAvatar,
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../../../../query/hooks/helpers";
import { usePayeeTaxForms } from "../../../../../../query/taxForm/queries/usePayeeTaxForms";
import { useUpdateActivity } from "../../../../../../query/users/mutations";
import {
  useActivities,
  useMemberProfile
} from "../../../../../../query/users/queries";
import { WSQueries } from "../../../../../../query/WSQuery";
import { AddressText } from "../../../../../../shared/components/AddressText/AddressText";
import { selectorTaxClassificationLabel } from "../../../../../../shared/selectors/selectorTaxClassificationLabel";
import { formatEIN } from "../../../../../../shared/utils/formatEIN";
import { openInNewTab } from "../../../../../../shared/utils/openInNewTab";
import { Field } from "../../../../components/Field/Field";
import { Main1099LayoutModule } from "../../../../components/Main1099Layout/Main1099Layout.module";
import { CURRENT_YEAR } from "../../../../constants/currentYear";
import { selectorMemberTaxFormName } from "../../../../selectors/selectorMemberTaxFormName";
import { selectorTaxFormIsInPostFilingState } from "../../../../selectors/selectorTaxFormIsInPostFilingState";
import styles from "./index.module.scss";

export const RouteConfirmation: React.FC<{}> = () => {
  const history = useHistory();

  const userId = useUserId();

  const queryMember = useMemberProfile(userId);
  const queryActivity = useActivities(userId);
  const queryTaxForms = usePayeeTaxForms({ year: CURRENT_YEAR });

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  useEffect(() => {
    window.localStorage.removeItem("signUp1099");
  }, []);

  return (
    <Main1099LayoutModule
      title="You have confirmed your tax preferences"
      rightPanel={
        <WSElement className={styles.faq}>
          <WSText weight="medium" mb="2XL">
            Frequently asked questions
          </WSText>

          <WSElement mb="2XL">
            <WSText.ParagraphSm weight="medium" color="gray600" mb="M">
              When will I get my 1099 form?
            </WSText.ParagraphSm>
            <WSText.ParagraphSm mb="M" color="gray600">
              If eligible, you will receive your 1099 by{" "}
              <WSText.ParagraphSm weight="medium" inline>
                February 1st, 2024
              </WSText.ParagraphSm>
              .
            </WSText.ParagraphSm>

            <WSText.ParagraphSm color="gray600">
              You may receive multiple 1099s via Wingspan if you've been paid by
              multiple clients.
            </WSText.ParagraphSm>
          </WSElement>

          <WSElement mb="2XL">
            <WSText.ParagraphSm weight="medium" color="gray600" mb="M">
              What is a 1099 form?
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray600">
              1099 forms are used to report money you've made that isn't
              reported on a Form W-2. This could be from freelance work,
              side-gigs or contract work. These forms are sent to the IRS and
              sometimes to your state's tax department.
            </WSText.ParagraphSm>
          </WSElement>

          <WSElement mb="2XL">
            <WSText.ParagraphSm weight="medium" color="gray600" mb="M">
              Who will receive a 1099 form?
            </WSText.ParagraphSm>
            <WSText.ParagraphSm as="ul" className={styles.listStyle}>
              <WSElement as="li">
                <WSText.ParagraphSm color="gray600" mb="M">
                  If you've received at least $600 for services performed in a
                  trade or business by a non-employer, you typically fall into
                  the category of those who should receive this form. This is
                  particularly relevant for independent contractors,
                  freelancers, and self-employed individuals.
                </WSText.ParagraphSm>
              </WSElement>
              <WSElement as="li">
                <WSText.ParagraphSm color="gray600" mb="M">
                  Exceptions: There are exceptions. For instance, payments made
                  to C-Corporations and S-Corporations generally do not require
                  a 1099-NEC.
                </WSText.ParagraphSm>
              </WSElement>
              <WSElement as="li">
                <WSText.ParagraphSm color="gray600">
                  Payer's Discretion: Ultimately, the decision to issue a
                  1099-NEC lies with the payer. They will determine whether they
                  need to file this form based on IRS rules and the specifics of
                  their business relationship and payments with you.
                </WSText.ParagraphSm>
              </WSElement>
            </WSText.ParagraphSm>
          </WSElement>
          <WSElement mb="2XL">
            <WSText.ParagraphSm weight="medium" color="gray600" mb="XS">
              Need to make corrections?
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray600">
              Once you’ve received your 1099, any corrections to your 1099 will
              happen in Wingspan.{" "}
              <WSButton.Link
                size="S"
                onClick={() => {
                  history.push(
                    "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_748f87fef0"
                  );
                }}
              >
                Learn how
              </WSButton.Link>
            </WSText.ParagraphSm>
          </WSElement>
        </WSElement>
      }
    >
      <WSQueries queries={{ queryMember, queryActivity, queryTaxForms }}>
        {({ queryMemberData, queryActivityData, queryTaxFormsData }) => {
          const isBusiness =
            queryMemberData.profile.formW9Options?.tinPreference ===
            TinType.Business;
          const hasSubmittedForms = queryTaxFormsData.some(f =>
            selectorTaxFormIsInPostFilingState(f)
          );

          return (
            <>
              <WSElement mb="2XL">
                <WSFlexBox.CenterX mb="M">
                  <WSAvatar.Icon
                    icon="check"
                    color="white"
                    colorBackground="green500"
                  />
                </WSFlexBox.CenterX>
                <WSText.Heading4 align="center">
                  You have confirmed your tax preferences
                </WSText.Heading4>
              </WSElement>

              {/* had feb 1st deadline in copy "If you notice any errors that should be corrected, starting February 1st, click “View details”"  */}
              {hasSubmittedForms ? (
                <WSMessageBox.Warning
                  mb="XL"
                  title="Important, please read:"
                  noBorder
                >
                  <WSText.ParagraphSm color="gray500" mt="M">
                    The payer may have already submitted your 1099-NEC for
                    filing with the IRS before you confirmed your tax
                    preferences. If this is the case, please review your
                    1099-NEC once it is delivered to you.
                    <br />
                    <br />
                    If you notice any errors that should be corrected, click
                    “View details” from your tax documents dashboard and the
                    “Request correction” button. This will send a correction
                    request to the payer.
                  </WSText.ParagraphSm>
                </WSMessageBox.Warning>
              ) : null}

              <WSElement mb="XL">
                <WSText color="gray400" mb="M">
                  Please add our notifications email to your email contacts so
                  your 1099 doesn't get sent to spam:{" "}
                  <WSButton.Link
                    onClick={() => {
                      openInNewTab("mailto:no-reply@t.wingspan.app");
                    }}
                  >
                    no-reply@t.wingspan.app
                  </WSButton.Link>
                </WSText>
                <WSText color="gray400">Here’s what we have:</WSText>
              </WSElement>

              <WSPanel mb="2XL" pb="NONE">
                <Field
                  label="Name"
                  value={selectorMemberTaxFormName(queryMemberData)}
                />
                <Field label="Email" value={queryMemberData.user.email} />
                <Field
                  label="Federal Tax Classification"
                  value={selectorTaxClassificationLabel(
                    queryMemberData.profile.company?.structure
                  )}
                />
                <Field
                  label="Taxpayer Identification Number"
                  value={
                    isBusiness
                      ? `${
                          queryMemberData.profile.company?.taxId
                            ? formatEIN(queryMemberData.profile.company?.taxId)
                            : ""
                        } (Employer Identification Number)`
                      : "Social Security Number"
                  }
                />
                <Field
                  label="Address"
                  value={
                    <WSText.ParagraphSm color="gray400" mb="M" as="pre">
                      <AddressText {...queryMemberData.profile?.address} />
                    </WSText.ParagraphSm>
                  }
                />

                <Field
                  label="Delivery preferences"
                  value={
                    queryActivityData.events.electronic1099Consent
                      ? "Electronic delivery"
                      : "Mail"
                  }
                />
              </WSPanel>

              <WSText.ParagraphSm color="gray400" mb="M">
                You’re all set for now! Feel free to explore what Wingspan has
                to offer:
              </WSText.ParagraphSm>

              <WSButtons forceFullWidth mb="XL">
                <WSButton
                  onClick={async () => {
                    await updateActivity({
                      context: {
                        type: undefined
                      }
                    });
                    history.push("/member/tax-documents");
                  }}
                >
                  View tax documents
                </WSButton>
              </WSButtons>

              <WSElement mb="XL">
                <WSText color="gray400">
                  Questions? View our{" "}
                  <WSButton.Link
                    onClick={() => {
                      openInNewTab(
                        "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                      );
                    }}
                  >
                    1099 FAQs
                  </WSButton.Link>
                </WSText>
              </WSElement>

              <WSElement>
                <WSText.ParagraphSm color="gray400">
                  Need help?{" "}
                  <WSButton.Link
                    onClick={() =>
                      openInNewTab("https://www.wingspan.app/support")
                    }
                  >
                    Chat with a live representative
                  </WSButton.Link>
                </WSText.ParagraphSm>
              </WSElement>
            </>
          );
        }}
      </WSQueries>
    </Main1099LayoutModule>
  );
};
