import {
  WSAvatar,
  WSButton,
  WSElement,
  WSFlexBox,
  WSImage,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import imageTransactions from "../../../assets/images/microDepositTransactions.png";
import { FormManuallyAddAccount } from "../../../shared/components/FormManuallyAddAccount";
import { LayoutFullscreen } from "../../../shared/components/LayoutFullscreen";
import { openIntercom } from "../../../shared/utils/intercom";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";

export const RouteAddBankAccountManually: React.FC<
  RouteComponentProps
> = () => {
  const history = useHistory();
  const [account, setAccount] = React.useState<IAccount | undefined>();
  const location = useLocation<{ backPath?: string }>();

  const onBack = () => {
    history.push(location.state?.backPath || "/payment/history");
  };

  return account ? (
    <LayoutFullscreen
      headTitle="Add payment method"
      onBack={onBack}
      narrow
      whiteBackground
    >
      <WSElement mb="XL">
        <WSFlexBox.CenterX mb="M">
          <WSAvatar.Icon
            icon="check"
            color="white"
            colorBackground="green500"
          />
        </WSFlexBox.CenterX>
        <WSText.Heading4 align="center">
          Micro-deposits initiated
        </WSText.Heading4>
      </WSElement>

      <WSText color="gray500" mb="XL">
        Expect two <b>micro-deposits</b> to the account ending in •••
        {account.numbers.account.slice(-4)} in the next business day and an
        email with additional instructions to verify your bank account through
        your Wingspan payment portal.
      </WSText>

      <WSMessageBox.Info mb="XL">
        <WSFlexBox.CenterY mb="XL">
          <WSAvatar.Icon
            mr="M"
            icon="bank"
            color="white"
            colorBackground="gray500"
          />

          <WSText>•••{account.numbers.account.slice(-4)} Bank Statement</WSText>
        </WSFlexBox.CenterY>

        <WSImage mb="XL" src={imageTransactions} placeholder="" />

        <WSText color="gray500">
          An example of the micro-deposits in your account. Make note of the
          amounts in order to verify your account.
        </WSText>
      </WSMessageBox.Info>

      <WSText color="gray500" mb="XL">
        Payments to invoices made from this account will be in pending status
        until the verification process is complete. Once the account is
        verified, invoices paid with this account will be processed.
      </WSText>

      <WSButton fullWidth onClick={onBack} mb="XL">
        Continue
      </WSButton>

      <WSElement mb="XL">
        <WSText weight="medium">Have questions?</WSText>
        <WSButton.Link
          onClick={() => {
            openInNewTab(WS_LINKS.faq);
          }}
        >
          Learn more about micro-deposits
        </WSButton.Link>
      </WSElement>

      <WSElement>
        <WSText weight="medium">Need additional support?</WSText>
        <WSButton.Link onClick={openIntercom}>
          Chat with a live representative
        </WSButton.Link>
      </WSElement>
    </LayoutFullscreen>
  ) : (
    <LayoutFullscreen
      headTitle="Add payment method"
      title="Enter your account info"
      onBack={onBack}
      narrow
    >
      <FormManuallyAddAccount
        requestVerification
        setDefaultPaymentMethod
        onSuccess={account => {
          setAccount(account);
        }}
      />
    </LayoutFullscreen>
  );
};
