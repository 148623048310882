import {
  ClientWorkFlowStatus,
  FrequencyAndScheduleStatus,
  IClientInvoice,
  IClientInvoiceCreateRequest,
  IClientInvoiceTemplate,
  IClientInvoiceTemplateUpdateRequest,
  IClientInvoiceUpdateRequest,
  ICollaboratorSchema,
  IDeductionResponse,
  IInvoice,
  IInvoiceCollaboratorCreateRequest,
  IInvoiceTemplate,
  InvoiceStatus,
  IPayableSchema,
  IPayRequest,
  IPayrollSettings,
  IPayrollSettingsUpdate,
  IScheduleDateUpdate,
  MemberWorkFlowStatus,
  PayrollWorkflowStrategy,
  ScheduleStatus,
  WorkFlowSubStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  CardStatusUpdate,
  ICardCodeRequest,
  ICardCreateRequest,
  ICardTokenRequest,
  ICardUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/card";
import { Currency } from "@wingspanhq/utils/dist/currency/currencies";
import {
  WSMutationConfig,
  WSMutationsConfig,
  WSQueryCache
} from "@ws-react-query";
import { isEqual } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { InvoicesFormValues } from "../../Invoices/components/InvoicesForm/InvoicesForm";
import {
  PayableFormValues,
  SendPayableInvoiceAction
} from "../../Invoices/components/PayableForm/PayableForm";
import { useDownloadPdf } from "../../Invoices/utils";
import {
  attachmentsFormDataToCreateRequest,
  attachmentsFormDataToUpdateRequest,
  convertToLineItemsCreateRequest,
  convertToLineItemsUpdateRequest,
  convertToLineItemsUpdateRequest2,
  creditFeeFormDataToRequest,
  generateDefaultDueDateSetByPayer,
  generateDueDate,
  generateFrequencyConfig,
  generateLateFeeConfig,
  generateSendDate,
  paymentMethodsFormDataToCreateRequest,
  paymentMethodsFormDataToUpdateRequest,
  useInvoiceFormGoBack
} from "../../Invoices/utils/invoicesFormUtils";
import { paymentsService } from "../../services/payments";
import { convertDateToMidday, isToday } from "../../utils/dates";
import { getChangedData } from "../../utils/getChangedData";
import { openInNewTab } from "../../utils/openInNewTab";
import { QUERY_BOOKKEEPING_BANKING_ACCOUNT } from "../bookkeeping/keys";
import { useWSMutation } from "../helpers";
import { useUserId } from "../hooks/helpers";
import {
  QUERY_ALL_COLLABORATOR_GROUPS,
  QUERY_CARD,
  QUERY_CARD_LIST,
  QUERY_CLIENT_INVOICE,
  QUERY_CLIENT_INVOICE_TEMPLATE,
  QUERY_COLLABORATOR_DEDUCTIONS,
  QUERY_COLLABORATOR_GROUP,
  QUERY_COLLABORATOR_GROUPS,
  QUERY_INVOICE,
  QUERY_INVOICE_TEMPLATE,
  QUERY_INVOICE_TEMPLATES,
  QUERY_INVOICES,
  QUERY_PAYABLE,
  QUERY_PAYABLES,
  QUERY_PAYROLL_SETTINGS
} from "./keys";

import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import {
  ICollaboratorGroupCreateRequest,
  ICollaboratorGroupUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/collaboratorGroup";
import { IDeductionCreateRequest } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { transformErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { QUERY_KEY_INVOICES_ROWS } from "../../modules/Invoicing/queries/useQueryInvoicesRows";
import { QUERY_KEY_INVOICES_ROWS_SUMMARY } from "../../modules/Invoicing/queries/useQueryInvoicesRowsSummary";
import { IInvoiceRow } from "../../modules/Invoicing/service";
import { getCurrencyRate } from "../../services/api/payments/invoices";
import {
  ICurrencyRate,
  ICurrencyRateRequest
} from "../../services/api/payments/invoices/types";
import { getPayer, updatePayer } from "../../services/payers";
import { ErrorContextKey } from "../../services/platform";
import { WSServiceError } from "../../utils/serviceHelper";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL
} from "../payeeEngagements/keys";
import { usePaymentsStatusQuery } from "./queries";

const findCreatedInvoice = async (
  invoiceTemplateId: string
): Promise<IInvoice | undefined> => {
  try {
    return (await paymentsService.invoice.list()).find(
      invoice => invoice.invoiceTemplateId === invoiceTemplateId
    );
  } catch (error) {
    console.error("Failed to fetch invoices: ", error);
  }
};

export const useSendInvoiceTemplate = () => {
  const history = useHistory();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoiceTemplateId: string;
    }
  >(
    async ({ invoiceTemplateId }) => {
      await paymentsService.invoiceTemplate.update(invoiceTemplateId, {
        status: FrequencyAndScheduleStatus.Active
      });

      const createdInvoice = await findCreatedInvoice(invoiceTemplateId);

      if (createdInvoice) {
        history.push(
          `/member/invoices/${createdInvoice.invoiceId}/send-success`
        );
      } else {
        history.push(
          `/member/invoices/template/${invoiceTemplateId}/send-success`
        );
      }
    },
    {
      throwOnError: true,
      dependencies: [QUERY_INVOICE_TEMPLATES]
    }
  );
};

export const useSendNowInvoiceTemplate = () => {
  const history = useHistory();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoiceTemplateId: string;
    }
  >(
    async ({ invoiceTemplateId }) => {
      await paymentsService.invoiceTemplate.update(invoiceTemplateId, {
        scheduleDates: [{ date: new Date(), status: ScheduleStatus.Pending }],
        status: FrequencyAndScheduleStatus.Active
      });

      const createdInvoice = await findCreatedInvoice(invoiceTemplateId);

      if (createdInvoice) {
        history.push(
          `/member/invoices/${createdInvoice.invoiceId}/send-success`
        );
      } else {
        history.push(
          `/member/invoices/template/${invoiceTemplateId}/send-success`
        );
      }
    },
    {
      throwOnError: true,
      dependencies: [QUERY_INVOICE_TEMPLATES]
    }
  );
};

export const useSendInvoiceReminder = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoice: IInvoice | IInvoiceRow;
    }
  >(
    async ({ invoice }) => {
      await paymentsService.invoice.sendReminder(invoice.invoiceId);
    },
    {
      onSuccess: () => {
        openSnackbar({
          type: "success",
          message: "Reminder sent"
        });
      }
    }
  );
};

export const useAcceptInvoice = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(
    async ({ invoiceId }) => {
      await paymentsService.invoice.update(
        invoiceId,
        {
          member: {
            workflowStatus: MemberWorkFlowStatus.Accepted
          }
        },
        {
          waitForRowUpdate: true
        }
      );
    },
    {
      onSuccess: (_, { invoiceId }) => {
        openSnackbar({
          type: "success",
          message: "Invoice accepted"
        });

        WSQueryCache.invalidateQueries(QUERY_INVOICES);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        WSQueryCache.invalidateQueries([QUERY_INVOICE, invoiceId]);
      }
    }
  );
};

export const useDisputeInvoice = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoiceId: string;
      disputeComment?: string;
    }
  >(
    async ({ invoiceId, disputeComment }) => {
      await paymentsService.invoice.update(
        invoiceId,
        {
          member: {
            workflowStatus: MemberWorkFlowStatus.Disputed,
            comment: disputeComment
          }
        },
        {
          waitForRowUpdate: true
        }
      );
    },
    {
      onSuccess: (_, { invoiceId }) => {
        openSnackbar({
          type: "success",
          message: "Invoice disputed"
        });

        WSQueryCache.invalidateQueries(QUERY_INVOICES);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS_SUMMARY);
        WSQueryCache.invalidateQueries([QUERY_INVOICE, invoiceId]);
      }
    }
  );
};

export const useMarkInvoiceAsPaid = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    IInvoice,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(
    async ({ invoiceId }) => {
      return await paymentsService.invoice.update(
        invoiceId,
        {
          status: InvoiceStatus.Paid
        },
        {
          waitForRowUpdate: true
        }
      );
    },
    {
      dependencies: [
        QUERY_INVOICES,
        QUERY_KEY_INVOICES_ROWS,
        QUERY_KEY_INVOICES_ROWS_SUMMARY
      ],
      onSuccess: () => {
        openSnackbar({
          type: "success",
          message: "Invoice marked as deposited"
        });
      }
    }
  );
};

export const useRetryInvoicePayment = () => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    any,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(
    async ({ invoiceId }) => {
      await paymentsService.invoice.pay(invoiceId);
    },
    {
      onSuccess: (_, { invoiceId }) => {
        WSQueryCache.invalidateQueries(QUERY_INVOICES);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS_SUMMARY);
        WSQueryCache.invalidateQueries([QUERY_INVOICE, invoiceId]);
      },
      onError: () => {
        openSnackbar({ type: "warning", message: "Failed to retry payment" });
      }
    }
  );
};

export const useCancelInvoice = () => {
  return useWSMutation<
    IInvoice,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(
    async ({ invoiceId }) => {
      return await paymentsService.invoice.update(
        invoiceId,
        {
          status: InvoiceStatus.Cancelled
        },
        {
          waitForRowUpdate: true
        }
      );
    },
    {
      throwOnError: true,
      dependencies: [
        QUERY_INVOICES,
        QUERY_KEY_INVOICES_ROWS,
        QUERY_KEY_INVOICES_ROWS_SUMMARY
      ]
    }
  );
};

export const useCancelInvoiceTemplate = () => {
  return useWSMutation<
    IInvoiceTemplate,
    WSServiceError,
    {
      invoiceTemplateId: string;
    }
  >(
    async ({ invoiceTemplateId }) => {
      return await paymentsService.invoiceTemplate.update(invoiceTemplateId, {
        status: FrequencyAndScheduleStatus.Expired,
        frequency: {
          endDate: new Date()
        }
      });
    },
    {
      throwOnError: true,
      dependencies: [QUERY_INVOICE_TEMPLATES]
    }
  );
};

export const useDeleteInvoice = () => {
  return useWSMutation<
    IInvoice,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(
    async ({ invoiceId }) => {
      return await paymentsService.invoice.delete(invoiceId);
    },
    {
      throwOnError: true,
      dependencies: [
        QUERY_INVOICES,
        QUERY_KEY_INVOICES_ROWS,
        QUERY_KEY_INVOICES_ROWS_SUMMARY
      ]
    }
  );
};

export const useDeleteInvoiceTemplate = () => {
  return useWSMutation<
    IInvoiceTemplate,
    WSServiceError,
    {
      invoiceTemplateId: string;
    }
  >(
    async ({ invoiceTemplateId }) => {
      return await paymentsService.invoiceTemplate.delete(invoiceTemplateId);
    },
    {
      throwOnError: true,
      dependencies: [QUERY_INVOICE_TEMPLATES]
    }
  );
};

export const useSaveInvoice = () => {
  const userId = useUserId();
  const goBack = useInvoiceFormGoBack();
  const paymentsStatusQuery = usePaymentsStatusQuery();
  const history = useHistory();
  const location = useLocation();

  return useWSMutation<
    {
      savedInvoice?: IInvoice;
      savedInvoiceTemplate?: IInvoiceTemplate;
    },
    WSServiceError,
    {
      invoiceId?: string;
      invoiceTemplateId?: string;
      data: InvoicesFormValues & {
        dueInDays?: number;
      };
      send?: boolean;
      resubmit?: boolean;
    }
  >(
    async ({ invoiceId, invoiceTemplateId, data, send, resubmit }) => {
      let invoice: IInvoice | undefined;
      let invoiceTemplate: IInvoiceTemplate | undefined;
      let savedInvoice: IInvoice | undefined;
      let savedInvoiceTemplate: IInvoiceTemplate | undefined;

      if (invoiceId) {
        invoice = await paymentsService.invoice.get(invoiceId);
      }

      if (invoiceTemplateId) {
        invoiceTemplate = await paymentsService.invoiceTemplate.get(
          invoiceTemplateId
        );
      }

      // Client
      let memberClientId: string | undefined;

      const payerId = data.client?.payerId as string;
      try {
        const payerPayeeEngagementId = data.client
          ?.payerPayeeEngagementId as string;
        memberClientId = payerPayeeEngagementId;

        const payer = await getPayer(payerId);

        const additionalEmails = (data.client?.emailsCC || [])
          .map(i => i.email)
          .filter(e => !!e);
        if (
          !isEqual(additionalEmails, payer.payeeOwnedData.payerEmailCC || [])
        ) {
          // Update payer if email CC was updated
          await updatePayer(payerId, {
            payeeOwnedData: {
              payerEmailCC: additionalEmails
            }
          });
        }
      } catch (error) {
        console.error("Failed to update client email CCs", error);
      }

      if (!memberClientId) {
        throw new Error("No memberClientId set");
      }

      let collaboratorsRequest: (IInvoiceCollaboratorCreateRequest | null)[];

      collaboratorsRequest = data.invoiceCollaborators.map(
        invoiceCollaborator => {
          if (
            invoiceCollaborator.remove ||
            !invoiceCollaborator.payee?.payerPayeeEngagementId ||
            !invoiceCollaborator.amount
          )
            return null;

          return {
            memberClientId: invoiceCollaborator.payee.payerPayeeEngagementId,
            amount: invoiceCollaborator.amount,
            currency:
              paymentsStatusQuery.data?.defaultAccountCurrency || Currency.USD,
            description: invoiceCollaborator.description
          };
        }
      );

      const collaboratorsCreateRequestData = collaboratorsRequest.filter(
        collaborator => !!collaborator
      ) as IInvoiceCollaboratorCreateRequest[];

      const sendDate = generateSendDate({
        send: data.send
      });

      let dueDate: Date;
      if (data.dueInDays !== undefined) {
        // if dueInDays is set by payer then generate due date based on it
        dueDate = generateDefaultDueDateSetByPayer({
          dueInDays: data.dueInDays,
          sendDate
        });
      } else {
        // Generate due date based on form values and send date
        dueDate = generateDueDate({
          formValues: {
            due: data.due,
            customDueDate: data.customDueDate
          },
          sendDate
        });
      }

      // Generate object with common invoice data which can be used for both invoice and invoiceTemplate
      const commonData = {
        memberId: userId,
        currency:
          paymentsStatusQuery.data?.defaultAccountCurrency || Currency.USD,
        memberClientId,
        clientId: payerId,
        invoiceNotes: data.other.notes,
        creditFeeHandling: creditFeeFormDataToRequest(
          data.advanced.creditFeeHandling
        ),
        notificationPreferences: {
          sendInvoice: data.advanced.sendEmails,
          sendReminders:
            data.advanced.sendEmails && !!data.advanced.sendReminders
        },
        dueDate,
        lateFeeHandling: data.includesLateFee
          ? generateLateFeeConfig({
              formValues: data.lateFee,
              dueDate
            })
          : undefined,
        attachments: {
          customAttachmentIds: attachmentsFormDataToCreateRequest(
            data.other.attachments
          )
        },
        acceptedPaymentMethods: paymentMethodsFormDataToCreateRequest(
          data.paymentMethods
        ),
        labels: {
          projectName: data.other.projectName || (undefined as any as string),
          subject: data.subject
        },
        metadata: {
          invoiceDate: convertDateToMidday(data.advanced.date),
          purchaseOrderNumber: data.purchaseOrderNumber || undefined
        }
      };

      if (
        !data.recurring &&
        (data.send.type === "immediately" || isToday(sendDate))
      ) {
        // If invoice is not repeats and have to be sent immediately – we can work just with invoice entity

        if (invoiceId) {
          savedInvoice = await paymentsService.invoice.update(
            invoiceId,
            {
              ...getChangedData(
                invoice,
                {
                  ...commonData,
                  collaborators: collaboratorsRequest,
                  lineItems: convertToLineItemsUpdateRequest(data.lineItems),
                  lateFeeHandling:
                    invoice?.lateFeeHandling && !commonData.lateFeeHandling
                      ? {
                          lateFeePercentage: null,
                          lateFeeAmount: null,
                          frequency: null
                        }
                      : commonData.lateFeeHandling,
                  acceptedPaymentMethods: paymentMethodsFormDataToUpdateRequest(
                    data.paymentMethods
                  ),
                  attachments: {
                    customAttachmentIds: attachmentsFormDataToUpdateRequest(
                      data.other.attachments,
                      invoice?.attachments?.customAttachmentIds || []
                    )
                  },
                  member: resubmit
                    ? {
                        workflowSubStatus: WorkFlowSubStatus.Submitted
                      }
                    : undefined
                },
                { dismissTimeInDates: true }
              )
            },
            {
              waitForRowUpdate: true
            }
          );
        } else if (invoiceTemplateId) {
          // This is the case when member have one-time scheduled for future invoice but wants to send it now, so they updates send date to today in Edit form. In this case we're just updating existing invoiceTemplate and it will create new Open invoice.

          savedInvoiceTemplate = await paymentsService.invoiceTemplate.update(
            invoiceTemplateId,
            getChangedData(
              invoiceTemplate,
              {
                invoiceData: {
                  ...commonData,
                  collaborators: collaboratorsRequest,
                  lineItems: convertToLineItemsUpdateRequest(data.lineItems),
                  acceptedPaymentMethods: paymentMethodsFormDataToUpdateRequest(
                    data.paymentMethods
                  ),
                  attachments: {
                    customAttachmentIds: attachmentsFormDataToUpdateRequest(
                      data.other.attachments,
                      invoiceTemplate?.invoiceData.attachments
                        ?.customAttachmentIds || []
                    )
                  }
                },
                scheduleDates: [
                  {
                    date: sendDate
                  }
                ],
                labels: commonData.labels
              },
              { dismissTimeInDates: true }
            )
          );
        } else {
          // Create new invoice
          savedInvoice = await paymentsService.invoice.create({
            ...commonData,
            lineItems: convertToLineItemsCreateRequest(data.lineItems),
            collaborators: collaboratorsCreateRequestData,
            status: InvoiceStatus.Draft
          });
        }
      } else {
        // If invoice repeats OR sending should be delayed – we are working with invoiceTemplate entity

        // Generate frequency config if invoice have to be repeated
        const frequency = data.recurring
          ? generateFrequencyConfig({
              formValues: data.frequency,
              sendDate
            })
          : undefined;

        if (invoiceTemplateId) {
          let scheduleDates: (IScheduleDateUpdate | null)[] | undefined;

          if (invoiceTemplate?.isSchedulingOnly) {
            // New scheduling logic

            if (frequency) {
              // If invoice is repeatable

              if (frequency.endDate) {
                // If repeatable invoice has end date – we need to cut off everything after end date

                scheduleDates = invoiceTemplate?.scheduleDates?.map(
                  scheduleDate =>
                    frequency.endDate && scheduleDate.date > frequency.endDate
                      ? null
                      : {}
                );
              }

              // If repeatable invoice is endless don't do anything
            } else {
              // If it's one time scheduled invoice, just update send date

              scheduleDates = [
                {
                  date: sendDate
                }
              ];
            }
          } else {
            // Old scheduling logic

            scheduleDates = [
              {
                date: sendDate
              }
            ];
          }

          // If invoiceTemplate is being edited – update invoiceTemplate
          savedInvoiceTemplate = await paymentsService.invoiceTemplate.update(
            invoiceTemplateId,
            getChangedData(
              invoiceTemplate,
              {
                invoiceData: {
                  ...commonData,
                  collaborators: collaboratorsRequest,
                  lineItems: convertToLineItemsUpdateRequest2(
                    invoiceTemplate?.invoiceData.lineItems || [],
                    data.lineItems
                  ),
                  lateFeeHandling:
                    invoiceTemplate?.invoiceData.lateFeeHandling &&
                    !commonData.lateFeeHandling
                      ? {
                          lateFeePercentage: null,
                          lateFeeAmount: null,
                          frequency: null
                        }
                      : commonData.lateFeeHandling,
                  acceptedPaymentMethods: paymentMethodsFormDataToUpdateRequest(
                    data.paymentMethods
                  ),
                  attachments: {
                    customAttachmentIds: attachmentsFormDataToUpdateRequest(
                      data.other.attachments,
                      invoiceTemplate?.invoiceData.attachments
                        ?.customAttachmentIds || []
                    )
                  }
                },
                frequency: frequency
                  ? frequency
                  : invoiceTemplate?.frequency
                  ? null
                  : undefined,
                scheduleDates,
                labels: commonData.labels
              },
              { dismissTimeInDates: true }
            )
          );
        } else {
          if (invoiceId) {
            if (invoice?.status === InvoiceStatus.Draft) {
              await paymentsService.invoice.delete(invoiceId);
            }
          }

          // Create new invoiceTemplate
          savedInvoiceTemplate = await paymentsService.invoiceTemplate.create({
            invoiceData: {
              ...commonData,
              collaborators: collaboratorsCreateRequestData,
              lineItems: convertToLineItemsCreateRequest(data.lineItems)
            },
            frequency,
            scheduleDates: [{ date: sendDate, status: ScheduleStatus.Pending }],
            status: FrequencyAndScheduleStatus.Draft,
            labels: commonData.labels,
            isSchedulingOnly: true
          });
        }
      }

      if (send) {
        if (savedInvoice) {
          savedInvoice = await paymentsService.invoice.update(
            savedInvoice.invoiceId,
            {
              status: InvoiceStatus.Open
            },
            {
              waitForRowUpdate: true
            }
          );

          history.push(
            `/member/invoices/${savedInvoice.invoiceId}/send-success`,
            location.state
          );
        }

        if (savedInvoiceTemplate) {
          savedInvoiceTemplate = await paymentsService.invoiceTemplate.update(
            savedInvoiceTemplate.invoiceTemplateId,
            {
              status: FrequencyAndScheduleStatus.Active
            }
          );

          const createdInvoice = await findCreatedInvoice(
            savedInvoiceTemplate.invoiceTemplateId
          );

          if (createdInvoice) {
            history.push(
              `/member/invoices/${createdInvoice.invoiceId}/send-success`,
              location.state
            );
          } else {
            history.push(
              `/member/invoices/template/${savedInvoiceTemplate.invoiceTemplateId}/send-success`,
              location.state
            );
          }
        }
      }

      return {
        savedInvoice,
        savedInvoiceTemplate
      };
    },
    {
      throwOnError: false,
      onSuccess: ({ savedInvoice, savedInvoiceTemplate }, { send }) => {
        WSQueryCache.invalidateQueries(QUERY_INVOICES);
        WSQueryCache.invalidateQueries(QUERY_INVOICE_TEMPLATES);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS_SUMMARY);

        if (savedInvoice) {
          WSQueryCache.invalidateQueries([
            QUERY_INVOICE,
            savedInvoice.invoiceId
          ]);
        }
        if (savedInvoiceTemplate) {
          WSQueryCache.invalidateQueries([
            QUERY_INVOICE_TEMPLATE,
            savedInvoiceTemplate.invoiceTemplateId
          ]);
        }

        if (!send) {
          goBack();
        }
      }
    }
  );
};

export const useSendInvoice = () => {
  const history = useHistory();
  const location = useLocation();
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation<
    {
      invoice?: IInvoice;
      invoiceTemplate?: IInvoiceTemplate;
    },
    WSServiceError,
    {
      invoiceId?: string;
      invoiceTemplateId?: string;
    }
  >(
    async ({ invoiceId, invoiceTemplateId }) => {
      let invoice: IInvoice | undefined;
      let invoiceTemplate: IInvoiceTemplate | undefined;

      if (invoiceId) {
        invoice = await paymentsService.invoice.update(
          invoiceId,
          {
            status: InvoiceStatus.Open
          },
          {
            waitForRowUpdate: true
          }
        );

        history.push(
          `/member/invoices/${invoiceId}/send-success`,
          location.state
        );
      }

      if (invoiceTemplateId) {
        invoiceTemplate = await paymentsService.invoiceTemplate.update(
          invoiceTemplateId,
          {
            status: FrequencyAndScheduleStatus.Active
          }
        );

        const createdInvoice = await findCreatedInvoice(invoiceTemplateId);

        if (createdInvoice) {
          history.push(
            `/member/invoices/${createdInvoice.invoiceId}/send-success`,
            location.state
          );
        } else {
          history.push(
            `/member/invoices/template/${invoiceTemplateId}/send-success`,
            location.state
          );
        }
      }

      return {
        invoice,
        invoiceTemplate
      };
    },
    {
      throwOnError: false,
      onSuccess: ({ invoice, invoiceTemplate }) => {
        openSnackbar({
          type: "success",
          message: "Invoice has been sent"
        });

        WSQueryCache.invalidateQueries(QUERY_INVOICES);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        WSQueryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS_SUMMARY);

        if (invoice) {
          WSQueryCache.invalidateQueries([QUERY_INVOICE, invoice.invoiceId]);
        }

        if (invoiceTemplate) {
          WSQueryCache.invalidateQueries([
            QUERY_INVOICE_TEMPLATE,
            invoiceTemplate.invoiceTemplateId
          ]);
          WSQueryCache.invalidateQueries(QUERY_INVOICE_TEMPLATES);
        }
      },
      onError: error => {
        openSnackbar({
          type: "warning",
          message: transformErrorMessage({
            error,
            contextKey: ErrorContextKey.InvoiceForm
          })
        });
      }
    }
  );
};

export const useCreateClientInvoice = () => {
  return useWSMutation<
    IClientInvoice,
    WSServiceError,
    IClientInvoiceCreateRequest
  >(data => paymentsService.client.invoice.create(data), {
    throwOnError: false,
    dependencies: [QUERY_CLIENT_INVOICE]
  });
};

export const useUpdateClientInvoice = (invoiceId: string) => {
  return useWSMutation<
    IClientInvoice,
    WSServiceError,
    IClientInvoiceUpdateRequest
  >(data => paymentsService.client.invoice.update(invoiceId, data), {
    throwOnError: false,
    dependencies: [[QUERY_CLIENT_INVOICE, invoiceId]]
  });
};

export const usePayClientInvoice = (
  invoiceId: string,
  config?: WSMutationsConfig<IClientInvoice, WSServiceError, IPayRequest>
) => {
  return useWSMutation<IClientInvoice, WSServiceError, IPayRequest>(
    data => paymentsService.client.invoice.pay(invoiceId, data),
    {
      throwOnError: false,
      dependencies: [[QUERY_CLIENT_INVOICE, invoiceId]],
      ...config
    }
  );
};

export const useUpdateClientInvoiceTemplate = () => {
  return useWSMutation<
    IClientInvoiceTemplate,
    WSServiceError,
    { invoiceTemplateId: string; data: IClientInvoiceTemplateUpdateRequest }
  >(
    ({ invoiceTemplateId, data }) =>
      paymentsService.client.invoiceTemplate.update(invoiceTemplateId, data),
    {
      throwOnError: false,
      dependencies: [QUERY_CLIENT_INVOICE_TEMPLATE]
    }
  );
};

export const useAddCollaboratorToGroup = () =>
  useWSMutation<
    ICollaboratorSchema,
    WSServiceError,
    {
      collaboratorId: string;
      groupId: string;
    }
  >(
    ({ collaboratorId, groupId }) =>
      paymentsService.collaborator.addToGroup(collaboratorId, groupId),
    {
      throwOnError: false,
      awaitDependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL
      ]
    }
  );

export const useRemoveCollaboratorFromGroup = () =>
  useWSMutation<
    ICollaboratorSchema,
    WSServiceError,
    {
      collaboratorId: string;
      groupId: string;
    }
  >(
    ({ collaboratorId, groupId }) =>
      paymentsService.collaborator.removeFromGroup(collaboratorId, groupId),
    {
      throwOnError: false,
      awaitDependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL
      ]
    }
  );

export const useSavePayable = () => {
  const paymentsStatusQuery = usePaymentsStatusQuery();

  return useWSMutation<
    {
      savedPayable: IPayableSchema;
      savedCollaborator?: ICollaboratorSchema;
    },
    WSServiceError,
    {
      payableId?: string;
      data: PayableFormValues;
      resubmit?: boolean;
    }
  >(
    async ({ data, payableId, resubmit }) => {
      let savedCollaborator: ICollaboratorSchema | undefined;
      let savedPayable: IPayableSchema;

      const commonData = {
        currency:
          paymentsStatusQuery.data?.defaultAccountCurrency || Currency.USD,
        invoiceNotes: data.other.notes,
        labels: data.other.projectName
          ? {
              projectName: data.other.projectName
            }
          : undefined,
        attachments: {
          customAttachmentIds: attachmentsFormDataToCreateRequest(
            data.other.attachments
          )
        },
        client: {
          payDate: data.payDate
        },
        creditFeeHandling: {
          memberPays: 0,
          clientPays: 100
        },
        metadata: {
          purchaseOrderNumber: data.purchaseOrderNumber || undefined
        }
      };

      if (payableId) {
        const payable = await paymentsService.payable.get(payableId);

        savedPayable = await paymentsService.payable.update(
          payableId,
          getChangedData(
            payable,
            {
              ...commonData,
              lineItems:
                data.lineItems && data.lineItems.length > 0
                  ? convertToLineItemsUpdateRequest(data.lineItems)
                  : [
                      {
                        description: "",
                        totalCost: 0
                      }
                    ],
              attachments: {
                customAttachmentIds: attachmentsFormDataToUpdateRequest(
                  data.other.attachments,
                  payable.attachments?.customAttachmentIds || []
                )
              },
              client: {
                ...commonData.client,
                workflowSubStatus: resubmit
                  ? WorkFlowSubStatus.Submitted
                  : undefined
              }
            },
            { dismissTimeInDates: true }
          )
        );
      } else {
        if (!data.payee) throw new Error("No payee set");

        const collaboratorId = data.payee?.payerPayeeEngagementId;

        savedPayable = await paymentsService.payable.create({
          ...commonData,
          collaboratorId,
          lineItems:
            data.lineItems && data.lineItems.length > 0
              ? convertToLineItemsCreateRequest(data.lineItems)
              : [
                  {
                    description: "",
                    totalCost: 0
                  }
                ],
          dueDate: data.payDate
        });
      }

      return {
        savedCollaborator,
        savedPayable
      };
    },
    {
      throwOnError: false,
      dependencies: [QUERY_PAYABLES]
    }
  );
};

export const useSendPayable = () => {
  return useWSMutation<
    {
      payable?: IPayableSchema;
    },
    WSServiceError,
    {
      action: SendPayableInvoiceAction;
      payableId: string;
    }
  >(
    async ({ action, payableId }) => {
      let payable: IPayableSchema;

      if (action === "pay") {
        payable = await paymentsService.payable.update(payableId, {
          status: InvoiceStatus.Open
        });

        if (payable.attachments?.invoiceLink) {
          openInNewTab(payable.attachments?.invoiceLink);
        } else {
        }
      } else if (action === "approve") {
        payable = await paymentsService.payable.update(payableId, {
          status: InvoiceStatus.Open,
          client: {
            workflowStatus: ClientWorkFlowStatus.Approved
          }
        });
      } else {
        payable = await paymentsService.payable.update(payableId, {
          status: InvoiceStatus.Open
        });
      }

      return {
        payable
      };
    },
    {
      throwOnError: false,
      onSuccess: ({ payable }) => {
        WSQueryCache.invalidateQueries(QUERY_PAYABLES);
        if (payable) {
          WSQueryCache.invalidateQueries([QUERY_PAYABLE, payable?.payableId]);
        }
      }
    }
  );
};

export const useApprovePayable = () => {
  return useWSMutation<
    {
      payable: IPayableSchema;
    },
    WSServiceError
  >(
    async (payableId: string) => {
      const payable = await paymentsService.payable.update(payableId, {
        client: {
          workflowStatus: ClientWorkFlowStatus.Approved
        }
      });

      return {
        payable
      };
    },
    {
      onSuccess: ({ payable }) => {
        WSQueryCache.refetchQueries(QUERY_PAYABLES);
        WSQueryCache.refetchQueries([QUERY_PAYABLE, payable.payableId]);
      },
      throwOnError: false
    }
  );
};

export const usePreApprovePayable = () => {
  return useWSMutation<
    {
      payable: IPayableSchema;
    },
    WSServiceError
  >(
    async (payableId: string) => {
      const payable = await paymentsService.payable.update(payableId, {
        client: {
          workflowStatus: ClientWorkFlowStatus.PreApproved
        }
      });

      return {
        payable
      };
    },
    {
      onSuccess: ({ payable }) => {
        WSQueryCache.refetchQueries(QUERY_PAYABLES);
        WSQueryCache.refetchQueries([QUERY_PAYABLE, payable.payableId]);
      },
      throwOnError: false
    }
  );
};

export const useUpdatePayrollSettings = (
  memberId: string,
  config?: WSMutationConfig<IPayrollSettings, WSServiceError>
) => {
  return useWSMutation<
    IPayrollSettings,
    WSServiceError,
    IPayrollSettingsUpdate
  >(
    async data => {
      await paymentsService.service.get();
      return paymentsService.payrollSettings.update(memberId, data);
    },
    {
      dependencies: [QUERY_PAYROLL_SETTINGS],
      ...config
    }
  );
};

export const useUnapprovePayable = () => {
  const userId = useUserId();
  return useWSMutation<
    {
      payable: IPayableSchema;
    },
    WSServiceError
  >(
    async (payableId: string) => {
      const payrollSettings = await paymentsService.payrollSettings.get(userId);

      let workflowStatus = ClientWorkFlowStatus.Pending;

      if (payrollSettings.workflow === PayrollWorkflowStrategy.DualStage) {
        const payable = await paymentsService.payable.get(payableId);
        if (payable.client.workflowStatus === ClientWorkFlowStatus.Approved) {
          workflowStatus = ClientWorkFlowStatus.PreApproved;
        }
      }

      const payable = await paymentsService.payable.update(payableId, {
        client: {
          workflowStatus
        }
      });

      return {
        payable
      };
    },
    {
      onSuccess: ({ payable }) => {
        WSQueryCache.refetchQueries(QUERY_PAYABLES);
        WSQueryCache.refetchQueries([QUERY_PAYABLE, payable.payableId]);
      },
      throwOnError: false
    }
  );
};

export const useCancelPayable = () => {
  return useWSMutation<
    IPayableSchema,
    WSServiceError,
    {
      payableId: string;
    }
  >(
    async ({ payableId }) => {
      return await paymentsService.payable.update(payableId, {
        status: InvoiceStatus.Cancelled
      });
    },
    {
      dependencies: [QUERY_PAYABLES]
    }
  );
};

export const useDeletePayable = () => {
  return useWSMutation<
    IPayableSchema,
    WSServiceError,
    {
      payableId: string;
    }
  >(
    async ({ payableId }) => {
      return await paymentsService.payable.delete(payableId);
    },
    {
      dependencies: [QUERY_PAYABLES]
    }
  );
};

export const useRejectPayable = () => {
  return useWSMutation<
    IPayableSchema,
    WSServiceError,
    {
      payableId: string;
      comment: string;
    }
  >(
    async ({ payableId, comment }) => {
      return await paymentsService.payable.update(payableId, {
        client: {
          comment,
          workflowStatus: ClientWorkFlowStatus.Declined
        }
      });
    },
    {
      dependencies: [QUERY_PAYABLES]
    }
  );
};

export const useDownloadInvoicePdf = () => {
  const downloadPdf = useDownloadPdf();

  return useWSMutation<
    void,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(async ({ invoiceId }) => {
    const invoice = await paymentsService.invoice.generatePdf(invoiceId);

    if (!invoice.attachments?.invoicePdf) {
      throw new Error("No invoice pdf to download");
    }

    downloadPdf(invoice.attachments.invoicePdf);
  });
};

export const useDownloadInvoiceReceiptPdf = () => {
  const downloadPdf = useDownloadPdf();

  return useWSMutation<
    void,
    WSServiceError,
    {
      invoiceId: string;
    }
  >(async ({ invoiceId }) => {
    const invoice = await paymentsService.invoice.generatePdf(invoiceId);

    if (!invoice.attachments?.receiptPdf) {
      throw new Error("No invoice receipt to download");
    }

    downloadPdf(invoice.attachments.receiptPdf);
  });
};

export const useCreateCard = () => {
  return useWSMutation(
    async (data: ICardCreateRequest) => {
      return await paymentsService.banking.card.create(data);
    },
    {
      dependencies: [QUERY_CARD_LIST, QUERY_BOOKKEEPING_BANKING_ACCOUNT]
    }
  );
};

export const useReplaceCard = () => {
  return useWSMutation(
    async (payload: { id: string; data: ICardCreateRequest }) => {
      return await paymentsService.banking.card.replace(payload);
    },
    {
      dependencies: [QUERY_CARD_LIST, QUERY_BOOKKEEPING_BANKING_ACCOUNT]
    }
  );
};

export const useUpdateCard = () => {
  return useWSMutation(
    async (payload: { id: string; data: ICardUpdateRequest }) => {
      return await paymentsService.banking.card.update(
        payload.id,
        payload.data
      );
    },
    {
      dependencies: [QUERY_CARD_LIST]
    }
  );
};

export const useCreateCardToken = () => {
  return useWSMutation(
    async ({ id, ...data }: ICardCodeRequest & { id: string }) => {
      return await paymentsService.banking.card.token.create(id, data);
    }
  );
};

export const useUpdateCardToken = () => {
  return useWSMutation(
    async ({ id, ...data }: ICardTokenRequest & { id: string }) => {
      return await paymentsService.banking.card.token.update(id, data);
    }
  );
};

export const useRequestCardVerificationToken = () =>
  useWSMutation(({ id, ...data }: ICardCodeRequest & { id: string }) =>
    paymentsService.banking.card.token.create(id, data)
  );

export const useRequestCardToken = () =>
  useWSMutation(({ id, ...data }: ICardTokenRequest & { id: string }) =>
    paymentsService.banking.card.token.update(id, data)
  );

export const useLockCard = () =>
  useWSMutation(
    (id: string) =>
      paymentsService.banking.card.update(id, {
        status: CardStatusUpdate.Frozen
      }),
    {
      onSuccess: (_, id) => {
        WSQueryCache.invalidateQueries([QUERY_CARD, id]);
        WSQueryCache.invalidateQueries(QUERY_CARD_LIST);
      }
    }
  );

export const useUnlockCard = () =>
  useWSMutation(
    (id: string) =>
      paymentsService.banking.card.update(id, {
        status: CardStatusUpdate.Active
      }),
    {
      onSuccess: (_, id) => {
        WSQueryCache.invalidateQueries([QUERY_CARD, id]);
        WSQueryCache.invalidateQueries(QUERY_CARD_LIST);
      }
    }
  );

export const useTemrinateCard = () =>
  useWSMutation(
    (id: string) =>
      paymentsService.banking.card.update(id, {
        status: CardStatusUpdate.ClosedByCustomer
      }),
    {
      onSuccess: (_, id) => {
        WSQueryCache.invalidateQueries(QUERY_CARD_LIST);
      }
    }
  );

export const useSetStolenCard = () =>
  useWSMutation(
    (id: string) =>
      paymentsService.banking.card.update(id, {
        status: CardStatusUpdate.Stolen
      }),
    {
      onSuccess: (_, id) => {
        WSQueryCache.invalidateQueries(QUERY_CARD_LIST);
      }
    }
  );

export const useSetLostCard = () =>
  useWSMutation(
    (id: string) =>
      paymentsService.banking.card.update(id, {
        status: CardStatusUpdate.ClosedByCustomer
      }),
    {
      onSuccess: (_, id) => {
        WSQueryCache.invalidateQueries(QUERY_CARD_LIST);
      }
    }
  );

export const useCreateCollaboratorsGroup = () =>
  useWSMutation(
    (requestData: ICollaboratorGroupCreateRequest) =>
      paymentsService.collaboratorGroup.create(requestData),
    {
      dependencies: [QUERY_COLLABORATOR_GROUPS, QUERY_ALL_COLLABORATOR_GROUPS]
    }
  );

export const useUpdateCollaboratorsGroup = () =>
  useWSMutation(
    (requestData: { id: string } & ICollaboratorGroupUpdateRequest) =>
      paymentsService.collaboratorGroup.update(requestData.id, requestData),
    {
      dependencies: [QUERY_COLLABORATOR_GROUPS, QUERY_ALL_COLLABORATOR_GROUPS],
      onSuccess: (_, { id }) => {
        WSQueryCache.invalidateQueries([QUERY_COLLABORATOR_GROUP, id]);
      }
    }
  );

export const useDeleteCollaboratorsGroup = () =>
  useWSMutation((id: string) => paymentsService.collaboratorGroup.delete(id), {
    dependencies: [QUERY_COLLABORATOR_GROUPS, QUERY_ALL_COLLABORATOR_GROUPS]
  });

export const useCreateCollaboratorDeduction = () =>
  useWSMutation(
    (data: IDeductionCreateRequest) =>
      paymentsService.collaboratorDeductions.create(data),
    {
      dependencies: [QUERY_COLLABORATOR_DEDUCTIONS]
    }
  );

export const useDeleteCollaboratorDeduction = (
  config?: WSMutationConfig<IDeductionResponse, WSServiceError>
) =>
  useWSMutation<IDeductionResponse, WSServiceError>(
    (id: string) => paymentsService.collaboratorDeductions.delete(id),
    {
      dependencies: [QUERY_COLLABORATOR_DEDUCTIONS],
      ...config
    }
  );

export const useGetCurrencyRate = () => {
  return useWSMutation(
    async (conversionData: ICurrencyRateRequest): Promise<ICurrencyRate> => {
      const exchangeRateTimestamp = new Date().toISOString();

      const currencyRate = await getCurrencyRate(conversionData);

      return { ...currencyRate, exchangeRateTimestamp };
    }
  );
};
