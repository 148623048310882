import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useCallback, useEffect, useState } from "react";
import { updateAccountCache } from "../../query/cache/updateAccountCache";
import { usersService } from "../../services/users";
import { useWSPlaidLink } from "../../utils/usePlaidLink";

export function usePlaidReconnect() {
  const [account, setAccount] = useState<IAccount | null>(null);

  const { openSnackbar } = useWSSnackbar();

  const plaidLink = useWSPlaidLink({
    token: account?.publicToken,
    onSuccess: async () => {
      if (account) {
        const updatedAccount = await usersService.account.update(
          account.accountId,
          {}
        );

        updateAccountCache(updatedAccount);

        openSnackbar({
          type: "success",
          icon: {
            name: "check-circle"
          },
          message: "Account was successfully reconnected"
        });
      }

      setAccount(null);
    },
    onExit: () => {
      setAccount(null);
    }
  });

  useEffect(() => {
    if (account?.publicToken) {
      plaidLink.open();
    }
  }, [account?.publicToken, plaidLink]);

  return useCallback((account: IAccount) => {
    setAccount(account);
  }, []);
}
