import {
  toWSDateString,
  useIsDesktop,
  WSButton,
  WSScreen
} from "@wingspanhq/fe-component-library";
import { PaymentStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { addDays } from "date-fns";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../query/hooks/helpers";
import {
  useGetPaymentMethod,
  useSubscriptionV3Query
} from "../../query/subscriptions/queries";
import { StaticBanner } from "../StaticBanner/StaticBanner";

export const SubscriptionBanner: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const isDesktop = useIsDesktop();
  const {
    data: subscription,
    isFetched: subscriptionIsFetched,
    error: subscriptionError
  } = useSubscriptionV3Query(userId);
  const {
    data: paymentMethod,
    isFetched: paymentMethodIsFetched,
    error: paymentMethodError
  } = useGetPaymentMethod();

  const creditCardRequired =
    paymentMethod?.discount?.percentOff !== 100 && !paymentMethod?.last4;

  const defaultAction = () => {
    if (isDesktop) {
      history.push("/member/settings/account?editPm=1");
    } else {
      history.push("/member/settings/account/membership?editPm=1");
    }
  };

  if (!subscriptionIsFetched || !paymentMethodIsFetched) {
    return null;
  }

  if (
    !subscription &&
    subscriptionError &&
    (subscriptionError.response?.status === 404 ||
      subscriptionError.response?.status === 400)
  ) {
    return null;
  }

  const subscriptionErrorAction = () => {
    window.open("mailto:support@wingspan.app");
  };

  return (
    <>
      <StaticBanner
        kind="Error"
        onClick={subscriptionErrorAction}
        name="subscriptionError"
        isVisible={
          !!subscriptionError ||
          (!!paymentMethodError && paymentMethodError.response?.status !== 404)
        }
      >
        Something went wrong. Please, contact{" "}
        <WSButton.Link onClick={subscriptionErrorAction}>
          support@wingspan.app
        </WSButton.Link>
      </StaticBanner>

      <StaticBanner
        onClick={defaultAction}
        name="subscriptionFreeTrial"
        isVisible={
          !!subscription &&
          subscription.trialEnd >= new Date() &&
          creditCardRequired &&
          !subscription.subscriptionGrantId
        }
      >
        <WSScreen.Mobile>
          Free trial expires{" "}
          <b>{toWSDateString(subscription?.trialEnd, "monthDayYear")}</b>.{" "}
          <WSButton.Link onClick={defaultAction}>Update.</WSButton.Link>
        </WSScreen.Mobile>
        <WSScreen.TabletAndDesktop>
          Your free trial expires after{" "}
          <b>{toWSDateString(subscription?.trialEnd, "monthDayYear")}</b>.{" "}
          <WSButton.Link onClick={defaultAction}>
            Add payment method.
          </WSButton.Link>
        </WSScreen.TabletAndDesktop>
      </StaticBanner>

      <StaticBanner
        kind="Warning"
        onClick={defaultAction}
        name="subscriptionPaymentFailed"
        isVisible={
          !!subscription &&
          subscription.latestPaymentStatus === PaymentStatus.Failed &&
          !subscription.cancelAtPeriodEnd &&
          subscription.currentPeriodEnd < new Date() &&
          subscription.gracePeriodEnd >= new Date()
        }
      >
        <WSScreen.Mobile>
          Payment failed. Resolve before{" "}
          <b>
            {toWSDateString(
              addDays(subscription?.currentPeriodEnd || new Date(), 7),
              "monthDayYear"
            )}
          </b>{" "}
          to prevent your account from being paused.{" "}
          <WSButton.Link onClick={defaultAction}>Update.</WSButton.Link>
        </WSScreen.Mobile>
        <WSScreen.TabletAndDesktop>
          Payment failed. Resolve before{" "}
          <b>
            {toWSDateString(
              addDays(subscription?.currentPeriodEnd || new Date(), 7),
              "monthDayYear"
            )}
          </b>{" "}
          to prevent your account from being paused.{" "}
          <WSButton.Link onClick={defaultAction}>
            Update payment method.
          </WSButton.Link>
        </WSScreen.TabletAndDesktop>
      </StaticBanner>
    </>
  );
};
