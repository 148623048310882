import { Redirect, Route, Switch } from "react-router-dom";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../query/WSQuery";
import {
  BULK_IMPORT_CONTRACTORS_BATCH_DETAILS,
  BULK_IMPORT_CONTRACTORS_BATCH_EVENT_HISTORY,
  BULK_IMPORT_CONTRACTORS_START,
  BULK_IMPORT_PAYABLES_BATCH_DETAILS,
  BULK_IMPORT_PAYABLES_BATCH_EVENT_HISTORY,
  BULK_IMPORT_PAYABLES_START,
  BULK_IMPORTS_ROOT_PATH
} from "./paths";
import { RouteImportPayableBatchDetails } from "./routes/RouteImportPayableBatchDetails";
import { RouteImportPayableBatchEventHistory } from "./routes/RouteImportPayableBatchEventHistory";
import { RouteImportPayablesStart } from "./routes/RouteImportPayablesStart";
import { RouteImportContractorsBatchDetails } from "./routes/RouteImportContractorsBatchDetails";
import { RouteImportContractorBatchEventHistory } from "./routes/RouteImportContractorBatchEventHistory";
import { RouteImportContractorsStart } from "./routes/RouteImportContractorsStart";
import { BulkImports } from "./routes/BulkImports";

export const BulkImportsIndex: React.FC = () => {
  const queryFeatureFlags = useFeatureFlags();
  return (
    <WSQueries queries={{ queryFeatureFlags }}>
      {({ queryFeatureFlagsData: featureFlags }) => {
        return (
          <Switch>
            {/* Bulk import contractors pages */}
            <Route
              path={BULK_IMPORT_CONTRACTORS_START}
              component={RouteImportContractorsStart}
            />
            {featureFlags.newBulkImporterUI && (
              <Route
                path={BULK_IMPORT_CONTRACTORS_BATCH_EVENT_HISTORY}
                component={RouteImportContractorBatchEventHistory}
              />
            )}
            {featureFlags.newBulkImporterUI && (
              <Route
                path={BULK_IMPORT_CONTRACTORS_BATCH_DETAILS}
                component={RouteImportContractorsBatchDetails}
              />
            )}
            {/* Bulk import payables pages */}
            <Route
              path={BULK_IMPORT_PAYABLES_START}
              component={RouteImportPayablesStart}
            />
            {featureFlags.newBulkImporterUIForPayables && (
              <Route
                path={BULK_IMPORT_PAYABLES_BATCH_EVENT_HISTORY}
                component={RouteImportPayableBatchEventHistory}
              />
            )}
            {featureFlags.newBulkImporterUIForPayables && (
              <Route
                path={BULK_IMPORT_PAYABLES_BATCH_DETAILS}
                component={RouteImportPayableBatchDetails}
              />
            )}
            <Route path={BULK_IMPORTS_ROOT_PATH} component={BulkImports} />
            <Redirect from="*" to={BULK_IMPORTS_ROOT_PATH} />
          </Switch>
        );
      }}
    </WSQueries>
  );
};
