import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  QUERY_ENGAGEMENT,
  QUERY_ENGAGEMENT_LIST,
  QUERY_ENGAGEMENT_LIST_SIZE
} from "../keys";
import { deleteRequirementFromEngagement } from "../../../services/engagements/deleteRequirementFromEngagement";

type DeleteRequirementFromEngagementRequest = {
  engagementId: string;
  requirementDefinitionId: string;
};

export const useDeleteRequirementFromEngagement = (
  config?: WSMutationConfig<IEngagement, WSServiceError>
) =>
  useWSMutation<
    IEngagement,
    WSServiceError,
    DeleteRequirementFromEngagementRequest
  >(
    ({ engagementId, requirementDefinitionId }) =>
      deleteRequirementFromEngagement(engagementId, requirementDefinitionId),
    {
      ...config,
      dependencies: [QUERY_ENGAGEMENT_LIST, QUERY_ENGAGEMENT_LIST_SIZE],
      awaitDependencies: response => [[QUERY_ENGAGEMENT, response.engagementId]]
    }
  );
