import {
  useWSModal,
  WSActions,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";
import { CashbackProgramInfo } from "../../PaymentMethods/components/DebitCashbackProgram";

const CashbackDetailsModal: React.FC<{ onClose(): void }> = ({ onClose }) => {
  return (
    <WSList gap="XL">
      <CashbackProgramInfo />

      <WSActions
        alignment="fill"
        sortButtons={false}
        buttons={[
          {
            label: "View terms & conditions",
            rightIcon: "open-tab",
            onAsyncClick: async () => {
              openInNewTab(WS_LINKS.cashBackTermsAndConditions);
            },
            kind: "Tertiary"
          },
          {
            label: "Done",
            onClick: onClose,
            kind: "Secondary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCashbackDetailsModal() {
  return useWSModal(CashbackDetailsModal, {
    title: "Cashback program details",
    size: "M"
  });
}
