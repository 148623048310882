import {
  WSInfiniteScroll,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import { useQueryInvoicesRows } from "../../queries/useQueryInvoicesRows";
import { TableInvoices } from "../TableInvoices";

type Props = {
  query: ReturnType<typeof useQueryInvoicesRows>;
  onDownload?: () => void;
  downloadLoading?: boolean;
};

export const PaginatedTable: React.FC<Props> = ({
  query,
  onDownload,
  downloadLoading
}) => {
  if (query.isLoading) {
    return <WSLoader.Spinner size="S" />;
  }

  if (query.data && query.data.length === 0) {
    return (
      <WSText.ParagraphSm color="gray500">No invoices found</WSText.ParagraphSm>
    );
  }

  return (
    <WSInfiniteScroll
      onLoad={() => {
        query.fetchNextPage();
      }}
      loadMore={query.data && query.data.length > 0}
      endOfList={!query.hasNextPage}
      loading={!!query.isFetchingNextPage}
    >
      <TableInvoices
        headerAction={
          onDownload
            ? {
                icon: "download",
                onClick: onDownload,
                loading: downloadLoading
              }
            : undefined
        }
        loading={query.isLoading}
        invoices={query.data || []}
        showHeader
      />
    </WSInfiniteScroll>
  );
};
