import {
  useModalOldContext,
  WSElement,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";

import React from "react";

export const ORGANIZATION_DESCRIPTION_INFO_MODAL =
  "ORGANIZATION_DESCRIPTION_INFO_MODAL";

export const OrganizationDescriptionInfoModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  return (
    <WSModalOld
      name={ORGANIZATION_DESCRIPTION_INFO_MODAL}
      size="S"
      allowClickOutsideToClose
    >
      <WSText.Heading4 mb="M">What is a Wingspan Account?</WSText.Heading4>
      <WSText mb="XL">
        A Wingspan Account can be almost completely invisible to the account
        holder. You—the platform—are responsible for all interactions with your
        user, including collecting any information Wingspan needs. You have the
        ability to change all of the account’s settings, including the payout
        bank or debit card account, programmatically. Optionally, you can use
        <a
          target="_blank"
          href="https://wingspan.readme.io/docs/embed-wingspan-into-your-app"
          rel="noreferrer"
        >
          {` Wingspan’s pre-built UIs `}
        </a>
        to onboard your Child Accounts.
      </WSText>
      <WSText as="ul" mb="XL">
        Use Wingspan Accounts accounts when you:
        <WSElement as="li" ml="M" mt="XS">
          Want complete control over your user’s experience
        </WSElement>
        <WSElement as="li" ml="M" mt="XS">
          Can build the significant infrastructure required to collect user
          information, create a user dashboard, and handle support
        </WSElement>
      </WSText>
      <WSText mb="M">
        Currently, these accounts
        <a
          href="https://wingspan.readme.io/docs/create-an-organization-child-account"
          target="_blank"
          rel="noreferrer"
        >{` can only be created programmatically `}</a>
      </WSText>
    </WSModalOld>
  );
};
