import {
  useWSModal,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  ICustomField,
  IPayeeResponse,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

import orderBy from "lodash/orderBy";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { getChangedData } from "../../../../utils/getChangedData";
import {
  getDefaultValuesForAdditionalData,
  getYupValidationSchema,
  prepareCollaboratorLabelsRequest
} from "../../../../utils/jsonFormSchema";

export const prepareDynamicFormFields = (
  additionalData: ICustomField[],
  prefix?: string
) => {
  return orderBy(additionalData, "type", "desc").map(field => {
    switch (field.type) {
      case WSDataTypes.String:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            label={`${field.name}${field.required ? "" : " (optional)"}`}
            component={WSTextInput}
          />
        );
      case WSDataTypes.Boolean:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            component={WSCheckboxToggle}
            componentProps={{ label: field.name }}
          />
        );
      default:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            label={`${field.name}${field.required ? "" : " (optional)"}`}
            component={WSTextInput}
          />
        );
    }
  });
};

type Props = {
  payee: IPayeeResponse;
  allCustomFields: ICustomField[];
  isEnterpriseUser: boolean;
  onClose: (result: boolean) => void;
};

const UpdateCustomFieldsModal: React.FC<Props> = ({
  onClose,
  payee,
  isEnterpriseUser,
  allCustomFields
}) => {
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const additionalDataValidationSchema =
    getYupValidationSchema(allCustomFields);

  return (
    <WSFormOld
      defaultValues={{
        externalId: payee.payerOwnedData.payeeExternalId,
        ...getDefaultValuesForAdditionalData(
          allCustomFields,
          payee.payerOwnedData.customFields
        )
      }}
      onSubmit={({ externalId, ...customFields }) => {
        updatePayee(
          {
            payeeId: payee.payeeId,
            payerOwnedData: getChangedData(payee.payerOwnedData, {
              ...(isEnterpriseUser
                ? {
                    payeeExternalId: externalId
                  }
                : {}),
              customFields: {
                ...prepareCollaboratorLabelsRequest(
                  allCustomFields,
                  customFields
                )
              }
            })
          },
          {
            onSuccess: () => {
              onClose(true);
            }
          }
        );
      }}
      validationSchema={Yup.object().shape({
        ...additionalDataValidationSchema
      })}
    >
      {isEnterpriseUser && (
        <>
          <WSFormOld.Field
            name="externalId"
            label="External Contractor ID (optional)"
            component={WSTextInput}
            mb="XL"
          />
        </>
      )}
      {prepareDynamicFormFields(allCustomFields)}
      <WSErrorMessage
        mb="XL"
        error={updatePayeeMeta.error}
        contextKey="EditContact"
      />
      <WSButtons format="modal">
        <WSButton loading={updatePayeeMeta.isLoading}>Update</WSButton>
        <WSButton.Tertiary
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSFormOld>
  );
};

export const useUpdateCustomFieldsModal = () =>
  useWSModal(UpdateCustomFieldsModal, {
    title: "Update custom fields",
    size: "S"
  });
