import {
  useWSModal,
  WSForm,
  WSInputText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueryCache } from "@ws-react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_USER_PROFILE } from "../../../../../query/users/keys";
import { useUserProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorName } from "../../../../../shared/validators/validatorName";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: string };

type Props = {
  onClose: () => void;
};

export const FormEditPreferredName: React.FC<Props> = props => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const user = await usersService.user.update(userId, {
        profile: {
          preferredName: data.newValue
        }
      });

      WSQueryCache.setQueryData(QUERY_USERS_USER_PROFILE, user);
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE],
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      defaultValues={{
        newValue: queryUser.data?.profile.preferredName
      }}
      onSubmit={mutate}
      validationSchema={Yup.object({ newValue: validatorName })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <WSForm.Field
          name="newValue"
          label="Preferred name (optional)"
          component={WSInputText}
        />
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditPreferredName = () =>
  useWSModal(FormEditPreferredName, {
    title: "Preferred name",
    size: "S"
  });
