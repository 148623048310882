import React from "react";
import { PageHeader } from "../../../../shared/components/PageHeader";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../..";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  toWSDate,
  WSElement,
  WSFlexBox,
  WSList,
  WSPage,
  WSPanel,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useRequirementDefinitionQuery } from "../../../../query/requirementDefinitions/query/useRequirementDefinitionQuery";
import { WSQueries } from "../../../../query/WSQuery";
import {
  RequirementDefinitionStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { useSignedDocumentTemplateQuery } from "../../../../query/signedDocuments/query/useSignedDocumentTemplateQuery";
import { RequirementInformation } from "../../components/RequirementInformation";
import { EngagementsTable } from "./EngagementsTable";
import { helloSignClient, helloSignPromise } from "../../../../utils/helloSign";
import { useRenameRequirementModal } from "../../components/RenameRequirementModal/useRenameRequirementModal";
import { useSharedFileSettingsQuery } from "../../../../query/sharedFile/query/useSharedFileSettingsQuery";
import { WSActionsMenuItem } from "@wingspanhq/fe-component-library/dist/lib/components/WSActions/WSActions";
import { useBackgroundChecksSettingsQuery } from "../../../../query/backgroundChecks/queries/useBackgroundChecksSettingsQuery";

export const RouteRequirementDetails: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ requirementId: string }>();
  const { requirementId } = match.params;
  const requirementQuery = useRequirementDefinitionQuery(requirementId);
  const renameRequirementModal = useRenameRequirementModal();
  const signedDocumentTemplateQuery = useSignedDocumentTemplateQuery(
    requirementQuery.data?.dataSourceId as string,
    {
      enabled:
        requirementQuery.data?.requirementType === RequirementType.Signature &&
        !!requirementQuery.data?.dataSourceId
    }
  );
  const sharedFileSettingsQuery = useSharedFileSettingsQuery(
    requirementQuery.data?.dataSourceId as string,
    {
      enabled:
        requirementQuery.data?.requirementType === RequirementType.SharedFile &&
        !!requirementQuery.data?.dataSourceId
    }
  );

  const backgroundChecksSettingsQuery = useBackgroundChecksSettingsQuery(
    requirementQuery.data?.dataSourceId as string,
    {
      enabled:
        requirementQuery.data?.requirementType ===
          RequirementType.BackgroundCheck &&
        !!requirementQuery.data?.dataSourceId
    }
  );

  return (
    <WSPage>
      <WSQueries queries={{ requirementQuery }}>
        {({ requirementQueryData }) => {
          // @ts-ignore
          const onPreviewByType: Record<
            RequirementType,
            undefined | (() => Promise<void>)
          > = {
            [RequirementType.Signature]: async () => {
              let template = signedDocumentTemplateQuery.data;
              if (!signedDocumentTemplateQuery.isSuccess) return;
              if (!template?.viewUrl) {
                console.error("No HS view URL provided");
                return;
              }

              helloSignClient.open(template.viewUrl);

              await helloSignPromise(); // wait for close
              await signedDocumentTemplateQuery.refetch(); // refetching to enable re-opening the window. view URLs seems to be one-time
            },
            [RequirementType.SharedFile]: undefined,
            [RequirementType.BackgroundCheck]: undefined
          };
          // @ts-ignore
          const loadingByType: Record<RequirementType, boolean> = {
            [RequirementType.Signature]:
              signedDocumentTemplateQuery.isInitialLoading,
            [RequirementType.SharedFile]:
              sharedFileSettingsQuery.isInitialLoading,
            [RequirementType.BackgroundCheck]:
              backgroundChecksSettingsQuery.isInitialLoading
          };

          const openPreview =
            onPreviewByType[requirementQueryData.requirementType];

          if (loadingByType[requirementQueryData.requirementType]) {
            return null;
          }

          return (
            <WSElement>
              <PageHeader
                title="Requirements"
                rootPath={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
                breadcrumbs={[
                  {
                    label: "Requirements",
                    onClick: () => {
                      history.push(ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH);
                    }
                  },
                  {
                    label: requirementQueryData.name
                  }
                ]}
                buttons={
                  openPreview
                    ? [
                        {
                          label: "Preview requirement",
                          onClick: openPreview
                        }
                      ]
                    : undefined
                }
                menuItems={
                  [
                    ...(openPreview
                      ? [
                          {
                            icon: "view",
                            label: "Preview requirement",
                            onClick: openPreview
                          }
                        ]
                      : []),
                    {
                      icon: "edit",
                      label: "Rename requirement",
                      onClick: () =>
                        renameRequirementModal.open({ requirementId })
                    }
                  ] as WSActionsMenuItem[]
                }
              />

              <WSFlexBox.CenterY mt="L" mb="2XL" gap="M">
                <WSText.Heading3 singleLine>
                  {requirementQueryData.name}
                </WSText.Heading3>
                {requirementQueryData.status ===
                RequirementDefinitionStatus.Inactive ? (
                  <WSPill theme="neutral" text="Archived" icon={false} />
                ) : requirementQueryData.status ===
                  RequirementDefinitionStatus.Active ? (
                  <WSPill theme="success" text="Ready" icon={false} />
                ) : null}
                <WSText.ParagraphXs color="gray500">
                  Last modified{" "}
                  {toWSDate(requirementQueryData.updatedAt).formattedValue}
                </WSText.ParagraphXs>
              </WSFlexBox.CenterY>

              <WSList gap="XL">
                <WSElement>
                  <WSSectionToolbar title="Requirement information" />
                  <WSPanel mt="M">
                    <RequirementInformation
                      requirementDefinition={requirementQueryData}
                      singedDocumentTemplate={signedDocumentTemplateQuery?.data}
                      sharedFileSettings={sharedFileSettingsQuery?.data}
                      backgroundChecksSettings={
                        backgroundChecksSettingsQuery?.data
                      }
                    />
                  </WSPanel>
                </WSElement>

                <WSElement>
                  <WSSectionToolbar mb="M" title="Requirement in engagements" />
                  <EngagementsTable requirementId={requirementId} />
                </WSElement>
              </WSList>
            </WSElement>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
