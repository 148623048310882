import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import {
  isBatchFullyImported,
  isBatchPartiallyImported
} from "../../utils/bulkBatchUtils";
import { WSAlert } from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { formatNumber } from "accounting";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";

type Props = {
  bulkBatchId: string;
  bulkBatch: IBulkBatch;
  bulkResource: BulkResource;
};
export const BulkBatchStatusAlert: React.FC<Props> = ({
  bulkBatchId,
  bulkBatch,
  bulkResource
}) => {
  const [showCompleteAlert, setShowCompleteAlert] = useState(true);

  const { useBulkBatchSummary } = getBulkQueriesByResource(bulkResource);

  const queryBulkBatchItems = useBulkBatchSummary(bulkBatchId);
  const totalBatchItemsSize = queryBulkBatchItems.data?.listSize || 0;
  const itemsProcessed = bulkBatch.statistics?.itemsProcessed || 0;
  const itemsFailed = bulkBatch.statistics?.itemsFailed || 0;

  const formattedTotalBatchItemsSize = formatNumber(totalBatchItemsSize);
  const formattedItemsProcessed = formatNumber(itemsProcessed);
  const formattedItemsFailed = formatNumber(itemsFailed);

  if (isBatchFullyImported(bulkBatch)) {
    return showCompleteAlert ? (
      <WSAlert
        theme="success"
        icon="check-circle"
        title="Import complete"
        onDismiss={() => setShowCompleteAlert(false)}
      >
        All items were imported from the spreadsheet. To add a name and notes to
        the import batch for your own reference click the “Edit name” button.
      </WSAlert>
    ) : null;
  } else if (isBatchPartiallyImported(bulkBatch)) {
    return (
      <WSAlert theme="warning" icon="alert" title="Partially imported">
        {formattedItemsProcessed} of {formattedTotalBatchItemsSize} items were
        imported from the spreadsheet and {formattedItemsFailed} of{" "}
        {formattedTotalBatchItemsSize} failed. A spreadsheet with the errors is
        available for download. You can resolve the errors and upload in a new
        import batch. To add a name and notes to the import batch for your own
        reference click “Edit name” below.
      </WSAlert>
    );
  } else if (bulkBatch.status === BulkStatus.Failed) {
    return (
      <WSAlert theme="error" icon="alert-circle" title="Import failed">
        {formattedItemsFailed} of {formattedTotalBatchItemsSize} items failed to
        import from the spreadsheet. A spreadsheet with the errors is available
        for download. You can resolve the errors and upload in a new import
        batch. To add a name and notes to the import batch for your own
        reference click “Edit name” below.
      </WSAlert>
    );
  }

  return null;
};
