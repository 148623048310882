import {
  useWSSnackbar,
  WSAlert,
  WSBreadcrumbsItem,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPageToolbar,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerResponse,
  PayeeTaxDocumentSharePermission,
  PayerPayeeEngagementStatus,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useCallback } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { useMemberPrivateFiles } from "../../../../query/files/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useIntegrationsQuickbooks } from "../../../../query/integrations/queries";
import { usePayerEngagementsListQuery } from "../../../../query/payerEngagements/queries/usePayerEngagementsListQuery";
import { useUpdatePayer } from "../../../../query/payers/mutations/useUpdatePayer";
import { usePayerQuery } from "../../../../query/payers/queries/usePayerQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { useModalShareTaxInformationWithPayer } from "../../../../shared/components/ModalShareTaxInformationWithPayer/useModalShareTaxInformationWithPayer";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";
import { selectorIsEngagementsActiveForPayer } from "../../../Payees/selectors/selectorIsEngagementsActiveForPayer";
import { selectorPayerEngagementsTmp } from "../../../Payees/selectors/selectorPayerEngagementsTmp";
import { selectorPayerPayeeEngagements } from "../../../Payees/selectors/selectorPayerPayeeEngagements";
import { AutoPay } from "../../components/AutoPay";
import { ClientInfoPanel } from "../../components/ClientInfoPanel";
import { DocumentsSection } from "../../components/DocumentsSection";
import { DocumentsSectionType } from "../../components/DocumentsSection/types";
import { useEditClientModal } from "../../components/EditClientModal/useEditClientModal";
import { EngagementsTable } from "../../components/EngagementsTable";
import { PayerIntegrationWidget } from "../../components/IntegrationsSection/PayerIntegrationWidget";
import {
  getAllEngagementsEligibilityRequirementsCompleted,
  getHasEligibleEngagements
} from "../../utils/engagement";
import { PAYERS_PATH } from "../RouteClients";
import { RouteEngagementDetails } from "../RouteEngagementDetails";
import { useArchiveClientModal } from "./ArchiveClientModal";
import { RecentInvoicesWidget } from "./RecentInvoicesWidget";
import { WireTransferSection } from "./WireTransferSection";

export const RouteClientDetails: React.FC = () => {
  const history = useHistory();

  const { openSnackbar } = useWSSnackbar();
  const match = useRouteMatch<{ clientId: string }>();
  const userId = useUserId();
  const modalFormW9PayerConsent = useModalShareTaxInformationWithPayer();

  const queryClient = usePayerQuery(match.params.clientId);
  const queryPayerEngagements = usePayerEngagementsListQuery(
    match.params.clientId
  );

  const qDocuments = useMemberPrivateFiles({
    filter: {
      hidden: true,
      tags: { in: [`payer:${match.params.clientId}`] }
    }
  });
  const [restoreClient] = useUpdatePayer();

  const archiveClientModal = useArchiveClientModal();
  const editClientModal = useEditClientModal();

  const queryFeatureFlags = useFeatureFlags();
  const queryQBO = useIntegrationsQuickbooks();

  const handleCreateInvoice = useCallback(() => {
    const state =
      queryClient.data && queryPayerEngagements.data?.length === 1
        ? {
            payerId: queryClient.data.payerId,
            payerPayeeEngagementId:
              queryPayerEngagements.data[0].payerPayeeEngagementId
          }
        : {
            email: queryClient?.data?.user?.email
          };

    history.push("/member/invoices/create", state);
  }, [queryClient.data, queryPayerEngagements.data]);

  const handleEditClient = () => {
    editClientModal.open({
      client: queryClient?.data as IPayerResponse
    });
  };

  const handleRestoreClient = async (client: IPayerResponse) => {
    await restoreClient(
      {
        id: client.payerId,
        payeeOwnedData: {
          status: PayerPayeeStatus.Active
        }
      },
      {
        onSuccess: () => {
          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Client restored successfully"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to restore client`
          });
        }
      }
    );
  };

  return (
    <WSPage>
      <Route
        path={`${match.path}/engagements/:engagementId`}
        component={RouteEngagementDetails}
      />
      <WSQueries
        queries={{
          queryClient,
          queryPayerEngagements,
          queryFeatureFlags,
          queryQBO
        }}
      >
        {({
          queryClientData: client,
          queryPayerEngagementsData: payerEngagements,
          queryQBOData,
          queryFeatureFlagsData
        }) => {
          const clientInfo = wsName({
            user: {
              email: client.user?.email as string,
              profile: client.user?.profile
            },
            member: client.member,
            payeeOwnedData: client.payeeOwnedData
          });
          const activeEngagements = payerEngagements.filter(
            e => e.status !== PayerPayeeEngagementStatus.Inactive
          );
          const areRequirementsCompleted =
            getAllEngagementsEligibilityRequirementsCompleted(
              activeEngagements
            );

          const hasEligibleEngagements =
            getHasEligibleEngagements(activeEngagements);

          const isInactive =
            client.payeeOwnedData.status === PayerPayeeStatus.Inactive;
          const isNotShared =
            client.payeeOwnedData?.shareTaxDocument !==
            PayeeTaxDocumentSharePermission.Allow;

          const isQuickbooksActive = selectorIsQBOActive(
            queryFeatureFlagsData,
            queryQBOData
          );

          const forceShowEngagements =
            isQuickbooksActive ||
            payerEngagements.some(
              eng => eng.status === PayerPayeeEngagementStatus.Inactive
            );

          const filteredEngagements = selectorIsEngagementsActiveForPayer(
            payerEngagements
          )
            ? selectorPayerPayeeEngagements(payerEngagements, false, true)
            : selectorPayerEngagementsTmp(
                payerEngagements,
                forceShowEngagements
              );

          return (
            <>
              <WSPageToolbar
                breadcrumbs={[
                  {
                    label: "Clients",
                    onClick: () => {
                      history.push(PAYERS_PATH);
                    }
                  },
                  ...(isInactive
                    ? [
                        {
                          label: "Archived clients",
                          onClick: () => {
                            history.push(`${PAYERS_PATH}/archived`);
                          }
                        } as WSBreadcrumbsItem
                      ]
                    : []),
                  {
                    label: clientInfo.getResolvedName()
                  }
                ]}
                actions={
                  isInactive
                    ? [
                        {
                          label: "Restore client",
                          icon: "repeat-circle",
                          onClick: () => {
                            handleRestoreClient(client);
                          }
                        }
                      ]
                    : [
                        {
                          icon: "plus",
                          label: "Create invoice",
                          buttonKind: "Primary",
                          onClick: handleCreateInvoice
                        },
                        {
                          icon: "draft",
                          label: "Create invoice",
                          onClick: handleCreateInvoice
                        },
                        {
                          icon: "edit",
                          label: "Edit client",
                          onClick: handleEditClient
                        },
                        {
                          icon: "archive",
                          label: "Archive client",
                          onClick: () => {
                            archiveClientModal.open({
                              client
                            });
                          }
                        }
                        // FIX: implement in future
                        // {
                        //   icon: "trash",
                        //   iconProps: {
                        //     color: "red400"
                        //   },
                        //   label: "Remove client",
                        //   labelProps: {
                        //     color: "red400"
                        //   },
                        //   onClick: () => {
                        //     removeClientModal.open({
                        //       client
                        //     });
                        //   }
                        // }
                      ]
                }
              />
              <WSFlexBox.CenterY mb="XL">
                <WSText.Heading3 mr="L">
                  {clientInfo.getResolvedName()}
                </WSText.Heading3>
                {client.payeeOwnedData.status === PayerPayeeStatus.Inactive ? (
                  <WSPill theme="neutral" text="Archived" />
                ) : null}
              </WSFlexBox.CenterY>
              <WSGrid gutter="2XL">
                <WSGrid.Item span={{ m: "8" }}>
                  {
                    // show if registered but not shared
                    isNotShared && (
                      <WSAlert
                        icon="alert"
                        size="M"
                        theme="warning"
                        title="Tax information not shared"
                        action={{
                          onClick: () => {
                            modalFormW9PayerConsent.open({
                              payer: client
                            });
                          },
                          text: "Share tax information"
                        }}
                      >
                        <WSText.ParagraphSm weight="book" color="gray700">
                          You have not consented to share your tax information
                          with the client. Future payments to you may not be
                          completed until the information is shared.
                        </WSText.ParagraphSm>
                      </WSAlert>
                    )
                  }
                  <WSSectionToolbar title="Client information" />
                  <ClientInfoPanel mb="XL" client={client} />

                  {filteredEngagements.length ? (
                    <>
                      <WSSectionToolbar title="Engagements" />
                      {areRequirementsCompleted && !hasEligibleEngagements ? (
                        <WSAlert
                          mb="L"
                          theme="warning"
                          icon="alert"
                          title="Payments eligibility pending"
                        >
                          You need to have at least one active engagement that
                          is eligible for payments to receive payments from this
                          client.
                        </WSAlert>
                      ) : !areRequirementsCompleted ? (
                        <WSAlert
                          theme="warning"
                          icon="alert"
                          title="Engagement requirements incomplete"
                          mb="L"
                        >
                          You will not be able to receive payouts for an
                          engagement until all requirements for that engagement
                          is complete.
                        </WSAlert>
                      ) : null}
                      <EngagementsTable engagements={filteredEngagements} />
                    </>
                  ) : null}
                </WSGrid.Item>

                <WSGrid.Item span={{ m: "4" }}>
                  <RecentInvoicesWidget payerId={client.payerId} />
                  {/* Wire transfer section */}
                  <WireTransferSection client={client} mb="XL" />

                  {/* Documents section */}
                  {qDocuments?.data && qDocuments.data.length > 0 ? (
                    <DocumentsSection
                      isInactive={isInactive}
                      mb="XL"
                      type={DocumentsSectionType.Client}
                      payerId={match.params.clientId}
                      payeeId={userId}
                    />
                  ) : null}

                  {/* Integration mappings section */}

                  <PayerIntegrationWidget
                    isInactive={isInactive}
                    payerId={client.payerId}
                    customerId={
                      client?.payeeOwnedData?.integration?.quickbooks
                        ?.customerId
                    }
                    itemId={
                      client?.payeeOwnedData?.integration?.quickbooks?.itemId
                    }
                  />

                  {/* Autopay section */}
                  <AutoPay mb="XL" client={client} />
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
