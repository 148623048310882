import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { WSQueryCache, WSQueryConfig } from "@ws-react-query";
import { paymentsService } from "../../../services/payments";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_INVOICE } from "../../payments/keys";

export const QUERY_INVOICES_BY_IDS = "QUERY_INVOICES_BY_IDS";

export const useQueryInvoicesByIds = (
  invoicesIds: string[],
  config?: WSQueryConfig<IInvoice[], WSServiceError>
) =>
  useWSQuery<IInvoice[], WSServiceError>(
    [QUERY_INVOICES_BY_IDS, invoicesIds],
    async () => {
      const actions = invoicesIds.map(invoiceId => async () => {
        const cachedInvoiceDetail = WSQueryCache.getQueryData<IInvoice>([
          QUERY_INVOICE,
          invoiceId
        ]);

        if (cachedInvoiceDetail) {
          return cachedInvoiceDetail;
        }

        const invoiceDetails = await paymentsService.invoice.get(invoiceId);

        WSQueryCache.setQueryData([QUERY_INVOICE, invoiceId], invoiceDetails);

        return invoiceDetails;
      });

      const allInvoicesDetails = await concurrentActions(actions);

      return allInvoicesDetails;
    },
    config
  );
