import { IMemberFileResponse } from "@wingspanhq/files/dist/lib/interfaces";

export const selectorT4ADocument = (
  file: IMemberFileResponse,
  payerId: string,
  year: number
) =>
  file.tags?.includes("payer:" + payerId) &&
  file.filename.includes("T4A") &&
  file.filename.includes(String(year));
