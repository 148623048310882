import React, { useContext, useEffect, useState } from "react";
import { wsModalContext } from "./WSModal.context";
import {
  buildModalKey,
  OpenModalComponentProps,
  OptionalModalComponentProps,
  RequiredModalComponentProps
} from "./utils";

export type WSModalCustomProps<Result extends any> = {
  onClose: (props: Result) => void;
};

export type ModalCustomComponent<
  Props extends WSModalCustomProps<Result>,
  Result extends any
> = React.FC<
  Props & {
    onClose: (props: Result) => void;
  }
>;

type WithArgumentsOpen<Props extends {}, Result extends any> = (
  props: OpenModalComponentProps<Props> & {
    onClose?: (result: Result) => void;
  }
) => Promise<Result | null>;

type EmptyArgumentsOpen<Props extends {}, Result extends any> = (
  props?: OptionalModalComponentProps<Props> & {
    onClose?: (result: Result) => void;
  }
) => Promise<Result | null>;

export type OpenModalCustomCallback<Props extends {}, Result extends any> = {
  onClose: (result: Result) => void;
} extends RequiredModalComponentProps<Props>
  ? EmptyArgumentsOpen<Props, Result>
  : WithArgumentsOpen<Props, Result>;

export type UseWSModalCustomResult<Props extends {}, Result extends any> = {
  open: OpenModalCustomCallback<Props, Result>;
  close: () => void;
};

export const useWSModalCustom = <
  Props extends WSModalCustomProps<Result>,
  Result extends any
>(
  Component: ModalCustomComponent<Props, Result>
): UseWSModalCustomResult<Props, Result> => {
  const { createModal, removeModal } = useContext(wsModalContext);

  const [key] = useState(() => buildModalKey());

  useEffect(
    () => () => {
      removeModal(key);
    },
    [key, removeModal]
  );

  return {
    open: ((props = {} as Props) =>
      new Promise<Result>((resolve) => {
        createModal({
          key,
          modal: () => (
            <div>
              {/*Need <div> to make Form works*/}
              <Component {...props} onClose={resolve} />
            </div>
          )
        });
      }).then((result) => {
        props?.onClose?.(result);
        removeModal(key);
        return result;
      })) as OpenModalCustomCallback<Props, Result>,
    close: () => {
      removeModal(key);
    }
  };
};
