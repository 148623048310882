import { WSButton } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useCountersignDocument } from "../../../../../query/files/mutations";
import { getDocumentNameFromContext, NotificationTemplate } from "../index";
import {
  buildPayeeDetailsPath,
  PAYEES_DETAILS_ROUTE
} from "../../../../Payees/utils";

export type SignatureClientContext = {
  document: {
    documentId: string;
    title: string;
  };
  member: {
    memberId: string;
    fullName: string;
  };
  memberClient?: {
    memberClientId: string;
  };
  payerPayeeEngagement?: {
    payerPayeeEngagementId: string;
  };
};
export const SignatureClient: NotificationTemplate<SignatureClientContext> = {
  renderTitle: props => "E-signatures required",
  renderBody: props => {
    const memberName = props.context.member.fullName;
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return "Countersign documents that have been signed by your contractors.";
    }

    return (
      <>
        Countersign <b>{documentName}</b> that have been signed by{" "}
        <WSButton.Link
          size="S"
          onClick={() => {
            props.history?.push(
              buildPayeeDetailsPath(props.context.member.memberId)
            );
          }}
        >
          {memberName}
        </WSButton.Link>
      </>
    );
  },
  Footer: props => {
    const history = useHistory();
    const [countersign] = useCountersignDocument();
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={() => {
            history.push("/member/dashboard/clients-e-signatures");
          }}
        >
          Sign documents
        </WSButton.Link>
      );
    } else {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onAsyncClick={async () => {
            await countersign({
              documentId: props.context.document.documentId,
              payeeId: props.context.member.memberId,
              payerPayeeEngagementId:
                props.context.memberClient?.memberClientId ||
                props.context.payerPayeeEngagement?.payerPayeeEngagementId,
              notificationId: props.notificationId
            });
          }}
        >
          Sign <b>{documentName}</b>
        </WSButton.Link>
      );
    }
  }
};
