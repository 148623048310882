import {
  toWSDate,
  useModalOldContext,
  WSElement,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { PlaidReAuth } from "../../../components/PlaidReAuth/PlaidReAuth";
import {
  WSAccountsList,
  WSAccountsListProps
} from "../../../components/WSAccountsList/WSAccountsList.component";
import {
  DeleteInstitution,
  DELETE_INSTITUTION_MODAL
} from "../../screens/modals/DeleteInstitution";
import {
  EditAccount,
  EDIT_ACCOUNT_MODAL
} from "../../screens/modals/EditAccount";

export const AccountsList: React.FC<{
  accounts: IAccount[];
  onManageInstitution?: WSAccountsListProps["onManageInstitution"];
}> = ({ accounts, onManageInstitution }) => {
  const { openModal } = useModalOldContext();

  return (
    <>
      <EditAccount />
      <DeleteInstitution />
      <WSAccountsList
        accounts={accounts}
        onEditAccount={account => {
          openModal(EDIT_ACCOUNT_MODAL, account);
        }}
        onManageInstitution={onManageInstitution}
        onDeleteInstitution={institution => {
          openModal(DELETE_INSTITUTION_MODAL, institution.accounts);
        }}
        getInstitutionSubtitle={institution => {
          if (institution.isDisconnected) {
            return "Disconnected";
          } else if (institution.lastSynced) {
            return `Last synced ${toWSDate(
              new Date(institution.lastSynced),
              "timeframe"
            )}`;
          } else {
            return "Syncing...";
          }
        }}
      />
    </>
  );
};
