import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { QUERY_CUSTOMIZATION } from "../keys";
import { getCustomization } from "../../../services/customizations/getCustomization";
import { ICustomizationSettingsResponse } from "@wingspanhq/users/dist/lib/interfaces";

export const useCustomizationQuery = (
  config?: WSQueryConfig<ICustomizationSettingsResponse, WSServiceError>
) => {
  return useWSQuery<ICustomizationSettingsResponse, WSServiceError>(
    [QUERY_CUSTOMIZATION],
    getCustomization,
    {
      ...config,
      onSuccess: result => {
        window.organizationAccountConfig = result;
        config?.onSuccess?.(result);
      }
    }
  );
};
