import {
  toWSDateString,
  useModalOldContext,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { ScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import classNames from "classnames";
import React, { useMemo } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { getPayerName } from "../../../modules/Payers/utils/getPayerNames";
import { useQueryInvoicesByIds } from "../../../query/invoices/queries/useQueryInvoicesByIds";
import { usePayerQuery } from "../../../query/payers/queries/usePayerQuery";
import { useInvoiceTemplateQuery } from "../../../query/payments/queries";
import { getInvoice } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { InvoicesSeriesInvoiceEdit } from "../../screens/InvoicesSeriesInvoiceEdit";
import { RouteInvoiceDetails } from "../../screens/RouteInvoiceDetails";
import {
  SERIES_INVOICE_ACTIONS_MODAL,
  SeriesInvoiceActionsModal
} from "../SeriesInvoiceActionsModal/SeriesInvoiceActionsModal";
import styles from "./SeriesList.module.scss";

type Props = WSElementProps & {
  invoiceTemplateId: string;
};

export const SeriesList: React.FC<Props> = ({
  invoiceTemplateId,
  ...elementProps
}) => {
  const queryInvoiceTemplate = useInvoiceTemplateQuery(invoiceTemplateId);
  const queryPayer = usePayerQuery(
    queryInvoiceTemplate.data?.invoiceData.clientId!,
    {
      enabled: !!queryInvoiceTemplate.data?.invoiceData.clientId
    }
  );
  const invoiceIds = useMemo(
    () =>
      (queryInvoiceTemplate.data?.scheduleDates
        ?.map(scheduleDate => scheduleDate.invoiceId)
        .filter(Boolean) as string[]) || [],
    [queryInvoiceTemplate.data?.scheduleDates]
  );

  const queryInvoices = useQueryInvoicesByIds(invoiceIds);

  const { openModal } = useModalOldContext();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <WSElement {...elementProps}>
      <SeriesInvoiceActionsModal />

      <Route
        path={`${match.path}/:scheduleDateIndex`}
        component={InvoicesSeriesInvoiceEdit}
        exact
      />
      <Route
        path={`${match.path}/invoice/:invoiceId`}
        component={RouteInvoiceDetails}
        exact
      />
      <WSQueries
        queries={{
          queryInvoiceTemplate,
          queryPayer,
          queryInvoices
        }}
      >
        {({
          queryInvoiceTemplate: { data: invoiceTemplate },
          queryPayer: { data: payer },
          queryInvoices: { data: invoices }
        }) => {
          const payerName = getPayerName(payer);

          return (
            <>
              {(invoiceTemplate.scheduleDates || []).map(
                (scheduleDate, index) => {
                  const invoiceId =
                    scheduleDate.status === ScheduleStatus.Completed &&
                    scheduleDate.invoiceId
                      ? scheduleDate.invoiceId
                      : undefined;

                  const invoice = invoiceId
                    ? getInvoice(invoices, invoiceId)
                    : undefined;

                  return (
                    <WSElement
                      className={classNames(styles.item, {
                        [styles.completed]: scheduleDate.date < new Date()
                      })}
                      onClick={() => {
                        if (invoice) {
                          history.push({
                            pathname: `${location.pathname}/invoice/${invoice.invoiceId}`,
                            state: {
                              backPath: location.pathname
                            }
                          });
                        } else {
                          openModal(SERIES_INVOICE_ACTIONS_MODAL, {
                            parentInvoiceTemplateId: invoiceTemplateId,
                            scheduleDateIndex: index
                          });
                        }
                      }}
                      mb="XL"
                    >
                      <WSText.ParagraphSm mb="XS">
                        {scheduleDate.status === ScheduleStatus.Completed &&
                        invoice
                          ? `Due ${toWSDateString(
                              invoice.dueDate,
                              "monthDate"
                            )}`
                          : `${
                              scheduleDate.status === ScheduleStatus.Skipped
                                ? scheduleDate.date > new Date()
                                  ? "Skipping"
                                  : "Skipped"
                                : "Scheduled"
                            } ${toWSDateString(
                              scheduleDate.date,
                              "monthDate"
                            )}`}
                      </WSText.ParagraphSm>
                      <WSText weight="medium" mb="XS">
                        {payerName} – Invoice #
                        {scheduleDate.status === ScheduleStatus.Skipped
                          ? " Skipped"
                          : invoice
                          ? invoice.invoiceNumber
                          : " Scheduled"}
                      </WSText>
                      <WSText>
                        Invoice {index + 1} of{" "}
                        {invoiceTemplate.scheduleDates?.length}
                      </WSText>
                    </WSElement>
                  );
                }
              )}
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
