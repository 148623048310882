import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { ClientWorkFlowStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { addNotification } from "../../../components/Notification/Notification";
import { QUERY_PAYABLE, QUERY_PAYABLES } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { PayablesTableItemData } from "./PayablesTable";

type BulkPreApproveModalProps = {
  data: PayablesTableItemData[];
  onFinish?(): void;
};

export const PAYABLES_BULK_PREAPPROVE = "PAYABLES_BULK_PREAPPROVE";

export const BulkPreApproveModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  const [isUpdating, setIsUpdating] = useState(false);

  const bulkPreApprove = bulkActionFactory({
    onStart: () => {
      setIsUpdating(true);
    },
    action: async (item: PayablesTableItemData) => {
      const payableId = item.payable?.payableId || "";

      return await paymentsService.payable.update(payableId, {
        client: {
          workflowStatus: ClientWorkFlowStatus.PreApproved
        }
      });
    },
    onFinish: ({ successItems, failedItems }) => {
      WSQueryCache.invalidateQueries(QUERY_PAYABLES);
      WSQueryCache.invalidateQueries("PAYABLES_TO_PRE_APPROVE");
      successItems.forEach(item => {
        WSQueryCache.invalidateQueries([
          QUERY_PAYABLE,
          item.payable?.payableId
        ]);
      });

      addNotification({
        timeout: 6000,
        text:
          `${successItems.length} payable${
            successItems.length > 1 ? "s" : ""
          } have been pre-approved` +
          (failedItems.length > 0 ? `, ${failedItems.length} failed` : "")
      });

      setIsUpdating(false);
    }
  });

  return (
    <WSModalOld
      blockClose={isUpdating}
      name={PAYABLES_BULK_PREAPPROVE}
      size="S"
    >
      {({ data, onFinish }: BulkPreApproveModalProps) => (
        <>
          {data && data.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Pre-approve {data.length} payable{data.length > 1 && "s"}?
              </WSText.Heading5>
              <WSText mb="XL">Pre-approve payables to be approved</WSText>

              <WSButtons format="modal">
                <WSButton
                  loading={isUpdating}
                  onClick={async () => {
                    await bulkPreApprove.run(data);
                    onFinish?.();
                    closeModal(PAYABLES_BULK_PREAPPROVE);
                  }}
                >
                  Pre-approve
                </WSButton>
                <WSButton.Tertiary
                  disabled={isUpdating}
                  onClick={() => closeModal(PAYABLES_BULK_PREAPPROVE)}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          ) : (
            closeModal(PAYABLES_BULK_PREAPPROVE)
          )}
        </>
      )}
    </WSModalOld>
  );
};
