import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../query/onboarding/queries/useQueryVerifications";
import { useUserLocation } from "../../../../query/user";
import { useMemberProfile } from "../../../../query/users/queries";
import { FormAccountType } from "../../components/FormAccountType";
import { getProfileDataAccountType } from "../../components/FormAccountType/getProfileDataAccountType";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";

export const AccountType: React.FC<OnboardingStep> = ({ onNext }) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();
  const locationQuery = useUserLocation();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;
  const accountTypes = onboardingModule?.options?.accountTypes;
  const verificationLevel = onboardingModule?.options?.verificationLevel;

  const verificationLevelIsBanking = verificationLevel === "Banking";

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryCustomerEntity = useQueryCustomerEntity();
  const queryVerifications = useQueryVerifications();
  const verificationStatus =
    verificationLevel === "Banking"
      ? queryVerifications.data?.banking
      : queryVerifications.data?.tax;

  const defaultData = useMemo(() => {
    const profileData = getProfileDataAccountType(
      queryCustomerEntity.data,
      queryMember.data
    );

    if (!profileData.country) {
      profileData.country = locationQuery.data?.isoCode;
    }

    return profileData;
  }, [queryCustomerEntity.data, queryMember.data, locationQuery.data?.isoCode]);

  return (
    <WSList gap="2XL">
      <FormAccountType
        accountTypes={accountTypes}
        verificationLevelIsBanking={verificationLevelIsBanking}
        disabled={verificationStatus === "Verified"}
        defaultValues={context.accountType || defaultData}
        onSubmit={data => {
          setContext(prev => ({
            ...prev,
            accountType: data
          }));
          onNext?.();
        }}
        onChange={data => {
          setContext(prev => ({
            ...prev,
            accountType: data
          }));
        }}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
