import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  WSCentered,
  WSFilters,
  WSPage
} from "@wingspanhq/fe-component-library";
import { SearchPanel } from "../../../../Invoicing/components/SearchPanel";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useBrowserPageTitle } from "../../../../../components/BrowserPageTitle/BrowserPageTitle";
import { TAX_FILING_1099_FILING_DASHBOARD_PATH } from "../../../paths";
import { WSQueries } from "../../../../../query/WSQuery";
import { NEC1099FilingTable } from "../RouteDashboard/NEC1099FilingTable";
import { TableNoDataMessage } from "../RouteDashboard/TableNoDataMessage";
import {
  useAllOrganizationUsers,
  useMemberProfile
} from "../../../../../query/users/queries";
import {
  createTaxFormSubmissionListRequest,
  TaxFormSubmissionViewFilters
} from "../../../../../query/search/taxForm/queries/filtering";
import { TaxFormSubmissionRequest } from "../../../../../services/search";
import { useTaxFormSubmissionRowsQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useUrlQuery } from "../../../../../shared/hooks/useUrlQuery";

export const RouteTaxFormsSearch: React.FC = () => {
  const history = useHistory();

  const currentYear = useCurrentTaxFilingYear();
  useBrowserPageTitle(`1099 Filing Dashboard ${currentYear}`);

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const { urlQuery, setUrlQuery } = useUrlQuery<
    TaxFormSubmissionViewFilters,
    "name" | "totalAmount"
  >();

  const queryOrgAccounts = useAllOrganizationUsers();

  const enabled = !!urlQuery.filter.searchString;

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(
      currentYear,
      {
        ...urlQuery.filter
      },
      urlQuery.sort
    );
  }, [currentYear, urlQuery.filter, urlQuery.sort]);

  const queryTaxFormSubmissionRows = useTaxFormSubmissionRowsQuery(
    currentRequest,
    {
      enabled
    }
  );

  const queryTaxFormRowSearchCount = useTaxFormSubmissionRowsSummaryQuery(
    currentRequest,
    {
      enabled
    }
  );

  return (
    <WSPage>
      <SearchPanel
        mb="L"
        context="tax-filing"
        onBack={() => {
          history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
        }}
        value={urlQuery.filter.searchString || ""}
        onSearch={newValue => {
          setUrlQuery(prev => ({
            ...prev,
            filter: { ...urlQuery.filter, searchString: newValue }
          }));
        }}
        onClear={() => {
          setUrlQuery(prev => ({
            ...prev,
            filter: { ...urlQuery.filter, searchString: undefined }
          }));
        }}
        placeholder="Search tax forms"
      />

      {urlQuery.filter.searchString && (
        <WSQueries
          queries={{
            queryTaxFormSubmissionRows,
            queryOrgAccounts,
            queryMember
          }}
        >
          {({
            queryTaxFormSubmissionRowsData,
            queryOrgAccountsData,
            queryMemberData
          }) => {
            return (
              <>
                <WSFilters
                  my="L"
                  values={{}}
                  onFilter={() => {}}
                  info={{
                    count: queryTaxFormRowSearchCount.data?.listSize ?? 0
                  }}
                />
                {queryTaxFormSubmissionRowsData.length > 0 ? (
                  <NEC1099FilingTable
                    currentMember={queryMemberData}
                    queryTaxFormSubmissions={queryTaxFormSubmissionRows}
                    organizations={queryOrgAccountsData}
                    sort={urlQuery.sort}
                    onSort={(field, direction) => {
                      setUrlQuery(prev => ({
                        ...prev,
                        sort: {
                          field,
                          direction
                        }
                      }));
                    }}
                  />
                ) : (
                  <WSCentered span={{ m: "6" }}>
                    <TableNoDataMessage search={urlQuery.filter.searchString} />
                  </WSCentered>
                )}
              </>
            );
          }}
        </WSQueries>
      )}
    </WSPage>
  );
};
