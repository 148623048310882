import {
  useWSModal,
  WSActions,
  WSForm,
  WSInfoBox,
  WSInputText,
  WSInputTextarea,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";

import { useUpdateBulkPayableBatch } from "../../query/bulkPayable/mutations";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";
import { useUserId } from "../../../../query/hooks/helpers";
import { BulkPayableStatus } from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

type FormData = {
  name: string;
  notes: string;
  status: BulkPayableStatus;
};

const ModalNewPayableBatchCreated: React.FC<{
  bulkBatchId: string;
  onClose: () => void;
  onDelete: () => void;
  onAck: () => void;
}> = ({ onClose, onDelete, bulkBatchId, onAck }) => {
  const userId = useUserId();
  const [status, setStatus] = useLocalStorage(
    `${userId}:bulk-payable-status`,
    BulkPayableStatus.Draft
  );

  const [updateBulkPayableBatch, updateBulkPayableBatchMeta] =
    useUpdateBulkPayableBatch();

  const onCancel = () => {
    onClose();
    onDelete();
  };

  const handleSubmit = async (data: FormData) => {
    setStatus(data.status);
    await updateBulkPayableBatch(
      {
        id: bulkBatchId,
        data: {
          labels: {
            payableStatus: data.status,
            [BATCH_LABELS_WS_KEYS.bulkBatchName]: data.name,
            [BATCH_LABELS_WS_KEYS.bulkBatchNotes]: data.notes
          }
        }
      },
      {
        onSuccess: () => {
          onClose();
          onAck();
        }
      }
    );
  };

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm weight="book" color="gray600">
        A new batch was created and ready for your formatted spreadsheet to be
        uploaded. Select a payables status for the batch. Optionally, add a name
        and notes to identify the batch.
      </WSText.ParagraphSm>

      <WSForm<FormData>
        defaultValues={{
          name: "",
          notes: "",
          status
        }}
        onSubmit={handleSubmit}
      >
        <WSList gap="XL">
          <WSForm.Field
            name="name"
            label="Batch name (optional)"
            component={WSInputText}
            componentProps={{
              placeholder: "Enter name for the import batch"
            }}
          />
          <WSForm.Field
            name="notes"
            label="Notes (optional)"
            component={WSInputTextarea}
            componentProps={{
              placeholder: "Provide notes for your reference",
              helperText: "Provide details about the batch for your reference"
            }}
          />

          <WSInfoBox>
            <WSText.ParagraphSm weight="book" color="gray600">
              Tip: adding a name and notes can be helpful when filtering by
              batches in the contractor list and help identify the purpose of an
              imported batch for your own reference.
            </WSText.ParagraphSm>
          </WSInfoBox>

          <WSForm.Field
            name="status"
            label="Select a status for the batch"
            component={WSSelect}
            componentProps={{
              required: true,
              tooltip: `Payable statuses
                
                Draft
                  • Payables will be hidden to contractors until it is opened by you or a team member
                
                Open
                  • Contractors can see payables, but it will not be paid until it is approved by you or a team member
                
                Approved
                  • Contractors will automatically be paid on the next payroll date
                
                Paid (historical upload)
                  • Payables will be visible to contractors and marked as paid off of Wingspan
                  • Historical uploads are typically used for recording past payments. Companies looking to file Form 1099-NECs can use this option to include payments paid off of Wingspan for a given filing year.
                `,
              options: [
                {
                  value: BulkPayableStatus.Draft,
                  label: BulkPayableStatus.Draft,
                  labels: [
                    "Payables will be hidden to contractors until it is opened by you or a team member"
                  ]
                },
                {
                  value: BulkPayableStatus.Open,
                  label: BulkPayableStatus.Open,
                  labels: [
                    "Contractors can see payables, but it will not be paid until it is approved by you or a team member"
                  ]
                },
                {
                  value: BulkPayableStatus.Approved,
                  label: BulkPayableStatus.Approved,
                  labels: [
                    "Contractors will automatically be paid on the next payroll date"
                  ]
                },
                {
                  value: BulkPayableStatus.Paid,
                  label: BulkPayableStatus.Paid,
                  labels: [
                    "Payables will be visible to contractors and marked as paid off of Wingspan"
                  ]
                }
              ]
            }}
          />

          <WSInfoBox>
            <p>Reminders:</p>
            <ul>
              <li>An example template is available for download</li>
              <li>
                Only “Paid (Historical upload)” can be associated with archived
                contractors, all other statuses require the contractors to be
                active at time of import.
              </li>
              <li>
                If you created the batch by accident or are not ready to upload
                the spreadsheet yet, you can delete the batch.
              </li>
            </ul>
          </WSInfoBox>

          <WSErrorMessage
            contextKey={ErrorContextKey.BulkBatchUpdate}
            error={updateBulkPayableBatchMeta.error}
          />

          <WSForm.Values names={["status"]}>
            {({ status }) => {
              return (
                <WSActions
                  alignment="fill"
                  sortButtons={false}
                  buttons={[
                    {
                      destructive: true,
                      kind: "Secondary",
                      label: "Cancel & delete batch",
                      onClick: onCancel
                    },
                    {
                      label: "Continue",
                      type: "submit",
                      disabled: !status,
                      loading: updateBulkPayableBatchMeta.isLoading
                    }
                  ]}
                />
              );
            }}
          </WSForm.Values>
        </WSList>
      </WSForm>
    </WSList>
  );
};

export const useModalNewPayableBatchCreated = () =>
  useWSModal(ModalNewPayableBatchCreated, {
    title: "New batch created",
    size: "M",
    showCloseIcon: false
  });
