import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSSidebar,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { PublicAttachmentDownloadLink } from "../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { getPayerName } from "../../modules/Payers/utils/getPayerNames";
import { WSQueries } from "../../query/WSQuery";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import {
  useSendInvoiceTemplate,
  useSendNowInvoiceTemplate
} from "../../query/payments/mutations";
import { useInvoiceTemplateQuery } from "../../query/payments/queries";
import { useSetWSStore } from "../../store";
import { isToday } from "../../utils/dates";
import { InvoiceCollaborators } from "../components/InvoiceCollaborators";
import { InvoiceDetailsHeader } from "../components/InvoiceDetailsHeader/InvoiceDetailsHeader";
import { InvoiceTemplateTotals } from "../components/InvoiceTempalteTotals";
import { SeriesList } from "../components/SeriesList/SeriesList";
import {
  calculateInvoiceTempalteDueDate,
  calculateLineItemsTotal
} from "../utils";
import styles from "./InvoicesInvoiceDetails.module.scss";

type Props = RouteComponentProps<{
  invoiceTemplateId: string;
  listName?: string;
}>;

export const RouteInvoiceTemplateDetails: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const location = useLocation<{ backPath?: string }>();

  const onBack = () => {
    const newPath = location.state?.backPath
      ? location.state?.backPath
      : location.pathname.includes("series")
      ? "/member/invoices/scheduled"
      : `/member/invoices${
          match.params.listName ? `/${match.params.listName}` : ""
        }`;

    history.push({
      pathname: newPath,
      search: location.search
    });
  };

  return (
    <WSSidebar onClose={onBack} header={"Invoice # Scheduled"}>
      <Inner
        invoiceTemplateId={match.params.invoiceTemplateId}
        onBack={onBack}
      />
    </WSSidebar>
  );
};

type InnerProps = {
  invoiceTemplateId: string;
  onBack: () => void;
};

const Inner: React.FC<InnerProps> = ({ invoiceTemplateId }) => {
  const history = useHistory();

  const [detailsVisible, setDetailsVisible] = useState(true);
  const [seriesVisible, setSeriesVisible] = useState(false);

  const queryInvoiceTemplate = useInvoiceTemplateQuery(invoiceTemplateId);
  const queryPayer = usePayerQuery(
    queryInvoiceTemplate.data?.invoiceData.clientId!,
    {
      enabled: !!queryInvoiceTemplate.data?.invoiceData.clientId
    }
  );

  const [sendInvoiceTemplate, sendInvoiceTemplateMeta] =
    useSendInvoiceTemplate();
  const [sendNowInvoiceTemplate, sendNowInvoiceTemplateMeta] =
    useSendNowInvoiceTemplate();
  const location = useLocation();
  const setStore = useSetWSStore();

  return (
    <WSElement className={styles.wrapper}>
      <WSQueries queries={{ queryInvoiceTemplate, queryPayer }}>
        {({
          queryInvoiceTemplate: { data: invoiceTemplate },
          queryPayer: { data: payer }
        }) => {
          const dueDate = calculateInvoiceTempalteDueDate(invoiceTemplate);

          const sendDateInFuture =
            invoiceTemplate.scheduleDates?.[0]?.date &&
            invoiceTemplate.scheduleDates[0].date > new Date() &&
            !isToday(invoiceTemplate.scheduleDates[0].date);

          const payerName = getPayerName(payer);

          return (
            <>
              <WSElement className={styles.main}>
                <Card mb="XL">
                  <WSText.Heading4 mb="M">{payerName}</WSText.Heading4>
                  {invoiceTemplate.status ===
                    FrequencyAndScheduleStatus.Active &&
                  invoiceTemplate.frequency ? (
                    <InvoiceDetailsHeader
                      title="Invoice is scheduled"
                      description={`${
                        invoiceTemplate.scheduleDates?.[0]?.status ===
                        ScheduleStatus.Completed
                          ? "Next"
                          : "First"
                      } invoice will be sent ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.status ===
                          ScheduleStatus.Completed
                          ? invoiceTemplate.nextInvoiceDate
                          : invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`}
                      buttonProps={{
                        children: "Edit series",
                        onClick: () => {
                          setStore({ invoiceFormBackPath: location.pathname });
                          history.push(
                            `/member/invoices/template/${invoiceTemplateId}/edit`
                          );
                        }
                      }}
                      invoiceTemplate={invoiceTemplate}
                    />
                  ) : invoiceTemplate.status ===
                    FrequencyAndScheduleStatus.Active ? (
                    <InvoiceDetailsHeader
                      title="Invoice is scheduled"
                      description={`Send date is ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`}
                      buttonProps={{
                        children: "Send now",
                        onClick: () => {
                          sendNowInvoiceTemplate({ invoiceTemplateId });
                        },
                        loading: sendNowInvoiceTemplateMeta.isLoading
                      }}
                      invoiceTemplate={invoiceTemplate}
                    />
                  ) : (
                    <InvoiceDetailsHeader
                      title="This is a draft"
                      description="Send now or schedule for later"
                      buttonProps={{
                        children: sendDateInFuture ? "Schedule" : "Send now",
                        onClick: () => {
                          if (sendDateInFuture) {
                            sendInvoiceTemplate({ invoiceTemplateId });
                          } else {
                            sendNowInvoiceTemplate({ invoiceTemplateId });
                          }
                        },
                        loading:
                          sendInvoiceTemplateMeta.isLoading ||
                          sendNowInvoiceTemplateMeta.isLoading
                      }}
                      invoiceTemplate={invoiceTemplate}
                    />
                  )}
                </Card>

                <Card mb="XL">
                  <WSFlexBox wrap="nowrap" justify="space-between">
                    <WSButton.Link
                      onClick={() => {
                        setDetailsVisible(!detailsVisible);
                      }}
                      rightIcon={detailsVisible ? "caret-up" : "caret-down"}
                    >
                      {detailsVisible ? "Hide" : "Show"} invoice details
                    </WSButton.Link>
                    <WSText.Heading5>
                      {toWSMoneyString(
                        calculateLineItemsTotal(
                          invoiceTemplate.invoiceData.lineItems
                        ),
                        "default",
                        invoiceTemplate.invoiceData.currency
                      )}
                    </WSText.Heading5>
                  </WSFlexBox>
                  {detailsVisible && (
                    <WSElement mt="XL">
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Bill to
                        </WSText.ParagraphSm>
                        <WSText>{payerName}</WSText>
                        <WSText>
                          {[
                            payer.user?.email,
                            ...(payer.payeeOwnedData.payerEmailCC || [])
                          ].join(", ")}
                        </WSText>
                      </WSElement>

                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Due date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(dueDate, "monthDayYear")}
                        </WSText>
                      </WSElement>

                      <InvoiceTemplateTotals
                        invoiceTemplate={invoiceTemplate}
                      />

                      {invoiceTemplate.invoiceData.attachments
                        ?.customAttachmentIds && (
                        <WSElement mt="XL">
                          {invoiceTemplate.invoiceData.attachments?.customAttachmentIds.map(
                            id => (
                              <PublicAttachmentDownloadLink id={id} mb="M" />
                            )
                          )}
                        </WSElement>
                      )}

                      {invoiceTemplate.invoiceData.collaborators &&
                        invoiceTemplate.invoiceData.collaborators.length >
                          0 && (
                          <WSElement mt="3XL">
                            <WSText.Heading5 mb="M">
                              Contractors
                            </WSText.Heading5>
                            <InvoiceCollaborators
                              invoice={invoiceTemplate.invoiceData}
                            />
                          </WSElement>
                        )}
                    </WSElement>
                  )}
                </Card>

                <Card>
                  <WSButton.Link
                    key={seriesVisible ? "Hide" : "Show"}
                    onClick={() => {
                      setSeriesVisible(v => !v);
                    }}
                    rightIcon={seriesVisible ? "caret-up" : "caret-down"}
                  >
                    {seriesVisible ? "Hide" : "Show"} series details
                  </WSButton.Link>
                  {seriesVisible && (
                    <SeriesList invoiceTemplateId={invoiceTemplateId} mt="XL" />
                  )}
                </Card>
              </WSElement>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
