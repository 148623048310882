import {
  PendingTransactionSubType,
  PendingWithholdingSubType,
  WithholdingTransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { AccountStatus, Currency } from "@wingspanhq/users/dist/lib/interfaces";
import {
  QUERY_BOOKKEEPING_BANKING_BALANCE,
  QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS
} from "../../../../query/bookkeeping/keys";
import { useWSMutation, WSMutationConfig } from "../../../../query/helpers";
import {
  QUERY_TAXES_PENDING_WITHHOLDING_LIST,
  QUERY_TAXES_WITHHOLDING_BALANCE,
  QUERY_TAXES_WITHHOLDING_TRANSACTIONS
} from "../../../../query/taxes/keys";
import { QUERY_USERS_ACCOUNTS } from "../../../../query/users/keys";
import bookkeepingService from "../../../../services/bookkeeping";
import { usersService } from "../../../../services/users";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { FormData } from "../../types";
import { useModalReconnectAccount } from "./ModalReconnectAccount";
import { InternalAccountType } from "../../../../services/api/banking/types";

export const useMutationTransfer = (
  config?: WSMutationConfig<void, WSServiceError, FormData>
) => {
  const modalReconnectAccount = useModalReconnectAccount();

  return useWSMutation<void, WSServiceError, FormData>(
    async ({ balance, type, amount, methodId }) => {
      if (methodId !== "wallet" && methodId !== "taxWithholdings") {
        const account = await usersService.account.get(methodId);

        const shouldRecconnect =
          type === "deposit" &&
          account.status === AccountStatus.Disconnected &&
          !!account.publicToken;

        if (shouldRecconnect) {
          const isReconnected = await modalReconnectAccount.open({
            token: account.publicToken || ""
          });

          if (!isReconnected) return;
        }
      }

      if (balance === "wallet") {
        await bookkeepingService.createPendingBankingTransaction({
          amount,
          type: InternalAccountType.Banking,
          subType:
            type === "deposit"
              ? PendingTransactionSubType.Deposit
              : PendingTransactionSubType.Withdraw,
          currency: Currency.USD,
          accountId: methodId === "taxWithholdings" ? undefined : methodId,
          transactionPartyType:
            methodId === "taxWithholdings"
              ? InternalAccountType.TaxWithholding
              : undefined
        });
      } else {
        await bookkeepingService.createPendingWithholding({
          amount,
          type:
            methodId === "wallet"
              ? WithholdingTransactionType.BookTransfer
              : WithholdingTransactionType.TaxWithholding,
          subType:
            type === "deposit"
              ? PendingWithholdingSubType.Deposit
              : PendingWithholdingSubType.Withdraw,
          currency: Currency.USD,
          accountId: methodId === "wallet" ? undefined : methodId,
          transactionPartyType:
            methodId === "wallet" ? InternalAccountType.Banking : undefined
        });
      }
    },
    {
      dependencies: [
        QUERY_BOOKKEEPING_BANKING_BALANCE,
        QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
        QUERY_TAXES_WITHHOLDING_BALANCE,
        QUERY_TAXES_WITHHOLDING_TRANSACTIONS,
        QUERY_TAXES_PENDING_WITHHOLDING_LIST,
        QUERY_USERS_ACCOUNTS
      ],
      ...config
    }
  );
};
