import {
  detachLayoutProps,
  WSButton,
  WSMarginProps
} from "@wingspanhq/fe-component-library";
import { CSVLink } from "react-csv";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";

type Props = WSMarginProps & {
  fullWidth?: boolean;
};

export function useDownloadPayableExampleTemplate() {
  const queryLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const downloadExamplePayables = [
    {
      email: "wade@wignspan.app",
      contractorId: "",
      amount: "$100.50",
      lineItemTitle: "Line item title",
      lineItemDescription: "Website updates & copy",
      invoiceNotes: "Website Re-brand Project",
      dueDate: "2025-12-01",
      paidDate: "",
      attachmentId: "",
      paymentRefId: "",
      ...(queryLineItemCustomFields.data?.reduce(
        (acc: Record<string, string>, field) => {
          acc[field.name] = "";
          return acc;
        },
        {}
      ) || {})
    }
  ];
  const csvProps = {
    filename: `Bulk_Upload_Payables_Template.csv`,
    headers: [
      { label: "Email", key: "email" },
      { label: "External Contractor ID", key: "contractorId" },
      { label: "Amount (USD)", key: "amount" },
      { label: "Line item title", key: "lineItemTitle" },
      { label: "Line item description", key: "lineItemDescription" },
      { label: "Invoice notes", key: "invoiceNotes" },
      { label: "Due date", key: "dueDate" },
      { label: "Paid date", key: "paidDate" },
      { label: "Attachment ID", key: "attachmentId" },
      { label: "Payment reference ID", key: "paymentRefId" },
      ...(queryLineItemCustomFields.data?.map(field => ({
        label: field.name,
        key: field.name
      })) || [])
    ],
    data: downloadExamplePayables
  };

  return { csvProps, queryLineItemCustomFields };
}

export const DownloadPayableExampleTemplateButton: React.FC<Props> = ({
  fullWidth = true,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  const { csvProps, queryLineItemCustomFields } =
    useDownloadPayableExampleTemplate();
  return (
    <CSVLink {...csvProps}>
      <WSButton.Secondary
        disabled={queryLineItemCustomFields.isLoading}
        fullWidth={fullWidth}
        icon="download"
        {...layoutProps}
      >
        Download example
      </WSButton.Secondary>
    </CSVLink>
  );
};
