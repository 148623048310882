import { WSQueryConfig } from "@ws-react-query";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_PAYEE } from "../keys";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";

export const usePayeeTaxForm = (
  taxFormId: string,
  queryConfig?: WSQueryConfig<IPayeeTaxFormResponse, WSServiceError>
) =>
  useWSQuery<IPayeeTaxFormResponse, WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_PAYEE, taxFormId],
    () => paymentsService.taxForm.get(taxFormId),
    queryConfig
  );
