import {
  useIsMobile,
  useModalOldContext,
  WSDivider,
  WSInfiniteScroll,
  WSTable,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import flatten from "lodash/flatten";
import React, { useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { CollaboratorGroupDetails } from "./CollaboratorGroupDetails";
import { DeleteCollaboratorGroupModal } from "../../components/DeleteCollaboratorGroupModal";
import {
  UPDATE_COLLABORATOR_GROUP_MODAL,
  UpdateCollaboratorGroupModal
} from "../../components/UpdateCollaboratorGroupModal";
import { useAllCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams
} from "../../../../utils/tableSortingHelpers";
import { WSPersistentUpgradeButton } from "../../../../components/Membership/WSPersistentUpgradeButton";
import { WSFrontendFeature } from "../../../../Settings/utils/subscriptionUtils";
import {
  buildGroupDetailsPath,
  PAYEES_ENGAGEMENTS_DETAILS_ROUTE
} from "../../utils";
import { useAddCollaboratorGroupModal } from "../../components/AddCollaboratorGroupModal";

export const RouteGroups: React.FC = () => {
  const { openModal } = useModalOldContext();
  const modalAddCollaboratorGroup = useAddCollaboratorGroupModal();

  const isMobile = useIsMobile();
  const history = useHistory();
  const [sortParams, setSortParams] = useState<
    Parameters<typeof useAllCollaboratorGroupsQuery>[0]
  >({ updatedAt: "desc" } as const);
  const qGroups = useAllCollaboratorGroupsQuery(sortParams);

  const groups = flatten(qGroups.data?.pages || []);

  const tableData = groups.map(group => {
    return {
      data: {
        name: group.name,
        description: group.description
      },
      id: group.collaboratorGroupId
    };
  });

  const onNameSort = () => {
    setSortParams(prevState => ({
      name: mapAndRevertTableSortToParams(prevState?.name)
    }));
  };

  const onDescriptionSort = () => {
    setSortParams(prevState => ({
      description: mapAndRevertTableSortToParams(prevState?.description)
    }));
  };

  const columns: Array<WSTableColumn> = [
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        sortDirection: mapAndRevertParamsSortToTable(sortParams.name),
        onColumnSort: onNameSort,
        header: { text: "Name" }
      },
      renderFunction: row => (
        <WSText.ParagraphSm color="gray600" singleLine>
          {row.data.name}
        </WSText.ParagraphSm>
      )
    },
    {
      config: {
        gridTemplateSizeMax: "1fr",
        sortDirection: mapAndRevertParamsSortToTable(sortParams.description),
        onColumnSort: onDescriptionSort,
        header: { text: "Description" }
      },
      renderFunction: row => (
        <WSText.ParagraphSm color="gray600" singleLine>
          {row.data.description}
        </WSText.ParagraphSm>
      )
    }
  ];

  return (
    <>
      <Route
        path={PAYEES_ENGAGEMENTS_DETAILS_ROUTE}
        component={CollaboratorGroupDetails}
      />
      <UpdateCollaboratorGroupModal />
      <DeleteCollaboratorGroupModal />
      <>
        {isMobile && <WSDivider type="expand" my="XL" />}

        <WSInfiniteScroll
          onLoad={() => {
            qGroups.fetchNextPage();
          }}
          loadMore={groups.length > 0}
          endOfList={!qGroups.hasNextPage}
          loading={!!qGroups.isFetchingNextPage}
        >
          <WSTable
            mt="2XL"
            bottomMessage={
              qGroups.isFetched && tableData.length === 0 ? (
                <WSText>
                  No groups yet.{" "}
                  <WSPersistentUpgradeButton
                    feature={WSFrontendFeature.InviteAndPayCollaborator}
                    kind="Link"
                    onClick={() => {
                      modalAddCollaboratorGroup.open();
                    }}
                  >
                    Add group
                  </WSPersistentUpgradeButton>
                </WSText>
              ) : (
                ""
              )
            }
            rowMenuActions={rowItem => [
              {
                label: "Edit",
                onClick: () => {
                  openModal(UPDATE_COLLABORATOR_GROUP_MODAL, {
                    id: rowItem.id,
                    name: rowItem.data.name,
                    description: rowItem.data.description
                  });
                }
              }
              // {
              //   label: "Delete",
              //   onClick: () => {
              //     openModal(DELETE_COLLABORATOR_GROUP_MODAL, {
              //       id: rowItem.id,
              //       name: rowItem.data.name
              //     });
              //   }
              // }
            ]}
            columns={columns}
            tableData={tableData}
            onRowClick={({ id }) => {
              history.push(buildGroupDetailsPath(id));
            }}
          />
        </WSInfiniteScroll>
      </>
    </>
  );
};
