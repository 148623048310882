import { WSPage } from "@wingspanhq/fe-component-library";
import { Redirect, Route, Switch } from "react-router-dom";
import { PATH_SETTINGS } from "../..";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { RouteAddPaymentMethod } from "../RoutePaymentMethods/routes/RouteAddPaymentMethod";
import { RouteAddPayoutMethod } from "./routes/RouteAddPayoutMethod";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteManagePaymentMethod } from "./routes/RouteManagePaymentMethod";
import { RouteManagePayoutMethod } from "./routes/RouteManagePayoutMethod";

export const PATH_SETTINGS_PAYMENT_METHODS = PATH_SETTINGS + "/payment-methods";

export const PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/manage-payout-method";
export const PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/add-payout-method";

export const PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/manage-payment-method";
export const PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/add-payment-method";

export const RoutePaymentMethods: React.FC = () => {
  const queryFeatureFlag = useFeatureFlags();
  return (
    <WSPage>
      <Switch>
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD}
          component={RouteManagePaymentMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD}
          component={RouteAddPaymentMethod}
        />

        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYOUT_METHOD}
          component={RouteManagePayoutMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYOUT_METHOD}
          component={RouteAddPayoutMethod}
        />

        <Route path={PATH_SETTINGS_PAYMENT_METHODS} component={RouteIndex} />
        <Redirect to={PATH_SETTINGS_PAYMENT_METHODS} />
      </Switch>
    </WSPage>
  );
};
