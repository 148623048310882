import {
  toWSDateString,
  toWSMoneyString,
  useModalOldContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  IPayableSchema,
  IPayeeResponse
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { getPayeeName } from "../../../modules/Payees/selectors/getPayeeNames";
import {
  useCancelPayable,
  useDeletePayable
} from "../../../query/payments/mutations";
import { calculateLineItemsTotal } from "../../utils";

type Props = WSElementProps;

export const DELETE_PAYABLE_MODAL = "DELETE_PAYABLE_MODAL";

export const DeletePayableModal: React.FC<Props> = () => {
  const { closeModal } = useModalOldContext();
  const history = useHistory();
  const [deletePayable, deletePayableMeta] = useDeletePayable();
  const [cancelPayable, cancelPayableMeta] = useCancelPayable();

  return (
    <WSModalOld name={DELETE_PAYABLE_MODAL} size="M">
      {({
        payable,
        payee,
        listName
      }: {
        payable?: IPayableSchema;
        payee?: IPayeeResponse;
        listName?: "paid";
      }) => {
        const isCancel = payable
          ? [
              InvoiceStatus.Pending,
              InvoiceStatus.Open,
              InvoiceStatus.Overdue
            ].includes(payable.status)
          : false;

        const toText = payee ? ` to ${getPayeeName(payee)}` : "";

        const onSuccess = () => {
          closeModal(DELETE_PAYABLE_MODAL);
          history.push(
            `/member/invoices/payables${listName ? `/${listName}` : ""}`
          );
        };

        const dueDate = payable ? payable.dueDate : new Date();

        const amount = payable ? calculateLineItemsTotal(payable.lineItems) : 0;

        return (
          <>
            <WSText.Heading5 mb="M">
              {isCancel ? "Cancel" : "Delete"} invoice
              {toText}
            </WSText.Heading5>
            <WSText mb="M">
              Are you sure you want to {isCancel ? "cancel" : "delete"} your{" "}
              {toWSDateString(dueDate, "monthDayYear")} invoice
              {toText} for {toWSMoneyString(amount)}?
            </WSText>
            <WSText mb="XL">
              This action can't be undone, once you{" "}
              {isCancel ? "cancel" : "delete"} an invoice you'll have to
              recreate it.
            </WSText>

            <WSButtons format="modal">
              <WSButton
                destructive
                name="delete"
                onClick={() => {
                  if (payable) {
                    if (isCancel) {
                      cancelPayable(
                        { payableId: payable.payableId },
                        { onSuccess }
                      );
                    } else {
                      deletePayable(
                        { payableId: payable.payableId },
                        { onSuccess }
                      );
                    }
                  }
                }}
                loading={
                  cancelPayableMeta.isLoading || deletePayableMeta.isLoading
                }
              >
                {isCancel ? "Cancel" : "Delete"} invoice
              </WSButton>
              <WSButton.Secondary
                onClick={() => {
                  closeModal(DELETE_PAYABLE_MODAL);
                }}
              >
                Keep invoice
              </WSButton.Secondary>
            </WSButtons>
          </>
        );
      }}
    </WSModalOld>
  );
};
