import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "./keys";

export function removeNotificationFromCache(notificationId: string) {
  const notificationsCache = WSQueryCache.getQueryData<
    IChannelNotificationResponse[]
  >(QUERY_NOTIFICATIONS_NOTIFICATIONS);

  if (notificationsCache) {
    WSQueryCache.setQueryData(
      QUERY_NOTIFICATIONS_NOTIFICATIONS,
      notificationsCache.filter(
        notification => notification.notificationId !== notificationId
      )
    );
  }
}
