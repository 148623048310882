import {
  WSButton,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { IBulkCalculation1099Batch } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../../services/platform";
import { Main1099LayoutModule } from "../../../components/Main1099Layout/Main1099Layout.module";

import { formatNumber } from "accounting";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { useBulkCalculate1099Batch } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import styles from "./styles.module.scss";

export const RouteGenerateForms: React.FC<SetupRouteComponentProps> = ({
  onNext
}) => {
  const [isBatchFetchingFailed, setIsBatchFetchingFailed] = useState(false);
  const [totalForms, setTotalForms] = useState<number>(1);
  const match = useRouteMatch<{ bulkCalculation1099BatchId: string }>();

  const bulkCalculation1099BatchId = match.params.bulkCalculation1099BatchId;

  const bulkCalculation1099BatchQuery = useBulkCalculate1099Batch(
    bulkCalculation1099BatchId,
    {
      enabled: !isBatchFetchingFailed,
      refetchInterval: 3000
    }
  );

  const bulkCalculation1099Batch =
    bulkCalculation1099BatchQuery.data as IBulkCalculation1099Batch;
  const isGenerationCompleted = bulkCalculation1099Batch
    ? [BulkStatus.Complete, BulkStatus.Failed].includes(
        bulkCalculation1099Batch?.status
      )
    : false;

  useEffect(() => {
    // stop polling if get batch fails
    if (bulkCalculation1099BatchQuery.isError) {
      setIsBatchFetchingFailed(true);
    }
  }, [isGenerationCompleted, bulkCalculation1099BatchQuery.isError]);

  const statusProgressMessageMap = {
    [BulkStatus.Open]: "Creating forms..",
    [BulkStatus.Pending]: "Creating forms..",
    [BulkStatus.Processing]: `Calculating 1099 balances for ${formatNumber(
      totalForms
    )} forms`,
    [BulkStatus.Complete]: `${formatNumber(totalForms)} forms generated`,
    [BulkStatus.Failed]: "There was an error while generating some forms"
  };

  let progressText = statusProgressMessageMap[bulkCalculation1099Batch?.status];

  return (
    <Main1099LayoutModule title="Generate 1099 Forms">
      <WSPanel>
        <WSText.Heading5 mb="2XL">
          Wingspan is generating your 1099 forms.
        </WSText.Heading5>

        <WSFlexBox.CenterY mb="2XL">
          {isGenerationCompleted ? (
            <WSIcon block name="report" size="M" color="blue400" />
          ) : (
            <WSLoader.Spinner />
          )}
          <WSText ml="M" color="gray600">
            {progressText}
          </WSText>
        </WSFlexBox.CenterY>
        {!isGenerationCompleted ? (
          <WSText.ParagraphSm color="gray700">
            Your forms will be ready soon! Please don't close or refresh this
            page. This may take a few minutes if you have a large number of
            forms to generate.
          </WSText.ParagraphSm>
        ) : null}
        <WSErrorMessage
          mt="XL"
          contextKey={ErrorContextKey.NEC1099BulkCalculate1099}
          error={bulkCalculation1099BatchQuery.error}
        />
        <WSButton.Primary
          mt="2XL"
          className={styles.actionBtn}
          fullWidth
          name="continueToNECFilingDashboard"
          onClick={onNext}
          disabled={!isGenerationCompleted}
        >
          Continue to 1099 Filing Dashboard
        </WSButton.Primary>
      </WSPanel>
    </Main1099LayoutModule>
  );
};
