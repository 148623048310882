import classNames from "classnames";
import React, { useMemo } from "react";
import { WSActions, WSActionsProps } from "../WSActions/WSActions";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import styles from "./WSCard.module.scss";
import { WSCardHeader, WSCardHeaderProps } from "./WSCardHeader";

export type WSCardProps = {
  header?: WSCardHeaderProps | string;
  actions?: WSActionsProps;
  divider?: boolean;
  active?: boolean;
  fullHeight?: boolean;
} & WSElementProps;

export const WSCard: React.FC<WSCardProps> = ({
  header,
  actions,
  divider,
  children,
  className,
  fullHeight,
  active,
  ...elementProps
}) => {
  const headerProps = useMemo(() => {
    if (typeof header === "string") {
      return {
        label: header
      };
    }

    return header;
  }, [header]);

  return (
    <WSElement
      className={classNames(
        styles.card,
        className,
        divider && styles.divider,
        active && styles.active,
        !!elementProps.onClick && styles.clickable,
        !!fullHeight && styles.fullHeight
      )}
      tabIndex={elementProps.onClick ? 1 : -1}
      {...elementProps}
    >
      {header && <WSCardHeader className={styles.header} {...headerProps} />}
      {children && <WSElement className={styles.content}>{children}</WSElement>}
      {fullHeight && <WSElement className={styles.spacer} />}
      {actions && <WSActions className={styles.actions} {...actions} />}
    </WSElement>
  );
};

export * from "./WSCardHeader";
export * from "./WSCardHeaderValue";
