import { useCardsQuery } from "../../../query/cards/queries/useCardsQuery";

const getLast4Digits = (card: { mask?: string } | { last4Digits?: string }) => {
  if ("last4Digits" in card && card.last4Digits) {
    return card.last4Digits;
  }

  if ("mask" in card && card.mask) {
    return card.mask;
  }

  return undefined;
};

export const useCards = () => {
  const cardsQuery = useCardsQuery();

  const query = cardsQuery;

  return (query.data || []).map(card => ({
    cardId: card.cardId,
    last4Digits: getLast4Digits(card)
  }));
};
