import * as Yup from "yup";

const today = new Date();
const min = new Date(
  today.getFullYear() - 150,
  today.getMonth(),
  today.getDate()
);
const max = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate() + 1
);

export const validatorDob = Yup.date()
  .nullable()
  .min(min, "Invalid date (too far in the past)")
  .max(max, "All users must be at least 18 years old")
  .nullable()
  .default(undefined);
