import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import plaidIcon from "../../../../assets/images/plaid-icon.png";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useCreateAccount } from "../../../../query/users/mutations";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { FormType } from "../../../../shared/components/FormManuallyAddAccount/FormType";
import {
  ManualAccountSubType,
  ManualAccountType
} from "../../../../shared/components/FormManuallyAddAccount/types";
import { StepLayoutWithOptions } from "../../../../shared/components/Layout/StepLayoutWithOptions";
import {
  avatarBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { useMemberPlaidLink } from "../../../../utils/usePlaidLink";
import { FormCreateInternalAccount } from "../../../Onboarding/components/FormCreateInternalAccount";
import { selectorAccountDepositReady } from "../../../Transfer/components/FormTransfer/selectorAccountDepositReady";
import { FormSelectAccount } from "../FormSelectAccount";

export type PayrollFundingMethodType = "ach" | "wallet-usd" | "wallet-cad";
export type PayrollFundingMethodACHType = "plaid" | "manual";

type Props = { basePath: string; onBack?: () => void; onNext?: () => void };

export const FlowAddFundingMethod: React.FC<Props> = ({
  basePath,
  onBack,
  onNext
}) => {
  const history = useHistory();
  const [createAccount] = useCreateAccount();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);

  const queryFeatureFlags = useFeatureFlags();

  const { openSnackbar } = useWSSnackbar();

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      const filteredAccounts = accounts.filter(selectorAccountDepositReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/accounts");

        return {
          success: true,
          data: accounts
        };
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payroll funding method"
        });

        return {
          success: false
        };
      }
    }
  });

  const [manualAccountType, setManualAccountType] = useState<{
    type: ManualAccountType;
    subType: ManualAccountSubType;
  }>({
    type: "personal",
    subType: "checking"
  });

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepLayoutWithOptions<PayrollFundingMethodType>
          key="type"
          title="Add payroll funding method"
          description="Add a payroll funding method to fund your payrolls and start sending payments"
          options={
            queryFeatureFlags.data?.prefundingMethods
              ? [
                  {
                    header: {
                      label: {
                        text: "Bank account (USD)",
                        helperText: "Connect your bank account securely",
                        avatar: avatarBankAccount
                      }
                    },
                    value: "ach"
                  },
                  {
                    header: {
                      label: {
                        text: "Wingspan Wallet pre-funding (USD)",
                        helperText:
                          "Pre-fund and send payments with a free business banking account",
                        avatar: avatarWallet
                      }
                    },
                    value: "wallet-usd"
                  },
                  {
                    header: {
                      label: {
                        text: "Wingspan Wallet pre-funding (CAD)",
                        helperText:
                          "Pre-fund and send payments in Canadian Dollars",
                        avatar: {
                          format: "square",
                          type: "country",
                          country: "Canada"
                        }
                      }
                    },
                    value: "wallet-cad"
                  }
                ]
              : [
                  {
                    header: {
                      label: {
                        text: "Bank account (USD)",
                        helperText: "Connect your bank account securely",
                        avatar: avatarBankAccount
                      }
                    },
                    value: "ach"
                  }
                ]
          }
          onBack={onBack}
          onNext={value => {
            history.push(basePath + "/" + value);
          }}
        />
      </Route>

      <Route path={basePath + "/ach"}>
        <StepLayoutWithOptions<PayrollFundingMethodACHType>
          key="ach"
          title="Add bank account"
          description="Add a bank account as your default payroll funding method"
          options={[
            {
              header: {
                label: {
                  text: "Link account with Plaid",
                  helperText:
                    "Instantly verify and connect your bank accounts securely",
                  avatar: {
                    format: "square",
                    type: "image",
                    image: plaidIcon
                  }
                }
              },
              value: "plaid"
            },
            {
              header: {
                label: {
                  text: "Set up account manually",
                  helperText: "Manually enter your routing and account numbers",
                  avatar: avatarBankAccount
                }
              },
              value: "manual"
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={value => {
            if (value === "plaid") {
              plaidHandler.open();
            } else {
              history.push(basePath + "/manual-type");
            }
          }}
        />
      </Route>

      <Route path={basePath + "/wallet-usd"}>
        <FormCreateInternalAccount
          type="Banking"
          currency="USD"
          setFundingSource
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={onNext}
        />
      </Route>

      <Route path={basePath + "/wallet-cad"}>
        <FormCreateInternalAccount
          type="InternationalClearing"
          currency="CAD"
          setFundingSource
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={onNext}
        />
      </Route>

      <Route path={basePath + "/accounts"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your default payroll funding method"
          setFundingSource
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/ach");
          }}
          onNext={onNext}
        />
      </Route>

      <Route path={basePath + "/manual-type"}>
        <FormType
          value={manualAccountType}
          onBack={() => {
            history.push(basePath + "/ach");
          }}
          onNext={newValue => {
            setManualAccountType(newValue);
            history.push(basePath + "/manual");
          }}
        />
      </Route>

      <Route path={basePath + "/manual"}>
        <FormManuallyAddAccount
          setFundingSource
          requestVerification
          onBack={() => {
            history.push(basePath + "/manual-type");
          }}
          onSuccess={onNext}
          withPanel
          type={manualAccountType.type}
          subType={manualAccountType.subType}
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
