import {
  IEngagement,
  IEngagementCreateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { createEngagement } from "../../../services/engagements";
import { QUERY_ENGAGEMENT_LIST, QUERY_ENGAGEMENT_LIST_SIZE } from "../keys";

export const useCreateEngagement = (
  config?: WSMutationConfig<IEngagement, WSServiceError>
) =>
  useWSMutation(
    (request: IEngagementCreateRequest) => createEngagement(request),
    {
      ...config,
      dependencies: [QUERY_ENGAGEMENT_LIST, QUERY_ENGAGEMENT_LIST_SIZE]
    }
  );
