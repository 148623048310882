import { WSButton, WSModalOld, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { WS_LINKS } from "../../../types/wsLinks";
import { openInNewTab } from "../../../utils/openInNewTab";

export const MODAL_WHAT_IS_US_PERSON = "MODAL_WHAT_IS_US_PERSON";

export const ModalWhatIsUSPerson: React.FC = () => (
  <WSModalOld
    name={MODAL_WHAT_IS_US_PERSON}
    size="S"
    title="What is a United States person?"
  >
    <WSText mb="M">
      A US persons is a legal classification of a taxpayer for tax purposes. If
      you’re a citizen or resident of the United States, you are a US persons.
    </WSText>
    <WSButton.Link
      onClick={() => {
        openInNewTab(WS_LINKS.taxpayersClassification);
      }}
      type="button"
    >
      Learn more
    </WSButton.Link>
  </WSModalOld>
);
