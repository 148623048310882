import { IProfitAndLoss } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  useModalOldContext,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { VerticalDivider } from "../../../shared/components/VerticalDivider";
import {
  BOOKKEEPING_ESTIMATED_TAXES_MODAL,
  EstimatedTaxesModal
} from "./EstimatedTaxesModal.component";
import styles from "./ProfitAndLoss.module.scss";

interface LineItemProps {
  label: string;
  value: string;
  secondValue: number;
  isLoading?: boolean;
}

const LineItem: React.FC<LineItemProps> = ({
  label,
  value,
  isLoading,
  secondValue
}) => {
  return (
    <WSFlexBox.Center justify="flex-end" mb="XS">
      <WSText style={{ flex: 1 }} color="gray600">
        {label}
      </WSText>
      <WSText shimmer={isLoading} color="gray600" tabularNums>
        {value}
      </WSText>
      <VerticalDivider py="M" mx="M" />
      <WSText
        align="right"
        shimmer={isLoading}
        color="gray600"
        tabularNums
        formatMoney
      >
        {secondValue > 0 ? secondValue : 0}
      </WSText>
    </WSFlexBox.Center>
  );
};

interface EstimatedTaxesProps {
  isLoading?: boolean;
  profitAndLoss?: IProfitAndLoss;
}

export const EstimatedTaxes: React.FC<EstimatedTaxesProps> = ({
  isLoading,
  profitAndLoss
}) => {
  const { openModal } = useModalOldContext();
  const profitBeforeTaxes =
    (profitAndLoss && profitAndLoss.profitBeforeTaxes) ?? 0;

  const taxBreakdown = profitAndLoss && profitAndLoss.taxBreakdown;

  const selfEmploymentTaxRate =
    (taxBreakdown && taxBreakdown.selfEmploymentTaxRate) ?? 0;
  const federalIncomeTaxRate =
    (taxBreakdown && taxBreakdown.federalIncomeTaxRate) ?? 0;
  const stateIncomeTaxRate =
    (taxBreakdown && taxBreakdown.stateIncomeTaxRate) ?? 0;
  const taxYear = taxBreakdown?.taxYear ?? "XXXX";

  let taxAmountEstimate = profitAndLoss?.taxAmountEstimate ?? 0;
  let earningsEstimate = profitAndLoss?.earningsEstimate ?? 0;
  let totalTaxRate = profitAndLoss?.taxBreakdown?.totalTaxRate ?? 0;

  return (
    <WSPanel mb="M" p="S">
      <LineItem
        label="Self employment tax"
        value={`${selfEmploymentTaxRate}%`}
        secondValue={(profitBeforeTaxes / 100) * selfEmploymentTaxRate}
        isLoading={isLoading}
      />
      <LineItem
        label="Federal income tax"
        value={`${federalIncomeTaxRate}%`}
        secondValue={(profitBeforeTaxes / 100) * federalIncomeTaxRate}
        isLoading={isLoading}
      />
      <LineItem
        label="State income tax"
        value={`${stateIncomeTaxRate}%`}
        secondValue={(profitBeforeTaxes / 100) * stateIncomeTaxRate}
        isLoading={isLoading}
      />

      <WSDivider my="M" />

      <WSFlexBox.CenterY justify="flex-end" className={styles.row}>
        <WSFlexBox.CenterY
          style={{ flex: 1 }}
          onClick={() => openModal(BOOKKEEPING_ESTIMATED_TAXES_MODAL)}
        >
          <WSText weight="medium" color="gray600">
            Estimated taxes ({taxYear})
          </WSText>
          <WSIcon block size="XS" name="info-circle" ml="XS" color="gray500" />
        </WSFlexBox.CenterY>
        <WSText shimmer={isLoading} tabularNums>
          {totalTaxRate}%
        </WSText>
        <VerticalDivider py="M" mx="M" color="gray500" />
        <WSText shimmer={isLoading} formatMoney="default">
          {taxAmountEstimate}
        </WSText>
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};

interface ProfitAndLossProps {
  isLoading?: boolean;
  profitAndLossData?: IProfitAndLoss;
}

export const ProfitAndLoss: React.FC<ProfitAndLossProps> = ({
  isLoading,
  profitAndLossData
}) => {
  const history = useHistory();

  const [showDetails, setShowDetails] = useState(false);

  let incomeAll = profitAndLossData?.incomeAll ?? 0;
  let expensesDeductible = profitAndLossData?.expensesDeductible ?? 0;
  let profitBeforeTaxes = profitAndLossData?.profitBeforeTaxes ?? 0;
  let taxAmountEstimate = profitAndLossData?.taxAmountEstimate ?? 0;
  let earningsEstimate = profitAndLossData?.earningsEstimate ?? 0;
  let totalTaxRate = profitAndLossData?.taxBreakdown?.totalTaxRate ?? 0;

  const taxBreakdown = profitAndLossData?.taxBreakdown;
  const taxYear = taxBreakdown?.taxYear ?? "XXXX";

  return (
    <WSPanel className={styles.panel} mt="XL">
      <WSFlexBox
        direction="column"
        className={styles.container}
        data-testid="taxInfo"
      >
        <WSFlexBox direction="column" className={styles.section} mb="M">
          <WSFlexBox justify="space-between" className={styles.row} mb="M">
            <WSText color="gray600">Income</WSText>
            <WSText shimmer={isLoading} formatMoney="bookkeeping">
              {incomeAll}
            </WSText>
          </WSFlexBox>
          <WSFlexBox justify="space-between" className={styles.row} mb="M">
            <WSText color="gray600">Deductible expenses</WSText>
            <WSText shimmer={isLoading} formatMoney="default">
              {-1 * expensesDeductible}
            </WSText>
          </WSFlexBox>
        </WSFlexBox>

        <WSFlexBox direction="column" className={styles.section} mb="M">
          <WSFlexBox justify="space-between" className={styles.row} mb="M">
            <WSText color="gray700">Profit before taxes</WSText>
            <WSElement>
              <WSText inline shimmer={isLoading} formatMoney="bookkeeping">
                {profitBeforeTaxes}
              </WSText>
            </WSElement>
          </WSFlexBox>
          <WSFlexBox.CenterY justify="flex-end" className={styles.row} mb="M">
            <WSFlexBox.CenterY style={{ flex: 1 }}>
              <WSText color="gray600" onClick={() => setShowDetails(s => !s)}>
                Estimated taxes ({taxYear})
                <WSIcon
                  size="XS"
                  name={showDetails ? "chevron-up" : "chevron-down"}
                  mx="XS"
                  color="gray600"
                />
              </WSText>
            </WSFlexBox.CenterY>
            <WSText shimmer={isLoading} formatMoney="default">
              {taxAmountEstimate}
            </WSText>
          </WSFlexBox.CenterY>
          {showDetails && (
            <EstimatedTaxes
              profitAndLoss={profitAndLossData}
              isLoading={isLoading}
            />
          )}
        </WSFlexBox>

        <WSFlexBox direction="column" className={styles.section} mb="M">
          <WSFlexBox justify="space-between" className={styles.row} mb="XL">
            <WSText weight="medium" color="gray700">
              Estimated earnings
            </WSText>
            <WSElement>
              <WSText
                weight="medium"
                inline
                shimmer={isLoading}
                formatMoney="bookkeeping"
                data-testid="estimatedEarningsValue"
              >
                {earningsEstimate}
              </WSText>
            </WSElement>
          </WSFlexBox>
        </WSFlexBox>
        <EstimatedTaxesModal />
      </WSFlexBox>
      <WSButton.Link
        rightIcon="arrow-right"
        onClick={() => history.push("/member/bookkeeping/transactions")}
      >
        View all transactions
      </WSButton.Link>
    </WSPanel>
  );
};
