import {
  WSEmptyState,
  WSLoader,
  WSMarginProps,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library";
import {
  IEngagement,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRequirementDefinitionListQuery } from "../../../../query/requirementDefinitions/query/useRequirementDefinitionListQuery";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { useAddRequirementToEngagementModal } from "../../components/AddRequirementToEngagementModal";
import {
  selectorRequirementHumanReadableType,
  selectorRequirementIcon
} from "../../../Requirements/selectors/selectorRequirementHumanReadableType";
import { useRemoveRequirementFromEngagementModal } from "../../components/RemoveRequirementFromEngagementModal";

export interface RequirementsTableProps extends WSMarginProps {
  engagement: IEngagement;
}

export const RequirementsTable: React.FC<RequirementsTableProps> = ({
  engagement,
  ...elementProps
}) => {
  const history = useHistory();
  const requirementsDefinitionsListQuery = useRequirementDefinitionListQuery();
  const addRequirementToEngagementModal = useAddRequirementToEngagementModal();
  const removeRequirementFromEngagementModal =
    useRemoveRequirementFromEngagementModal();

  const tableData = useMemo(() => {
    const list = flatten(
      requirementsDefinitionsListQuery.data?.pages?.map(r => r.data) || []
    ).filter(requirement =>
      engagement.requirementIds?.includes(requirement.requirementDefinitionId)
    );
    return list.map(requirement => ({
      id: requirement.requirementDefinitionId,
      data: requirement
    }));
  }, [requirementsDefinitionsListQuery.data, engagement]);

  const columns: Array<WSTableColumn> = [
    {
      config: {
        header: {
          text: "Name"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={rowItem.data.name}
          secondaryText={rowItem.data.description}
        />
      )
    },
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => (
        <WSTableCell
          icon={selectorRequirementIcon(
            rowItem.data.requirementType as RequirementType
          )}
          text={selectorRequirementHumanReadableType(
            rowItem.data.requirementType as RequirementType
          )}
        />
      )
    }
  ];

  if (!requirementsDefinitionsListQuery.isFetched) {
    return <WSLoader.Spinner my="2XL" size="S" color="gray500" />;
  }

  return tableData.length > 0 ? (
    <WSTable
      {...elementProps}
      loading={requirementsDefinitionsListQuery.isInitialLoading}
      tableData={tableData}
      columns={columns}
      rowMenuActions={(item): WSTableRowMenuAction[] => {
        return [
          {
            label: "View requirement",
            icon: "view",
            onClick: () => {
              history.push(
                `/member/settings/engagement-requirements/${item.id}`
              );
            }
          },
          {
            label: "Remove from engagement",
            destructive: true,
            icon: "trash",
            onAsyncClick: async () => {
              await removeRequirementFromEngagementModal.open({
                engagement: engagement,
                requirementDefinition: item.data
              });
            }
          }
        ];
      }}
      onRowClick={item => {
        history.push(`/member/settings/engagement-requirements/${item.id}`);
      }}
      onRowControlClick={item => {
        openInNewTab(`/member/settings/engagement-requirements/${item.id}`);
      }}
      onRowMousewheelClick={item => {
        openInNewTab(`/member/settings/engagement-requirements/${item.id}`);
      }}
      data-testid="engagementRequirementsTable"
    />
  ) : (
    <WSEmptyState
      type="documents"
      title="No requirements yet"
      description="Once a requirement is added to this engagement, it will appear here."
      buttons={[
        {
          label: "Add requirement",
          kind: "Secondary",
          onClick: () => {
            addRequirementToEngagementModal.open({
              requirementsIds: engagement.requirementIds,
              engagementId: engagement.engagementId
            });
            // history.push({
            //   pathname: `${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/create`,
            //   state: {
            //     engagementId
            //   }
            // });
          }
        }
        // {
        //   label: "Turn off requirements",
        //   kind: "Tertiary",
        //   onClick: () => {
        //     alert("TODO");
        //   }
        // }
      ]}
      hasBorder={true}
      bgColor="gray"
      orientation="horizontal"
    />
  );
};
