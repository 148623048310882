import { PayeeTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { useMutationUpdateAcknowledgement } from "../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { usePayerListQuery } from "../../../query/payers/queries/usePayerListQuery";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { updatePayer } from "../../../services/payers";
import { usersService } from "../../../services/users";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { acknowledgementVersions } from "../../constants/acknowledgements";

export const useMutationSubmit = (config?: WSMutationConfig<void>) => {
  const userId = useUserId();
  const queryPayers = usePayerListQuery();

  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  return useWSMutation<any, WSServiceError>(
    async () => {
      const payers = queryPayers.data || [];

      concurrentActions(
        payers.map(
          payer => () =>
            updatePayer(payer.payerId, {
              payeeOwnedData: {
                shareTaxDocument: PayeeTaxDocumentSharePermission.Allow
              }
            })
        )
      );

      await usersService.activity.update(userId, {
        events: {
          w9FormAcknowledged: new Date()
        }
      });

      await updateAcknowledgement({
        acknowledgementName: "W9Certification",
        requestData: {
          acknowledgementName: "W9Certification",
          acknowledgementStatus: "Given",
          version: acknowledgementVersions["W9Certification"]
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );
};
