import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";
import {
  getPayerRowsSummary,
  IPayerSearchRowsSummary,
  PayerRowsQuery
} from "../../../../services/search";

export const QUERY_PAYER_ROWS_SUMMARY = "QUERY_PAYER_ROWS_SUMMARY";

export const useQueryPayerRowsSummary = (
  params?: PayerRowsQuery,
  config?: WSQueryConfig<IPayerSearchRowsSummary, WSServiceError>
) => {
  const { sort, ...filter } = params || {};

  return useWSQuery<IPayerSearchRowsSummary, WSServiceError>(
    [QUERY_PAYER_ROWS_SUMMARY, params],
    () =>
      getPayerRowsSummary({
        ...params,
        page: { size: 1, number: 1 }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
