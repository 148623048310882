import React from "react";
import {
  WSElement,
  WSElementProps,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import { WALLET_CURRENT_YEAR } from "../../../Wallet/utils";

type Props = WSElementProps & {};

export const CashbackProgramInfo: React.FC<Props> = props => {
  return (
    <WSList gap="XL">
      <WSPanel p="L">
        <WSText color="gray600" weight="medium" mb="L">
          Cashback program:
        </WSText>

        <WSText.ParagraphSm color="gray500" mb="L">
          Earn 1% on eligible purchases every month—just keep your average daily
          balance above $100.
        </WSText.ParagraphSm>

        <TextBulleted as="li" color="gray500">
          <li>
            Cashback posts to your account after each monthly cycle closes.
          </li>
          <li>
            You won’t earn cash back on days when daily balance dips below $100.
          </li>
          <li>
            Returned purchases reduce the total qualifying spend and may affect
            your rewards.
          </li>
          <li>Program starts January 1, {WALLET_CURRENT_YEAR}.</li>
          <li>Offer valid through December 31, {WALLET_CURRENT_YEAR}.</li>
        </TextBulleted>
      </WSPanel>

      <WSPanel p="L">
        <WSText color="gray600" weight="medium" mb="L">
          Bonus rewards
        </WSText>

        <WSText.ParagraphSm color="gray500">
          Receive a $50 gift card, redeemable at 200+ businesses, when you spend
          $1,000 or more in your first 30 days after signing up for the Wingspan
          debit card. Expires March 31, {WALLET_CURRENT_YEAR}
        </WSText.ParagraphSm>
      </WSPanel>

      <WSPanel p="L">
        <WSText color="gray600" weight="medium" mb="L">
          Future offers
        </WSText>

        <WSText.ParagraphSm color="gray500">
          Future offers to be announced on your Wallet page.
        </WSText.ParagraphSm>
      </WSPanel>
    </WSList>
  );
};

export const CashbackProgramWidget: React.FC<Props> = props => {
  return (
    <WSElement {...props}>
      <WSText.Paragraph weight="medium" mb="L">
        Debit Cashback Program
      </WSText.Paragraph>
      <CashbackProgramInfo />
    </WSElement>
  );
};
