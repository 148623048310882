import React from "react";

import { useFlatfile } from "@flatfile/react";
import { WSButton, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { WSQueries } from "../../../../query/WSQuery";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";

import { BulkResource } from "../../types";
import { isUploadFinished } from "../../utils/bulkBatchUtils";
import { getUploadedFilename } from "../../utils/getUploadedFilename";

import { BulkBatchImportStatus } from "../BulkBatchImportStatus";
import { BulkBatchImportProgress } from "../BulkBatchImportProgress";

import styles from "./styles.module.scss";

interface DefaultHeaderProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

export function DefaultHeader({
  bulkBatchId,
  bulkResource
}: DefaultHeaderProps) {
  const { useBulkBatch, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qBulkBatchSummary = useBulkBatchSummary(bulkBatchId);
  const isEmptyBatch = qBulkBatchSummary.data?.listSize === 0;

  const { openPortal } = useFlatfile();

  const viewUpload = () => {
    openPortal();
  };

  return (
    <WSQueries queries={{ qBulkBatch }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        return (
          <WSFlexBox direction="column">
            <WSFlexBox.CenterY
              className={styles.header}
              justify="space-between"
              mb="XL"
            >
              <WSText
                weight="medium"
                title={getUploadedFilename(bulkBatch, bulkResource)}
              >
                {getUploadedFilename(bulkBatch, bulkResource, { length: 35 })}
              </WSText>
              <BulkBatchImportStatus
                batchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            </WSFlexBox.CenterY>

            <BulkBatchImportProgress
              batchId={bulkBatchId}
              bulkResource={bulkResource}
            />

            {isUploadFinished(bulkBatch, isEmptyBatch) &&
              !!bulkBatch?.labels?.[
                BATCH_LABELS_WS_KEYS.flatfileAccessToken
              ] && (
                <WSButton.Secondary
                  name="viewUpload"
                  icon="view"
                  fullWidth
                  mt="M"
                  onClick={viewUpload}
                >
                  View upload
                </WSButton.Secondary>
              )}
          </WSFlexBox>
        );
      }}
    </WSQueries>
  );
}
