import { WSAlert } from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { useAccounts } from "../../../query/users/queries";
import { selectorAccountVerificationPending } from "../../../shared/selectors/selectorAccountVerificationPending";
import { useModalMicrodepositsVerify } from "./ModalMicrodepositsVerify";

export const AlertPendingAccountMicroDeposit: React.FC = () => {
  const queryAccounts = useAccounts();

  const pendingAccount = useMemo(
    () => (queryAccounts.data || []).find(selectorAccountVerificationPending),
    [queryAccounts.data]
  );

  const modalMicrodepositsVerify = useModalMicrodepositsVerify();

  if (!pendingAccount) {
    return null;
  }

  return (
    <WSAlert
      theme="warning"
      icon="alert"
      title="Action required"
      action={{
        text: "Verify",
        onClick: () => {
          modalMicrodepositsVerify.open({
            account: pendingAccount
          });
        }
      }}
    >
      Wingspan sent micro-deposits to your account ending in •••
      {pendingAccount.numbers.account?.slice(-4)}. Enter the amounts deposited
      to verify your account.{" "}
    </WSAlert>
  );
};
