import { WSQueryConfig } from "@ws-react-query";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { getTaxFormSubmission } from "../../../services/taxFormSubmissions";

const QUERY_TAX_FORM_SUBMISSION = "QUERY_TAX_FORM_SUBMISSION";

export const usePayeeTaxFormSubmission = (
  taxFormId: string,
  submissionId: string,
  queryConfig?: WSQueryConfig<ITaxFormSubmissionResponse, WSServiceError>
) =>
  useWSQuery<ITaxFormSubmissionResponse, WSServiceError>(
    [QUERY_TAX_FORM_SUBMISSION, { taxFormId, submissionId }],
    () => getTaxFormSubmission(taxFormId, submissionId),
    queryConfig
  );
