import { WSQueryConfig } from "@ws-react-query";
import times from "lodash/times";
import flatten from "lodash/flatten";
import {
  getTaxFormSubmissionRows,
  getTaxFormSubmissionRowsSummary,
  TaxFormSubmissionRequest,
  TaxFormSubmissionRow
} from "../../../../services/search";
import {
  concurrentActions,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";
import { useWSQuery } from "../../../helpers";

const PAGE_SIZE = 500;
export const QUERY_TAX_FORM_SUBMISSION_ROWS_ALL =
  "QUERY_TAX_FORM_SUBMISSION_ROWS_ALL";

export function useTaxFormSubmissionRowsAllQuery(
  params?: TaxFormSubmissionRequest,
  config?: WSQueryConfig<TaxFormSubmissionRow[], WSServiceError>
) {
  const size = PAGE_SIZE;

  const xWingspanExpansion = useXWingspanExpansion();

  return useWSQuery<TaxFormSubmissionRow[], WSServiceError>(
    [QUERY_TAX_FORM_SUBMISSION_ROWS_ALL, { params, xWingspanExpansion }],
    async () => {
      const { listSize } = await getTaxFormSubmissionRowsSummary(
        params,
        xWingspanExpansion
      );

      const pages = Math.ceil(listSize / size) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          getTaxFormSubmissionRows(
            {
              ...params,
              page: { size, number: i + 2 }
            },
            xWingspanExpansion
          )
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([...allPages]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
}

export const downloadAllTaxFormSubmissionRows = async (
  params?: TaxFormSubmissionRequest,
  xWingspanExpansion?: string
) => {
  const { listSize } = await getTaxFormSubmissionRowsSummary(
    params,
    xWingspanExpansion
  );

  if (listSize === 0) {
    return [];
  }

  const size = PAGE_SIZE;

  const pagesCount = Math.ceil(listSize / size);

  const actions = times(pagesCount).map(
    (_, i) => () =>
      getTaxFormSubmissionRows(
        {
          ...params,
          page: { size, number: i + 1 }
        },
        xWingspanExpansion
      )
  );

  const allPages = await concurrentActions(actions, {
    concurrentLimit: 5
  });

  return flatten(allPages);
};
