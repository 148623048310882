import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSCheckboxGroup,
  WSFlexBox,
  WSFormOld,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { INextgenSubscription } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useDeleteNextGenSubscription } from "../../../query/subscriptions/mutations";
import { useNextGenSubscriptions } from "../../../query/subscriptions/queries";
import { WSQueries } from "../../../query/WSQuery";

export const CANCEL_NEXT_GEN_SUBSCRIPTIONS_MODAL =
  "CANCEL_NEXT_GEN_SUBSCRIPTIONS_MODAL";

export interface CancelNextGenSubscriptionsModalProps {}

interface SubscriptionOption {
  label: React.ReactNode;
  value: string;
}

export const CancelNextGenSubscriptionsModal: React.FC<
  CancelNextGenSubscriptionsModalProps
> = () => {
  const { closeModal } = useModalOldContext();
  const userId = useUserId();
  const nextGenSubscriptionsQuery = useNextGenSubscriptions(userId);
  const [deleteNextGenSubscription, deleteNextGenSubscriptionMeta] =
    useDeleteNextGenSubscription(userId);

  const prepareSubscriptionOptions = (
    subscriptions: INextgenSubscription[]
  ): SubscriptionOption[] => {
    return subscriptions.map(subscription => {
      if (subscription.cancelAtPeriodEnd) {
        return {
          label: (
            <WSFlexBox.Center direction="column" alignItems="flex-start">
              <WSText.ParagraphSm color="gray400">{`${subscription.plan.name} (${subscription.term})`}</WSText.ParagraphSm>
              <WSText.ParagraphSm color="gray400" inline>
                It will be canceled on{" "}
                <WSText.ParagraphSm
                  color="gray400"
                  inline
                  formatDate="monthDayYear"
                >
                  {subscription.currentPeriodEnd}
                </WSText.ParagraphSm>
              </WSText.ParagraphSm>
            </WSFlexBox.Center>
          ),
          value: subscription.subscriptionId,
          disabled: true
        };
      } else {
        return {
          label: `${subscription.plan.name} (${subscription.term})`,
          value: subscription.subscriptionId
        };
      }
    });
  };

  const onFormSubmit = async (data: { subscriptions: Array<string> }) => {
    await Promise.all(
      data.subscriptions.map(
        async subscriptionId => await deleteNextGenSubscription(subscriptionId)
      )
    );
    closeModal(CANCEL_NEXT_GEN_SUBSCRIPTIONS_MODAL);
  };

  return (
    <WSModalOld
      name={CANCEL_NEXT_GEN_SUBSCRIPTIONS_MODAL}
      size="XS"
      title="Cancel subscription"
    >
      <WSQueries queries={{ nextGenSubscriptionsQuery }}>
        {({ nextGenSubscriptionsQuery: { data: nextGenSubscriptions } }) => {
          const checkboxOptions =
            prepareSubscriptionOptions(nextGenSubscriptions);
          return (
            <WSFormOld
              onSubmit={onFormSubmit}
              validationSchema={Yup.object().shape({
                subscriptions: Yup.array(Yup.string().required())
                  .required("Please select at least one plan")
                  .min(1)
              })}
            >
              <WSFormOld.Field
                name="subscriptions"
                label="Your Subscriptions"
                mb="2XL"
                component={WSCheckboxGroup}
                componentProps={{
                  options: checkboxOptions
                }}
              />

              {deleteNextGenSubscriptionMeta.isError && (
                <WSText.ParagraphSm my="M" color="garnet">
                  {deleteNextGenSubscriptionMeta.error?.response?.data["error"]}
                </WSText.ParagraphSm>
              )}
              <WSButtons format="modal">
                <WSFormOld.SubmitButton
                  destructive
                  loading={deleteNextGenSubscriptionMeta.isLoading}
                >
                  Confirm
                </WSFormOld.SubmitButton>
                <WSButton.Tertiary
                  onClick={() =>
                    closeModal(CANCEL_NEXT_GEN_SUBSCRIPTIONS_MODAL)
                  }
                >
                  Close
                </WSButton.Tertiary>
              </WSButtons>
            </WSFormOld>
          );
        }}
      </WSQueries>
    </WSModalOld>
  );
};
