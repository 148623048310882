import {
  useWSModal,
  WSActions,
  WSForm,
  WSInfoBox,
  WSInputText,
  WSInputTextarea,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { WSQueries } from "../../../../query/WSQuery";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

type FormData = {
  name: string;
  notes: string;
};

const ModalEditBulkBatchName: React.FC<{
  onClose: () => void;
  bulkBatchId: string;
  bulkResource: BulkResource;
}> = ({ onClose, bulkBatchId, bulkResource }) => {
  const { useBulkBatch, useUpdateBulkBatch } =
    getBulkQueriesByResource(bulkResource);
  const queryBulkBatch = useBulkBatch(bulkBatchId);
  const [updateBulkBatch, updateBulkBatchMeta] = useUpdateBulkBatch();

  const handleSubmit = async (data: FormData) => {
    await updateBulkBatch(
      {
        id: bulkBatchId,
        data: {
          labels: {
            [BATCH_LABELS_WS_KEYS.bulkBatchName]: data.name,
            [BATCH_LABELS_WS_KEYS.bulkBatchNotes]: data.notes
          }
        }
      },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  return (
    <WSQueries queries={{ queryBulkBatch }}>
      {({ queryBulkBatchData: bulkBatch }) => {
        return (
          <WSForm<FormData>
            defaultValues={{
              name:
                (bulkBatch.labels?.[
                  BATCH_LABELS_WS_KEYS.bulkBatchName
                ] as string) || "",
              notes:
                (bulkBatch.labels?.[
                  BATCH_LABELS_WS_KEYS.bulkBatchNotes
                ] as string) || ""
            }}
            onSubmit={handleSubmit}
          >
            <WSList gap="XL">
              <WSForm.Field
                name="name"
                label="Batch name"
                component={WSInputText}
                componentProps={{
                  placeholder: "Enter name for the import batch"
                }}
              />
              <WSForm.Field
                name="notes"
                label="Notes"
                component={WSInputTextarea}
                componentProps={{
                  placeholder: "Provide notes for your reference",
                  helperText:
                    "Provide details about the batch for your reference"
                }}
              />

              <WSInfoBox>
                <WSText.ParagraphSm weight="book" color="gray600">
                  Tip: adding a name and notes can be helpful when filtering by
                  batches in the contractor list and help identify the purpose
                  of an imported batch for your own reference.
                </WSText.ParagraphSm>
              </WSInfoBox>

              <WSErrorMessage
                contextKey={ErrorContextKey.BulkBatchUpdate}
                error={updateBulkBatchMeta.error}
              />

              <WSForm.Values names={["name", "notes"]}>
                {({ name, notes }) => {
                  const isFormValuesEmpty = !name && !notes;
                  const isFormValuesChanged =
                    name !==
                      bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchName] ||
                    notes !==
                      bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkBatchNotes];
                  const isDisabled = isFormValuesEmpty || !isFormValuesChanged;
                  return (
                    <WSActions
                      alignment="fill"
                      buttons={[
                        {
                          label: "Cancel",
                          kind: "Tertiary",
                          onClick: onClose
                        },
                        {
                          label: "Save changes",
                          type: "submit",
                          kind: "Primary",
                          disabled: isDisabled,
                          loading: updateBulkBatchMeta.isLoading
                        }
                      ]}
                    />
                  );
                }}
              </WSForm.Values>
            </WSList>
          </WSForm>
        );
      }}
    </WSQueries>
  );
};

export const useModalEditBulkBatchName = () =>
  useWSModal(ModalEditBulkBatchName, {
    title: "Edit name",
    size: "S"
  });
