import {
  IAccount,
  IActivity,
  IApiSessionResponse,
  IAuthorizationListResponse,
  IAuthorizedAccountsResponse,
  IClient,
  IMember,
  INewUser,
  IOccupation,
  IPublicUserResponse,
  IUserTagResponse
} from "@wingspanhq/users";
import {
  IAccountRequirementsRequest,
  IAccountRequirementsResponse
} from "@wingspanhq/users/dist/lib/interfaces/api/account-requirements";
import {
  WSInfiniteQueryConfig,
  WSQueryCache,
  WSQueryConfig
} from "@ws-react-query";
import { ILinkTokenResponse, usersService } from "../../services/users";
import { concurrentActions, WSServiceError } from "../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../helpers";
import {
  QUERY_ACCOUNT_REQUIREMENTS,
  QUERY_API_SESSION,
  QUERY_API_SESSION_LIST,
  QUERY_AUTHORIZATION,
  QUERY_AUTHORIZATION_SCOPE_GROUPS,
  QUERY_AUTHORIZATIONS,
  QUERY_AUTHORIZATIONS_INFINITE,
  QUERY_AUTHORIZED_ACCOUNTS,
  QUERY_OCCUPATIONS,
  QUERY_USERS_ACCOUNT,
  QUERY_USERS_ACCOUNT_LINK_TOKEN,
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_ACTIVITIES,
  QUERY_USERS_ALL_ORGANIZATION_USERS,
  QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE,
  QUERY_USERS_CLIENT,
  QUERY_USERS_GET_ORGANIZATION_USER,
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_ORGANIZATION_USER,
  QUERY_USERS_ORGANIZATION_USERS,
  QUERY_USERS_PUBLIC_USER_ID,
  QUERY_USERS_USER_PROFILE,
  QUERY_USERS_USER_TAG
} from "./keys";
import { IAuthorizationResponse } from "@wingspanhq/users/dist/lib/interfaces/api/authorization";
import flatten from "lodash/flatten";
import times from "lodash/times";

export const useUserProfile = (
  userId: string,
  queryConfig?: WSQueryConfig<INewUser, WSServiceError>
) => {
  return useWSQuery(
    QUERY_USERS_USER_PROFILE,
    async () => await usersService.user.get(userId),
    {
      enabled: !!userId,
      refetchOnMount: false,
      ...queryConfig
    }
  );
};

export const useActivities = (
  userId: string,
  queryConfig?: WSQueryConfig<IActivity, WSServiceError>
) => {
  return useWSQuery(
    QUERY_USERS_ACTIVITIES,
    async () => {
      try {
        return await usersService.activity.get(userId);
      } catch {
        const defaultData: IActivity = {
          events: {
            incomeReviewedAt: new Date(0),
            expensesReviewedAt: new Date(0)
          },
          flows: {},
          userId
        };

        return defaultData;
      }
    },
    {
      enabled: !!userId,
      ...queryConfig
    }
  );
};

export const useMemberProfile = (
  memberId: string,
  queryConfig?: WSQueryConfig<IMember, WSServiceError>
) => {
  return useWSQuery(
    QUERY_USERS_MEMBER_PROFILE,
    async () => {
      return await usersService.member.get(memberId, {
        headers: { "x-ws-fe-flow": "query" }
      });
    },

    queryConfig
  );
};

export const useClientQuery = (
  clientId: string,
  queryConfig?: WSQueryConfig<IClient, WSServiceError>
) =>
  useWSQuery(
    QUERY_USERS_CLIENT,
    () =>
      usersService.client.get(clientId, {
        headers: { "x-ws-fe-flow": "query" }
      }),
    {
      retry: false,
      ...queryConfig
    }
  );

export const useAccount = (
  accountId: string,
  queryConfig?: WSQueryConfig<IAccount, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_USERS_ACCOUNT, accountId],
    () => usersService.account.get(accountId),
    queryConfig
  );
};

export const useAccounts = (
  queryConfig?: WSQueryConfig<IAccount[], WSServiceError>
) => {
  return useWSQuery(
    QUERY_USERS_ACCOUNTS,
    usersService.account.list,
    queryConfig
  );
};

export const useOccupationsQuery = (
  queryConfig?: WSQueryConfig<IOccupation[], WSServiceError>
) => {
  return useWSQuery(
    QUERY_OCCUPATIONS,
    usersService.user.occupation.list,
    queryConfig
  );
};

export const useUserTag = (
  tag: string,
  queryConfig?: WSQueryConfig<IUserTagResponse, WSServiceError>
) =>
  useWSQuery(
    QUERY_USERS_USER_TAG,
    () => usersService.user.tag.get(tag),
    queryConfig
  );

export const useUserById = (
  id: string,
  queryConfig?: WSQueryConfig<IPublicUserResponse, WSServiceError>
) =>
  useWSQuery(
    [QUERY_USERS_PUBLIC_USER_ID, id],
    () => usersService.user.public.get(id),
    queryConfig
  );

export const useAuthorizedAccounts = (
  userId: string,
  queryConfig?: WSQueryConfig<IAuthorizedAccountsResponse, WSServiceError>
) =>
  useWSQuery(
    [QUERY_AUTHORIZED_ACCOUNTS, userId],
    () => usersService.authorization.accounts(userId),
    queryConfig
  );

export const useAuthorizations = (
  queryConfig?: WSQueryConfig<IAuthorizationListResponse, WSServiceError>
) =>
  useWSQuery(
    QUERY_AUTHORIZATIONS,
    async () => {
      const listSize = await usersService.authorization.listSize();
      const pages = Math.ceil(listSize / 100);

      const actions = times(pages).map(
        (_, i) => () =>
          usersService.authorization.list({
            page: { size: 100, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten(allPages);
    },
    queryConfig
  );

export const useAuthorization = (
  id: string,
  queryConfig?: WSQueryConfig<IAuthorizationResponse, WSServiceError>
) =>
  useWSQuery(QUERY_AUTHORIZATION, () => usersService.authorization.get(id), {
    enabled: !!id,
    ...queryConfig
  });

export const useAuthorizationsInfinite = (
  queryConfig?: WSInfiniteQueryConfig<
    IAuthorizationListResponse,
    WSServiceError
  >
) =>
  useWSInfiniteQuery(
    QUERY_AUTHORIZATIONS_INFINITE,
    async ({ pageParam = 1 }) => {
      return await usersService.authorization.list({
        page: {
          size: 10,
          number: pageParam
        }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );

export const useAuthorizedScopeGroupsQuery = (
  requestingUserId?: string,
  queryConfig?: WSQueryConfig<IAuthorizedAccountsResponse, WSServiceError>
) =>
  useWSQuery<IAuthorizedAccountsResponse, WSServiceError>(
    QUERY_AUTHORIZATION_SCOPE_GROUPS,
    () => usersService.authorization.scopeGroups.list(requestingUserId),
    queryConfig
  );

export const useAPISessionList = (
  queryConfig?: WSQueryConfig<IApiSessionResponse[], WSServiceError>
) =>
  useWSQuery(
    QUERY_API_SESSION_LIST,
    usersService.session.apiToken.list,
    queryConfig
  );

export const useGetAPISession = (
  id: string,
  queryConfig?: WSQueryConfig<IApiSessionResponse, WSServiceError>
) =>
  useWSQuery(
    `${QUERY_API_SESSION}-${id}`,
    () => usersService.session.apiToken.get(id),
    queryConfig
  );

export const useUserAccountLinkToken = (
  queryConfig?: WSQueryConfig<ILinkTokenResponse, WSServiceError>
) =>
  useWSQuery(
    QUERY_USERS_ACCOUNT_LINK_TOKEN,
    () => usersService.accountLink.create(),
    {
      ...queryConfig,
      retry: 1
    }
  );

export const useOrganizationUser = (
  userId: string,
  queryConfig?: WSQueryConfig<IMember, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_USERS_ORGANIZATION_USER, userId],
    async () => {
      return await usersService.member.get(userId);
    },

    queryConfig
  );
};

// This is get for /users/organization/user/:userId but above useOrganizationUser
// query is not accessing above endpoint
export const useGetOrganizationUser = (
  userId: string,
  queryConfig?: WSQueryConfig<INewUser, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_USERS_GET_ORGANIZATION_USER, userId],
    async () => {
      return await usersService.organization.users.get(userId);
    },

    queryConfig
  );
};

export const useOrganizationUsers = (
  params: {
    sort?: any;
    filter?: any;
  },
  queryConfig?: WSInfiniteQueryConfig<INewUser[], unknown>
) =>
  useWSInfiniteQuery(
    [QUERY_USERS_ORGANIZATION_USERS, params],
    async ({ pageParam = 1 }) => {
      return await usersService.organization.users.list({
        page: {
          size: 10,
          number: pageParam
        },
        sort: params.sort,
        filter: params.filter
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...queryConfig
    }
  );

export const useOrganizationUsersListSize = (
  params?: {
    sort?: any;
    filter?: any;
  },
  queryConfig?: WSQueryConfig<number, unknown>
) =>
  useWSQuery(
    [QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE, params],
    async () => {
      const listSize = await usersService.organization.users.listSize();

      return listSize;
    },
    {
      refetchOnMount: false,
      ...queryConfig
    }
  );

export const useAllOrganizationUsers = (
  params?: {
    sort?: any;
    filter?: any;
  },
  queryConfig?: WSQueryConfig<INewUser[], WSServiceError>
) =>
  useWSQuery(
    [QUERY_USERS_ALL_ORGANIZATION_USERS, params],
    async () => {
      const listSize = await usersService.organization.users.listSize();

      WSQueryCache.setQueryData(
        [QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE, undefined],
        listSize
      );

      const pages = Math.ceil(listSize / 100);

      const actions = times(pages).map(
        (_, i) => () =>
          usersService.organization.users.list({
            page: { size: 100, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten(allPages);
    },
    {
      refetchOnMount: false,
      ...queryConfig
    }
  );

export const useQueryAccountRequirements = (
  data: IAccountRequirementsRequest,
  config?: WSQueryConfig<IAccountRequirementsResponse, WSServiceError>
) =>
  useWSQuery(
    [QUERY_ACCOUNT_REQUIREMENTS, data.country, data.currency],
    () => usersService.accountRequirements.get(data),
    config
  );
