import { WSQueryConfig } from "@ws-react-query";
import { getInternalAccount } from "../../../services/api/banking/internalAccounts";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_INTERNAL_ACCOUNT = "QUERY_INTERNAL_ACCOUNT";

export const useQueryInternalAccount = (
  internalAccountId: Parameters<typeof getInternalAccount>[0],
  config?: WSQueryConfig<
    Awaited<ReturnType<typeof getInternalAccount>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_INTERNAL_ACCOUNT, internalAccountId],
    () => getInternalAccount(internalAccountId),
    config
  );
