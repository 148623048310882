import React from "react";
import { WSFormOld, WSSelectOld } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { USStates } from "../../../constants/USstates";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_BOOKKEEPING_PROFIT_AND_LOSS } from "../../../query/bookkeeping/keys";
import { useUserId } from "../../../query/hooks/helpers";

interface TaxFilingStateFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const US_STATES_OPTIONS = USStates.map(s => ({
  value: s.abbreviation,
  label: s.name
}));

export const TaxFilingStateForm: React.FC<TaxFilingStateFormProps> = ({
  field,
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          [field]: data[field]
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
        WSQueryCache.invalidateQueries(QUERY_BOOKKEEPING_PROFIT_AND_LOSS);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.string().required("State is required")
      })}
    >
      <WSFormOld.Field name={field}>
        <WSFormOld.Input
          render={props => (
            <WSSelectOld
              placeholder="State"
              options={US_STATES_OPTIONS}
              searchable
              {...props}
            />
          )}
        />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
