import {
  IPayeeResponse,
  IPayeeUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { QUERY_PAYEE } from "../keys";
import { updatePayee } from "../../../services/payees";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
  QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE
} from "../../payeeEngagements/keys";

export type PayeeUpdateRequest = IPayeeUpdateRequest & { payeeId: string };

export const useUpdatePayee = (
  config?: WSMutationConfig<IPayeeResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSMutation(
    ({ payeeId, ...request }: PayeeUpdateRequest) =>
      updatePayee(payeeId, request, xWingspanPayerId),
    {
      ...config,
      dependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        QUERY_PAYEE_ROWS,
        QUERY_PAYEE_ROWS_SUMMARY
      ],
      awaitDependencies: ({ payeeId }) => [[QUERY_PAYEE, payeeId]]
    }
  );
};
