import { WSElementProps } from "@wingspanhq/fe-component-library";
import { Tabs } from "../../../../components/Tabs";
import { useQueryInvoicesRowsSummary } from "../../queries/useQueryInvoicesRowsSummary";
import { InvoiceRowStatus } from "../../service";

export const DashboardTabs: React.FC<WSElementProps> = props => {
  const queryAll = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      }
    }
  });
  const queryDrafts = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: InvoiceRowStatus.Draft
    }
  });
  const queryOutstanding = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: {
        in: [InvoiceRowStatus.ActionRequired, InvoiceRowStatus.AwaitingClient]
      }
    }
  });
  const queryInProgress = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: InvoiceRowStatus.InProgress
    }
  });
  const queryDeposited = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: { in: [InvoiceRowStatus.Deposited, InvoiceRowStatus.Refunded] }
    }
  });

  return (
    <Tabs
      {...props}
      tabs={[
        {
          label: "All",
          path: "/member/invoices",
          badge: queryAll.data?.listSize
        },
        {
          label: "Drafts",
          path: "/member/invoices/drafts",
          badge: queryDrafts.data?.listSize
        },
        {
          label: "Outstanding",
          path: "/member/invoices/outstanding",
          badge: queryOutstanding.data?.listSize
        },
        {
          label: "In-progress",
          path: "/member/invoices/in-progress",
          badge: queryInProgress.data?.listSize
        },
        {
          label: "Deposited",
          path: "/member/invoices/deposited",
          badge: queryDeposited.data?.listSize
        }
      ]}
    />
  );
};
