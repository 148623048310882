import {
  toWSDateString,
  toWSMoneyString,
  WSText
} from "@wingspanhq/fe-component-library";
import { IInvoice, IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { Card } from "../../ClientPayments/components/Card/Card";
import { getPayeeName } from "../../modules/Payees/selectors/getPayeeNames";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import { convertDateToEndDay } from "../../utils/dates";
import { Activity, ActivityItem } from "./Activity";
import { useQueryInvoiceCollaborators } from "./InvoiceCollaborators";

type Props = { invoice: IInvoice; payer: IPayerResponse };

export const InvoiceActivity: React.FC<Props> = ({ invoice, payer }) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const queryInvoiceCollaborators = useQueryInvoiceCollaborators(
    invoice.invoiceId,
    invoice.collaborators
  );

  const activity = useMemo(() => {
    const user = queryUser.data;

    if (!user) {
      return [];
    }

    const activity: ActivityItem[] = [];

    const payerEmail = payer.user?.email;
    const payeeEmail = user.email;

    activity.push({
      title: "Invoice was created",
      description: toWSDateString(invoice.createdAt),
      date: invoice.createdAt
    });

    if (invoice.events.openedAt) {
      activity.push({
        title: `Sent to ${payerEmail}`,
        description: toWSDateString(invoice.events.openedAt),
        date: invoice.events.openedAt
      });
    }

    if (
      invoice.events.emailReceivedAt &&
      invoice.events.emailReceivedAt.length > 0
    ) {
      activity.push({
        title: `Received by ${payerEmail}`,
        description: toWSDateString(invoice.events.emailReceivedAt[0]),
        date: invoice.events.emailReceivedAt[0]
      });
    }

    // Work with viewed at only if it is before paidAt and depositedAt
    // Viewed event should never come after paid and deposited
    const allowedViewedAt = (invoice.events.emailViewedAt || []).filter(date =>
      [
        invoice.events.paidAt,
        invoice.events.depositedAt,
        invoice.events.instantPayoutAt
      ].every(d => (d ? d > date : true))
    );

    if (allowedViewedAt.length > 0) {
      allowedViewedAt.sort((a, b) => b.getTime() - a.getTime());

      const firstViewedAt = allowedViewedAt[allowedViewedAt.length - 1];

      if (firstViewedAt) {
        activity.push({
          title: `Viewed by ${payerEmail}`,
          description: toWSDateString(firstViewedAt),
          date: firstViewedAt
        });
      }

      const secondViewedAt = firstViewedAt
        ? allowedViewedAt.find(
            date => date.getTime() - firstViewedAt.getTime() > 60 * 60 * 1000
          )
        : undefined;

      if (secondViewedAt) {
        activity.push({
          title: `Viewed by ${payerEmail}`,
          description: toWSDateString(secondViewedAt),
          date: secondViewedAt
        });
      }
    }

    if (invoice.events.approvedAt) {
      activity.push({
        title: invoice.client?.payDate
          ? `Approved to be paid on ${toWSDateString(
              invoice.client?.payDate,
              "monthDate"
            )}`
          : `Approved by ${payerEmail}`,
        description: toWSDateString(invoice.events.approvedAt),
        date: invoice.events.approvedAt
      });
    }

    if (invoice.events.unapprovedAt) {
      activity.push({
        title: invoice.client?.payDate
          ? `Unapproved on ${toWSDateString(
              invoice.client?.payDate,
              "monthDate"
            )}`
          : `Unapproved by ${payerEmail}`,
        description: toWSDateString(invoice.events.unapprovedAt),
        date: invoice.events.unapprovedAt
      });
    }

    if (invoice.events.preApprovedAt) {
      activity.push({
        title: `Pre-approved by ${payerEmail}`,
        description: toWSDateString(invoice.events.preApprovedAt),
        date: invoice.events.preApprovedAt
      });
    }

    if (invoice.events.sentDueIn3DaysAt) {
      activity.push({
        title: `Reminder sent to ${payerEmail}`,
        description: toWSDateString(invoice.events.sentDueIn3DaysAt),
        date: invoice.events.sentDueIn3DaysAt
      });
    }

    if (invoice.events.sentDueTodayAt) {
      activity.push({
        title: `Reminder sent to ${payerEmail}`,
        description: toWSDateString(invoice.events.sentDueTodayAt),
        date: invoice.events.sentDueTodayAt
      });
    }

    if (invoice.events.sentDue3DaysAgoAt) {
      activity.push({
        title: `Reminder sent to ${payerEmail}`,
        description: toWSDateString(invoice.events.sentDue3DaysAgoAt),
        date: invoice.events.sentDue3DaysAgoAt
      });
    }

    if (invoice.events.sentDue7DaysAgoAt) {
      activity.push({
        title: `Reminder sent to ${payerEmail}`,
        description: toWSDateString(invoice.events.sentDue7DaysAgoAt),
        date: invoice.events.sentDue7DaysAgoAt
      });
    }

    if (invoice.events.paymentInTransitAt) {
      activity.push({
        title: `Payment in transit on ${toWSDateString(
          invoice.events.paymentInTransitAt
        )}`,
        description: "Waiting on client's wire transfer to arrive",
        date: invoice.events.paymentInTransitAt
      });
    }

    if (invoice.events.paidAt) {
      activity.push(
        invoice.events.instantPayoutAt
          ? {
              title: "Invoice paid",
              description: toWSDateString(invoice.events.paidAt),
              date: invoice.events.paidAt
            }
          : {
              title: `Paid on ${toWSDateString(invoice.events.paidAt)}`,
              description: invoice.events.estimatedDepositAt
                ? `Payout initiated on ${toWSDateString(
                    invoice.events.estimatedDepositAt,
                    "monthDayYear"
                  )}`
                : "Invoice was paid outside of Wingspan",
              date: invoice.events.paidAt
            }
      );
    }

    if (invoice.events.depositedAt && !invoice.events.instantPayoutAt) {
      activity.push({
        title: "Payout initiated",
        description: `Initiated on ${toWSDateString(
          invoice.events.depositedAt,
          "monthDate"
        )}. Funds may take 1–2 business days to arrive.`,
        date: convertDateToEndDay(invoice.events.depositedAt)
      });
    }

    if (invoice.events.instantPayoutAt) {
      activity.push({
        title: "Deposited instantly",
        description: toWSDateString(invoice.events.instantPayoutAt),
        date: invoice.events.instantPayoutAt
      });
    }

    if (invoice.events.memberDisputedAt) {
      activity.push({
        title: `Invoice disputed`,
        description: `Dispute reason: ${invoice.member.comment}`,
        date: invoice.events.memberDisputedAt
      });
    }

    if (invoice.events.clientResolvedDisputeAt) {
      activity.push({
        title: "Dispute resolved",
        description: `Client resolved dispute on ${
          (toWSDateString(invoice.events.clientResolvedDisputeAt), "monthDate")
        }`,
        date: invoice.events.clientResolvedDisputeAt
      });
    }

    if (invoice.events.memberAcceptedAt) {
      activity.push({
        title: "Invoice accepted by contractor",
        description: toWSDateString(invoice.events.memberAcceptedAt),
        date: invoice.events.memberAcceptedAt
      });
    }

    if (invoice.events.clientDeclinedAt) {
      activity.push({
        title: `Rejected by ${payerEmail}`,
        description: `Reject reason: ${invoice.client.comment}`,
        date: invoice.events.clientDeclinedAt
      });
    }

    if (invoice.events.memberResubmittedAt) {
      activity.push({
        title: `Resubmitted by ${payeeEmail}`,
        description: `You resubmitted at ${toWSDateString(
          invoice.events.memberResubmittedAt
        )}`,
        date: invoice.events.memberResubmittedAt
      });
    }

    (queryInvoiceCollaborators.data || []).forEach(invoiceCollaborator => {
      if (!invoiceCollaborator.payee || !invoiceCollaborator.payable) {
        console.error("No payee or payable found for invoice collaborator");
        return;
      }

      const payeeName = getPayeeName(invoiceCollaborator.payee);

      if (invoiceCollaborator.payable.events.depositedAt) {
        activity.push({
          title: `${payeeName} received ${toWSMoneyString(
            invoiceCollaborator.amount,
            "default",
            invoiceCollaborator.currency
          )}`,
          description: toWSDateString(
            invoiceCollaborator.payable.events.depositedAt
          ),
          date: invoiceCollaborator.payable.events.depositedAt
        });
      }
    });

    activity.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });

    return activity;
  }, [
    invoice.client.comment,
    invoice.client?.payDate,
    invoice.createdAt,
    invoice.events.approvedAt,
    invoice.events.clientDeclinedAt,
    invoice.events.clientResolvedDisputeAt,
    invoice.events.depositedAt,
    invoice.events.emailReceivedAt,
    invoice.events.emailViewedAt,
    invoice.events.estimatedDepositAt,
    invoice.events.instantPayoutAt,
    invoice.events.memberAcceptedAt,
    invoice.events.memberDisputedAt,
    invoice.events.memberResubmittedAt,
    invoice.events.openedAt,
    invoice.events.paidAt,
    invoice.events.paymentInTransitAt,
    invoice.events.preApprovedAt,
    invoice.events.sentDue3DaysAgoAt,
    invoice.events.sentDue7DaysAgoAt,
    invoice.events.sentDueIn3DaysAt,
    invoice.events.sentDueTodayAt,
    invoice.events.unapprovedAt,
    invoice.member.comment,
    payer.user?.email,
    queryInvoiceCollaborators.data,
    queryUser.data
  ]);

  return (
    <Card>
      <WSText.Heading5 mb="XL">All activity</WSText.Heading5>
      <Activity activity={activity} />
    </Card>
  );
};
