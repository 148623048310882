import React, { useMemo } from "react";
import {
  WSChip,
  WSElement,
  WSEmptyState,
  WSFlexBox,
  WSInfiniteScroll,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { SearchPanel } from "../../Invoicing/components/SearchPanel";
import { useHistory } from "react-router-dom";
import { PAYERS_PATH } from "./RouteClients";
import { useUrlQueryFilters } from "../../../utils/router";
import { ClientsTable } from "../components/ClientsTable";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { PayerRowsQuery } from "../../../services/search";
import { useQueryPayerRowsSummary } from "../../../query/search/payer/queries/useQueryPayerRowsSummary";

import styles from "./styles.module.scss";

export const RouteSearchClients: React.FC = () => {
  useBrowserPageTitle("Search clients");

  const history = useHistory();
  const { filters, setFilters } = useUrlQueryFilters<{
    search: string;
  }>({
    search: ""
  });

  const requestFilters: PayerRowsQuery = useMemo(
    () => ({
      filter: {
        searchString: filters.search || undefined
      }
    }),
    [filters.search]
  );

  const queryRows = useQueryPayerRows(requestFilters);
  const queryPayerRowsSummary = useQueryPayerRowsSummary(requestFilters);
  return (
    <WSPage>
      <SearchPanel
        mb="L"
        context="clients"
        onBack={() => {
          history.push(PAYERS_PATH);
        }}
        value={filters.search || ""}
        onSearch={newValue => {
          setFilters({
            ...filters,
            search: newValue
          });
        }}
        onClear={() => {
          setFilters({
            search: ""
          });
        }}
        placeholder="Search clients"
      />
      {!!filters.search && (
        <>
          {/* <WSFilters
            mt="XL"
            mb="XL"
            values={{}}
            onFilter={newValues => {}}
            info={{
              count: queryPayerRowsSummaryData.listSize
            }}
          /> */}
          <WSFlexBox.CenterY mb="L" className={styles.statsContainer}>
            <WSElement className={styles.resultsStats}>
              <WSText.ParagraphXs color="gray500">Showing:</WSText.ParagraphXs>
              <WSChip
                pillText={`${queryPayerRowsSummary.data?.listSize ?? 0} item${
                  queryPayerRowsSummary.data?.listSize === 1 ? "" : "s"
                }`}
                theme="neutral"
                pillType="noBackground"
              />
            </WSElement>
          </WSFlexBox.CenterY>
          {queryRows.data?.length === 0 ? (
            <WSEmptyState
              hasBorder
              fullWidth
              bgColor="gray"
              type="search"
              title={`No results for "${filters.search}"`}
              description="Try adjusting your search or filter to find what you’re looking for. Clear search or filter to see all items."
              buttons={[
                {
                  label: "Clear search",
                  kind: "Secondary",
                  onClick: () => {
                    setFilters({
                      search: ""
                    });
                  }
                }
              ]}
            />
          ) : (
            <WSInfiniteScroll
              onLoad={() => {
                queryRows.fetchNextPage();
              }}
              loadMore={queryRows.data && queryRows.data.length > 0}
              endOfList={!queryRows.hasNextPage}
              loading={!!queryRows.isFetchingNextPage}
            >
              <ClientsTable
                listPath={PAYERS_PATH}
                clients={queryRows.data ?? []}
                loading={queryRows.isInitialLoading}
              />
            </WSInfiniteScroll>
          )}
        </>
      )}
    </WSPage>
  );
};
