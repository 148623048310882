import { WSButton, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import React, { useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { usePayerQuery } from "../../query/payers/queries/usePayerQuery";
import { useInvoiceTemplateQuery } from "../../query/payments/queries";
import { DrawerLayout } from "../components/DrawerLayout/DrawerLayout";
import {
  InvoiceSeriesTable,
  SeriesTableItemData
} from "../components/InvoiceSeriesTable/InvoiceSeriesTable";
import { SeriesInvoiceActionsModal } from "../components/SeriesInvoiceActionsModal/SeriesInvoiceActionsModal";

export const InvoicesSeriesDetails: React.FC<
  RouteComponentProps<{
    invoiceTemplateId: string;
  }>
> = ({ match, location }) => {
  const history = useHistory();

  const invoiceTemplateId = match.params.invoiceTemplateId;
  const queryInvoiceTemplate = useInvoiceTemplateQuery(invoiceTemplateId);
  const queryPayer = usePayerQuery(
    queryInvoiceTemplate.data?.invoiceData.clientId!,
    {
      enabled: !!queryInvoiceTemplate.data?.invoiceData.clientId
    }
  );

  const invoiceTemplate = queryInvoiceTemplate.data;
  const payer = queryPayer.data;

  const onBack = () => {
    history.replace({
      pathname: location.pathname.replace("/series", ""),
      search: location.search
    });
  };

  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [showAllPast, setShowAllPast] = useState(false);

  const [upcomingData, pastData] = useMemo(() => {
    const result: [SeriesTableItemData[], SeriesTableItemData[]] = [[], []];

    (queryInvoiceTemplate.data?.scheduleDates || []).forEach(
      (scheduleDate, index) => {
        if (scheduleDate.date > new Date()) {
          result[0].push({
            index,
            scheduleDate
          });
        } else {
          result[1].push({
            index,
            scheduleDate
          });
        }
      }
    );

    return result;
  }, [queryInvoiceTemplate.data?.scheduleDates]);

  const initialUpcomingLimit = pastData.length > 0 ? 3 : 6;

  if (!invoiceTemplate || !payer) {
    return (
      <DrawerLayout title="Series details" onBack={onBack}>
        <WSLoader.Spinner />
      </DrawerLayout>
    );
  }

  return (
    <DrawerLayout title="Series details" onBack={onBack}>
      <SeriesInvoiceActionsModal />

      <WSText mb="XL">Select invoice to edit</WSText>

      {upcomingData.length > 0 && (
        <>
          <WSText.ParagraphSm color="gray500" mb="XS">
            Upcoming invoices
          </WSText.ParagraphSm>
          <InvoiceSeriesTable
            data={upcomingData.slice(
              0,
              showAllUpcoming ? undefined : initialUpcomingLimit
            )}
            parentInvoiceTemplate={invoiceTemplate}
            payer={payer}
            mb="XS"
          />
          {!showAllUpcoming &&
            upcomingData.length - initialUpcomingLimit > 0 && (
              <WSButton.Link
                onClick={() => {
                  setShowAllUpcoming(true);
                }}
              >
                Load {upcomingData.length - initialUpcomingLimit} more
              </WSButton.Link>
            )}
        </>
      )}

      {pastData.length > 0 && (
        <>
          <WSText.ParagraphSm color="gray500" mt="2XL" mb="XS">
            Past invoices
          </WSText.ParagraphSm>
          <InvoiceSeriesTable
            noActions
            data={pastData.slice(0, showAllPast ? undefined : 3)}
            parentInvoiceTemplate={invoiceTemplate}
            payer={payer}
            mb="XS"
          />
          {!showAllPast && pastData.length - 3 > 0 && (
            <WSButton.Link
              onClick={() => {
                setShowAllPast(true);
              }}
            >
              Load {pastData.length - 3} more
            </WSButton.Link>
          )}
        </>
      )}
    </DrawerLayout>
  );
};
