import { WSElement, WSList, WSText } from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";

export type ActivityItem = {
  title: ReactNode;
  description: string;
  date: Date;
};

type Props = {
  activity: ActivityItem[];
};

export const Activity: React.FC<Props> = ({ activity }) => (
  <WSList gap="S">
    {activity.map((item, index) => (
      <WSElement key={String(index)}>
        <WSText weight="medium" mb="XS" color="gray600">
          {item.title}
        </WSText>
        <WSText color="gray500">{item.description}</WSText>
      </WSElement>
    ))}
  </WSList>
);
