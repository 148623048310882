import {
  toWSMoneyString,
  useWSModal,
  WSCard,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import {
  useBankingAccount,
  useBankingBalance
} from "../../../query/bookkeeping/queries";
import { ModalAccountDetails } from "./ModalAccountDetails";

export const AvailableBalance: React.FC = () => {
  const queryBankingAccount = useBankingAccount();
  const queryBankingBalance = useBankingBalance();

  const history = useHistory();
  const modalAccountDetails = useWSModal(ModalAccountDetails, {
    size: "S"
  });
  const availableBalance = queryBankingBalance.data?.available || 0;
  const currentBalance = queryBankingBalance.data?.balance || 0;
  const pendingAmounts = queryBankingBalance.data?.hold || 0;

  return (
    <WSCard
      shimmer={
        queryBankingBalance.isInitialLoading ||
        queryBankingAccount.isInitialLoading
      }
      fullHeight
      header={{
        value: {
          action: queryBankingAccount.data?.numbers?.account
            ? {
                label: `(...${queryBankingAccount.data.numbers.account.slice(
                  queryBankingAccount.data.numbers.account.length - 4
                )})`,
                rightIcon: "chevron-right",
                onClick: () => modalAccountDetails.open()
              }
            : undefined
        },
        label: {
          text: "Available balance"
        }
      }}
      divider
      actions={{
        alignment: "fill",
        sortButtons: false,
        buttons: [
          {
            label: "Transfer",
            kind: "Secondary",
            onAsyncClick: async () => {
              history.push("/member/transfer?balance=wallet");
            }
          }
          /*
          {
            label: "View transactions",
            kind: "Link",
            rightIcon: "chevron-right",
            onClick: () => {
              history.push("/member/wallet/transactions");
            }
          }
          */
        ]
      }}
    >
      <WSText.Heading1
        weight="book"
        color={availableBalance > 0 ? "gray600" : "gray200"}
        mb="XL"
      >
        {toWSMoneyString(availableBalance)}
      </WSText.Heading1>

      <WSFlexBox.CenterY justify="space-between" mb="M">
        <WSText.ParagraphSm color="gray500">Current balance</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray600">
          {toWSMoneyString(currentBalance)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="gray500">Pending amounts</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">
          {toWSMoneyString(pendingAmounts)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSText.ParagraphXs color="gray500"> </WSText.ParagraphXs>
    </WSCard>
  );
};
