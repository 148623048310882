import { EnabledExperimentsResponse } from "../types/experiments";
import { WSService } from "../utils/WSService";

const service = new WSService("/experiments");

export const getEnabledExperiments =
  async (): Promise<EnabledExperimentsResponse> => {
    const {
      data: { enabledExperiments }
    } = await service.get("/enabled-experiments");

    return enabledExperiments;
  };

export const getEnabledAnonymousExperiments = async (
  anonymousId: string
): Promise<EnabledExperimentsResponse> => {
  const {
    data: { enabledExperiments }
  } = await service.get(`/enabled-experiments/anonymous/${anonymousId}`);

  return enabledExperiments;
};
