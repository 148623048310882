import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { WSQuery } from "../../../query/WSQuery";
import { PATH_ONBOARDING_VERIFICAIONS_BANKING } from "../../Onboarding";

export const TURN_ON_PAYMENTS = "TURN_ON_PAYMENTS";

export const TaxesModalTurnOnPayment: React.FC = () => {
  const memberId = useUserId();
  const history = useHistory();
  const { closeModal } = useModalOldContext();
  const qMemberProfile = useMemberProfile(memberId);

  return (
    <WSModalOld name={TURN_ON_PAYMENTS} size="S" title="Enable payments">
      <WSQuery query={qMemberProfile}>
        {member => {
          const withholdingTax = member.data.profile?.withholdings?.tax;

          return (
            <>
              <WSText mb="XS">
                Set aside {withholdingTax?.rate}% of each invoice paid through
                Wingspan.
              </WSText>
              <WSButtons mt="2XL" forceFullWidth format="modal">
                <WSButton.Primary
                  size="M"
                  onClick={async () => {
                    history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);

                    closeModal(TURN_ON_PAYMENTS);
                  }}
                >
                  Enable banking
                </WSButton.Primary>
                <WSButton.Tertiary
                  size="M"
                  onClick={() => {
                    closeModal(TURN_ON_PAYMENTS);
                  }}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          );
        }}
      </WSQuery>
    </WSModalOld>
  );
};
