import React from "react";

import {
  WSActions,
  WSElement,
  WSPillTheme,
  WSSectionToolbar,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { IconType } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSPill/types";
import queryString from "qs";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useQueryInvoicesRows } from "../../../Invoicing/queries/useQueryInvoicesRows";
import { IInvoiceRow, InvoiceRowStatus } from "../../../Invoicing/service";

type RecentInvoicesWidgetProps = {
  payerId: string;
};

export const RecentInvoicesWidget: React.FC<RecentInvoicesWidgetProps> = ({
  payerId
}) => {
  const history = useHistory();

  const queryInvoicesRows = useQueryInvoicesRows({
    filter: {
      clientId: payerId
    },
    page: {
      size: 5,
      number: 1
    }
  });

  return (
    <WSQueries
      queries={{ queryInvoicesRows }}
      renderErrors={() => null}
      renderLoader={() => null}
    >
      {({ queryInvoicesRowsData }) => {
        if (!queryInvoicesRowsData.length) {
          return null;
        }

        return (
          <WSElement mb="XL">
            <WSSectionToolbar title="Recent invoices" mb="S" />

            <WSTable<IInvoiceRow>
              tableData={queryInvoicesRowsData.map(invoice => ({
                id: invoice.invoiceId,
                data: invoice
              }))}
              onRowClick={row => {
                history.push(`/member/invoices/${row.id}`);
              }}
              columns={[
                {
                  config: {
                    header: {
                      text: "Invoice #"
                    },
                    gridTemplateSizeMax: "2fr"
                  },
                  renderFunction: ({ data }) => {
                    // const engagement = engagements.find(
                    //   e => e.payerPayeeEngagementId === data.memberClientId
                    // );

                    return (
                      <WSTableCell
                        text={`#${data.invoiceNumber ?? "Pending"}`}
                        // secondaryText={
                        //   engagement
                        //     ? getTmpEngagementName(engagement)
                        //     : undefined
                        // }
                      />
                    );
                  }
                },
                {
                  config: {
                    header: {
                      text: "Status"
                    }
                  },
                  renderFunction: ({ data }) => {
                    let icon: IconType<any> = "time";
                    let theme: WSPillTheme = "neutral";
                    let badge: boolean = false;

                    if (data.status === InvoiceRowStatus.AwaitingClient) {
                      icon = "calendar";
                    } else if (
                      data.status === InvoiceRowStatus.ActionRequired
                    ) {
                      icon = "alert";
                      theme = "warning";
                      badge = true;
                    } else if (data.status === InvoiceRowStatus.Deposited) {
                      icon = true;
                      theme = "success";
                    } else if (data.status === InvoiceRowStatus.InProgress) {
                      icon = "check";
                    } else {
                      icon = "edit";
                    }

                    return (
                      <WSTableCell
                        pill={
                          badge
                            ? {
                                badge,
                                theme,
                                icon
                              }
                            : {
                                theme,
                                icon
                              }
                        }
                      />
                    );
                  }
                }
              ]}
            />
            <WSActions
              alignment="left"
              size="M"
              mt="M"
              buttons={[
                {
                  label: "View all in invoices page",
                  kind: "Link",
                  onClick: () => {
                    const query = queryString.stringify({
                      clientId: payerId
                    });
                    history.push(`/member/invoices?${query}`);
                  }
                }
              ]}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
