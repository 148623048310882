import {
  WSActions,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSLayout,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { track } from "../../../../utils/analytics";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

import { DownloadContractorExampleTemplateButton } from "../../components/DownloadContractorExampleTemplateButton";
import { ContractorColumnsStackedView } from "../../components/ContractorColumnsStackedView";
import { useModalContractorColumnsGuide } from "../../components/ModalContractorColumnsGuide";
import { useCreateBulkCollaboratorBatch } from "../../query/bulkCollaborator/mutations";
import {
  useGoToContractorBatchDetails,
  useGoToContractorBatches,
  useGoToPayablesImportStart
} from "../../paths";

import styles from "./styles.module.scss";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";

export const RouteImportContractorsStart: React.FC = () => {
  useBrowserPageTitle("Bulk upload contractors: Start");
  const contractorsColumnsGuideModal = useModalContractorColumnsGuide();
  const queryFeatureFlags = useFeatureFlags();
  const [createBulkCollaboratorBatch, createBulkCollaboratorBatchMeta] =
    useCreateBulkCollaboratorBatch();

  const goToContractorBatchDetails = useGoToContractorBatchDetails();
  const goToContractorBatches = useGoToContractorBatches();
  const goToPayablesImportStart = useGoToPayablesImportStart();
  const onBack = () => {
    goToContractorBatches();
  };

  const onCreateUploadBatch = async () => {
    await createBulkCollaboratorBatch(
      {},
      {
        onSuccess: response => {
          track("Contractors Bulk Upload Started");
          goToContractorBatchDetails(response.bulkCollaboratorBatchId, {
            showBatchCreatedModal: queryFeatureFlags.data?.newBulkImporterUI
          });
        }
      }
    );
  };
  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Bulk upload contractors
        </WSText.ParagraphSm>
      }
      onClose={onBack}
    >
      <WSDivider />
      <WSElement colorBackground="gray50">
        <WSContainer verticalPadding>
          <WSGrid gutter="L">
            <WSGrid.Item span={{ xs: "12", m: "7" }}>
              <WSText.Paragraph weight="medium" color="gray700">
                Bulk upload contractors
              </WSText.Paragraph>

              <WSText.ParagraphSm weight="book" color="gray500">
                Use this importer tool to add new contractors or update existing
                contractor records in a single batch with a spreadsheet. Here’s
                what to expect:
              </WSText.ParagraphSm>
              <WSPanel my="2XL" p="XL">
                <WSInfoBox className={styles.content}>
                  <ol>
                    <WSList>
                      <li>
                        <b>Set up your spreadsheet</b>
                        <ul>
                          <li>
                            Get started by formatting your spreadsheet. Please
                            review the reminders to the right.
                          </li>
                        </ul>
                      </li>

                      <li>
                        <b>Create batch and upload spreadsheet</b>
                        <ul>
                          <li>
                            When you’re ready, create an upload batch and upload
                            the formatted CSV or Excel spreadsheet of your
                            contractors.
                          </li>
                          <li>
                            Should any information or formatting issues arise,
                            this is an opportunity to correct them. You have the
                            option to delete the batch and start again, if
                            needed.
                          </li>
                        </ul>
                      </li>

                      <li>
                        <b>Complete import</b>
                        <ul>
                          <li>
                            Once the spreadsheet is uploaded you can complete
                            the import and process the spreadsheet.
                          </li>
                          <li>
                            Import details includes information about import
                            statistics and outcomes (success or errors). If
                            applicable, any import errors can be corrected and
                            addressed with a subsequent import.
                          </li>
                          <li>
                            Optionally, name or add notes to the import batch
                            for your reference.
                          </li>
                        </ul>
                      </li>
                    </WSList>
                  </ol>
                </WSInfoBox>
              </WSPanel>

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    name: "back",
                    kind: "Secondary",
                    label: "Cancel",
                    onClick: onBack
                  },
                  {
                    name: "next",
                    label: "Create upload batch",
                    onClick: onCreateUploadBatch,
                    loading: createBulkCollaboratorBatchMeta.isLoading
                  }
                ]}
              />
            </WSGrid.Item>

            <WSGrid.Item span={{ xs: "12", m: "5" }}>
              <WSPanel p="XL">
                <WSText.Paragraph weight="medium" color="gray600" mb="M">
                  Reminders
                </WSText.Paragraph>
                <WSList gap="L">
                  <WSInfoBox>
                    <b>Adding new contractors:</b>
                    <ul>
                      <li>
                        Newly created contractors automatically receive an
                        invitation email to sign-up for Wingspan.
                      </li>
                      <li>
                        Optional fields capture more information when adding
                        contractors.
                      </li>
                      <li>
                        Don't worry if your spreadsheet has other details, but
                        please make sure it contains the required columns.
                      </li>
                    </ul>

                    <ContractorColumnsStackedView my="L" mx="XL" />

                    <b>Updating existing contractors:</b>
                    <ul>
                      <li>
                        Identify the contractor: provide at least one of
                        Wingspan user ID, External ID, or Email.
                      </li>
                      <li>
                        Field overwrites: columns you include overwrite existing
                        values.
                      </li>
                      <li>
                        Archived contractors: if an identifier matches an
                        archived contractor, that contractor is reactivated with
                        no new invitation sent.
                      </li>
                    </ul>

                    <WSText.ParagraphSm weight="book" color="gray600" inline>
                      Need help setting up your spreadsheet?
                      <WSButton.Link
                        onClick={() => {
                          contractorsColumnsGuideModal.open();
                        }}
                      >
                        Learn more here.
                      </WSButton.Link>
                    </WSText.ParagraphSm>

                    <DownloadContractorExampleTemplateButton mt="L" />
                  </WSInfoBox>
                  <WSInfoBox px="L" py="XL">
                    <b>Tax filing (1099s):</b>
                    <WSText.ParagraphSm weight="book" color="gray600" mb="XL">
                      If you are filing 1099s with Wingspan, this information is
                      relevant to you.
                    </WSText.ParagraphSm>
                    <ul>
                      <li>
                        Optionally include Contractor W-9 information if you
                        have it on file.
                      </li>
                      <li>
                        Contractors receiving payments on Wingspan will
                        automatically populate their 1099-NECs (U.S. only) when
                        it comes time to generate tax forms.
                      </li>
                      <li>
                        If you've been making payments outside of Wingspan or
                        switched to Wingspan mid-year, ensure that you’ve
                        uploaded all contractors using this importer and their
                        payments information (through the payables page) by each
                        January filing deadline to complete any gaps in payment
                        information.
                      </li>
                    </ul>

                    <WSText.ParagraphSm weight="book" color="gray600" inline>
                      Missing out on tax filing with Wingspan?
                      <WSButton.Link
                        onClick={() => {
                          openInNewTab(
                            "https://www.wingspan.app/solutions/offload-1099-filing"
                          );
                        }}
                      >
                        Learn more here.
                      </WSButton.Link>
                    </WSText.ParagraphSm>
                  </WSInfoBox>
                </WSList>
                <WSFlexBox.CenterY mb="M">
                  <WSText.ParagraphSm singleLine color="gray500">
                    Looking for past imports?
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    icon="details"
                    rightIcon="chevron-right"
                    size="M"
                    name="bulkUploadContractors"
                    onClick={() => goToContractorBatches()}
                  >
                    See imports
                  </WSButton.Link>
                </WSFlexBox.CenterY>

                <WSFlexBox.CenterY>
                  <WSText.ParagraphSm singleLine color="gray500">
                    Looking to bulk upload payments?
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    icon="upload"
                    rightIcon="chevron-right"
                    size="M"
                    name="bulkUploadPayments"
                    onClick={() => {
                      goToPayablesImportStart();
                    }}
                  >
                    Bulk upload payments
                  </WSButton.Link>
                </WSFlexBox.CenterY>
              </WSPanel>
            </WSGrid.Item>
          </WSGrid>
        </WSContainer>
      </WSElement>
    </WSLayout>
  );
};
