import {
  WSButton,
  WSEmptyState,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";

import { useCustomizationQuery } from "../../../../../query/customization/queries/useCustomizationQuery";
import { WSQueries } from "../../../../../query/WSQuery";

import styles from "./styles.module.scss";
import { useGotoEmailInvitesAddOrUpdateSnippet } from "../../../paths";
import isEmpty from "lodash/isEmpty";

export const RouteEmailInvites: React.FC = () => {
  const customizationQuery = useCustomizationQuery();
  const gotoEditSnippet = useGotoEmailInvitesAddOrUpdateSnippet();

  return (
    <WSGrid>
      <WSGrid.Item span={{ m: "12" }}>
        <WSText.Paragraph weight="medium" color="gray700" mb="S">
          Custom email snippet
        </WSText.Paragraph>
        <WSText.ParagraphSm weight="book" color="gray500" mb="XL">
          Set up custom email snippet for your invite emails to contractors
        </WSText.ParagraphSm>

        <WSQueries queries={{ customizationQuery }}>
          {({ customizationQueryData: customization }) => {
            return !isEmpty(customization) ? (
              <WSPanel px="M" py="S">
                <WSFlexBox.CenterY
                  justify="space-between"
                  wrap="nowrap"
                  onClick={() => {
                    gotoEditSnippet();
                  }}
                >
                  <WSText.ParagraphSm
                    weight="book"
                    color="gray600"
                    className={styles.snippetName}
                  >
                    Contractor invite
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    rightIcon="chevron-right"
                    onClick={() => {
                      gotoEditSnippet();
                    }}
                  >
                    Edit
                  </WSButton.Link>
                </WSFlexBox.CenterY>
              </WSPanel>
            ) : (
              <WSEmptyState
                hasBorder
                fullWidth
                bgColor="gray"
                type="tasks"
                title="No snippet yet"
                description="Add a custom email snippet for your invite emails to contractors"
                buttons={[
                  {
                    label: "Add snippet",
                    onClick: () => {
                      gotoEditSnippet();
                    }
                  }
                ]}
              />
            );
          }}
        </WSQueries>
      </WSGrid.Item>
    </WSGrid>
  );
};
