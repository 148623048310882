import { useLocation, useRouteMatch } from "react-router-dom";
import queryString from "qs";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSButtons,
  WSControl,
  WSDivider,
  WSElement,
  WSList,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { getCorrectionTypeFromCorrectionRequest } from "./getCorrectionTypeFromCorrectionRequest";
import { AddressCorrectionDiffPreview } from "../../../TaxCorrections/components/AddressCorrectionDiffPreview";
import { AmountCorrectionDiffPreview } from "../../../TaxCorrections/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxCorrections/components/TINOrNameCorrectionDiffPreview";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { UnchangedRecipientDetails } from "../../components/UnchangedRecipientDetails";
import React, { useState } from "react";
import { selectorPayeeTaxFormPayerCompanyName } from "../../selectors/selectorPayeeTaxFormPayerCompanyName";
import {
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { transformCorrectionRequestToFormData } from "./transformCorrectionRequestToFormData";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { useTaxFormApproveCorrection } from "../../../../query/taxForm/mutations/useTaxFormApproveCorrection";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { AreYouReadyForCorrection } from "../../../TaxCorrections/components/AreYouReadyForCorrection";
import { SetupRouteReviewCorrectionFlowProps } from "./RouteReviewCorrectionFlow";
import { WSQueryCache } from "@ws-react-query";
import { QUERY_PAYMENTS_TAXFORM_PAYER } from "../../../../query/taxForm/keys";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";

export const RouteSubmitCorrection: React.FC<
  SetupRouteReviewCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const [consent, setConsent] = useState(false);
  const location = useLocation();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const onCancel = onBack;

  const queryTaxForm = usePayerTaxForm(taxFormId);
  const [approveCorrection, approveCorrectionMeta] =
    useTaxFormApproveCorrection(taxFormId);
  const { force }: { force?: string } = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const forceCorrection = !!Number(force);

  const onEditUnchangedDetails = (corrections: string[]) => {
    onEdit({
      corrections,
      showRequestedValue: false
    });
  };

  const onAmountEdit = () => {
    onEdit({
      corrections: [CORRECTION_AMOUNT],
      showRequestedValue: true
    });
  };

  const onTINOrNameEdit = () => {
    onEdit({
      corrections: [CORRECTION_TIN_OR_NAME],
      showRequestedValue: true
    });
  };

  const onAddressEdit = () => {
    onEdit({
      corrections: [CORRECTION_ADDRESS],
      showRequestedValue: true
    });
  };

  const onEdit = ({
    corrections,
    showRequestedValue
  }: {
    corrections: string[];
    showRequestedValue: boolean;
  }) => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const correctionRequest =
      taxForm?.pendingCorrection as ITaxFormCorrectionResponse;
    onNext({
      isEdit: true,
      backPath: location.pathname,
      corrections,
      showRequestedValue,
      correctionsFormData: transformCorrectionRequestToFormData(
        corrections,
        taxForm,
        correctionRequest
      )
    });
  };

  const onSubmitCorrection = async () => {
    const taxForm = queryTaxForm?.data as IPayerTaxFormResponse;
    await approveCorrection(
      {
        correctionId: taxForm?.pendingCorrection?.taxFormCorrectionId as string,
        requestData: {
          forceCorrection
        }
      },
      {
        onSuccess: payload => {
          WSQueryCache.removeQueries(QUERY_PAYMENTS_TAXFORM_PAYER);
          onNext({ isSuccess: true, submissionId: payload.submissionId });
        }
      }
    );
  };

  return (
    <EditLayout title="Create and submit correction" noBack onClose={onCancel}>
      <WSElement>
        <WSText.Paragraph weight="book" mb="XS">
          Review 1099-NEC correction request
        </WSText.Paragraph>
        <WSText.ParagraphSm color="gray500" mb="2XL">
          Please review to ensure the information is accurate before proceeding
        </WSText.ParagraphSm>

        <WSQueries queries={{ queryTaxForm }}>
          {({ queryTaxFormData: taxForm }) => {
            const correctionRequest = taxForm?.pendingCorrection;
            if (!correctionRequest) {
              return (
                <WSMessageBox.Info
                  button={{
                    kind: "Link",
                    children: "Refresh",
                    onClick: () => {
                      window.location.reload();
                    }
                  }}
                >
                  Please refresh the web page if you can't see correction
                  details.
                </WSMessageBox.Info>
              );
            }

            const { corrections, isCompanyStructureChangeOnly, isVoidType } =
              getCorrectionTypeFromCorrectionRequest(taxForm);

            const hasUnchangedDetails =
              !corrections.includes(CORRECTION_AMOUNT) ||
              !corrections.includes(CORRECTION_TIN_OR_NAME) ||
              !corrections.includes(CORRECTION_ADDRESS);

            const isType1Correction =
              corrections.length === 1 &&
              corrections.includes(CORRECTION_AMOUNT);

            const correctionTypeToPreviewComponentMap: Record<
              string,
              JSX.Element
            > = {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  onEdit={onAmountEdit}
                  amount={taxForm?.data?.totalAmount ?? 0}
                  correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                  comment={
                    correctionRequest.payerOwnedData?.comment ||
                    correctionRequest.payeeOwnedData?.comment ||
                    ""
                  }
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  isVoidType={isVoidType}
                  isForce={forceCorrection}
                  onEdit={onTINOrNameEdit}
                  identificationNumberType={
                    correctionRequest.data?.w9Info?.ein ||
                    correctionRequest.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  originalTinData={getPayeeTINorName(taxForm)}
                  correctedTinData={{
                    ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                    ein: correctionRequest?.data?.w9Info?.ein ?? "",
                    businessName:
                      correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                    firstName: correctionRequest?.data?.w9Info?.firstName,
                    lastName: correctionRequest?.data?.w9Info?.lastName,
                    disregardedEntityName:
                      correctionRequest?.data?.w9Info?.disregardedEntityName,
                    taxClassification: correctionRequest?.data?.w9Info
                      ?.companyStructure as CompanyStructure
                  }}
                  reasonForChange={
                    correctionRequest?.payeeOwnedData?.reason ?? ""
                  }
                  otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
                  hasOtherEligibleCorrectionChanges={
                    !isCompanyStructureChangeOnly
                  }
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  onEdit={onAddressEdit}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  correctedAddress={{
                    addressLine1:
                      correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                    addressLine2:
                      correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                    city: correctionRequest?.data?.w9Info?.city ?? "",
                    state: correctionRequest?.data?.w9Info?.state ?? "",
                    postalCode:
                      correctionRequest?.data?.w9Info?.postalCode ?? "",
                    country: correctionRequest?.data?.w9Info?.country ?? ""
                  }}
                />
              )
            };
            return (
              <WSList gap="XL">
                {corrections.map(correctionType => {
                  const DiffPreviewComponent =
                    correctionTypeToPreviewComponentMap[correctionType];
                  return (
                    <WSElement key={correctionType}>
                      {DiffPreviewComponent}
                    </WSElement>
                  );
                })}
                <WSDivider />

                {hasUnchangedDetails ? (
                  <UnchangedRecipientDetails
                    onEdit={onEditUnchangedDetails}
                    taxForm={taxForm}
                    corrections={corrections}
                  />
                ) : null}

                <AreYouReadyForCorrection
                  isType1Correction={isType1Correction}
                  amountChanges={corrections.includes(CORRECTION_AMOUNT)}
                  tinAddressChanges={
                    corrections.includes(CORRECTION_TIN_OR_NAME) ||
                    corrections.includes(CORRECTION_ADDRESS)
                  }
                />
                <WSControl
                  type="checkbox"
                  size="S"
                  name="consent"
                  value={consent}
                  onChange={setConsent}
                  label={`Under penalties of perjury, I declare that to the best of my knowledge. ${selectorPayeeTaxFormPayerCompanyName(
                    taxForm
                  )} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.`}
                />

                <WSErrorMessage
                  contextKey={ErrorContextKey.NEC1099PayerApproveCorrection}
                  error={approveCorrectionMeta.error}
                />

                <WSButtons forceFullWidth>
                  <WSButton.Primary
                    disabled={!consent}
                    onClick={onSubmitCorrection}
                    loading={approveCorrectionMeta.isLoading}
                  >
                    Submit correction to the IRS
                  </WSButton.Primary>
                  <WSButton.Tertiary onClick={onCancel}>
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </WSList>
            );
          }}
        </WSQueries>
      </WSElement>
    </EditLayout>
  );
};
