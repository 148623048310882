import {
  toWSDateString,
  WSAlert,
  WSElementProps,
  WSInfoBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  TaxFormCorrectionStatus,
  TaxFormExclusionReason,
  TaxFormStatus,
  TaxFormW9Source
} from "@wingspanhq/payments/dist/interfaces";
import { IActivity, IMember } from "@wingspanhq/users";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { selectorPayeeTaxFormPayerName } from "../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import { selectorPayeeTaxFormSupportContacts } from "../selectors/selectorPayeeTaxFormSupportContacts";
import { getPaymentsLinkForTaxDocument } from "../utils/getPaymentsLinkForTaxDocument";
import { useGoToTaxDocumentByIds, useGoToTaxDocumentByTaxForm } from "../paths";
import {
  GetTaxFormCardButton,
  TaxFormCardProps,
  TaxFormCardView
} from "./TaxFormCardView";
import { selectorPayeeTaxFormCurrentCorrection } from "../selectors/selectorPayeeTaxFormCurrentCorrection";
import { getYearDateRange } from "../utils/getYearDateRange";
import { useTaxFormDownloadDocument } from "../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { downloadFileFromBlob } from "../../../utils/files";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { getFormFileName } from "../../TaxFiling/utils/formFileName";
import { RelatedSubmissions } from "../../TaxCorrections/components/RelatedSubmissions";

export interface TaxFormPreviewProps extends WSElementProps {
  taxForm: IPayeeTaxFormResponse;
  member: IMember;
  activity: IActivity;
}

const MainText: React.FC = ({ children }) => (
  <WSText.ParagraphSm color="gray500" weight="book">
    {children}
  </WSText.ParagraphSm>
);

const SecondaryText: React.FC = ({ children }) => (
  <WSText.ParagraphSm color="gray400" weight="book">
    {children}
  </WSText.ParagraphSm>
);

const NonUsAddressAlert: React.FC = () => (
  <WSAlert theme="info" icon="info-circle" title="Non-U.S. mailing address">
    We noticed that your tax form delivery preference is set to physical mail.
    Wingspan can only deliver physical copies by mail to U.S. addresses. Please
    contact your payer about mailing forms outside of Wingspan and the U.S.
  </WSAlert>
);

export const TaxFormCard: React.FC<TaxFormPreviewProps> = ({
  taxForm,
  member,
  activity,
  ...otherProps
}) => {
  const history = useHistory();
  const goToTaxDocument = useGoToTaxDocumentByTaxForm();
  const goToTaxDocumentByIds = useGoToTaxDocumentByIds();

  const now = new Date();
  const currentYear = taxForm.year;

  const PAYER_CORRECTION_REVIEW_START = new Date(
    taxForm.year + 1,
    0,
    24,
    0,
    0,
    0
  ); // 24th of January

  const { supportPhone, supportEmail } =
    selectorPayeeTaxFormSupportContacts(taxForm);
  const payerName = selectorPayeeTaxFormPayerName(taxForm);
  const taxClassificationLabel = selectorTaxClassificationLabel(
    taxForm?.data?.w9Info?.companyStructure
  );
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();

  const isUS = taxForm.data?.w9Info?.country === "US";
  const isMailDelivery = taxForm.data?.deliveryMethod === "Mail";

  const getViewPaymentsButton: GetTaxFormCardButton = () => {
    return {
      kind: "Tertiary",
      label: "View payments",
      rightIcon: "chevron-right",
      onClick: () => {
        const defaultRange = getYearDateRange(taxForm.year);

        history.push(
          getPaymentsLinkForTaxDocument(
            taxForm.clientId,
            taxForm.startDate || defaultRange.startDate,
            taxForm.endDate || defaultRange.endDate
          )
        );
      }
    };
  };

  const getDownloadTaxFormSubmissionButton = useMemo(
    () =>
      (title: string): GetTaxFormCardButton =>
      () =>
        taxForm.currentSubmissionId
          ? {
              kind: "Primary",
              label: title,
              rightIcon: "download",
              loading: downloadDocumentMeta.isLoading,
              onClick: async () => {
                await downloadDocument(
                  {
                    taxFormId: taxForm.taxFormId,
                    submissionId: taxForm.currentSubmissionId!,
                    viewer: TaxFormViewer.payee
                  },
                  {
                    onSuccess(pdf) {
                      const formPayeeFileName = getFormFileName({
                        viewer: TaxFormViewer.payee,
                        year: taxForm.year || 2024,
                        payerName,
                        payeeW9: taxForm.data?.w9Info
                      });

                      downloadFileFromBlob(pdf, formPayeeFileName);
                    },
                    async onError(err) {
                      const data = err.response?.data as any as Blob;
                      if (data) {
                        const rawError = JSON.parse(await data.text());
                        console.log(rawError);
                      }
                    }
                  }
                );
              }
            }
          : null,
    [taxForm, downloadDocument, downloadDocumentMeta.isLoading]
  );

  const getViewDetailsButton: GetTaxFormCardButton = () => ({
    kind: "Secondary",
    label: "View details",
    rightIcon: "chevron-right",
    onClick: () => {
      goToTaxDocument(taxForm);
    }
  });

  const getViewToDoTaskPrimary: GetTaxFormCardButton = () => ({
    onClick: () => {
      history.push(`/member/dashboard`);
    },
    label: "View to-do tasks",
    kind: "Primary",
    rightIcon: "chevron-right"
  });

  const getContactPayerButton: GetTaxFormCardButton = () =>
    supportEmail
      ? {
          onClick: () => {
            openInNewTab("mailto:" + supportEmail);
          },
          label: "Contact payer support",
          kind: "Secondary",
          rightIcon: "comment"
        }
      : null;

  const notIssuedPill = {
    text: "Not issued",
    theme: "neutral",
    badge: true
  } as const;

  const notYetFiledPill = {
    text: "Not yet filed",
    theme: "neutral",
    badge: true
  } as const;

  const getMultipleFormsPill = () =>
    taxForm.submissions?.length && taxForm.submissions?.length > 1
      ? ({
          text: "Multiple forms",
          theme: "neutral",
          icon: "collection"
        } as const)
      : undefined;

  const excludedCards: Record<
    TaxFormExclusionReason,
    Omit<TaxFormCardProps, "payerName">
  > = {
    [TaxFormExclusionReason.BelowThreshold]: {
      pill: notIssuedPill,
      buttons: [getContactPayerButton, getViewPaymentsButton],
      content: (
        <>
          <MainText>
            You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
            {currentYear} because you did not meet the $600 payment threshold.
          </MainText>
          <SecondaryText>
            If you think there was an error, please contact the payer for
            additional support.
          </SecondaryText>
        </>
      )
    },
    [TaxFormExclusionReason.IneligibleCompanyStructure]: {
      pill: notIssuedPill,
      buttons: [getContactPayerButton, getViewPaymentsButton],
      content: (
        <>
          <MainText>
            You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
            {currentYear} because your federal tax classification is{" "}
            {taxClassificationLabel}.{" "}
            <a
              href="https://www.irs.gov/instructions/i1099mec"
              target="_blank"
              rel="noreferrer"
            >
              According to IRS guidelines
            </a>
            , corporations are not eligible to receive Form 1099-NEC.
          </MainText>
          <SecondaryText>
            If this is an error, please contact the payer to reflect your
            accurate federal tax classification.
          </SecondaryText>
        </>
      )
    },
    [TaxFormExclusionReason.NonUsEntity]: {
      pill: notIssuedPill,
      buttons: [getContactPayerButton, getViewPaymentsButton],
      content: (
        <>
          <MainText>
            You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
            {currentYear} because you are not a U.S. Business or Individual.
          </MainText>
          <SecondaryText>
            If this is an error, please contact the payer for additional
            support.
          </SecondaryText>
        </>
      )
    },
    [TaxFormExclusionReason.ManuallyExcluded]: {
      pill: notIssuedPill,
      buttons: [getContactPayerButton, getViewPaymentsButton],
      content: (
        <>
          <MainText>
            You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
            {currentYear}.
          </MainText>
          <SecondaryText>
            If you are expecting a tax form, please contact the payer for
            additional support.
          </SecondaryText>
        </>
      )
    }
  };

  let matchedTaxForm: Omit<TaxFormCardProps, "payerName"> | undefined;

  if (taxForm.status === TaxFormStatus.Excluded) {
    matchedTaxForm = taxForm.exclusionReason
      ? excludedCards[taxForm.exclusionReason]
      : excludedCards[TaxFormExclusionReason.ManuallyExcluded];
  } else if (taxForm.status === TaxFormStatus.ReadyToSubmitToIrs) {
    matchedTaxForm = {
      pill: notYetFiledPill,
      content: (
        <>
          <MainText>Your Form 1099 has not yet been filed.</MainText>
          <SecondaryText>
            Once the payer has submitted the tax form for filing, it will become
            available here. Please contact the payer for additional support.
          </SecondaryText>
        </>
      )
    };
  } else if (TaxFormStatus.NeedsAction === taxForm.status) {
    matchedTaxForm = {
      pill: notYetFiledPill,
      buttons: [getViewToDoTaskPrimary, getContactPayerButton],
      content: (
        <>
          <MainText>Your Form 1099 has not yet been filed.</MainText>
          <SecondaryText>
            Complete all to-do tasks, including confirming your taxpayer info is
            up to date. Once the payer has submitted the tax form for filing, it
            will become available here. Please contact the payer for additional
            support.
          </SecondaryText>
        </>
      )
    };
  } else if (
    [
      TaxFormStatus.SubmittedToIrs,
      TaxFormStatus.AcceptedByIrs,
      TaxFormStatus.RejectedByIrs,
      TaxFormStatus.PendingCorrection
    ].includes(taxForm.status)
  ) {
    if (taxForm.recipientConfirmedW9Info !== true) {
      const filedByNonPayee = {
        text: "Filed with payer’s records",
        theme: "neutral",
        badge: true
      } as const;

      if (isUS) {
        matchedTaxForm = {
          buttons: [getContactPayerButton /*, getViewToDoTasks */],
          pill: filedByNonPayee,
          content: (
            <>
              <MainText>
                You were not issued a digital Form 1099-NEC from {payerName} for
                tax year {currentYear} because taxpayer information was not
                shared through the Wingspan platform. The payer used the
                information they had on file for this year’s tax filing. You
                will receive a copy of the form in the mail based on the address
                on file.
              </MainText>
              <SecondaryText>
                To receive future filings digitally through Wingspan, go to your
                homepage to complete outstanding tasks. Please contact the payer
                for additional support.
              </SecondaryText>
            </>
          )
        };
      } else {
        matchedTaxForm = {
          buttons: [getContactPayerButton /*, getViewToDoTasks */],
          pill: filedByNonPayee,
          content: (
            <>
              <MainText>
                You were not issued a digital Form 1099-NEC from {payerName} for
                tax year {currentYear} because taxpayer information was not
                shared through the Wingspan platform. The payer used the
                information they had on file for this year’s tax filing. You
                will receive a copy of the form in the mail based on the address
                on file.
              </MainText>
              {isMailDelivery ? <NonUsAddressAlert /> : null}
            </>
          )
        };
      }
    } else {
      const correction = selectorPayeeTaxFormCurrentCorrection(taxForm);
      const hasCorrections = !correction;

      const isPayerFiledWithEdit =
        taxForm.data?.w9Info?.w9Source === TaxFormW9Source.Overwrite;
      const isNonUSMailDelivery = isMailDelivery && !isUS;

      const reason = correction?.payerOwnedData?.comment || "";
      const pendingCorrectionDate = correction?.events.createdAt;

      matchedTaxForm = {
        pill: getMultipleFormsPill(),
        buttons: [
          getDownloadTaxFormSubmissionButton(
            `Download ${
              taxForm.submissions && taxForm.submissions?.length > 1
                ? "(Updated)"
                : ""
            }`
          ),
          getViewDetailsButton
        ],
        content: (
          <>
            {correction?.status === TaxFormCorrectionStatus.Pending && (
              <>
                <MainText>
                  You requested a correction on{" "}
                  {toWSDateString(pendingCorrectionDate, "monthDayYear")}. The
                  payer is still reviewing your correction request.{" "}
                </MainText>

                {now < PAYER_CORRECTION_REVIEW_START ? (
                  <WSInfoBox>
                    <b>
                      Starting{" "}
                      {toWSDateString(
                        PAYER_CORRECTION_REVIEW_START,
                        "monthDayYear"
                      )}
                    </b>
                    , you will be notified when the payer accepts or rejects
                    your correction request.
                  </WSInfoBox>
                ) : null}
              </>
            )}
            {correction?.status === TaxFormCorrectionStatus.Submitted && (
              <MainText>
                {/*has correction && correction.status == CorrectionStatus.Submitted */}
                {payerName} issued a correction to your {currentYear} Form
                1099-NEC form. The corrected form was submitted to the IRS.
              </MainText>
            )}

            {/*<MainText>*/}
            {/*  {payerName} issued a correction to your {currentYear} Form*/}
            {/*  1099-NEC form.*/}
            {/*</MainText>*/}

            {correction?.status === TaxFormCorrectionStatus.Rejected && (
              <>
                {/* has rejected corrections && correction?.payerOwnedData?.comment */}
                <MainText>
                  {payerName} rejected your correction request with reason:
                </MainText>
                {reason && <WSInfoBox theme="warning">{reason}</WSInfoBox>}
              </>
            )}

            {correction?.status ===
              TaxFormCorrectionStatus.AppliedWithoutSubmission && (
              <MainText>
                {/*has corrections &&  correction.status === AppliedWithoutSubmission */}
                <b>You’re all set for now!</b> The requested address change does
                not require a correction to the IRS for filing purposes. We’ve
                updated the address on your profile and no correction request
                was sent to the payer. Please contact the payer if you would
                like to have the tax forms re-mailed to your new address.
              </MainText>
            )}

            {correction &&
              correction?.status !==
                TaxFormCorrectionStatus.AppliedWithoutSubmission && (
                <MainText>
                  {/* has corrections && correction.status !== AppliedWithoutSubmission */}
                  <b>
                    Only one (1) correction request can be made through Wingspan
                    to your payer for a tax year.
                  </b>{" "}
                  If there are additional errors to correct after the payer’s
                  response, please reach out to the payer directly to resolve
                  the issue.
                </MainText>
              )}

            {hasCorrections ? (
              <SecondaryText>
                {/* has corrections */}
                Your original {currentYear} Form 1099-NEC from {payerName} is
                available.
              </SecondaryText>
            ) : (
              <SecondaryText>
                {/* no corrections */}
                Your {currentYear} Form 1099-NEC is now available.
              </SecondaryText>
            )}

            <RelatedSubmissions
              viewer={TaxFormViewer.payee}
              taxForm={taxForm}
              redirectTo={(taxFormId, submissionId) =>
                goToTaxDocumentByIds(taxFormId, submissionId)
              }
            />

            {!hasCorrections && (
              <SecondaryText>
                {/* no corrections */}
                Notice an error? You can request a correction from the details
                page.
              </SecondaryText>
            )}
            {isNonUSMailDelivery && <NonUsAddressAlert />}
            {isPayerFiledWithEdit && (
              <WSAlert
                theme="info"
                icon="info-circle"
                title="Payer filed with edits to taxpayer info"
              >
                The payer filed with information from their records regarding
                1099-NEC total and taxpayer information. If you notice an error,
                you can request a correction from the detail page or contact
                payer support for additional help.
              </WSAlert>
            )}
          </>
        )
      };
    }
  }

  if (matchedTaxForm) {
    return (
      <TaxFormCardView
        content={matchedTaxForm.content}
        supportEmail={supportEmail}
        supportPhone={supportPhone}
        buttons={matchedTaxForm.buttons}
        payerName={payerName}
        pill={matchedTaxForm.pill}
      />
    );
  }

  return null;
};
