import { WSButton, WSButtonProps } from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useDownloadInvoicePdf } from "../../../query/payments/mutations";

type Props = WSButtonProps<"Link"> & { clientInvoice: IClientInvoice };

export const DonwloadPdfButton: React.FC<Props> = ({
  clientInvoice,
  onClick,
  ...buttonProps
}) => {
  const [downloadInvoicePdf, downloadInvoicePdfMeta] = useDownloadInvoicePdf();

  return (
    <WSButton.Link
      onClick={(...args) => {
        downloadInvoicePdf({
          invoiceId: clientInvoice.invoiceId
        });

        onClick?.(...args);
      }}
      {...buttonProps}
      loading={downloadInvoicePdfMeta.isLoading}
    >
      Download
    </WSButton.Link>
  );
};
