import {
  toWSDateString,
  useIsDesktop,
  WSAlert,
  WSButton,
  WSDataItem,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import api from "@flatfile/api";
import { CSVLink } from "react-csv";
import {
  useBulkCollaboratorBatch,
  useBulkCollaboratorBatchSummary
} from "../../query/bulkCollaborator/queries";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { useCollaboratorGroupQuery } from "../../../../query/payments/queries";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { useGoToContractorBatches } from "../../paths";
import { useDownloadContractorExampleTemplate } from "../../components/DownloadContractorExampleTemplateButton";

import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { ContractorBatchStatusWidget } from "./ContractorBatchStatusWidget";
import { ContractorBatchDetailsWidget } from "./ContractorBatchDetailsWidget";
import { useFlatfileSpaceConfig } from "../../hooks/useFlatfileSpaceConfig";
import { BulkResource } from "../../types";
import {
  FlatfileProvider,
  Space,
  useFlatfile,
  useListener
} from "@flatfile/react";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { flatfileProviderConfig } from "../../utils/flatfileThemeConfig";
import {
  getBulkBatchAge,
  isFileUploaded,
  isOldBulkBatch,
  isUploadInProgress
} from "../../utils/bulkBatchUtils";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import FlatfileListener, { FlatfileEvent } from "@flatfile/listener";
import { CONTRACTORS_ACTION_SLUG } from "../../constants";
import { useModalNewContractorBatchCreated } from "../../components/ModalNewContractorBatchCreated";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "../../query/bulkCollaborator/keys";
import { WSQueryCache } from "@ws-react-query";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { useDownloadErrorsCSV } from "./useDownloadErrorsCSV";
import { useModalEditBulkBatchName } from "../../components/ModalEditBulkBatchName";
import {
  BulkStatus,
  IBulkCollaboratorBatch
} from "@wingspanhq/payments/dist/interfaces";
import { BulkBatchImportSteps } from "../../components/BulkBatchImportSteps";
import { BulkBatchStatusAlert } from "../../components/BulkBatchStatusAlert";
import { useBulkBatchActions } from "../../hooks/useBulkBatchActions";
import { useModalConfirmBulkBatchDelete } from "../../components/ModalConfirmBulkBatchDelete";
import { BulkBatchEventHistoryWidget } from "../../components/BulkBatchEventHistoryWidget";

const ContractorsBatchDetails: React.FC<{
  bulkBatch: IBulkCollaboratorBatch;
}> = ({ bulkBatch }) => {
  const history = useHistory();
  const location = useLocation<{ showBatchCreatedModal?: boolean }>();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const [showOldBatchAlert, setShowOldBatchAlert] = useState(true);
  const exampleTemplateCSVLinkRef = useRef<any>(null);
  const errorsCSVLinkRef = useRef<any>(null);

  const queryBulkCollaboratorBatch = useBulkCollaboratorBatch(bulkBatchId);
  const queryBulkCollaboratorBatchSummary =
    useBulkCollaboratorBatchSummary(bulkBatchId);
  const isEmptyBatch = !!(
    queryBulkCollaboratorBatchSummary.data &&
    queryBulkCollaboratorBatchSummary.data.collaboratorsCount === 0
  );

  const collaboratorGroupId = queryBulkCollaboratorBatch.data?.labels
    .collaboratorGroupId as string;

  const queryCollaboratorGroup = useCollaboratorGroupQuery(
    collaboratorGroupId as string,
    {
      enabled: !!collaboratorGroupId
    }
  );

  const isEngagementsActive = useIsEngagementsActive();

  const engagementId = queryBulkCollaboratorBatch.data?.labels
    .engagementId as string;
  const queryEngagement = useEngagementQuery(engagementId, {
    enabled: !!engagementId && isEngagementsActive
  });

  const goToContractorBatches = useGoToContractorBatches();
  const { csvProps: exampleTemplateCSVProps } =
    useDownloadContractorExampleTemplate();
  const { errorsCSVFileProps } = useDownloadErrorsCSV(
    bulkBatchId,
    BulkResource.Collaborator
  );
  const downloadExampleCSV = () => {
    exampleTemplateCSVLinkRef.current.link.click();
  };
  const downloadErrorsCSV = () => {
    errorsCSVLinkRef.current.link.click();
  };
  let actions = useBulkBatchActions({
    bulkBatchId,
    bulkResource: BulkResource.Collaborator,
    isEmptyBatch,
    downloadExampleCSV,
    downloadErrorsCSV
  });
  // hide the download spreadsheet button for failed/partially imported batches
  actions = actions.filter(action => action.name !== "downloadErrorsCSV");

  const newBatchCreatedModal = useModalNewContractorBatchCreated();
  const editBulkBatchNameModal = useModalEditBulkBatchName();
  const deleteBulkBatchModal = useModalConfirmBulkBatchDelete();

  const onCloseFlatfileModal = () => {
    WSQueryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_LIST);
    WSQueryCache.invalidateQueries([
      QUERY_BULK_COLLABORATOR_BATCH,
      queryBulkCollaboratorBatch.data?.bulkCollaboratorBatchId
    ]);
    WSQueryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_SUMMARY);
  };
  const { openPortal, closePortal } = useFlatfile({
    onClose: onCloseFlatfileModal
  });

  // Open the new batch created modal only once
  useEffect(() => {
    if (location.state?.showBatchCreatedModal) {
      const onDelete = () => {
        deleteBulkBatchModal.open({
          bulkBatchId,
          bulkResource: BulkResource.Collaborator,
          isEmptyBatch
        });
      };
      newBatchCreatedModal.open({
        bulkBatchId,
        onDelete,
        onAck: () => {
          history.replace(location.pathname);
          openPortal();
        }
      });
    }
  }, []);

  const spaceProps = useFlatfileSpaceConfig(
    bulkBatch,
    BulkResource.Collaborator
  );

  useListener((listener: FlatfileListener) => {
    listener.on("space:created", (event: FlatfileEvent) => {
      const { spaceId } = event.context;
      console.log("spaceId", spaceId);
    });

    listener.on(
      "job:outcome-acknowledged",
      { job: `workbook:${CONTRACTORS_ACTION_SLUG}` },
      async event => {
        const { spaceId } = event.context;
        console.log(
          `[job:outcome-acknowledged]:workbook:${CONTRACTORS_ACTION_SLUG} -- spaceId`,
          spaceId
        );

        const space = await api.spaces.get(spaceId);

        // close the flatfile portal if there are no rejections
        const { data: workbookSheets } = await api.sheets.list({
          workbookId: space.data.metadata?.wsInternalWorkbookId
        });
        const rejectionSheet = workbookSheets.find(s =>
          s.slug.includes("rejections")
        );
        if (rejectionSheet) {
          const rejectionRecords = rejectionSheet.recordCounts?.total;
          if (rejectionRecords === 0) {
            closePortal();
          }
        }
      }
    );
  });

  const isDesktop = useIsDesktop();

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryBulkCollaboratorBatch,
          queryBulkCollaboratorBatchSummary
        }}
      >
        {({
          queryBulkCollaboratorBatchData: collaboratorBatch,
          queryBulkCollaboratorBatchSummaryData: bulkBatchSummary
        }) => {
          const date = toWSDateString(
            collaboratorBatch.createdAt,
            "monthDayYear"
          );
          return (
            <>
              {/* Render Flatfile space component */}
              <Space {...spaceProps} />
              {/* Render hidden example template csv link component */}
              <CSVLink
                ref={exampleTemplateCSVLinkRef}
                className={styles.hiddenButton}
                {...exampleTemplateCSVProps}
              />
              {/* Render hidden errors csv link component */}
              <CSVLink
                ref={errorsCSVLinkRef}
                className={styles.hiddenButton}
                {...errorsCSVFileProps}
              />
              <WSPageToolbar
                breadcrumbs={[
                  {
                    label: "Bulk imports (contractors)",
                    onClick: () => {
                      goToContractorBatches();
                    }
                  },
                  { label: date }
                ]}
                actions={actions}
              />

              {/* Batch name and edit action  */}
              <WSFlexBox.CenterY mb="S">
                <WSText.Heading3 weight="medium" mr="L">
                  {collaboratorBatch.labels?.[
                    BATCH_LABELS_WS_KEYS.bulkBatchName
                  ] || date}
                </WSText.Heading3>
                <WSButton.Link
                  onClick={() => {
                    editBulkBatchNameModal.open({
                      bulkBatchId: collaboratorBatch.bulkCollaboratorBatchId,
                      bulkResource: BulkResource.Collaborator
                    });
                  }}
                >
                  Edit name
                </WSButton.Link>
              </WSFlexBox.CenterY>

              {/* Show original spreadsheet filename if file is uploaded */}
              {isFileUploaded(collaboratorBatch) && (
                <WSFlexBox.CenterY>
                  <WSIcon name="file" mr="XS" size="XS" />
                  <WSText.ParagraphXs weight="book" color="gray600">
                    {getUploadedFilename(
                      collaboratorBatch,
                      BulkResource.Collaborator
                    )}
                  </WSText.ParagraphXs>
                </WSFlexBox.CenterY>
              )}

              <WSGrid gutter="XL" my="2XL">
                <WSGrid.Item span={{ s: "12", m: "8" }}>
                  <WSList>
                    {!isDesktop && (
                      <BulkBatchImportSteps
                        bulkBatchId={bulkBatchId}
                        bulkResource={BulkResource.Collaborator}
                      />
                    )}
                    {/* Batch notes if any */}
                    {collaboratorBatch.labels?.[
                      BATCH_LABELS_WS_KEYS.bulkBatchNotes
                    ] && (
                      <WSPanel>
                        <WSDataItem
                          label={{
                            text: "Notes",
                            action: {
                              label: "Edit",
                              onClick: () => {
                                editBulkBatchNameModal.open({
                                  bulkBatchId:
                                    collaboratorBatch.bulkCollaboratorBatchId,
                                  bulkResource: BulkResource.Collaborator
                                });
                              }
                            }
                          }}
                          value={
                            collaboratorBatch.labels?.[
                              BATCH_LABELS_WS_KEYS.bulkBatchNotes
                            ]
                          }
                        />
                      </WSPanel>
                    )}
                    {/* Show old bulk batch import alert */}
                    {showOldBatchAlert &&
                      collaboratorBatch.status === BulkStatus.Open &&
                      isOldBulkBatch(collaboratorBatch) && (
                        <WSAlert
                          theme="info"
                          icon="alert-circle"
                          title={`This upload is over ${getBulkBatchAge(
                            collaboratorBatch
                          )} old`}
                          onDismiss={() => setShowOldBatchAlert(false)}
                        >
                          This batch contains an upload over{" "}
                          {getBulkBatchAge(collaboratorBatch)} old that was not
                          yet imported and may contain old validation data from
                          the time it was uploaded. To ensure up-to-date
                          validation for your formatted spreadsheet, delete this
                          batch and start a new one to upload and import your
                          spreadsheet. We recommend completing the import as
                          soon as your spreadsheet is finished uploading so it
                          doesn’t sit for an extended period of time.
                        </WSAlert>
                      )}

                    {/* Show Bulk batch status alert */}
                    <BulkBatchStatusAlert
                      bulkBatchId={collaboratorBatch.bulkCollaboratorBatchId}
                      bulkBatch={collaboratorBatch}
                      bulkResource={BulkResource.Collaborator}
                    />

                    <ContractorBatchStatusWidget
                      bulkBatch={collaboratorBatch}
                      bulkBatchSummary={bulkBatchSummary}
                      downloadExampleCSV={downloadExampleCSV}
                      downloadErrorsCSV={downloadErrorsCSV}
                    />

                    {/* Show details widget */}
                    <ContractorBatchDetailsWidget
                      bulkBatch={collaboratorBatch}
                      engagement={queryEngagement.data}
                      collaboratorGroup={queryCollaboratorGroup.data}
                    />

                    {!isDesktop && (
                      <BulkBatchEventHistoryWidget
                        bulkBatch={bulkBatch}
                        bulkResource={BulkResource.Collaborator}
                      />
                    )}
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "12", m: "4" }}>
                  {isDesktop && (
                    <WSList gap="L">
                      <BulkBatchImportSteps
                        bulkBatchId={bulkBatchId}
                        bulkResource={BulkResource.Collaborator}
                      />
                      <BulkBatchEventHistoryWidget
                        bulkBatch={bulkBatch}
                        bulkResource={BulkResource.Collaborator}
                      />
                    </WSList>
                  )}
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};

export const RouteImportContractorsBatchDetails: React.FC = () => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const queryBulkCollaboratorBatch = useBulkCollaboratorBatch(bulkBatchId);

  return (
    <WSQueries queries={{ queryBulkCollaboratorBatch }}>
      {({ queryBulkCollaboratorBatchData: bulkBatch }) => {
        const ffProviderProps = (bulkBatch.labels[
          BATCH_LABELS_WS_KEYS.flatfileAccessToken
        ] as string)
          ? {
              accessToken: bulkBatch.labels[
                BATCH_LABELS_WS_KEYS.flatfileAccessToken
              ] as string
            }
          : {
              publishableKey: process.env
                .REACT_APP_FLATFILE_PUBLISHABLE_KEY as string
            };

        return (
          <FlatfileProvider
            // Do not set key prop if upload is in progress
            {...(isUploadInProgress(bulkBatch)
              ? {}
              : {
                  key:
                    ffProviderProps.accessToken ??
                    ffProviderProps.publishableKey
                })}
            config={{
              debug: !IS_PRODUCTION_ENV,
              ...flatfileProviderConfig
            }}
            {...ffProviderProps}
          >
            <ContractorsBatchDetails bulkBatch={bulkBatch} />
          </FlatfileProvider>
        );
      }}
    </WSQueries>
  );
};
