import { PayeeTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../../query/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";
import { updatePayer } from "../../../../services/payers";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (config?: WSMutationConfig<void>) => {
  return useWSMutation<any, WSServiceError, string[]>(
    async clientIds => {
      return await Promise.all(
        clientIds.map(clientId =>
          updatePayer(clientId, {
            payeeOwnedData: {
              shareTaxDocument: PayeeTaxDocumentSharePermission.Allow
            }
          })
        )
      );
    },
    {
      awaitDependencies: [QUERY_NOTIFICATIONS_NOTIFICATIONS],
      ...config
    }
  );
};
