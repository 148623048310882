import {
  WSFilterDateRangeValue,
  WSFilters,
  WSInfiniteScroll,
  WSList
} from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { useQueryListInternalAccountTransactions } from "../../../query/banking/queries/useQueryListInternalAccountTransactions";
import { operations } from "../../../services/api/banking/types";
import { UrlQueryFilter, useUrlQuery } from "../../../shared/hooks/useUrlQuery";
import { TableTransactions } from "../components/TableTransactions";

type Props = { internalAccountId: string };

interface TransactionUrlQueryFilter extends UrlQueryFilter {
  date: WSFilterDateRangeValue;
}

export const RouteNewTransactions: React.FC<Props> = props => {
  const { urlQuery, setUrlQuery } = useUrlQuery<TransactionUrlQueryFilter>();

  const requestQuery =
    useMemo((): operations["listInternalAccountTransactions"]["parameters"]["query"] => {
      return {};
    }, []);

  const queryListInternalAccountTransactions =
    useQueryListInternalAccountTransactions(
      props.internalAccountId,
      requestQuery
    );

  const transactions = useMemo(
    () =>
      (queryListInternalAccountTransactions.data?.pages || []).flatMap(
        page => page.transactions
      ),
    [queryListInternalAccountTransactions.data?.pages]
  );

  return (
    <WSList gap="2XL">
      {/* <WSFilters<TransactionUrlQueryFilter>
        values={urlQuery.filter}
        onFilter={newValues => {
          setUrlQuery(prev => ({
            ...prev,
            filter: newValues
          }));
        }}
        info={{
          count: queryListInternalAccountTransactions.data?.pages[0]?.total || 0
        }}
        primaryFilters={[
          {
            name: "dateRange",
            nonExpandable: true,
            type: "daterange",
            options: ["all", "pastWeek", "pastMonth", "yearToDate", "custom"]
          }
        ]}
      /> */}

      <WSInfiniteScroll
        onLoad={() => {
          queryListInternalAccountTransactions.fetchNextPage();
        }}
        loadMore={transactions.length > 0}
        endOfList={!queryListInternalAccountTransactions.hasNextPage}
        loading={!!queryListInternalAccountTransactions.isFetchingNextPage}
      >
        <TableTransactions
          showHeader
          transactions={transactions}
          loading={queryListInternalAccountTransactions.isInitialLoading}
          bottomMessage={
            transactions.length === 0 ? "No transactions found." : null
          }
        />
      </WSInfiniteScroll>
    </WSList>
  );
};
