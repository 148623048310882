import {
  WSActions,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSLayout,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { BatchProcessingStrategy } from "@wingspanhq/payments/dist/interfaces";
import React from "react";

import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { track } from "../../../../utils/analytics";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

import {
  useGoToContractorImportStart,
  useGoToPayableBatchDetails,
  useGoToPayableBatches
} from "../../paths";

import { useModalPayablesColumnsGuide } from "../../components/ModalPayableColumnsGuide";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { useCreateBulkPayableBatch } from "../../query/bulkPayable/mutations";
import { PayableColumnsStackedView } from "../../components/PayableColumnsStackedView";
import { DownloadPayableExampleTemplateButton } from "../../components/DownloadPayableExampleTemplateButton";

import styles from "./styles.module.scss";

export const RouteImportPayablesStart: React.FC = () => {
  useBrowserPageTitle("Bulk upload payables: Start");
  const payablesColumnsGuideModal = useModalPayablesColumnsGuide();
  const queryFeatureFlags = useFeatureFlags();
  const [createBulkPayableBatch, createBulkPayableBatchMeta] =
    useCreateBulkPayableBatch();

  const goToPayableBatchDetails = useGoToPayableBatchDetails();
  const goToContractorImportStart = useGoToContractorImportStart();
  const goToPayablesBatches = useGoToPayableBatches();
  const onBack = () => {
    goToPayablesBatches();
  };

  const onCreateUploadBatch = async () => {
    await createBulkPayableBatch(
      {
        // Note: Assuming default processing strategy as Merge
        processingStrategy: BatchProcessingStrategy.Merge
      },
      {
        onSuccess: response => {
          track("Payable Bulk Upload Started");
          goToPayableBatchDetails(response.bulkPayableBatchId, {
            showBatchCreatedModal:
              queryFeatureFlags.data?.newBulkImporterUIForPayables
          });
        }
      }
    );
  };
  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Bulk upload payables
        </WSText.ParagraphSm>
      }
      onClose={onBack}
    >
      <WSDivider />
      <WSElement colorBackground="gray50">
        <WSContainer verticalPadding>
          <WSGrid gutter="L">
            <WSGrid.Item span={{ xs: "12", m: "7" }}>
              <WSText.Paragraph weight="medium" color="gray700">
                Bulk upload payables
              </WSText.Paragraph>

              <WSText.ParagraphSm weight="book" color="gray500">
                Use this importer tool to upload payables in a single batch with
                a spreadsheet. Here’s what to expect:
              </WSText.ParagraphSm>
              <WSPanel my="2XL" p="XL">
                <WSInfoBox className={styles.content}>
                  <ol>
                    <WSList>
                      <li>
                        <b>Set up your spreadsheet</b>
                        <ul>
                          <li>
                            Get started by formatting your spreadsheet. Please
                            review the reminders to the right.
                          </li>
                          <li>
                            A payable should be associated with existing
                            contractors. If you are looking to upload
                            contractors,{" "}
                            <WSButton.Link
                              onClick={() => goToContractorImportStart()}
                            >
                              click here.
                            </WSButton.Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <b>Create batch and upload spreadsheet</b>
                        <ul>
                          <li>
                            When you’re ready, create an upload batch and upload
                            the formatted CSV or Excel spreadsheet of your
                            payables.
                          </li>
                          <li>
                            Should any information or formatting issues arise,
                            this is an opportunity to correct them. You have the
                            option to delete the batch and start again, if
                            needed.
                          </li>
                        </ul>
                      </li>

                      <li>
                        <b>Complete import</b>
                        <ul>
                          <li>
                            Once the spreadsheet is uploaded you can complete
                            the import and process the spreadsheet.
                          </li>
                          <li>
                            Import details includes information about import
                            statistics and outcomes (success or errors). If
                            applicable, any import errors can be corrected and
                            addressed with a subsequent import.
                          </li>
                          <li>
                            Optionally, name or add notes to the import batch
                            for your reference.
                          </li>
                        </ul>
                      </li>
                    </WSList>
                  </ol>
                </WSInfoBox>
              </WSPanel>

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    name: "back",
                    kind: "Secondary",
                    label: "Cancel",
                    onClick: onBack
                  },
                  {
                    name: "next",
                    label: "Create upload batch",
                    onClick: onCreateUploadBatch,
                    loading: createBulkPayableBatchMeta.isLoading
                  }
                ]}
              />
            </WSGrid.Item>

            <WSGrid.Item span={{ xs: "12", m: "5" }}>
              <WSPanel p="XL">
                <WSText.Paragraph weight="medium" color="gray600" mb="M">
                  Reminders
                </WSText.Paragraph>
                <WSList gap="L">
                  <WSInfoBox>
                    <b>Upload a spreadsheet of line items:</b>
                    <ul>
                      <li>
                        Upload a CSV or Excel spreadsheet of your line items.
                        Don't worry if your spreadsheet has other details, but
                        please make sure it contains the required columns to
                        associate payables with the intended contractors.
                      </li>
                      <li>
                        Optional fields capture more information when adding
                        payables.
                      </li>
                    </ul>

                    <PayableColumnsStackedView my="L" mx="XL" />

                    <WSText.ParagraphSm weight="book" color="gray600" inline>
                      Need help setting up your spreadsheet?
                      <WSButton.Link
                        onClick={() => {
                          payablesColumnsGuideModal.open();
                        }}
                      >
                        Learn more here.
                      </WSButton.Link>
                    </WSText.ParagraphSm>

                    <DownloadPayableExampleTemplateButton mt="L" />
                  </WSInfoBox>
                  <WSInfoBox px="L" py="XL">
                    <b>Tax filing (1099s):</b>
                    <WSText.ParagraphSm weight="book" color="gray600" mb="XL">
                      If you are filing 1099s with Wingspan, this information is
                      relevant to you.
                    </WSText.ParagraphSm>
                    <ul>
                      <li>
                        Contractors receiving payments on Wingspan will
                        automatically populate their 1099-NECs (U.S. only) when
                        it comes time to generate tax forms.
                      </li>
                      <li>
                        If you've been making payments outside of Wingspan or
                        switched to Wingspan mid-year, ensure that you’ve
                        uploaded all contractors (through the contractors page)
                        and their payments information using this importer by
                        each January filing deadline to complete any gaps in
                        payment information.
                      </li>
                    </ul>

                    <WSText.ParagraphSm weight="book" color="gray600" inline>
                      Missing out on tax filing with Wingspan?
                      <WSButton.Link
                        onClick={() => {
                          openInNewTab(
                            "https://www.wingspan.app/solutions/offload-1099-filing"
                          );
                        }}
                      >
                        Learn more here.
                      </WSButton.Link>
                    </WSText.ParagraphSm>
                  </WSInfoBox>
                </WSList>
                <WSFlexBox.CenterY mb="M">
                  <WSText.ParagraphSm singleLine color="gray500">
                    Looking for past imports?
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    icon="details"
                    rightIcon="chevron-right"
                    size="M"
                    name="bulkUploadPayables"
                    onClick={() => goToPayablesBatches()}
                  >
                    See imports
                  </WSButton.Link>
                </WSFlexBox.CenterY>

                <WSFlexBox.CenterY>
                  <WSText.ParagraphSm singleLine color="gray500">
                    Looking to bulk upload contractors?
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    icon="upload"
                    rightIcon="chevron-right"
                    size="M"
                    name="bulkUploadContractors"
                    onClick={() => goToContractorImportStart()}
                  >
                    Bulk upload contractors
                  </WSButton.Link>
                </WSFlexBox.CenterY>
              </WSPanel>
            </WSGrid.Item>
          </WSGrid>
        </WSContainer>
      </WSElement>
    </WSLayout>
  );
};
