import { PaymentMethod } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";
import { FlowSetup } from "../../../shared/components/FlowSetup";
import { useWSStore } from "../../../store";
import { SetupConfirm } from "./SetupConfirm";
import { SetupConsent } from "./SetupConsent";
import { SetupCreditCard } from "./SetupCreditCard";
import { SetupPaymentMethod } from "./SetupPaymentMethod";

export type AutopaySetupParams = {
  payeeId?: string;
};

export const AutopaySetup: React.FC<
  RouteComponentProps<{
    payeeId: string;
  }>
> = ({
  match: {
    params: { payeeId }
  }
}) => {
  const history = useHistory();
  const store = useWSStore();
  const basePath = "/member/autopay/" + payeeId;

  const payeeQuery = usePayeeQuery(payeeId);

  if (payeeQuery.isInitialLoading) {
    return <OverlaySpinner />;
  }

  return (
    <FlowSetup
      basePath={basePath}
      params={{ payeeId }}
      routes={[
        {
          path: "/payment-method",
          component: SetupPaymentMethod,
          onBack: () => {},
          onNext: context => {
            if (context?.paymentMethodType === PaymentMethod.Credit) {
              history.push(basePath + "/credit-card");
            } else {
              history.push(basePath + "/consent");
            }
          }
        },
        {
          path: "/credit-card",
          component: SetupCreditCard,
          onBack: () => {
            history.push(basePath + "/payment-method");
          },
          onNext: () => {
            history.push(basePath + "/consent");
          }
        },
        {
          path: "/consent",
          component: SetupConsent,
          onBack: () => {
            history.push(basePath + "/payment-method");
          },
          onNext: () => {
            history.push(basePath + "/confirm");
          }
        },
        {
          path: "/confirm",
          component: SetupConfirm,
          onBack: () => {
            history.push(basePath + "/consent");
          },
          onNext: () => {
            if (store.signUpAutopayDetails?.redirectTo) {
              window.open(store.signUpAutopayDetails.redirectTo, "_self");
            } else {
              history.push("/member/payees/" + payeeId);
            }
          }
        }
      ]}
    />
  );
};
