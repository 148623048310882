import {
  WSFlexBox,
  WSGrid,
  WSIconList,
  WSMarginProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { InfoTooltip } from "../../../../shared/components/InfoTooltip/InfoTooltip";

import styles from "./styles.module.scss";

type Props = WSMarginProps;

export const ContractorColumnsStackedView: React.FC<Props> = props => {
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const requiredFields = ["First name", "Last name", "Email"];
  const optionalFields = ["Phone number", "External contractor ID"];

  collaboratorCustomFields.forEach(field => {
    if (field.required) {
      requiredFields.push(field.name);
    } else {
      optionalFields.push(field.name);
    }
  });

  return (
    <WSGrid gutter="S" {...props}>
      <WSGrid.Item span={{ xs: "12", s: "6" }}>
        <WSFlexBox.CenterY mb="M">
          <WSText.ParagraphSm color="gray500" className={styles.title}>
            Required
          </WSText.ParagraphSm>

          <InfoTooltip
            text={
              <>
                <p>
                  Send an invite to the contractor’s personal email or the email
                  of the contractor’s business that they operate under.
                </p>
                <br />
                <p>
                  We do not recommend using emails provided by your company
                  since the contractor’s account is portable for their own use
                  as a contractor.
                </p>
                <br />
                <p>
                  This information will be confirmed by the contractor when they
                  onboard
                </p>
              </>
            }
            tooltipProps={{
              title: "Contractor email and full name"
            }}
          />
        </WSFlexBox.CenterY>
        <WSIconList.Checks
          items={requiredFields}
          iconProps={{
            name: "check-circle",
            color: "green400"
          }}
          textProps={{
            kind: "ParagraphSm"
          }}
          mb="XL"
        />
      </WSGrid.Item>
      <WSGrid.Item span={{ xs: "12", s: "6" }}>
        <WSText.ParagraphSm color="gray500" mb="M">
          Optional
        </WSText.ParagraphSm>
        <WSIconList.Checks
          items={optionalFields}
          iconProps={{
            name: "info-circle",
            color: "gray500"
          }}
          textProps={{
            kind: "ParagraphSm"
          }}
          mb="XL"
        />
      </WSGrid.Item>
    </WSGrid>
  );
};
