import React, { ComponentType, useCallback, useContext, useState } from "react";

export interface ModalOldCloseContext {
  [key: string]: any;
}
export interface ModalOldBaseProps {
  onClose?: (context?: ModalOldCloseContext) => void;
}

export interface ModalOldRecord<Props extends ModalOldBaseProps = any> {
  name: string;
  visible: boolean;
  props: Props;
}

export interface IWSModalOldContext<Props extends ModalOldBaseProps = any> {
  modals: ModalOldRecord<Props>[];
  addModal: (name: string) => void;
  removeModal: (name: string) => void;
  openModal: (name: string, props?: Props) => void;
  openModalAsync: (
    name: string,
    props?: Props
  ) => Promise<ModalOldCloseContext | void>;
  closeModal: (name: string, context?: ModalOldCloseContext) => void;
}

export const WSModalOldContext = React.createContext<IWSModalOldContext>({
  modals: [],
  addModal: () => {},
  removeModal: () => {},
  openModal: () => {},
  openModalAsync: () => new Promise(() => {}),
  closeModal: () => {}
});

export const useModalOldContext = () => useContext(WSModalOldContext);

export const WSModalOldProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<ModalOldRecord[]>([]);

  const addModal = useCallback(
    (name: string) => {
      setModals((prevModals) => [
        ...prevModals,
        {
          name,
          visible: false,
          props: {}
        }
      ]);
    },
    [setModals]
  );

  const removeModal = useCallback(
    (name: string) => {
      setModals((prevModals) =>
        prevModals.filter((modal) => modal.name !== name)
      );
    },
    [setModals]
  );

  const updateModal = useCallback(
    (name: string, data: Partial<ModalOldRecord>) => {
      setModals((prevModals) =>
        prevModals.map((modal) => {
          if (modal.name === name) {
            return {
              ...modal,
              ...data
            };
          }
          return modal;
        })
      );
    },
    [setModals]
  );

  const openModal = useCallback(
    (name: string, props?: any) => {
      updateModal(name, { visible: true, props });
    },
    [updateModal]
  );

  const openModalAsync = useCallback(
    (name: string, props?: any) =>
      new Promise<ModalOldCloseContext | void>((resolve) => {
        openModal(name, {
          ...props,
          onClose: resolve
        });
      }),
    [openModal]
  );

  const closeModal = useCallback(
    (name: string, context?: ModalOldCloseContext) => {
      const modalRecord = modals.find((record) => record.name === name);

      updateModal(name, { visible: false });

      if (modalRecord?.props?.onClose) {
        modalRecord.props.onClose(context);
      }
    },
    [modals, updateModal]
  );

  return (
    <>
      <WSModalOldContext.Provider
        value={{
          modals,
          addModal,
          removeModal,
          openModal,
          openModalAsync,
          closeModal
        }}
      >
        {children}
      </WSModalOldContext.Provider>
    </>
  );
};

export const withWSModalOldProvider = (Component: ComponentType) => () => (
  <WSModalOldProvider>
    <Component />
  </WSModalOldProvider>
);
