import {
  toWSDate,
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  INewUser,
  IRedactedUser,
  ISubscription,
  NextgenSubscriptionTerm,
  SubscriptionStatus,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  useCreateOrUpdateSubscriptionV3,
  useUpdateNextGenSubscription
} from "../../query/subscriptions/mutations";
import {
  useGetPaymentMethod,
  useGrantedSubscriberListQuery,
  useNextGenSubscriptionPlans,
  useNextGenSubscriptions,
  useSubscriptionPackages,
  useSubscriptionV3Query
} from "../../query/subscriptions/queries";
import { useClientQuery, useUserById } from "../../query/users/queries";
import { getAuthorizedAccountName } from "../../shared/utils/teamUtils";
import { CancelMembershipModal } from "../components/CancelMembershipModal/CancelMembershipModal";
import { SUBSCRIPTION_PACKAGES_ADDONS } from "../components/MembershipPackages/subscriptionPackages";
import { MembershipPaymentMethod } from "../components/MembershipPaymentMethod/MembershipPaymentMethod";
import { NextGenSubscriptionPaymentMethod } from "../components/NextGenSubscriptionPaymentMethod/NextGenSubscriptionPaymentMethod";
import { CancelNextGenSubscriptionsModal } from "../components/NextGenSubscriptionPlans/CancelNextGenSubscriptionsModal";
import { SettingsLoader } from "../components/SettingsLoader/SettingsLoader";
import {
  getCurrentPackage,
  hasSubscription,
  isProfessionalSubscription,
  isSubscriptionCanceled,
  PackageTierTerm
} from "../utils/subscriptionUtils";
import styles from "./AccountTab.module.scss";

export const NextGenMembershipSettingSection: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const clientQuery = useClientQuery(userId);
  const nextGenSubscriptionPlansQuery = useNextGenSubscriptionPlans(userId);
  const nextGenSubscriptionsQuery = useNextGenSubscriptions(userId);

  const [updateNextGenSubscription, updateNextGenSubscriptionMeta] =
    useUpdateNextGenSubscription(userId);

  return (
    <WSQueries
      queries={{
        nextGenSubscriptionPlansQuery,
        nextGenSubscriptionsQuery,
        featureFlagsQuery,
        clientQuery
      }}
      renderLoader={() => <SettingsLoader />}
    >
      {({
        nextGenSubscriptionPlansQuery: { data: nextGenSubscriptionPlans },
        nextGenSubscriptionsQuery: { data: nextGenSubscriptions },
        clientQuery: { data: client }
      }) => {
        const freePlan = nextGenSubscriptionPlans.find(
          plan =>
            plan.prices[NextgenSubscriptionTerm.Monthly].amount === 0 &&
            plan.prices[NextgenSubscriptionTerm.Yearly].amount === 0
        );
        const monthlySubscriptions = nextGenSubscriptions.filter(
          nextGenSub => nextGenSub.term === NextgenSubscriptionTerm.Monthly
        );
        return (
          <WSElement>
            <WSFlexBox direction="column">
              {monthlySubscriptions.length > 0 && (
                <WSFlexBox.CenterY
                  py="XS"
                  px="M"
                  justify="space-between"
                  className={styles.savingTip}
                  mb="XL"
                >
                  <WSText color="gray600">
                    Switch to annual billing and save 33%
                  </WSText>
                  <WSButton.Link
                    name="updateAnnualBilling"
                    loading={updateNextGenSubscriptionMeta.isLoading}
                    onClick={async () => {
                      await Promise.all(
                        monthlySubscriptions.map(
                          async nextGenSub =>
                            await updateNextGenSubscription({
                              subscriptionId: nextGenSub.subscriptionId,
                              term: NextgenSubscriptionTerm.Yearly
                            })
                        )
                      );
                    }}
                  >
                    Update
                  </WSButton.Link>
                </WSFlexBox.CenterY>
              )}
              <WSFlexBox justify="space-between" className={styles.membership}>
                {nextGenSubscriptions.length > 0 ? (
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan(s)
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {nextGenSubscriptions.map(nextGenSub => (
                        <WSText.ParagraphSm
                          color={
                            nextGenSub.cancelAtPeriodEnd ? "gray400" : "gray600"
                          }
                          mb="XS"
                          key={nextGenSub.subscriptionId}
                        >
                          {nextGenSub.plan.name} ({nextGenSub.term}){" "}
                          {nextGenSub.cancelAtPeriodEnd
                            ? `-- will be canceled on ${toWSDate(
                                nextGenSub.currentPeriodEnd,
                                "monthDayYear"
                              )}`
                            : ""}
                        </WSText.ParagraphSm>
                      ))}
                    </WSText.ParagraphSm>
                  </WSElement>
                ) : (
                  // Individual is a FREE subscription
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {freePlan ? freePlan.name : "Free plan"}
                    </WSText.ParagraphSm>
                  </WSElement>
                )}
                <WSFlexBox.CenterY
                  direction="column"
                  alignItems={isMobile ? "flex-start" : "flex-end"}
                  mt={isMobile ? "XL" : "NONE"}
                  className={styles.btnContainer}
                >
                  <WSButton
                    name="gotoPlans"
                    mb="M"
                    onClick={() =>
                      history.push("/member/USA/subscription-plans")
                    }
                  >
                    {nextGenSubscriptions.length > 0
                      ? "Change plan"
                      : "Explore plans"}
                  </WSButton>
                </WSFlexBox.CenterY>
              </WSFlexBox>
              {updateNextGenSubscriptionMeta.error ? (
                <WSText.ParagraphSm weight="medium" mt="M" color="garnet">
                  {String(
                    updateNextGenSubscriptionMeta.error?.response?.data?.error
                  )}
                </WSText.ParagraphSm>
              ) : null}
            </WSFlexBox>
            <CancelMembershipModal />
            <CancelNextGenSubscriptionsModal />
            <NextGenSubscriptionPaymentMethod
              paymentMethod={client.profile.subscriptionPaymentMethod}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};

export const MembershipSettingSection: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { openModal } = useModalOldContext();

  const userId = useUserId();
  const qSubscription = useSubscriptionV3Query(userId);
  const qPaymentMethod = useGetPaymentMethod();
  const qSubscriptionPackages = useSubscriptionPackages();
  const qGrantedSubscriberList = useGrantedSubscriberListQuery();
  const appliedSubscriptionGrant = (qGrantedSubscriberList.data ?? []).find(
    sg => sg.subscriptionGrantId === qSubscription.data?.subscriptionGrantId
  );
  const qPublicUser = useUserById(
    appliedSubscriptionGrant?.grantorId as string,
    {
      enabled: !!appliedSubscriptionGrant?.grantorId
    }
  );

  const [createOrUpdateSubscription, createOrUpdateSubscriptionMeta] =
    useCreateOrUpdateSubscriptionV3(userId);

  return (
    <WSQueries
      queries={{ qSubscriptionPackages }}
      renderLoader={() => <SettingsLoader />}
    >
      {({ qSubscriptionPackages: { data: nextGenSubscriptionPlans } }) => {
        const subscription = qSubscription.data as ISubscription;
        const currentPackage = getCurrentPackage(
          subscription
        ) as PackageTierTerm;
        let subscriptionWithAmount = null;
        if (subscription) {
          const pkg = SUBSCRIPTION_PACKAGES_ADDONS.find(
            sub =>
              sub.package === currentPackage?.package &&
              sub.tier === currentPackage.packageTier
          );
          const termData =
            nextGenSubscriptionPlans[currentPackage.package][
              currentPackage.packageTier
            ][currentPackage.term];
          subscriptionWithAmount = {
            ...termData,
            ...pkg
          };
        }
        return (
          <WSElement>
            <WSFlexBox direction="column">
              {subscription &&
                subscription.subscriptionGrantId &&
                qPublicUser.isSuccess && (
                  <WSFlexBox.CenterY
                    py="XS"
                    px="M"
                    justify="space-between"
                    className={styles.savingTip}
                    mb="XL"
                  >
                    <WSText color="gray600">
                      🎁 Paid membership sponsored by{" "}
                      {getAuthorizedAccountName(
                        qPublicUser.data?.member,
                        qPublicUser.data?.user as INewUser | IRedactedUser
                      )}
                    </WSText>
                  </WSFlexBox.CenterY>
                )}
              {subscription &&
                !subscription.subscriptionGrantId &&
                currentPackage.term === SubscriptionTerm.Monthly && (
                  <WSFlexBox.CenterY
                    py="XS"
                    px="M"
                    justify="space-between"
                    className={styles.savingTip}
                    mb="XL"
                  >
                    <WSText color="gray600">
                      Switch to annual billing and save 33%
                    </WSText>
                    <WSButton.Link
                      name="updateAnnualBilling"
                      loading={createOrUpdateSubscriptionMeta.isLoading}
                      onClick={async () => {
                        createOrUpdateSubscriptionMeta.reset();
                        await createOrUpdateSubscription({
                          package: currentPackage.package,
                          packageTier: currentPackage.packageTier,
                          term: SubscriptionTerm.Yearly
                        });
                      }}
                    >
                      Update
                    </WSButton.Link>
                  </WSFlexBox.CenterY>
                )}
              <WSFlexBox justify="space-between" className={styles.membership}>
                {subscription && subscriptionWithAmount ? (
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {subscriptionWithAmount.title}
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionTerm"
                    >
                      {(function () {
                        if (
                          subscription &&
                          subscription.subscriptionGrantId &&
                          qPublicUser.isSuccess
                        ) {
                          return `Sponsored by ${getAuthorizedAccountName(
                            qPublicUser.data?.member,
                            qPublicUser.data?.user as INewUser | IRedactedUser
                          )}`;
                        } else if (
                          currentPackage.term === SubscriptionTerm.Monthly
                        ) {
                          return "Billed monthly";
                        }
                        return "Billed annually";
                      })()}
                    </WSText.ParagraphSm>
                  </WSElement>
                ) : (
                  // Individual is a FREE subscription
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {SUBSCRIPTION_PACKAGES_ADDONS[0].title}
                    </WSText.ParagraphSm>
                  </WSElement>
                )}
                <WSFlexBox.CenterY
                  direction="column"
                  alignItems={isMobile ? "flex-start" : "flex-end"}
                  mt={isMobile ? "XL" : "NONE"}
                  className={styles.btnContainer}
                >
                  {subscription && !isProfessionalSubscription(subscription) ? (
                    <WSButton
                      name="upgradeSubscription"
                      mb="M"
                      onClick={async () => {
                        history.push("/member/membership-plans");
                      }}
                      icon="reward"
                      loading={createOrUpdateSubscriptionMeta.isLoading}
                    >
                      Explore plans
                    </WSButton>
                  ) : (
                    <WSButton
                      name="gotoPlans"
                      mb="M"
                      onClick={() => history.push("/member/membership-plans")}
                    >
                      {hasSubscription(subscription)
                        ? "Change plan"
                        : "Explore plans"}
                    </WSButton>
                  )}
                  {subscription && !isSubscriptionCanceled(subscription) ? (
                    <WSButton.Link
                      name="cancelSubscription"
                      onClick={() => openModal("cancel-subscription")}
                    >
                      Cancel membership
                    </WSButton.Link>
                  ) : null}
                </WSFlexBox.CenterY>
              </WSFlexBox>
              {createOrUpdateSubscriptionMeta.error ? (
                <WSText.ParagraphSm weight="medium" mt="M" color="garnet">
                  {String(
                    createOrUpdateSubscriptionMeta.error?.response?.data?.error
                  )}
                </WSText.ParagraphSm>
              ) : null}
            </WSFlexBox>
            <CancelMembershipModal />
            <WSDivider my="L" />
            <MembershipPaymentMethod card={qPaymentMethod.data} />

            {subscription &&
              subscriptionWithAmount &&
              !subscription.subscriptionGrantId && (
                <WSFlexBox justify="space-between" alignItems="center">
                  <WSElement>
                    <WSText.ParagraphSm color="gray500" mt="XL">
                      Billing
                    </WSText.ParagraphSm>

                    {!subscription.cancelAtPeriodEnd &&
                      subscription.status !== SubscriptionStatus.canceled && (
                        <WSText.ParagraphSm color="gray500" mt="M">
                          Your next bill is for{" "}
                          <WSText.ParagraphSm
                            weight="medium"
                            color="gray700"
                            inline
                          >
                            ${subscription.nextBillingAmount} on{" "}
                            <WSText.ParagraphSm
                              weight="medium"
                              inline
                              formatDate="monthDayYear"
                            >
                              {subscription.currentPeriodEnd}
                            </WSText.ParagraphSm>
                            .
                          </WSText.ParagraphSm>
                        </WSText.ParagraphSm>
                      )}
                  </WSElement>
                  <WSButton.Link
                    onClick={() =>
                      history.push("/member/membership-billing-history")
                    }
                  >
                    View billing history
                  </WSButton.Link>
                </WSFlexBox>
              )}
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
