import { WSMutationConfig } from "@ws-react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../query/notifications/keys";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { usersService } from "../../../services/users";
import { useSetWSStore } from "../../../store";
import { useWSAnalytics } from "../../../utils/WSAnalytics";

export const useMutationFinishSetup = (config?: WSMutationConfig<void>) => {
  const userId = useUserId();
  const setStore = useSetWSStore();
  const { track } = useWSAnalytics();

  return useWSMutation(
    async () => {
      setStore({ productActivationAfterOnboarding: false });

      await usersService.activity.update(userId, {
        flows: {
          paymentsSetup: {
            complete: true
          }
        }
      });

      track.other("Product activation");
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES, QUERY_NOTIFICATIONS_NOTIFICATIONS],
      ...config
    }
  );
};
