import {
  useIsMobile,
  useModalOldContext,
  WSButton,
  WSFlexBox,
  WSIcon,
  WSImage,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../utils/openInNewTab";
import WingspanOutlineLogo from "../../assets/Wingspan_Outline.svg";
import styles from "./BenefitDetailsV2.module.scss";

export const BENEFIT_DETAILS_TRANSITION_MODAL =
  "BENEFIT_DETAILS_TRANSITION_MODAL";

export const BenefitDetailsTransitionModal: React.FC = () => {
  const isMobile = useIsMobile();
  const { closeModal } = useModalOldContext();
  return (
    <WSModalOld
      name={BENEFIT_DETAILS_TRANSITION_MODAL}
      size="XL"
      centered
      fullScreenOnMobile={isMobile}
      fullScreen
      showCloseIcon={false}
    >
      {({ gettingStarted }) => (
        <WSFlexBox
          direction="column"
          alignItems="center"
          justify="center"
          wrap="nowrap"
          p="M"
        >
          <WSFlexBox
            alignContent="center"
            alignItems="center"
            justify="center"
            mb="XL"
            wrap="nowrap"
          >
            <WSFlexBox
              alignItems="center"
              justify="center"
              alignContent="center"
              className={styles.wingspanLogoContainer}
              mr="XL"
            >
              <WSImage
                src={WingspanOutlineLogo}
                placeholder="Wingspan"
                className={styles.wsLogo}
              />
            </WSFlexBox>
            <WSIcon block name="arrow-right" color="gray600" size="S" mr="XL" />
            <WSImage
              src={gettingStarted?.transitionLogo}
              placeholder="Wingspan"
              className={styles.transitionLogo}
            />
          </WSFlexBox>
          <WSText.Heading4 mb="3XL">
            Complete your enrollment with {gettingStarted?.company}
          </WSText.Heading4>
          <WSButton mb="M" onClick={() => openInNewTab(gettingStarted.ctaLink)}>
            Go to {gettingStarted?.company}
          </WSButton>
          <WSButton.Link
            onClick={() => closeModal(BENEFIT_DETAILS_TRANSITION_MODAL)}
          >
            Return to Wingspan
          </WSButton.Link>
        </WSFlexBox>
      )}
    </WSModalOld>
  );
};
