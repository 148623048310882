import React, { useState } from "react";

import styles from "./WSCategories.module.scss";

import {
  CATEGORY_ICON,
  getAllItemsFromSections,
  getCategoryIcon,
  getItemByValue,
  isEqualValues,
  itemIsMarked,
  WSCategoriesData
} from "./utils";

import {
  WSButton,
  WSButtons,
  WSAvatar,
  WSAvatarIconProps,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSModalOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  CategoryItem,
  CategoryItemValue,
  CategorySections,
  INFLOW_SECTIONS,
  OUTFLOW_SECTIONS,
  PseudoCategory
} from "./categories";
import { CategoryDescriptionsMap } from "./descriptions";
import { mapDataToCategoryState, mapValuesToData } from "./mapping";
import {
  ITransaction,
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  CustomSubcategoriesResponse,
  useSubcategories
} from "../../../query/bookkeeping/queries";
import * as Subcategories from "@wingspanhq/bookkeeping/dist/lib/interfaces/subcategories";

export type WSCategoryIconProps = Omit<WSAvatarIconProps, "icon" | "type"> & {
  value: CategoryItemValue;
};

export const WSCategoryIcon: React.FC<WSCategoryIconProps> = ({
  value,
  ...props
}) => <WSAvatar.Icon size="S" {...props} icon={getCategoryIcon(value)} />;

const buildKey = (value: CategoryItemValue) =>
  [value.pseudoCategory, value.wsCategory, value.subcategory]
    .filter(Boolean)
    .join(",");

const getCustomSubcategories = (
  subcategories?: CustomSubcategoriesResponse["subcategories"],
  wsCategory?: WSCategory
) => {
  return (
    (wsCategory &&
      subcategories?.[wsCategory]?.filter(Boolean).filter(
        c =>
          !Object.values(Subcategories)
            .filter(t => typeof t === "object")
            .some(group => Object.values(group).some(sc => sc === c))
      )) ||
    []
  );
};

const Category: React.FC<{
  selected: boolean;
  hasChildren: boolean;
  item: CategoryItem;
  onSelect(value: CategoryItemValue): void;
  onOpen(value: CategoryItemValue): void;
}> = ({ item, selected, onSelect, onOpen, hasChildren }) => (
  <WSFlexBox.CenterY
    className={styles.category}
    wrap="nowrap"
    onClick={() =>
      item.value.pseudoCategory && !!item.children?.length
        ? onOpen(item.value)
        : item.value.pseudoCategory
        ? onSelect(item.value)
        : !selected && onSelect(item.value)
    }
    data-testid={buildKey(item.value)}
  >
    <WSFlexBox.CenterY className={styles.categoryBody} wrap="nowrap">
      <WSCategoryIcon value={item.value} mr="M" />
      <WSText.ParagraphSm color="gray500" className={styles.categoryName}>
        {item.name}
      </WSText.ParagraphSm>
    </WSFlexBox.CenterY>
    <WSFlexBox.CenterY>
      {selected ? <WSIcon block name="check" color="blue400" size="M" /> : null}
      {hasChildren ? (
        <WSFlexBox.Center
          onClick={() => onOpen(item.value)}
          p="S"
          data-testid={buildKey(item.value) + "-children"}
        >
          <WSIcon block name="chevron-right" color="gray500" />
        </WSFlexBox.Center>
      ) : null}
    </WSFlexBox.CenterY>
  </WSFlexBox.CenterY>
);

const SubCategory: React.FC<{
  selected: boolean;
  item: CategoryItem;
  onSelect(value: CategoryItemValue): void;
}> = ({ selected, onSelect, item }) => (
  <WSFlexBox.CenterY
    className={styles.category}
    wrap="nowrap"
    onClick={() => !selected && onSelect(item.value)}
    data-testid={buildKey(item.value)}
  >
    <WSFlexBox.CenterY className={styles.categoryBody} wrap="nowrap">
      <WSAvatar.Icon mr="M" size="S" icon={getCategoryIcon(item.value)} />
      <WSText.ParagraphSm color="gray500" className={styles.categoryName}>
        {item.name}
      </WSText.ParagraphSm>
    </WSFlexBox.CenterY>
    {selected ? <WSIcon block name="check" color="blue400" size="M" /> : null}
  </WSFlexBox.CenterY>
);

const searchCategory = (searchQuery: string) => {
  const query = searchQuery.toLowerCase().trim();

  return query
    ? CategoryDescriptionsMap.filter(([, labels, category, subcategory]) => {
        if (category.toLowerCase().includes(query)) {
          return true;
        }

        if (subcategory && subcategory.toLowerCase().includes(query)) {
          return true;
        }

        if (labels && labels.some((label: string) => label.includes(query))) {
          return true;
        }

        return false;
      })
    : [];
};

export const MainList: React.FC<{
  selectedValue?: CategoryItemValue;
  allCustomSubcategories?: CustomSubcategoriesResponse["subcategories"];
  isSelectOnly: boolean;
  openedValue?: CategoryItemValue;
  header: string;
  onSelect(value: CategoryItemValue): void;
  onOpen(value: CategoryItemValue): void;
  sections: CategorySections;
}> = ({
  onSelect,
  isSelectOnly,
  sections,
  onOpen,
  header,
  selectedValue,
  allCustomSubcategories
}) => {
  const [search, setSearch] = useState("");
  const searchQuery = search.trim();
  const searchResult = searchCategory(searchQuery);

  return (
    <>
      <WSText.Heading4>Change category</WSText.Heading4>
      <WSText weight="medium" mt="XL" className={styles.title}>
        {header}
      </WSText>
      <WSDivider mt="XL" type="expand" />
      <WSTextInput
        className={styles.search}
        inputClassName={styles.searchInput}
        value={search}
        onChange={event => setSearch(event.target.value)}
        name="search"
        icon="search"
        placeholder="Search"
        key="search"
      />
      {searchQuery ? (
        <>
          {searchResult.length ? (
            <>
              <WSText.ParagraphSm color="gray600">
                {searchResult.length} results
              </WSText.ParagraphSm>
              <WSDivider mt="M" />

              {searchResult.map(([description, , category, subcategory]) => (
                <WSElement
                  key={`${category} ${subcategory}`}
                  mt="M"
                  onClick={() => {
                    onSelect({
                      wsCategory: category,
                      subcategory: subcategory || undefined
                    });
                    setSearch("");
                  }}
                  className={styles.searchItem}
                >
                  <WSFlexBox.CenterY wrap="nowrap">
                    <WSAvatar.Icon
                      mr="M"
                      size="S"
                      icon={CATEGORY_ICON[category] || "info-circle"}
                    />
                    <WSText.ParagraphSm className={styles.categoryName}>
                      {subcategory || category}
                    </WSText.ParagraphSm>
                  </WSFlexBox.CenterY>
                  <WSText.ParagraphSm
                    color="gray500"
                    mt="XS"
                    ml="2XL"
                    pr="XL"
                    className={styles.description}
                  >
                    {description}
                  </WSText.ParagraphSm>
                  <WSDivider mt="M" />
                </WSElement>
              ))}
            </>
          ) : (
            <WSText.ParagraphSm color="gray600">
              No results.{" "}
              <WSButton.Link onClick={() => setSearch("")}>
                Clear search.
              </WSButton.Link>
            </WSText.ParagraphSm>
          )}
        </>
      ) : (
        <>
          <WSDivider type="expand" />
          {sections.map(({ title, categories }) => (
            <WSElement key={title}>
              <WSText.ParagraphSm color="gray500" mt="XL">
                {title}
              </WSText.ParagraphSm>
              <WSDivider mt="XS" />
              {categories
                .filter(c => (isSelectOnly ? !c.value.pseudoCategory : true))
                .map(item => (
                  <Category
                    key={buildKey(item.value)}
                    item={item}
                    hasChildren={
                      isSelectOnly
                        ? (!!item.children?.length ||
                            !!getCustomSubcategories(
                              allCustomSubcategories,
                              item.value.wsCategory
                            ).length) &&
                          !item.value.pseudoCategory
                        : !!item.value.pseudoCategory
                        ? !!item.children?.length
                        : true
                    }
                    selected={itemIsMarked(item, selectedValue)}
                    onSelect={onSelect}
                    onOpen={onOpen}
                  />
                ))}
            </WSElement>
          ))}
        </>
      )}
    </>
  );
};

const CustomCategoryCreate: React.FC<{
  onSubmit(name: string): void;
  onCancel(): void;
  title: string;
}> = ({ title, onCancel, onSubmit }) => {
  const [categoryName, setCategoryName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <>
      <WSText.Heading4 mb="M">{`Name “${title}” subcategory`}</WSText.Heading4>
      <WSTextInput
        error={isSubmitted && !categoryName.trim()}
        name="category"
        value={categoryName}
        onChange={event => setCategoryName(event.target.value)}
      />

      <WSButtons mt="XL">
        <WSButton.Tertiary onClick={onCancel} fullWidth>
          Cancel
        </WSButton.Tertiary>
        <WSButton.Primary
          onClick={() => {
            setIsSubmitted(isSubmitted);

            categoryName.trim() && onSubmit(categoryName);
          }}
          fullWidth
          name="saveCategory"
        >
          Save
        </WSButton.Primary>
      </WSButtons>
    </>
  );
};

export const SubList: React.FC<{
  selectedValue?: CategoryItemValue;
  allCustomSubcategories?: CustomSubcategoriesResponse["subcategories"];
  openedItem: CategoryItem;
  isSelectOnly: boolean;
  subItems: CategoryItem[];
  onBack(): void;
  onSelect(value: CategoryItemValue): void;
  onOpen(value: CategoryItemValue): void;
  header: string;
}> = ({
  onSelect,
  allCustomSubcategories,
  isSelectOnly,
  selectedValue,
  openedItem,
  header,
  subItems,
  onBack,
  onOpen
}) => {
  const [isCreate, setIsCreate] = useState(false);

  if (isCreate) {
    return (
      <CustomCategoryCreate
        onSubmit={name => {
          onSelect({
            type: openedItem.value.type,
            wsCategory: openedItem.value.wsCategory,
            pseudoCategory: openedItem.value.pseudoCategory,
            subcategory: name,
            isCustom: true
          });
        }}
        onCancel={() => setIsCreate(false)}
        title={openedItem.name}
      />
    );
  }

  const currentCustomCategories = getCustomSubcategories(
    allCustomSubcategories,
    openedItem.value?.wsCategory
  );

  return (
    <>
      <WSText.Heading4>Change category</WSText.Heading4>
      <WSText weight="medium" mt="XL">
        {header}
      </WSText>
      <WSDivider mt="XL" type="expand" />

      <WSFlexBox.CenterY className={styles.back} mt="XL" onClick={onBack}>
        <WSIcon block name="arrow-left" color="gray500" mr="XS" size="XS" />
        <WSText.ParagraphSm color="gray500">Back</WSText.ParagraphSm>
      </WSFlexBox.CenterY>

      <WSDivider mt="XL" type="expand" />
      <WSText.ParagraphSm color="gray500" mt="XL">
        {openedItem.name}
      </WSText.ParagraphSm>
      <WSDivider mt="XS" />
      {subItems.map(item =>
        !item.value.subcategory ? (
          <Category
            hasChildren={
              isSelectOnly
                ? !!item.children?.length ||
                  !!getCustomSubcategories(
                    allCustomSubcategories,
                    item.value?.wsCategory
                  ).length
                : true
            }
            key={buildKey(item.value)}
            item={item}
            selected={itemIsMarked(item, selectedValue)}
            onSelect={onSelect}
            onOpen={onOpen}
          />
        ) : (
          <SubCategory
            key={buildKey(item.value)}
            item={item}
            selected={isEqualValues(item.value, selectedValue)}
            onSelect={onSelect}
          />
        )
      )}

      {currentCustomCategories.map((customSubcategory, index) => {
        const item: CategoryItem = {
          name: customSubcategory,
          value: {
            ...openedItem.value,
            isCustom: true,
            subcategory: customSubcategory
          }
        };

        return (
          <SubCategory
            key={customSubcategory + index}
            selected={isEqualValues(item.value, selectedValue)}
            onSelect={onSelect}
            item={item}
          />
        );
      })}

      {!isSelectOnly &&
      !currentCustomCategories.length &&
      selectedValue?.wsCategory === openedItem.value.wsCategory &&
      selectedValue?.isCustom ? (
        <SubCategory
          item={{
            name: selectedValue.subcategory as string,
            value: selectedValue
          }}
          selected
          onSelect={() => {}}
        />
      ) : null}

      {!isSelectOnly &&
      !openedItem.value.pseudoCategory &&
      !openedItem.children?.some(c => {
        return c.value.type === TransactionType.Transfer && c.value.subcategory;
      }) ? (
        <WSFlexBox.CenterY
          className={styles.category}
          wrap="nowrap"
          onClick={() => setIsCreate(true)}
          data-testid="createCustom"
        >
          <WSFlexBox.CenterY className={styles.categoryBody} wrap="nowrap">
            <WSAvatar.Icon
              mr="M"
              size="S"
              icon={getCategoryIcon(openedItem.value)}
            />
            <WSText.ParagraphSm color="gray500" className={styles.categoryName}>
              Custom
              {` ${openedItem.name}`.toLowerCase()}
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
          <WSElement p="S">
            <WSIcon block name="chevron-right" color="gray500" />
          </WSElement>
        </WSFlexBox.CenterY>
      ) : null}
    </>
  );
};

export interface WSCategoriesProps {
  data?: WSCategoriesData;
  header: string;
  onUpdate: (data: WSCategoriesData) => void | Promise<void>;
  isSelectOnly?: boolean;
  renderTrigger: (props: {
    label: {
      wsCategory?: string;
      subcategory?: string;
      pseudoCategory?: string;
    };
    value: CategoryItemValue;
    pseudoCategory?: PseudoCategory;
    open: () => void;
    close: () => void;
    isOpened: boolean;
    isLoading: boolean;
  }) => React.ReactNode;
}

export const WSCategories: React.FC<WSCategoriesProps> = ({
  data,
  header,
  isSelectOnly = false,
  onUpdate,
  renderTrigger
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [openedValue, setOpenedValue] = useState<CategoryItemValue>();
  const [currentData, setCurrentData] = useState(data);
  const defaultState = mapDataToCategoryState(isSelectOnly, currentData);
  const [sections, setSections] = useState<CategorySections>(
    defaultState.sections
  );
  const allItems = getAllItemsFromSections(sections);
  const isCustom =
    !getItemByValue(allItems, defaultState.selectedValue) &&
    !!defaultState.selectedValue.subcategory;
  const [selectedValue, setSelectedValue] = useState<CategoryItemValue>({
    ...defaultState.selectedValue,
    isCustom
  });
  const openedItem = getItemByValue(allItems, openedValue);
  const qSubcategories = useSubcategories();

  const handleSelect = async (value: CategoryItemValue) => {
    setIsChanging(true);

    if (value.pseudoCategory) {
      if (value.pseudoCategory === PseudoCategory.EXPENSE) {
        setSections(OUTFLOW_SECTIONS);
      } else if (value.pseudoCategory === PseudoCategory.INCOME) {
        setSections(INFLOW_SECTIONS);
      } else {
        const newData = mapValuesToData(
          { ...selectedValue, ...value },
          currentData
        );

        setSelectedValue(value);
        await onUpdate(newData);
        await qSubcategories.refetch();
        setCurrentData(newData);
        setIsOpened(false);
      }
    } else {
      setIsOpened(false);
      setSelectedValue(value);
      await onUpdate(mapValuesToData(value, currentData));
      await qSubcategories.refetch();
    }

    setIsChanging(false);
  };

  return (
    <>
      {isOpened ? (
        <WSModalOld
          size="XS"
          onClose={() => setIsOpened(false)}
          className={styles.modal}
          fullScreenOnMobile
          showCloseIcon={false}
        >
          <WSIcon
            block
            className={styles.modalClose}
            name="exit"
            size="XS"
            onClick={() => setIsOpened(false)}
          />
          <WSElement className={styles.contentWrapper}>
            {openedItem ? (
              <SubList
                selectedValue={selectedValue}
                allCustomSubcategories={qSubcategories.data?.subcategories}
                isSelectOnly={isSelectOnly}
                subItems={openedItem.children || []}
                onBack={() => setOpenedValue(undefined)}
                onSelect={handleSelect}
                onOpen={setOpenedValue}
                openedItem={openedItem}
                header={header}
              />
            ) : (
              <MainList
                isSelectOnly={isSelectOnly}
                sections={sections}
                allCustomSubcategories={qSubcategories.data?.subcategories}
                onOpen={setOpenedValue}
                onSelect={handleSelect}
                openedValue={openedValue}
                selectedValue={selectedValue}
                header={header}
              />
            )}
          </WSElement>
        </WSModalOld>
      ) : null}

      {renderTrigger({
        open: () => setIsOpened(true),
        close: () => setIsOpened(false),
        isLoading: isChanging || qSubcategories.isLoading,
        isOpened,
        value: selectedValue,
        label: {
          wsCategory:
            getItemByValue(allItems, {
              wsCategory: selectedValue.wsCategory
            })?.name ?? selectedValue.wsCategory,
          pseudoCategory: selectedValue.pseudoCategory,
          subcategory: selectedValue.subcategory
        }
      })}
    </>
  );
};

export const transactionToWSCategoriesData: (
  transaction: ITransaction
) => WSCategoriesData = t => ({
  subcategory: t.labels.subcategory as string,
  wsCategory: t.wsCategory as WSCategory,
  isPositive: t.amount >= 0,
  type: t.type,
  business: t.business
});
