import {
  useModalOldContext,
  WSButton,
  WSButtons,
  WSModalOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { WSQueryCache } from "@ws-react-query";
import { addNotification } from "../../../components/Notification/Notification";
import { QUERY_PAYABLE, QUERY_PAYABLES } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { PayablesTableItemData } from "./PayablesTable";

type BulkUnapproveModalProps = {
  data: PayablesTableItemData[];
  onFinish?(): void;
};

export const PAYABLES_BULK_UNAPPROVE = "PAYABLES_BULK_UNAPPROVE";

export const BulkUnapproveModal: React.FC = () => {
  const { closeModal } = useModalOldContext();

  const [isUpdating, setIsUpdating] = useState(false);

  let paidItems: string[] = [];

  const bulkUnpprove = bulkActionFactory({
    onStart: () => {
      setIsUpdating(true);
      paidItems = [];
    },
    action: async (item: PayablesTableItemData) => {
      const payableId = item.payable?.payableId || "";

      if (item.payable?.status === InvoiceStatus.Paid) {
        paidItems.push(payableId);
      } else {
        return await paymentsService.payable.update(payableId, {
          client: {
            workflowStatus: ClientWorkFlowStatus.Pending
          }
        });
      }
    },
    onFinish: ({ successItems, failedItems }) => {
      WSQueryCache.invalidateQueries(QUERY_PAYABLES);
      successItems.forEach(item => {
        WSQueryCache.invalidateQueries([
          QUERY_PAYABLE,
          item.payable?.payableId
        ]);
      });

      addNotification({
        timeout: 6000,
        text:
          `${successItems.length} payable${
            successItems.length > 1 ? "s" : ""
          } have been unapproved` +
          (paidItems.length > 0
            ? `, ${paidItems.length} ${
                paidItems.length > 1 ? "were" : "was"
              } already paid`
            : "") +
          (failedItems.length > 0 ? `, ${failedItems.length} failed` : "")
      });

      setIsUpdating(false);
    }
  });

  return (
    <WSModalOld blockClose={isUpdating} name={PAYABLES_BULK_UNAPPROVE} size="S">
      {({ data, onFinish }: BulkUnapproveModalProps) => (
        <>
          {data && data.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Unapprove {data.length} payable{data.length > 1 && "s"}?
              </WSText.Heading5>
              <WSText mb="XL">Unapprove payables from being paid</WSText>
              <WSButtons format="modal">
                <WSButton
                  loading={isUpdating}
                  onClick={async () => {
                    await bulkUnpprove.run(data);
                    onFinish?.();
                    closeModal(PAYABLES_BULK_UNAPPROVE);
                  }}
                >
                  Unapprove
                </WSButton>
                <WSButton.Tertiary
                  disabled={isUpdating}
                  onClick={() => closeModal(PAYABLES_BULK_UNAPPROVE)}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          ) : (
            closeModal(PAYABLES_BULK_UNAPPROVE)
          )}
        </>
      )}
    </WSModalOld>
  );
};
