import {
  SelectOptionOld,
  useModalOldContext,
  WSButtons,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSPanel,
  WSSelectOld,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { selectQuickbooksUserOptions } from "../../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import {
  getPayerName,
  getPayerSecondaryName
} from "../../../../modules/Payers/utils/getPayerNames";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers
} from "../../../../query/integrations/queries";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import { useQueryPayerRowsAll } from "../../../../query/search/payer/queries/useQueryPayerRowsAll";
import { updatePayer } from "../../../../services/payers";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";
import { getChangedData } from "../../../../utils/getChangedData";
import styles from "./Quickbooks.module.scss";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "./RefreshButton";

export const QuickbooksSetupStep4: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const { openModal } = useModalOldContext();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const queryPayers = useQueryPayerRowsAll();

  const [error, setError] = useState("");

  const updatePayersAction = bulkActionFactory({
    onStart() {
      setError("");
    },
    async action(data: { clientId: string; customerId: string }) {
      await updatePayer(data.clientId, {
        payeeOwnedData: {
          integration: {
            quickbooks: {
              customerId: data.customerId
            }
          }
        }
      });
    },
    onFinish({ successItems, failedItems }) {
      if (failedItems.length) {
        setError("Something went wrong!");
      } else {
        history.push("/member/settings/integrations/quickbooks/setup/step/5");
      }
    }
  });

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 4}>
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksCustomers,
          queryPayers
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksCustomersData,
          queryPayers: { data: payers }
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;

          const quickbooksCustomerOptions: SelectOptionOld[] =
            selectQuickbooksUserOptions(qIntegrationsQuickbooksCustomersData);

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          const defaultFormData = payers.reduce(
            (res, payer) => ({
              ...res,
              [payer.payerId]:
                payer.payeeOwnedData?.integration?.quickbooks?.customerId
            }),
            {} as any
          );

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  4 of 8
                </WSText.ParagraphSm>
                <WSText.Heading4>Map Customers</WSText.Heading4>

                <WSPanel mt="2XL" mb="5XL">
                  <WSFormOld
                    onSubmit={async formData => {
                      console.log("formData", defaultFormData, formData);
                      const changedData = getChangedData(
                        defaultFormData,
                        formData
                      );
                      console.log("changedData", changedData);

                      await updatePayersAction.run(
                        Object.keys(changedData).map(clientId => ({
                          clientId,
                          customerId: changedData[clientId] ?? null
                        }))
                      );
                    }}
                    defaultValues={defaultFormData}
                  >
                    <WSText.Heading5 mt="XL">
                      Import your Quickbooks customers and associate them to
                      Wingspan clients
                    </WSText.Heading5>
                    {payers.length > 0 ? (
                      <>
                        <WSText mt="XL">
                          Please map your existing clients to customers in
                          Quickbooks. Any clients you don't map will have a new
                          customer created in QBO alongside any invoicing
                          activity
                        </WSText>

                        <WSTable
                          mt="XL"
                          tableData={payers.map(payer => ({
                            id: payer.payerId,
                            data: payer
                          }))}
                          columns={[
                            {
                              config: {
                                gridTemplateSizeMax: "1fr"
                              },
                              renderFunction: ({ data }) => {
                                const name = getPayerName(data);
                                return (
                                  <WSTableCell
                                    avatar={{
                                      type: "text",
                                      text: name
                                    }}
                                    text={name}
                                    secondaryText={getPayerSecondaryName(data)}
                                  />
                                );
                              }
                            },
                            {
                              config: {
                                gridTemplateSizeMax: "1fr",
                                className: styles.column
                              },
                              renderFunction: ({ data }) => (
                                <WSFlexBox.CenterY wrap="nowrap">
                                  <WSFormOld.Field
                                    className={styles.select}
                                    name={data.payerId}
                                    component={WSSelectOld}
                                    componentProps={{
                                      placeholder: "Not mapped",
                                      options: quickbooksCustomerOptions,
                                      searchable: true,
                                      cleanable: true,
                                      placeholderActions: [
                                        {
                                          label: "Resync QBO Customers",
                                          icon: "refresh-v",
                                          callback() {
                                            openModal(
                                              INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                              {
                                                entity:
                                                  QUICKBOOKS_ENTITY.CUSTOMERS
                                              }
                                            );
                                          }
                                        }
                                      ]
                                    }}
                                    defaultValue={
                                      data.payeeOwnedData?.integration
                                        ?.quickbooks?.customerId
                                    }
                                    label=""
                                  />
                                </WSFlexBox.CenterY>
                              )
                            }
                          ]}
                        />

                        <WSErrorMessage
                          mt="2XL"
                          contextKey="IntegrationsQuickbooksMapping"
                          error={error}
                        />
                      </>
                    ) : (
                      <>
                        <WSText mt="XL">
                          It appears you have no existing clients in Wingspan.
                          You can skip this step.
                        </WSText>
                      </>
                    )}
                    <WSFlexBox.Center mt="3XL">
                      <WSButtons>
                        <WSFormOld.SubmitButton name="next">
                          Next
                        </WSFormOld.SubmitButton>
                      </WSButtons>
                    </WSFlexBox.Center>
                  </WSFormOld>
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
      <RefreshModal />
    </ProjectOnboardingLayout>
  );
};
