import {
  WSButton,
  WSButtonProps,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSText,
  useIsMobile,
  useModalOldContext,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import {
  IClient,
  INextgenSubscription,
  NextgenSubscriptionPlanType,
  NextgenSubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import { formatMoney } from "accounting";
import cn from "classnames";
import React from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateNextGenSubscription,
  useDeleteNextGenSubscription,
  useUpdateNextGenSubscription
} from "../../../query/subscriptions/mutations";
import { useNextGenSubscriptions } from "../../../query/subscriptions/queries";
import { useClientQuery } from "../../../query/users/queries";
import { ErrorContextKey } from "../../../services/platform";
import { INextGenSubscriptionPlanWithInfo } from "../../utils/subscriptionUtils";
import { CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL } from "../PaymentMethodsList/ChooseAddPaymentMethodFlowModal";
import styles from "./NextGenSubscriptionPlanCard.module.scss";

export interface NextGenSubscriptionPlanCardProps {
  subscriptionPackage: INextGenSubscriptionPlanWithInfo;
  isAnnualBilling: boolean;
}

export const NextGenSubscriptionPlanCard: React.FC<
  NextGenSubscriptionPlanCardProps
> = ({ isAnnualBilling, subscriptionPackage }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();
  const { openModal } = useModalOldContext();
  const nextGenSubscriptionsQuery = useNextGenSubscriptions(userId);
  const clientQuery = useClientQuery(userId);
  const [createNextGenSubscription, createNextGenSubscriptionMeta] =
    useCreateNextGenSubscription(userId);
  const [updateNextGenSubscription, updateNextGenSubscriptionMeta] =
    useUpdateNextGenSubscription(userId);
  const [deleteNextGenSubscription, deleteNextGenSubscriptionMeta] =
    useDeleteNextGenSubscription(userId);

  const {
    name,
    description,
    includeDescription,
    prices,
    planId,
    planType,
    valuePropostions,
    isPopular
  } = subscriptionPackage;

  const feTerm = isAnnualBilling
    ? NextgenSubscriptionTerm.Yearly
    : NextgenSubscriptionTerm.Monthly;

  const nextGenSubscriptions =
    nextGenSubscriptionsQuery.data as INextgenSubscription[];
  const client = clientQuery.data as IClient;

  const formatPackageAmount = (amount: number) => {
    const amountPerMonth = isAnnualBilling ? amount / 12 : amount;
    return formatMoney(amountPerMonth, {
      symbol: "$",
      precision: amount % 12 === 0 || !isAnnualBilling ? 0 : 2
    });
  };

  const isFreePlan = () => {
    return (
      prices[NextgenSubscriptionTerm.Monthly].amount === 0 &&
      prices[NextgenSubscriptionTerm.Yearly].amount === 0
    );
  };

  const isCurrentPlan = () => {
    const displaySubscriptionTerm = isAnnualBilling
      ? NextgenSubscriptionTerm.Yearly
      : NextgenSubscriptionTerm.Monthly;
    return (
      nextGenSubscriptions.filter(
        nextGenSub =>
          nextGenSub.planId === planId &&
          nextGenSub.term === displaySubscriptionTerm
      ).length > 0
    );
  };

  const renderPackagePrice = () => {
    const amount = isAnnualBilling
      ? prices[NextgenSubscriptionTerm.Yearly].amount
      : prices[NextgenSubscriptionTerm.Monthly].amount;
    let content: React.ReactNode = `${formatPackageAmount(amount)}/mo`;

    return (
      <WSFlexBox.Center mb="M" className={styles.price}>
        <WSText.Heading1>{content}</WSText.Heading1>
      </WSFlexBox.Center>
    );
  };

  const getSubscriptionOfSamePlanId = () => {
    return nextGenSubscriptions.find(
      nextGenSub => nextGenSub.plan.planId === planId
    );
  };

  const getSubscriptionOfSamePlanType = (
    planType: NextgenSubscriptionPlanType
  ) => {
    return nextGenSubscriptions.find(
      nextGenSub => nextGenSub.plan.planType === planType
    );
  };

  const createOrUpdateNextGenSubscription = async () => {
    createNextGenSubscriptionMeta.reset();
    updateNextGenSubscriptionMeta.reset();
    const samePlanSubscription = getSubscriptionOfSamePlanId();
    const samePlanTypeSubscription = getSubscriptionOfSamePlanType(planType);
    if (samePlanSubscription || samePlanTypeSubscription) {
      await updateNextGenSubscription(
        {
          ...(samePlanSubscription
            ? {
                subscriptionId: samePlanSubscription?.subscriptionId,
                term: isAnnualBilling
                  ? NextgenSubscriptionTerm.Yearly
                  : NextgenSubscriptionTerm.Monthly,
                ...(samePlanSubscription.cancelAtPeriodEnd && {
                  cancelAtPeriodEnd: false
                })
              }
            : {}),
          ...(samePlanTypeSubscription
            ? {
                subscriptionId: samePlanTypeSubscription.subscriptionId,
                planId,
                term: isAnnualBilling
                  ? NextgenSubscriptionTerm.Yearly
                  : NextgenSubscriptionTerm.Monthly
              }
            : {})
        },
        {
          onSuccess: () => {
            history.push("/member/settings/account");
          },
          onError: err => {
            if (
              err?.response?.data?.errorSubType ===
              ErrorContextKey.PaymentMethodRequired
            ) {
              openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
                shouldSetSubscriptionPaymentMethod: true,
                onFinish: () => {
                  createOrUpdateNextGenSubscription();
                }
              });
            }
            openSnackbar({
              message: String(
                updateNextGenSubscriptionMeta.error?.response?.data?.error ||
                  "Something went wrong while changing your subscription. Please try again or contact support if this continues!"
              ),
              type: "warning"
            });
          }
        }
      );
    } else {
      await createNextGenSubscription(
        {
          planId,
          term: isAnnualBilling
            ? NextgenSubscriptionTerm.Yearly
            : NextgenSubscriptionTerm.Monthly
        },
        {
          onSuccess: () => {
            history.push("/member/settings/account");
          },
          onError: err => {
            if (
              err?.response?.data?.errorSubType ===
              ErrorContextKey.PaymentMethodRequired
            ) {
              openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
                shouldSetSubscriptionPaymentMethod: true,
                onFinish: () => {
                  createOrUpdateNextGenSubscription();
                }
              });
            } else {
              openSnackbar({
                message: String(
                  createNextGenSubscriptionMeta.error?.response?.data?.error ||
                    "Something went wrong while creating your subscription. Please try again or contact support if this continues!"
                ),
                type: "warning"
              });
            }
          }
        }
      );
    }
  };

  const renderFreePlanCTA = () => {
    let buttonProps: WSButtonProps = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined,
      disabled: true
    };
    let buttonText: React.ReactNode = `Current ${planType} plan`;
    const basePlanTypeSubscriptions = nextGenSubscriptions.filter(
      nextGenSubscription =>
        nextGenSubscription.plan.planType === NextgenSubscriptionPlanType.Base
    );
    if (basePlanTypeSubscriptions.length > 0) {
      buttonText = "Downgrade";
      buttonProps.disabled = false;
    }
    return (
      <WSButton
        textClassName={styles.btnText}
        name="selectPackage"
        loading={deleteNextGenSubscriptionMeta.isLoading}
        onClick={async () => {
          const samePlanTypeSubscription = getSubscriptionOfSamePlanType(
            NextgenSubscriptionPlanType.Base
          );
          if (samePlanTypeSubscription) {
            await deleteNextGenSubscription(
              samePlanTypeSubscription?.subscriptionId,
              {
                onSuccess: () => {
                  history.push("/member/settings/account");
                },
                onError: () => {
                  openSnackbar({
                    message: String(
                      deleteNextGenSubscriptionMeta.error?.response?.data
                        ?.error ||
                        "Something went wrong while downgrading your subscription. Please try again or contact support if this continues!"
                    ),
                    type: "warning"
                  });
                }
              }
            );
          }
        }}
        {...buttonProps}
      >
        {buttonText}
      </WSButton>
    );
  };

  const onCancelSubscription = async () => {
    const samePlanSubscription = getSubscriptionOfSamePlanId();
    await deleteNextGenSubscription(samePlanSubscription?.subscriptionId, {
      onSuccess: () => {
        history.push("/member/settings/account");
      },
      onError: () => {
        openSnackbar({
          message: String(
            deleteNextGenSubscriptionMeta.error?.response?.data?.error ||
              "Something went wrong while canceling your subscription. Please try again or contact support if this continues!"
          ),
          type: "warning"
        });
      }
    });
  };

  const renderCancelSubscriptionInfo = () => {
    const samePlanSubscription = getSubscriptionOfSamePlanId();
    if (
      samePlanSubscription?.cancelAtPeriodEnd &&
      feTerm === samePlanSubscription.term
    ) {
      return (
        <WSElement
          mb="M"
          colorBackground="blue100"
          className={styles.cancelInfoBadge}
        >
          {" "}
          <WSText.ParagraphSm color="blue500">
            Effective until{" "}
            <WSText.ParagraphSm
              color="blue500"
              inline
              formatDate="monthDayYear"
            >
              {samePlanSubscription.currentPeriodEnd}
            </WSText.ParagraphSm>
          </WSText.ParagraphSm>
        </WSElement>
      );
    } else if (isCurrentPlan()) {
      return (
        <WSButton.Secondary
          destructive
          fullWidth
          mb="XL"
          loading={
            deleteNextGenSubscriptionMeta.isLoading &&
            samePlanSubscription?.plan.planId === planId
          }
          onClick={onCancelSubscription}
        >
          Cancel
        </WSButton.Secondary>
      );
    }
    return null;
  };

  const renderPaidPlanCTA = () => {
    let buttonProps: WSButtonProps = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined
    };
    let buttonText: React.ReactNode = "Choose plan";
    const samePlanSubscription = getSubscriptionOfSamePlanId();
    if (isCurrentPlan()) {
      // TODO: @GregM Support re-activate functionality
      if (samePlanSubscription?.cancelAtPeriodEnd) {
        buttonText = `Reactivate`;
      } else {
        buttonProps.disabled = true;
        buttonText = `Current ${planType} plan`;
      }
    }
    return (
      <WSButton
        textClassName={styles.btnText}
        name="selectPackage"
        loading={
          createNextGenSubscriptionMeta.isLoading ||
          updateNextGenSubscriptionMeta.isLoading
        }
        onClick={async () => {
          await createOrUpdateNextGenSubscription();
        }}
        {...buttonProps}
      >
        {buttonText}
      </WSButton>
    );
  };

  const renderPackageCTA = () => {
    if (isFreePlan()) {
      return renderFreePlanCTA();
    }
    return renderPaidPlanCTA();
  };
  const samePlanSubscription = getSubscriptionOfSamePlanId();

  return (
    <WSFlexBox
      direction="column"
      className={cn(styles.subscriptionPackageCard, {
        [styles.popular]: isPopular
      })}
      p="M"
      justify="flex-end"
      data-testid={name}
    >
      <WSFlexBox justify="space-between" mb="M">
        <WSText.Heading5 mr="M">{name}</WSText.Heading5>
        {isPopular && <WSPill theme="blue" icon="reward" text="MOST POPULAR" />}
      </WSFlexBox>
      {description && (
        <WSText
          color="gray600"
          mb="XL"
          align="left"
          className={styles.description}
        >
          {description}
        </WSText>
      )}
      {renderPackagePrice()}
      <WSFlexBox
        direction="column"
        mb="3XL"
        className={cn(styles.valuePropostionsContainer, {
          [styles.effectiveDateBadge]:
            samePlanSubscription?.cancelAtPeriodEnd &&
            feTerm === samePlanSubscription.term,
          [styles.cancelButton]: isCurrentPlan()
        })}
      >
        {valuePropostions.map((valuePropostion: string) => (
          <WSFlexBox.CenterX mb="M" wrap="nowrap">
            <WSIcon block name="check" size="M" color="green500" mr="XS" />
            <WSText inline align="left">
              {valuePropostion}
            </WSText>
          </WSFlexBox.CenterX>
        ))}
      </WSFlexBox>
      {renderCancelSubscriptionInfo()}
      {renderPackageCTA()}
    </WSFlexBox>
  );
};
