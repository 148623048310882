import { WSQueryConfig } from "@ws-react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { ICardEmbedUrlResponse } from "../../../services/api/banking/cards/types";
import { getCardEmbedUrl } from "../../../services/api/banking/cards";

export const QUERY_CARD_EMBED_URL = "QUERY_CARD_EMBED_URL";

let EMBED_CSS = window.location.origin + "/embed-card.css";

export const useCardEmbedUrlQuery = (
  cardId: string,
  config?: WSQueryConfig<ICardEmbedUrlResponse, WSServiceError>
) => {
  return useWSQuery<ICardEmbedUrlResponse, WSServiceError>(
    [QUERY_CARD_EMBED_URL, cardId],
    () => getCardEmbedUrl(cardId, EMBED_CSS),
    {
      retry: false,
      ...config
    }
  );
};
