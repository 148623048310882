import { WSLoader } from "@wingspanhq/fe-component-library";
import { FundingSourceType } from "@wingspanhq/payments/dist/interfaces";
import { AccountStatus, Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryInternalAccounts } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_PAYROLL_SETTINGS } from "../../../../query/payments/keys";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { useAccounts, useClientQuery } from "../../../../query/users/queries";
import { paymentsService } from "../../../../services/payments";
import { selectorAccountVerificationPending } from "../../../../shared/selectors/selectorAccountVerificationPending";
import { selectorActiveBankingAccount } from "../../../../shared/selectors/selectorActiveBankingAccount";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD } from "../../paths";
import { ManageMethods, MethodType, MethodValue } from "../ManageMethods";

function methodTypeToFundingSourceType(methodType: MethodType) {
  switch (methodType) {
    case "Account":
      return FundingSourceType.Account;

    case "InternalAccount":
      return FundingSourceType.InternalAccount;

    case "CreditCard":
      return FundingSourceType.PaymentCard;

    default:
      throw new Error("Not supported");
  }
}

function fundingSourceTypeToMethodType(
  fundingSourceType: FundingSourceType
): MethodType {
  switch (fundingSourceType) {
    case FundingSourceType.Account:
      return "Account";

    case FundingSourceType.InternalAccount:
      return "InternalAccount";

    case FundingSourceType.PaymentCard:
      return "CreditCard";

    default:
      throw new Error("Not supported");
  }
}

type Props = {
  onNext: () => void;
};

export const ManageFundingMethod: React.FC<Props> = ({ onNext }) => {
  const userId = useUserId();
  const history = useHistory();
  const queryAccounts = useAccounts();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryInternalAccounts = useQueryInternalAccounts("Banking");
  const queryClient = useClientQuery(userId);

  const accounts = useMemo(
    () =>
      (queryAccounts.data || []).filter(
        account =>
          (account.canBeUsedFor?.payments ||
            selectorAccountVerificationPending(account)) &&
          account.status !== AccountStatus.Inactive
      ),
    [queryAccounts.data]
  );

  const creditCards = useMemo(
    () => queryClient.data?.profile.savedPaymentMethods,
    [queryClient.data?.profile.savedPaymentMethods]
  );

  const internalAccounts = useMemo(
    () =>
      (queryInternalAccounts.data || []).filter(selectorActiveBankingAccount),
    [queryInternalAccounts.data]
  );

  const initialValue = useMemo((): MethodValue | undefined => {
    if (queryPayrollSettings.data?.fundingSource) {
      return {
        id: queryPayrollSettings.data?.fundingSource.fundingSourceId,
        type: fundingSourceTypeToMethodType(
          queryPayrollSettings.data?.fundingSource.fundingSourceType
        )
      };
    }
  }, [queryPayrollSettings.data?.fundingSource]);

  const [mutate, meta] = useWSMutation<void, WSServiceError, MethodValue>(
    async data => {
      await paymentsService.payrollSettings.update(userId, {
        fundingSource: {
          fundingSourceId: data.id,
          fundingSourceType: methodTypeToFundingSourceType(data.type),
          fundingSourceCurrency: Currency.USD
        }
      });
    },
    { onSuccess: onNext, dependencies: [QUERY_PAYROLL_SETTINGS] }
  );

  if (
    queryAccounts.isLoading ||
    queryPayrollSettings.isLoading ||
    queryInternalAccounts.isLoading
  ) {
    return <WSLoader.Spinner />;
  }

  return (
    <ManageMethods
      data={{
        accounts,
        internalAccounts,
        creditCards
      }}
      header={{
        label: {
          text: "Manage payroll funding method",
          helperText:
            "Set an existing payroll funding method as default or add a new payroll funding method"
        }
      }}
      onAddNew={() => {
        history.push(PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD);
      }}
      addNewLabel="Add new payroll funding method"
      initialValue={initialValue}
      onSubmit={mutate}
      error={meta.error}
      isLoading={meta.isLoading}
    />
  );
};
