import {
  IPendingWithholding,
  IPendingWithholdingCreate,
  IPendingWithholdingUpdate
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { WSMutationsConfig } from "@ws-react-query";
import bookkeepingService from "../../services/bookkeeping";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSMutation } from "../helpers";
import {
  QUERY_TAXES_PENDING_WITHHOLDING,
  QUERY_TAXES_PENDING_WITHHOLDING_LIST
} from "./keys";

export const useCreatePendingWithholding = (
  config?: WSMutationsConfig<
    IPendingWithholding,
    WSServiceError,
    IPendingWithholdingCreate
  >
) =>
  useWSMutation(
    async (request: IPendingWithholdingCreate) =>
      await bookkeepingService.createPendingWithholding(request),
    {
      awaitDependencies: [QUERY_TAXES_PENDING_WITHHOLDING_LIST],
      ...config
    }
  );

export const useUpdatePendingWithholding = (
  config?: WSMutationsConfig<
    IPendingWithholding,
    WSServiceError,
    { id: string } & IPendingWithholdingUpdate
  >
) =>
  useWSMutation(
    async ({ id, ...params }) =>
      await bookkeepingService.updatePendingWithholding(id, params),
    {
      awaitDependencies: [
        QUERY_TAXES_PENDING_WITHHOLDING_LIST,
        QUERY_TAXES_PENDING_WITHHOLDING
      ],
      ...config
    }
  );
