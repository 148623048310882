import {
  WSButton,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
// import { FormRequestCorrectionData } from "../../../../TaxDocuments/routes/RequestCorrectionFlow/types";
import {
  useUpdateMemberProfile,
  useUpdateUserProfile
} from "../../../../../query/users/mutations";
import React, { useEffect } from "react";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { useUserId } from "../../../../../query/hooks/helpers";
import isEmpty from "lodash/isEmpty";
import { IMemberProfile, IMemberProfileWrite } from "@wingspanhq/users";
import { FormRequestCorrectionData } from "../types";

export interface ProfileUpdateSummaryProps extends WSElementProps {
  correctionsFormData: FormRequestCorrectionData;
  taxForm: IPayeeTaxFormResponse;
}

const renderErrorMessage = () => (
  <WSMessageBox.Error noBorder mt="M" mb="XL">
    <WSText weight="medium" color="red500">
      There was an error updating this information
    </WSText>
    <WSText.ParagraphSm mt="XS">
      Please{" "}
      <WSButton.Link onClick={openIntercom}>contact support.</WSButton.Link>{" "}
    </WSText.ParagraphSm>
  </WSMessageBox.Error>
);

const renderBlock = (props: {
  title: string;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}) => {
  return (
    <WSFlexBox.CenterY mb="M">
      {props.isSuccess ? (
        <WSIcon block name="check-double" size="XS" color="green500" />
      ) : props.isError ? (
        <WSIcon block name="alert" size="XS" color="red500" />
      ) : (
        <WSLoader.Spinner size="XS" />
      )}
      <WSText.ParagraphSm ml="M" color="gray600">
        {props.title}
      </WSText.ParagraphSm>
    </WSFlexBox.CenterY>
  );
};

export const ProfileUpdateSummary: React.FC<ProfileUpdateSummaryProps> = ({
  taxForm,
  correctionsFormData,
  ...otherProps
}) => {
  const userId = useUserId();
  const {
    ssn,
    ein,
    identificationNumberType,
    businessName,
    disregardedEntityName,
    firstName,
    lastName,
    formW9Address,
    taxClassification
  } = correctionsFormData || {};
  const [updateTin, updateTinMeta] = useUpdateMemberProfile();
  const [updateMemberCompany, updateMemberCompanyMeta] =
    useUpdateMemberProfile();
  const [updateMemberAddress, updateMemberAddressMeta] =
    useUpdateMemberProfile();
  const [updateUser, updateUserMeta] = useUpdateUserProfile();

  const isTINCorrection = !!(ssn || ein || identificationNumberType);
  const isBusinessNameCorrection = !!businessName;
  const isTaxClassificationCorrection = !!taxClassification;
  const isAddressCorrection = !!formW9Address;
  const isNameCorrection = !!(firstName || lastName);

  useEffect(() => {
    (async () => {
      if (isTINCorrection) {
        const profile = {
          ...(ssn ? { ssn } : {}),
          ...(ein ? { company: { taxId: ein } } : {})
        };
        if (!isEmpty(profile)) {
          updateTin({
            memberId: userId,
            profile
          });
        }
      }

      if (
        isTINCorrection ||
        isBusinessNameCorrection ||
        isTaxClassificationCorrection
      ) {
        const company: IMemberProfileWrite["company"] = {
          ...(businessName ? { legalBusinessName: businessName } : {}),
          ...(disregardedEntityName ? { disregardedEntityName } : {}),
          ...(taxClassification ? { structure: taxClassification } : {})
        };
        updateMemberCompany({
          memberId: userId,
          profile: {
            company
          }
        });
      }

      if (isAddressCorrection) {
        const address: IMemberProfile["address"] = {
          addressLine1: formW9Address?.addressLine1,
          addressLine2: formW9Address?.addressLine2,
          city: formW9Address?.city,
          state: formW9Address?.state!,
          postalCode: formW9Address?.postalCode!,
          country: "US"!
        };

        updateMemberAddress({
          memberId: userId,
          profile: {
            address
          }
        });
      }

      if (isNameCorrection) {
        updateUser({
          profile: {
            firstName,
            lastName
          }
        });
      }
    })();
  }, [userId, correctionsFormData]);

  return (
    <WSMessageBox.Info noBorder {...otherProps}>
      <WSText weight="medium" color="gray500" mb="M">
        Wingspan profile update summary:
      </WSText>

      {isTINCorrection
        ? renderBlock({
            title: "Taxpayer identification number",
            ...updateTinMeta
          })
        : null}
      {isTINCorrection && updateTinMeta.isError ? renderErrorMessage() : null}

      {isBusinessNameCorrection || isNameCorrection
        ? renderBlock({
            title: "Business name",
            ...(isNameCorrection ? updateUserMeta : updateMemberCompanyMeta)
          })
        : null}
      {isBusinessNameCorrection && updateMemberCompanyMeta.isError
        ? renderErrorMessage()
        : null}

      {isTaxClassificationCorrection
        ? renderBlock({
            title: "Federal tax classification",
            ...updateMemberCompanyMeta
          })
        : null}
      {isTaxClassificationCorrection && updateMemberCompanyMeta.isError
        ? renderErrorMessage()
        : null}

      {isAddressCorrection
        ? renderBlock({
            title: "Address",
            ...updateMemberAddressMeta
          })
        : null}
      {isAddressCorrection && updateMemberAddressMeta.isError
        ? renderErrorMessage()
        : null}

      <WSText.ParagraphSm color="gray400" mb="XS" mt="XL">
        Updates on your profile{" "}
        <WSText.ParagraphSm weight="medium" color="gray400" inline>
          occur separately from tax filings
        </WSText.ParagraphSm>{" "}
        to ensure the most up-to-date information is reflected in Wingspan.
      </WSText.ParagraphSm>
    </WSMessageBox.Info>
  );
};
