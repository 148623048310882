import {
  toWSMoneyString,
  useModalOldContext,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import {
  DeductionStatus,
  DeductionType
} from "@wingspanhq/payments/dist/interfaces/deductions";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useCollaboratorDeductions } from "../../../query/payments/queries";
import { ADD_DEDUCTION_MODAL } from "../../screens/deductions/modals/AddDeduction";
import { DEDUCTION_INFO_MODAL } from "../../screens/deductions/modals/DeductionsInfo";
import { toURLQuerySearch } from "../../../shared/hooks/useUrlQuery";

type Props = {
  payableId?: string;
  collaboratorEmail?: string;
  clientId: string;
  memberId: string;
  collaboratorId: string;
  onRowClick(d: IDeductionResponse): void;
  onAllClick?(): void;
};

const DeductionTable: React.FC<{
  tableData: WSTableItem[];
  title: string;
  onAddNew?(): void;
  onRowClick(d: IDeductionResponse): void;
  onAllClick?(): void;
  showInfo?: boolean;
}> = ({ tableData, title, onRowClick, onAllClick, onAddNew, showInfo }) => {
  const { openModal } = useModalOldContext();

  const data = tableData.slice(0, 4);

  return (
    <>
      <WSFlexBox.CenterY justify="space-between" mb="XL">
        <WSText.Heading5>
          {`${title} `}
          {showInfo ? (
            <WSIcon
              ml="XS"
              size="XS"
              name="info-circle"
              color="gray400"
              onClick={() => openModal(DEDUCTION_INFO_MODAL)}
            />
          ) : null}
        </WSText.Heading5>
        {onAllClick && tableData.length > 3 ? (
          <WSButton.Link onClick={onAllClick}>See all</WSButton.Link>
        ) : null}
      </WSFlexBox.CenterY>

      <WSDivider />
      <WSTable<IDeductionResponse>
        tableData={data}
        onRowClick={item => onRowClick(item.data)}
        columns={[
          {
            config: {
              gridTemplateSizeMax: "1fr"
            },
            renderFunction: ({ data }) => {
              const deductedAmount = data.application
                .map(a => a.amountDeducted)
                .reduce((a, b) => a + b, 0);

              const remainingAmount = deductedAmount
                ? data.amount - deductedAmount
                : data.amount;

              return (
                <WSTableCell
                  text={
                    data.status === DeductionStatus.PartiallyApplied
                      ? `${toWSMoneyString(
                          remainingAmount,
                          "default",
                          data.currency
                        )} ${deductedAmount ? "remaining" : ""}`
                      : toWSMoneyString(data.amount, "default", data.currency)
                  }
                  secondaryText={data.name}
                />
              );
            }
          },
          {
            config: {
              hideOnScreens: ["XS"],
              gridTemplateSizeMax: "0.5fr",
              justify: "end"
            },
            renderFunction: ({ data }) => (
              <WSTableCell
                pill={{
                  icon:
                    data.type === DeductionType.PostPayment
                      ? "after"
                      : "before",
                  text:
                    data.type === DeductionType.PostPayment
                      ? "Post-payment"
                      : "Pre-payment"
                }}
              />
            )
          }
        ]}
      />
      {onAddNew ? (
        <>
          <WSDivider mb="XL" />
          <WSButton.Link icon="plus-circle" onClick={onAddNew}>
            Add new deduction
          </WSButton.Link>
        </>
      ) : null}
    </>
  );
};

export const CollaboratorDeductionsList: React.FC<Props> = props => {
  const history = useHistory();
  const { openModal } = useModalOldContext();

  const featureFlags = useFeatureFlags();

  const qPendingDeductions = useCollaboratorDeductions({
    memberId: props.memberId,
    clientId: props.clientId,
    status: DeductionStatus.Pending
  });

  const qCompleteDeductions = useCollaboratorDeductions({
    memberId: props.memberId,
    clientId: props.clientId,
    status: DeductionStatus.Complete
  });

  return (
    <WSPanel noBorder mb="XL" data-testid="deductionsPanel">
      <WSQueries queries={{ qPendingDeductions, qCompleteDeductions }}>
        {({ qPendingDeductions, qCompleteDeductions }) => {
          const pendingData: WSTableItem[] = qPendingDeductions.data.map(
            deduction => {
              const { deductionId } = deduction;

              return {
                id: deductionId,
                data: deduction
              };
            }
          );

          const completedData: WSTableItem[] = qCompleteDeductions.data.map(
            deduction => {
              const { deductionId } = deduction;

              return {
                id: deductionId,
                data: deduction
              };
            }
          );

          return (
            <>
              <DeductionTable
                showInfo
                tableData={pendingData}
                title="Scheduled deductions"
                onRowClick={props.onRowClick}
                onAddNew={() => {
                  openModal(ADD_DEDUCTION_MODAL, {
                    clientId: props.clientId,
                    memberId: props.memberId
                  });
                }}
                onAllClick={
                  props.onAllClick
                    ? props.onAllClick
                    : () => {
                        history.push({
                          pathname: "/member/invoices/payables/deductions",
                          search: toURLQuerySearch({
                            collaboratorEmail: props.collaboratorEmail
                          })
                        });
                      }
                }
              />
              {completedData.length ? (
                <>
                  <WSElement pt="2XL" />
                  <DeductionTable
                    tableData={completedData}
                    title="Completed deductions"
                    onRowClick={props.onRowClick}
                    onAllClick={() => {
                      history.push({
                        pathname: `/member/invoices/payables/deductions`,
                        search: toURLQuerySearch({
                          collaboratorEmail: props.collaboratorEmail
                        })
                      });
                    }}
                  />
                </>
              ) : null}
            </>
          );
        }}
      </WSQueries>
    </WSPanel>
  );
};

export const UnpaidDeductionsList: React.FC<Props> = props => {
  const history = useHistory();
  const { openModal } = useModalOldContext();

  const featureFlags = useFeatureFlags();

  const qPendingDeductions = useCollaboratorDeductions({
    memberId: props.memberId,
    clientId: props.clientId,
    status: DeductionStatus.Pending
  });

  return (
    <WSPanel noBorder mb="XL" data-testid="deductionsPanel">
      <WSQueries queries={{ qPendingDeductions }}>
        {({ qPendingDeductions: { data: deductions } }) => {
          if (props.payableId) {
            deductions = deductions.filter(deduction => {
              if (deduction.application?.length > 0) {
                return deduction.application.some(
                  application => application.payableId === props.payableId
                );
              }

              return false;
            });
          }

          const pendingData: WSTableItem[] = deductions.map(deduction => {
            const { deductionId } = deduction;

            return {
              id: deductionId,
              data: deduction
            };
          });

          return (
            <>
              <DeductionTable
                showInfo
                tableData={pendingData}
                title="Scheduled deductions"
                onRowClick={props.onRowClick}
                onAddNew={() => {
                  openModal(ADD_DEDUCTION_MODAL, {
                    clientId: props.clientId,
                    memberId: props.memberId
                  });
                }}
                onAllClick={
                  props.onAllClick
                    ? props.onAllClick
                    : () => {
                        history.push({
                          pathname: "/member/invoices/payables/deductions",
                          search: toURLQuerySearch({
                            collaboratorEmail: props.collaboratorEmail
                          })
                        });
                      }
                }
              />
            </>
          );
        }}
      </WSQueries>
    </WSPanel>
  );
};

export const PaidDeductionsList: React.FC<Props> = props => {
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const qAppliedDeductions = useCollaboratorDeductions({
    memberId: props.memberId,
    clientId: props.clientId,
    status: { in: [DeductionStatus.Complete, DeductionStatus.PartiallyApplied] }
  });

  return (
    <WSQueries queries={{ qAppliedDeductions }} renderLoader={() => null}>
      {({ qAppliedDeductions: { data: deductions } }) => {
        if (props.payableId) {
          deductions = deductions.filter(deduction => {
            if (deduction.application?.length > 0) {
              return deduction.application.some(
                application => application.payableId === props.payableId
              );
            }

            return false;
          });
        }

        const appliedData: WSTableItem[] = deductions.map(deduction => {
          const { deductionId } = deduction;

          return {
            id: deductionId,
            data: deduction
          };
        });

        if (!appliedData.length) {
          return null;
        }

        return (
          <WSPanel noBorder mb="XL" data-testid="deductionsPanel">
            <DeductionTable
              tableData={appliedData}
              showInfo
              title="Applied deductions"
              onRowClick={props.onRowClick}
              onAllClick={
                props.onAllClick
                  ? props.onAllClick
                  : () => {
                      history.push({
                        pathname: "/member/invoices/payables/deductions",
                        search: toURLQuerySearch({
                          collaboratorEmail: props.collaboratorEmail
                        })
                      });
                    }
              }
            />
          </WSPanel>
        );
      }}
    </WSQueries>
  );
};
